/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AnyTag, Tag, UnsavedTag } from "api/tag";
import { validate, ValidationResult } from "utils/validation.utils";

export function validateTag(tag: UnsavedTag): ValidationResult<UnsavedTag>;
export function validateTag(tag: Tag): ValidationResult<Tag>;
export function validateTag(tag: AnyTag): ValidationResult<AnyTag> {
  return validate(tag, {
    name: {
      presence: { allowEmpty: false }
    },
    slug: {
      presence: { allowEmpty: false }
    }
  });
}
