/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const DesktopIcon = ({ width = "16", height = "16", fill = "var(--mono-5)", color = "#fff" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fill} viewBox="0 0 16 16">
    <g clipPath="url(#clip0_3450_4392)">
      <path
        fill={color}
        d="M6 13v-1.111h1v-1.111H4a.92.92 0 0 1-.706-.327A1.13 1.13 0 0 1 3 9.667V4.11q0-.458.294-.785A.92.92 0 0 1 4 3h8q.412 0 .706.326.294.327.294.785v5.556q0 .458-.294.784a.92.92 0 0 1-.706.327H9v1.11h1V13zM4 9.667h8V4.11H4z"></path>
    </g>
    <defs>
      <clipPath id="clip0_3450_4392">
        <rect width="16" height="16" fill="#fff" rx="2"></rect>
      </clipPath>
    </defs>
  </svg>
);

export default DesktopIcon;
