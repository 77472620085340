/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect, useState } from "react";
import styles from "./embargo.module.css";
import { t } from "i18n";
import Inspector from "components/inspector/inspector";
import DatePickerCustom from "components/date-picker/date-picker";
import Button from "components/button/button";
import ConfirmationModal from "components/confirmation-modal/confirmation-modal";

export enum InspectorType {
  CREATE = "Create",
  EDIT = "Edit"
}
interface StateProps {
  isActive: boolean;
  onClose: () => void;
  inspectorType: InspectorType;
  updateEmbargo: (embargoTime: number | null) => void;
  embargoedTill: number | null;
}

type Props = StateProps;

const EmbargoInspector: React.SFC<Props> = ({ isActive, onClose, inspectorType, updateEmbargo, embargoedTill }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [embargoTime, setEmbargoTime] = useState(embargoedTill);

  const actionButtonLabel = inspectorType === InspectorType.CREATE ? t("common.save") : t("common.update");

  const actionHandler = (timeStamp: number | null) => {
    updateEmbargo(timeStamp);
    setShowConfirmationModal(false);
  };

  useEffect(() => setEmbargoTime(embargoedTill), [embargoedTill]);

  const closeConfirmationModal = () => setShowConfirmationModal(false);
  return (
    <div>
      <Inspector
        title={t("common.embargo")}
        onClose={onClose}
        isActive={isActive}
        actionButtonLabel={actionButtonLabel}
        onActionButtonClick={() => actionHandler(embargoTime)}>
        <>
          <h3 data-test-id="embargo-inspector-title" className={styles["embargo-inspector-title"]}>
            {t("story-editor.inspector.embargo.title")}
          </h3>
          <p data-test-id="embargo-inspector-subtitle" className={styles["embargo-inspector-subtitle"]}>
            {t("story-editor.inspector.embargo.subtitle")}
          </p>
          <br />
          <DatePickerCustom
            showTimeSelect
            onChange={setEmbargoTime}
            selectedDate={embargoTime}
            showTimeZone={false}
            dateInUTC={true}
          />
          <br />
          {inspectorType !== InspectorType.CREATE && (
            <Button type="secondary" onClick={() => setShowConfirmationModal(!showConfirmationModal)}>
              {t("story-editor.inspector.embargo.remove-button")}
            </Button>
          )}
        </>
      </Inspector>

      <ConfirmationModal
        showConfirmationModal={showConfirmationModal}
        headerText={t("story-editor.inspector.embargo.remove-confirmation-title")}
        text={t("story-editor.inspector.embargo.remove-confirmation-subtitle")}
        confirmButtonLabel={t("settings.generalSettings.remove")}
        cancelButtonLabel={t("workspace.confirmation_modal.cancel")}
        onConfirm={() => actionHandler(null)}
        onCancel={closeConfirmationModal}
        onClose={closeConfirmationModal}
      />
    </div>
  );
};

export default EmbargoInspector;
