/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Embed = ({ width = "24", height = "24", color = "#c6ccd5" }) => (
  <svg width={width} height={height} fill={color} viewBox="0 0 24 24">
    <path d="M14.07 4.5L8.9 18.98l-.05.12 1.08.4L15.1 5.02l.04-.12-1.07-.4z" />
    <path
      fillRule="evenodd"
      d="M2.5 11.88l4.92 4.87.8-.82-4.09-4.05 4-3.96.09-.09-.8-.82-4.92 4.87zm13.28-4.05l4.09 4.05-4 3.96-.09.09.79.82 4.93-4.87-4.93-4.87-.79.82z"
    />
  </svg>
);

export default Embed;
