/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

export interface AmpConfigResponse {
  "amp-config": AmpConfig;
}

export type AmpSaveResponse = AmpConfigResponse | Object;

export interface ChartBeatConfig {
  uid: string;
}
export interface AmpConfig {
  colors: ColorsTypes;
  doubleclick: DoubleclickTypes;
  fonts: FontsConfigTypes;
  "google-analytics-tracking-id": string;
  "gtm-id": string;
  "comscore-tracking-vars": ComscoreTrackingVarsTypes;
  "logo-url": string;
  "google-client-id-api": boolean;
  menu: MenuTypes;
  "invalid-elements-strategy": string;
  chartbeat?: ChartBeatConfig;
}
export interface MenuTypes {
  enabled: boolean;
}

export interface ComscoreTrackingVarsTypes {
  c1: number | null;
  c2: number | null;
}

export interface ColorsTypes {
  primary: string;
  secondary: string;
  "header-background": string;
  "footer-background": string;
  "footer-text-color": string;
  "section-text-color": string;
}

export interface DoubleclickTypes {
  "body-ad": Ad;
  "bottom-ad": Ad;
  "top-ad": Ad;
}

export interface Ad {
  "unit-path": string;
  width: number | null;
  height: number | null;
}

export interface FontsConfigTypes {
  primary: FontFamilyConfig;
  secondary: FontFamilyConfig;
}

export interface FontFamilyConfig {
  family: string;
  url: string;
}

export const saveAmpConfigData = (params: AmpConfig): Promise<AmpSaveResponse> => {
  return client
    .url("/api/amp-config")
    .json({ "amp-config": params })
    .post()
    .json()
    .catch((err) => {
      return Promise.reject(JSON.parse(err.message));
    });
};

export const getAmpConfigData = (): Promise<AmpConfigResponse> => {
  return client
    .url("/api/amp-config")
    .get()
    .json();
};
