/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import Async from "components/select/async";
import pDebounce from "p-debounce";
import { listPersonEntities } from "api/entity";
import { actions } from "../../actions";
import { StoryElements } from "api/story";
import { t } from "i18n";
import { PartialAppState } from "pages/story-editor/state";
import { Node } from "prosemirror-model";
import { ThunkDispatch } from "redux-thunk";

const debouncedListPersonEntities = pDebounce(
  (name: string) => listPersonEntities(name).then((response) => response["entities"]),
  250
);

const onChange = (type: string, storyElement: StoryElements, value: any, changefn: any) => {
  const keyChanged = type === "question" ? "interviewer" : "interviewee";
  if (storyElement) {
    const changes = { ...storyElement, metadata: { ...storyElement.metadata, [keyChanged]: value } };
    changefn(storyElement.id, changes);
  }
};

const NodeViewSelect = (props: any) => {
  return (
    <Async
      placeholder={
        props.type === "question"
          ? t("story-editor.story-element.question-attribution")
          : t("story-editor.story-element.answer-attribution")
      }
      value={
        props.type === "question"
          ? props.storyElement && props.storyElement.metadata.interviewer
          : props.storyElement && props.storyElement.metadata.interviewee
      }
      loadOptions={debouncedListPersonEntities}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      onChange={(value) => onChange(props.type, props.storyElement, value, props.onChange)}
      hideSelectedOptions={true}
      menuPlacement={props.type === "question" ? "bottom" : "top"}
      variant="editor"
      components={{ DropdownIndicator: null }}
      label={t("story-editor.story-element.attribution")}
      isLabelUpperCase={true}
    />
  );
};

const mapStateToProps = (state: PartialAppState, { node }: { node: Node }) => {
  return {
    type: node.attrs.helperName,
    storyElement: state.storyEditor.story["story-elements"][node.attrs.id]
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
  return {
    onChange: (id: number, changes: any) => dispatch({ type: actions.UPDATE_STORY_ELEMENT, payload: { id, changes } })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NodeViewSelect);
