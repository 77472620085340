/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { get } from "lodash";
import TextField from "components/text-field/text-field";
import { VidibleConfig, Integrations } from "api/integrations";
import { isClientValidationError } from "utils/validation.utils";

interface Props {
  integration: { key: Integrations.Vidible; value: VidibleConfig };
  updateIntegrationField: (key: string, value: Object) => void;
  readOnly?: boolean;
  errors: Error | null;
}

const Vidible: React.SFC<Props> = ({ integration, updateIntegrationField, readOnly, errors }) => {
  const integrationName = integration.key;
  return (
    <React.Fragment>
      <TextField
        label={t("integrations.fields.buyer-company-id")}
        value={integration.value["buyer-company-id"]}
        onChange={(value) => updateIntegrationField(integrationName, { "buyer-company-id": value })}
        readOnly={readOnly}
        errorMessage={
          isClientValidationError<VidibleConfig>(errors)
            ? get(errors, ["errors", "value", "buyer-company-id", "0"])
            : undefined
        }
      />
      <TextField
        label={t("integrations.fields.company-id")}
        value={integration.value["company-id"]}
        onChange={(value) => updateIntegrationField(integrationName, { "company-id": value })}
        readOnly={readOnly}
        errorMessage={
          isClientValidationError<VidibleConfig>(errors)
            ? get(errors, ["errors", "value", "company-id", "0"])
            : undefined
        }
      />
      <TextField
        label={t("integrations.fields.player-id")}
        value={integration.value["player-id"]}
        onChange={(value) => updateIntegrationField(integrationName, { "player-id": value })}
        readOnly={readOnly}
        errorMessage={
          isClientValidationError<VidibleConfig>(errors)
            ? get(errors, ["errors", "value", "player-id", "0"])
            : undefined
        }
      />
      <TextField
        label={t("integrations.fields.playlist-id")}
        value={integration.value["playlist-id"]}
        onChange={(value) => updateIntegrationField(integrationName, { "playlist-id": value })}
        readOnly={readOnly}
        errorMessage={
          isClientValidationError<VidibleConfig>(errors)
            ? get(errors, ["errors", "value", "playlist-id", "0"])
            : undefined
        }
      />
    </React.Fragment>
  );
};

export default Vidible;
