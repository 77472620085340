/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import wrapPage from "containers/page/page";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { t } from "i18n";
import { ThunkDispatch } from "redux-thunk";

import ContentHeader from "../../../components/content-header/content-header";
import { loadAmpConfig } from "./async-action-creators";
import { updateAmpConfigAction } from "./action-creators";
import { PartialAppState } from "./state";
import { AmpConfig, DoubleclickTypes, ColorsTypes, FontsConfigTypes, ComscoreTrackingVarsTypes } from "api/amp-config";
import AmpHeader from "./components/amp-header";
import ColorsConfig from "./components/colors-config";
import FontsConfig from "./components/fonts-config";
import OtherConfig from "./components/other-config";
import AdvertisementConfig from "./components/advertisement-config";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import { LoaderState } from "behaviors/loader/state";
import Loader from "./loader";

import classnames from "classnames/bind";
import styles from "./amp-config.module.css";

const cx = classnames.bind(styles);
interface StateProps {
  ampConfig: AmpConfig | null;
  saveErrors: Error | null;
  loader: LoaderState;
}

interface DispatchProps {
  loadAmpConfig: () => void;
  updateConfig: (
    key: string,
    changes: DoubleclickTypes & ColorsTypes & FontsConfigTypes & ComscoreTrackingVarsTypes & string
  ) => void;
}

type Props = StateProps &
  DispatchProps & {
    showSubmenu: string;
    activeMenu: string;
    isBannerPresent: boolean;
  };

class Amp extends React.Component<Props> {
  componentDidMount() {
    this.props.loadAmpConfig();
  }

  render() {
    const { ampConfig, updateConfig, saveErrors, loader } = this.props;

    return (
      <React.Fragment>
        <main className={cx("amp-config-page-container", "configure-page")}>
          <ContentHeader title={t("settings.amp.title")} description={t("settings.amp.description")} />
          <LoaderWrapper className="amp-config-progress-main-area" component={Loader} loader={loader}>
            {ampConfig && (
              <React.Fragment>
                <div className={styles["amp-config"]}>
                  <ColorsConfig ampConfig={ampConfig} saveErrors={saveErrors} updateConfig={updateConfig} />
                  <FontsConfig ampConfig={ampConfig} saveErrors={saveErrors} updateConfig={updateConfig} />
                  <OtherConfig ampConfig={ampConfig} saveErrors={saveErrors} updateConfig={updateConfig} />
                </div>
                <h3 className={styles["amp-config-title"]}>{t("settings.amp.advertisements.title")}</h3>
                <p className={styles["amp-config-description"]}>{t("settings.amp.advertisements.description")}</p>
                <div className={styles["amp-config"]}>
                  <AdvertisementConfig ampConfig={ampConfig} saveErrors={saveErrors} updateConfig={updateConfig} />
                </div>
              </React.Fragment>
            )}
          </LoaderWrapper>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    ampConfig: state.ampConfig.ampConfig,
    saveErrors: state.ampConfig.ui.saveErrors.error,
    loader: state.ampConfig.ui.main
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    loadAmpConfig: () => dispatch(loadAmpConfig()),
    updateConfig: (key, changes) => dispatch(updateAmpConfigAction(key, changes))
  };
};

export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  wrapPage({ HeaderComponent: AmpHeader })
)(Amp);

export { Amp };
