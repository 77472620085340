/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface LinkIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const Link: React.SFC<LinkIconProps> = ({ width = "24", height = "24", color = "currentColor" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 24 24">
    <path d="M8.35,15.32a.9.9,0,0,0,1.27,0l6-5.7a.9.9,0,0,0,0-1.27.91.91,0,0,0-1.28,0l-5.95,5.7A.9.9,0,0,0,8.35,15.32Z" />
    <path d="M15.54,3.1a5.38,5.38,0,0,0-3.18,1l-.1.08L9.33,7.08a.9.9,0,1,0,1.26,1.29l2.88-2.81a3.54,3.54,0,0,1,2.07-.66,3.6,3.6,0,0,1,3.6,3.6,3.56,3.56,0,0,1-.88,2.36l-2.43,2.51a.9.9,0,1,0,1.29,1.26l2.47-2.55a5.41,5.41,0,0,0-4.05-9Z" />
    <path d="M13.41,15.63l-2.88,2.81a3.54,3.54,0,0,1-2.07.66,3.6,3.6,0,0,1-3.6-3.6,3.56,3.56,0,0,1,.88-2.36l2.43-2.51A.9.9,0,1,0,6.88,9.37L4.41,11.92a5.4,5.4,0,0,0,7.23,7.94l.1-.08,2.93-2.86a.9.9,0,1,0-1.26-1.29Z" />
  </svg>
);

export default Link;
