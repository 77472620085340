/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { Fragment, ReactNode } from "react";
import Select from "components/select/select";
import Badge, { BadgeValue } from "components/badge/badge";
import Caret from "components/icons/caret";
import { components as ReactSelect } from "react-select";
import classnames from "classnames/bind";
import styles from "./select-tabs.module.css";
import { IndicatorProps } from "react-select/lib/components/indicators";
import { OptionProps } from "react-select/lib/components/Option";
import { CommonProps } from "react-select/lib/types";

const cx = classnames.bind(styles);

interface DropdownOption {
  value: string;
  label: string;
  badge?: BadgeValue;
  totalCount?: string;
}

interface SelectTabsProps {
  options: Array<DropdownOption>;
  value: string;
  onChange: (tabSlug: string) => void;
}

const Option = (props: OptionProps<DropdownOption>) => {
  return (
    <ReactSelect.Option {...props}>
      <span
        aria-labelledby={`${props.data.label}`}
        className={cx("dropdown-option-label", { "is-selected": props.isSelected })}>
        {props.data.label}
      </span>
      <Badge
        classname="dropdown-option-badge"
        muted={!props.isSelected}
        value={props.data.totalCount || props.data.badge}
      />
    </ReactSelect.Option>
  );
};

const DropdownIndicator = (props: IndicatorProps<DropdownOption>) => {
  return (
    <div data-test-id="drop-down-indicator">
      {ReactSelect.DropdownIndicator && (
        <ReactSelect.DropdownIndicator {...props}>
          <Caret variant="down" color="var(--brand-3)" />
        </ReactSelect.DropdownIndicator>
      )}
    </div>
  );
};

type SingleValueProps = CommonProps<DropdownOption> & {
  // using custom SingleValueProps component as select types library requires children to be string
  isDisabled: boolean;
  children: ReactNode;
  data: DropdownOption;
  innerProps: any;
};

const SingleValue = (props: SingleValueProps) => {
  const selectedOption = props.options.find((option) => option.value === props.data.value);
  return (
    <Fragment>
      <span aria-labelledby={`${props.data.label}`} className={styles["select-label"]} data-test-id="select-label">
        {props.data.label}
      </span>
      <Badge
        classname="dropdown-option-badge"
        muted={false}
        value={selectedOption && (selectedOption.totalCount || selectedOption.badge)}
      />
    </Fragment>
  );
};

export const SelectTabs = ({ options, value, onChange }: SelectTabsProps) => {
  return (
    <Select
      options={options}
      components={{ Option, DropdownIndicator, SingleValue }}
      onChange={(item) => onChange(item!["value"])}
      getOptionLabel={(item) => item.label}
      getOptionValue={(item) => item.value}
      value={options.find((item) => item.value === value) || null}
      classname="select-tabs"
      hideError={true}
      isSearchable={false}
    />
  );
};
