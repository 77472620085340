/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import styles from "./loader-wrapper.module.css";

const cx = classnames.bind(styles);

const LoaderWrapper = ({ component: Component, className = "", children, loader, ...rest }) => {
  const { loading, error } = loader;
  if (loading || error) {
    return (
      <div className={cx("progress", className)} data-test-id="progress">
        <Component {...rest} loader={loader} />
      </div>
    );
  } else {
    return children;
  }
};

export default LoaderWrapper;
