/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";

import wrapPage from "containers/page/page";
import actions from "../actions";
import {
  uploadAvatarForNewUser,
  showAllRoles,
  updateSelectedRoles,
  setSelectedRolesForNewUser,
  setBanner,
  closeBanner
} from "../action-creators";
import ContentHeader from "../../../../components/content-header/content-header";
import { navigateFn } from "utils/routes.utils";
import TextField from "components/text-field/text-field";
import TextArea from "components/text-area/text-area";
import PasswordField from "components/password-field/password-field";
import AddUserHeader from "./header";
import Edit from "components/icons/edit";
import { USERS_NEW_PATH, USERS_NEW_PATH_ROLES } from "../routes";
import Button from "components/button/button";
import { Switch } from "components/switch/switch";
import Inspector from "components/inspector/inspector";
import Checkbox from "components/checkbox/checkbox";
import Chip from "components/chip/chip";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import Loader from "../loader";
import ErrorMessage from "components/error-message/error-message";
import Spinner from "components/spinner/spinner";

import styles from "./profile.module.css";

import { NewUser, PartialAppState, ValidationError, UserRole } from "../state";
import SocialLinks, { SocialLinkProvider } from "components/social-links/social-links";
import { LoaderState } from "behaviors/loader/state";

interface DispatchProps {
  saveNewUserData: (key: string, changes: string | {}) => void;
  uploadAvatarForNewUser: (files: File[]) => void;
  openAddRoleInspector: () => void;
  showAllRoles: () => void;
  closeInspector: () => void;
  updateRoles: (updatedRole: UserRole) => void;
  setSelectedRolesForNewUser: () => void;
  setDefaultRoleBanner: () => void;
  closeDefaultRoleBanner: () => void;
  updateCanLogin: (canLogin: boolean) => void;
}

interface StateProps {
  newUser: NewUser;
  errors: ValidationError | null;
  memberFields: Array<string>;
  roles: Array<UserRole>;
  loaderInspector: LoaderState;
  loadingAvatar: boolean;
  title: string;
  defaultRole: UserRole | null;
  hasAdminPermission: boolean;
  canAddAuthor: boolean;
  canLogin: boolean;
  showInspector: boolean;
  defaultSocialLinkProviders: Array<SocialLinkProvider>;
}

type Props = StateProps & DispatchProps;

class AddUserProfile extends React.Component<Props> {
  componentDidMount() {
    if (this.props.hasAdminPermission) {
      this.props.updateCanLogin(true);
      this.props.showAllRoles();
    } else {
      this.props.updateCanLogin(false);
      this.props.setDefaultRoleBanner();
    }
  }
  componentDidUpdate(prevProps: Props) {
    if (this.props.hasAdminPermission !== prevProps.hasAdminPermission) {
      if (this.props.hasAdminPermission) {
        this.props.updateCanLogin(true);
        this.props.showAllRoles();
        this.props.closeDefaultRoleBanner();
      } else {
        this.props.updateCanLogin(false);
        this.props.setDefaultRoleBanner();
      }
    }
  }
  componentWillUnmount() {
    this.props.closeDefaultRoleBanner();
    this.props.updateCanLogin(false);
  }

  render() {
    const {
      newUser,
      saveNewUserData,
      errors,
      memberFields,
      uploadAvatarForNewUser,
      openAddRoleInspector,
      roles,
      updateRoles,
      setSelectedRolesForNewUser,
      closeInspector,
      loaderInspector,
      loadingAvatar,
      defaultRole,
      canLogin,
      updateCanLogin,
      hasAdminPermission,
      showInspector,
      defaultSocialLinkProviders
    } = this.props;
    return (
      <div>
        <main className={styles["add-user-page-container"]}>
          <ContentHeader title={t("users-and-roles.add_user")} />
          <div data-test-id="user-profile-page" className={styles["user-profile-page"]}>
            <div className={styles["add-user-image-container"]}>
              {loadingAvatar ? (
                <div className={styles["avatar-loader-container"]}>
                  <Spinner />
                </div>
              ) : (
                <span className={styles["add-avatar-image"]}>
                  {newUser["avatar-url"] && (
                    <img
                      className={styles["add-user-profile-image"]}
                      src={newUser["avatar-url"]}
                      alt={newUser["avatar-url"]}
                    />
                  )}
                </span>
              )}
              <input
                type="file"
                id="upload-avatar"
                className={styles["upload-input"]}
                accept="image/*"
                onChange={(e) => e.target.files && uploadAvatarForNewUser(Array.from(e.target.files))}
                disabled={!hasAdminPermission && !defaultRole ? true : false}
              />
              <label htmlFor="upload-avatar">
                <span className={styles["user-image-edit-icon"]}>
                  <Edit width={16} height={16} color="#4860bc" />
                </span>
              </label>
            </div>
            <div className={styles["add-user-form-container"]}>
              <div className={styles["add-user-common-fields-container"]}>
                <TextField
                  label={t("users-and-roles.fields.name")}
                  value={newUser.name}
                  onChange={(value) => saveNewUserData("name", value)}
                  errorMessage={errors ? errors.name && errors.name[0] : ""}
                  readOnly={!hasAdminPermission && !defaultRole}
                />
                <TextField
                  label={t("users-and-roles.add-user-profile-fields.email")}
                  value={newUser.email}
                  onChange={(value) => saveNewUserData("email", value && value.trim())}
                  errorMessage={(errors && errors.email && errors.email[0]) || ""}
                  readOnly={!hasAdminPermission && !defaultRole}
                />
                <TextField
                  label={t("users-and-roles.fields.communication_email")}
                  value={newUser["communication-email"]}
                  onChange={(value) => saveNewUserData("communication-email", value && value.trim())}
                  errorMessage={errors && errors["communication-email"]}
                  readOnly={!hasAdminPermission && !defaultRole}
                />
                <TextArea
                  label={t("users-and-roles.fields.bio")}
                  value={newUser["bio"] || ""}
                  onChange={(value) => saveNewUserData("bio", value)}
                  disabled={!hasAdminPermission && !defaultRole}
                />
                <div className={styles["add-user-can-login-toogle"]}>
                  <span>{t("users-and-roles.enable_login")}</span>
                  <Switch
                    id="can-login"
                    onChange={() => updateCanLogin(!canLogin)}
                    checked={canLogin}
                    disabled={!hasAdminPermission}
                  />
                </div>
                {canLogin && (
                  <React.Fragment>
                    <TextField
                      label={t("users-and-roles.add-user-profile-fields.username")}
                      value={newUser.username}
                      onChange={(value) => saveNewUserData("username", value)}
                      errorMessage={(errors && errors.username && errors.username[0]) || ""}
                    />
                    <PasswordField
                      label={t("users-and-roles.fields.password")}
                      onChange={(value) => saveNewUserData("password", value)}
                      errorMessage={(errors && errors.password && errors.password[0]) || ""}
                    />
                  </React.Fragment>
                )}
              </div>
              <div className={styles["add-user-roles-container"]} data-test-id="user-roles-container">
                {t("users-and-roles.roles")}
                <div className={styles["user-selected-roles"]}>
                  {newUser.roles &&
                    newUser.roles.map((role) => (
                      <Chip key={`role${role.id}`} value={role.name} invert classname={"edit-user-chip"} />
                    ))}
                </div>
                <ErrorMessage message={errors && errors.roles && t("users-and-roles.mandatory-role-message")} />
                {hasAdminPermission ? (
                  <Button type="default" onClick={() => openAddRoleInspector()} classname="user-roles-container-button">
                    {t("users-and-roles.add_user_roles")}
                  </Button>
                ) : (
                  <React.Fragment>
                    {defaultRole ? (
                      <Chip key={`role${defaultRole.id}`} value={defaultRole.name} invert />
                    ) : (
                      <ErrorMessage message={t("users-and-roles.default-role-message")} />
                    )}
                  </React.Fragment>
                )}
              </div>
              <div className={styles["add-user-custom-fields-and-social-container"]}>
                {memberFields && (
                  <h4 className={styles["custom-fields-header"]}>{t("users-and-roles.other-details")}</h4>
                )}
                {memberFields &&
                  memberFields.map((field) => (
                    <TextField
                      label={field}
                      value={newUser.metadata[field]}
                      onChange={(value) => saveNewUserData("metadata", { ...newUser.metadata, [field]: value })}
                    />
                  ))}
                <SocialLinks
                  defaultSocialLinkProviders={defaultSocialLinkProviders}
                  socialLinks={newUser.social}
                  updateSocialLinks={(socialLinks) => saveNewUserData("social", socialLinks)}
                  labelAdd={t("users-and-roles.add_social_link")}
                  haveHandleField={true}
                />
              </div>
            </div>
          </div>
          <Inspector
            title={t("users-and-roles.add_role")}
            isActive={showInspector}
            actionButtonLabel={t("users-and-roles.done_button")}
            onActionButtonClick={() => setSelectedRolesForNewUser()}
            onClose={() => closeInspector()}>
            <LoaderWrapper component={Loader} loader={loaderInspector}>
              <div className={styles["roles-list-container"]}>
                {roles &&
                  roles.map((role) => (
                    <Checkbox
                      id={`roles-checkbox-${role.id}`}
                      label={role.name}
                      checked={role.checked}
                      onChange={(value) => updateRoles({ ...role, checked: value })}
                    />
                  ))}
              </div>
            </LoaderWrapper>
          </Inspector>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state: PartialAppState, ownProps: { showInspector: boolean }) => {
  const usersPage = state.usersAndRoles.usersPage;

  return {
    newUser: usersPage.newUser,
    errors: usersPage.ui.errors,
    memberFields: state.config["member-fields"],
    roles: usersPage.ui.allRoles,
    loaderInspector: usersPage.ui.inspector,
    loadingAvatar: usersPage.ui.loadingAvatar,
    title: "add_user",
    defaultRole: state.config.defaultRole,
    hasAdminPermission: state.features.hasAdminPermission,
    canAddAuthor: state.features.canAddAuthor,
    canLogin: usersPage.newUser["can-login"],
    showInspector: ownProps.showInspector,
    defaultSocialLinkProviders: state.config["default-social-link-providers"] || []
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
  const navigate = navigateFn(dispatch);
  return {
    navigate,
    saveNewUserData: (key: string, changes: string | {}) =>
      dispatch({ type: actions.SAVE_NEW_USER_DATA, payload: { key, changes } }),
    uploadAvatarForNewUser: (files: File[]) => dispatch(uploadAvatarForNewUser(files)),
    openAddRoleInspector: () => {
      navigate(USERS_NEW_PATH_ROLES);
    },
    showAllRoles: () => dispatch(showAllRoles()),
    closeInspector: () => navigate(USERS_NEW_PATH),
    updateRoles: (updatedRole: UserRole) => dispatch(updateSelectedRoles(updatedRole)),
    setSelectedRolesForNewUser: () => dispatch(setSelectedRolesForNewUser()),
    setDefaultRoleBanner: () => dispatch(setBanner(t("users-and-roles.default-role-message"))),
    closeDefaultRoleBanner: () => dispatch(closeBanner()),
    updateCanLogin: (canLogin: boolean) =>
      dispatch({ type: actions.UPDATE_CAN_LOGIN_FOR_NEW_USER, payload: { canLogin } })
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  wrapPage({ HeaderComponent: AddUserHeader })
)(AddUserProfile);

export { AddUserProfile };
