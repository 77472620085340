/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";

import { Top } from "api/analytics";
import TopList from "../top-list/top-list";
import { DIMENSION } from "pages/analytics/constants";
import { CurrentFilter } from "pages/analytics/state";

interface Props {
  metrics: string;
  data: Top[];
  currentFilter: CurrentFilter;
}

const TopStories: React.SFC<Props> = ({ data, metrics, currentFilter }) => {
  return (
    <TopList
      dimension={DIMENSION.STORY}
      metrics={metrics}
      list={data}
      showDelta={currentFilter.dateRange.name !== "custom"}
    />
  );
};

export default connect()(TopStories);

export { TopStories };
