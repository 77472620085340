/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import * as actions from "../../actions";
import Button from "components/button/button";
import { generateSelectOptions } from "helpers/select";
import Inspector from "components/inspector/inspector";
import Select from "components/select/select";
import { t } from "i18n";
import styles from "pages/workspace/components/filters-form/filters-form.module.css";
import FieldLabel from "components/field-label/field-label";
import ConfirmationModal from "../../../../components/confirmation-modal/confirmation-modal";

const BulkActionInspector = ({
  showBulkActionInspector,
  onClose,
  isAccessLevelEnabled,
  accessLevels,
  handleAccessLevelValueChange,
  applyAccessLevel,
  bulkSelectedStoryIds,
  allStoriesSelected
}) => {
  const [isAccessLevelSelected, setIsAccessLevelSelected] = useState(false);
  const [showAccessLevelUpdateModal, setShowAccessLevelUpdateModal] = useState(false);

  const showAccessLevelApplyButton = (!isEmpty(bulkSelectedStoryIds) || allStoriesSelected) && isAccessLevelSelected;

  const onAccessLevelUpdateConfirm = () => {
    setShowAccessLevelUpdateModal(false);
    applyAccessLevel();
  };

  const onAccessLevelChange = (accessLevel) => {
    setIsAccessLevelSelected(true);
    handleAccessLevelValueChange(accessLevel);
  };

  const onApplyAccessLevelClick = () => {
    allStoriesSelected ? setShowAccessLevelUpdateModal(true) : applyAccessLevel();
  };

  return (
    <>
      <Inspector
        title={t("workspace.bulk_action_inspector.bulk_action")}
        isActive={showBulkActionInspector}
        onClose={onClose}>
        {isAccessLevelEnabled && (
          <div className={styles["filters-form"]}>
            <FieldLabel label={t("workspace.bulk_action_inspector.set_access_level_value")} />
            <div className={styles["filter-access-level"]}>
              <Select options={generateSelectOptions(accessLevels)} onChange={onAccessLevelChange} />
              <div>
                <Button type="primary" onClick={onApplyAccessLevelClick} disabled={!showAccessLevelApplyButton}>
                  {t("workspace.bulk_action_inspector.apply")}
                </Button>
              </div>
            </div>
          </div>
        )}
      </Inspector>
      <ConfirmationModal
        showConfirmationModal={showAccessLevelUpdateModal}
        headerText={t("workspace.bulk_action_inspector.access-level-update-modal-header")}
        text={t("workspace.bulk_action_inspector.access-level-update-modal-text")}
        confirmButtonLabel={t("common.confirm")}
        cancelButtonLabel={t("common.cancel")}
        onConfirm={onAccessLevelUpdateConfirm}
        onCancel={() => setShowAccessLevelUpdateModal(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAccessLevelEnabled: state.features.accessLevelValue,
    accessLevels: state.config.accessLevels,
    bulkSelectedStoryIds: state.workspace.bulkSelectedStoryIds,
    allStoriesSelected: state.workspace.allStoriesSelected
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleAccessLevelValueChange: ({ value }) =>
      dispatch({ type: actions.WORKSPACE_UPDATE_ACCESS_LEVEL_VALUE, bulkAccessLevelValue: value })
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(BulkActionInspector);

export { BulkActionInspector };
