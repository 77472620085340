/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { newCard } from "./card";
import newStoryElement from "./story-elements";
import { Card, StoryElementTemplate, StoryElement } from "api/story";
import { getDistinctStoryElements } from "pages/story-editor/utils";

export function getTemplateElements(card: Card, storyElements: Array<StoryElementTemplate>) {
  return storyElements.map((storyElement) => {
    let requiredType = storyElement.subtype ? storyElement.subtype : storyElement.type;
    return newStoryElement(card, requiredType);
  });
}

export default function getTemplateCardAndElements(
  card: Card,
  storyElements: Array<StoryElementTemplate>
): {
  card: Card;
  storyElements: Array<StoryElement>;
} {
  const modifiedCard = card["card-type"] ? { ...newCard(), "card-type": card["card-type"] } : newCard();
  const modifiedStoryElements = getTemplateElements(modifiedCard, storyElements);
  const storyElementIds = modifiedStoryElements.map((element) => element.id);
  return {
    card: {
      ...modifiedCard,
      tree: getDistinctStoryElements([...modifiedCard.tree, ...storyElementIds])
    },
    storyElements: modifiedStoryElements as Array<StoryElement>
  };
}
