/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Spinner from "components/spinner/spinner";
import Plus from "components/icons/plus";
import Button from "components/button/button";
import Close from "components/icons/close";
import { LoaderState } from "behaviors/loader/state";
import { TimeoutError } from "api/errors";
import { IntegrationsNotFoundError } from "./errors";
import { t } from "i18n";

interface LoaderProps {
  loader: LoaderState;
  loadMenuItems: () => void;
}

const Loader: React.SFC<LoaderProps> = ({ loader: { loading, error }, loadMenuItems }) => {
  if (loading) {
    return <Spinner message={t("integrations.loading")} />;
  }
  if (error) {
    if (error instanceof TimeoutError) {
      return (
        <React.Fragment>
          <Plus width="48" height="48" />
          <p>could not load integrations</p>
          <p>
            <Button onClick={loadMenuItems}>{t("menu-items.cta.load-retry")}</Button>
          </p>
        </React.Fragment>
      );
    }

    if (error instanceof IntegrationsNotFoundError) {
      return (
        <React.Fragment>
          <Close width="48" height="48" />
          <p>Could not found integrations</p>
          {/* <p>{t("menu-items.messages.not-found", { id: error.id })}</p> */}
        </React.Fragment>
      );
    }
  }

  return <p>{t("menu-items.messages.unknown-error")}</p>;
};

export default Loader;
