/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Analytics = ({ width = "24", height = "24", color = "currentColor" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox="0 0 24 24">
    <defs>
      <path
        id="a-analytics"
        d="M2 3h1.176v17.076H21.96v1.192H2V3zm8.922 8.174l-4.615 5.173-.871-.8 5.296-5.938 4.037 2.742 4.608-5.396.89.78-5.289 6.194-4.056-2.755z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b-analytics" fill={color}>
        <use xlinkHref="#a-analytics" />
      </mask>
      <use fill={color} xlinkHref="#a-analytics" />
      <path d="M0 0h24v24H0z" mask="url(#b-analytics)" />
      <g fill={color} mask="url(#b-analytics)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

export default Analytics;
