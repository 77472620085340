/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { AnyAction, compose } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import Inspector from "components/inspector/inspector";
import TextField from "components/text-field/text-field";
import { PartialAppState, SectionPageInspectorType, TabList } from "../../state";
import { AnySection, SectionTree, Section } from "api/section";
import { WatermarkImage } from "api/watermark-images";
import { cancelSaveSection, saveSection, setGeneratedSlug } from "../../async-action-creators";
import { replaceCurrentSectionAction, setCurrentTab } from "../../action-creators";
import { setWatermarkImage } from "store/watermark-images";
import { isExistingSection, findSectionInTree } from "../../utils";
import Select from "components/select/select";
import Breadcrumbs from "components/breadcrumbs/breadcrumbs";
import { isClientValidationError } from "utils/validation.utils";
import { flatten, get } from "lodash";
import CategoryTitle from "components/category-title/category-title";
import ColorField from "components/color-field/color-field";
import { FixedTabList } from "components/tabs/tabs";
import ImageField from "components/image-field/image-field";
import MediaGallery from "pages/media-library/components/media-gallery/media-gallery";

import styles from "./inspector.module.css";
import { AnyImage } from "api/search-media-image";
import { Switch } from "components/switch/switch";
import FieldLabel from "components/field-label/field-label";

interface StateProps {
  type: SectionPageInspectorType | null;
  section: AnySection | null;
  sections: SectionTree[];
  saveError: Error | null;
  isSubsectionEnabled: boolean;
  currentTab: TabList;
  watermarkImage: WatermarkImage;
  enableWatermarkImages: boolean;
}

interface DispatchProps {
  saveSection: (section: AnySection) => void;
  cancelSaveSection: (section: AnySection) => void;
  onChange: (section: AnySection) => void;
  setSlug: (section: AnySection) => void;
  setCurrentTab: (tab: TabList) => void;
  setWatermarkImage: (image: AnyImage) => void;
  deleteWatermarkImage: () => void;
}

type Props = StateProps & DispatchProps;

const getParentSections = (
  allSections: SectionTree[],
  type: SectionPageInspectorType | null,
  currentSection: AnySection | null
) => {
  const activeSections = allSections.filter((section) => section["deleted-at"] === null);
  const childSections = flatten(activeSections.map((section) => section.children));
  const activeChildSections = childSections.filter((section) => section["deleted-at"] === null);
  const allParentSections = [...activeSections, ...activeChildSections];
  const allParentSectionsExceptCurrentSection =
    type === SectionPageInspectorType.Create
      ? allParentSections
      : allParentSections.filter((section) => section.slug !== (currentSection && currentSection.slug));
  return allParentSectionsExceptCurrentSection;
};

function getTabOptions() {
  return Object.keys(TabList).map((k) => ({ label: k, value: TabList[k] }));
}

const SectionInspector: React.SFC<Props> = ({
  section,
  setSlug,
  onChange,
  saveSection,
  cancelSaveSection,
  type,
  sections,
  saveError,
  isSubsectionEnabled,
  currentTab,
  setCurrentTab,
  watermarkImage,
  setWatermarkImage,
  enableWatermarkImages,
  deleteWatermarkImage
}) => {
  const title =
      type === SectionPageInspectorType.Create
        ? t("sections.inspector.create-title")
        : t("sections.inspector.update-title"),
    actionButtonLabel = type === SectionPageInspectorType.Create ? t("sections.cta.create") : t("sections.cta.update");

  let [replaceWatermarkImage, setReplaceWatermarkImage] = React.useState(false);
  const isSectionDisabled = get(section, ["data", "isDisabled"], false);
  return (
    <Inspector
      title={title}
      onClose={() => {
        cancelSaveSection(section!);
        setReplaceWatermarkImage(false);
        setCurrentTab(TabList.Details);
      }}
      isActive={!!type}
      actionButtonLabel={actionButtonLabel}
      onActionButtonClick={() => {
        saveSection(section!);
        setCurrentTab(TabList.Details);
      }}
      variant={currentTab === TabList.Details ? "default" : "wide"}>
      {enableWatermarkImages && (
        <FixedTabList
          className={styles["tab-list"]}
          tabs={getTabOptions()}
          value={currentTab}
          onChange={setCurrentTab}
        />
      )}
      {currentTab === TabList.Details && section && (
        <React.Fragment>
          <TextField
            value={section.name}
            label={t("sections.inspector.name-label")}
            onBlur={() => setSlug(section)}
            onChange={(name) =>
              onChange({
                ...section,
                name
              })
            }
            hint={t("sections.inspector.name-hint")}
            errorMessage={
              isClientValidationError<AnySection>(saveError)
                ? saveError.errors.name && saveError.errors.name[0]
                : undefined
            }
          />
          <TextField
            value={section["display-name"] || ""}
            label={t("sections.inspector.display-name-label")}
            onChange={(displayName) => onChange({ ...section, "display-name": displayName })}
            hint={t("sections.inspector.display-name-hint")}
          />
          <TextField
            value={section["slug"]}
            readOnly={isExistingSection(section)}
            label={t("sections.inspector.slug-label")}
            onChange={(slug) => (isExistingSection(section) ? undefined : onChange({ ...section, slug: slug }))}
            onBlur={() => setSlug(section)}
            hint={t("sections.inspector.slug-hint")}
            errorMessage={
              isClientValidationError<AnySection>(saveError)
                ? saveError.errors.slug && saveError.errors.slug[0]
                : undefined
            }
          />
          {isSubsectionEnabled && (
            <Select
              name="sections"
              label={t("sections.inspector.parent-id-label")}
              value={section["parent-id"] ? findSectionInTree(section["parent-id"], sections) : null}
              options={sections}
              onChange={(_section) =>
                !Array.isArray(_section) &&
                onChange({ ...section, "parent-id": _section && isExistingSection(_section) ? _section.id : null })
              }
              getOptionLabel={(section: Section) => section.name}
              getOptionValue={(section: Section) => section.id.toString()}
              formatOptionLabel={(section: Section) => (
                <React.Fragment>
                  {section.name}
                  <Breadcrumbs id={section.id} crumbs={sections} getCrumbLabel={(section) => section.name} />
                </React.Fragment>
              )}
              isClearable={true}
            />
          )}
          <ColorField
            value={section.data && section.data.color ? section.data.color : ""}
            label={t("sections.inspector.color-label")}
            onChange={(color) => onChange({ ...section, data: { ...section.data, color } })}
            hint={t("sections.inspector.color-hint")}
            errorMessage={
              isClientValidationError<AnySection>(saveError)
                ? saveError.errors.data && saveError.errors.data["color"] && saveError.errors.data["color"][0]
                : undefined
            }
          />

          <CategoryTitle title={t("sections.inspector.seo")} />
          <TextField
            label={t("sections.inspector.page-title")}
            value={(section["seo-metadata"] && section["seo-metadata"]["page-title"]) || ""}
            onChange={(value) =>
              onChange({ ...section, "seo-metadata": { ...section["seo-metadata"], "page-title": value } })
            }
          />
          <TextField
            label={t("sections.inspector.meta-title")}
            value={(section["seo-metadata"] && section["seo-metadata"]["title"]) || ""}
            onChange={(value) => onChange({ ...section, "seo-metadata": { ...section["seo-metadata"], title: value } })}
          />
          <TextField
            label={t("sections.inspector.description")}
            value={(section["seo-metadata"] && section["seo-metadata"].description) || ""}
            onChange={(value) =>
              onChange({ ...section, "seo-metadata": { ...section["seo-metadata"], description: value } })
            }
          />
          <TextField
            label={t("sections.inspector.keywords-label")}
            hint={t("sections.inspector.keywords-hint")}
            value={(section["seo-metadata"] && section["seo-metadata"].keywords) || ""}
            onChange={(value) =>
              onChange({ ...section, "seo-metadata": { ...section["seo-metadata"], keywords: value } })
            }
          />
        </React.Fragment>
      )}
      {currentTab === TabList.Watermark &&
        section &&
        (!replaceWatermarkImage && watermarkImage.image ? (
          <ImageField
            classname={styles["image-upload-container"]}
            value={watermarkImage.image}
            isPhotoEditor={false}
            openPhotoEditor={() => null}
            openGallery={() => null}
            onDelete={() => deleteWatermarkImage()}
            onReplace={() => setReplaceWatermarkImage(true)}
          />
        ) : (
          <MediaGallery
            showSelectMediaProvider={true}
            setSelectedMedia={(images: AnyImage[]) => {
              setWatermarkImage(images[0]);
              setReplaceWatermarkImage(false);
            }}
            scrollableTarget="inspector-container"
          />
        ))}
      {/* Disable/Reject Section toggle */}
      {section && (
        <div className={styles["disable-section-container"]}>
          <div>
            <FieldLabel label={t("sections.inspector.disable-section-label")} />
            <span className={styles["disable-section-hint"]}>{t("sections.inspector.disable-section-hint")}</span>
          </div>
          <Switch
            id="disable-section-toogle"
            checked={isSectionDisabled}
            onChange={() => onChange({ ...section, data: { ...section.data, isDisabled: !isSectionDisabled } })}
          />
        </div>
      )}
    </Inspector>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  const sections = getParentSections(
    state.sections.sections,
    state.sections.ui.inspector,
    state.sections.app.currentSection
  );
  return {
    section: state.sections.app.currentSection,
    sections,
    type: state.sections.ui.inspector,
    saveError: state.sections.ui.save.error,
    isSubsectionEnabled: state.features.isSubsectionEnabled,
    currentTab: state.sections.ui.currentTab,
    watermarkImage: state.watermarkImage.data,
    enableWatermarkImages: state.features.enableWatermarkImages
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    saveSection: (section: AnySection) => dispatch(saveSection(section)),
    cancelSaveSection: (section: AnySection) => dispatch(cancelSaveSection(section)),
    onChange: (section: AnySection) => dispatch(replaceCurrentSectionAction(section)),
    setSlug: (section: Section) => dispatch(setGeneratedSlug(section)),
    setCurrentTab: (tab: TabList) => dispatch(setCurrentTab(tab)),
    setWatermarkImage: (image: AnyImage) => dispatch(setWatermarkImage({ image })),
    deleteWatermarkImage: () => dispatch(setWatermarkImage({ image: null }))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SectionInspector);

export { SectionInspector };
