/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { CollectionId, PublisherId, SectionId, Timestamp } from "./primitive-types";

export interface SectionData {
  color?: string;
  isDisabled?: boolean;
}

export interface Seo {
  "page-title"?: string;
  title?: string;
  description?: string;
  keywords?: string;
}
export interface Section {
  id: SectionId;
  "publisher-id": PublisherId;

  name: string;
  "display-name": string | null;
  slug: string;
  data: SectionData | null;

  "parent-id": SectionId | null;
  "ancestor-ids": SectionId[];
  "tree-ids": SectionId[];

  "collection-id": CollectionId | null;

  "deleted-at": Timestamp | null;
  "updated-at": Timestamp;

  "seo-metadata": Seo | null;
}

export interface SectionTree extends Section {
  children: SectionTree[];
}

export type UnsavedSection = Pick<Section, "name" | "slug" | "display-name" | "parent-id" | "data" | "seo-metadata">;

export type AnySection = Section | UnsavedSection;

export const getSectionsList = (includeDeleted: boolean = false): Promise<Array<Section>> => {
  return client
    .url("/api/section")
    .query({ "include-deleted": includeDeleted })
    .get()
    .json();
};

export const getSectionsTree = (includeDeleted: boolean = false): Promise<Array<SectionTree>> => {
  return client
    .url("/api/section")
    .query({ "include-deleted": includeDeleted, format: "tree" })
    .get()
    .json();
};

interface SectionSaveResponse {
  section: Section;
}

export const saveNewSection = (section: UnsavedSection): Promise<SectionSaveResponse> => {
  return client
    .url("/api/section")
    .json({ section })
    .post()
    .json();
};

export const updateSection = (id: SectionId, section: UnsavedSection): Promise<SectionSaveResponse> => {
  return client
    .url("/api/section")
    .json({ section: { ...section, id } })
    .post()
    .json();
};

export const deleteSection = (section: Section): Promise<void> => {
  return client
    .url(`/api/section/${section.id}`)
    .delete()
    .res();
};

export const undeleteSection = (section: Section): Promise<void> => {
  return client
    .url(`/api/section/${section.id}/undelete`)
    .post()
    .res();
};
