/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { getStylesForOverlay, translateOverlayPointToFocusPoint, Dimension, Coordinate } from "./utils";
import { getPosition } from "../../image-utils";
import { getMetadataWithDimensions } from "utils/image-metadata.utils";

import { Image, ImageMetadata } from "api/search-media-image";
import styles from "./focus-point.module.css";

interface containerDimensions {
  containerWidth: number;
  containerHeight: number;
}

const setFocusPoint = (
  updateImageData: (key: string, value: any, index: number) => void,
  imageIndex: number,
  metadata: ImageMetadata,
  point: Coordinate,
  containerDimensions: containerDimensions
) => {
  const imageDimensions = {
    width: metadata.width,
    height: metadata.height
  };

  const { containerWidth, containerHeight } = containerDimensions;

  const overlayDimensions = getStylesForOverlay(metadata, containerWidth, containerHeight).dimensions;

  const { x, y } = translateOverlayPointToFocusPoint(overlayDimensions, imageDimensions, point);

  if (0 <= x && x < metadata.width && 0 <= y && y < metadata.height) {
    const newMetadata = { ...metadata, "focus-point": [x, y] };
    updateImageData("metadata", newMetadata, imageIndex);
  }
};

const setSelectedFocusPoint = (
  e: React.MouseEvent<HTMLDivElement>,
  image: Image,
  imageIndex: number,
  updateImageData: (key: string, value: any, index: number) => void,
  containerDimensions: containerDimensions
) => {
  var parentPosition = getPosition(e.target as HTMLDivElement);

  const point = {
    x: e.clientX - parentPosition.x,
    y: e.clientY - parentPosition.y
  };

  getMetadataWithDimensions(image).then((metadata) => {
    setFocusPoint(updateImageData, imageIndex, metadata, point, containerDimensions);
  });
};

const setDefaultFocusPoint = (
  metadata: ImageMetadata,
  overlayDimensions: Dimension,
  updateImageData: (key: string, value: any, index: number) => void,
  imageIndex: number,
  containerDimensions: containerDimensions
) => {
  const point = {
    x: overlayDimensions.width / 2,
    y: overlayDimensions.height / 2
  };

  setFocusPoint(updateImageData, imageIndex, metadata, point, containerDimensions);
};

const removeFocusPoint = (
  event: React.MouseEvent,
  imageMetadata: ImageMetadata,
  updateImageData: (key: string, value: any, index: number) => void,
  imageIndex: number
) => {
  event.stopPropagation();
  const { "focus-point": removed, ...metadata } = imageMetadata;
  updateImageData("metadata", metadata, imageIndex);
};

interface FocusPointProps {
  x: number;
  y: number;
}

const FocusPoint: React.FC<FocusPointProps> = ({ x, y }) => {
  const positionLeft = x,
    positionTop = y;

  return <span className={styles["image-focus-point-crosshair"]} style={{ top: positionTop, left: positionLeft }} />;
};

export { setSelectedFocusPoint, setDefaultFocusPoint, removeFocusPoint, FocusPoint };
