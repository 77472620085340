/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import forEach from "lodash/forEach";

import styles from "./preview.module.css";
import { STORY_EDITOR_PATH } from "pages/story-editor/routes";
import { navigate } from "utils/routes.utils";

// import { actions } from "./actions";
import { t } from "i18n";
import Close from "components/icons/close";
import AddNewMenuWithCustomActions from "components/add-new-menu/add-new-menu-with-custom-options";
import classnames from "classnames/bind";
import { get } from "lodash";

const cx = classnames.bind(styles);

function convertToPublishedJson(story) {
  const now = new Date().getTime(),
    cards = story.tree.map((treeCard) => story.cards[treeCard["content-id"]]),
    sections = story.sections && story.sections.length ? story.sections : [{ id: 0, name: "No Section" }];

  return {
    ...story,
    cards,
    sections,
    "published-at": story["published-at"] || now,
    "first-published-at": story["first-published-at"] || now,
    "last-published-at": story["last-published-at"] || now
  };
}

function expandStoryElementImages(storyElement) {
  if (storyElement.type === "image" && storyElement.image) {
    //Only when image is uploaded
    return {
      ...storyElement,
      "image-url": storyElement.image.url,
      title: storyElement.image.caption,
      "image-attribution": storyElement.image.attribution,
      "alt-text": storyElement.image["alt-text"],
      "image-metadata": storyElement.image.metadata,
      ...(storyElement.image.key ? { "image-s3-key": storyElement.image.key } : {}),
      ...(storyElement.image["temp-key"] ? { "image-s3-key": storyElement.image["temp-key"] } : {})
    };
  } else {
    return storyElement;
  }
}

function addStoryElementsToCards(card, story) {
  return {
    ...card,
    "story-elements": card.tree.map((storyElementId) => {
      if (story["story-elements"][storyElementId].tree) {
        return {
          ...story["story-elements"][storyElementId],
          "story-elements": story["story-elements"][storyElementId].tree.map((storyElementTreeId) =>
            expandStoryElementImages(story["story-elements"][storyElementTreeId])
          )
        };
      } else {
        return expandStoryElementImages(story["story-elements"][storyElementId]);
      }
    })
  };
}

function convertItsmanXStoryToNormalStory(story) {
  const storyWithHeroImage = story["hero-image"]
    ? {
        ...story,
        "hero-image-url": story["hero-image"].url,
        "hero-image-caption": story["hero-image"].caption,
        "hero-image-attribution": story["hero-image"].attribution,
        "hero-image-alt-text": story["hero-image"]["alt-text"],
        "hero-image-metadata": story["hero-image"].metadata,
        ...(story["hero-image"]["temp-key"] ? { "temporary-hero-image-key": story["hero-image"]["temp-key"] } : {}),
        ...(story["hero-image"].key ? { "hero-image-s3-key": story["hero-image"].key } : {})
      }
    : story;

  forEach(storyWithHeroImage.cards, (value, key) => {
    storyWithHeroImage.cards[key] = addStoryElementsToCards(value, storyWithHeroImage);
  });
  return convertToPublishedJson(storyWithHeroImage);
}

class Preview extends React.Component<any, any> {
  _intervals: NodeJS.Timeout[];
  iframe: React.RefObject<HTMLIFrameElement>;
  constructor(props) {
    super(props);
    this.state = {
      currentPreview: props.initialPreviewType || "story"
    };

    this._intervals = [];
    this.iframe = React.createRef();
  }

  componentDidMount() {
    const iFrame = this.iframe.current;
    if (iFrame) {
      iFrame.onload = this.sendStoryToIframe;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const iFrame = this.iframe.current;
    if (iFrame && iFrame.onload) {
      this.sendStoryToIframe();
    } else if (iFrame) {
      iFrame.onload = this.sendStoryToIframe;
    }
  }

  sendStoryToIframe = () => {
    const data = {
      story: convertItsmanXStoryToNormalStory(this.props.story),
      action: "reloadStory"
    };
    if (this.iframe.current && this.iframe.current.contentWindow) {
      this.iframe.current.contentWindow.postMessage(data, this.props.previewBase);
    }
  };

  render() {
    const { hidePreviewMenu, closePreview, bannerMessage, isSliderWindowOpen } = this.props;
    const alternatePreview = this.state.currentPreview === "story" ? "home" : "story";
    return (
      <div
        className={cx("story-preview-container", {
          "with-banner": bannerMessage,
          "with-slider-open": isSliderWindowOpen
        })}
        data-test-id="story-preview-container">
        {!hidePreviewMenu && (
          <div className={styles["story-preview-menu"]} data-test-id="story-preview-menu">
            <div
              className={styles["story-preview-close"]}
              data-test-id="story-preview-close-btn"
              onClick={() => closePreview(this.props.story["story-content-id"])}>
              <Close />
            </div>

            <AddNewMenuWithCustomActions
              title={t("story-editor.header.preview-dropdown")}
              labelPadding={20}
              actions={[
                {
                  title: t(`story-editor.header.preview-switch-${alternatePreview}`),
                  callback: () => this.setState({ currentPreview: alternatePreview })
                },
                {
                  title: t("story-editor.header.preview-desktop-web"),
                  callback: () => this.loadDesktopPreviewPath("story")
                },
                {
                  title: t("story-editor.header.preview-desktop-home"),
                  callback: () => this.loadDesktopPreviewPath("home")
                }
              ]}
            />
          </div>
        )}
        <iframe
          data-test-id="preview-iframe"
          title="Preview"
          src={`${this.props.previewBase}/preview/${this.state.currentPreview}`}
          width="100%"
          height="100%"
          ref={this.iframe}
        />
      </div>
    );
  }

  loadDesktopPreviewPath(previewType) {
    const newWindow: any = window.open(`${this.props.previewBase}/preview/${previewType}`, "_blank");
    const loadInterval: NodeJS.Timeout = setInterval(() => {
      const data = {
        story: convertItsmanXStoryToNormalStory(this.props.story),
        action: "reloadStory"
      };
      newWindow && newWindow.postMessage(data, this.props.previewBase);
    }, 250);
    this._intervals.push(loadInterval);
  }

  componentWillUnmount() {
    this._intervals.forEach((interval) => clearInterval(interval));
  }
}

const mapStateToProps = (state) => {
  return {
    story: state.storyEditor.story,
    previewBase: state.config.publisher["website-url"],
    bannerMessage:
      state.config.publisherWideBannerMessage || (state.storyEditor.ui.banner && state.storyEditor.ui.banner.message),
    isSliderWindowOpen: get(state, ["slider", "sliderWindow", "open"], false)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closePreview: (id) => dispatch(navigate(STORY_EDITOR_PATH, { id }, {}))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Preview);
