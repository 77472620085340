/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import AsyncSelect from "components/select/async";
import debounce from "p-debounce";
import { t } from "i18n";
import { searchAuthors } from "api/author";

const loadAuthorsData = debounce(searchAuthors, 250);

const AuthorFilter = ({ value, onChange }) => (
  <AsyncSelect
    label={t("workspace.filters_inspector.author")}
    value={value && value.length && value}
    onChange={(author) => onChange(author)}
    getOptionLabel={(author) => author.name}
    getOptionValue={(author) => author.id}
    loadOptions={loadAuthorsData}
    isMulti={true}
    defaultOptions={true}
  />
);

export default AuthorFilter;
