/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import Inspector from "components/inspector/inspector";
import { get } from "lodash";
import DatePickerCustom from "components/date-picker/date-picker";
import { t } from "i18n";
import styles from "./publish-inspector.module.css";
import RadioButton from "components/radio-button/radio-button";
import { cancelPublishAction, publishCollectionAction } from "pages/collections/async-action-creators";
import { format as formatDate, isAfter } from "date-fns";
import {
  updateStagingSelectedCollectionAction,
  updateSelectedCollectionAction,
  setScheduleCollectionDateErrorAction,
  removeFieldErrorAction
} from "../../action-creators";
import { PartialAppState } from "pages/collections/initial-state";
import { ThunkDispatch } from "redux-thunk";
import { ValidationResult } from "utils/validation.utils";
import { AnyCollection } from "api/story-collection";
import { CollectionId } from "api/primitive-types";

interface State {
  isPublishLater: boolean;
  publishAt: number | null;
}

interface StateProps {
  errors: ValidationResult<AnyCollection>;
}

interface DispatchProps {
  cancelPublish: (collectionId: CollectionId | "new") => void;
  publishCollection: () => void;
  updateStagingSelectedCollection: (a: string, b: any) => void;
  updateSelectedCollection: () => void;
  setScheduleCollectionDateError: (field: string) => void;
  removeFieldError: (field: string) => void;
}

interface ownProps {
  selectedCollection: AnyCollection | null;
  isActive: boolean;
  collectionId: CollectionId | "new";
}

type Props = StateProps & DispatchProps & ownProps;

export class CollectionPublishInspector extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isPublishLater: false,
      publishAt: null
    };

    this.updateCurrentPublishAt = this.updateCurrentPublishAt.bind(this);
    this.onChangePublishLater = this.onChangePublishLater.bind(this);
    this.validateDateField = this.validateDateField.bind(this);
  }

  updateCurrentPublishAt(publishAt: number | null) {
    this.setState({
      publishAt
    });
  }

  onChangePublishLater(isPublishLater: boolean) {
    this.setState({
      isPublishLater
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.selectedCollection !== this.props.selectedCollection) {
      this.setState({
        isPublishLater:
          this.state.isPublishLater || (this.props.selectedCollection && this.props.selectedCollection["publish-at"])
            ? true
            : false,
        publishAt:
          this.props.selectedCollection && this.props.selectedCollection["publish-at"]
            ? this.props.selectedCollection["publish-at"]
            : Date.now()
      });
    }
  }

  validateDateField() {
    this.props.removeFieldError("publish-date-missing");
    this.props.removeFieldError("publish-date-invalid");
    if (this.state.isPublishLater) {
      if (this.state.publishAt == null || isNaN(this.state.publishAt)) {
        this.props.setScheduleCollectionDateError("publish-date-missing");
        return false;
      } else if (isAfter(new Date(), this.state.publishAt)) {
        this.props.setScheduleCollectionDateError("publish-date-invalid");
        return false;
      }
    }
    return true;
  }

  render() {
    const {
      cancelPublish,
      collectionId,
      isActive,
      updateStagingSelectedCollection,
      updateSelectedCollection,
      publishCollection,
      selectedCollection,
      errors
    } = this.props;
    return (
      <Inspector
        title=""
        onClose={() => {
          cancelPublish(collectionId);
        }}
        isActive={isActive}
        actionButtonLabel={t("collections.maybe-publish.publish-button-label")}
        onActionButtonClick={() => {
          if (this.validateDateField()) {
            updateStagingSelectedCollection("publish-at", this.state.isPublishLater ? this.state.publishAt : null);
            updateSelectedCollection();
            publishCollection();
          }
        }}>
        <h3 className={styles["collection-publish-inspector-title"]}>{t("collections.maybe-publish.title")}</h3>
        <p className={styles["publish-text"]}>{t("collections.maybe-publish.subtext")}</p>
        <div className={styles["schedule-publish-inspector-options"]}>
          <RadioButton
            id="radio-publish-now"
            label={t("common.publish-now")}
            checked={!this.state.isPublishLater}
            name="publish"
            onChange={(isPublishNow) => this.onChangePublishLater(!isPublishNow)}
            classname={"schedule-publish-inspector-radio-button"}
          />
          <RadioButton
            id="radio-publish-later"
            label={t("common.publish-later")}
            checked={this.state.isPublishLater}
            name="publish"
            onChange={(isPublishLater) => this.onChangePublishLater(isPublishLater)}
            classname={"schedule-publish-inspector-radio-button"}
          />
        </div>
        {this.state.isPublishLater && (
          <div className={styles["schedule-publish-message"]}>
            <h3 className={styles["collection-publish-inspector-sub-title"]}>
              {t("collections.maybe-publish.schedule-title")}
            </h3>
            {selectedCollection && selectedCollection["publish-at"] && (
              <p>
                {t("collections.maybe-publish.scheduled-message") +
                  formatDate(selectedCollection["publish-at"], "dd/MM/yyyy hh:mm a")}
              </p>
            )}
            {selectedCollection && (
              <React.Fragment>
                <DatePickerCustom
                  showTimeSelect
                  onChange={(date: number | null) => {
                    date && this.updateCurrentPublishAt(date);
                  }}
                  selectedDate={this.state.publishAt}
                />

                <div className={styles["schedule-publish-targets-error-message"]}>
                  {(errors &&
                    get(errors, ["publish-date-missing"]) &&
                    t("collections.messages.publish_date_missing")) ||
                    (errors && get(errors, ["publish-date-invalid"]) && t("collections.messages.publish_date_invalid"))}
                </div>
              </React.Fragment>
            )}
          </div>
        )}
      </Inspector>
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    errors: state.collections.ui.errors
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DispatchProps => {
  return {
    cancelPublish: (collectionId: CollectionId) => dispatch(cancelPublishAction(collectionId)),
    publishCollection: () => dispatch(publishCollectionAction()),
    updateStagingSelectedCollection: (a: string, b: any) => dispatch(updateStagingSelectedCollectionAction(a, b)),
    updateSelectedCollection: () => dispatch(updateSelectedCollectionAction()),
    setScheduleCollectionDateError: (field: string) => dispatch(setScheduleCollectionDateErrorAction(field)),
    removeFieldError: (field: string) => dispatch(removeFieldErrorAction(field))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionPublishInspector);
