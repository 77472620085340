/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Cta = ({ width = 24, height = 24, color = "#c6ccd5" }) => (
  <svg viewBox="0 0 24 24" width={width} height={height} fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="m14.49 13.04c-0.29 0.09-0.5 0.32-0.56 0.61-0.04 0.17 0.14 4.87 0.21 5.59 0.04 0.31 0.07 0.37 0.29 0.52 0.39 0.27 0.75 0.24 1.37-0.13 0.59-0.36 0.47-0.44 1.19 0.79 0.28 0.48 0.58 0.92 0.68 0.98 0.12 0.08 0.32 0.13 0.53 0.13 0.28 0 0.43-0.06 0.85-0.3 0.64-0.38 0.87-0.59 0.95-0.89 0.09-0.34 0.02-0.54-0.56-1.54-0.27-0.47-0.5-0.88-0.51-0.91s0.21-0.21 0.49-0.41c0.42-0.29 0.52-0.39 0.55-0.58 0.08-0.38-0.01-0.7-0.25-0.94-0.13-0.12-1.17-0.83-2.33-1.59-2.26-1.48-2.34-1.52-2.9-1.33zm2.51 2.21c1.09 0.72 1.98 1.33 1.98 1.35s-0.3 0.25-0.67 0.51c-0.64 0.44-0.67 0.48-0.58 0.61 0.32 0.51 1.32 2.29 1.32 2.34 0 0.06-0.76 0.55-0.85 0.55-0.01 0-0.34-0.57-0.75-1.27-0.4-0.7-0.73-1.28-0.74-1.29-0.01-0.02-0.37 0.18-0.8 0.43-0.44 0.25-0.8 0.44-0.82 0.43-0.02-0.02-0.24-4.54-0.24-4.9 0-0.08 0.03-0.11 0.09-0.09 0.04 0.02 0.97 0.62 2.06 1.33zm-10.97-3.72v0.47h6.28 6.27v-0.47-0.48h-6.27-6.28v0.48zm-2.59-6.44c-0.69 0.16-1.34 0.69-1.66 1.35l-0.19 0.37-0.02 4.59c-0.01 4.49-0.01 4.59 0.12 4.96 0.27 0.7 0.85 1.25 1.56 1.47 0.4 0.13 0.62 0.14 4.59 0.14h4.16v-0.48-0.47h-4.16-4.15l-0.37-0.19c-0.45-0.23-0.72-0.59-0.81-1.09-0.09-0.49-0.09-8 0-8.49 0.09-0.5 0.36-0.86 0.81-1.09l0.35-0.19h8.33 8.33l0.35 0.19c0.45 0.23 0.72 0.59 0.81 1.09 0.04 0.21 0.07 1.93 0.07 4.25v3.89h0.44 0.44l-0.01-4.29-0.02-4.29-0.19-0.38c-0.25-0.51-0.67-0.91-1.2-1.17l-0.44-0.21s-8.44-0.01-8.44-0.01c-4.78 0-8.55 0.02-8.7 0.05z"
      stroke="none"
    />
  </svg>
);

export default Cta;
