/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { BreakingNewsId } from "api/primitive-types";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";

import { PartialAppState } from "./state";
import { loadBreakingNews } from "./async-action-creators";
import { LoaderState } from "behaviors/loader/state";

import Loader from "./components/loader";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import BreakingNews from "./breaking-news";
import "./existing-breaking-news.module.css";

interface OwnProps {
  id: BreakingNewsId;
}

interface StateProps {
  loader: LoaderState;
}

interface DispatchProps {
  loadBreakingNews: (id: BreakingNewsId) => void;
}

type ExistingBreakingNewsProps = OwnProps & StateProps & DispatchProps;

class ExistingBreakingNews extends React.Component<ExistingBreakingNewsProps> {
  componentDidMount() {
    this.props.loadBreakingNews(this.props.id);
  }

  render() {
    return (
      <LoaderWrapper
        className={"existingbreaking-news-progress-main-area"}
        component={Loader}
        loader={this.props.loader}>
        <BreakingNews showAdvancedOptionsInspector={false} isExistingBreakingNews={true} />
      </LoaderWrapper>
    );
  }
}

export { ExistingBreakingNews };

const mapStateToProps = (state: PartialAppState) => ({
  loader: state.breakingNews.ui.loader
});

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, any, any>) => ({
  loadBreakingNews: (id: BreakingNewsId) => dispatch(loadBreakingNews(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExistingBreakingNews);
