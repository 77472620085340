/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ExtendableError } from "utils/error.utils";

export class NoCollectionSelectedError extends ExtendableError {}
