/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ThunkDispatch } from "redux-thunk";
import * as api from "api/integrations";
import {
  cancelOrCloseIntegrationAction,
  listPublisherIntegrationsFailureAction,
  listPublisherIntegrationsSuccessAction,
  listSupportedIntegrationsFailureAction,
  listSupportedIntegrationsSuccessAction,
  setIntegrationSaveErrorsAction,
  setIntegrationValidateInProgress,
  setIntegrationValidateResultAction,
  updatePublisherSettingsAction
} from "./action-creators";
import { notificationError, notificationSuccess } from "containers/page/actions";
import { navigate } from "utils/routes.utils";
import { INTEGRATIONS_LISTING_PATH } from "./routes";
import { IntegrationConfig } from "api/integrations";
import * as PublisherAPI from "api/publisher";
import { validateIntegrationConfig } from "./validate";
import { toClientValidationError, toServerValidationError } from "utils/validation.utils";
import { PartialAppState, Publisher } from "./state";
import { AnyAction } from "redux";
import { get, pick } from "lodash";
import { t } from "i18n";
import { PushNotification } from "api/publisher";
import { parseError } from "utils/error.utils";

const filterPublisherSettings = (publisherSettings: Publisher) => {
  publisherSettings = pick(publisherSettings, ["push-notification"]);
  if (!get(publisherSettings, ["push-notification"])) {
    const pushNotification: PushNotification = {
      "icon-url": null,
      provider: {
        web: null,
        mobile: null
      }
    };
    publisherSettings["push-notification"] = pushNotification;
  }
  return publisherSettings;
};

export const loadPublisherIntegrations = () => async (dispatch: ThunkDispatch<any, any, any>) => {
  try {
    const publisherIntegrationsAPIData = await api.getPublisherIntegrations();
    dispatch(listPublisherIntegrationsSuccessAction(publisherIntegrationsAPIData));
  } catch (error) {
    dispatch(listPublisherIntegrationsFailureAction(error));
  }
};

export const loadSupportedIntegrations = () => async (dispatch: ThunkDispatch<any, any, any>) => {
  try {
    const supportedIntegrations = await api.getSupportedIntegrations();
    dispatch(listSupportedIntegrationsSuccessAction(supportedIntegrations));
  } catch (error) {
    dispatch(listSupportedIntegrationsFailureAction(error));
  }
};

export const cancelSaveIntegration = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(cancelOrCloseIntegrationAction());
};

export const clearSaveIntegrationsErrors = () => setIntegrationSaveErrorsAction(toClientValidationError([]));

export const saveIntegration = (selectedIntegration: IntegrationConfig) => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  const errors = validateIntegrationConfig(selectedIntegration);

  if (errors) {
    dispatch(setIntegrationSaveErrorsAction(toClientValidationError<IntegrationConfig>(errors)));
    return;
  }

  dispatch(clearSaveIntegrationsErrors());
  try {
    await api.savePublisherIntegration(selectedIntegration);
    dispatch(navigate(INTEGRATIONS_LISTING_PATH, { type: selectedIntegration.value.type }));
    dispatch(cancelOrCloseIntegrationAction());
    dispatch(notificationSuccess(t("integrations.messages.update-integration-success")));
    dispatch(loadPublisherIntegrations());
  } catch (error) {
    const errorJson = parseError(error);
    if (errorJson && errorJson.status === 422 && errorJson.error) {
      dispatch(setIntegrationSaveErrorsAction(toServerValidationError<IntegrationConfig>(errorJson.error)));
    }
    dispatch(notificationError(t("integrations.messages.update-integration-failure")));
  }
};

export const validateIntegration = (selectedIntegration: IntegrationConfig) => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(clearSaveIntegrationsErrors());
  dispatch(setIntegrationValidateInProgress(true));
  dispatch(setIntegrationValidateResultAction(false));
  const errors = validateIntegrationConfig(selectedIntegration);

  if (errors) {
    dispatch(setIntegrationSaveErrorsAction(toClientValidationError<IntegrationConfig>(errors)));
    dispatch(setIntegrationValidateInProgress(false));
    return;
  }

  try {
    await api.validatePublisherIntegration(selectedIntegration);
    dispatch(setIntegrationValidateResultAction(true));
  } catch (error) {
    const errorJson = parseError(error);
    if (errorJson && errorJson.status === 422 && errorJson.error) {
      dispatch(setIntegrationSaveErrorsAction(toServerValidationError<IntegrationConfig>(errorJson.error)));
    }
  } finally {
    dispatch(setIntegrationValidateInProgress(false));
  }
};

export const deleteIntegration = (integrationName: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
  try {
    await api.deletePublisherIntegration(integrationName);
    await dispatch(loadPublisherSettings());
    dispatch(notificationSuccess(t("integrations.messages.delete-integration-success")));
  } catch (error) {
    dispatch(notificationError(t("integrations.messages.delete-integration-failure")));
  }
  await dispatch(loadPublisherIntegrations());
};

export const toggleIntegration = (integration: IntegrationConfig) => async (dispatch: ThunkDispatch<any, any, any>) => {
  try {
    let toggledIntegration: IntegrationConfig = Object.assign({}, integration, {
      value: Object.assign({}, integration.value, { disabled: !integration.value.disabled })
    }); //TODO: For typescript to work

    await api.togglePublisherIntegration(toggledIntegration);
    await dispatch(loadPublisherSettings());
    dispatch(
      notificationSuccess(
        integration.value.disabled
          ? t("integrations.messages.activate-integration-success")
          : t("integrations.messages.deactivate-integration-success")
      )
    );
  } catch (error) {
    dispatch(
      notificationError(
        integration.value.disabled
          ? t("integrations.messages.activate-integration-failure")
          : t("integrations.messages.deactivate-integration-failure")
      )
    );
  }
  await dispatch(loadPublisherIntegrations());
};

export function loadPublisherSettings() {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
    try {
      const publisher = await PublisherAPI.getPublisher();
      const publisherSettings = pick(publisher, ["push-notification"]);
      dispatch(updatePublisherSettingsAction(filterPublisherSettings(publisherSettings)));
    } catch (e) {
      dispatch(notificationError(t("integrations.messages.load-publisher-settings-failure")));
    }
  };
}

export function savePublisherSettings(settings: Publisher) {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
    try {
      const publisherSettings = await PublisherAPI.updatePublisher(settings);
      dispatch(updatePublisherSettingsAction(filterPublisherSettings(publisherSettings)));
      dispatch(notificationSuccess(t("integrations.messages.update-publisher-settings-success")));
    } catch (e) {
      dispatch(notificationError(t("integrations.messages.update-publisher-settings-failure")));
    }
  };
}
