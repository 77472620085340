/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { compose, AnyAction } from "redux";
import { batch, connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { match } from "react-router";
import { t } from "i18n";
import startsWith from "lodash/startsWith";
import wrapPage from "containers/page/page";
import { showFeatureNotSetupPage, showUnauthorizedPage, showNotFoundPage } from "store/error-page";
import { PartialAppState, setIsLoading, resetCurrentForm, fetchForm, clearErrors } from "store/form/editor";
import { FORMS_EDITOR_PATH_EDIT, FORMS_EDITOR_PATH_MANAGE } from "pages/forms/routes";
import { navigate } from "utils/routes.utils";
import Spinner from "components/spinner/spinner";
import Header from "pages/forms/editor/header/header";
import WrapUseDesktop from "components/use-desktop/use-desktop";
import FormAttributes from "pages/forms/editor/attributes/attributes";
import FormBuilder from "pages/forms/editor/builder/builder";
import styles from "./editor.module.css";
import FormsInspector from "../inspector/inspector";

interface StateProps {
  title: string;
  isLoading: boolean;
  isFormsEnabled?: boolean;
  canCreateForms?: boolean;
  isDesktopSizeViewport: boolean;
}

interface DispatchProps {
  fetchForm: (formId: number) => void;
  setNewForm: () => void;
  showFeatureNotSetupPage: () => void;
  showUnauthorizedPage: () => void;
  showNotFoundPage: () => void;
  openManageInspector: (formId: string) => void;
  resetForm: () => void;
}

interface OwnProps {
  match: match<{ formId: string }>;
}

type FormEditorProps = StateProps & DispatchProps & OwnProps;

export class FormEditor extends React.Component<FormEditorProps> {
  async componentDidMount() {
    const {
      isFormsEnabled,
      canCreateForms,
      fetchForm,
      setNewForm,
      showUnauthorizedPage,
      showFeatureNotSetupPage,
      showNotFoundPage,
      match
    } = this.props;

    const formId = parseInt(match.params.formId);

    if (!isFormsEnabled) {
      showFeatureNotSetupPage();
    } else if (!canCreateForms) {
      showUnauthorizedPage();
    } else if (startsWith(match.path, FORMS_EDITOR_PATH_EDIT) && match.params.formId === "new") {
      setNewForm();
    } else if (isNaN(formId)) {
      showNotFoundPage();
    } else {
      await fetchForm(formId);
    }
  }

  componentWillUnmount() {
    this.props.resetForm();
  }

  render() {
    const { isLoading, isDesktopSizeViewport, match, openManageInspector } = this.props;
    const formId = match.params.formId;
    return isLoading ? (
      <Spinner message={t("forms.editor.loading")} classname={styles["form-editor-spinner"]} />
    ) : (
      <>
        <FormAttributes openManageInspector={() => openManageInspector(formId)} />
        <FormsInspector isNewForm={formId === "new"} formId={formId} isOpen={match.path === FORMS_EDITOR_PATH_MANAGE} />
        <WrapUseDesktop classname={styles["form-editor-use-desktop"]} isDesktopSizeViewport={isDesktopSizeViewport}>
          <FormBuilder />
        </WrapUseDesktop>
      </>
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    title: "Form Editor",
    isFormsEnabled: state.features.isFormsEnabled,
    canCreateForms: state.features.canCreateForms,
    isLoading: state.forms.editor.isLoading,
    isDesktopSizeViewport: state.viewport.isDesktopSizeViewport
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    fetchForm: async (formId) => await dispatch(fetchForm(formId)),
    setNewForm: () => {
      batch(() => {
        dispatch(clearErrors());
        dispatch(resetCurrentForm());
        dispatch(setIsLoading(false));
      });
    },
    showFeatureNotSetupPage: () => dispatch(showFeatureNotSetupPage()),
    showUnauthorizedPage: () => dispatch(showUnauthorizedPage()),
    showNotFoundPage: () => dispatch(showNotFoundPage()),
    openManageInspector: (formId: string) => {
      dispatch(navigate(FORMS_EDITOR_PATH_MANAGE, { formId }));
    },
    resetForm: () => {
      dispatch(resetCurrentForm());
    }
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  wrapPage({ HeaderComponent: Header, isStoryPage: true })
)(FormEditor);
