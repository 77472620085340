/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { INITIAL_STATE } from "../../initial-state";
import actions, { ActionTypes } from "./actions";

export default function generalSettingsReducer(state = INITIAL_STATE, action: ActionTypes) {
  switch (action.type) {
    case actions.LOAD_SETTINGS_DATA: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isSettingsModified: false,
          main: { loading: true, error: null }
        }
      };
    }

    case actions.LOAD_SETTINGS_DATA_SUCCESS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isSettingsModified: false,
          main: { loading: false, error: null }
        },
        generalSettings: action.payload.settings && action.payload.settings.settings["general-settings"],
        socialLinks: action.payload.settings && action.payload.settings.settings["social-links"],
        seoMetadata: action.payload.seoMetadata && action.payload.seoMetadata["seo-metadata"]
      };
    }

    case actions.LOAD_SETTINGS_DATA_FAILURE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isSettingsModified: false,
          main: { loading: false, error: action.payload.error }
        }
      };
    }

    case actions.UPDATE_SOCIAL_LINKS: {
      return {
        ...state,
        socialLinks: action.payload.socialLinks,
        ui: {
          ...state.ui,
          isSettingsModified: true
        }
      };
    }

    case actions.UPDATE_SEO_METADATA: {
      return {
        ...state,
        seoMetadata: {
          ...state.seoMetadata,
          ...action.payload.changes
        },
        ui: {
          ...state.ui,
          isSettingsModified: true
        }
      };
    }

    case actions.UPDATE_GENERAL_SETTINGS: {
      return {
        ...state,
        generalSettings: {
          ...state.generalSettings,
          [action.payload.key]: action.payload.changes
        },
        ui: {
          ...state.ui,
          isSettingsModified: true
        }
      };
    }

    case actions.UPDATE_IMAGE_UPLOAD_STATUS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          uploading: action.payload.uploading
        }
      };
    }

    case actions.UPDATE_IMAGE_UPLOAD_FAILURE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          imageError: action.payload.imageError
        }
      };
    }

    default:
      return state;
  }
}
