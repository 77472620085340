/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { Spacer as EmSpacer } from "@quintype/em/components/spacer";

interface SpacerProps {
  /** space token from spacing scale  */
  token: string;
  /** Alignment of space, vertical or horizontal. Defaults to horizontal */
  align?: "horizontal" | "vertical";
}

const Spacer: React.FC<SpacerProps> = (props) => <EmSpacer {...props} />;

export default Spacer;
