/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { DndContext, closestCenter, useSensors, useSensor, PointerSensor } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";

const swapItems = (items, firstItem, afterItem, updateSwappedItems, updateSortedItems, initialSwappedItems) => {
  if (firstItem === afterItem) return;
  const swappedItems = arrayMove(items, firstItem, afterItem);

  if (initialSwappedItems !== swappedItems) {
    updateSwappedItems(swappedItems);
    updateSortedItems(swappedItems);
  }
};

const SortableList = ({ items, updateSwappedItems, updateSortedItems, swappedItems, children }) => {
  const onDragEnd = (event) => {
    const { active, over } = event;
    if (active.id === over.id) return;
    const oldIndex = items.findIndex((item) => item.id === active.id);
    const newIndex = items.findIndex((item) => item.id === over.id);
    swapItems(items, oldIndex, newIndex, updateSwappedItems, updateSortedItems, swappedItems);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 5 }
    })
  );

  return (
    <div>
      <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd} sensors={sensors}>
        <SortableContext items={items.map((item) => item.id)} strategy={verticalListSortingStrategy}>
          <div>{children}</div>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default SortableList;
