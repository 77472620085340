/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

export const oauthAuthorize = (integrationId: number, redirectUri: string) => {
  return client
    .url("/api/auth/v1/oauth/authorize")
    .query({ client_id: integrationId, redirect_uri: redirectUri, response_type: "code", allow_ajax: true })
    .get()
    .json()
    .catch((err) => {
      return Promise.reject(JSON.parse(err.message));
    });
};
