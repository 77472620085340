/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import _assign from "lodash/assign";
import { connect } from "react-redux";
import { compose } from "redux";

import PhotoEditor from "components/photo-editor/photo-editor";
import { actions } from "../../actions";
import { dataURItoFile } from "pages/media-library/image-utils";
import { getMetadataWithDimensions } from "utils/image-metadata.utils";
import throttle from "helpers/throttle";
import { s3FileUpload } from "helpers/s3-gateway";

const s3UploadQueue = throttle(s3FileUpload);

function getHighResImageUrl(image) {
  // This is required because thumbor gives back a lower res image if no query param is present
  if (image.url) {
    const url = new URL(image.url);
    url.search = url.search ? url.search + `&dummy=value` : `dummy=value`;
    return url.toString();
  }

  return "";
}

class PhotoEditorWrapper extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.uploadToS3 = this.uploadToS3.bind(this);
    this.resetMetadata = this.resetMetadata.bind(this);
  }
  resetMetadata(dimensions, metadata) {
    return _assign({}, metadata, {
      width: dimensions.width,
      height: dimensions.height,
      "focus-point": isEqual(pick(metadata, ["width", "height"]), dimensions) ? metadata["focus-point"] : null
    });
  }

  uploadToS3(response) {
    const image: any = this.props.photoEditor.selectedImage;
    const fileName: any = image.url.substring(image.url.lastIndexOf("/") + 1);
    if (response) {
      const file = dataURItoFile(response, fileName);
      s3UploadQueue.queueJob(file, (result) => {
        if (result.success) {
          getMetadataWithDimensions(result).then((dimensions) => {
            this.props.updateCollectionCoverImage({
              ...image,
              "temp-key": result.key,
              url: result.url,
              metadata: this.resetMetadata(dimensions, image.metadata)
            });
          });
        }
      });
    }
  }
  render() {
    const { closePhotoEditor, photoEditorConfig, photoEditor } = this.props;
    if (!photoEditor.visible) {
      return false;
    }
    return (
      <PhotoEditor
        imageUrl={getHighResImageUrl(photoEditor.selectedImage)}
        onExport={this.uploadToS3}
        onClose={closePhotoEditor}
        photoEditorConfig={photoEditorConfig}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    photoEditor: state.collections.app.photoEditor,
    photoEditorConfig: state.config["photo-editor-sdk"]
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closePhotoEditor: () => dispatch({ type: actions.CLOSE_PHOTO_EDITOR }),
    updateCollectionCoverImage: (image) => {
      dispatch({ type: actions.UPDATE_EDITED_COVER_IMAGE, payload: { image } });
      dispatch({ type: actions.CLOSE_PHOTO_EDITOR });
    }
  };
};

export default compose<any>(connect(mapStateToProps, mapDispatchToProps))(PhotoEditorWrapper);
