/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { CurrentDomain, DataError, Domains, Domain } from "store/domain/domain";
import { t } from "i18n";
import { toClientValidationError } from "utils/validation.utils";
import { validateDomain } from "./validate";
import { mapValues } from "lodash";

export const ERRORS_TRANSLATION_PATH = "secret-mode.domain-manager.errors.data";

export function getValidationErrors(domain: CurrentDomain) {
  const validationResult = validateDomain(domain);
  const errors = validationResult && toClientValidationError<CurrentDomain>(validationResult).errors;
  return errors
    ? mapValues(errors, (errorDescription) => (errorDescription ? (errorDescription[0] as string) : undefined))
    : {};
}

export function getDataErrors(newDomain: CurrentDomain, domains: Domains): DataError {
  return Object.values(domains).reduce((errors: DataError, domain: Domain) => {
    if (domain.name === newDomain.name) {
      errors.name = t(`${ERRORS_TRANSLATION_PATH}.duplicate-name`);
    }
    if (domain["host-url"] === newDomain["host-url"]) {
      errors["host-url"] = t(`${ERRORS_TRANSLATION_PATH}.duplicate-host-url`);
    }
    return errors;
  }, {});
}
