/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

function generateOpinions() {
  let opinions: any = [];
  opinions.push({
    text: "",
    type: "text",
    metadata: {},
    "percentage-votes": 0
  });
  opinions.push({
    text: "",
    type: "text",
    metadata: {},
    "percentage-votes": 0
  });
  return opinions;
}

export function newOpinionPoll() {
  return {
    description: "",
    opinions: generateOpinions(),
    settings: {
      "anonymous-voting?": false,
      "show-results": "logged-in-voted",
      "change-vote?": false
    },
    "hero-image": {},
    topic: ""
  };
}
