/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AnyImage } from "api/search-media-image";
import * as actions from "./actions";

export const initialState = {
  providers: {},
  currentMediaProvider: {
    label: "All",
    value: "media-library",
    category: "PUBLISHER_MEDIA_CATEGORIES"
  },
  searchTerm: "",
  loading: false,
  uploading: false,
  selectedMedia: [],
  errors: [],
  mediaStats: { stories: [], total: 0 },
  advancedSearchOpts: {
    "geo-radius": 2,
    "uploaded-at": {
      type: null,
      from: null,
      to: null
    }
  },
  ui: {
    mediaForMultiSelect: [],
    isSaving: false,
    isLinkAuthorEnabled: false,
    selectedTab: "",
    main: { loading: false, error: null },
    showAdvancedSearch: false
  }
};

const updateSelectedMedia = (selectedMedia, payload) => {
  const { key, value, mediaIndex } = payload;

  return selectedMedia.map((item, index) => {
    if (index !== mediaIndex) {
      return item;
    }
    return {
      ...item,
      [key]: value
    };
  });
};

export const updateMediaForMultiSelect = (media, mediaForMultiSelect) => {
  if (mediaForMultiSelect.find((m) => m.image.url === media.image.url)) {
    let updatedMedia: AnyImage[] = [];
    if (mediaForMultiSelect.find((m) => m === media)) {
      return mediaForMultiSelect.filter((m) => m.image.url !== media.image.url);
    } else {
      mediaForMultiSelect.forEach((m) => {
        if (m.image.url === media.image.url) {
          m.instances[0] = { ...m.instances[0], ...media.image };
          delete m.instances[0].url;
          delete m.instances[0].key;
          updatedMedia.push(m);
        }
      });
      return updatedMedia;
    }
  } else {
    return [...mediaForMultiSelect, media];
  }
};

const setProviderMedia: any = (state: any, data: any, append = false): any => {
  let newProviders = {};
  for (const [key] of Object.entries(data.providers)) {
    const value: any = data.providers[key];
    newProviders[key] = {
      media:
        state.providers[key] && state.providers[key].media && append
          ? state.providers[key].media.concat(value.media)
          : value.media,
      page: value.page,
      aggregations: value.aggregations
    };
    if (newProviders[key].media) newProviders[key].media.forEach((media, index) => (media.index = index));
  }
  return newProviders;
};

const updateProviderMedia = (allMedias, payload) => {
  payload.updates &&
    Object.keys(payload.updates).forEach((mediaKey) => {
      switch (mediaKey) {
        case "instances":
          for (const [key] of Object.entries(payload.updates.instances)) {
            allMedias[payload.index]["instances"][0][key] = payload.updates.instances[key];
          }
          break;
        case "image":
          if (payload.updates.image.location) {
            if (allMedias[payload.index]["image"]["extracted-data"]) {
              allMedias[payload.index]["image"]["extracted-data"]["location"] = payload.updates.image.location;
            }
          } else {
            allMedias[payload.index]["image"]["extracted-data"] = {
              location: payload.updates.image.location
            };
          }
          break;
        default:
      }
    });
  return allMedias;
};

const mediaLibraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.MEDIA_LIBRARY_DATA_SUCCESS: {
      return { ...state, providers: setProviderMedia(state, action.payload.data) };
    }

    case actions.MEDIA_LIBRARY_NEXT_DATA_SUCCESS: {
      return { ...state, providers: setProviderMedia(state, action.payload.data, true) };
    }

    case actions.MEDIA_LIBRARY_SET_PROVIDER: {
      return {
        ...state,
        currentMediaProvider: {
          ...action.payload.provider
        }
      };
    }

    case actions.MEDIA_LIBRARY_UPDATE_SEARCH_TERM: {
      return { ...state, searchTerm: action.payload.searchTerm };
    }

    case actions.MEDIA_LIBRARY_SET_SELECTED_MEDIA: {
      return { ...state, selectedMedia: action.payload };
    }

    case actions.MEDIA_LIBRARY_UPDATE_IMAGE_UPLOAD_STATUS: {
      return { ...state, uploading: action.payload.uploading };
    }

    case actions.MEDIA_LIBRARY_LOADING_STATUS: {
      return { ...state, loading: action.payload.loading };
    }

    case actions.MEDIA_LIBRARY_UPDATE_SELECTED_MEDIA_DATA: {
      return { ...state, selectedMedia: updateSelectedMedia(state.selectedMedia, action.payload) };
    }

    case actions.MEDIA_LIBRARY_UPDATE_ERROR: {
      return {
        ...state,
        errors: action.payload,
        ui: {
          ...state.ui,
          isSaving: false
        }
      };
    }

    case actions.MEDIA_LIBRARY_UPDATE_MEDIA: {
      return {
        ...state,
        providers: {
          ...state.providers,
          "media-library": {
            ...state.providers["media-library"],
            media: updateProviderMedia(state.providers["media-library"].media, action.payload)
          }
        }
      };
    }

    case actions.MEDIA_LIBRARY_IMAGE_SAVE_INIT: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isSaving: true
        }
      };
    }

    case actions.MEDIA_LIBRARY_IMAGE_SAVE_SUCCESS: {
      return {
        ...state,
        advancedSearchOpts: action.payload.showAdvancedSearch
          ? state.advancedSearchOpts
          : initialState.advancedSearchOpts,
        ui: {
          ...state.ui,
          isSaving: false,
          showAdvancedSearch: action.payload.showAdvancedSearch
        }
      };
    }
    case actions.MEDIA_LIBRARY_IMAGE_SAVE_FAILURE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isSaving: false
        }
      };
    }
    case actions.MEDIA_LIBRARY_RESET_DATA: {
      return {
        ...state,
        errors: [],
        selectedMedia: [],
        currentMediaProvider: initialState.currentMediaProvider,
        searchTerm: "",
        advancedSearchOpts: initialState.advancedSearchOpts,
        ui: {
          ...state.ui,
          showAdvancedSearch: initialState.ui.showAdvancedSearch
        }
      };
    }

    case actions.MEDIA_LIBRARY_UPDATE_MEDIA_FOR_MULTI_SELECT: {
      return {
        ...state,
        ui: {
          ...state.ui,
          mediaForMultiSelect: updateMediaForMultiSelect(action.payload.media, state.ui.mediaForMultiSelect)
        }
      };
    }

    case actions.MEDIA_LIBRARY_CLEAR_MEDIA_FOR_MULTI_SELECT: {
      return {
        ...state,
        mediaStats: { stories: [], total: 0 },
        ui: {
          ...state.ui,
          mediaForMultiSelect: [],
          main: { loading: false, error: null },
          selectedTab: ""
        }
      };
    }

    case actions.MEDIA_LIBRARY_CLEAR_SELECTED_MEDIA: {
      return {
        ...state,
        selectedMedia: [],
        errors: [],
        mediaStats: { stories: [], total: 0 },
        ui: { ...state.ui, main: { loading: false, error: null }, selectedTab: "" }
      };
    }

    case actions.MEDIA_LIBRARY_REMOVE_ERROR: {
      return {
        ...state,
        errors: []
      };
    }

    case actions.MEDIA_LIBRARY_UPDATE_SELECTED_TAB: {
      return {
        ...state,
        ui: { ...state.ui, selectedTab: action.payload.selectedTab }
      };
    }

    case actions.MEDIA_LIBRARY_UPDATE_MEDIA_STATS: {
      return {
        ...state,
        mediaStats: {
          ...state.mediaStats,
          stories: [...state.mediaStats.stories, ...action.payload.mediaStats.stories],
          total: action.payload.mediaStats.total
        }
      };
    }

    case actions.MEDIA_LIBRARY_UPDATE_STORY_LIST_LOADER_STATUS: {
      return {
        ...state,
        ui: { ...state.ui, main: action.payload }
      };
    }

    case actions.MEDIA_LIBRARY_TOGGLE_SHOW_ADVANCED_SEARCH: {
      const advancedSearchOpts = !state.ui.showAdvancedSearch
        ? state.advancedSearchOpts
        : initialState.advancedSearchOpts;
      return {
        ...state,
        advancedSearchOpts,
        ui: {
          ...state.ui,
          showAdvancedSearch: !state.ui.showAdvancedSearch
        }
      };
    }

    case actions.MEDIA_LIBRARY_UPDATE_ADVANCED_SEARCH_OPTIONS: {
      if (!action.payload) return state;
      const newGeoRadius = Number.parseInt(action.payload["geo-radius"]);
      const uploadedAt = action.payload["uploaded-at"];
      return {
        ...state,
        advancedSearchOpts: {
          ...state.advancedSearchOpts,
          ...action.payload,
          "geo-radius": newGeoRadius ? action.payload["geo-radius"] : initialState.advancedSearchOpts["geo-radius"],
          "uploaded-at": uploadedAt || initialState.advancedSearchOpts["uploaded-at"]
        }
      };
    }

    default:
      return state;
  }
};

export default mediaLibraryReducer;
