/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import actions, {
  AnalyticsFetchSuccess,
  SetFilterOptions,
  ToggleAnalyticsShare,
  SetCustomRange,
  AuthorStoriesSuccessAction,
  CloseSublist,
  OpenSublist
} from "./actions";
import { Analytics, Top } from "api/analytics";
import { PartialFilterOptions, Range } from "./state";
import { AuthorId } from "api/primitive-types";

export const analyticsSuccessAction = (analytics: Analytics): AnalyticsFetchSuccess => ({
  type: actions.ANALYTICS_FETCH_SUCCESS,
  payload: {
    analytics: analytics
  }
});

export const setFilterOptions = (filterOptions: PartialFilterOptions): SetFilterOptions => ({
  type: actions.SET_FILTER_OPTIONS,
  payload: {
    filterOptions: filterOptions
  }
});

export const toggleAnalyticsShare = (): ToggleAnalyticsShare => ({
  type: actions.TOGGLE_ANALYTICS_SHARE
});

export const setCustomRange = (customRange: Range): SetCustomRange => ({
  type: actions.SET_CUSTOM_RANGE,
  payload: {
    customRange
  }
});

export const authorStoriesSuccessAction = (authorStories: Top[]): AuthorStoriesSuccessAction => ({
  type: actions.AUTHOR_STORIES_FETCH_SUCCESS,
  payload: { authorStories }
});

export const openSublist = (authorId: AuthorId): OpenSublist => ({
  type: actions.OPEN_SUBLIST,
  payload: { authorId }
});

export const closeSublist = (): CloseSublist => ({ type: actions.CLOSE_SUBLIST });
