/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { actions } from "../../actions";
import { StoryElement } from "api/story";
import { PartialAppState } from "pages/story-editor/state";
import { Node } from "prosemirror-model";
import { ThunkDispatch } from "redux-thunk";
import Checkbox from "components/checkbox/checkbox";
import { buildCtaElementTextUsingMetadata, getCtaOptions } from "../../prosemirror/prosemirror-to-story";
import styles from "./cta-checkbox-options.module.css";

interface CtaCheckboxOptionsProps {
  node: Node;
  storyElement: StoryElement;
  readOnly?: boolean;

  onChange(id: any, changes: any): void;
}

const onChange = (type: string, storyElement: StoryElement, value: boolean, changeFn: any) => {
  const changes = buildCtaElementTextUsingMetadata({
    ...storyElement,
    metadata: { ...storyElement.metadata, [type]: value }
  });
  changeFn(storyElement.id, changes);
};

const CtaCheckboxOptions = (props: CtaCheckboxOptionsProps) => {
  if (!props.storyElement) {
    return null;
  }
  const ctaOptions = getCtaOptions(props.node);
  return (
    <div className={styles["cta-options-container"]} contentEditable={false}>
      {ctaOptions.map(({ type, label }) => (
        <Checkbox
          id={`${type}-${props.storyElement["client-id"]}`}
          key={`${type}-${props.storyElement["client-id"]}`}
          label={label}
          checked={props.storyElement.metadata[type] as boolean}
          onChange={(value: boolean) => onChange(type, props.storyElement, value, props.onChange)}
          disabled={props.readOnly}
        />
      ))}
    </div>
  );
};

const mapStateToProps = (state: PartialAppState, { node }: { node: Node }) => {
  return {
    node: node,
    storyElement: state.storyEditor.story["story-elements"][node.attrs.id],
    readOnly: state.storyEditor.ui.isStoryLocked
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
  return {
    onChange: (id: any, changes: any) => dispatch({ type: actions.UPDATE_STORY_ELEMENT, payload: { id, changes } })
  };
};

export { CtaCheckboxOptions };

export default connect(mapStateToProps, mapDispatchToProps)(CtaCheckboxOptions);
