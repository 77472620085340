/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const ENTITIES = "/manage/entities";
export const ENTITIES_ADD_NEW = "/manage/entities/new";
export const ENTITIES_EDIT = "/manage/entities/:id";
export const ENTITIES_CHOOSE_IMAGE = "/manage/entities/:id/image";
export const ENTITIES_EDIT_IMAGE = "/manage/entities/:id/image/edit/:mediaKey";
