/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

export interface SEOMetadataResponse {
  "seo-metadata": SEOMetadata;
}

export interface SEOMetadata {
  "page-title": string;
  title: string;
  description: string;
  keywords: string;
}

export const getSeoMetadata = (): Promise<SEOMetadataResponse> => {
  return client
    .url("/api/seo-metadata/home")
    .get()
    .json();
};

export const saveSeoMetadata = (params: SEOMetadata): Promise<SEOMetadataResponse> => {
  return client
    .url("/api/seo-metadata/home")
    .json({ "seo-metadata": params })
    .post()
    .json();
};
