/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { CustomField } from "api/route-data/story-route-data";
import { get, has, isEmpty } from "lodash";
import { StoryTemplateFields } from "pages/breaking-news/state";

export function getTemplateCustomMetadata(
  customMetadataFields: CustomField[],
  template: string,
  storyTemplates: Partial<StoryTemplateFields>
): CustomField[] {
  if (isEmpty(customMetadataFields) || !template) return [];

  return customMetadataFields.filter(({ config }: CustomField) => {
    const enabledForAllTemplates =
      !has(config, "enabled-for-all-templates") || get(config, "enabled-for-all-templates");

    const enabledTemplates = get(config, "content-templates", []) as string[];
    const isActiveTemplate = template in storyTemplates && storyTemplates[template].toggle;

    return enabledForAllTemplates || (enabledTemplates.includes(template) && isActiveTemplate);
  });
}
