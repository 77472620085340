/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { findCardNP, findElementNP } from "../../find";
import { addIfNotExists } from "utils/array.utils";
import { CardId } from "api/primitive-types";
import { AnyStory, Card, ChildStoryElement, CompositeStoryElement, StoryElement } from "api/story";
import { EditorState } from "prosemirror-state";
import { getDistinctStoryElements } from "pages/story-editor/utils";
import { moveStoryElementToPosition } from "./utils";
import { Direction } from "pages/story-editor/operations/card/move";

function move(arr: Array<any>, from: number, to: number): Array<any> {
  let array = arr.slice();

  let element = array[from];
  array[from] = array[to];
  array[to] = element;

  return array;
}

function updateUpdatedCard(updatedCards: Array<String>, cardId: CardId): Array<String> {
  return cardId ? addIfNotExists(updatedCards, cardId) : updatedCards;
}

function moveStoryElementUpWithinACard(story: AnyStory, cardId: CardId, elementIndex: number): AnyStory {
  const updatedCards = updateUpdatedCard(story["updated-cards"], cardId);
  const cardTree = story.cards[cardId].tree;

  return {
    ...story,
    cards: {
      ...story.cards,
      [cardId]: {
        ...story.cards[cardId],
        tree: getDistinctStoryElements(move(cardTree, elementIndex, elementIndex - 1))
      }
    },
    "updated-cards": updatedCards
  };
}

function moveStoryElementUpBetweenCards(
  story: AnyStory,
  currentCardId: CardId,
  previousCardId: CardId,
  currentStoryElement: StoryElement | CompositeStoryElement | ChildStoryElement
): AnyStory {
  const currentStoryElementId = currentStoryElement.id;
  const updatedCards = [currentCardId, previousCardId].reduce(updateUpdatedCard, story["updated-cards"]);
  const newTree = story.cards[currentCardId].tree.slice();
  newTree.splice(0, 1);

  return {
    ...story,
    cards: {
      ...story.cards,
      [previousCardId]: {
        ...story.cards[previousCardId],
        tree: getDistinctStoryElements([...story.cards[previousCardId].tree, currentStoryElementId])
      },
      [currentCardId]: {
        ...story.cards[currentCardId],
        tree: getDistinctStoryElements(newTree)
      }
    },
    "story-elements": {
      ...story["story-elements"],
      [currentStoryElementId]: currentStoryElement
    },
    "updated-cards": updatedCards
  };
}

function moveStoryElementUpWithinCardNode(
  editorState: EditorState,
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement,
  previousStoryElement: StoryElement | CompositeStoryElement | ChildStoryElement
): EditorState {
  const tr = editorState.tr;
  const elementNP = findElementNP(editorState, storyElement);
  const previousElementNP = findElementNP(editorState, previousStoryElement);
  if (elementNP && previousElementNP) {
    const insertAt = previousElementNP.pos;
    const trWithFocus = moveStoryElementToPosition(tr, editorState, Direction.UP, elementNP, insertAt, storyElement);
    return editorState.apply(trWithFocus);
  }
  return editorState;
}

function moveStoryElementUpBetweenCardNodes(
  editorState: EditorState,
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement,
  previousCardLastElement: StoryElement | CompositeStoryElement | ChildStoryElement
): EditorState {
  const tr = editorState.tr;
  const elementNP = findElementNP(editorState, storyElement);
  const previousCardLastElementNP = findElementNP(editorState, previousCardLastElement);

  if (elementNP && previousCardLastElementNP) {
    const insertAt = previousCardLastElementNP.pos + previousCardLastElementNP.node.nodeSize;
    const trWithFocus = moveStoryElementToPosition(tr, editorState, Direction.UP, elementNP, insertAt, storyElement);
    return editorState.apply(trWithFocus);
  }
  return editorState;
}

function moveStoryElementUpToEmptyCard(
  editorState: EditorState,
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement,
  previousCard: Card
): EditorState {
  const tr = editorState.tr;
  const elementNP = findElementNP(editorState, storyElement);
  const emptyCardNP = findCardNP(editorState, previousCard);

  if (elementNP && emptyCardNP) {
    const insertAt = emptyCardNP.pos + 1;
    const trWithFocus = moveStoryElementToPosition(tr, editorState, Direction.UP, elementNP, insertAt, storyElement);
    return editorState.apply(trWithFocus);
  }
  return editorState;
}

function moveStoryElementUp(
  story: AnyStory,
  editorState: EditorState,
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement
): {
  story: AnyStory;
  editorState: EditorState;
} {
  const currentCardId = storyElement["card-id"];
  const currentCardTree = story.cards[currentCardId].tree;
  const currentCardIndex = story.tree.findIndex((cardData) => cardData["content-id"] === currentCardId);

  const currentStoryElementIndex = currentCardTree.findIndex((storyElementId) => storyElementId === storyElement.id);

  if (currentStoryElementIndex === 0) {
    if (currentCardIndex === 0) {
      return { story, editorState };
    } else {
      const previousCardId = story.tree[currentCardIndex - 1]["content-id"];
      const previousCardVersion = story.tree[currentCardIndex - 1]["version-id"];
      const previousCard = story.cards[previousCardId];

      const previousCardTreeSize = story.cards[previousCardId].tree.length;
      const previousCardLastElementId = story.cards[previousCardId].tree[previousCardTreeSize - 1];
      const previousCardLastElement = story["story-elements"][previousCardLastElementId];

      const newStoryElement = { ...storyElement, "card-id": previousCardId, "card-version-id": previousCardVersion };
      const newStory = moveStoryElementUpBetweenCards(story, currentCardId, previousCardId, newStoryElement);

      if (previousCardTreeSize === 0) {
        const newEditorState = moveStoryElementUpToEmptyCard(editorState, storyElement, previousCard);
        return { story: newStory, editorState: newEditorState };
      } else {
        const newEditorState = moveStoryElementUpBetweenCardNodes(
          editorState,
          newStoryElement,
          previousCardLastElement
        );
        return { story: newStory, editorState: newEditorState };
      }
    }
  } else {
    const previousStoryElementId = currentCardTree[currentStoryElementIndex - 1];
    const previousStoryElement = story["story-elements"][previousStoryElementId];

    const newStory = moveStoryElementUpWithinACard(story, currentCardId, currentStoryElementIndex);
    const newEditorState = moveStoryElementUpWithinCardNode(editorState, storyElement, previousStoryElement);

    return { story: newStory, editorState: newEditorState };
  }
}

export default moveStoryElementUp;
