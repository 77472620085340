/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface Props {
  width?: number;
  height?: number;
  fill?: string;
  variant?: "up" | "down" | "left" | "right";
}

const Chevron: React.SFC<Props> = ({ width = 16, height = 16, variant = "up", fill = "#000" }) => {
  let style;

  switch (variant) {
    case "up":
      style = { transform: "rotate(0deg)" };
      break;
    case "left":
      style = { transform: "rotate(-90deg)" };
      break;
    case "right":
      style = { transform: "rotate(90deg)" };
      break;
    case "down":
      style = { transform: "rotate(180deg)" };
      break;
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={style} width={width} height={height} fill={fill}>
      <path d="M3.85 15.77a.49.49 0 0 1 0-.62l8.06-8.28a.41.41 0 0 1 .31-.12.41.41 0 0 1 .3.12l8.06 8.28a.46.46 0 0 1 0 .62l-.3.31a.41.41 0 0 1-.31.12.54.54 0 0 1-.34-.12l-7.41-7.65-7.42 7.65a.54.54 0 0 1-.34.12.41.41 0 0 1-.31-.12z" />
    </svg>
  );
};

export default Chevron;
