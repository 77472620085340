/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Plugin, EditorState } from "prosemirror-state";
import { Decoration, DecorationSet, DecorationAttrs } from "prosemirror-view";

interface AttributesForDecorations extends DecorationAttrs {
  "data-placeholder"?: string;
}

export default () => {
  return new Plugin({
    props: {
      decorations: (editorState: EditorState) => {
        const decorations: Array<any> = [];

        editorState.doc.descendants((node, pos) => {
          if (node.attrs.placeholder) {
            if (node.type.inlineContent && (node.childCount === 0 || node.textContent === " ")) {
              decorations.push(
                Decoration.node(pos, pos + node.nodeSize, {
                  class: "placeholder",
                  "data-placeholder": node.attrs.placeholder
                } as AttributesForDecorations)
              );
            }
          }
        });

        return DecorationSet.create(editorState.doc, decorations);
      }
    }
  });
};
