/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import ActionBar from "components/action-bar/action-bar";
import { navigate } from "utils/routes.utils";
import { t } from "i18n";
import PlusCircle from "components/icons/plus-circle";
import Menu from "components/menu/menu";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import File from "components/icons/file";
import NoContentCard from "components/no-content-card/no-content-card";
import Spinner from "components/spinner/spinner";

import { PartialAppState } from "../../state";
import { LoaderState } from "behaviors/loader/state";
import { loadMenu, selectMenu } from "../../async-action-creators";
import { MenuGroupList, MenuGroup } from "api/menu-group";
import { MENU_NEW_PATH, MENU_INDEX_PATH } from "../../routes";
import styles from "./action-bar.module.css";

interface StateProps {
  loader: { loading: boolean; error: Error | null };
  menuGroups: MenuGroupList;
  selectedMenu: MenuGroup | null;
}

interface DispatchProps {
  loadMenu: () => void;
  addNewMenu: () => void;
  selectMenu: (menu: MenuGroup) => void;
}

export type ActionBarProps = StateProps & DispatchProps & { isBannerPresent: boolean; isDesktopSizeViewport: boolean };

interface LoaderProps {
  loader: LoaderState;
  loadMenu: () => void;
}

const Loader: React.SFC<LoaderProps> = ({ loader: { loading, error }, loadMenu }) => {
  if (loading) {
    return <Spinner message="Loading..." />;
  } else if (error) {
    return (
      <NoContentCard>
        <File />
        {t("menu.messages.could-not-load-list")}
        <span role="button" className="retry" onClick={loadMenu}>
          {t("menu.cta.load-retry")}
        </span>
      </NoContentCard>
    );
  } else {
    return null;
  }
};

class MenuActionBar extends React.Component<ActionBarProps> {
  componentDidMount() {
    this.props.loadMenu();
  }

  render() {
    return (
      <ActionBar
        isDesktopSizeViewport={this.props.isDesktopSizeViewport}
        isBannerPresent={this.props.isBannerPresent}
        renderContents={(onItemClickCallback) => (
          <Fragment>
            <div className={styles["sidebar-header-container"]}>
              <h3 className={styles["menu-title"]}>{t("menu.sidebar.title")}</h3>
              <div className={styles["add-new-menu"]} onClick={() => this.props.addNewMenu()}>
                <PlusCircle color="var(--brand-3)" />
              </div>
            </div>
            <LoaderWrapper component={Loader} className={styles["menu-sidebar-loader-area"]} loader={this.props.loader}>
              {this.props.menuGroups.length === 0 ? (
                <NoContentCard>
                  <File />
                  {t("menu.messages.no-menu-items")}
                </NoContentCard>
              ) : (
                <Menu
                  items={this.props.menuGroups}
                  getItemLabel={(item) => item.name}
                  onItemClick={(item) => {
                    this.props.selectMenu(item);
                    onItemClickCallback();
                  }}
                  routeTo={(item) => `${MENU_INDEX_PATH}/${item.id}`}
                  isItemSelected={(item) =>
                    this.props.selectedMenu ? item.name === this.props.selectedMenu.name : false
                  }
                  classname="sidebar-header-container-menu-title"
                />
              )}
            </LoaderWrapper>
          </Fragment>
        )}
      />
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    loader: state.menu.ui.sidebar,
    menuGroups: state.menu.menuGroups,
    selectedMenu: state.menu.app.selectedMenu
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    loadMenu: () => dispatch(loadMenu()),
    addNewMenu: () => dispatch(navigate(MENU_NEW_PATH)),
    selectMenu: (menu) => dispatch(selectMenu(menu))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuActionBar);

export { Loader, MenuActionBar };
