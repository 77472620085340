/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { t } from "i18n";
import {
  subHours,
  subDays,
  subWeeks,
  format,
  compareAsc,
  isSameDay,
  addDays,
  differenceInDays,
  isFuture
} from "date-fns";
import { timeMinutes, timeHours, timeWeeks, timeDays, timeMonths } from "d3-time";

import { RangeFilter } from "api/analytics";
import { Timestamp } from "api/primitive-types";
import { DIMENSION } from "./constants";
import { Range } from "./state";
import { ParsedQuery } from "query-string";

export function endOfPreviousUTCHour(date: Date): Date {
  return new Date(
    Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), 0, 0) - 1000
  );
}

function getRangeFilter(date: Date, duration: number, unit: string): RangeFilter {
  let previousTime: Date = date;

  switch (unit) {
    case "hour":
      previousTime = subHours(date, 1);
      break;
    case "hours":
      previousTime = subHours(date, duration);
      break;
    case "day":
      previousTime = subDays(date, 1);
      break;
    case "days":
      previousTime = subDays(date, duration);
      break;
    case "week":
      previousTime = subWeeks(date, 1);
      break;
    default:
      previousTime = subHours(date, 1);
      break;
  }

  return { "start-ts": previousTime.getTime(), "end-ts": date.getTime() };
}

export type SelectedAndComparisonDateRange = {
  "range-1": RangeFilter;
  "range-2": RangeFilter;
};

export function getDateRange(period: string): SelectedAndComparisonDateRange {
  const now = new Date();
  switch (period) {
    case "hour":
      return {
        "range-1": getRangeFilter(now, 1, "hour"),
        "range-2": getRangeFilter(subDays(now, 1), 1, "hour")
      };
    case "4-hours":
      return {
        "range-1": getRangeFilter(now, 4, "hours"),
        "range-2": getRangeFilter(subDays(now, 1), 4, "hours")
      };
    case "12-hours":
      return {
        "range-1": getRangeFilter(now, 12, "hours"),
        "range-2": getRangeFilter(subDays(now, 1), 12, "hours")
      };
    case "day":
      return {
        "range-1": getRangeFilter(now, 1, "day"),
        "range-2": getRangeFilter(subDays(now, 1), 1, "day")
      };
    case "days":
      return {
        "range-1": getRangeFilter(now, 3, "days"),
        "range-2": getRangeFilter(subDays(now, 1), 1, "day")
      };
    case "week":
      return {
        "range-1": getRangeFilter(endOfPreviousUTCHour(now), 1, "week"),
        "range-2": getRangeFilter(subWeeks(endOfPreviousUTCHour(now), 1), 1, "week")
      };
    case "month":
      return {
        "range-1": getRangeFilter(endOfPreviousUTCHour(now), 30, "days"),
        "range-2": getRangeFilter(subDays(endOfPreviousUTCHour(now), 30), 30, "days")
      };
    default:
      return {
        "range-1": getRangeFilter(now, 1, "hour"),
        "range-2": getRangeFilter(subDays(now, 1), 1, "hour")
      };
  }
}

export function comparison(dateRangeName: string, startTimestamp: Timestamp, endTimestamp: Timestamp): string {
  let comparison;
  if (dateRangeName.endsWith("hour") || dateRangeName.endsWith("hours")) {
    comparison = `${format(startTimestamp, "MMM dd yyyy hh:mm aaa")}-${format(endTimestamp, "hh:mm aaa")}`;
  } else {
    comparison = `${format(startTimestamp, "MMM dd yyyy ")} - ${format(endTimestamp, "MMM dd yyyy ")}`;
  }
  return comparison;
}

export function getDateRangeComparison(
  dateRangeName: string
): { currentRangeComparison: string; prevRangeComparison: string } {
  const selectedAndComparisonDateRange = getDateRange(dateRangeName);

  const prevRangeComparison = comparison(
    dateRangeName,
    selectedAndComparisonDateRange["range-2"]["start-ts"],
    selectedAndComparisonDateRange["range-2"]["end-ts"]
  );
  const currentRangeComparison = comparison(
    dateRangeName,
    selectedAndComparisonDateRange["range-1"]["start-ts"],
    selectedAndComparisonDateRange["range-1"]["end-ts"]
  );
  return { currentRangeComparison, prevRangeComparison };
}

export function xAxisLabelFormatter(dateRangeName: string, timeStamp: Timestamp, data: Timestamp[]) {
  const startTs = new Date(data[0]);
  const endTs = new Date(data[data.length - 1]);
  const derivedDateRange = getDerivedDateRange(dateRangeName, startTs, endTs);
  let dateFormat = "ddd dd h:mm aaa";
  if (derivedDateRange.endsWith("hour")) {
    dateFormat = "hh:mm";
  } else if (derivedDateRange.endsWith("hours") || derivedDateRange.endsWith("day")) {
    dateFormat = "hh a";
  } else if (derivedDateRange.endsWith("week")) {
    dateFormat = "EEEEEE d";
  } else {
    dateFormat = "MMM d";
  }

  return format(timeStamp, dateFormat);
}

export function getTopListHeader(dimension: string, metrics: string, dataCount: number) {
  switch (dimension) {
    case DIMENSION.SECTION:
      return t("analytics.top_sections.header", { metrics: t(`analytics.${metrics}`), sectionsCount: dataCount });
    case DIMENSION.STORY:
      return t("analytics.top_stories.header", { metrics: t(`analytics.${metrics}`), storiesCount: dataCount });
    case DIMENSION.AUTHOR:
      return t("analytics.top_authors.header", { metrics: t(`analytics.${metrics}`), authorsCount: dataCount });
  }
}

function compare(item: Timestamp, currentPointTimeStamp: Timestamp) {
  return compareAsc(item, currentPointTimeStamp) === 0 ? true : false;
}

function getDerivedDateRange(dateRangeName: string, startTs: Date, endTs: Date) {
  if (dateRangeName !== "custom") {
    return dateRangeName;
  }

  const daysDifference = differenceInDays(endTs, startTs);

  if (daysDifference <= 1) {
    return "day";
  } else if (daysDifference <= 14) {
    return "week";
  } else if (daysDifference <= 90) {
    return "month";
  } else {
    return "year";
  }
}

export function generateXAxisTicks(dateRangeName: string, data: Timestamp[]) {
  const startTs = new Date(data[0]);
  const endTs = new Date(data[data.length - 1]);
  const derivedDateRangeName = getDerivedDateRange(dateRangeName, startTs, endTs);

  switch (derivedDateRangeName) {
    case "hour":
      return timeMinutes(startTs, endTs, 10);
    case "4-hours":
      return timeHours(startTs, endTs, 1);
    case "12-hours":
      return timeHours(startTs, endTs, 2);
    case "day":
      return timeHours(startTs, endTs, 3);
    case "week":
      return timeDays(startTs, endTs, 1);
    case "month":
      return timeWeeks(startTs, endTs, 1);
    case "year":
      return timeMonths(startTs, endTs, 2);
    default:
      return [];
  }
}

export function getNextPeriod(dateCurTs: Timestamp, timestampData: Timestamp[], period: string, graphName: string) {
  const positionOfDateCurTs = timestampData.findIndex((item) => compare(item, dateCurTs));
  const dateRange = getDateRange(period);

  const oneSecond = 1000;
  const range = graphName === "current" ? dateRange["range-1"] : dateRange["range-2"];
  if (positionOfDateCurTs === 0 && period !== "custom") {
    dateCurTs = range["start-ts"];
  }

  let dateNextTs = timestampData[positionOfDateCurTs + 1];
  let result = format(dateCurTs, "MMM dd hh:mm");
  if (!dateNextTs) {
    dateNextTs = range["end-ts"] + oneSecond; // the end time is included but visually excluded, hence +1
  }
  if (dateNextTs) {
    if (isSameDay(dateCurTs, dateNextTs)) {
      return result + " - " + format(dateNextTs, "h:mma");
    } else {
      return result + " - " + format(dateNextTs, "MMM dd h:mma");
    }
  }

  return null;
}

export function getEndDateBasedOnValuesPeriod(startDate: Date, customRange: Range) {
  const rangeTwoStartDate = startDate;
  const periodOfRangeOne = differenceInDays(customRange["range-1"]["end-ts"], customRange["range-1"]["start-ts"]);
  const endDate = addDays(rangeTwoStartDate, periodOfRangeOne);
  return isFuture(endDate) ? new Date() : endDate;
}

export function getDateRangeFromQueryParam(customRange: ParsedQuery) {
  const range1 = customRange["range-1"] as string;
  const range2 = customRange["range-2"] as string;
  const [startTsRange1, endTsRange1] = range1.split("-");
  const [startTsRange2, endTsRange2] = range2.split("-");
  return {
    "range-1": { "start-ts": parseInt(startTsRange1), "end-ts": parseInt(endTsRange1) },
    "range-2": { "start-ts": parseInt(startTsRange2), "end-ts": parseInt(endTsRange2) }
  };
}
