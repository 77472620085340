/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { Timestamp, CustomURLId } from "./primitive-types";

export enum CustomURLType {
  Redirect = "redirect"
}

export interface CustomURL {
  "created-at"?: Timestamp;
  "destination-path": string;
  id: CustomURLId;
  "source-path": string;
  "status-code": number | null;
  type: CustomURLType;
}

export interface CustomURLsListResponse {
  "custom-urls": CustomURL[];
  pagination: number;
}

export type UnsavedCustomURL = Pick<CustomURL, "destination-path" | "source-path" | "type">;

export type AnyCustomURL = UnsavedCustomURL | CustomURL;

export const getCustomURLsList = (
  searchTerm?: string,
  limit: number = 10,
  offset: number = 0
): Promise<CustomURLsListResponse> => {
  const query = searchTerm && searchTerm.length > 0 ? { offset, limit, "source-path": searchTerm } : { offset, limit };
  return client
    .url("/api/custom-url")
    .query(query)
    .get()
    .json();
};

export const saveNewCustomURL = (customURL: UnsavedCustomURL): Promise<{ id: CustomURLId }> => {
  return client
    .url("/api/custom-url")
    .json(customURL)
    .post()
    .json();
};

export const updateCustomURL = (customURL: CustomURL): Promise<void> => {
  return client
    .url(`/api/custom-url/${customURL.id}`)
    .json(customURL)
    .put()
    .res();
};

export const deleteCustomURL = (id: CustomURLId): Promise<any> => {
  return client
    .url(`/api/custom-url/${id}`)
    .delete()
    .res();
};
