/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";

import { PartialAppState } from "../../initial-state";
import wrapPage from "containers/page/page";
import { LoaderState } from "behaviors/loader/state";
import { loadSettingsPageDataAction, updateSocialLinksAction, updateSeoMetadataAction } from "./async-action-creators";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import SettingsHeader from "./settings-header";
import General from "./general";
import SocialLinks, { SocialLinkProvider } from "components/social-links/social-links";
import SeoMetadata from "./seo-metadata";
import * as Types from "api/settings";
import { SEOMetadata } from "api/seo-metadata";
import Loader from "./loader";
import classnames from "classnames/bind";
import styles from "./settings.module.css";

const cx = classnames.bind(styles);
interface StateProps {
  loader: LoaderState;
  socialLinks: Types.SocialLinks | null;
  seoMetadata: SEOMetadata | null;
  defaultSocialLinkProviders: Array<SocialLinkProvider>;
}

interface DispatchProps {
  loadSettingsPageData: () => void;
  updateSocialLinks: (changes: object) => void;
  updateSeoMetadata: (changes: object) => void;
}

type Props = StateProps &
  DispatchProps & {
    activeMenu: string;
    isBannerPresent: boolean;
  };

class Settings extends React.Component<Props> {
  async componentDidMount() {
    await this.props.loadSettingsPageData();
  }

  render() {
    const { loader } = this.props;
    return (
      <React.Fragment>
        <LoaderWrapper className="settings-progress-main-area" component={Loader} loader={loader}>
          <div className={cx("settings-page-container", "general-settings-page")}>
            <section className={styles["settings-layout"]} data-test-id="settings-general-settings">
              <div className={styles["settings-details"]} data-test-id="general-settings-details">
                <h3 className={styles["settings-title"]}>{t("settings.generalSettings.title")} </h3>
                <p className={styles["settings-description"]}>{t("settings.generalSettings.description")}</p>
              </div>
              <div className={styles["settings-fields"]} data-test-id="general-settings-content">
                <General />
              </div>
            </section>
            <section className={styles["settings-layout"]} data-test-id="settings-home-seo">
              <div className={styles["settings-details"]} data-test-id="home-seo-details">
                <h3 className={styles["settings-title"]}>{t("settings.seoMetadata.title")} </h3>
                <p className={styles["settings-description"]}>{t("settings.seoMetadata.description")}</p>
              </div>
              <div className={styles["settings-fields"]} data-test-id="home-seo-content">
                <SeoMetadata seoMetadata={this.props.seoMetadata} updateSeoMetadata={this.props.updateSeoMetadata} />
              </div>
            </section>
            <section className={styles["settings-layout"]} data-test-id="settings-social-settings">
              <div className={styles["settings-details"]} data-test-id="social-settings-details">
                <h3 className={styles["settings-title"]}>{t("settings.socialLinks.title")}</h3>
                <p className={styles["settings-description"]}>{t("settings.socialLinks.description")}</p>
              </div>
              <div className={styles["settings-fields"]} data-test-id="social-settings-content">
                <SocialLinks
                  defaultSocialLinkProviders={this.props.defaultSocialLinkProviders}
                  socialLinks={this.props.socialLinks}
                  updateSocialLinks={this.props.updateSocialLinks}
                />
              </div>
            </section>
          </div>
        </LoaderWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    loader: state.settingsPage.ui.main,
    socialLinks: state.settingsPage.socialLinks,
    seoMetadata: state.settingsPage.seoMetadata,
    defaultSocialLinkProviders: state.config["default-social-link-providers"] || []
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    loadSettingsPageData: () => dispatch(loadSettingsPageDataAction()),
    updateSocialLinks: (socialLinks) => dispatch(updateSocialLinksAction(socialLinks)),
    updateSeoMetadata: (changes) => dispatch(updateSeoMetadataAction(changes))
  };
};

export default compose<any, any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  wrapPage({ HeaderComponent: SettingsHeader })
)(Settings);

export { Settings };
