/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import styles from "./oembed-video-selector.module.css";
import Select from "components/select/select";
import { VideoOEmbedFilter, VideoOEmbedFilters } from "api/route-data/story-route-data";
import { VideoOEmbedSelectedSearchFilters } from "pages/story-editor/state";
import { t } from "i18n";

interface Props {
  filters: VideoOEmbedFilters;
  selectedFilters: VideoOEmbedSelectedSearchFilters;
  onChange: (filterKey: string, filterValue: VideoOEmbedFilter) => void;
}

const SearchFilters: React.FC<Props> = ({ filters, selectedFilters, onChange }) => {
  return (
    <div className={styles["search-filters-wrapper"]}>
      <Select
        value={selectedFilters.provider}
        onChange={(provider: VideoOEmbedFilter) => {
          provider && !Array.isArray(provider) && onChange("provider", provider);
        }}
        options={filters.providers}
        getOptionLabel={(provider: VideoOEmbedFilter) => provider.name}
        getOptionValue={(provider: VideoOEmbedFilter) => provider.id || ""}
        menuPlacement="bottom"
        hideError={true}
        label={t("story-editor.inspector.oembed-video-selector-provider-filter")}
      />
    </div>
  );
};

export default SearchFilters;
