/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import get from "lodash/get";

import styles from "./video-story-element.module.css";

const VIDEO_PROVIDER_EMBEDS = {
  "vod-video": "//vod-platform.net/Embed/$videoID",
  "dailymotion-video": "//www.dailymotion.com/embed/video/$videoID"
};

const VIDEO_PROVIDER_URLS = {
  "facebook-video": ["metadata", "facebook-url"],
  "dailymotion-video": ["metadata", "video-id"],
  "youtube-video": ["embed-url"],
  "vod-video": ["metadata", "video-id"],
  "brightcove-video": ["metadata", "player-url"],
  "soundcloud-audio": ["embed-url"],
  jwplayer: ["metadata", "player-url"],
  video: ["metadata", "video-clip-url"]
  //change to thumbnail-url for showing just image
};

const getEmbedURL = (storyElement, provider) => {
  const videoDetails = get(storyElement, VIDEO_PROVIDER_URLS[provider]);

  if (provider === "dailymotion-video") {
    return VIDEO_PROVIDER_EMBEDS[provider].replace("$videoID", storyElement.metadata["video-id"]);
  }

  if (provider === "youtube-video") {
    return videoDetails;
  }

  if (provider === "brightcove-video") {
    return videoDetails;
  }

  if (provider === "vod-video") {
    return VIDEO_PROVIDER_EMBEDS[provider].replace("$videoID", storyElement.metadata["video-id"]);
  }

  if (provider === "soundcloud-audio") {
    return videoDetails;
  }

  if (provider === "jwplayer") {
    return videoDetails;
  }

  if (provider === "video") {
    return videoDetails;
  }
};

const VideoStoryElement = ({ storyElement, provider, showPreview }) => {
  const embedURL = getEmbedURL(storyElement, provider);
  if (!showPreview || !embedURL) {
    return null;
  }
  return (
    <div className={styles["video-story-element-container"]}>
      <div className={styles["video-story-element-video-wrapper"]}>
        <iframe
          className={styles["video-story-element-video"]}
          width="100%"
          height="100%"
          src={embedURL}
          frameBorder="0"
          allowFullScreen
          title={`title-${storyElement.id}`}
        />
      </div>
    </div>
  );
};

export default VideoStoryElement;
