/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "@reduxjs/toolkit";
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from "containers/page/actions";
import { t } from "i18n";
import { PartialAppState } from "pages/settings/initial-state";
import { disableStorySlugSaveButton, updateStorySlug } from "./action-creators";
import * as PublisherAPI from "api/publisher";
import { setConfigState } from "store/route-data";

export const updateStorySlugSettings = () => async (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  getState: () => PartialAppState
) => {
  try {
    const publisherSettings = await PublisherAPI.updatePublisher({ "story-slug": getState().settingsPage.storySlug });
    if (publisherSettings && publisherSettings["story-slug"]) {
      dispatch(updateStorySlug(publisherSettings["story-slug"]));
      dispatch(
        setConfigState({
          ...getState().config,
          "story-slug": {
            ...getState().config["story-slug"],
            ...publisherSettings["story-slug"]
          }
        })
      );
    }
    dispatch(disableStorySlugSaveButton());
    dispatch({
      type: NOTIFICATION_SUCCESS,
      payload: { message: t("settings.story-slug.messages.update-slug-settings-success") }
    });
  } catch (error) {
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: { message: t("settings.story-slug.messages.update-slug-settings-fail") }
    });
  }
};
