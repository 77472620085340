/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const MicrosoftEntra = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} viewBox="0 0 30 15">
    <title>{"MS-SymbolLockup"}</title>
    <path fill="#f25022" d="M1 1h9v9H1z" />
    <path fill="#00a4ef" d="M1 11h9v9H1z" />
    <path fill="#7fba00" d="M11 1h9v9h-9z" />
    <path fill="#ffb900" d="M11 11h9v9h-9z" />
  </svg>
);

export default MicrosoftEntra;
