/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import produce from "immer";
import { State, INITIAL_STATE, NEW_MENU, MenuPageInspectorType, MenuPageItemInspectorType } from "./state";
import actions, { ActionTypes } from "./actions";

function reducer(state: State = INITIAL_STATE, action: ActionTypes) {
  return produce(state, (draft) => {
    switch (action.type) {
      case actions.LOAD_MENU:
        draft.ui.sidebar = { loading: true, error: null };
        draft.ui.main = { loading: false, error: null };
        return;

      case actions.LOAD_MENU_SUCCESS:
        draft.ui.sidebar = { loading: false, error: null };
        draft.menuGroups = action.payload.data;
        return;

      case actions.LOAD_MENU_FAILURE:
        draft.ui.sidebar = { loading: false, error: action.payload.error };
        return;

      case actions.INIT_NEW_MENU:
        draft.app.currentMenu = NEW_MENU;
        draft.ui.inspector = MenuPageInspectorType.Create;
        return;

      case actions.UPDATE_MENU:
        draft.app.currentMenu = action.payload.data;
        return;

      case actions.CANCEL_CREATE_MENU:
        draft.app.currentMenu = null;
        draft.ui.inspector = null;
        draft.ui.save = { loading: false, error: null };
        return;

      case actions.SAVE_MENU:
        draft.ui.save = { loading: true, error: null };
        return;

      case actions.SAVE_MENU_SUCCESS:
        draft.ui.save = { loading: false, error: null };
        draft.app.currentMenu = null;
        draft.ui.inspector = null;
        return;

      case actions.SAVE_MENU_FAILURE:
        draft.ui.save = { loading: false, error: action.payload.error };
        return;

      case actions.SELECT_MENU:
        draft.ui.inspector = null;
        draft.app.selectedMenu = action.payload.menu;
        draft.ui.save = { loading: false, error: null };
        return;

      case actions.LIST_MENU_ITEMS:
        draft.ui.main = { loading: true, error: null };
        return;

      case actions.LIST_MENU_ITEMS_SUCCESS:
        draft.ui.main = { loading: false, error: null };
        draft.menuItems = action.payload.menuItems;
        return;

      case actions.LIST_MENU_ITEMS_FAILURE:
        draft.ui.main = { loading: false, error: action.payload.error };
        return;

      case actions.REPLACE_CURRENT_MENU_ITEM:
        draft.app.currentMenuItem = action.payload.menuItem;
        return;

      case actions.INIT_NEW_MENU_ITEM:
        draft.app.currentMenuItem = action.payload.menuItem;
        draft.ui.inspector = MenuPageItemInspectorType.Create;
        return;

      case actions.EDIT_MENU_ITEM:
        draft.app.currentMenuItem = action.payload.menuItem;
        draft.ui.inspector = MenuPageItemInspectorType.Edit;
        return;

      case actions.EDIT_MENU_ITEM_FAILURE:
        draft.ui.main = { loading: false, error: action.payload.error };
        return;

      case actions.CANCEL_CREATE_OR_EDIT_MENU_ITEM:
        draft.app.currentMenuItem = null;
        draft.ui.inspector = null;
        draft.ui.save = { loading: false, error: null };
        return;

      case actions.SAVE_MENU_ITEM:
        draft.ui.save = { loading: true, error: null };
        return;

      case actions.SAVE_MENU_ITEM_SUCCESS:
        draft.ui.save = { loading: false, error: null };
        draft.app.currentMenuItem = null;
        draft.ui.inspector = null;
        return;

      case actions.SAVE_MENU_ITEM_FAILURE:
        draft.ui.save = { loading: false, error: action.payload.error };
        return;

      case actions.DELETE_MENU_ITEM:
        draft.ui.menuItems[action.payload.menuItem.id] = { loading: true, error: null };
        return;

      case actions.DELETE_MENU_ITEM_SUCCESS:
        draft.ui.menuItems[action.payload.menuItem.id] = { loading: false, error: null };
        return;

      case actions.DELETE_MENU_ITEM_FAILURE:
        draft.ui.menuItems[action.payload.menuItem.id] = { loading: false, error: action.payload.error };
        return;

      case actions.MOVE_MENU_ITEM:
        draft.ui.menuItems[action.payload.menuItem.id] = { loading: true, error: null };
        return;

      case actions.MOVE_MENU_ITEM_SUCCESS:
        draft.ui.menuItems[action.payload.menuItem.id] = { loading: false, error: null };
        return;

      case actions.MOVE_MENU_ITEM_FAILURE:
        draft.ui.menuItems[action.payload.menuItem.id] = { loading: false, error: action.payload.error };
        return;

      case actions.TOGGLE_DELETE_CONFIRMATION:
        draft.ui.deleteConfirmationVisible = !draft.ui.deleteConfirmationVisible;
        draft.app.itemToBeDeleted = action.payload;
        return;

      case actions.RESET_MENU:
        return INITIAL_STATE;

      case actions.SET_GENERATED_SLUG:
        draft.app.currentMenu = action.payload.menuGroup;
        return;

      default:
        return;
    }
  });
}

export default reducer;
