/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";
import { mapIntegrationToComponent } from "pages/settings/pages/integrations/map-integration-to-component";
import Inspector from "components/inspector/inspector";
import {
  IntegrationsPageInspectorType,
  IntegrationType,
  PartialAppState,
  Publisher
} from "pages/settings/pages/integrations/state";
import Select from "components/select/select";
import {
  onChangeOfIntegrationAction,
  updateIntegrationFieldAction,
  editIntegrationAction
} from "pages/settings/pages/integrations/action-creators";
import {
  clearSaveIntegrationsErrors,
  loadSupportedIntegrations,
  saveIntegration,
  savePublisherSettings,
  validateIntegration
} from "pages/settings/pages/integrations/async-action-creators";
import { IntegrationConfig, Integrations } from "api/integrations";
import { isEmptyObject } from "utils/object.utils";
import { capitalize, isEqual, toLower } from "lodash";
import PushNotificationSettings from "pages/settings/pages/integrations/components/push-notification-settings/push-notification-settings";
import CategoryTitle from "components/category-title/category-title";

interface OwnProps {
  inspectorType: IntegrationsPageInspectorType | null;
  integrationName?: Integrations;
  onClose?: () => void;
}

interface StateProps {
  selectedIntegration?: IntegrationConfig | null;
  listIntegrationsAvailableToAdd?: Integrations[];
  errors: Error | null;
  publisher?: Publisher;
  integrationType?: IntegrationType;
  isValidateInProgress?: boolean;
}

interface DispatchProps {
  saveIntegration?: (selectedIntegration: IntegrationConfig) => void;
  validateIntegration?: (selectedIntegration: IntegrationConfig) => void;
  onChangeOfIntegration?: (value: Integrations) => void;
  updateIntegrationField?: (key: string, value: Object) => void;
  initializeAddIntegration?: () => void;
  initializeEditIntegration?: (integrationName: Integrations) => void;
  savePublisherSettings?: (settings: Publisher) => void;
  clearSaveErrors: () => void;
}

type Props = OwnProps & StateProps & DispatchProps;

const getInspectorProps = (
  inspectorType: IntegrationsPageInspectorType | null,
  selectedIntegration: IntegrationConfig | null,
  saveIntegration: ((selectedIntegration: IntegrationConfig) => void) | null,
  localPublisher: Publisher,
  savePublisherSettings: ((settings: Publisher) => void) | null,
  publisher: Publisher | null,
  onClose?: () => void
) => {
  let title, actionButtonLabel, isActionButtonDisabled, onActionButtonClick;
  switch (inspectorType) {
    case IntegrationsPageInspectorType.Create:
      title = t("integrations.inspector.create_title");
      actionButtonLabel = t("integrations.inspector.create_button");
      isActionButtonDisabled = !selectedIntegration || isEmptyObject(selectedIntegration);
      onActionButtonClick = () => selectedIntegration && saveIntegration && saveIntegration(selectedIntegration);
      break;
    case IntegrationsPageInspectorType.Edit:
      title = t("integrations.inspector.update_title");
      actionButtonLabel = t("integrations.inspector.update_button");
      isActionButtonDisabled = !selectedIntegration || isEmptyObject(selectedIntegration);
      onActionButtonClick = () => selectedIntegration && saveIntegration && saveIntegration(selectedIntegration);
      break;
    case IntegrationsPageInspectorType.INTEGRATION_SETTINGS:
      title = t("common.settings");
      actionButtonLabel = t("integrations.inspector.update_button");
      isActionButtonDisabled = isEqual(publisher, localPublisher);
      onActionButtonClick = () => {
        savePublisherSettings && savePublisherSettings(localPublisher);
        onClose && onClose();
      };
      break;
  }
  return [title, actionButtonLabel, isActionButtonDisabled, onActionButtonClick];
};

const IntegrationsInspector: React.SFC<Props> = ({
  onChangeOfIntegration,
  listIntegrationsAvailableToAdd,
  selectedIntegration,
  validateIntegration,
  isValidateInProgress,
  updateIntegrationField,
  saveIntegration,
  initializeAddIntegration,
  initializeEditIntegration,
  inspectorType,
  errors,
  integrationName,
  onClose,
  publisher,
  savePublisherSettings,
  integrationType,
  clearSaveErrors
}) => {
  useEffect(() => {
    switch (inspectorType) {
      case IntegrationsPageInspectorType.Create:
        initializeAddIntegration && initializeAddIntegration();
        break;
      case IntegrationsPageInspectorType.Edit:
        integrationName && initializeEditIntegration && initializeEditIntegration(integrationName);
        break;
    }
  }, [initializeAddIntegration, initializeEditIntegration, inspectorType, integrationName]);

  // Creating a local publisher so that changes in push notification settings
  // will not update the publisher settings in main state.
  const [localPublisher, setLocalPublisher] = useState<Publisher>({});

  useEffect(() => {
    if (
      inspectorType === IntegrationsPageInspectorType.INTEGRATION_SETTINGS &&
      integrationType === IntegrationType.PUSH_NOTIFICATION
    ) {
      publisher && setLocalPublisher(publisher);
    }
  }, [publisher, inspectorType, integrationType]);

  const [title, actionButtonLabel, isActionButtonDisabled, onActionButtonClick] = getInspectorProps(
    inspectorType,
    selectedIntegration || null,
    saveIntegration || null,
    localPublisher,
    savePublisherSettings || null,
    publisher || null,
    onClose
  );

  const showMapIntegrationToComponent =
    inspectorType &&
    [IntegrationsPageInspectorType.Create, IntegrationsPageInspectorType.Edit].includes(inspectorType) &&
    selectedIntegration &&
    selectedIntegration.value &&
    updateIntegrationField &&
    mapIntegrationToComponent(
      selectedIntegration,
      false,
      updateIntegrationField,
      errors,
      validateIntegration,
      isValidateInProgress
    );

  return (
    <Inspector
      title={title}
      isActive={!!inspectorType}
      onClose={() => {
        clearSaveErrors();
        onClose && onClose();
      }}
      actionButtonLabel={actionButtonLabel}
      isActionButtonDisabled={isActionButtonDisabled}
      onActionButtonClick={onActionButtonClick}>
      {inspectorType && (
        <>
          <div className="add-integration-container">
            {inspectorType === IntegrationsPageInspectorType.Create && (
              <Select
                options={listIntegrationsAvailableToAdd}
                getOptionLabel={(integration: Integrations) =>
                  t(`integrations.providers.${toLower(integration)}`, capitalize(integration))
                }
                getOptionValue={(integration) => integration || ""}
                onChange={(value: Integrations) => onChangeOfIntegration && onChangeOfIntegration(value)}
                value={[selectedIntegration && (selectedIntegration.key as Integrations)]}
              />
            )}
            {inspectorType === IntegrationsPageInspectorType.Edit && selectedIntegration && (
              <CategoryTitle
                title={t(`integrations.providers.${selectedIntegration.key}`, capitalize(selectedIntegration.key))}
              />
            )}
            {showMapIntegrationToComponent}
          </div>
          {inspectorType === IntegrationsPageInspectorType.INTEGRATION_SETTINGS &&
            integrationType === IntegrationType.PUSH_NOTIFICATION && (
              <PushNotificationSettings
                settings={localPublisher}
                updateSettings={(settings) => setLocalPublisher(settings)}
              />
            )}
        </>
      )}
    </Inspector>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    selectedIntegration: state.integrations.app.selectedIntegration,
    listIntegrationsAvailableToAdd: state.integrations.app.listIntegrationsAvailableToAdd,
    errors: state.integrations.ui.saveErrors.error,
    isValidateInProgress: state.integrations.ui.isValidateInProgress,
    publisher: state.integrations.app.publisher,
    integrationType: state.integrations.app.integrationType
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    onChangeOfIntegration: (selectedIntegration) => {
      dispatch(clearSaveIntegrationsErrors());
      dispatch(onChangeOfIntegrationAction(selectedIntegration));
    },
    updateIntegrationField: (key, changes) => dispatch(updateIntegrationFieldAction(key, changes)),
    saveIntegration: (selectedIntegration) => dispatch(saveIntegration(selectedIntegration)),
    validateIntegration: (selectedIntegration) => dispatch(validateIntegration(selectedIntegration)),
    initializeAddIntegration: () => dispatch(loadSupportedIntegrations()),
    initializeEditIntegration: (integrationName: Integrations) => dispatch(editIntegrationAction(integrationName)),
    savePublisherSettings: (settings: Publisher) => dispatch(savePublisherSettings(settings)),
    clearSaveErrors: () => dispatch(clearSaveIntegrationsErrors())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationsInspector);

export { IntegrationsInspector };
