/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { getStringTabValue, TabValue } from "./tab-list";

const PUSH_NOTIFICATION_TAB = `${getStringTabValue(TabValue.ALL)}|${getStringTabValue(
  TabValue.PUBLISHED
)}|${getStringTabValue(TabValue.SCHEDULED)}`;

export const PUSH_NOTIFICATION_INDEX_PATH = "/content/push-notifications";
export const PUSH_NOTIFICATION_LISTING_PATH = `${PUSH_NOTIFICATION_INDEX_PATH}/:type(${PUSH_NOTIFICATION_TAB})`;
export const PUSH_NOTIFICATION_NEW_PATH = `${PUSH_NOTIFICATION_INDEX_PATH}/:type(${PUSH_NOTIFICATION_TAB})/new`;
export const PUSH_NOTIFICATION_EDIT_PATH = `${PUSH_NOTIFICATION_INDEX_PATH}/:type(${PUSH_NOTIFICATION_TAB})/:pushNotificationId`;
