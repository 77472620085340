/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ExtendableError } from "utils/error.utils";
import { toClientValidationError, validate } from "utils/validation.utils";
import { t } from "i18n";
import { mapValues } from "lodash";
import { StaticPageMetadataSeo } from "api/static-pages";

export class StaticPageNotFoundError extends ExtendableError {
  id: number;

  constructor(id: number, message: string) {
    super(message);
    this.id = id;
  }
}

export class StaticPageValidationError extends ExtendableError {
  error: any;
  constructor(
    message: string,
    body: {
      status: number;
      error: { [key: string]: any };
    }
  ) {
    super(message);
    this.error = body.error;
  }
}

export const META_FIELDS_LIMIT = 500;

const validateMetadata = (staticPageMetadataSeo: StaticPageMetadataSeo) => {
  return validate(staticPageMetadataSeo, {
    "meta-title": {
      length: {
        maximum: META_FIELDS_LIMIT,
        message:
          "^" + t("staticPages.form.inspector.seo.errors.max-meta-title-limit", { metaTitleLimit: META_FIELDS_LIMIT })
      }
    },
    "meta-description": {
      length: {
        maximum: META_FIELDS_LIMIT,
        message:
          "^" +
          t("staticPages.form.inspector.seo.errors.max-meta-description-limit", {
            metaDescriptionLimit: META_FIELDS_LIMIT
          })
      }
    }
  });
};

export function getValidationErrors(staticPageMetadataSeo: StaticPageMetadataSeo) {
  const validationResult = validateMetadata(staticPageMetadataSeo);
  const errors = validationResult && toClientValidationError<StaticPageMetadataSeo>(validationResult).errors;
  return errors ? mapValues(errors, (errorDescription) => errorDescription && errorDescription[0]) : {};
}
