/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect } from "react";
import { AnyAction, compose } from "redux";
import { connect } from "react-redux";
import wrapPage from "containers/page/page";
import { PartialAppState, StorySlugSettingsState, StorySlugTypes } from "pages/settings/initial-state";
import { StorySlugConfig } from "api/route-data/route-data";
import SettingsContainer from "components/settings/settings-container/settings-container";
import SettingsSection from "components/settings/settings-section/settings-section";
import Select from "components/select/select";
import { get } from "lodash";
import { ThunkDispatch } from "redux-thunk";
import { disableStorySlugSaveButton, enableStorySlugSaveButton, updateStorySlug } from "./action-creators";
import StorySlugHeader from "./components/story-slug-header";
import { t } from "i18n";
import { showUnauthorizedPage } from "store/error-page";

interface StateProps {
  storySlugConfig: StorySlugConfig;
  storySlugSettings: StorySlugSettingsState;
  canAccessStorySlug: boolean;
}

interface DispatchProps {
  updateStorySlugType: (slugType: StorySlugTypes) => void;
  enableSaveButton: () => void;
  disableSaveButton: () => void;
  showUnauthorizedPage: () => void;
}

type StorySlugSettingsProps = StateProps & DispatchProps;

export const StorySlugSettings: React.FC<StorySlugSettingsProps> = ({
  storySlugConfig,
  storySlugSettings,
  updateStorySlugType,
  enableSaveButton,
  disableSaveButton,
  canAccessStorySlug,
  showUnauthorizedPage
}) => {
  useEffect(() => {
    if (storySlugConfig && storySlugConfig.type) {
      updateStorySlugType(storySlugConfig.type);
    }
  }, [updateStorySlugType, storySlugConfig]);

  useEffect(() => {
    if (get(storySlugSettings, "type", null) !== get(storySlugConfig, "type", null)) {
      enableSaveButton();
    } else {
      disableSaveButton();
    }
  }, [enableSaveButton, disableSaveButton, storySlugConfig, storySlugSettings]);

  const storySlugType = storySlugSettings.type || StorySlugTypes.Headline;

  const getSlugTypeOptions = () => {
    if (storySlugConfig && storySlugConfig.options) {
      return storySlugConfig.options.map((type) => ({ name: type }));
    }
    return [];
  };

  if (!canAccessStorySlug) {
    showUnauthorizedPage();
  }

  return (
    <>
      <SettingsContainer>
        <SettingsSection title={t("settings.story-slug.settings.title")}>
          <Select
            label={t("settings.story-slug.settings.fields.story-slug-type.label")}
            options={getSlugTypeOptions()}
            getOptionLabel={(type) => t(`settings.story-slug.settings.fields.story-slug-type.${type.name}`)}
            getOptionValue={(type) => type.name}
            helpText={t(`settings.story-slug.settings.fields.story-slug-type.${storySlugType}-hint`)}
            value={{ name: storySlugType || "" }}
            onChange={(value) => value && updateStorySlugType(value["name"])}
          />
        </SettingsSection>
      </SettingsContainer>
    </>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    storySlugConfig: state.config["story-slug"],
    storySlugSettings: state.settingsPage.storySlug,
    canAccessStorySlug: !!state.features.canAccessStorySlug
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    updateStorySlugType: (slugType) => dispatch(updateStorySlug({ type: slugType })),
    enableSaveButton: () => dispatch(enableStorySlugSaveButton()),
    disableSaveButton: () => dispatch(disableStorySlugSaveButton()),
    showUnauthorizedPage: () => dispatch(showUnauthorizedPage())
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  wrapPage({ HeaderComponent: StorySlugHeader })
)(StorySlugSettings);
