/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import Entities from "./entities";

import { ENTITIES, ENTITIES_ADD_NEW, ENTITIES_EDIT, ENTITIES_CHOOSE_IMAGE, ENTITIES_EDIT_IMAGE } from "./routes";

export const Router = () => (
  <React.Fragment>
    <Switch>
      <Route exact path={ENTITIES} render={(props) => <Entities {...props} title="entities" />} />
      <Route
        exact
        path={ENTITIES_ADD_NEW}
        render={(props) => (
          <Entities
            {...props}
            title="entities"
            showInspector={true}
            inspectorLevel={0}
            inspectorActionButtonLabel="save"
            showAddNewInspector={true}
            inspectorLabel="add_new_item"
          />
        )}
      />
      <Route
        exact
        path={ENTITIES_EDIT}
        render={(props) => (
          <Entities
            {...props}
            title="entities"
            inspectorLevel={0}
            showInspector={true}
            inspectorActionButtonLabel="save"
            showAddNewInspector={true}
            inspectorLabel="edit_item"
          />
        )}
      />

      <Route
        exact
        path={ENTITIES_CHOOSE_IMAGE}
        render={(props) => (
          <Entities
            {...props}
            title="entities"
            inspectorVariant="wide"
            inspectorLevel={1}
            showImageInspector={true}
            showInspector={true}
            inspectorLabel="choose_image"
          />
        )}
      />

      <Route
        exact
        path={ENTITIES_EDIT_IMAGE}
        render={(props) => (
          <Entities
            {...props}
            title="entities"
            inspectorVariant="wide"
            inspectorActionButtonLabel="choose"
            inspectorLevel={2}
            showImageEditInspector={true}
            showInspector={true}
            inspectorLabel="edit_image"
          />
        )}
      />
    </Switch>
  </React.Fragment>
);
