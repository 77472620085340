/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ThunkDispatch } from "redux-thunk";

import * as apiMenuGroup from "api/menu-group";
import { UnsavedMenuGroup, MenuGroup } from "api/menu-group";
import * as apiMenuItem from "api/menu-item";
import { generateSlug } from "api/slug";
import { MenuItem, MenuItemMoveDirection, AnyMenuItem } from "api/menu-item";
import {
  loadMenuAction,
  loadMenuSuccessAction,
  loadMenuFailureAction,
  initNewMenuAction,
  updateMenuAction,
  cancelCreateMenuAction,
  saveMenuAction,
  saveMenuSuccessAction,
  saveMenuFailureAction,
  selectMenuAction,
  listMenuItemsAction,
  listMenuItemsSuccessAction,
  listMenuItemsFailureAction,
  saveMenuItemAction,
  saveMenuItemSuccessAction,
  saveMenuItemFailureAction,
  deleteMenuItemAction,
  deleteMenuItemSuccessAction,
  deleteMenuItemFailureAction,
  moveMenuItemAction,
  moveMenuItemFailureAction,
  moveMenuItemSuccessAction,
  initNewMenuItemAction,
  cancelCreateOrEditMenuItemAction,
  editMenuItemAction,
  editMenuItemFailureAction,
  toggleDeleteConfirmationAction,
  replaceCurrentMenuItemAction,
  resetMenuAction,
  setGeneratedSlugAction
} from "./action-creators";
import { goBack, navigate } from "utils/routes.utils";
import { MENU_ITEMS_INDEX_PATH } from "./routes";
import { validateMenu, validateMenuItem } from "./validate";
import { toClientValidationError } from "utils/validation.utils";
import { isExistingMenuItem, menuItemToUnsavedMenuItem } from "./utils";
import { MenuItemNotFoundError } from "./errors";
import { NOTIFICATION_ERROR } from "containers/page/actions";
import { PartialAppState, NEW_MENU_ITEM } from "./state";
import { MenuItemId } from "api/primitive-types";
import { t } from "i18n";

export const loadMenu = () => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(loadMenuAction());

  try {
    const response = await apiMenuGroup.getMenuGroupList();
    dispatch(loadMenuSuccessAction(response));
  } catch (error) {
    dispatch(loadMenuFailureAction(error));
  }
};

export const addNewMenu = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(initNewMenuAction());
};

export const updateMenu = (data: UnsavedMenuGroup) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(updateMenuAction(data));
};

export const cancelSaveMenu = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(cancelCreateMenuAction());
  dispatch(goBack());
};

export const saveMenu = (menu: UnsavedMenuGroup) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(saveMenuAction());

  const errors = validateMenu(menu);
  if (errors) {
    dispatch(saveMenuFailureAction(toClientValidationError<UnsavedMenuGroup>(errors)));
    return;
  }

  try {
    const response = await apiMenuGroup.saveMenuGroup(menu);
    dispatch(saveMenuSuccessAction(response));
    dispatch(goBack());
    await dispatch(loadMenu());
  } catch (error) {
    const errorJSON = JSON.parse(error.message);
    let errorMessage = errorJSON.error.message;
    dispatch(saveMenuFailureAction(error));
    dispatch({ type: NOTIFICATION_ERROR, payload: { message: errorMessage } });
  }
};

export const loadMenuItems = (menu: MenuGroup) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(listMenuItemsAction());

  try {
    const response = await apiMenuGroup.getMenuItems(menu.id);
    dispatch(listMenuItemsSuccessAction(response["menu-items"]));
  } catch (error) {
    dispatch(listMenuItemsFailureAction(error));
  }
};

export const selectMenu = (menu: MenuGroup) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(selectMenuAction(menu));
};

export const moveMenuItem = (menuItem: MenuItem, direction: MenuItemMoveDirection) => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const menu = getState().menu.app.selectedMenu;
  if (menu) {
    dispatch(moveMenuItemAction(menuItem, direction));

    try {
      await apiMenuItem.moveMenuItem(menuItem, direction);

      dispatch(moveMenuItemSuccessAction(menuItem, direction));
      dispatch(loadMenuItems(menu));
    } catch (error) {
      dispatch(moveMenuItemFailureAction(menuItem, direction, error));
    }
  }
};

export const saveMenuItem = (menuItem: AnyMenuItem) => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const menu = getState().menu.app.selectedMenu;
  dispatch(saveMenuItemAction(menuItem));

  const errors = validateMenuItem(menuItem);
  if (errors) {
    dispatch(saveMenuItemFailureAction(menuItem, toClientValidationError<AnyMenuItem>(errors)));
    return;
  }

  const itemType =
    menuItem["item-type"] === "tag" && menuItem.tag && menuItem.tag["tag-type"] === "Entity"
      ? apiMenuItem.EntityType.Entity
      : menuItem["item-type"];
  const menuItemToSave = { ...menuItem, "item-type": itemType };

  try {
    const response = isExistingMenuItem(menuItem)
      ? await apiMenuItem.updateMenuItem(menuItem.id, menuItemToUnsavedMenuItem(menuItemToSave))
      : await apiMenuItem.saveNewMenuItem(menuItemToSave);

    dispatch(saveMenuItemSuccessAction(response["menu-item"]));
    if (menu) {
      dispatch(loadMenuItems(menu));
      dispatch(navigate(MENU_ITEMS_INDEX_PATH, { menuId: menu.id }));
    } else {
      dispatch(goBack());
    }
  } catch (error) {
    dispatch(saveMenuItemFailureAction(menuItem, error));
  }
};

export const deleteMenuItem = (menuItem: MenuItem) => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const menu = getState().menu.app.selectedMenu;
  dispatch(deleteMenuItemAction(menuItem));

  try {
    await apiMenuItem.deleteMenuItem(menuItem);

    dispatch(deleteMenuItemSuccessAction(menuItem));
    if (menu) {
      dispatch(loadMenuItems(menu));
    }
  } catch (error) {
    dispatch(deleteMenuItemFailureAction(menuItem, error));
  }
};

export const addNewMenuItem = () => (dispatch: ThunkDispatch<any, any, any>, getState: () => PartialAppState) => {
  const selectedMenu = getState().menu.app.selectedMenu;
  const initialMenuItem = {
    ...NEW_MENU_ITEM,
    "menu-group-slug": selectedMenu && selectedMenu.slug,
    "menu-group-id": selectedMenu && selectedMenu.id
  };
  dispatch(initNewMenuItemAction(initialMenuItem));
};

export const editMenuItem = (menuItemIdParam: string | MenuItemId) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const menuItemId = typeof menuItemIdParam === "string" ? parseInt(menuItemIdParam, 10) : menuItemIdParam;
  const menuItem = Number.isNaN(menuItemId)
    ? undefined
    : getState().menu.menuItems.find((_menuItem) => _menuItem.id === menuItemId);

  if (menuItem) {
    dispatch(editMenuItemAction(menuItem));
  } else {
    const idStr = menuItemIdParam.toString();
    dispatch(editMenuItemFailureAction(new MenuItemNotFoundError(idStr, t("menu.messages.not-found", { id: idStr }))));
  }
};

export const cancelSaveMenuItem = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(cancelCreateOrEditMenuItemAction());
  dispatch(goBack());
};

export const toggleDeleteConfirmation = (menuItem: MenuItem | null) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(toggleDeleteConfirmationAction(menuItem));
};

export const replaceCurrentMenuItem = (menuItem: AnyMenuItem | null) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(replaceCurrentMenuItemAction(menuItem));
};

export const resetMenu = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(resetMenuAction());
};

export const setGeneratedSlug = (menuGroup: UnsavedMenuGroup) => async (dispatch: ThunkDispatch<any, any, any>) => {
  try {
    const response = await generateSlug(menuGroup.name || menuGroup.slug);
    if (response.slug) {
      dispatch(setGeneratedSlugAction({ ...menuGroup, slug: response.slug }));
    }
  } catch (error) {
    console.error("Failed to generate slug");
  }
};
