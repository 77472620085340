/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";

import { t } from "i18n";
import styles from "./listicle-card.module.css";

interface ListicleCardProps {
  index: number;
  totalCards: number;
  cardType?: string;
  hasIntroductionCard?: boolean;
}

function isIntroductionCard(cardType) {
  return cardType === "introduction";
}

function getCardNumber(hasIntroductionCard, index, totalCards) {
  return hasIntroductionCard ? `${index}/${totalCards - 1}` : `${index + 1}/${totalCards}`;
}

const ListicleCard: React.FunctionComponent<ListicleCardProps> = ({
  index,
  totalCards,
  cardType,
  hasIntroductionCard
}) => {
  const headerIdentifier = isIntroductionCard(cardType)
    ? t("story-editor.story-card.introduction")
    : getCardNumber(hasIntroductionCard, index, totalCards);

  return (
    <h5 className={styles["story-editor-card-number"]} data-test-id="story-editor-card-number">
      {headerIdentifier}
    </h5>
  );
};

export default ListicleCard;
