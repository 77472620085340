/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const MagicPencil = ({ width = "24", height = "24", color = "currentColor" }) => (
  <svg width={width} height={height} viewBox="0 0 18.0025 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Reusable-Assets" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-2-Copy" transform="translate(-3.000000, -2.000000)">
        <g id="edit_black_24dp">
          <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
          <path
            d="M14.06,9.02 L14.98,9.94 L5.92,19 L5,19 L5,18.08 L14.06,9.02 M17.66,3 C17.41,3 17.15,3.1 16.96,3.29 L15.13,5.12 L18.88,8.87 L20.71,7.04 C21.1,6.65 21.1,6.02 20.71,5.63 L18.37,3.29 C18.17,3.09 17.92,3 17.66,3 L17.66,3 Z M14.06,6.19 L3,17.25 L3,21 L6.75,21 L17.81,9.94 L14.06,6.19 Z"
            id="Shape"
            fill={color}
            fill-rule="nonzero"></path>
        </g>
        <g id="auto_fix_high_black_24dp">
          <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
          <g id="Group" transform="translate(3.000000, 2.000000)" fill={color} fill-rule="nonzero">
            <polygon
              id="Path"
              points="8 4 8.62666667 2.62666667 10 2 8.62666667 1.37333333 8 0 7.37333333 1.37333333 6 2 7.37333333 2.62666667"></polygon>
            <polygon id="Path" points="3 9 3.94 6.94 6 6 3.94 5.06 3 3 2.06 5.06 0 6 2.06 6.94"></polygon>
            <polygon
              id="Path"
              points="15 14 14.06 16.06 12 17 14.06 17.94 15 20 15.94 17.94 18 17 15.94 16.06"></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default MagicPencil;
