/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Story, MediaStats } from "../../../../api/media-stats";
import { t } from "i18n";
import styles from "./story-list.module.css";
import { STORY_EDITOR_PATH_REFRESH } from "../../../story-editor/routes";
import Button from "components/button/button";
import { Location } from "history";

interface Props {
  mediaStats: MediaStats;
  loadMoreStories: (location: Location) => void;
  selectedMedia: any;
}

const StoryList = ({ mediaStats, loadMoreStories, selectedMedia }: Props) => {
  let location = selectedMedia && selectedMedia.length > 0 && selectedMedia[0].key;
  return (
    <div className={styles["story-list-container"]}>
      <p className={styles["story-list-title"]}>{`${t("mediaLibrary.image_usage_details")} (${mediaStats.total})`}</p>
      {mediaStats &&
        mediaStats.stories &&
        mediaStats.stories.length > 0 &&
        mediaStats.stories.map((story: Story) => (
          <p
            className={styles["story-headline"]}
            data-test-id="image-usage-story-headline"
            onClick={() => window.open(`${STORY_EDITOR_PATH_REFRESH}${story["story-id"]}`, "_blank")}>
            {story.headline}
          </p>
        ))}
      {mediaStats.stories.length < mediaStats.total && (
        <div className={styles["load-more-btn"]}>
          <Button type="secondary" onClick={() => loadMoreStories(location)}>
            {t("mediaLibrary.load_more")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default StoryList;
