/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import _pick from "lodash/pick";
import { t } from "i18n";

import { FixedTabList, Panel } from "components/tabs/tabs";
import generateAdvancedInspectorTabs from "./tab-list";
import Metadata from "./metadata";
import Seo from "./seo";
import StoryAttributes from "./story-attributes";
import { actions } from "../../actions";
import PushNotifications from "./push-notifications";
import SocialNotifications from "./social-notifications";

import { MetadataFields } from "pages/story-editor/components/metadata-fields";
import { SeoFields } from "pages/story-editor/components/seo-fields";
import { getTemplateStoryAttributes, validations } from "pages/story-editor/utils";
import {
  getAttributesOfTypeEntityOptionsAction,
  updateStoryContributions,
  updateSelectedPlaceAction,
  updateAttributeAction,
  updateStoryTagAction
} from "pages/story-editor/async-action-creators";
import { navigateFn } from "utils/routes.utils";

import styles from "./advanced-inspector.module.css";
import CategoryTitle from "components/category-title/category-title";
import { updateStoryFeatures } from "pages/story-editor/action-creators";

const filterHiddenFields = (fields, fieldSpecs) =>
  fields.filter((field) => !validations.isHidden(field.specPath, fieldSpecs));

const getMetadataErrors = (errors) => ({
  ..._pick(errors, [
    "summary",
    "sections",
    "tags",
    "author-id",
    "canonical-url",
    "custom-slug",
    "storyline-id",
    "storyline-title",
    "story-claim-review"
  ]),
  ..._pick(errors.metadata, "sponsored-by")
});

const getSeoErrors = (errors) => _pick(errors, ["meta-title", "meta-description", "meta-keywords"]);

const getAttributesErrors = (errors, storyAttributes) => {
  const storyAttributeNames = storyAttributes.map((attr) => attr["is-mandatory"] && attr.name);
  return _pick(errors, ["linked-view-counterview-story", ...storyAttributeNames, "story-attributes"]);
};

const AdvancedInspector = ({
  selectedTab,
  setSelectedTab,
  fieldSpecs,
  story,
  onChange,
  onStoryFeatureChange,
  onSEOChange,
  onTagChange,
  sections,
  attributes,
  onAttributeChange,
  isViewCounterViewEnabled,
  isLocationForStoriesEnabled,
  isFreeTextAttributesEnabled,
  fieldLimits,
  onMetadataChange,
  toggleSponsoredContent,
  getRecommendedTags,
  recommendedTags,
  addRecommendedTag,
  getAttributesOfTypeEntityOptions,
  errors,
  isClaimReviewEnabled,
  toggleClaimReview,
  updateStoryContributions,
  selectedPlace,
  updateSelectedPlace,
  channelsEnabled,
  isStoryLocked
}) => {
  const storyTemplate = story["story-template"];
  const storyAttributes = getTemplateStoryAttributes(attributes, storyTemplate);

  return (
    <div className={styles["manage-inspector-container"]}>
      <FixedTabList
        tabs={generateAdvancedInspectorTabs({
          showAttributes: storyAttributes.length > 0 || isViewCounterViewEnabled || isLocationForStoriesEnabled
        })}
        value={selectedTab}
        onChange={(tab) => setSelectedTab(tab, story["story-content-id"])}
      />
      <Panel>
        {selectedTab === "metadata" && (
          <React.Fragment>
            <Metadata
              fields={filterHiddenFields(MetadataFields, fieldSpecs)}
              story={story}
              onChange={onChange}
              onMetadataChange={onMetadataChange}
              onTagChange={onTagChange}
              sections={sections}
              fieldSpecs={fieldSpecs}
              updateStoryContributions={updateStoryContributions}
              errors={getMetadataErrors(errors)}
            />
            <Seo
              fields={filterHiddenFields(SeoFields, fieldSpecs)}
              onSEOChange={onSEOChange}
              story={story}
              fieldSpecs={fieldSpecs}
              errors={getSeoErrors(errors)}
              disabled={isStoryLocked}
            />
          </React.Fragment>
        )}
        {selectedTab === "attributes" && (
          <StoryAttributes
            isViewCounterViewEnabled={isViewCounterViewEnabled}
            isLocationForStoriesEnabled={isLocationForStoriesEnabled}
            isFreeTextAttributesEnabled={isFreeTextAttributesEnabled}
            attributes={storyAttributes}
            onAttributeChange={onAttributeChange}
            story={story}
            getAttributesOfTypeEntityOptions={getAttributesOfTypeEntityOptions}
            errors={getAttributesErrors(errors, storyAttributes)}
            selectedPlace={selectedPlace}
            updateSelectedPlace={updateSelectedPlace}
            disabled={isStoryLocked}
          />
        )}
        {selectedTab === "social-notifications" && (
          <React.Fragment>
            <PushNotifications
              story={story}
              fieldLimits={fieldLimits}
              onPushNotificationTextChange={onChange("push-notification")}
              onPushNotificationTitleChange={onChange("push-notification-title")}
              onPushNotificationTargetChange={onChange("push-notification-targets")}
              onStoryFeatureChange={onStoryFeatureChange}
              channelsEnabled={channelsEnabled}
              disabled={isStoryLocked}
            />
            <CategoryTitle title={t("story-editor.inspector.social-share")} />
            <SocialNotifications disabled={isStoryLocked} />
          </React.Fragment>
        )}
      </Panel>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    story: state.storyEditor.story,
    fieldSpecs: state.storyEditor.storyTemplateFields,
    selectedTab: state.storyEditor.ui.inspector.selectedTab,
    sections: state.config.sections,
    attributes: state.config.storyAttributes,
    isViewCounterViewEnabled: state.features.isViewCounterViewEnabled,
    isLocationForStoriesEnabled: state.features.isLocationForStoriesEnabled,
    isFreeTextAttributesEnabled: state.features.isFreeTextAttributesEnabled,
    fieldLimits: state.config.fieldLimits,
    errors: state.storyEditor.ui.errors.inspector,
    isEntityAsTagEnabled: state.features.isEntityAsTagEnabled,
    selectedPlace: state.storyEditor.app.location.selectedPlace,
    channelsEnabled: state.features.enablePushNotificationChannels,
    isStoryLocked: state.storyEditor.ui.isStoryLocked
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const navigate = navigateFn(dispatch);

  return {
    navigate,
    setSelectedTab: (tab, id) => dispatch(push(`/story/${id}/manage/advanced/${tab}`)),
    onChange: (key) => (value) => dispatch({ type: actions.UPDATE_STORY, payload: { key, value } }),
    onStoryFeatureChange: (feature, changes) => dispatch(updateStoryFeatures(feature, changes)),
    updateStoryContributions: (contributions) => dispatch(updateStoryContributions(contributions)),
    onSEOChange: (path) => (value) => dispatch({ type: actions.UPDATE_SEO, payload: { path, value } }),
    onAttributeChange: (key, value) => dispatch(updateAttributeAction(key, value)),
    onMetadataChange: (key, value) => dispatch({ type: actions.UPDATE_STORY_METADATA, payload: { key, value } }),
    getAttributesOfTypeEntityOptions: (query, attributeValues) =>
      getAttributesOfTypeEntityOptionsAction(query, attributeValues),
    updateSelectedPlace: (place) => dispatch(updateSelectedPlaceAction(place)),
    onTagChange: (value) => dispatch(updateStoryTagAction(value))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AdvancedInspector);
