/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import TextField from "components/text-field/text-field";
import { Integrations, GoogleAnalytics4ReportingConfig, IntegrationConfig } from "api/integrations";
import TextArea from "components/text-area/text-area";
import { ButtonWithLoader } from "components/button/button";
import { getErrorMessage } from "../validate";
import { Callout } from "@quintype/em/components/callout";
import { useEffect, useState } from "react";
import styles from "./ga4-reporting.module.css";
import Select from "components/select/select";
import { getBrowserTimezone, getTimezoneList } from "utils/timezone.utils";

interface Props {
  integration: {
    key: Integrations.GoogleAnalytics4Reporting;
    value: GoogleAnalytics4ReportingConfig;
    isValid: boolean;
  };
  updateIntegrationField: (key: string, value: Object) => void;
  readOnly?: boolean;
  errors: Error | null;
  validateIntegration?: (integration: IntegrationConfig) => void;
  isValidateInProgress?: boolean;
}

interface TimezoneOptionType {
  label: string;
  value: string;
}

const getTimezoneSelectOption = (timezone: string): TimezoneOptionType => ({ label: timezone, value: timezone });

const Ga4Reporting: React.FunctionComponent<Props> = ({
  integration,
  updateIntegrationField,
  validateIntegration,
  readOnly,
  isValidateInProgress,
  errors
}) => {
  const integrationName = integration.key;
  const errorDetail = getErrorMessage(integration.key, "message", errors);
  const [isValidateClicked, setIsValidateClicked] = useState<Boolean>(false);

  useEffect(() => {
    if (!integration.value.timezone) {
      updateIntegrationField(integrationName, { timezone: getBrowserTimezone() });
    }
  }, [integrationName, updateIntegrationField, integration]);

  return (
    <React.Fragment>
      <TextField
        label={t("integrations.analytics.ga4-reporting.client-email")}
        value={integration.value["client-email"]}
        onChange={(value) => updateIntegrationField(integrationName, { "client-email": value })}
        readOnly={readOnly}
        errorMessage={getErrorMessage(integration.key, "client-email", errors)}
      />
      <TextArea
        label={t("integrations.analytics.ga4-reporting.private-key")}
        value={integration.value["private-key"]}
        onChange={(value) => !readOnly && updateIntegrationField(integrationName, { "private-key": value })}
        maxRows={10}
        errorMessage={getErrorMessage(integration.key, "private-key", errors)}
      />
      <TextField
        label={t("integrations.analytics.ga4-reporting.property-id")}
        value={integration.value["property-id"]}
        onChange={(value) => updateIntegrationField(integrationName, { "property-id": value })}
        readOnly={readOnly}
        errorMessage={getErrorMessage(integration.key, "property-id", errors)}
      />
      {readOnly ? (
        <TextField
          label={t("integrations.analytics.ga4-reporting.timezone")}
          value={integration.value["timezone"]}
          readOnly={readOnly}
          errorMessage={getErrorMessage(integration.key, "timezone", errors)}
          onChange={() => {}}
        />
      ) : (
        <Select<TimezoneOptionType>
          name={"timezone"}
          label={t("integrations.analytics.ga4-reporting.timezone")}
          value={getTimezoneSelectOption(integration.value["timezone"])}
          options={getTimezoneList().map((timezone) => getTimezoneSelectOption(timezone))}
          getOptionLabel={(timezone) => timezone.label}
          getOptionValue={(timezone) => timezone.value}
          onChange={(timezone: TimezoneOptionType) => {
            updateIntegrationField(integrationName, { timezone: timezone.value });
          }}
        />
      )}
      {validateIntegration && (
        <ButtonWithLoader
          loading={isValidateInProgress}
          onClick={() => {
            setIsValidateClicked(true);
            validateIntegration(integration);
          }}>
          {t("integrations.validate-credentials")}
        </ButtonWithLoader>
      )}
      <div className={styles["validation-message"]}>
        {isValidateClicked && showCallout(integration.isValid, errorDetail)}
      </div>
    </React.Fragment>
  );
};

function showCallout(isIntegrationValid: boolean, errorMessage?: string) {
  if (errorMessage) {
    return <Callout title={t("integrations.validation-error-title")} content={errorMessage} variant="danger" />;
  }
  if (isIntegrationValid) {
    return (
      <Callout
        title={t("integrations.validation-success-title")}
        content={t("integrations.analytics.ga4-reporting.usage-info")}
        variant="success"
      />
    );
  }
  return;
}

export default Ga4Reporting;
