/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "api/client";
import { Timestamp } from "./primitive-types";
import { base64Encode } from "utils";

export interface Response {
  "static-pages": StaticPage[];
  pagination: number;
}

export enum StaticPageStatus {
  Draft = "draft",
  Retract = "retract",
  Publish = "publish"
}

export interface StaticPage {
  id: number;
  slug: string;
  title: string;
  content: null | string;
  status?: StaticPageStatus;
  "created-at": Timestamp;
  metadata: Metadata;
  "source-path": string;
}

export enum MimeTypes {
  HTML = "text/html",
  JSON = "application/json",
  XML = "application/xml",
  TEXT = "text/plain"
}

export type UnSavedStaticPage = Pick<StaticPage, "title" | "slug" | "content" | "metadata" | "source-path">;

export interface StaticPageMetadataSeo {
  "meta-title"?: string;
  "meta-description"?: string;
  "meta-keywords"?: string;
}

export interface Metadata {
  header?: boolean;
  footer?: boolean;
  "mime-type"?: MimeTypes;
  seo?: StaticPageMetadataSeo;
}

export const getStaticPages = (offset = 0): Promise<Response> => {
  return client
    .url("/api/static-pages")
    .query({ limit: 20, offset })
    .get()
    .json();
};

export const getStaticPage = (id: number): Promise<any> => {
  return client
    .url(`/api/static-pages/${id}`)
    .get()
    .json();
};

interface StaticPageCreatedResponse {
  id: {
    "updated-at": Timestamp;
    "member-id": number;
    slug: string;
    content: string;
    "publisher-id": number;
    "deleted-at": Timestamp | null;
    title: string;
    "source-path": string;
    status: StaticPageStatus;
    id: number;
    "created-at": Timestamp;
    metadata: Metadata;
  };
}

export const createNewStaticPage = (staticPage: StaticPage | UnSavedStaticPage): Promise<StaticPageCreatedResponse> => {
  const encodedContent = staticPage && staticPage.content && base64Encode(staticPage.content);
  return client
    .url("/api/static-pages")
    .json({ ...staticPage, content: encodedContent })
    .post()
    .json();
};

export const updateStaticPage = (staticPage: StaticPage): Promise<any> => {
  const encodedContent = staticPage && staticPage.content && base64Encode(staticPage.content);
  return client
    .url(`/api/static-pages/${staticPage.id}`)
    .json({ ...staticPage, content: encodedContent })
    .put()
    .json();
};

export const deleteStaticPage = (id: number): Promise<any> => {
  return client
    .url(`/api/static-pages/${id}`)
    .delete()
    .res();
};
