/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { localesMapping, t } from "i18n";
import { ThunkDispatch } from "redux-thunk";
import { selectIsDesktopSizeViewport } from "store/viewport";

import wrapPage from "containers/page/page";
import actions from "../actions";
import {
  loadSelectedUserData,
  showAllRolesWithAssignedRolesSelected,
  updateSelectedRoles,
  updateSelectedTeams,
  uploadAvatarForSelectedUser,
  setSelectedRolesForSelectedUser,
  setSelectedTeamsForSelectedUser,
  showAllTeamsWithAssignedTeamsSelected
} from "../action-creators";
import ContentHeader from "../../../../components/content-header/content-header";
import { USERS_EDIT_PATH, USERS_EDIT_PATH_ROLES, USERS_EDIT_PATH_TEAMS } from "../routes";
import { USER_PROFILE_PATH } from "pages/user/routes";
import { navigateFn } from "utils/routes.utils";
import TextField from "components/text-field/text-field";
import TextArea from "components/text-area/text-area";
import Button from "components/button/button";
import { Switch } from "components/switch/switch";
import Chip from "components/chip/chip";
import EditUserHeader from "./header";
import Edit from "components/icons/edit";
import Inspector from "components/inspector/inspector";
import Checkbox from "components/checkbox/checkbox";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import Loader from "../loader";
import Spinner from "components/spinner/spinner";
import Dialog from "components/dialog/dialog";
import PasswordField from "components/password-field/password-field";
import { COLLECTIONS_SELECTED_COLLECTION_PATH, COLLECTIONS_CONTENT_SETTINGS_PATH } from "pages/collections/routes";
import ConfirmationModal from "components/confirmation-modal/confirmation-modal";
import { saveAndLogout } from "../action-creators";
import SocialLinks, { SocialLinkProvider } from "components/social-links/social-links";
import styles from "./profile.module.css";

import { ValidationError, PartialAppState, UserTeam, SelectedUser, UserRole } from "../state";
import { LoaderState } from "behaviors/loader/state";
import Dropzone from "components/dropzone/dropzone";
import DropzoneMessage, { DropzoneMessageVariant, DropzoneMessageType } from "components/dropzone/dropzone-message";
import { MimeType } from "utils/file.utils";
import CategoryTitle from "components/category-title/category-title";

import EnableTwoFactorAuth from "pages/login/enable-two-factor-auth";
import { notificationSuccess } from "action-creators/notification";
import Spacer from "components/spacer/spacer";
import Select from "components/select/select";
import { get } from "lodash";

interface StateProps {
  user: SelectedUser;
  roles: UserRole[];
  teams: UserTeam[];
  errors: ValidationError;
  loaderMain: LoaderState;
  loaderInspector: LoaderState;
  teamsLoaderInspector: LoaderState;
  loadingAvatar: boolean;
  isDarkModeConfirmationModalVisible: boolean;
  isManageStoryInspectorModalVisible: boolean;
  isManageLanguageInspectorModalVisible: boolean;
  title: string;
  canCreateCollections: boolean;
  isTeamsEnabled: boolean;
  isDarkModeEnabled: boolean;
  isDefaultManageInspectorEnabled: boolean;
  isProfilePage: boolean;
  memberFields: string[];
  canLogin: boolean;
  canLoginModified: boolean;
  defaultSocialLinkProviders: Array<SocialLinkProvider>;
  isToggleLoginConfirmationModalVisible: boolean;
  isTFAEnabled: boolean;
  publisherConfig: string;
  isDesktopSizeViewport: boolean;
}

interface DispatchProps {
  loadSelectedUserData: VoidFunction;
  updateUserProfile: (key: string, changes: object | string) => void;
  UpdateUserSettings: (key: string, changes: object | string) => void;
  openEditRoleInspector: VoidFunction;
  openEditTeamsInspector: VoidFunction;
  closeBoldSwitchConfirmationModal: VoidFunction;
  showBoldSwitchConfirmationModal: VoidFunction;
  closeDarkModeConfirmationModal: VoidFunction;
  showDarkModeConfirmationModal: VoidFunction;
  closeManageInspectorConfirmationModal: VoidFunction;
  showManageInspectorConfirmationModal: VoidFunction;
  togglelanguageChangeConfirmationModal: VoidFunction;
  saveAndLogout: (user: SelectedUser) => void;
  closeInspector: VoidFunction;
  onChangeOfRoles: (updatedRole: UserRole) => void;
  onChangeOfTeams: (updatedTeam: UserTeam) => void;
  uploadAvatarForSelectedUser: (files: File[]) => void;
  setSelectedRolesForSelectedUser: VoidFunction;
  setSelectedTeamsForSelectedUser: VoidFunction;
  navigateToCollectionPage: (authorCollectionId: string, authorId: number) => void;
  updateCanLogin: (canLogin: boolean) => void;
  closeToggleLoginConfirmationModal: VoidFunction;
  showToggleLoginConfirmationModal: VoidFunction;
  showTFAEnableSetupNotification: VoidFunction;
  toggleDefaultManageInspector: (isDefaultManageInspectorEnabled: boolean) => void;
  languageChanged: (isDefaultManageInspectorEnabled: boolean) => void;
}

interface OwnProps {
  match: { params: { id: number }; path: string };
  showTeamsInspector: boolean;
  showInspector: boolean;
}

type Props = StateProps & DispatchProps & OwnProps & { canEditRoles: boolean };

type UserProps = Pick<
  Props,
  | "user"
  | "uploadAvatarForSelectedUser"
  | "updateUserProfile"
  | "UpdateUserSettings"
  | "openEditRoleInspector"
  | "openEditTeamsInspector"
  | "errors"
  | "navigateToCollectionPage"
  | "loadingAvatar"
  | "canEditRoles"
  | "canCreateCollections"
  | "isTeamsEnabled"
  | "isProfilePage"
  | "memberFields"
  | "isDarkModeEnabled"
  | "isDefaultManageInspectorEnabled"
  | "canLogin"
  | "updateCanLogin"
  | "canLoginModified"
  | "defaultSocialLinkProviders"
  | "isTFAEnabled"
  | "publisherConfig"
  | "showTFAEnableSetupNotification"
  | "loadSelectedUserData"
  | "toggleDefaultManageInspector"
  | "languageChanged"
  | "isDesktopSizeViewport"
> & { toggleDarkMode: () => void; toggleLogin: () => void };

interface ProfileImageProps {
  loading: boolean;
  user: SelectedUser;
  uploadAvatar: (files: File[]) => void;
}

const ProfileImage: React.FC<ProfileImageProps> = ({ loading, user, uploadAvatar }) => {
  return (
    <div data-test-id="user-image-container" className={styles["user-image-container"]}>
      {loading ? (
        <div className={styles["avatar-loader-container"]}>
          <Spinner />
        </div>
      ) : (
        <span className={styles["user-avatar-image"]}>
          {user["avatar-url"] && (
            <Dropzone
              dropHandler={uploadAvatar}
              accept={MimeType.Images}
              enableMultipleUploads={false}
              showOnDragAnywhere={true}
              dragEnterMessage={
                <DropzoneMessage
                  message={t("components.dropzone.drop-image-hint")}
                  variant={DropzoneMessageVariant.Small}
                  type={DropzoneMessageType.Image}
                />
              }>
              <img className={styles["edit-user-avatar-image"]} src={user["avatar-url"]} alt={user["avatar-url"]} />
            </Dropzone>
          )}
        </span>
      )}
      <input
        type="file"
        id="upload-avatar"
        className={styles["upload-input"]}
        accept="image/*"
        onChange={(e) => e.target.files && uploadAvatar(Array.from(e.target.files))}
      />
      <label htmlFor="upload-avatar">
        <span className={styles["user-image-edit-icon"]}>
          <Edit width={16} height={16} color="#4860bc" />
        </span>
      </label>
    </div>
  );
};

export type LocaleOption = {
  name: string;
  value: string;
  label: string;
};

const getDefaultLanguageOption = (
  user: any,
  publisherConfig: any,
  availableEditorLanguages: LocaleOption[]
): LocaleOption => {
  const userLocale = get(user, ["settings", "i18n", "locale"]);
  const publisherLocale = get(publisherConfig, ["i18n", "locale"]);

  const defaultLocale = userLocale || publisherLocale || "en-in";

  const selectedLanguageOptions = availableEditorLanguages.filter((option) => option.value === defaultLocale);

  return selectedLanguageOptions[0] || availableEditorLanguages[0];
};

const User: React.SFC<UserProps> = ({
  user,
  uploadAvatarForSelectedUser,
  updateUserProfile,
  UpdateUserSettings,
  openEditRoleInspector,
  openEditTeamsInspector,
  errors,
  navigateToCollectionPage,
  loadingAvatar,
  canEditRoles,
  canCreateCollections,
  isTeamsEnabled,
  isProfilePage,
  memberFields,
  toggleDarkMode,
  toggleDefaultManageInspector,
  languageChanged,
  isDarkModeEnabled,
  isDefaultManageInspectorEnabled,
  canLogin,
  canLoginModified,
  defaultSocialLinkProviders,
  toggleLogin,
  isTFAEnabled,
  isDesktopSizeViewport,
  showTFAEnableSetupNotification,
  loadSelectedUserData,
  publisherConfig
}) => {
  const [showTfaModal, setTfaModal] = useState(false);

  const availableEditorLanguages = Object.keys(localesMapping).map((locale) => ({
    name: locale,
    value: locale,
    label: t(`common.languages.${locale}`)
  }));

  const [defaultLanguageOption] = useState<LocaleOption>(
    getDefaultLanguageOption(user, publisherConfig, availableEditorLanguages)
  );
  const [selectedLanguage, setSelectedLanguage] = useState<LocaleOption>();

  useEffect(() => {
    setSelectedLanguage(defaultLanguageOption);
  }, [defaultLanguageOption]);

  const handleLanguageChange = (selectedOption) => {
    setSelectedLanguage(selectedOption);
    if (selectedOption.value !== defaultLanguageOption.value) {
      const updatedI18n = {
        "locale-display": selectedOption.label,
        locale: selectedOption.name,
        dir: selectedOption.value === "ar" ? "rtl" : "ltr"
      };
      UpdateUserSettings("i18n", updatedI18n);
      languageChanged(true);
    }
  };

  return (
    <div className={styles["user-profile-page"]}>
      <ProfileImage loading={loadingAvatar} user={user} uploadAvatar={uploadAvatarForSelectedUser} />
      <div className={styles["user-form-container"]}>
        <div className={styles["user-common-fields-container"]}>
          <TextField
            label={t("users-and-roles.fields.name")}
            value={user.name}
            classname="user-name"
            onChange={(value) => updateUserProfile("name", value)}
            errorMessage={errors && errors.name && errors.name[0]}
          />
          <TextField label={t("users-and-roles.fields.email")} value={user.email} onChange={() => {}} disabled={true} />
          <TextField
            label={t("users-and-roles.fields.slug")}
            value={user.slug}
            onChange={(value) => {
              updateUserProfile("slug", value && value.trim());
            }}
            errorMessage={errors && errors.slug && errors.slug[0]}
          />
          <TextField
            label={t("users-and-roles.fields.username")}
            value={user.username}
            onChange={() => {}}
            disabled={true}
          />
          <TextField
            label={t("users-and-roles.fields.communication_email")}
            value={user["communication-email"]}
            onChange={(value) => updateUserProfile("communication-email", value && value.trim())}
            errorMessage={errors && errors["communication-email"] && errors["communication-email"][0]}
          />
          <TextArea
            label={t("users-and-roles.fields.bio")}
            value={user["bio"] || ""}
            onChange={(value) => updateUserProfile("bio", value)}
          />
          {!isProfilePage && (
            <React.Fragment>
              <div className={styles["user-can-login-toggle"]}>
                <span>{t("users-and-roles.enable_login")}</span>
                <Switch id="can-login" onChange={() => toggleLogin()} checked={canLogin} disabled={false} />
              </div>
              {canLogin && canLoginModified && (
                <React.Fragment>
                  <TextField
                    label={t("users-and-roles.fields.username")}
                    value={user.username}
                    onChange={(value) => updateUserProfile("username", value)}
                    errorMessage={(errors && errors.username && errors.username[0]) || ""}
                  />
                  <PasswordField
                    label={t("users-and-roles.fields.password")}
                    onChange={(value) => updateUserProfile("password", value)}
                    errorMessage={(errors && errors.password && errors.password[0]) || ""}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {!isProfilePage && (
            <React.Fragment>
              <div className={styles["user-roles-container"]} data-test-id="user-roles-container">
                {t("users-and-roles.roles")}
                <div className={styles["user-selected-roles"]}>
                  {user.roles &&
                    user.roles.map((role) => (
                      <Chip key={`role${role.id}`} value={role.name} invert classname={"edit-user-chip"} />
                    ))}
                </div>
                {canEditRoles && (
                  <Button
                    type="default"
                    onClick={() => openEditRoleInspector()}
                    testId="user-action-edit-roles-btn"
                    classname={"edit-user-profile-button"}>
                    {user.roles && user.roles.length > 0
                      ? t("users-and-roles.edit_roles_button")
                      : t("users-and-roles.add_user_roles")}
                  </Button>
                )}
              </div>
              {isTeamsEnabled && (
                <div className={styles["user-teams-container"]} data-test-id="user-teams-container">
                  {t("users-and-roles.teams")}
                  <div className={styles["user-selected-teams"]}>
                    {user.teams &&
                      user.teams.map((team) => (
                        <Chip key={`team${team.id}`} value={team.name} invert classname={"edit-user-chip"} />
                      ))}
                  </div>
                  <Button
                    type="default"
                    onClick={() => openEditTeamsInspector()}
                    testId="user-action-edit-teams-btn"
                    classname={"edit-user-profile-button"}>
                    {user.teams && user.roles.length > 0
                      ? t("users-and-roles.edit_teams_button")
                      : t("users-and-roles.add_user_teams")}
                  </Button>
                </div>
              )}
            </React.Fragment>
          )}
          {canCreateCollections && (
            <div className={styles["author-button"]}>
              <Button
                type="default"
                testId="user-action-author-stories-btn"
                onClick={() => navigateToCollectionPage(user["author-collection-id"], user.id)}>
                {t("users-and-roles.author_stories")}
              </Button>
            </div>
          )}
        </div>
        <div className={styles["user-action-container"]} data-test-id="user-action-container">
          <div className="preferences">
            <label id="dark-mode-toggle" className={styles["preference-text"]}>
              {t("users-and-roles.preferences")}
            </label>
          </div>
          {isDarkModeEnabled && isProfilePage && (
            <div className={styles["dark-mode-toggle"]}>
              <label id="dark-mode-toggle" className={styles["dark-mode-toggle-text"]}>
                {t("users-and-roles.enable_dark_mode")}
              </label>
              <Switch
                id="dark-mode-toggle"
                data-test-id="darkModeToggle"
                checked={user.settings && user.settings["dark-mode"] ? user.settings["dark-mode"] : false}
                onChange={() => toggleDarkMode()}
              />
            </div>
          )}

          {isProfilePage && isDesktopSizeViewport && (
            <div className={styles["dark-mode-toggle"]}>
              <label id="default-manage-inspector-toggle" className={styles["dark-mode-toggle-text"]}>
                {t("users-and-roles.enable_default_manage_inspector")}
              </label>
              <Switch
                id="default-manage-inspector-toggle"
                data-test-id="default-manage-inspector-toggle"
                checked={
                  user.settings && user.settings["default-manage-story-inspector"]
                    ? user.settings["default-manage-story-inspector"]
                    : false
                }
                onChange={() => toggleDefaultManageInspector(isDefaultManageInspectorEnabled)}
              />
            </div>
          )}
          <div className={styles["select-field"]}>
            <Select
              isClearable={false}
              getOptionLabel={(languageCode) => languageCode.label}
              getOptionValue={(languageCode) => languageCode.value}
              options={availableEditorLanguages}
              label={t("users-and-roles.bold-language")}
              onChange={handleLanguageChange}
              value={selectedLanguage}
              defaultValue={selectedLanguage}
            />
          </div>
        </div>
        <div
          data-test-id="add-user-custom-fields-and-social-container"
          className={styles["add-user-custom-fields-and-social-container"]}>
          {memberFields && <h4 className={styles["custom-fields-header"]}>{t("users-and-roles.other-details")}</h4>}
          {memberFields &&
            memberFields.map((field) => (
              <TextField
                key={`user-meta-field-${field}`}
                label={field}
                value={user.metadata && user.metadata[field] ? user.metadata[field] : ""}
                onChange={(value) => updateUserProfile("metadata", { ...user.metadata, [field]: value })}
              />
            ))}
          <SocialLinks
            defaultSocialLinkProviders={defaultSocialLinkProviders}
            socialLinks={user.social}
            updateSocialLinks={(socialLinks) => updateUserProfile("social", socialLinks)}
            labelAdd={t("users-and-roles.add_social_link")}
            haveHandleField={true}
          />
        </div>
        {isProfilePage && isTFAEnabled && (
          <div>
            <CategoryTitle title={t("user-page.login-and-security.two_factor_auth.title")} />
            <p className={styles["tfa-title-help-text"]}>
              {t("user-page.login-and-security.two_factor_auth.title_help_text")}
            </p>
            <div className={styles["tfa-setup-container"]}>
              <div className={styles["tfa-label-container"]}></div>
              <Dialog isOpen={showTfaModal} onClose={() => setTfaModal(false)}>
                <div className={styles["tfa-dialog-content"]} data-test-id="tfa-dialog-content">
                  <EnableTwoFactorAuth
                    postEnableHandler={() => {
                      loadSelectedUserData();
                      setTfaModal(false);
                      showTFAEnableSetupNotification();
                    }}
                  />
                </div>
              </Dialog>

              {user["is-tfa-enabled"] && (
                <>
                  <Button disabled onClick={() => {}} testId="tfa-active-button">
                    {t("user-page.login-and-security.two_factor_auth.active_button_label")}
                  </Button>
                  <Spacer token="m" />
                </>
              )}

              <Button onClick={() => setTfaModal(true)}>
                {user["is-tfa-enabled"]
                  ? t("user-page.login-and-security.two_factor_auth.reset_button_label")
                  : t("user-page.login-and-security.two_factor_auth.setup_button_label")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

class EditUserProfile extends React.Component<Props> {
  componentDidMount() {
    this.props.loadSelectedUserData();
  }

  render() {
    const {
      user,
      updateUserProfile,
      UpdateUserSettings,
      roles,
      teams,
      openEditRoleInspector,
      openEditTeamsInspector,
      uploadAvatarForSelectedUser,
      onChangeOfRoles,
      onChangeOfTeams,
      errors,
      setSelectedRolesForSelectedUser,
      setSelectedTeamsForSelectedUser,
      loaderMain,
      loaderInspector,
      teamsLoaderInspector,
      loadingAvatar,
      navigateToCollectionPage,
      isDarkModeConfirmationModalVisible,
      isManageStoryInspectorModalVisible,
      isManageLanguageInspectorModalVisible,
      closeDarkModeConfirmationModal,
      closeManageInspectorConfirmationModal,
      showDarkModeConfirmationModal,
      showManageInspectorConfirmationModal,
      togglelanguageChangeConfirmationModal,
      saveAndLogout,
      canEditRoles = true,
      canCreateCollections,
      isTeamsEnabled,
      isProfilePage,
      memberFields,
      isDarkModeEnabled,
      isDefaultManageInspectorEnabled,
      canLogin,
      updateCanLogin,
      canLoginModified,
      defaultSocialLinkProviders,
      isToggleLoginConfirmationModalVisible,
      closeToggleLoginConfirmationModal,
      showToggleLoginConfirmationModal,
      isTFAEnabled,
      showTFAEnableSetupNotification,
      loadSelectedUserData,
      isDesktopSizeViewport,
      publisherConfig
    } = this.props;
    return (
      <div>
        <main className={styles["edit-user-page-container"]}>
          <ContentHeader title={t("users-and-roles.edit_user_title")} />
          <LoaderWrapper component={Loader} loader={loaderMain}>
            {user && (
              <User
                user={user}
                uploadAvatarForSelectedUser={uploadAvatarForSelectedUser}
                updateUserProfile={updateUserProfile}
                UpdateUserSettings={UpdateUserSettings}
                openEditRoleInspector={openEditRoleInspector}
                openEditTeamsInspector={openEditTeamsInspector}
                errors={errors}
                loadingAvatar={loadingAvatar}
                navigateToCollectionPage={navigateToCollectionPage}
                canEditRoles={canEditRoles}
                canCreateCollections={canCreateCollections}
                isTeamsEnabled={isTeamsEnabled}
                isProfilePage={isProfilePage}
                memberFields={memberFields}
                toggleDarkMode={showDarkModeConfirmationModal}
                toggleDefaultManageInspector={showManageInspectorConfirmationModal}
                languageChanged={togglelanguageChangeConfirmationModal}
                isDarkModeEnabled={isDarkModeEnabled}
                isDefaultManageInspectorEnabled={isDefaultManageInspectorEnabled}
                canLogin={canLogin}
                updateCanLogin={updateCanLogin}
                canLoginModified={canLoginModified}
                defaultSocialLinkProviders={defaultSocialLinkProviders}
                toggleLogin={showToggleLoginConfirmationModal}
                isTFAEnabled={isTFAEnabled}
                publisherConfig={publisherConfig}
                showTFAEnableSetupNotification={showTFAEnableSetupNotification}
                loadSelectedUserData={loadSelectedUserData}
                isDesktopSizeViewport={isDesktopSizeViewport}
              />
            )}
          </LoaderWrapper>

          {isDarkModeEnabled && (
            <ConfirmationModal
              showConfirmationModal={isDarkModeConfirmationModalVisible}
              headerText={t("users-and-roles.dark_mode_modal_message")}
              confirmButtonLabel={t("users-and-roles.modal_yes")}
              cancelButtonLabel={t("users-and-roles.modal_cancel")}
              onConfirm={() =>
                saveAndLogout({
                  ...user,
                  settings: user.settings
                    ? { ...user.settings, "dark-mode": !user.settings["dark-mode"] }
                    : { "dark-mode": true }
                })
              }
              onCancel={() => closeDarkModeConfirmationModal()}
              onClose={() => closeDarkModeConfirmationModal()}
            />
          )}

          {isDesktopSizeViewport && (
            <ConfirmationModal
              showConfirmationModal={isManageStoryInspectorModalVisible}
              headerText={t("users-and-roles.default_change_language_modal_message")}
              confirmButtonLabel={t("users-and-roles.modal_yes")}
              cancelButtonLabel={t("users-and-roles.modal_cancel")}
              onConfirm={() =>
                saveAndLogout({
                  ...user,
                  settings: user.settings
                    ? {
                        ...user.settings,
                        "default-manage-story-inspector": !user.settings["default-manage-story-inspector"]
                      }
                    : { "default-manage-story-inspector": true }
                })
              }
              onCancel={() => closeManageInspectorConfirmationModal()}
              onClose={() => closeManageInspectorConfirmationModal()}
            />
          )}

          {
            <ConfirmationModal
              showConfirmationModal={isManageLanguageInspectorModalVisible}
              headerText={t("users-and-roles.default_change_language_modal_message")}
              confirmButtonLabel={t("users-and-roles.modal_yes")}
              cancelButtonLabel={t("users-and-roles.modal_cancel")}
              onConfirm={() => saveAndLogout(user)}
              onCancel={() => togglelanguageChangeConfirmationModal()}
              onClose={() => togglelanguageChangeConfirmationModal()}
            />
          }

          <ConfirmationModal
            showConfirmationModal={isToggleLoginConfirmationModalVisible}
            headerText={canLogin ? t("users-and-roles.disable_login") : t("users-and-roles.enable_login")}
            text={t("users-and-roles.toggle_login_message")}
            confirmButtonLabel={t("users-and-roles.modal_yes")}
            cancelButtonLabel={t("users-and-roles.modal_cancel")}
            onConfirm={() => updateCanLogin(!canLogin)}
            onCancel={() => closeToggleLoginConfirmationModal()}
            onClose={() => closeToggleLoginConfirmationModal()}
          />

          <Inspector
            title={t("users-and-roles.edit_role")}
            isActive={this.props.showInspector}
            isBackgroundActionDisabled={true}
            actionButtonLabel={t("users-and-roles.done_button")}
            onActionButtonClick={() => setSelectedRolesForSelectedUser()}
            onClose={() => this.props.closeInspector()}>
            <LoaderWrapper component={Loader} loader={loaderInspector}>
              <div className={styles["roles-list-container"]}>
                {roles &&
                  roles.map((role) => (
                    <Checkbox
                      key={role.id}
                      id={`roles-checkbox-${role.id}`}
                      label={role.name}
                      checked={role.checked}
                      onChange={(value) => onChangeOfRoles({ ...role, checked: value })}
                    />
                  ))}
              </div>
            </LoaderWrapper>
          </Inspector>
          <Inspector
            title={t("users-and-roles.edit_team")}
            isActive={this.props.showTeamsInspector}
            isBackgroundActionDisabled={true}
            actionButtonLabel={t("users-and-roles.done_button")}
            onActionButtonClick={() => setSelectedTeamsForSelectedUser()}
            onClose={() => this.props.closeInspector()}>
            <LoaderWrapper component={Loader} loader={teamsLoaderInspector}>
              <div className={styles["roles-list-container"]}>
                {teams &&
                  teams.map((team) => (
                    <Checkbox
                      id={team.id}
                      key={team.id}
                      label={team.name}
                      checked={team.checked}
                      onChange={(value) => onChangeOfTeams({ ...team, checked: value })}
                    />
                  ))}
              </div>
            </LoaderWrapper>
          </Inspector>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state: PartialAppState, ownProps: OwnProps) => {
  const usersPage = state.usersAndRoles.usersPage;
  return {
    user: usersPage.selectedUser,
    roles: usersPage.ui.allRoles,
    teams: usersPage.ui.allTeams,
    errors: usersPage.ui.errors,
    loaderMain: usersPage.ui.main,
    loaderInspector: usersPage.ui.inspector,
    teamsLoaderInspector: usersPage.ui.teamsInspector,
    loadingAvatar: usersPage.ui.loadingAvatar,
    isDarkModeConfirmationModalVisible: usersPage.ui.isDarkModeConfirmationModalVisible,
    isManageStoryInspectorModalVisible: usersPage.ui.isManageStoryInspectorModalVisible,
    isManageLanguageInspectorModalVisible: usersPage.ui.isManageLanguageInspectorModalVisible,
    isToggleLoginConfirmationModalVisible: usersPage.ui.isToggleLoginConfirmationModalVisible,
    title: "edit_user",
    canCreateCollections: state.features.canCreateCollections,
    isTeamsEnabled: state.features.isTeamsEnabled,
    isDarkModeEnabled: state.features.isDarkModeEnabled,
    isDefaultManageInspectorEnabled: state.features.isDefaultManageInspectorEnabled,
    isProfilePage: ownProps.match.path === USER_PROFILE_PATH,
    memberFields: state.config["member-fields"],
    defaultSocialLinkProviders: state.config["default-social-link-providers"] || [],
    canLogin: usersPage.selectedUser && usersPage.selectedUser["can-login"],
    canLoginModified: usersPage.ui.canLoginModified,
    isTFAEnabled: state.features.isTFAEnabled,
    isDesktopSizeViewport: selectIsDesktopSizeViewport(state),
    publisherConfig: state.config.publisher
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>, ownProps: OwnProps) => {
  const navigate = navigateFn(dispatch);
  const userId = ownProps.match.params.id;
  const path = ownProps.match.path;

  return {
    navigate,
    loadSelectedUserData: () => dispatch(loadSelectedUserData(userId, path)),
    updateUserProfile: (key: string, changes: string | object) =>
      dispatch({ type: actions.UPDATE_USER_PROFILE, payload: { key, changes } }),
    UpdateUserSettings: (key: string, changes: string | object) =>
      dispatch({ type: actions.UPDATE_USER_SETTINGS, payload: { key, changes } }),
    openEditRoleInspector: () => {
      navigate(USERS_EDIT_PATH_ROLES, { id: userId });
      dispatch(showAllRolesWithAssignedRolesSelected());
    },
    openEditTeamsInspector: () => {
      navigate(USERS_EDIT_PATH_TEAMS, { id: userId });
      dispatch(showAllTeamsWithAssignedTeamsSelected());
    },
    closeDarkModeConfirmationModal: () => dispatch({ type: actions.CLOSE_DARK_MODE_CONFIRMATION_MODAL }),
    showDarkModeConfirmationModal: () => dispatch({ type: actions.SHOW_DARK_MODE_CONFIRMATION_MODAL }),
    showToggleLoginConfirmationModal: () => dispatch({ type: actions.SHOW_TOGGLE_LOGIN_CONFIRMATION_MODAL }),
    closeToggleLoginConfirmationModal: () => dispatch({ type: actions.CLOSE_TOGGLE_LOGIN_CONFIRMATION_MODAL }),
    showManageInspectorConfirmationModal: () =>
      dispatch({ type: actions.SHOW_MANAGE_STORY_INSPECTOR_CONFIRMATION_MODAL }),
    closeManageInspectorConfirmationModal: () =>
      dispatch({ type: actions.CLOSE_MANAGE_STORY_INSPECTOR_CONFIRMATION_MODAL }),
    togglelanguageChangeConfirmationModal: () =>
      dispatch({ type: actions.TOGGLE_USER_LANGUAGE_CHANGE_CONFIRMATION_MODAL }),
    saveAndLogout: (user: SelectedUser) => dispatch(saveAndLogout(user, path)),
    closeInspector: () => navigate(USERS_EDIT_PATH, { id: userId }),
    onChangeOfRoles: (updatedRole: UserRole) => dispatch(updateSelectedRoles(updatedRole)),
    onChangeOfTeams: (updatedTeam: UserTeam) => dispatch(updateSelectedTeams(updatedTeam)),
    uploadAvatarForSelectedUser: (files: File[]) => dispatch(uploadAvatarForSelectedUser(files)),
    setSelectedRolesForSelectedUser: () => {
      dispatch(setSelectedRolesForSelectedUser());
      navigate(USERS_EDIT_PATH, { id: userId });
    },
    setSelectedTeamsForSelectedUser: () => {
      dispatch(setSelectedTeamsForSelectedUser());
      navigate(USERS_EDIT_PATH, { id: userId });
    },
    navigateToCollectionPage: (authorCollectionId: string, authorId: number) => {
      if (authorCollectionId) {
        return navigate(COLLECTIONS_SELECTED_COLLECTION_PATH, { collectionId: authorCollectionId });
      } else {
        return navigate(`${COLLECTIONS_CONTENT_SETTINGS_PATH}?author-id=${authorId}`, { collectionId: "new" });
      }
    },
    updateCanLogin: (canLogin: boolean) => {
      dispatch({ type: actions.UPDATE_CAN_LOGIN_FOR_SELECTED_USER, payload: { canLogin } });
      dispatch({ type: actions.CLOSE_TOGGLE_LOGIN_CONFIRMATION_MODAL });
    },
    showTFAEnableSetupNotification: () => {
      dispatch(notificationSuccess(t("user-page.login-and-security.two_factor_auth.enable_setup")));
    }
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  wrapPage({ HeaderComponent: EditUserHeader })
)(EditUserProfile);

export { EditUserProfile, User };
