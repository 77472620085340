/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

export const asanaConnect = () => {
  window.open(
    "https://app.asana.com/-/oauth_authorize?response_type=code&client_id=1002093845261465&redirect_uri=http%3A%2F%2Flocalhost%3A9001%2Fapi%2Fasana%2Flogin%2Fcallback",
    "_blank",
    "height=600,width=800,left=250,top=100,resizable=yes",
    true
  );
};

export interface AsanaProject {
  "project-id": string;
  name: string;
}

export const getAsanaProjects = (): Promise<Array<AsanaProject>> => {
  return client
    .url("/api/asana/projects")
    .get()
    .json();
};
