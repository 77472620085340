/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";

import { PartialAppState } from "./state";
import { NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from "containers/page/actions";
import { navigate } from "utils/routes.utils";
import { FORGOT_PASSWORD_PATH, LOGIN_PATH, SETUP_TWO_FACTOR_AUTH, VALIDATE_TWO_FACTOR_AUTH } from "./routes";
import { RouteDataResponse } from "api/route-data/route-data";
import { validate, toClientValidationError } from "utils/validation.utils";
import {
  setValidationErrorAction,
  clearValidationErrorAction,
  initializeSignInAction,
  signInSuccessAction,
  updateLoginDataAction,
  getLoginDataAction,
  setEmailToResetPasswordAction,
  cancelFormSignInAction,
  onRouteChangedAction,
  updateResetPasswordFieldsAction,
  getTwoFactorAuthTokenSuccessAction,
  getTwoFactorAuthTokenFailureAction,
  loadTwoFactorAuth
} from "./action-creators";
import {
  submitLoginData,
  forgotPassword,
  resetPassword,
  forgotPasswordBKProxy,
  resetPasswordBKProxy,
  getCurrentUser
} from "api/login";
import { oauthAuthorize } from "api/oauth";

import { getQrCode, enableTfa, validateTfa } from "api/tfa";
import { ValidatePasswords } from "./reset-password-fields";
import { isNotNullish } from "utils";
import { notificationError } from "action-creators/notification";
import { get } from "lodash";
import { getSessionRedirectUri, removeSessionRedirectUri, setSessionRedirectUri } from "./utils";

export const sendEmailForResetPassword = () => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const email = getState().login.app.emailToResetPassword;
  const isBKIntegrationsEnabled = getState().login.features.isBridgekeeperIntegrationEnabled;
  const validationError = validate({ email }, { email: { email: { message: "is not valid" } } });

  if (validationError) {
    dispatch(setValidationErrorAction(toClientValidationError<{ email: string }>(validationError)));
    return;
  }

  try {
    if (isBKIntegrationsEnabled) {
      await forgotPasswordBKProxy(email);
    } else {
      await forgotPassword(email);
    }
    dispatch(clearValidationErrorAction());
    dispatch({
      type: NOTIFICATION_SUCCESS,
      payload: { message: `${t("loginPage.messages.send_reset_email_success")} ${email}` }
    });
  } catch (error) {
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: { message: error.error || error.message || t("loginPage.messages.reset_failure") }
    });
  }
};

export const resetNewPassword = (tokenWithQuestionMark: string) => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const newPassword = getState().login.app.newPassword;
  const confirmPassword = getState().login.app.confirmPassword;
  const token = tokenWithQuestionMark.substr(7);
  const isBKIntegrationsEnabled = getState().login.features.isBridgekeeperIntegrationEnabled;
  const validationError = validate(
    { newPassword, confirmPassword },
    {
      newPassword: {
        presence: {
          allowEmpty: false,
          message: t("loginPage.messages.can_t_be_empty_validation")
        }
      },
      confirmPassword: {
        presence: {
          allowEmpty: false,
          message: t("loginPage.messages.can_t_be_empty_validation")
        }
      }
    },
    {}
  );

  if (validationError) {
    dispatch(setValidationErrorAction(toClientValidationError<ValidatePasswords>(validationError)));
    return;
  }

  try {
    if (isBKIntegrationsEnabled) {
      await resetPasswordBKProxy({ "new-password": newPassword, "confirm-password": confirmPassword, token });
    } else {
      await resetPassword({ password: newPassword, "repeat-password": confirmPassword, token });
    }
    dispatch({ type: NOTIFICATION_SUCCESS, payload: { message: t("loginPage.messages.reset_success") } });
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  } catch (error) {
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: { message: error.error || error.message || t("loginPage.messages.reset_failure") }
    });
  }
};

export const signIn = () => async (dispatch: ThunkDispatch<any, any, any>, getState: () => PartialAppState) => {
  dispatch(initializeSignInAction());
  const username = getState().login.app.username;
  const password = getState().login.app.password;

  const validationError = validate(
    { username, password },
    {
      username: { presence: { allowEmpty: false } },
      password: { presence: { allowEmpty: false } }
    }
  );

  if (validationError) {
    dispatch(
      setValidationErrorAction(toClientValidationError<{ username: string; password: string }>(validationError))
    );
    return;
  }

  try {
    const { member, code } = await submitLoginData({ username, password });
    dispatch(clearValidationErrorAction());
    dispatch(signInSuccessAction());

    if (code === "tfa-setup-is-required") {
      dispatch(navigate(SETUP_TWO_FACTOR_AUTH));
      return;
    }

    if (code === "tfa-validation-is-required") {
      dispatch(navigate(VALIDATE_TWO_FACTOR_AUTH));
      return;
    }
    if (member && member.id) {
      const redirectUri = getSessionRedirectUri();
      if (redirectUri) {
        const integrationId = get(getState(), ["login", "config", "bridgekeeper", "sso", "integration-id"]);
        const notificationAction = await handleSSO(redirectUri, integrationId);
        notificationAction && dispatch(notificationAction);
      } else {
        dispatch(navigate("/"));
      }

      return;
    }
  } catch (error) {
    dispatch({ type: NOTIFICATION_ERROR, payload: { message: error.error } });
  }
};

export const getTwoFactorAuthToken = () => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(loadTwoFactorAuth());
  try {
    const response = await getQrCode();
    dispatch(getTwoFactorAuthTokenSuccessAction(response));
  } catch (error) {
    dispatch(getTwoFactorAuthTokenFailureAction(error));
  }
};

export const validateOTPAndSaveSecret = (secretKey: string, otp: string, postEnableHandler: () => void) => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(clearValidationErrorAction());
  const validationError = validate(
    { otp },
    { otp: { presence: { allowEmpty: false }, numericality: { onlyInteger: true } } }
  );

  if (validationError) {
    dispatch(setValidationErrorAction(toClientValidationError<{ otp: string }>(validationError)));
    return;
  }
  try {
    const newOTP = parseInt(otp);
    await enableTfa(secretKey, newOTP);
    postEnableHandler();
  } catch (error) {
    dispatch({ type: NOTIFICATION_ERROR, payload: { message: t("loginPage.messages.invalid_otp") } });
  }
};

export const onConfirmTOTP = (totp: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(clearValidationErrorAction());
  const validationError = validate(
    { totp },
    { totp: { presence: { allowEmpty: false }, numericality: { onlyInteger: true } } }
  );

  if (validationError) {
    dispatch(setValidationErrorAction(toClientValidationError<{ totp: string }>(validationError)));
    return;
  }
  try {
    await validateTfa(totp);
    window.location.href = "/";
  } catch (error) {
    dispatch({ type: NOTIFICATION_ERROR, payload: { message: t("loginPage.messages.invalid_otp") } });
  }
};

export const showLoginError = (hash: string) => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  let message: string | null;
  switch (hash) {
    case "#no-roles-assigned":
      message = t("loginPage.messages.no_roles_assigned");
      break;
    case "#permissions-required-to-access-page":
      message = t("loginPage.messages.permissions_required_to_access_page");
      break;
    case "#feature-disabled":
      message = t("loginPage.messages.feature_disabled");
      break;
    default:
      message = null;
  }
  if (message) {
    dispatch({ type: NOTIFICATION_ERROR, payload: { message } });
  }
};

export const updateLoginData = (key: string, text: string) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(updateLoginDataAction(key, text));
};

export const redirectToForgotPassword = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(navigate(FORGOT_PASSWORD_PATH));
  dispatch(cancelFormSignInAction());
  dispatch(clearValidationErrorAction());
};

export const redirectToLogin = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(navigate(LOGIN_PATH));
  dispatch(clearValidationErrorAction());
};

export const getLoginData = (data: RouteDataResponse) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(getLoginDataAction(data));
};

export const setEmailToResetPassword = (email: string) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(setEmailToResetPasswordAction(email));
};

export const updateResetPasswordFields = (key: string, value: string) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(updateResetPasswordFieldsAction(key, value));
};

export const cancelFormSignIn = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(cancelFormSignInAction());
  dispatch(clearValidationErrorAction());
};

export const onRouteChanged = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(onRouteChangedAction());
};

async function handleSSO(redirectUri: string, integrationId?: number) {
  if (isNotNullish(integrationId)) {
    removeSessionRedirectUri();
    try {
      const oauthResponse = await oauthAuthorize(integrationId, redirectUri);
      if (oauthResponse.redirect_uri) window.location.href = oauthResponse.redirect_uri;
      return;
    } catch (error) {
      setSessionRedirectUri(error.redirect_uri);
      return notificationError(t(`loginPage.sso.error_messages.${error.error_code}`));
    }
  } else {
    return notificationError(t("loginPage.sso.error_messages.missing_client_id"));
  }
}

export const validateSession = (integrationId?: number) => async (dispatch: ThunkDispatch<any, any, any>) => {
  const user = await getCurrentUser();
  if (user) {
    const redirectUri = getSessionRedirectUri();
    if (redirectUri) {
      const notificationAction = await handleSSO(redirectUri, integrationId);
      notificationAction && dispatch(notificationAction);
    } else {
      dispatch(navigate("/"));
    }
  }
};
