/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { getSignedUrl } from "api/video-sign";
import { get } from "lodash";

export interface VideoSignature {
  asset_id: string;
  upload_url: string;
  collection_id: string;
  status: string;
  output: {
    format: string;
    status_url: string;
    playback_url: string;
    thumbnail_url: string[];
    preview_thumbnails_url: string;
  };
}

export interface SignVideo {
  format: string;
  source_id: string;
  mp4_access: boolean;
  title: string;
}

export const uploadVideo = async (payload, callback: (arg0: VideoSignature) => void) => {
  try {
    const signedUrl = await getSignedUrl(payload);
    const uploadUrl = get(signedUrl, ["result", "upload_url"]);
    const signedResponse = get(signedUrl, ["result"]);
    const signedVideoDetails = get(signedUrl, ["result", "output"]);

    const options = {
      method: "PUT",
      body: payload["file"],
      headers: {
        "Content-Type": "video/mp4"
      }
    };

    const response = await fetch(uploadUrl, options);

    if (!response.ok) {
      throw new Error("Failed to upload file");
    }

    callback({
      asset_id: signedResponse["asset_id"],
      upload_url: uploadUrl,
      collection_id: payload["gumletCollectionId"],
      status: "upload-complete",
      output: {
        format: "MP4",
        status_url: signedVideoDetails["status_url"],
        playback_url: signedVideoDetails["playback_url"],
        thumbnail_url: signedVideoDetails["thumbnail_url"],
        preview_thumbnails_url: signedVideoDetails["preview_thumbnails_url"]
      }
    });
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
