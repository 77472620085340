/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { StaticPage, UnSavedStaticPage } from "api/static-pages";

enum actions {
  INIT_LOAD_STATIC_PAGES = "@@static-pages/INIT_LOAD_STATIC_PAGES",
  LOAD_STATIC_PAGES_SUCCESS = "@@static-pages/LOAD_STATIC_PAGES_SUCCESS",
  LOAD_STATIC_PAGES_FAILURE = "@@static-pages/LOAD_STATIC_PAGES_FAILURE",
  INIT_LOAD_NEXT_STATIC_PAGES = "@@static-pages/INIT_LOAD_NEXT_STATIC_PAGES",
  LOAD_NEXT_STATIC_PAGES_SUCCESS = "@@static-pages/LOAD_NEXT_STATIC_PAGES_SUCCESS",
  LOAD_NEXT_STATIC_PAGES_FAILURE = "@@static-pages/LOAD_NEXT_STATIC_PAGES_FAILURE",
  SET_CURRENT_STATIC_PAGE = "@@static-pages/SET_CURRENT_STATIC_PAGE",
  SET_CURRENT_STATIC_PAGE_ERROR = "@@static-pages/SET_CURRENT_STATIC_PAGE_ERROR",
  UPDATE_CURRENT_STATIC_PAGE = "@@static-pages/UPDATE_CURRENT_STATIC_PAGE",
  INIT_SAVE_STATIC_PAGE = "@@static-pages/INIT_SAVE_STATIC_PAGE",
  SAVE_STATIC_PAGE_SUCCESS = "@@static-pages/SAVE_STATIC_PAGE_SUCCESS",
  SAVE_STATIC_PAGE_FAILURE = "@@static-pages/SAVE_STATIC_PAGE_FAILURE",
  INIT_PUBLISH_STATIC_PAGE = "@@static-pages/INIT_PUBLISH_STATIC_PAGE",
  PUBLISH_STATIC_PAGE_SUCCESS = "@@static-pages/PUBLISH_STATIC_PAGE_SUCCESS",
  PUBLISH_STATIC_PAGE_FAILURE = "@@static-pages/PUBLISH_STATIC_PAGE_FAILURE",
  INIT_RETRACT_STATIC_PAGE = "@@static-pages/INIT_RETRACT_STATIC_PAGE",
  RETRACT_STATIC_PAGE_SUCCESS = "@@static-pages/RETRACT_STATIC_PAGE_SUCCESS",
  RETRACT_STATIC_PAGE_FAILURE = "@@static-pages/RETRACT_STATIC_PAGE_FAILURE",
  INIT_DELETE_STATIC_PAGE = "@@static-pages/INIT_DELETE_STATIC_PAGE",
  DELETE_STATIC_PAGE_SUCCESS = "@@static-pages/DELETE_STATIC_PAGE_SUCCESS",
  DELETE_STATIC_PAGE_FAILURE = "@@static-pages/DELETE_STATIC_PAGE_FAILURE",
  OPEN_DELETE_CONFIRMATION = "@@static-pages/OPEN_DELETE_CONFIRMATION",
  CLOSE_DELETE_CONFIRMATION = "@@static-pages/CLOSE_DELETE_CONFIRMATION"
}

export type InitLoadStaticPages = {
  type: actions.INIT_LOAD_STATIC_PAGES;
};

export type InitLoadNextStaticPages = {
  type: actions.INIT_LOAD_NEXT_STATIC_PAGES;
};

export type InitSaveStaticPage = {
  type: actions.INIT_SAVE_STATIC_PAGE;
};

export type InitPublishStaticPage = {
  type: actions.INIT_PUBLISH_STATIC_PAGE;
};

export type PublishStaticPageSuccess = {
  type: actions.PUBLISH_STATIC_PAGE_SUCCESS;
};

export type PublishStaticPageFailure = {
  type: actions.PUBLISH_STATIC_PAGE_FAILURE;
  payload: {
    error: any;
  };
};

export type InitRetractStaticPage = {
  type: actions.INIT_RETRACT_STATIC_PAGE;
};

export type RetractStaticPageSuccess = {
  type: actions.RETRACT_STATIC_PAGE_SUCCESS;
};

export type RetractStaticPageFailure = {
  type: actions.RETRACT_STATIC_PAGE_FAILURE;
  payload: {
    error: any;
  };
};

export type InitDeleteStaticPage = {
  type: actions.INIT_DELETE_STATIC_PAGE;
};

export type DeleteStaticPageSuccess = {
  type: actions.DELETE_STATIC_PAGE_SUCCESS;
};

export type DeleteStaticPageFailure = {
  type: actions.DELETE_STATIC_PAGE_FAILURE;
  payload: {
    error: any;
  };
};

export type SaveStaticPageSuccess = {
  type: actions.SAVE_STATIC_PAGE_SUCCESS;
};

export type SaveStaticPageFailure = {
  type: actions.SAVE_STATIC_PAGE_FAILURE;
  payload: {
    error: any;
  };
};

export type LoadStaticPagesSuccess = {
  type: actions.LOAD_STATIC_PAGES_SUCCESS;
  payload: {
    staticPages: StaticPage[];
    totalCount: number;
  };
};

export type LoadStaticPagesFailure = {
  type: actions.LOAD_STATIC_PAGES_FAILURE;
  payload: { error: any };
};

export type LoadNextStaticPagesSuccess = {
  type: actions.LOAD_NEXT_STATIC_PAGES_SUCCESS;
  payload: {
    staticPages: StaticPage[];
    totalCount: number;
  };
};

export type LoadNextStaticPagesFailure = {
  type: actions.LOAD_NEXT_STATIC_PAGES_FAILURE;
  payload: { error: any };
};

export type SetCurrentStaticPage = {
  type: actions.SET_CURRENT_STATIC_PAGE;
  payload: { staticPage: StaticPage | UnSavedStaticPage };
};

export type SetCurrentStaticPageError = {
  type: actions.SET_CURRENT_STATIC_PAGE_ERROR;
  payload: { error: any };
};

export type UpdateCurrentStaticPage = {
  type: actions.UPDATE_CURRENT_STATIC_PAGE;
  payload: { key: string; value: any };
};

export type OpenDeleteConfirmation = {
  type: actions.OPEN_DELETE_CONFIRMATION;
};

export type CloseDeleteConfirmation = {
  type: actions.CLOSE_DELETE_CONFIRMATION;
};

export type ActionTypes =
  | InitLoadStaticPages
  | LoadStaticPagesSuccess
  | LoadStaticPagesFailure
  | InitLoadNextStaticPages
  | LoadNextStaticPagesSuccess
  | LoadNextStaticPagesFailure
  | SetCurrentStaticPage
  | UpdateCurrentStaticPage
  | InitSaveStaticPage
  | SaveStaticPageSuccess
  | SaveStaticPageFailure
  | InitPublishStaticPage
  | PublishStaticPageSuccess
  | PublishStaticPageFailure
  | InitRetractStaticPage
  | RetractStaticPageSuccess
  | RetractStaticPageFailure
  | InitDeleteStaticPage
  | DeleteStaticPageSuccess
  | DeleteStaticPageFailure
  | SetCurrentStaticPageError
  | OpenDeleteConfirmation
  | CloseDeleteConfirmation;

export default actions;
