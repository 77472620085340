/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ExtendableError } from "utils/error.utils";

export class AttributeNotFoundError extends ExtendableError {
  name: string;

  constructor(name: string, message: string) {
    super(message);
    this.name = name;
  }
}
