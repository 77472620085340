/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import NotificationSystem from "react-notification-system";

import { ThunkDispatch } from "redux-thunk";
import { NOTIFICATION_RESET } from "containers/page/actions";

import "./snackbar.module.css";
import SnackbarMessage from "./snackbar-message";

interface StateProps {
  notification: NotificationSystem.Notification;
}

interface DispatchProps {
  resetNotification: () => void;
}

type Props = StateProps & DispatchProps;

class Snackbar extends React.Component<Props> {
  notificationSystem: NotificationSystem.System | null = null;
  // ref: React.RefObject<NotificationSystem.System> = React.createRef();

  ref = (instance: NotificationSystem.System) => (this.notificationSystem = instance);

  addNotification() {
    if (this.notificationSystem) {
      const uid = this.props.notification.message as string;
      this.notificationSystem.addNotification({
        ...this.props.notification,
        uid,
        message: <SnackbarMessage notification={this.props.notification} />,
        onRemove: this.props.resetNotification
      });
    }
  }

  componentDidUpdate(prevProps: StateProps) {
    if (this.props.notification !== prevProps.notification && this.props.notification.message !== "") {
      this.addNotification();
    }
  }

  render() {
    return (
      /* eslint-disable */
      // library itself asks you to pass style as boolean false to override instead of object
      <div>
        <NotificationSystem ref={this.ref} style={false} />
      </div>
      /* eslint-enable */
    );
  }
}

const mapStateToProps = (state: StateProps) => {
  return {
    notification: state.notification
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DispatchProps => {
  return {
    resetNotification: () => dispatch({ type: NOTIFICATION_RESET })
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Snackbar);

export { Snackbar };
