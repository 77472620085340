/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import styles from "./text.module.css";

class Text extends React.Component<any, any> {
  textRef: any;
  constructor(props) {
    super(props);
    this.textRef = React.createRef();
  }

  componentDidMount() {
    this.appendContentDOM(this.textRef);
  }
  componentDidUpdate() {
    this.appendContentDOM(this.textRef);
  }
  shouldComponentUpdate(prevProps) {
    return prevProps.node.attrs["story-version-id"] !== this.props.node.attrs["story-version-id"];
  }

  appendContentDOM = (ref) => {
    ref && ref.current && ref.current.appendChild(this.props.contentDOM);
  };

  render() {
    const props = this.props;
    return (
      <div
        className={styles[`story-element-${props.storyElement.subtype || props.storyElement.type}-container`]}
        ref={this.textRef}
      />
    );
  }
}

export default Text;
