/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const EmptyPin = ({ width = "24", height = "24", fill = "var(--mono-4)" }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
    width={width}
    height={height}
    viewBox="0 0 24 24">
    <path d="M15.72,10.6a6.36,6.36,0,0,1,2,2.17,5.58,5.58,0,0,1,.72,2.79.85.85,0,0,1-.85.85H12.56v3.76L12,21.85a.27.27,0,0,1-.28.18.27.27,0,0,1-.28-.18l-.57-1.68V16.41H5.81a.86.86,0,0,1-.6-.25.81.81,0,0,1-.24-.6,5.58,5.58,0,0,1,.72-2.79,6.33,6.33,0,0,1,2-2.17l.17-1.51H6.94a.84.84,0,0,1-.85-.84V4.87A.85.85,0,0,1,6.94,4H16.5a.82.82,0,0,1,.6.25.81.81,0,0,1,.24.59V8.25a.82.82,0,0,1-.84.84h-.95Zm-9,4.12h9.91a4.1,4.1,0,0,0-.88-1.8,5.24,5.24,0,0,0-1.61-1.3l-.57-4.21h2V5.72H7.78V7.41h2l-.56,4.21a5.17,5.17,0,0,0-1.62,1.3A4.1,4.1,0,0,0,6.76,14.72Z" />
  </svg>
);

export default EmptyPin;
