/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import TextAreaWithCount from "components/text-area/text-area-with-count";
import { getErrorMessage } from "pages/story-editor/utils";

import { t } from "i18n";
import classnames from "classnames/bind";
import styles from "pages/story-editor/components/manage-fields/summary.module.css";
import { AiGenerateButton } from "../ai-generate-button/ai-generate-button";
import { PartialAppState } from "pages/story-editor/state";
import { compose } from "redux";
import { AllowedStoryFields } from "api/ai";
import { get, isEmpty } from "lodash";
import { connect } from "react-redux";
import { ValidationError as ValidationErrorType } from "api/breaking-news";
import { useState } from "react";
import AiSuggestions from "pages/story-editor/components/ai-suggestions/ai-suggestions";

const cx = classnames.bind(styles);

interface StateProps {
  isGenerateSummaryEnabled: boolean;
}

interface OwnProps {
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
  spec: {
    validations: { "hard-limit": number };
  };
  errors: ValidationErrorType;
}

type Props = OwnProps & StateProps;

export const Summary: React.FC<Props> = ({
  value,
  spec,
  onChange,
  errors,
  disabled = false,
  isGenerateSummaryEnabled
}) => {
  const [generatingSuggestion, setGeneratingSuggestion] = useState(false);
  const [showAiSuggestions, setShowAiSuggestions] = useState(false);
  const maxLength = get(spec, ["validations", "hard-limit"], 140);

  return (
    <div className={cx("summary", { "summary--show-ai-generate-btn": isGenerateSummaryEnabled })}>
      <AiSuggestions
        isOpen={showAiSuggestions}
        onClose={() => setShowAiSuggestions(false)}
        storyField={AllowedStoryFields.SUMMARY}
        characterLimit={maxLength}
        onSuggestionSelect={(suggestion: string) => onChange(suggestion)}
        currentFieldValue={value}
        onGeneratingSuggestion={(generatingSuggestion: boolean) => setGeneratingSuggestion(generatingSuggestion)}
        onSuggestionsChange={(suggestions: string[]) => {
          if (isEmpty(value) && suggestions.length > 0) {
            onChange(suggestions[0]);
          }
        }}>
        <TextAreaWithCount
          label={t("story-editor.inspector.social-description")}
          maxValue={maxLength}
          value={value}
          onChange={onChange}
          errorMessage={getErrorMessage(errors)}
          disabled={disabled}
        />
        {isGenerateSummaryEnabled && (
          <AiGenerateButton
            classname={cx("summary__ai-generate-btn")}
            disabled={disabled}
            loading={generatingSuggestion}
            onClick={() => setShowAiSuggestions(!showAiSuggestions)}
          />
        )}
      </AiSuggestions>
    </div>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    isGenerateSummaryEnabled: get(state.config, ["ai-content-generation", "story", AllowedStoryFields.SUMMARY], false)
  };
};

export default compose(connect(mapStateToProps))(Summary);
