/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import actions, {
  associatedStoryDefaultsFetchSuccess,
  updateAssociatedStoryDefaults,
  saveAssociatedStoryDefaults,
  associatedStoryDefaultsSaveSuccess,
  associatedStoryDefaultsSaveError,
  associatedStoryDefaultsFetchError,
  fetchAssociatedStoryDefaults
} from "./actions";
import { AssociatedStoryFeature, AssociatedStoryDefaults, ValidationErrors } from "api/breaking-news-defaults";

export const fetchAssociatedStoryDefaultsAction = (): fetchAssociatedStoryDefaults => {
  return {
    type: actions.FETCH_ASSOCIATED_STORY_DEFAULTS
  };
};

export const fetchAssociatedStoryDefaultsSuccessAction = (
  associatedStoryFeature: AssociatedStoryFeature
): associatedStoryDefaultsFetchSuccess => {
  return {
    type: actions.ASSOCIATED_STORY_DEFAULTS_FETCH_SUCCESS,
    payload: {
      associatedStoryFeature: associatedStoryFeature
    }
  };
};

export const fetchAssociatedStoryDefaultsErrorAction = (): associatedStoryDefaultsFetchError => {
  return {
    type: actions.ASSOCIATED_STORY_DEFAULTS_FETCH_ERROR
  };
};

export const updateAssociatedStoryDefaultsAction = (
  updatedAssociatedStoryDefaults: AssociatedStoryDefaults
): updateAssociatedStoryDefaults => {
  return {
    type: actions.UPDATE_ASSOCIATED_STORY_DEFAULTS,
    payload: {
      updatedAssociatedStoryDefaults: updatedAssociatedStoryDefaults
    }
  };
};

export const saveAssociatedStoryDefaultsAction = (): saveAssociatedStoryDefaults => {
  return {
    type: actions.SAVE_ASSOCIATED_STORY_DEFAULTS
  };
};

export const associatedStoryDefaultsSaveSuccessAction = (): associatedStoryDefaultsSaveSuccess => {
  return {
    type: actions.ASSOCIATED_STORY_DEFAULTS_SAVE_SUCCESS
  };
};

export const associatedStoryDefaultsSaveErrorAction = (
  errors: ValidationErrors | null
): associatedStoryDefaultsSaveError => {
  return {
    type: actions.ASSOCIATED_STORY_DEFAULTS_SAVE_ERROR,
    payload: { errors }
  };
};
