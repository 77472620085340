/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { t } from "i18n";
import {
  COLLECTIONS_SELECTED_COLLECTION_PATH,
  COLLECTIONS_CONTENT_SETTINGS_PATH,
  COLLECTIONS_PATH,
  COLLECTIONS_PUBLISH_PATH
} from "./routes";
import { navigate } from "../../utils/routes.utils";
import { actions } from "./actions";
import { mandatoryFields, isExistingCollection } from "./utils";
import { buildFilterParams } from "pages/collections/build-search-query";
import { INITIAL_FILTER_SET, NEW_COLLECTION, FilterInput, RulesInput } from "./initial-state";
import { NoCollectionSelectedError } from "./errors";
import {
  fetchCollection,
  updateExistingCollection,
  saveNewCollection,
  ContentType,
  CollectionItemWrapper,
  AssociatedMetadata,
  deleteCollection,
  Metadata,
  uploadFile,
  AnyCollection,
  UnsavedAutomatedCollectionWithRules,
  Value
} from "api/story-collection";
import { ThunkDispatch } from "redux-thunk";
import { ListCollectionItemsResponse, listCollectionItemsPost } from "api/collection-items";
import { getCollectionTemplates } from "api/template-options";
import {
  loadLHSCollectionItemsAction,
  refreshContentAction,
  loadMoreLHSCollectionItemsAction,
  removeItemAction,
  addItemAction,
  toggleAdvancedSearchAction,
  closeAdvancedSearchAction,
  publishingAction,
  publishedAction,
  singleCollectionPageLoadFailureAction,
  setFieldEmptyErrorAction,
  removeFieldErrorAction,
  updateSelectedCollectionAction,
  toggleCardExpandAction,
  setStagingAssociatedMetadataAction,
  loadTemplateOptionsAction,
  singleCollectionPageLoadSuccessAction,
  setSelectedCollectionAction,
  singleCollectionPageLoadingAction,
  resetAction,
  togglePolygonSelectionAction,
  updateGeoPolygonAction,
  setIsCollectionModifiedStateAction
} from "./action-creators";

import { notificationError } from "action-creators/notification";
import { getErrorMessage } from "utils/error.utils";

import pDebounce from "p-debounce";
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from "containers/page/actions";
import { fetchPageViews } from "api/qlitics";
import { CollectionId } from "api/primitive-types";
import { EntityInCollection } from "api/entity";
import { PartialAppState } from "pages/collections/initial-state";
import { get, uniq } from "lodash";
import { generateSlug } from "api/slug";

const debouncedListCollectionsItems = pDebounce(listCollectionItemsPost, 1000);
const loadLHSCollectionItems = (filters: FilterInput | RulesInput, showNotification?: boolean) => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const collections = getState().collections;
  const selectedCollection = collections.collectionPage.selectedCollection;
  const selectedLane = collections.ui.selectedLaneTab;
  const stagingSelectedCollection = collections.collectionPage.stagingSelectedCollection;
  const collectionId = selectedCollection && isExistingCollection(selectedCollection) && selectedCollection.id;
  let [storyParams, collectionParams, scheduledStoryParams] = buildFilterParams(filters, stagingSelectedCollection);

  const offset = filters["offset"];
  const isScheduledTab = filters["isScheduledTab"];

  const loadActionFn = offset ? loadMoreLHSCollectionItemsAction : loadLHSCollectionItemsAction;

  if (collectionId) {
    storyParams["collection-id"] = collectionParams["collection-id"] = collectionId;
  }
  try {
    dispatch(refreshContentAction(true));
    if (!offset || (selectedLane === "story" && !isScheduledTab)) {
      const storyResponse = await debouncedListCollectionsItems(storyParams);
      dispatch(loadActionFn(ContentType.Story, storyResponse));
      pDebounce(dispatch(setStoryCardViewsAction(storyResponse, selectedCollection, offset)), 3000);
    }

    if (selectedCollection && selectedCollection.template === "breaking-news") {
      dispatch(loadLHSCollectionItemsAction(ContentType.Collection, { hits: 0, items: [] }));
      dispatch(loadLHSCollectionItemsAction(ContentType.DraftStory, { hits: 0, items: [] }));
    } else {
      if (
        (!offset || (selectedLane === "story" && isScheduledTab) || selectedLane === "draft-story") &&
        getState().features.isStoryScheduleWithCollectionEnabled
      ) {
        const scheduledStoryResponse = await debouncedListCollectionsItems(scheduledStoryParams);
        dispatch(loadActionFn(ContentType.DraftStory, scheduledStoryResponse));
      }
      if (!offset || selectedLane === "collection") {
        const collectionResponse = await debouncedListCollectionsItems(collectionParams);
        dispatch(loadActionFn(ContentType.Collection, collectionResponse));
      }
    }
    dispatch(refreshContentAction(false));
    showNotification &&
      dispatch({ type: NOTIFICATION_SUCCESS, payload: { message: t("collections.refresh-success") } });
  } catch (error) {
    dispatch(refreshContentAction(false));
    showNotification && dispatch({ type: NOTIFICATION_ERROR, payload: { message: t("collections.refresh-failure") } });
  }
};

export const setStoryCardViewsAction = (
  storyResponse: ListCollectionItemsResponse,
  selectedCollection: AnyCollection | null,
  offset?: number
) => async (dispatch: ThunkDispatch<any, any, any>) => {
  const storyIds = uniq(
    [...storyResponse.items, ...((selectedCollection && selectedCollection.items) || [])]
      .filter((item) => item && item.type === "story")
      .map((item) => item.id)
  ).join(",");

  try {
    const pageViews = await fetchPageViews(storyIds);
    if (offset) {
      dispatch({
        type: actions.SET_MORE_STORY_CARD_PAGE_VIEWS_SUCCESS,
        payload: { stories: storyResponse.items, pageViews: pageViews }
      });
    } else
      dispatch({
        type: actions.SET_STORY_CARD_PAGE_VIEWS_SUCCESS,
        payload: { stories: storyResponse.items, pageViews: pageViews }
      });
  } catch (error) {}
};

const loadTemplateOptions = () => (dispatch: ThunkDispatch<any, any, any>, getState: () => PartialAppState) => {
  const {
    config: { publisher }
  } = getState();
  getCollectionTemplates(publisher)
    .then((collectionTemplateOptions) => dispatch(loadTemplateOptionsAction(collectionTemplateOptions)))
    .catch(() => console.error("[Collection] Could not load template options from the front-end app."));
};

export const createNewCollection = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(navigate(COLLECTIONS_CONTENT_SETTINGS_PATH, { collectionId: "new" }));
};

export const toggleCollectionItemAction = (item: CollectionItemWrapper) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const selectedCollection = getState().collections.collectionPage.selectedCollection;

  if (selectedCollection && selectedCollection.items) {
    dispatch(setIsCollectionModifiedStateAction(true));
    /* eslint-disable */
    // Doing a double equality check instead of a triple equality check for the find method below because the server returns id as a string and typescript recognizes item.id as a number always. - Sai, 3th Dec 2018
    if (selectedCollection.items.find((i) => i.id == item.id)) {
      /* eslint-enable */
      dispatch(removeItemAction(item));
    } else {
      dispatch(addItemAction(item));
    }
  }
};

const performSearchAction = (
  dispatch: ThunkDispatch<any, any, any>,
  filters: FilterInput,
  showNotification?: boolean
) => {
  dispatch(loadLHSCollectionItems(filters, showNotification));
};

function performFilterAction(dispatch: ThunkDispatch<any, any, any>, filters: FilterInput) {
  dispatch(toggleAdvancedSearchAction());
  dispatch(loadLHSCollectionItems(filters));
}

export const resetFilterAction = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch({ type: actions.RESET_FILTER });
  performFilterAction(dispatch, INITIAL_FILTER_SET);
};

export const applyFilterAction = () => (dispatch: ThunkDispatch<any, any, any>, getState: () => PartialAppState) => {
  const filters = getState().collections.collectionPage.filters;
  performFilterAction(dispatch, filters);
};

export const publishCollectionAction = () => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  dispatch(setIsCollectionModifiedStateAction(false));
  dispatch(publishingAction());
  const selectedCollection = getState().collections.collectionPage.selectedCollection;

  if (selectedCollection) {
    if (!selectedCollection.name.trim()) {
      dispatch({
        type: NOTIFICATION_ERROR,
        payload: { message: t("collections.title-missing") }
      });
      return;
    }
    try {
      const collection =
        isExistingCollection(selectedCollection) && selectedCollection.id
          ? await updateExistingCollection(selectedCollection.id, selectedCollection)
          : await saveNewCollection(selectedCollection);
      const successMessage =
        collection.status === "scheduled" ? t("collections.schedule_success") : t("collections.publish_success");

      dispatch(publishedAction(collection));

      dispatch(navigate(COLLECTIONS_SELECTED_COLLECTION_PATH, { collectionId: collection.id }));
      dispatch({
        type: NOTIFICATION_SUCCESS,
        payload: { message: successMessage }
      });
    } catch (error) {
      dispatch(notificationError(getErrorMessage(error, t("collections.messages.connectivity-error"))));

      dispatch(setIsCollectionModifiedStateAction(true));
    }
  }
};

export const loadSingleCollectionPageData = (collectionId?: string) => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  let collection;

  if (collectionId === undefined) {
    dispatch(singleCollectionPageLoadFailureAction(new NoCollectionSelectedError()));
    return;
  }

  dispatch(singleCollectionPageLoadingAction());

  try {
    collection = collectionId === "new" ? NEW_COLLECTION : await fetchCollection(parseInt(collectionId));
  } catch (error) {
    dispatch(singleCollectionPageLoadFailureAction(error));
    return;
  }

  // TODO: Find out what selectedCollectionCoverImage actually does and why it's here. It should ideally be either a component or a reducer.
  dispatch(setSelectedCollectionAction(collection));

  // TODO: Add a domain state to avoid this:
  if (Object.keys(getState().collections.collectionPage.templateOptions["collection-layouts"]).length === 0) {
    dispatch(loadTemplateOptions());
  }

  dispatch(singleCollectionPageLoadSuccessAction());

  await dispatch(loadLHSCollectionItems({ ...INITIAL_FILTER_SET, ...collection["expanded-rules"] }));
};

export const updateCollectionAction = () => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  dispatch(setIsCollectionModifiedStateAction(true));
  dispatch({ type: actions.RESET_FILTER });
  dispatch(closeAdvancedSearchAction());
  const collection = getState().collections.collectionPage.stagingSelectedCollection;

  let filter = { ...INITIAL_FILTER_SET, ...(collection && collection["expanded-rules"]) };

  if (!collection) {
    return;
  }

  mandatoryFields.forEach((field) => {
    if (!collection[field] || collection[field] === "") {
      dispatch(setFieldEmptyErrorAction(field));
    } else {
      if (getState().collections.ui.errors && getState().collections.ui.errors[field]) {
        dispatch(removeFieldErrorAction(field));
      }
    }
  });

  const fieldErrors = getState().collections.ui.errors || {};

  if (collection.template === "series" && !get(collection, ["rules", "story-attributes", "series"], false)) {
    dispatch({ type: NOTIFICATION_ERROR, payload: { message: t("collections.messages.add_series_rule") } });
    return;
  }

  dispatch(loadLHSCollectionItems(filter));

  if (Object.keys(fieldErrors).length === 0) {
    dispatch(updateSelectedCollectionAction());
    dispatch(navigate(COLLECTIONS_SELECTED_COLLECTION_PATH, { collectionId: collection.id || "new" }));
  }
};

export const expandCollectionCardAction = (item: { "associated-metadata": AssociatedMetadata }, index: number) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(toggleCardExpandAction(index));
  const associatedMetadata = item["associated-metadata"] ? item["associated-metadata"] : {};
  dispatch(setStagingAssociatedMetadataAction(associatedMetadata));
};

export const searchCollectionItemsAction = (
  offset?: number,
  isScheduledTab?: boolean,
  showNotification?: boolean
) => async (dispatch: ThunkDispatch<any, any, any>, getState: () => PartialAppState) => {
  const selectedCollection = getState().collections.collectionPage.selectedCollection;
  const selectedFilters = getState().collections.collectionPage.filters;

  const filters =
    selectedCollection && selectedCollection["expanded-rules"]
      ? { ...selectedFilters, ...selectedCollection["expanded-rules"] }
      : selectedFilters;

  performSearchAction(dispatch, { ...filters, offset: offset, isScheduledTab: isScheduledTab }, showNotification);
};

export const deleteCollectionAction = (collectionId: CollectionId) => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  try {
    await deleteCollection(collectionId);
    dispatch(navigate(COLLECTIONS_PATH));
    dispatch({
      type: NOTIFICATION_SUCCESS,
      payload: { message: t("collections.delete_success") }
    });
  } catch (error) {
    dispatch(navigate(COLLECTIONS_SELECTED_COLLECTION_PATH, { collectionId }));
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: { message: t("collections.delete_failure") }
    });
  }
};

export const switchToCollectionAction = (collectionId: CollectionId) => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(navigate(COLLECTIONS_SELECTED_COLLECTION_PATH, { collectionId }));
};

export const setAuthoredStoriesForCollection = (collection: UnsavedAutomatedCollectionWithRules) => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(singleCollectionPageLoadingAction());
  dispatch(setSelectedCollectionAction(collection));

  dispatch(singleCollectionPageLoadSuccessAction());
  await dispatch(loadLHSCollectionItems({ ...INITIAL_FILTER_SET, ...collection["expanded-rules"] } as FilterInput));
};

export const updateStagingSelectedCollectionEntities = (
  selectedEntities: EntityInCollection[],
  entityType: string,
  metadata: Metadata
) => (dispatch: ThunkDispatch<any, any, any>) => {
  let updatedMetadata;
  if (metadata.entities) {
    updatedMetadata = {
      ...metadata,
      entities: {
        ...metadata.entities,
        collectionEntities: {
          ...metadata.entities.collectionEntities,
          [entityType]: selectedEntities
        }
      }
    };
  } else {
    updatedMetadata = {
      ...metadata,
      entities: {
        collectionEntities: { [entityType]: selectedEntities }
      }
    };
  }

  dispatch({
    type: actions.UPDATE_STAGING_SELECTED_COLLECTION,
    payload: { key: "metadata", value: updatedMetadata }
  });
};

const getReadableErrorMesage = (errorMessage: string) => {
  switch (errorMessage) {
    case "invalid-file-size":
      return t(`collections.messages.invalid-file-size`);
    case "invalid-file":
      return t(`collections.messages.invalid-file`);
  }
  return t(`collections.content.upload-failed`);
};

export const cancelPublishAction = (collectionId: number | "new" | void) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(navigate(COLLECTIONS_SELECTED_COLLECTION_PATH, { collectionId: collectionId || "new" }));
};

export const maybePublishAction = (collectionId: number | "new" | void) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(navigate(COLLECTIONS_PUBLISH_PATH, { collectionId: collectionId || "new" }));
};

export const fileUploadAction = (err: Error | null, file: File, fileName: string, metadata: Metadata) => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  if (err) {
    console.log(err);
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: { message: t(`collections.content.exceeds-file-size`) }
    });
    return;
  }
  try {
    const timeStamp = new Date().getTime().toString();
    dispatch({
      type: actions.PDF_UPLOAD,
      payload: fileName
    });
    const response = await uploadFile(file, timeStamp);
    let updatedMetadata = {
      ...metadata,
      "temp-pdf-s3-key": response["temp-file-s3-key"],
      "pdf-upload-timestamp": timeStamp
    };
    dispatch({
      type: actions.PDF_UPLOAD_SUCCESS
    });

    dispatch({
      type: actions.UPDATE_STAGING_SELECTED_COLLECTION,
      payload: { key: "metadata", value: updatedMetadata }
    });
  } catch (e) {
    dispatch({
      type: actions.PDF_UPLOAD_FAILURE
    });
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: {
        message: getReadableErrorMesage(JSON.parse(e.message).error.message)
      }
    });
  }
};

export const resetCollection = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(resetAction());
};

export const setGeneratedSlug = (collection: AnyCollection) => async (dispatch: ThunkDispatch<any, any, any>) => {
  if (!collection.hasOwnProperty("id")) {
    try {
      const response = await generateSlug(collection.name || collection.slug);
      if (response.slug) {
        dispatch({
          type: actions.UPDATE_STAGING_SELECTED_COLLECTION,
          payload: { key: "slug", value: response.slug }
        });
      }
    } catch (error) {
      console.error("Failed to generate slug");
    }
  }
};

export const togglePolygonSelection = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(togglePolygonSelectionAction());
};

export const updateGeoPolygon = (geoPolygon: Array<google.maps.LatLngLiteral>) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(updateGeoPolygonAction(geoPolygon));
};

export const updateGeoPolygonRules = () => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const collections = getState().collections;
  const selectedCollection = collections.collectionPage.stagingSelectedCollection;
  const currentLocationRules =
    selectedCollection && selectedCollection["rules"] && selectedCollection["rules"].location;
  if (currentLocationRules) {
    dispatch({
      type: actions.UPDATE_STAGING_SELECTED_COLLECTION_RULES,
      payload: {
        key: "location",
        value: { "is-enabled": currentLocationRules["is-enabled"], "geo-polygon": collections.app.geoPolygon }
      }
    });
  }
  dispatch(updateGeoPolygonAction([]));
  dispatch(togglePolygonSelection());
};

export const updateLocationFilterToggle = (isEnabled: boolean) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const selectedCollection = getState().collections.collectionPage.stagingSelectedCollection;
  const currentLocationRules =
    selectedCollection && selectedCollection["rules"] && selectedCollection["rules"].location;
  dispatch({
    type: actions.UPDATE_STAGING_SELECTED_COLLECTION_RULES,
    payload: {
      key: "location",
      value: {
        "is-enabled": isEnabled,
        "geo-polygon": (currentLocationRules && currentLocationRules["geo-polygon"]) || []
      }
    }
  });
};

export const updateSortedItemsAction = (items: CollectionItemWrapper[]) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch({ type: actions.UPDATE_SORTED_ITEMS, payload: { items } });
  dispatch(setIsCollectionModifiedStateAction(true));
};

export const updateAssociatedMetadataAction = (index: number, key: string, value: Value) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch({ type: actions.UPDATE_COLLECTION_CARD, payload: { index, key, value } });
  dispatch(setIsCollectionModifiedStateAction(true));
};
