/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import classnames from "classnames/bind";
import Button from "../../../components/button/button";
import { addNewCard } from "../action-creators";
import PlusIcon from "../../../components/icons/plus";
import DownArrowCircle from "../../../components/icons/down-arrow-circle";
import { navigate } from "utils/routes.utils";
import { STORY_EDITOR_STORY_SEARCH_PATH } from "pages/story-editor/routes";
import { StoryId } from "api/primitive-types";
import { PartialAppState } from "../state";
import { ThunkDispatch } from "redux-thunk";
import { selectIsDesktopSizeViewport } from "store/viewport";
import styles from "./insert-card-bar.module.css";

import { t } from "i18n";
import { NOTIFICATION_INFO } from "containers/page/actions";

export enum Position {
  TOP = "top",
  BOTTOM = "bottom"
}

const cx = classnames.bind(styles);

interface StateProps {
  storyId: StoryId;
  areCardsPresent?: boolean;
  isDesktopSizeViewport: boolean;
  isStoryLocked: boolean;
}

interface DispatchProps {
  addNewCard: () => void;
  openImportCardInspector: (storyId: StoryId) => void;
  showSnackBar(message: string): void;
}

interface ownProps {
  position?: Position;
  showSnackBar(message: string): void;
  isStoryLocked: boolean;
  readOnlyStoryVersionId?: any;
}

type Props = StateProps & DispatchProps & ownProps;

const InsertCardBar: React.SFC<Props> = ({
  addNewCard,
  openImportCardInspector,
  storyId,
  position = Position.BOTTOM,
  areCardsPresent,
  isDesktopSizeViewport,
  showSnackBar,
  isStoryLocked,
  readOnlyStoryVersionId
}) => {
  const isBarOnTop = position === Position.TOP || !areCardsPresent;
  const classes = cx("insert-card-bar", { top: isBarOnTop });

  return (
    <div className={styles["insert-card-bar-container"]}>
      {(isStoryLocked || readOnlyStoryVersionId) && (
        <div
          className={styles["insert-card-block-container"]}
          onClick={() => showSnackBar(t("story-editor.read_only_mode"))}
        />
      )}
      <div className={classes} data-test-id="insert-card-bar">
        <Button
          onClick={addNewCard}
          classname={cx("insert-card-bar-button", "add-new-card-button")}
          testId="add-new-card">
          <PlusIcon fill="var(--mono-4)" />
          {t("story-editor.insert-card-bar.add-new-card")}
        </Button>
        <Button
          onClick={() => openImportCardInspector(storyId)}
          classname={cx("insert-card-bar-button", "import-card-button")}
          testId="import-card">
          <DownArrowCircle />
          {isDesktopSizeViewport && t("story-editor.insert-card-bar.import-card")}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    storyId: state.storyEditor.story["story-content-id"],
    areCardsPresent: state.storyEditor.story.tree.length > 0,
    isDesktopSizeViewport: selectIsDesktopSizeViewport(state),
    isStoryLocked: state.storyEditor.ui.isStoryLocked
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    addNewCard() {
      dispatch(addNewCard());
    },
    openImportCardInspector(storyId) {
      dispatch(navigate(STORY_EDITOR_STORY_SEARCH_PATH, { id: storyId }));
    },
    showSnackBar: (message) => dispatch({ type: NOTIFICATION_INFO, payload: { message, action: null } })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InsertCardBar);

export { InsertCardBar };
