/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AmpConfig } from "api/amp-config";
import actions, {
  ListAmpConfigAction,
  ListAmpConfigSuccessAction,
  ListAmpConfigFailureAction,
  UpdateAmpConfigAction,
  SetErrorsAction
} from "./actions";

export function listAmpConfigAction(): ListAmpConfigAction {
  return { type: actions.LIST_AMP_CONFIG };
}

export function listAmpConfigSuccessAction(ampConfig: AmpConfig): ListAmpConfigSuccessAction {
  return { type: actions.LIST_AMP_CONFIG_SUCCESS, payload: { ampConfig } };
}

export function listAmpConfigFailureAction(error: Error): ListAmpConfigFailureAction {
  return { type: actions.LIST_AMP_CONFIG_FAILURE, payload: { error } };
}

export function updateAmpConfigAction(key: string, changes: Object): UpdateAmpConfigAction {
  return { type: actions.UPDATE_AMP_CONFIG, payload: { key, changes } };
}

export function setErrorsAction(errors: Error): SetErrorsAction {
  return { type: actions.SET_ERRORS, payload: { errors } };
}
