/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AnyMenuItem, MenuItem, UnsavedMenuItemType, MenuItemToSave } from "api/menu-item";

export function isExistingMenuItem(menuItem: AnyMenuItem): menuItem is MenuItem {
  return !!(menuItem as MenuItem).id;
}

export function menuItemToUnsavedMenuItem(
  menuItem: Omit<AnyMenuItem, "item-type"> & { "item-type": UnsavedMenuItemType }
): MenuItemToSave {
  return {
    title: menuItem.title,
    "parent-id": menuItem["parent-id"],
    "item-type": menuItem["item-type"],
    "item-id": menuItem["item-id"],
    tag: menuItem.tag,
    "menu-group-slug": menuItem["menu-group-slug"],
    "menu-group-id": menuItem["menu-group-id"],
    data: menuItem.data
  };
}
