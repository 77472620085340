/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { Fragment, ReactNode, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";
import pDebounce from "p-debounce";
import { Node } from "prosemirror-model";
import { ClearIndicator, IndicatorProps } from "react-select/lib/components/indicators";
import { listPersonEntities } from "api/entity";
import { StoryElement, CompositeStoryElement, ChildStoryElement } from "api/story";
import Async from "components/select/async";
import TextField from "components/text-field/text-field";
import { actions } from "../../actions";
import { PartialAppState } from "pages/story-editor/state";
import { updateStoryElementEntities } from "pages/story-editor/async-action-creators";
import { setFormattingToolbarActive, setFormattingToolbarInActive } from "pages/story-editor/action-creators";
import { StoryElementId } from "api/primitive-types";
import { CommonProps } from "react-select/lib/types";
import { updateQuoteHTMLWithAttribution } from "pages/story-editor/prosemirror/utils";
import styles from "./text-and-select.module.css";
import get from "lodash/get";

class CustomClearIndicator<OptionType> extends React.Component<IndicatorProps<OptionType>> {
  render() {
    return (
      <ClearIndicator {...this.props}>
        <Fragment />
      </ClearIndicator>
    );
  }
}

interface DropdownOption {
  value: string;
  label: string;
}

type SingleValueProps = CommonProps<DropdownOption> & {
  // using custom SingleValueProps component as select types library requires children to be string
  isDisabled: boolean;
  children: ReactNode;
  data: DropdownOption;
  innerProps: any;
};

const SingleValue = (props: SingleValueProps) => {
  return <span>{`@${props.children}`}</span>;
};

const debouncedListEntities = pDebounce(
  (name: string) => listPersonEntities(name.slice(1)).then((response) => response["entities"]),
  250
);

const onChange = (
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement,
  value: any,
  changefn: (id: StoryElementId, changes: any) => void
) => {
  if (storyElement) {
    const text = updateQuoteHTMLWithAttribution(value || "", storyElement.text);
    const name = value && (typeof value === "string" ? value : value.name);
    let memberOrEntity = { entity: undefined, member: undefined };
    if (value && typeof value !== "string") {
      if (value.hasOwnProperty("entity-type-id")) {
        memberOrEntity = { entity: value, member: undefined };
      } else {
        memberOrEntity = { member: value, entity: undefined };
      }
    }

    const changes = {
      ...storyElement,
      metadata: { ...storyElement.metadata, attribution: name, ...memberOrEntity },
      text: text
    };
    changefn(storyElement.id, changes);
  }
};

interface TextAndSelectProps {
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement;
  onChangeAttribution(id: StoryElementId, changes: any): void;
  onChangeAttributionEntity(id: StoryElementId, changes: any): void;
  setToolbarActive: () => void;
  setToolbarInActive: () => void;
  readOnly?: boolean;
}

const TextAndSelect: React.FC<TextAndSelectProps> = (props) => {
  const attribution = props.storyElement && props.storyElement.metadata && props.storyElement.metadata.attribution;
  const startingInput = attribution && typeof attribution === "string" ? attribution : "";
  const [currentInput, setCurrentInput] = useState(startingInput);
  useEffect(() => {
    setCurrentInput(startingInput);
  }, [startingInput]);
  const memberOrEntity = get(props.storyElement, "metadata.member") || get(props.storyElement, "metadata.entity");

  function handleSelectInputChange(input: string) {
    setCurrentInput(input);
    if (!input.startsWith("@") && !memberOrEntity) {
      onChange(props.storyElement, input, props.onChangeAttribution);
    }
  }

  return (
    <Fragment>
      {currentInput.startsWith("@") || !!memberOrEntity ? (
        <Async
          placeholder={t("story-editor.story-element.enter-attribution-entity")}
          inputValue={currentInput}
          onInputChange={handleSelectInputChange}
          value={memberOrEntity}
          loadOptions={debouncedListEntities}
          getOptionLabel={(option: any) => option.name}
          getOptionValue={(option) => option.id}
          onChange={(value) => {
            onChange(props.storyElement, value, props.onChangeAttributionEntity);
          }}
          hideSelectedOptions={true}
          menuPlacement="bottom"
          variant="editor"
          components={{ SingleValue: SingleValue, DropdownIndicator: null, ClearIndicator: CustomClearIndicator }}
          isLabelUpperCase={true}
          isClearable={true}
          autoFocus={currentInput.startsWith("@")}
          onMenuOpen={props.setToolbarActive}
          onMenuClose={props.setToolbarInActive}
        />
      ) : (
        <TextField
          {...props}
          value={currentInput}
          onChange={(value) => {
            setCurrentInput(value);
            onChange(props.storyElement, value, props.onChangeAttribution);
          }}
          placeholder={t("story-editor.story-element.enter-attribution-entity")}
          errorEnabled={false}
          classname={styles["text-and-select-text-attribution"]}
          labelId="quote-attribution-label"
          disabled={props.readOnly}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: PartialAppState, { node }: { node: Node }) => {
  return {
    type: node.attrs.helperName,
    storyElement: state.storyEditor.story["story-elements"][node.attrs.id],
    readOnly: state.storyEditor.ui.isStoryLocked
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
  return {
    onChangeAttribution: (id: StoryElementId, changes: StoryElement) =>
      dispatch({ type: actions.UPDATE_STORY_ELEMENT, payload: { id, changes } }),
    onChangeAttributionEntity: (id: StoryElementId, changes: StoryElement) => {
      dispatch({ type: actions.UPDATE_STORY_ELEMENT, payload: { id, changes } });
      const entity = get(changes.metadata, "entity", null);
      dispatch(updateStoryElementEntities(id, entity));
    },
    setToolbarActive: () => dispatch(setFormattingToolbarActive()),
    setToolbarInActive: () => dispatch(setFormattingToolbarInActive())
  };
};

export { TextAndSelect };

export default connect(mapStateToProps, mapDispatchToProps)(TextAndSelect);
