/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ExtendableError } from "utils/error.utils";
import { BreakingNewsId } from "api/primitive-types";

export class BreakingNewsNotFound extends ExtendableError {
  id: BreakingNewsId;

  constructor(message: string, id: BreakingNewsId) {
    super(message);
    this.id = id;
  }
}
