/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

export interface QrData {
  "qr-code-text": string;
  "secret-key": string;
}

interface TFAUser {
  id: number;
  name: string | null;
  email: string;
  "created-at": number;
  "avatar-url": string | null;
  "first-name": string | null;
  "last-name": string | null;
  "verification-status": string | null;
  "avatar-s3-key": string | null;
  slug: string | null;
  "communication-email": string | null;
  "phone-number": string | null;
  "updated-at": number;
  "is-tfa-enabled": boolean;
}

interface ValidateTfaResponse {
  user: TFAUser;
  member: TFAUser;
  "active-sessions-count": number;
}

type EnableTFAResposne = ValidateTfaResponse | { message: string };

export const getQrCode = (): Promise<QrData> => {
  return client
    .url("/api/auth/v1/tfa/qr-code")
    .get()
    .json();
};

export const enableTfa = (secretKey: string, otp: number): Promise<EnableTFAResposne> => {
  return client
    .url("/api/auth/v1/tfa/enable")
    .post({ secret: secretKey, otp: otp.toString() })
    .json();
};

export const validateTfa = (otp: string): Promise<ValidateTfaResponse> => {
  return client
    .url("/api/auth/v1/tfa/validate")
    .post({ otp })
    .json();
};
