/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import DatePicker from "react-datepicker";
import { setHours, setMinutes, isSameDay, addMinutes, startOfDay, endOfDay } from "date-fns";
import { convertToUTC } from "./utils";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./date-picker.module.css";
import { t } from "i18n";

const cx = classnames.bind(styles);

class DatePickerCustom extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      minTime: this.props.maxTime || this.validMinTime(this.props.selectedDate),
      maxTime: this.props.maxTime || setHours(setMinutes(new Date(), 59), 23),
      disabled: this.props.disabled
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(day) {
    if (this.props.showTimeSelect) {
      this.setState({
        minTime: this.validMinTime(day)
      });
    }
  }

  validMinTime(date): Date {
    const currentDate = new Date();
    date = date || currentDate;
    if (isSameDay(new Date(date), currentDate))
      // prevent current minute from being valid selection
      return addMinutes(currentDate, 1);
    return setHours(setMinutes(date, 0), 0);
  }

  getMaxTime(): Date {
    // Get the current date and time
    const currentDate = new Date();

    // Create a new Date object using the current time to avoid modifying the original object
    const currentDateObject = new Date(currentDate.getTime());

    // If `selectedDate` is provided, convert it to a timestamp; otherwise, use the current date
    const selectedDate = this.props.selectedDate ? new Date(this.props.selectedDate).getTime() : currentDate;

    // Create a new Date object for the selected date to compare later
    const selectedDateObject = new Date(selectedDate);

    // Check if the current date's day is the same as the selected date's day
    if (isSameDay(currentDateObject, selectedDateObject)) {
      // If the current date is the same as the selected date, return the current date and time
      return currentDate;
    }
    // If the current date is earlier than the selected date
    else if (currentDateObject < selectedDate) {
      // Set the time of the current date to 00:00:00 (midnight)
      return new Date(startOfDay(currentDate));
    }

    // If none of the above conditions are met, set the time to 23:59:00 (just before midnight)
    return new Date(endOfDay(currentDate));
  }

  render() {
    const currentDate = new Date();
    const currentTimezoneOffset = -(currentDate.getTimezoneOffset() / 60);
    if (this.props.showTimeSelect) {
      return (
        <div className={cx("date-picker-container", this.props.classname)} data-test-id="date-picker-container">
          <DatePicker
            className={cx("input-container", { "is-disabled": this.state.disabled })}
            disabled={this.state.disabled}
            readOnly={this.props.readOnly || false}
            selected={this.props.selectedDate || this.state.minTime}
            onChange={(date: any) => this.props.onChange(Date.parse(date))}
            onSelect={this.handleSelect}
            showTimeSelect
            dateFormat={this.props.dateFormat || "dd/MM/yyyy h:mm aa"}
            timeIntervals={1}
            minDate={this.props.allowPastDateSelection ? null : currentDate}
            maxDate={this.props.isStoryPublished ? currentDate : null}
            maxTime={
              this.props.allowPastDateSelection && this.props.isStoryPublished ? this.getMaxTime() : this.state.maxTime
            }
            minTime={
              this.props.allowPastDateSelection ? new Date(currentDate.setHours(0, 0, 0, 0)) : this.state.minTime
            }
          />
          <div className={styles["time-zone"]}>
            <span>{Intl.DateTimeFormat().resolvedOptions().timeZone}:</span>
            <span>{currentTimezoneOffset}</span>
          </div>
        </div>
      );
    }
    return (
      <div className={cx("date-picker-container", this.props.classname)} data-test-id="date-picker-container">
        <DatePicker
          className={cx("input-container", { "is-disabled": this.state.disabled })}
          disabled={this.state.disabled}
          selected={this.props.selectedDate}
          onChange={(date: any) => {
            const convertedDate = this.props.dateInUTC ? convertToUTC(date) : Date.parse(date);
            this.props.onChange(convertedDate);
          }}
          dateFormat={this.props.dateFormat || "dd/MM/yyyy"}
          minDate={this.props.minDate}
          maxDate={this.props.maxDate}
          placeholderText={t("common.select-placeholder")}
        />
        {this.props.showTimeZone && (
          <div className={styles["time-zone"]}>
            <span>{Intl.DateTimeFormat().resolvedOptions().timeZone}:</span>
            <span>{currentTimezoneOffset}</span>
          </div>
        )}
      </div>
    );
  }
}

export default DatePickerCustom;
