/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface SubScriptIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const SubScript: React.SFC<SubScriptIconProps> = ({ width = "24", height = "24", color = "currentColor" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 24 24">
    <path d="M8.56,3.94a8.83,8.83,0,0,0-2.83.45A6.6,6.6,0,0,0,3.42,5.65l.77,1.27a5.63,5.63,0,0,1,1.9-1.09,6.59,6.59,0,0,1,2.3-.4,3.73,3.73,0,0,1,2.66.85A3.25,3.25,0,0,1,12,8.77v.76H8a5.68,5.68,0,0,0-3.71,1,3.2,3.2,0,0,0-1.16,2.59,3.25,3.25,0,0,0,1.24,2.66,5.18,5.18,0,0,0,3.36,1,5.93,5.93,0,0,0,2.66-.55A4,4,0,0,0,12,14.69v2h1.63V8.84a4.76,4.76,0,0,0-1.32-3.66A5.31,5.31,0,0,0,8.56,3.94ZM12,12.87a4,4,0,0,1-1.54,1.91A4.49,4.49,0,0,1,8,15.44a3.77,3.77,0,0,1-2.35-.64,2.05,2.05,0,0,1-.84-1.74q0-2.25,3.24-2.25h4Z" />
    <path d="M20.48,16.53a3.12,3.12,0,0,0-1.22-1.17,3.9,3.9,0,0,0-3.52,0,3.12,3.12,0,0,0-1.22,1.17,3.27,3.27,0,0,0-.43,1.69,3.33,3.33,0,0,0,.43,1.7,3.12,3.12,0,0,0,1.22,1.17,3.9,3.9,0,0,0,3.52,0,3.12,3.12,0,0,0,1.22-1.17,3.33,3.33,0,0,0,.43-1.7A3.27,3.27,0,0,0,20.48,16.53Zm-1.62,3.14a2,2,0,0,1-2.73,0,2.25,2.25,0,0,1,0-2.9,1.94,1.94,0,0,1,2.73,0,2.22,2.22,0,0,1,0,2.9Z" />
  </svg>
);

export default SubScript;
