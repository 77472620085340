/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import Attributes from "./attributes";
import { ATTRIBUTE_EDIT_PATH, ATTRIBUTES_INDEX_PATH, ATTRIBUTES_NEW_PATH } from "./routes";

export const Router = () => (
  <React.Fragment>
    <Switch>
      <Route exact path={ATTRIBUTES_INDEX_PATH} component={Attributes} />
      <Route exact path={ATTRIBUTES_NEW_PATH} component={Attributes} />
      <Route exact path={ATTRIBUTE_EDIT_PATH} component={Attributes} />
    </Switch>
  </React.Fragment>
);
