/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect, useState } from "react";
import TextArea from "react-textarea-autosize";
import { formatNumberToMetricSystem } from "utils";
import FieldLabel from "components/field-label/field-label";
import styles from "./text-area-with-count.module.css";
import ErrorMessage from "components/error-message/error-message";
import classnames from "classnames/bind";
const cx = classnames.bind(styles);

interface TextAreaWithCountProps {
  value: string;
  label?: string;
  onChange: (value: string) => void;
  maxValue?: number;
  placeholder?: string;
  errorMessage?: string;
  disabled?: boolean;
}

const isExceedingCount = (value: string, maxValue: number) => (value ? value.length > maxValue : false);

const TextAreaWithCount: React.SFC<TextAreaWithCountProps> = ({
  value,
  label = "",
  onChange,
  maxValue,
  placeholder = "",
  errorMessage = "",
  disabled
}) => {
  const className = cx("text-field", {
    "has-error": errorMessage.length > 0
  });
  const countClasses = cx("text-field-count", { "is-exceeding": maxValue && isExceedingCount(value, maxValue) });
  const [textAreaValue, setTextAreaValue] = useState(value);
  useEffect(() => {
    setTextAreaValue(value);
  }, [value]);

  const handleOnChange = (e) => {
    setTextAreaValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className={className}>
      <div className={styles["text-field-with-count"]} data-test-id="text-field-with-count">
        <FieldLabel htmlFor={`text-field-${label}`} label={label} />
        {maxValue && (
          <span className={countClasses}>{`${value ? value.length : 0} / ${formatNumberToMetricSystem(
            maxValue
          )}`}</span>
        )}
      </div>
      <TextArea
        className={styles["text-area-input"]}
        data-test-id="text-area-input"
        id={`text-field-${label}`}
        value={textAreaValue}
        placeholder={disabled ? "" : placeholder}
        onChange={handleOnChange}
        disabled={disabled}
      />
      <ErrorMessage data-test-id={"em-error-message"} message={errorMessage} />
    </div>
  );
};

export default TextAreaWithCount;
