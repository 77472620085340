/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useRef } from "react";
import { AnyAction } from "@reduxjs/toolkit";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import t from "i18n";
import { navigate } from "utils/routes.utils";
import { COLLECTIONS_TABS_PATH } from "pages/collections/routes";
import { defaultTab, PartialAppState, Tab } from "store/collection/collections-dashboard";
import { ScrollTabList } from "components/tabs/tabs";
import { SelectTabs } from "pages/workspace/components/select-tabs/select-tabs";
import styles from "./tabs.module.css";

interface StateProps {
  currentTab?: Tab;
  isDesktopSizeViewport: boolean;
}

interface DispatchProps {
  switchTab: (tab: string) => void;
}

const DashboardTabs: React.FC<StateProps & DispatchProps> = ({
  currentTab = defaultTab,
  switchTab,
  isDesktopSizeViewport
}) => {
  const { current: allTabs } = useRef<{ value: Tab; label: string }[]>([
    { value: "pinned", label: t("common.pinned") },
    { value: "all", label: t("common.all") },
    { value: "scheduled", label: t("common.scheduled") }
  ]);

  return (
    <>
      {isDesktopSizeViewport ? (
        <ScrollTabList className={styles["tabs"]} tabs={allTabs} value={currentTab} onChange={switchTab} />
      ) : (
        <SelectTabs options={allTabs} value={currentTab} onChange={switchTab} />
      )}
    </>
  );
};

function mapStateToProps(state: PartialAppState): StateProps {
  return {
    currentTab: state.collectionsDashboard.ui.currentTab,
    isDesktopSizeViewport: state.viewport.isDesktopSizeViewport
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps {
  return {
    switchTab: (tab) => dispatch(navigate(COLLECTIONS_TABS_PATH, { tab }))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTabs);
