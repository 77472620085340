/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as api from "api/promotional-messages";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";
import {
  loadPromotionalMessagesRequestAction,
  loadPromotionalMessagesSuccessAction,
  loadPromotionalMessagesFailureAction,
  savePromotionalMessagesInitAction
} from "./action-creators";
import { Config } from "api/promotional-messages";
import { NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from "containers/page/actions";

export const loadPromotionalMessages = () => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(loadPromotionalMessagesRequestAction());
  try {
    const promotionalMessages = await api.getPromotionalMessages();
    dispatch(loadPromotionalMessagesSuccessAction(promotionalMessages));
  } catch (error) {
    dispatch(loadPromotionalMessagesFailureAction(error));
  }
};

export const savePromotionalMessages = (promotionalMessagesConfig: Config) => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(savePromotionalMessagesInitAction());
  try {
    await api.savePromotionalMessages(promotionalMessagesConfig);
    dispatch(loadPromotionalMessages());
    dispatch({ type: NOTIFICATION_SUCCESS, payload: { message: t("promotional_messages.success_message") } });
  } catch ({ error }) {
    dispatch({ type: NOTIFICATION_ERROR, payload: { message: error } });
  }
};
