/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { useId } from "react-id-generator";
import classnames from "classnames/bind";
import styles from "./switch.module.css";

const cx = classnames.bind(styles);

interface Props {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  classname?: string;
  id?: string;
}

// TODO Remove id prop from usages of this component
const Switch: React.FC<Props> = ({ checked, onChange, disabled, classname }) => {
  let [inputId] = useId(1, "switch-");

  return (
    <React.Fragment>
      <input
        type="checkbox"
        id={inputId}
        name="switch-button"
        data-test-id="switch-checkbox-button"
        className={cx("switch-input", { "is-disabled": disabled })}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
      <label htmlFor={inputId} className={cx("switch-label", classname)} />
    </React.Fragment>
  );
};

export { Switch };
