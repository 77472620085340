/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

import StoryTypeCard from "pages/collections/components/story-type-card/story-type-card";
import CollectionTypeCard from "pages/collections/components/collection-type-card/collection-type-card";

import { Props as StoryCardProps } from "../story-type-card/story-type-card";
import { Props as CollectionCardProps } from "../collection-type-card/collection-type-card";

import { ContentType, CollectionItemWrapper } from "api/story-collection";

export type Props = Omit<StoryCardProps, "item"> &
  Omit<CollectionCardProps, "item"> & {
    item: CollectionItemWrapper;
  };

const CollectionCard: React.SFC<Props> = ({
  item,
  isSelected,
  badge,
  toggleCollectionItem,
  type,
  changePageViewsThreshold,
  updatePageViewsThreshold,
  index,
  updateStagingAssociatedMetadata,
  isCardExpanded,
  toggleCardExpanded,
  stagingAssociatedMetadata,
  changeAssociatedMetadataLayout,
  cancelCardExpanded,
  updateAssociatedMetadata,
  templateOptions,
  isAssociatedMetadataEnabled,
  pageViews,
  expandCollectionCard,
  attributes,
  hasCollectionRankNestedCollectionPermission,
  threshold,
  isDragging
}) => {
  switch (item.type) {
    case ContentType.Story:
      return (
        <StoryTypeCard
          item={item}
          isSelected={isSelected}
          isDragging={isDragging}
          badge={badge}
          toggleCollectionItem={toggleCollectionItem}
          type={type}
          changePageViewsThreshold={changePageViewsThreshold}
          updatePageViewsThreshold={updatePageViewsThreshold}
          index={index}
          isCardExpanded={isCardExpanded}
          toggleCardExpanded={toggleCardExpanded}
          cancelCardExpanded={cancelCardExpanded}
          pageViews={pageViews}
          threshold={threshold}
          attributes={attributes}
          updateStagingAssociatedMetadata={updateStagingAssociatedMetadata}
          stagingAssociatedMetadata={stagingAssociatedMetadata}
          updateAssociatedMetadata={updateAssociatedMetadata}
        />
      );
    case ContentType.Collection:
      return (
        <CollectionTypeCard
          item={item}
          isSelected={isSelected}
          isDragging={isDragging}
          badge={badge}
          toggleCollectionItem={toggleCollectionItem}
          type={type}
          index={index}
          updateStagingAssociatedMetadata={updateStagingAssociatedMetadata}
          isCardExpanded={isCardExpanded}
          stagingAssociatedMetadata={stagingAssociatedMetadata}
          changeAssociatedMetadataLayout={changeAssociatedMetadataLayout}
          cancelCardExpanded={cancelCardExpanded}
          updateAssociatedMetadata={updateAssociatedMetadata}
          templateOptions={templateOptions}
          isAssociatedMetadataEnabled={isAssociatedMetadataEnabled}
          expandCollectionCard={expandCollectionCard}
          hasCollectionRankNestedCollectionPermission={hasCollectionRankNestedCollectionPermission}
          updatePageViewsThreshold={() => undefined}
        />
      );
    case ContentType.BreakingNews:
      return (
        <StoryTypeCard
          item={item}
          isSelected={isSelected}
          isDragging={isDragging}
          badge={badge}
          toggleCollectionItem={toggleCollectionItem}
          type={type}
          changePageViewsThreshold={changePageViewsThreshold}
          updatePageViewsThreshold={updatePageViewsThreshold}
          index={index}
          isCardExpanded={isCardExpanded}
          toggleCardExpanded={toggleCardExpanded}
          cancelCardExpanded={cancelCardExpanded}
          pageViews={pageViews}
          threshold={threshold}
          attributes={attributes}
          updateStagingAssociatedMetadata={updateStagingAssociatedMetadata}
          stagingAssociatedMetadata={stagingAssociatedMetadata}
          updateAssociatedMetadata={updateAssociatedMetadata}
        />
      );
  }
};

export default CollectionCard;
