/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as actions from "../containers/page/actions";

interface Action {
  label: string;
  callback: () => void;
}

export function notificationError(message: string, action?: Action) {
  return {
    type: actions.NOTIFICATION_ERROR,
    payload: {
      message,
      action
    }
  };
}

export function notificationSuccess(message: string, action?: Action) {
  return {
    type: actions.NOTIFICATION_SUCCESS,
    payload: {
      message,
      action
    }
  };
}

export function notificationInfo(message: string, action?: Action) {
  return {
    type: actions.NOTIFICATION_INFO,
    payload: {
      message,
      action
    }
  };
}
