/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { validate } from "utils/validation.utils";
import { UnsavedPushNotification, PushNotificationUrlType } from "api/push-notification";
import { ValidationResult } from "utils/validation.utils";
import { t } from "i18n";
import { get } from "lodash";

function pushNotificationConstraints(channelsEnabled: boolean, pushNotification: UnsavedPushNotification) {
  const fieldConstraints = {
    "push-notification": {
      presence: {
        allowEmpty: false,
        message: "^" + t("push-notification.messages.message-missing")
      }
    }
  };
  if (channelsEnabled) {
    fieldConstraints["target-categories"] = {
      presence: {
        allowEmpty: false,
        message: "^" + t("push-notification.messages.channels_missing")
      }
    };
  } else {
    fieldConstraints["target-categories"] = {
      presence: {
        allowEmpty: false,
        message: "^" + t("push-notification.messages.platform-missing")
      }
    };
  }
  if (get(pushNotification, ["metadata", "type"]) === PushNotificationUrlType.STORY) {
    fieldConstraints["story-content-id"] = {
      presence: {
        allowEmpty: false,
        message: "^" + t("push-notification.messages.story-missing")
      }
    };
  } else if (get(pushNotification, ["metadata", "type"]) === PushNotificationUrlType.CUSTOM_URL) {
    fieldConstraints["metadata.external-url"] = {
      presence: {
        allowEmpty: false,
        message: "^" + t("push-notification.messages.url-missing")
      },
      url: {
        message: "^" + t("push-notification.messages.invalid_url"),
        length: {
          maximum: 255,
          tooLong: "^" + t("push-notification.messages.too_long_url")
        }
      }
    };
  }
  return fieldConstraints;
}

export function validatePushNotification(
  pushNotification: UnsavedPushNotification,
  channelsEnabled: boolean
): ValidationResult<UnsavedPushNotification> {
  return validate(pushNotification, pushNotificationConstraints(channelsEnabled, pushNotification));
}
