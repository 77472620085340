/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CurrentWindowConfig {
  isInSlider: boolean;
  isOpenedAsSliderFromMainWindow: boolean;
}

export interface SliderWindowConfig {
  enabled: boolean;
  open: boolean;
  iframeUrl: string;
  showUpdatesIndicator: boolean;
}

export interface SliderState {
  currentWindow: CurrentWindowConfig;
  sliderWindow: SliderWindowConfig;
}

export const INITIAL_SLIDER_STATE: SliderState = {
  currentWindow: {
    isInSlider: false,
    isOpenedAsSliderFromMainWindow: false
  },
  sliderWindow: {
    enabled: false,
    open: false,
    iframeUrl: "",
    showUpdatesIndicator: false
  }
};

const { reducer, actions } = createSlice({
  initialState: INITIAL_SLIDER_STATE,
  name: "slider",
  reducers: {
    setCurrentWindowConfig: (state: SliderState, { payload }: PayloadAction<Partial<CurrentWindowConfig>>) => {
      state.currentWindow = { ...state.currentWindow, ...payload };
    },
    setSliderWindowConfig: (state: SliderState, { payload }: PayloadAction<Partial<SliderWindowConfig>>) => {
      state.sliderWindow = { ...state.sliderWindow, ...payload };
    },
    showSliderUpdatesIndicator: (state: SliderState, { payload }: PayloadAction<boolean>) => {
      state.sliderWindow = { ...state.sliderWindow, showUpdatesIndicator: payload };
    },
    toggleSlider: (state: SliderState) => {
      state.sliderWindow = { ...state.sliderWindow, open: !state.sliderWindow.open };
    }
  }
});

export const { setCurrentWindowConfig, setSliderWindowConfig, showSliderUpdatesIndicator, toggleSlider } = actions;
export default reducer;
