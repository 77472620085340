/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { clientWithoutCreds } from "./client";

export interface SeoStory {
  content: string;
  title: string;
  "meta-description": string;
}

export interface SeoStatItem {
  errors: string[];
  warnings: string[];
  goodies: string[];
}

export interface SeoStats {
  title: SeoStatItem;
  "meta-description": SeoStatItem;
  content: SeoStatItem;
}

export const getValidatorStats = (story: SeoStory, focus: string, apiHost: string): Promise<SeoStats> => {
  return clientWithoutCreds
    .url(apiHost)
    .post({
      "focus-keyword": focus,
      story
    })
    .json();
};
