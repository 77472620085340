/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Button as EmButton } from "@quintype/em/components/button";
import { ButtonWithLoader as EmButtonWithLoader } from "@quintype/em/components/button";

export interface ButtonProps {
  /** Specifies the type of the button  */
  type?: "primary" | "secondary" | "default" | "transparent" | "link";
  /** On click event handler */
  onClick?: React.MouseEventHandler;
  /** Variants of the buttons */
  variant?: "success" | "danger" | "warning";
  /** Test id */
  testId?: string;
  /** Id for the button component */
  id?: string;
  /** Toggles disabled property */
  disabled?: boolean;
  /** Toggles greyed out aesthetics */
  greyed?: boolean;
  /** Custom classname */
  classname?: string;
  /** Label of the button */
  children: React.ReactChild | React.ReactChild[];
  /** Form id if a form is being associated */
  form?: string;
}

interface ButtonWithLoaderProps extends Omit<ButtonProps, "greyed"> {
  loading?: boolean;
}

const Button = (props: ButtonProps) => <EmButton {...props} />;
const ButtonWithLoader = (props: ButtonWithLoaderProps) => <EmButtonWithLoader {...props} />;

export default Button;
export { Button };
export { ButtonWithLoader };
