/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import Integrations from "./integrations";
import {
  INTEGRATION_EDIT_PATH,
  INTEGRATION_NEW_PATH,
  INTEGRATIONS,
  INTEGRATIONS_LISTING_PATH,
  INTEGRATION_SETTINGS
} from "./routes";
import { IntegrationsPageInspectorType, IntegrationType } from "./state";

export const IntegrationsRouter = () => (
  <React.Fragment>
    <Switch>
      <Route
        exact
        path={INTEGRATIONS}
        render={(props) => <Integrations {...props} integrationType={IntegrationType.ALL} />}
      />
      <Route
        exact
        path={INTEGRATIONS_LISTING_PATH}
        render={(props) => <Integrations {...props} integrationType={props.match.params.type} />}
      />
      <Route
        exact
        path={INTEGRATION_NEW_PATH}
        render={(props) => (
          <Integrations
            {...props}
            inspectorType={IntegrationsPageInspectorType.Create}
            integrationType={props.match.params.type}
          />
        )}
      />
      <Route
        exact
        path={INTEGRATION_SETTINGS}
        render={(props) => (
          <Integrations
            {...props}
            inspectorType={IntegrationsPageInspectorType.INTEGRATION_SETTINGS}
            integrationType={props.match.params.type}
          />
        )}
      />
      <Route
        exact
        path={INTEGRATION_EDIT_PATH}
        render={(props) => (
          <Integrations
            {...props}
            inspectorType={IntegrationsPageInspectorType.Edit}
            integrationType={props.match.params.type}
            integrationName={props.match.params.integration}
          />
        )}
      />
    </Switch>
  </React.Fragment>
);
