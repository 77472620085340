/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import TextAreaWithCount from "components/text-area/text-area-with-count";
import { AllowedStoryFields } from "api/ai";
import { t } from "i18n";
import { getErrorMessage } from "pages/story-editor/utils";
import React, { useState } from "react";
import styles from "pages/story-editor/components/manage-fields/meta-description.module.css";
import { AiGenerateButton } from "pages/story-editor/components/ai-generate-button/ai-generate-button";
import classnames from "classnames/bind";
import { compose } from "redux";
import { connect } from "react-redux";
import { PartialAppState } from "pages/story-editor/state";
import { get, isEmpty } from "lodash";
import AiSuggestions from "pages/story-editor/components/ai-suggestions/ai-suggestions";

const cx = classnames.bind(styles);

interface StateProps {
  isGenerateMetaDescriptionEnabled: boolean;
}

export const MetaDescription = ({ value, spec, onChange, errors, disabled, isGenerateMetaDescriptionEnabled }) => {
  const [generatingSuggestion, setGeneratingSuggestion] = useState(false);
  const [showAiSuggestions, setShowAiSuggestions] = useState(false);
  const maxLength = get(spec, ["validations", "hard-limit"], 140);

  return (
    <div
      className={cx("meta-description", {
        "meta-description--show-ai-generate-btn": isGenerateMetaDescriptionEnabled
      })}>
      <AiSuggestions
        isOpen={showAiSuggestions}
        onClose={() => setShowAiSuggestions(false)}
        storyField={AllowedStoryFields.META_DESC}
        characterLimit={maxLength}
        onSuggestionSelect={(suggestion: string) => onChange(suggestion)}
        currentFieldValue={value}
        onGeneratingSuggestion={(generatingSuggestion: boolean) => setGeneratingSuggestion(generatingSuggestion)}
        onSuggestionsChange={(suggestions: string[]) => {
          if (isEmpty(value) && suggestions.length > 0) {
            onChange(suggestions[0]);
          }
        }}>
        <TextAreaWithCount
          label={t("story-editor.inspector.meta-description")}
          maxValue={maxLength}
          value={value}
          onChange={onChange}
          errorMessage={getErrorMessage(errors)}
          disabled={disabled}
        />
        {isGenerateMetaDescriptionEnabled && (
          <AiGenerateButton
            classname={cx("meta-description__ai-generate-btn")}
            disabled={disabled}
            loading={generatingSuggestion}
            onClick={() => setShowAiSuggestions(!showAiSuggestions)}
          />
        )}
      </AiSuggestions>
    </div>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    isGenerateMetaDescriptionEnabled: get(
      state.config,
      ["ai-content-generation", "story", AllowedStoryFields.META_DESC],
      false
    )
  };
};

export default compose(connect(mapStateToProps))(MetaDescription);
