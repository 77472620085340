/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import SocialShare from "./social-share";
import { SOCIAL_ACCOUNTS_PATH, SOCIAL_ACCOUNTS_ADD_PATH } from "./routes";

export const Router = () => (
  <React.Fragment>
    <Switch>
      <Route exact path={SOCIAL_ACCOUNTS_PATH} render={(props) => <SocialShare {...props} />} />
      <Route exact path={SOCIAL_ACCOUNTS_ADD_PATH} component={SocialShare} />
    </Switch>
  </React.Fragment>
);
