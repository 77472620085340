/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import wrapPage from "containers/page/page";
import {
  loadSingleCollectionPageData,
  createNewCollection,
  switchToCollectionAction,
  deleteCollectionAction,
  setAuthoredStoriesForCollection,
  resetCollection,
  togglePolygonSelection,
  updateGeoPolygon,
  updateGeoPolygonRules
} from "pages/collections/async-action-creators";
import * as React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { t } from "i18n";
import queryString from "query-string";
import styles from "./collections.module.css";
import Collection from "./containers/collection/collection";
import ContentInspector from "./containers/content-inspector/content-inspector";
import ImageEditInspector from "./containers/image-edit-inspector/image-edit-inspector";
import ImageInspector from "./containers/image-inspector/image-inspector";
import AllItemsInspector from "./containers/all-items-inspector/all-items-inspector";
import CollectionPublishInspector from "./components/publish-inspector/publish-inspector";
import Spinner from "components/spinner/spinner";
import AnimatedLoader from "components/icons/animated-loader";
import { NoCollectionSelectedError } from "./errors";
import File from "components/icons/file";

import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import { wrapRealtime } from "helpers/ably/realtime-provider";
import CollectionsHeader from "./components/header/header";
import ConfirmationModal from "components/confirmation-modal/confirmation-modal";
import Close from "components/icons/close";
import { getUserProfile, Member } from "api/users";
import PhotoEditorWrapper from "./components/photo-editor-wrapper/photo-editor-wrapper";
import Rodal from "rodal";
import { slugify } from "utils/slug.utils";
import Location from "components/location/location";
import Button from "components/button/button";
import classnames from "classnames/bind";
import { PartialAppState } from "./initial-state";
import {
  AnyCollection,
  Collection as ExistingCollection,
  DataSource,
  CollectionType,
  UnsavedAutomatedCollectionWithRules
} from "api/story-collection";
import { LoaderState } from "behaviors/loader/state";
import { PublisherId, CollectionId } from "api/primitive-types";
import { ThunkDispatch } from "redux-thunk";
import { RealtimeMember } from "helpers/ably/types";
import { Location as LocationType } from "history";
import { match as Match } from "react-router";
import { CollectionTab } from "./components/settings/settings";
import AblyPresenceAdapter from "helpers/ably/presence-adapter";
import CollectionPreview from "pages/collections/components/preview/preview";
import { NOTIFICATION_SUCCESS } from "containers/page/actions";

const cx = classnames.bind(styles);

const buildCollectionUsingMember = (member: Member): UnsavedAutomatedCollectionWithRules => ({
  "data-source": DataSource.Automated,
  items: [],
  template: "author",
  type: CollectionType.Content,
  "author-id": member.id ? member.id : null,
  name: member.name ? member.name : "",
  slug: member.name ? slugify(member.name) : "",
  "story-content-ids": [],
  summary: member.bio ? member.bio : null,
  metadata: { "cover-image": null },
  rules: { "author-id": member.id ? member.id.toString() : "" },
  "expanded-rules": {
    "author-id": member.id ? member.id.toString() : "",
    authors: [{ id: member.id ? member.id : 0, name: member.name ? member.name : "" }],
    "story-template": []
  }
});

interface LoaderProps {
  loader: LoaderState;
  createNewCollection(): void;
}

const Loader = ({ loader: { loading, error }, createNewCollection }: LoaderProps) => {
  if (loading) {
    return <Spinner message={t("collections.loading-items")} />;
  }
  if (error) {
    if (error instanceof NoCollectionSelectedError) {
      return (
        <React.Fragment>
          <div className={styles["feedback-box"]}>
            <File width="48" height="48" />
            <p>
              <p className={styles["feedback-message"]}>{t("collections.new_feedback_message")}</p>
              <button className={styles["create-new-link"]} onClick={createNewCollection}>
                {t("collections.create_new")}
              </button>
            </p>
          </div>
        </React.Fragment>
      );
    }
    return (
      <div className={styles["feedback-box"]} data-test-id="feedback-box">
        <File width="48" height="48" />
        <p>
          <p>{t("collections.no_collection_found")}</p>
          <p className={styles["feedback-message"]}>{t("collections.feedback_message")}</p>
          <button
            className={styles["create-new-link"]}
            onClick={createNewCollection}
            data-test-id="create-new-link-btn">
            {t("collections.create_new")}
          </button>
        </p>
      </div>
    );
  }
  return null;
};

interface CollectionsStateProps {
  selectedCollection: AnyCollection | null;
  loader: LoaderState;
  publisherId: PublisherId;
  queryString: string;
  collectionId?: string;
  showFileUploading: boolean;
  uploadFileName: string;
  googleMapsApiKey: string;
  showPolygonSelection: boolean;
  isPreviewEnabled: boolean;
}

interface CollectionsDispatchProps {
  loadSingleCollectionPageData(): void;
  createNewCollection(): void;
  deleteCollection(collectionId: CollectionId): void;
  switchToCollection(collectionId: CollectionId): void;
  setAuthoredStoriesCollection(collection: UnsavedAutomatedCollectionWithRules): void;
  togglePolygonSelection(): void;
  reset(): void;
  updateGeoPolygonAction(polygonVertices: Array<google.maps.LatLngLiteral>): void;
  updateGeoPolygonRulesAction(): void;
  notificationSuccess(message: string): void;
}

interface CollectionsOwnProps {
  location: LocationType;
  match: Match<{ collectionId: string }>;
  adapter: AblyPresenceAdapter;
  inspector?: string;
  inspectorTab?: CollectionTab;
  showCollectionDeleteModal?: boolean;
  realtimeMembers: RealtimeMember[];
  realtimeCurrentMember: RealtimeMember;
  realtimeReinitialize(): void;
}

type CollectionsProps = CollectionsStateProps & CollectionsDispatchProps & CollectionsOwnProps;

class Collections extends React.Component<CollectionsProps, any> {
  componentDidMount() {
    if (this.props.queryString.length > 0) {
      const { "author-id": authorId } = queryString.parse(this.props.queryString);

      authorId && this.props.collectionId === "new"
        ? getUserProfile(authorId as string).then((member) =>
            this.props.setAuthoredStoriesCollection(buildCollectionUsingMember(member as Member))
          )
        : this.props.loadSingleCollectionPageData();
    } else {
      this.props.loadSingleCollectionPageData();
    }
  }

  componentDidUpdate = (prevProps: CollectionsProps) => {
    if (this.props.match.params.collectionId !== prevProps.match.params.collectionId) {
      this.props.loadSingleCollectionPageData();

      const prevChannelName = makeChannelName(prevProps);
      const prevChannel = this.props.adapter.getChannel(prevChannelName);
      this.props.adapter.leave(prevChannel);

      this.props.realtimeReinitialize();
    }
  };

  componentWillUnmount = () => {
    this.props.reset();
  };

  render() {
    const {
      showFileUploading,
      uploadFileName,
      loader,
      createNewCollection,
      realtimeMembers,
      realtimeCurrentMember,
      inspector,
      inspectorTab,
      showCollectionDeleteModal,
      selectedCollection,
      deleteCollection,
      switchToCollection,
      match,
      showPolygonSelection,
      updateGeoPolygonAction,
      updateGeoPolygonRulesAction,
      isPreviewEnabled
    } = this.props;
    return (
      <React.Fragment>
        <Rodal onClose={() => {}} visible={showFileUploading} width={530} height={290}>
          <AnimatedLoader />
          <div className={cx("rodal-header", "collection-file-upload-rodal-header")} data-test-id="rodal-header">
            {t("collections.content.rodal-file-upload-header")} {uploadFileName}
          </div>
          <div className={cx("body", "collection-file-upload-rodal-body")} data-test-id="rodal-body">
            {t("collections.content.rodal-file-upload-body")}
          </div>
        </Rodal>
        <section className={cx("page-container", "collections")} data-test-id="collections">
          <LoaderWrapper
            className="collections-progress-main-area"
            component={Loader}
            loader={loader}
            createNewCollection={createNewCollection}>
            <Collection realtimeMembers={realtimeMembers} realtimeCurrentMember={realtimeCurrentMember} />
            <ImageInspector isActive={inspector === "image"} />
            <ImageEditInspector isActive={inspector === "image-edit"} />
            <AllItemsInspector isActive={inspector === "all-items"} />
            <ContentInspector isActive={inspector === "content"} selectedTab={inspectorTab!} />
            <ConfirmationModal
              showConfirmationModal={showCollectionDeleteModal}
              headerText={t("collections.confirmation_modal.delete_confirmation")}
              text={t("collections.confirmation_modal.delete_message")}
              confirmButtonLabel={t("collections.confirmation_modal.delete")}
              cancelButtonLabel={t("collections.confirmation_modal.cancel")}
              onConfirm={() => deleteCollection((selectedCollection as ExistingCollection).id)}
              onCancel={() => switchToCollection((selectedCollection as ExistingCollection).id)}
              onClose={() => switchToCollection((selectedCollection as ExistingCollection).id)}
            />
            <PhotoEditorWrapper />
          </LoaderWrapper>
        </section>
        <CollectionPublishInspector
          isActive={inspector === "publish"}
          collectionId={match.params.collectionId as CollectionId | "new"}
          selectedCollection={selectedCollection}
        />
        {isPreviewEnabled && <CollectionPreview isActive={inspector === "preview"} />}
        {showPolygonSelection && (
          <Rodal
            onClose={() => {}}
            className={styles["location-container"]}
            visible={true}
            width={Math.min(window.innerWidth, 1000)}
            height={600}>
            <div
              className={styles["location-rodal-close"]}
              data-test-id="location-rodal-close"
              onClick={togglePolygonSelection}>
              <Close width="16" height="16" />
            </div>
            <Location
              className={styles["collection-rodal"]}
              data-test-id="collection-rodal"
              searchBoxLabel={t("collections.location.draw-boundary")}
              enablePolygonSelection={true}
              enableMarkerCreation={false}
              onPolygonComplete={(polygonVertices) => updateGeoPolygonAction(polygonVertices)}
            />
            <div className={styles["location-rodal-button"]} data-test-id="location-polygon-apply">
              <Button type="primary" onClick={updateGeoPolygonRulesAction} testId="location-polygon-apply-btn">
                {t("collections.apply")}
              </Button>
            </div>
          </Rodal>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: PartialAppState, ownProps: CollectionsOwnProps): CollectionsStateProps => {
  return {
    selectedCollection: state.collections.collectionPage.selectedCollection,
    loader: state.collections.areas.main,
    publisherId: state.config.publisher.id,
    queryString: ownProps.location.search,
    collectionId: ownProps.match.params.collectionId,
    showFileUploading: state.collections.ui.showFileUploading,
    uploadFileName: state.collections.ui.uploadFileName,
    googleMapsApiKey: state.config.googleMaps && state.config.googleMaps["api-key"],
    showPolygonSelection: state.collections.ui.showPolygonSelection,
    isPreviewEnabled: !!state.config["preview-id"]
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  ownProps: CollectionsOwnProps
): CollectionsDispatchProps => {
  return {
    loadSingleCollectionPageData: () => dispatch(loadSingleCollectionPageData(ownProps.match.params.collectionId)),
    createNewCollection: () => dispatch(createNewCollection()),
    deleteCollection: (collectionId) => dispatch(deleteCollectionAction(collectionId)),
    switchToCollection: (collectionId) => dispatch(switchToCollectionAction(collectionId)),
    setAuthoredStoriesCollection: (collection) => dispatch(setAuthoredStoriesForCollection(collection)),
    togglePolygonSelection: () => dispatch(togglePolygonSelection()),
    reset: () => dispatch(resetCollection()),
    updateGeoPolygonAction: (polygonVertices) => dispatch(updateGeoPolygon(polygonVertices)),
    updateGeoPolygonRulesAction: () => dispatch(updateGeoPolygonRules()),
    notificationSuccess: (message: string) => dispatch({ type: NOTIFICATION_SUCCESS, payload: { message } })
  };
};

const sortMembersByOpenedAt = (memberA: RealtimeMember, memberB: RealtimeMember) => {
  return memberA.openedAt - memberB.openedAt;
};

const makeChannelName = ({ publisherId, match }: CollectionsProps) => {
  const collectionId = match.params.collectionId;
  return collectionId !== "new" ? publisherId + "::collection::" + collectionId : undefined;
};

export default compose<any, any, any, any>(
  connect(mapStateToProps, mapDispatchToProps),
  wrapPage({ HeaderComponent: CollectionsHeader }),
  wrapRealtime({ makeChannelName, sortMechanism: sortMembersByOpenedAt, updateCurrentMemberIsEditing: () => null })
)(Collections);

export { Collections };
