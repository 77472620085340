/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface H6IconProps {
  width?: string;
  height?: string;
  color?: string;
}

const H6: React.SFC<H6IconProps> = ({ width = "24", height = "24", color = "currentColor" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 24 24">
    <path d="M21.37,13.08A3.42,3.42,0,0,0,20,11.85a4.42,4.42,0,0,0-2-.44,3.75,3.75,0,0,0-2.79,1,3.51,3.51,0,0,1,.9-2.44,3.08,3.08,0,0,1,2.28-.84,3.67,3.67,0,0,1,2.11.57l1-1.92a4.78,4.78,0,0,0-1.41-.58A7.61,7.61,0,0,0,18.32,7a6.23,6.23,0,0,0-3,.71,5,5,0,0,0-2,2.09,6.86,6.86,0,0,0-.73,3.29,5.92,5.92,0,0,0,1.34,4.15,4.78,4.78,0,0,0,3.71,1.45,5,5,0,0,0,2.17-.46,3.5,3.5,0,0,0,2.11-3.3A3.28,3.28,0,0,0,21.37,13.08Zm-2.54,3.21a2,2,0,0,1-1.36.45A2.15,2.15,0,0,1,16,16.27a1.55,1.55,0,0,1-.53-1.21A1.53,1.53,0,0,1,16,13.84a2.05,2.05,0,0,1,1.4-.48,2.07,2.07,0,0,1,1.4.46,1.57,1.57,0,0,1,.53,1.24A1.54,1.54,0,0,1,18.83,16.29Z" />
    <path d="M7.21,9.68A4.35,4.35,0,0,0,5.66,10a3.39,3.39,0,0,0-1.21.77V6.52H1.93v12H4.45V14.2A2.44,2.44,0,0,1,5,12.45a2,2,0,0,1,1.52-.59,1.64,1.64,0,0,1,1.3.51,2.22,2.22,0,0,1,.46,1.54V18.5h2.51v-5a3.87,3.87,0,0,0-1-2.88A3.6,3.6,0,0,0,7.21,9.68Z" />
  </svg>
);

export default H6;
