/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { MemberId, PublisherId, Timestamp } from "./primitive-types";
import { ContributorRole } from "api/route-data/story-route-data";

export interface Author {
  id: MemberId;
  "publisher-id"?: PublisherId;
  name: string;
  email?: string;
  "communication-email"?: null;
  "first-name"?: string | null;
  "last-name"?: string | null;
  source?: string | null;
  bio?: string | null;
  "avatar-s3-key"?: string | null;
  "avatar-url"?: string | null;
  "twitter-handle"?: string | null;
  "created-at"?: Timestamp;
  "updated-at"?: Timestamp;
  "contributor-role"?: ContributorRole;
  "temp-s3-key"?: string;
  "is-tfa-enabled"?: boolean;
}

export interface AuthorWithRole extends Author {
  "contributor-role": ContributorRole;
}

export const searchAuthors = (name: string): Promise<Array<Author>> => {
  const params = name ? { name, limit: 10, fields: "id,name,email" } : { limit: 10, fields: "id,name,email" };
  return client
    .url("/api/author")
    .query(params)
    .get()
    .json();
};

export const getAuthorProfile = (): Promise<Author> => {
  return client
    .url(`/api/v1/authors/me`)
    .get()
    .json();
};

export const updateAuthorProfile = (updatedUser: Author) => {
  return client
    .url(`/api/v1/authors/me`)
    .json(updatedUser)
    .post()
    .json();
};
