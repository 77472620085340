/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Attribute } from "api/story-attributes";
import { kebabCase } from "lodash";

export function findAttribute(attributes: Attribute[], name: string) {
  return attributes.find((attribute) => attribute.name === name);
}

export function addNameToAttribute(
  attribute: Pick<
    Attribute,
    "type" | "values" | "display-name" | "data-type" | "is-mandatory" | "enabled-for-all-templates"
  >,
  existingNames: Array<string>
) {
  /*
   * Adds name to only new attributes, name doesnot change for existing attributes.
   * Name is the unique key for attributes
   */

  const initialName = kebabCase(attribute["display-name"])
    .split("-")
    .join("");

  let name = initialName;

  let counter = 0;
  while (existingNames.includes(name)) {
    name = name + ++counter;
    continue;
  }

  return { ...attribute, name };
}
