/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import classnames from "classnames/bind";
import styles from "./image-gallery.module.css";
import {
  editInspectorImageAction,
  openImageGalleryElementMediaInspector
} from "pages/story-editor/async-action-creators";
import { actions } from "pages/story-editor/actions";
import Image from "pages/story-editor/story-element-types/image/image";
import Trash from "components/icons/trash";
import Chevron from "components/icons/chevron";
import AddNewMedia from "components/add-new-media/add-new-media";
import { DropzoneMessageVariant } from "components/dropzone/dropzone-message";
import { get } from "lodash";
import { STORY_EDITOR_IMAGE_INSPECTOR_PATH } from "pages/story-editor/routes";

const cx = classnames.bind(styles);
export class ImageGallery extends React.Component<any, any> {
  render() {
    const {
      storyElement,
      storyElements,
      dispatch,
      openPhotoEditor,
      isDisabled,
      setMediaForMultiSelect,
      moveStoryElementUp,
      moveStoryElementDown,
      deleteChildImageElement,
      error
    } = this.props;

    const storyElementClientId = storyElement && storyElement["client-id"];
    const imageGalleryClasses = cx("image-gallery-image-wrapper", { "is-disabled": isDisabled });

    const images =
      storyElement &&
      storyElement.tree.map((storyElementId, index) => {
        const imageStoryElement = storyElements[storyElementId];
        return (
          <div className={imageGalleryClasses} key={`image-gallery-image-${storyElementId}`}>
            <Image
              storyElement={imageStoryElement}
              dispatch={dispatch}
              openPhotoEditor={openPhotoEditor}
              error={{
                hyperlink: get(error, [index, "hyperlink"]),
                "alt-text": get(error, [index, "alt-text"])
              }}
            />
            <div className={cx("image-gallery-image-actions-container", "story-editor-child-story-element-actions")}>
              {index !== 0 && (
                <div
                  className={cx("image-gallery-image-action", "image-gallery-image-action--up")}
                  onClick={() => moveStoryElementUp(imageStoryElement, storyElement)}>
                  <Chevron variant="up" fill="currentColor" width={24} height={24} />
                </div>
              )}
              {index !== storyElement.tree.length - 1 && (
                <div
                  className={cx("image-gallery-image-action", "image-gallery-image-action--down")}
                  onClick={() => moveStoryElementDown(imageStoryElement, storyElement)}>
                  <Chevron variant="down" fill="currentColor" width={24} height={24} />
                </div>
              )}
              <div
                className={cx("image-gallery-image-action", "image-gallery-image-action--delete")}
                onClick={() => deleteChildImageElement(imageStoryElement)}>
                <Trash color="currentColor" />
              </div>
            </div>
          </div>
        );
      });

    return (
      <React.Fragment>
        {images}
        <AddNewMedia
          classname={cx("image-gallery-add-images-container", { "is-disabled": isDisabled })}
          openGallery={(e: any) => dispatch(openImageGalleryElementMediaInspector(e, storyElementClientId))}
          setSelectedMedia={(images) => {
            storyElementClientId && setMediaForMultiSelect(images, storyElementClientId);
          }}
          enableMultipleUploads={true}
          dropzoneMessageSize={DropzoneMessageVariant.Medium}
          data-test-id={"image-gallery-add-new-media"}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  storyElements: state.storyEditor.story["story-elements"]
});

const mapDispatchToProps = (dispatch) => ({
  deleteChildImageElement: (storyElement) =>
    dispatch({ type: actions.STORY_EDITOR_DELETE_STORY_ELEMENT, payload: { storyElement } }),
  moveStoryElementUp: (imageStoryElement, parentStoryElement) =>
    dispatch({
      type: actions.STORY_EDITOR_MOVE_STORY_ELEMENT_UP,
      payload: {
        imageStoryElement,
        parentStoryElement
      }
    }),
  moveStoryElementDown: (imageStoryElement, parentStoryElement) =>
    dispatch({
      type: actions.STORY_EDITOR_MOVE_STORY_ELEMENT_DOWN,
      payload: {
        imageStoryElement,
        parentStoryElement
      }
    }),
  setMediaForMultiSelect: (media, storyElementClientId, storyId) => {
    dispatch(
      editInspectorImageAction(storyId, media, STORY_EDITOR_IMAGE_INSPECTOR_PATH, storyElementClientId, "multiImage")
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageGallery);
