/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface CaretProps {
  width?: number;
  height?: number;
  color?: string;
  variant?: "up" | "down" | "left" | "right";
}

const Caret: React.SFC<CaretProps> = ({ width = 24, height = 24, variant = "up", color = "currentColor" }) => {
  let style;

  switch (variant) {
    case "up":
      style = { transform: "rotate(0deg)" };
      break;
    case "left":
      style = { transform: "rotate(-90deg)" };
      break;
    case "right":
      style = { transform: "rotate(90deg)" };
      break;
    case "down":
      style = { transform: "rotate(180deg)" };
      break;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={style}
      width={width}
      height={height}
      fill={color}>
      <path
        fillRule="evenodd"
        d="M17,15H7.94a.61.61,0,0,1-.63-.44.64.64,0,0,1,.14-.75L12,9.24a.68.68,0,0,1,1,0l4.54,4.54a.64.64,0,0,1,.14.75A.63.63,0,0,1,17,15Z"
      />
    </svg>
  );
};

export default Caret;
