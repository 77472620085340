/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import actions, {
  LoadMenuAction,
  LoadMenuSuccessAction,
  LoadMenuFailureAction,
  InitNewMenuAction,
  UpdateMenuAction,
  CancelCreateMenuAction,
  SaveMenuAction,
  SaveMenuSuccessAction,
  SaveMenuFailureAction,
  SelectMenuAction,
  ListMenuItemsAction,
  ListMenuItemsSuccessAction,
  ListMenuItemsFailureAction,
  ReplaceCurrentMenuItemAction,
  InitNewMenuItemAction,
  SaveMenuItemAction,
  SaveMenuItemSuccessAction,
  SaveMenuItemFailureAction,
  MoveMenuItemAction,
  MoveMenuItemSuccessAction,
  MoveMenuItemFailureAction,
  DeleteMenuItemAction,
  DeleteMenuItemSuccessAction,
  DeleteMenuItemFailureAction,
  CancelCreateOrEditMenuItemAction,
  EditMenuItemAction,
  EditMenuItemFailureAction,
  ToggleDeleteConfirmation,
  ResetMenuAction,
  SetGeneratedSlugAction
} from "./actions";
import { MenuGroupList, UnsavedMenuGroup, SavedMenuGroup, MenuGroup } from "api/menu-group";
import { MenuItem, AnyMenuItem, MenuItemMoveDirection, UnsavedMenuItem } from "api/menu-item";

export function loadMenuAction(): LoadMenuAction {
  return { type: actions.LOAD_MENU };
}

export function loadMenuSuccessAction(data: MenuGroupList): LoadMenuSuccessAction {
  return { type: actions.LOAD_MENU_SUCCESS, payload: { data } };
}

export function loadMenuFailureAction(error: Error): LoadMenuFailureAction {
  return { type: actions.LOAD_MENU_FAILURE, payload: { error } };
}

export function initNewMenuAction(): InitNewMenuAction {
  return { type: actions.INIT_NEW_MENU };
}

export function updateMenuAction(data: UnsavedMenuGroup): UpdateMenuAction {
  return { type: actions.UPDATE_MENU, payload: { data } };
}

export function cancelCreateMenuAction(): CancelCreateMenuAction {
  return { type: actions.CANCEL_CREATE_MENU };
}

export function saveMenuAction(): SaveMenuAction {
  return { type: actions.SAVE_MENU };
}

export function saveMenuSuccessAction(menu: SavedMenuGroup): SaveMenuSuccessAction {
  return { type: actions.SAVE_MENU_SUCCESS };
}

export function saveMenuFailureAction(error: Error): SaveMenuFailureAction {
  return { type: actions.SAVE_MENU_FAILURE, payload: { error } };
}

export function selectMenuAction(menu: MenuGroup): SelectMenuAction {
  return { type: actions.SELECT_MENU, payload: { menu } };
}

export function listMenuItemsAction(): ListMenuItemsAction {
  return { type: actions.LIST_MENU_ITEMS };
}

export function listMenuItemsSuccessAction(menuItems: MenuItem[]): ListMenuItemsSuccessAction {
  return { type: actions.LIST_MENU_ITEMS_SUCCESS, payload: { menuItems } };
}

export function listMenuItemsFailureAction(error: Error): ListMenuItemsFailureAction {
  return { type: actions.LIST_MENU_ITEMS_FAILURE, payload: { error } };
}

export function replaceCurrentMenuItemAction(menuItem: AnyMenuItem | null): ReplaceCurrentMenuItemAction {
  return { type: actions.REPLACE_CURRENT_MENU_ITEM, payload: { menuItem } };
}

export function initNewMenuItemAction(menuItem: UnsavedMenuItem): InitNewMenuItemAction {
  return { type: actions.INIT_NEW_MENU_ITEM, payload: { menuItem } };
}

export function editMenuItemAction(menuItem: MenuItem): EditMenuItemAction {
  return { type: actions.EDIT_MENU_ITEM, payload: { menuItem } };
}

export function editMenuItemFailureAction(error: Error): EditMenuItemFailureAction {
  return { type: actions.EDIT_MENU_ITEM_FAILURE, payload: { error } };
}

export function cancelCreateOrEditMenuItemAction(): CancelCreateOrEditMenuItemAction {
  return { type: actions.CANCEL_CREATE_OR_EDIT_MENU_ITEM };
}

export function saveMenuItemAction(menuItem: AnyMenuItem): SaveMenuItemAction {
  return { type: actions.SAVE_MENU_ITEM, payload: { menuItem } };
}

export function saveMenuItemSuccessAction(menuItem: MenuItem): SaveMenuItemSuccessAction {
  return { type: actions.SAVE_MENU_ITEM_SUCCESS, payload: { menuItem } };
}

export function saveMenuItemFailureAction(menuItem: AnyMenuItem, error: Error): SaveMenuItemFailureAction {
  return { type: actions.SAVE_MENU_ITEM_FAILURE, payload: { menuItem, error } };
}

export function deleteMenuItemAction(menuItem: MenuItem): DeleteMenuItemAction {
  return { type: actions.DELETE_MENU_ITEM, payload: { menuItem } };
}

export function deleteMenuItemSuccessAction(menuItem: MenuItem): DeleteMenuItemSuccessAction {
  return { type: actions.DELETE_MENU_ITEM_SUCCESS, payload: { menuItem } };
}

export function deleteMenuItemFailureAction(menuItem: MenuItem, error: Error): DeleteMenuItemFailureAction {
  return { type: actions.DELETE_MENU_ITEM_FAILURE, payload: { menuItem, error } };
}

export function moveMenuItemAction(menuItem: MenuItem, direction: MenuItemMoveDirection): MoveMenuItemAction {
  return { type: actions.MOVE_MENU_ITEM, payload: { menuItem, direction } };
}

export function moveMenuItemSuccessAction(
  menuItem: MenuItem,
  direction: MenuItemMoveDirection
): MoveMenuItemSuccessAction {
  return { type: actions.MOVE_MENU_ITEM_SUCCESS, payload: { menuItem, direction } };
}

export function moveMenuItemFailureAction(
  menuItem: MenuItem,
  direction: MenuItemMoveDirection,
  error: Error
): MoveMenuItemFailureAction {
  return { type: actions.MOVE_MENU_ITEM_FAILURE, payload: { menuItem, direction, error } };
}

export function toggleDeleteConfirmationAction(menuItem: MenuItem | null): ToggleDeleteConfirmation {
  return { type: actions.TOGGLE_DELETE_CONFIRMATION, payload: menuItem };
}

export function resetMenuAction(): ResetMenuAction {
  return { type: actions.RESET_MENU };
}

export function setGeneratedSlugAction(menuGroup: UnsavedMenuGroup): SetGeneratedSlugAction {
  return { type: actions.SET_GENERATED_SLUG, payload: { menuGroup } };
}
