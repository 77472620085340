/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";

const Envelope = ({ width = 16, height = 16, color = "currentColor" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="4 4 16 16" height={height} width={width} fill={color}>
    <path
      d="M18.5,6.2c0.4,0,0.8,0.1,1.1,0.4C19.9,6.9,20,7.3,20,7.7v9c0,0.4-0.1,0.8-0.4,1.1c-0.3,0.3-0.6,0.4-1.1,0.4h-13
		c-0.4,0-0.8-0.1-1.1-0.4S4,17.1,4,16.7v-9c0-0.4,0.1-0.8,0.4-1.1s0.6-0.4,1.1-0.4H18.5z M5.5,7.2c-0.1,0-0.3,0-0.4,0.1S5,7.5,5,7.7
		V9c0.7,0.6,2.3,1.9,4.7,3.8L10,13c0.4,0.4,0.8,0.6,1,0.8c0.4,0.3,0.7,0.4,1,0.4s0.6-0.1,1-0.4c0.2-0.1,0.6-0.4,1-0.8l0.3-0.2
		c2.4-1.9,4-3.2,4.7-3.8V7.7c0-0.1,0-0.3-0.1-0.4s-0.2-0.1-0.4-0.1H5.5z M18.5,17.2c0.1,0,0.3,0,0.4-0.1s0.1-0.2,0.1-0.4v-6.4
		c-0.7,0.6-2.1,1.7-4.1,3.3l-0.3,0.2c-0.5,0.4-0.9,0.7-1.2,0.9c-0.5,0.3-1,0.5-1.4,0.5s-0.9-0.2-1.4-0.5c-0.3-0.2-0.7-0.5-1.2-0.9
		l-0.3-0.2c-2-1.6-3.4-2.7-4.1-3.3v6.4c0,0.1,0,0.3,0.1,0.4s0.2,0.1,0.4,0.1H18.5z"
    />
  </svg>
);

export default Envelope;
