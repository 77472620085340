/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { LoaderState, INITIAL_LOADER_STATE } from "behaviors/loader/state";
import { AnyCustomURL, UnsavedCustomURL, CustomURLType } from "api/custom-url";

export interface PartialAppState {
  customURLs: State;
}

export enum CustomURLsInspectorType {
  Create = "create",
  Edit = "edit"
}

export interface CustomURLsPagination {
  total: number;
  currentPage: number;
  limit: number;
  offset: number;
  itemsPerPage: number;
}
export interface State {
  customURLs: any[];
  pagination: CustomURLsPagination;
  app: {
    currentCustomURL: AnyCustomURL | null;
    error: Error | null;
    searchTerm: string;
  };
  ui: {
    showDeleteConfirmation: boolean;
    inspector: null;
    main: LoaderState;
    save: LoaderState;
  };
}

export const CUSTOM_URLS_PAGINATION: CustomURLsPagination = {
  total: 0,
  currentPage: 1,
  limit: 10,
  offset: 0,
  itemsPerPage: 10
};

export const INITIAL_CUSTOM_URLS_STATE: State = {
  customURLs: [],
  pagination: CUSTOM_URLS_PAGINATION,
  app: {
    currentCustomURL: null,
    error: null,
    searchTerm: ""
  },
  ui: {
    showDeleteConfirmation: false,
    inspector: null,
    main: INITIAL_LOADER_STATE,
    save: { loading: false, error: null }
  }
};

export const NEW_CUSTOM_URL: UnsavedCustomURL = {
  "destination-path": "",
  "source-path": "",
  type: CustomURLType.Redirect
};
