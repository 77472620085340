/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { ClaimReview as ClaimReviewType } from "api/story";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { format } from "date-fns";
import { formatDate } from "utils";
import produce from "immer";
import classnames from "classnames/bind";

import Select from "components/select/select";
import { t } from "i18n";
import TextField from "components/text-field/text-field";
import { getErrorMessage } from "pages/story-editor/utils";
import styles from "./claim-review.module.css";
import FieldLabel from "components/field-label/field-label";
import Checkbox from "components/checkbox/checkbox";
import { get } from "lodash";

const cx = classnames.bind(styles);
interface Props {
  claimReview: ClaimReviewType;
  toggleClaimReview: (value: boolean) => void;
  onSEOChange: (value: ClaimReviewType) => void;
  errors:
    | {
        claimReviewed: { code: string };
        itemReviewed: { author: { name: { code: string }; sameAs: { code: string } } };
        reviewRating: { ratingValue: { code: string } };
      }
    | undefined;
  disabled?: boolean;
}

interface Value {
  alternateName: string;
  ratingValue: string;
}

const REVIEW_RATING_OPTIONS = [
  { ratingValue: "1", alternateName: "False" },
  { ratingValue: "2", alternateName: "Mostly false" },
  { ratingValue: "3", alternateName: "Half true" },
  { ratingValue: "4", alternateName: "Mostly true" },
  { ratingValue: "5", alternateName: "True" }
];

const ClaimReview: React.SFC<Props> = ({ claimReview, toggleClaimReview, onSEOChange, errors, disabled }) => {
  const onChange = (value: ClaimReviewType) => {
    onSEOChange(value);
  };

  return (
    <div className={styles["claim-review-container"]} data-test-id="claim-review-container">
      <div className={styles["claim-review-header"]}>
        <Checkbox
          id="claim-review"
          label={t("story-editor.inspector.claim-review")}
          checked={claimReview.enabled || false}
          onChange={(value) => toggleClaimReview(value)}
          disabled={disabled}
        />
      </div>
      <div className={cx("claim-review-fields", { "is-open": claimReview.enabled })}>
        {claimReview.enabled && (
          <React.Fragment>
            {" "}
            <TextField
              value={claimReview.claimReviewed}
              label={t("story-editor.inspector.summary-of-the-claim")}
              onChange={(value) =>
                onChange(
                  produce(claimReview, (changes) => {
                    changes.claimReviewed = value;
                  })
                )
              }
              errorMessage={errors && getErrorMessage(get(errors, ["claimReviewed"]))}
              disabled={disabled}
            />
            <TextField
              value={claimReview.itemReviewed.author.sameAs}
              label={t("story-editor.inspector.url-of-refered-article")}
              onChange={(value) =>
                onChange(
                  produce(claimReview, (changes) => {
                    changes.itemReviewed.author.sameAs = value;
                  })
                )
              }
              errorMessage={errors && getErrorMessage(get(errors, ["itemReviewed", "author", "sameAs"]))}
              disabled={disabled}
            />
            <TextField
              value={claimReview.itemReviewed.author.name}
              label={t("story-editor.inspector.publisher-of-refered-article")}
              onChange={(value) =>
                onChange(
                  produce(claimReview, (changes) => {
                    changes.itemReviewed.author.name = value;
                  })
                )
              }
              errorMessage={errors && getErrorMessage(get(errors, ["itemReviewed", "author", "name"]))}
              disabled={disabled}
            />
            <div className={styles["claim-review-date"]}>
              <FieldLabel label={t("story-editor.inspector.date-of-publishing")} />
              <DayPickerInput
                value={claimReview.itemReviewed.datePublished}
                format={"yyyy-MM-dd"}
                formatDate={formatDate}
                onDayChange={(day) =>
                  onChange(
                    produce(claimReview, (changes) => {
                      changes.itemReviewed.datePublished = format(day, "yyyy-MM-dd");
                    })
                  )
                }
                inputProps={{ disabled: disabled }}
              />
            </div>
            <Select
              label={t("story-editor.inspector.review-rating")}
              defaultValue={claimReview.reviewRating}
              getOptionLabel={(rating) => rating.alternateName}
              getOptionValue={(rating) => rating.ratingValue}
              options={REVIEW_RATING_OPTIONS}
              onChange={(value: Value) =>
                onChange(
                  produce(claimReview, (changes) => {
                    changes.reviewRating.alternateName = value.alternateName;
                    changes.reviewRating.ratingValue = value.ratingValue;
                  })
                )
              }
              isDisabled={disabled}
              errorMessage={errors && getErrorMessage(get(errors, ["reviewRating", "ratingValue"]))}
            />{" "}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default ClaimReview;
