/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Spinner from "components/spinner/spinner";
import Plus from "components/icons/plus";
import Button from "components/button/button";
import Close from "components/icons/close";
import { LoaderState } from "behaviors/loader/state";
import { TimeoutError } from "api/errors";
import { TagNotFoundError } from "./errors";
import { t } from "i18n";

interface LoaderProps {
  loader: LoaderState;
  loadTags: () => void;
}

const TagLoader: React.SFC<LoaderProps> = ({ loader: { loading, error }, loadTags }) => {
  if (loading) {
    return <Spinner message={t("tags.messages.loading-list")} />;
  }
  if (error) {
    if (error instanceof TimeoutError) {
      return (
        <React.Fragment>
          <Plus width="48" height="48" />
          <p>{t("tags.messages.could-not-load-list")}</p>
          <p>
            <Button onClick={loadTags}>{t("tags.cta.load-retry")}</Button>
          </p>
        </React.Fragment>
      );
    }

    if (error instanceof TagNotFoundError) {
      return (
        <React.Fragment>
          <Close width="48" height="48" />
          <p>{t("tags.messages.not-found", { id: error.id })}</p>
        </React.Fragment>
      );
    }
  }

  return <p>{t("tags.messages.unknown-error")}</p>;
};

export default TagLoader;
