/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import throttle from "helpers/throttle";
import { uploadFile } from "helpers/upload-file";
import { validationFn } from "../utils";

import Plus from "../../../../components/icons/plus";
import Edit from "../../../../components/icons/edit";
import { formatBytesToHumanReadable } from "utils";
import Tooltip from "components/tooltip/tooltip";
import classnames from "classnames/bind";
import { notificationError } from "action-creators/notification";

import styles from "./attachment.module.css";

import { t } from "i18n";

const s3UploadQueue = throttle(uploadFile);
const cx = classnames.bind(styles);

class Attachment extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false
    };
  }

  validationFailed = (attachmentSize, invalidFiletypeOrSizeError) => {
    invalidFiletypeOrSizeError("Invalid file type or size. Please upload size upto " + attachmentSize + "MB");
  };

  uploadToS3 = (file) => {
    return new Promise((resolve, reject) => {
      s3UploadQueue.queueJob(file, function(result) {
        if (result.success) {
          resolve({
            metadata: { "file-size": file.size },
            url: result.url,
            "temp-attachment-key": result.key,
            "file-name": file.name,
            "content-type": result["mime-type"]
          });
        } else {
          reject({ upload: false });
        }
      });
    });
  };

  uploadFile(e, elementId, attachmentSize, invalidFiletypeOrSizeError) {
    const file = e.target.files[0];

    if (file) {
      if (validationFn) {
        if (validationFn(file, "attachment", attachmentSize)) {
          this.setState({
            uploading: true
          });
          this.uploadToS3(file).then(
            (res) => {
              this.props.onChange(elementId, res);
              this.setState({ uploading: false });
            },
            (err) => console.log(err, "File upload failed")
          );
        } else {
          this.validationFailed(attachmentSize, invalidFiletypeOrSizeError);
          e.target.value = null;
        }
      }
    }
  }

  render() {
    const { storyElement, attachmentSize, invalidFiletypeOrSizeError } = this.props;
    const fileUrl = storyElement && storyElement.url;
    const attachmentMessage =
      t("story-editor.story-element.attachment-info") +
      " " +
      attachmentSize +
      t("story-editor.story-element.attachment-size-unit");

    if (!storyElement) {
      return null;
    }
    const classes = cx("attachment-container", { "is-disabled": this.props.isDisabled });
    return (
      <div className={classes} contentEditable={false}>
        <input
          className={styles["file-field"]}
          id={`attachment-${storyElement.id}`}
          type="file"
          onChange={(e) => this.uploadFile(e, storyElement.id, attachmentSize, invalidFiletypeOrSizeError)}
          disabled={this.state.uploading}
        />
        <div className={styles["attachment-input-label"]}>
          {!fileUrl && (
            <React.Fragment>
              <label className={styles["attachment-label-wrapper"]} htmlFor={`attachment-${storyElement.id}`} />
              <Plus height={16} width={16} fill="#5f6978" />
              <p>{t("story-editor.story-element.add-attachment")}</p>
              <p className={styles["info"]}>{attachmentMessage}</p>
            </React.Fragment>
          )}
          {fileUrl && (
            <div className={styles["attachment-details"]}>
              <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                {storyElement["file-name"]}
              </a>
              {storyElement.metadata["file-size"] && (
                <p className={styles["attachment-data"]}>
                  {`(${formatBytesToHumanReadable(storyElement.metadata["file-size"])})`}
                </p>
              )}
              <label
                className={styles["attachment-data-edit"]}
                htmlFor={`attachment-${storyElement.id}`}
                data-tip
                data-for={`attachment-edit-${storyElement.id}`}>
                <Edit height={16} width={16} />
                <Tooltip
                  id={`attachment-edit-${storyElement.id}`}
                  place="bottom"
                  effect="solid"
                  value={t("story-editor.story-element.replace")}
                />
              </label>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    attachmentSize: state.config["attachment-size"]["max-size-in-mb"]
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invalidFiletypeOrSizeError: (error) => dispatch(notificationError(error))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Attachment);
export { Attachment };
