/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import AblyPresenceAdapter from "helpers/ably/presence-adapter";

function buildMembers(clientId, presenceMessages, sortMechanism) {
  const members = presenceMessages
    .map((presenceMessage) => {
      return {
        ...presenceMessage.data.member,
        clientId: presenceMessage.clientId
      };
    })
    .sort(sortMechanism);
  const currentMember = members.filter((member) => member.clientId === clientId)[0];
  return { members, currentMember };
}

function buildEntryMember({ member, forceEditMode }) {
  return {
    id: member && member.id,
    name: member && member.name,
    avatarImageUrl: member && member["avatar-url"],
    openedAt: new Date().getTime(),
    isEditing: !!forceEditMode
  };
}

const wrapRealtime = ({ makeChannelName, updateCurrentMemberIsEditing, sortMechanism }) => (Component) =>
  class RealtimeProvider extends React.Component<any, any> {
    channel: null;
    adapter: AblyPresenceAdapter | null;
    constructor(props) {
      super(props);
      this.channel = null;
      this.adapter = null;
      this.state = {
        members: [],
        currentMember: null,
        connectionState: "connected"
      };
    }

    componentDidMount() {
      this.adapter = new AblyPresenceAdapter(this.initializeRealtime);
    }

    componentDidUpdate(prevProps) {
      if (prevProps.match.params.id !== this.props.match.params.id) {
        this.initializeRealtime();
      }
    }

    initializeRealtime = (mergeProps = {}) => {
      const mergedProps: any = { ...this.props, ...mergeProps },
        channelName = makeChannelName(mergedProps),
        member = buildEntryMember(mergedProps);
      if (this.adapter && this.adapter.isReady() && channelName) {
        this.channel = this.adapter.getChannel(channelName);
        this.adapter.subscribe(this.channel, this.presenceListener);
        this.adapter.subscribeConnection(this.connectionListener);
        this.adapter.enter(this.channel, { member });

        if (mergedProps.optimisticUpdate) {
          this.setState({ currentMember: member, members: [member] });
        }
      }
    };

    connectionListener = (stateChange) => {
      if (this.state.currentMember && this.state.connectionState !== stateChange.current) {
        this.setState({ connectionState: stateChange.current });
      }
    };

    presenceListener = (presenceMessage) => {
      const storyStatus = this.props.story && this.props.story.status;
      const clientId = this.adapter && this.adapter.getClientId();
      this.adapter &&
        this.adapter.getCurrentMembers(this.channel, (err, presenceMessages) => {
          if (err) console.error("Ably:", err);
          else {
            const nextState = buildMembers(clientId, presenceMessages, sortMechanism);

            this.setState(nextState, () => {
              const member = updateCurrentMemberIsEditing(presenceMessage.action, this.state, storyStatus);
              if (member && member !== this.state.currentMember) {
                this.updateCurrentMember(member);
              }
            });
          }
        });
    };

    componentWillUnmount() {
      if (this.adapter && this.adapter.isReady()) {
        this.adapter.leave(this.channel);
      }
    }

    updateCurrentMember = (member) => {
      if (this.adapter && this.adapter.isReady()) {
        this.adapter.update(this.channel, { member });
      }
    };

    render() {
      return (
        <Component
          {...this.props}
          realtimeMembers={this.state.members}
          realtimeCurrentMember={this.state.currentMember}
          realtimeUpdateCurrentMember={this.updateCurrentMember}
          realtimeReinitialize={this.initializeRealtime}
          realtimeConnectionState={this.state.connectionState}
          adapter={this.adapter}
        />
      );
    }
  };

export { wrapRealtime };
