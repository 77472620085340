/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";
import Workspace from "pages/workspace/workspace";

import {
  WORKSPACE_TABS_PATH,
  WORKSPACE_FILTERS_PATH,
  WORKSPACE_BULK_ACTION_PATH,
  WORKSPACE_SAVE_FILTER_PATH,
  WORKSPACE_SAVED_FILTERS_PATH,
  WORKSPACE_DELETE_STORY_PATH
} from "./routes";

export const Router = () => (
  <React.Fragment>
    <Switch>
      <Route exact path={WORKSPACE_TABS_PATH} component={Workspace} />
      <Route
        exact
        path={WORKSPACE_FILTERS_PATH}
        render={(props) => <Workspace {...props} showFilterInspector={true} />}
      />
      <Route
        exact
        path={WORKSPACE_SAVED_FILTERS_PATH}
        render={(props) => <Workspace {...props} showFilterInspector={true} showSavedFilterList={true} />}
      />
      <Route
        exact
        path={WORKSPACE_BULK_ACTION_PATH}
        render={(props) => <Workspace {...props} showBulkActionInspector={true} />}
      />
      <Route
        exact
        path={WORKSPACE_SAVE_FILTER_PATH}
        render={(props) => <Workspace {...props} showSaveFilterInspector={true} />}
      />
      <Route
        exact
        path={WORKSPACE_DELETE_STORY_PATH}
        render={(props) => <Workspace {...props} showStoryDeleteModal={true} />}
      />
    </Switch>
  </React.Fragment>
);
