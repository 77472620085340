/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Edit = ({ width = 24, height = 24, color = "#5f6978" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} width={width} height={height}>
    <g id="Layer_1" data-name="Layer 1">
      <rect x="0.03" y="0.13" />
    </g>
    <g id="Layer_2" data-name="Layer 2">
      <path d="M20.32,5a2.25,2.25,0,0,1,0,3.2L8,20.55l-4,.45a.81.81,0,0,1-.7-.24A.86.86,0,0,1,3,20.05l.46-4L15.82,3.67A2.22,2.22,0,0,1,17.42,3a2.18,2.18,0,0,1,1.6.67ZM7.42,19.49l.71-.7V17.56H6.44V15.87H5.21l-.71.7-.21,2.08L5.35,19.7ZM17,9.93,14.07,7,6.19,14.89H7.42v1.68H9.11V17.8Zm2.57-2.57a1.07,1.07,0,0,0,.32-.79,1.09,1.09,0,0,0-.32-.79L18.21,4.45a1,1,0,0,0-.79-.32,1,1,0,0,0-.79.32L14.88,6.2l2.91,2.92Z" />
    </g>
  </svg>
);

export default Edit;
