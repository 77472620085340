/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

import StoryTemplateFilter from "pages/workspace/components/filters/story-template-filter/story-template-filter";
import DateFilter, { RelativeDateFilter } from "components/date-filter/date-filter";
import SectionFilter from "pages/workspace/components/filters/section-filter/section-filter";
import AuthorFilter from "pages/workspace/components/filters/author-filter/author-filter";
import StoryAttributeFilter from "pages/workspace/components/filters/story-attribute-filter/story-attribute-filter";
import SourceFilter from "pages/workspace/components/filters/source-filter/source-filter";
import { t } from "i18n";
import { Author, FilterSet, Source, StoryTemplate } from "api/workspace";
import { Section } from "api/route-data/route-data";
import { StoryAttribute } from "api/route-data/story-route-data";
import Checkbox from "components/checkbox/checkbox";
import styles from "./filters-form.module.css";
import { filterStoryTemplateWithPrint } from "utils";

interface FiltersFormProps {
  sections: Section[];
  storyTemplates: StoryTemplate[];
  storyAttributes: StoryAttribute[];
  sources: Source[];
  filterSet: FilterSet;
  updateFilter: (key: string, value: any) => void;
  showExternalSourcesFilter?: boolean;
  showAuthorFilter?: boolean;
  isPrintSelected: boolean;
  isStoryPrintEnabled?: boolean;
}

const FiltersForm = ({
  sections,
  storyTemplates,
  storyAttributes,
  sources,
  filterSet,
  updateFilter,
  showExternalSourcesFilter,
  showAuthorFilter,
  isPrintSelected,
  isStoryPrintEnabled
}: FiltersFormProps) => (
  <div className={styles["filters-form"]} data-test-id="filters-form">
    {!isPrintSelected && (
      <StoryTemplateFilter
        storyTemplates={filterStoryTemplateWithPrint(isStoryPrintEnabled, storyTemplates)}
        value={filterSet["story-templates"]}
        onChange={(value: StoryTemplate) => updateFilter("story-templates", value)}
      />
    )}
    <DateFilter
      label={t("workspace.filters_inspector.updated_date")}
      value={filterSet["updated-at"]}
      onChange={(value: RelativeDateFilter) => updateFilter("updated-at", value)}
      classname="filters-form-date-filter-container"
    />
    {!isPrintSelected && (
      <DateFilter
        label={t("workspace.filters_inspector.published_date")}
        value={filterSet["published-at"]}
        onChange={(value: RelativeDateFilter) => updateFilter("published-at", value)}
        classname="filters-form-date-filter-container"
      />
    )}
    <SectionFilter
      value={filterSet["sections"]}
      onChange={(value: Section) => updateFilter("sections", value)}
      sections={sections}
    />
    {showAuthorFilter && (
      <AuthorFilter value={filterSet["authors"]} onChange={(value: Author) => updateFilter("authors", value)} />
    )}
    {storyAttributes.length > 0 && (
      <StoryAttributeFilter
        value={filterSet["attributes"]}
        storyAttributes={storyAttributes}
        onChange={(value: StoryAttribute) => updateFilter("attributes", value)}
      />
    )}
    {!isPrintSelected && showExternalSourcesFilter && sources.length > 0 && (
      <SourceFilter
        value={filterSet["source"]}
        sources={sources}
        onChange={(value: Source) => updateFilter("source", value)}
      />
    )}
    {/* Checkbox to filter Embargoed stories */}
    {!isPrintSelected && (
      <Checkbox
        data-test-id="embargo-filter-checkbox"
        id="embargo-filter-checkbox"
        checked={filterSet["is-embargoed"]}
        label={t("workspace.filters_inspector.show_embargoed_stories")}
        onChange={(checked) => updateFilter("is-embargoed", checked)}
        disabled={false}
      />
    )}
    {/* Checkbox to filter parent stories that are cloned to print */}
    {!isPrintSelected && isStoryPrintEnabled && (
      <Checkbox
        classname={styles["checkbox-field"]}
        data-test-id="clone-to-print-filter-checkbox"
        id="clone-to-print-filter-checkbox"
        checked={filterSet["is-cloned-to-print"]}
        label={t("workspace.filters_inspector.show_cloned_to_print_stories")}
        onChange={(checked) => updateFilter("is-cloned-to-print", checked)}
        disabled={false}
      />
    )}
  </div>
);

export default FiltersForm;
