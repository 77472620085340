/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import INITIAL_STATE, { INITIAL_CLAIM_REVIEW_STATE } from "./initial-state";
import { generateCardTemplate } from "pages/story-editor/utils";
import { updateStoryFeatures, updateStory } from "../reducer";
import { StoryTemplate, Story } from "api/story";
import { SocialCard, SocialAccount } from "api/social";
import { CardId, StoryId } from "api/primitive-types";
import { InspectorData } from "../components/inspector/inspector-data";
import { Image } from "api/search-media-image";
import { Tag } from "api/tag";
import { get, isEmpty, isNil, omit } from "lodash";
import { removeKeyFromObject } from "utils";
import { Entity } from "api/entity";
import { SearchedStory } from "api/search";

import {
  actions,
  VideoOEmbedSearchSuccess,
  ToggleOEmbedInspectorSearchFilter,
  VideoOEmbedSearchFiltersChange,
  VideoOEmbedSearchReset
} from "../actions";

const updateSeoData = ({ path, value }: { path: String; value: string | string[] }): string | string[] =>
  path === "meta-keywords" ? (value as Array<any>).map((keyword) => keyword.value as string) : value;

const updateStoryData = ({ key, value }: { key: string; value: string | [] | Array<{ id: string; name: string }> }) => {
  if (key === "authors") {
    return {
      [key]: isEmpty(value) ? null : value,
      "author-id": get(value, [0, "id"], null),
      "author-name": get(value, [0, "name"], null)
    };
  } else {
    return { [key]: value };
  }
};

const updateSocialCards = (
  socialCards: SocialCard[],
  cardId: CardId,
  key: string,
  value: string | boolean
): SocialCard[] => {
  return socialCards.map((card) => {
    if (card.cardId === cardId) {
      return { ...card, [key]: value };
    } else {
      return card;
    }
  });
};

const addSocialCard = (socialCards: SocialCard[], storyId: StoryId) => {
  return storyId !== "new" ? [generateCardTemplate(storyId)].concat(socialCards) : [...socialCards, {}];
};

const updateSelectedMedia = (selectedMedia: Image[], accountId: number, accountProvider: string) => {
  if (selectedMedia.some((media: Image) => media.id === accountId)) {
    return selectedMedia.filter((media) => media.id !== accountId);
  } else {
    return [...selectedMedia, { id: accountId, type: accountProvider }];
  }
};

const updateSocialCardAccount = (
  socialCards: SocialCard[],
  cardId: CardId,
  accountId: number,
  accountProvider: string
) => {
  return socialCards.map((socialCard) => {
    if (socialCard.cardId === cardId) {
      return {
        ...socialCard,
        selectedMedia: updateSelectedMedia(socialCard.selectedMedia as Image[], accountId, accountProvider)
      };
    } else {
      return socialCard;
    }
  });
};

const addTags = (storyTags: Tag[], tag: Tag) =>
  storyTags.some((storyTag) => storyTag.name === tag.name) ? storyTags : [...storyTags, tag];

const updateEntities = (entities: any, path: string, newEntities: Array<Entity>) => {
  const currentEntities: number[] = newEntities.map((entity) => entity.id);
  return { ...entities, [path]: currentEntities };
};

const addSocialHistory = (socialCards: SocialCard[]) => {
  return socialCards.filter((socialCard) => socialCard.isPosted);
};

const updateSelectedInspectorImages = (
  selectedImages: Image[] | null,
  payload: { key: string; value: string | number | null; imageIndex: number }
): Image[] | null => {
  const { key, value, imageIndex } = payload;
  if (selectedImages) {
    return selectedImages.map((item, index) => {
      if (index !== imageIndex) {
        return item;
      }
      return {
        ...item,
        [key]: value
      };
    });
  }
  return null;
};

export type ActionType =
  | { type: actions.UPDATE_TEMPLATE; payload: { template: StoryTemplate; numberOfCardsToLoad?: number } }
  | { type: actions.UPDATE_STORY; payload: { key: string; value: any } }
  | { type: actions.UPDATE_STORY_FEATURES; payload: { feature: string; changes: any } }
  | { type: actions.UPDATE_SEO; payload: { path: string; value: any } }
  | { type: actions.UPDATE_STORY_ATTRIBUTE; payload: { key: string; value: Array<string> } }
  | { type: actions.ADD_STORY_SOCIAL_CARD; payload: { storyId: StoryId } }
  | { type: actions.DELETE_STORY_SOCIAL_CARD; payload: { cardId: CardId } }
  | { type: actions.TOGGLE_SPONSORED_CONTENT; payload: { value: Boolean } }
  | { type: actions.UPDATE_STORY_METADATA; payload: { key: string; value: any } }
  | { type: actions.LOAD_RECOMMENDED_TAGS; payload: { tags: any } }
  | { type: actions.ADD_TAGS; payload: { tag: any } }
  | { type: actions.UPDATE_ENTITIES_STORY_ATTRIBUTE; payload: { value: Array<Entity> } }
  | {
      type: actions.UPDATE_STORY_TAG;
      payload: { tags: Array<Tag | Entity>; tagEntities: Array<Entity>; updatedKeywords: string[] | undefined };
    }
  | { type: actions.UPDATE_INSPECTOR_DATA; payload: { data: InspectorData } }
  | { type: actions.TOGGLE_CLAIM_REVIEW; payload: { value: boolean } }
  | {
      type: actions.UPDATE_IMAGE_DATA_FOR_INSPECTOR;
      payload: { key: string; value: string | number | null; imageIndex: number };
    }
  | { type: actions.SET_IMAGE_FOR_INSPECTOR; payload: { image: Image | [] } }
  | {
      type: actions.LOAD_SOCIAL_ACCOUNTS_AND_CARDS;
      payload: { socialCards: SocialCard[]; socialAccounts: SocialAccount[] };
    }
  | {
      type: actions.ADD_SOCIAL_NOTIFICATIONS_HISTORY;
      payload: { socialCards: SocialCard[]; socialAccounts: SocialAccount[]; socialCardsHistory: SocialCard[] };
    }
  | { type: actions.UPDATE_STORY_SOCIAL_CARD; payload: { cardId: CardId; key: string; value: any } }
  | {
      type: actions.UPDATE_STORY_SOCIAL_CARD_ACCOUNT;
      payload: { cardId: CardId; accountId: number; accountProvider: string };
    }
  | {
      type: actions.UPDATE_STORY_SEARCH_TERM;
      payload: { term: string };
    }
  | {
      type: actions.SET_STORY_LIST;
      payload: { storyList: SearchedStory[] };
    }
  | {
      type: actions.SET_SELECTED_STORY;
      payload: { selectedStory: Story };
    }
  | {
      type: actions.UPDATE_IMPORT_CARD_ID;
      payload: { cardId: CardId };
    }
  | {
      type: actions.RESET_IMPORT_CARD;
    }
  | {
      type: actions.RESET_IMPORT_CARD_DISPLAY;
    }
  | {
      type: actions.UPDATE_SELECTED_PLACE;
      payload: { place: string };
    }
  | {
      type: actions.SET_PROJECT;
      payload: { "project-id": string; name: string };
    }
  | { type: actions.UPDATE_EDITOR_NOTES; payload: { editorNotes: string } }
  | { type: actions.UPDATE_EMBARGO; payload: { embargoedTill: number | null } }
  | { type: actions.VIDEO_OEMBED_SEARCH_TERM_CHANGE; payload: { term: string } }
  | VideoOEmbedSearchSuccess
  | ToggleOEmbedInspectorSearchFilter
  | VideoOEmbedSearchFiltersChange
  | VideoOEmbedSearchReset;

export default function inspectorReducer(state = INITIAL_STATE, action: ActionType) {
  switch (action.type) {
    case actions.UPDATE_TEMPLATE: {
      const { template, numberOfCardsToLoad } = action.payload;
      const changes = {
        "story-template": template
      };
      const uiOpts = action.payload.template === "live-blog" ? { numberOfCardsShown: numberOfCardsToLoad } : {};
      return updateStory(state, changes, uiOpts);
    }
    case actions.UPDATE_STORY: {
      const changes = updateStoryData(action.payload);
      return updateStory(state, changes, {});
    }
    case actions.UPDATE_STORY_FEATURES: {
      const { feature, changes } = action.payload;
      return updateStoryFeatures(state, feature, changes);
    }
    case actions.UPDATE_SEO: {
      const changes = {
        seo: {
          ...state.story.seo,
          [action.payload.path]: updateSeoData(action.payload)
        }
      };
      return updateStory(state, changes, {});
    }
    case actions.UPDATE_STORY_ATTRIBUTE: {
      const { key, value } = action.payload;
      const storyAttributes = get(state, ["story", "metadata", "story-attributes"]);
      const changes = {
        metadata: {
          ...state.story.metadata,
          "story-attributes":
            isNil(value) || isEmpty(value)
              ? omit(storyAttributes, key)
              : {
                  ...storyAttributes,
                  [key]: value
                }
        }
      };
      return updateStory(state, changes, {});
    }

    case actions.LOAD_SOCIAL_ACCOUNTS_AND_CARDS: {
      return {
        ...state,
        story: {
          ...state.story,
          "social-cards": action.payload.socialCards || [],
          "social-accounts": action.payload.socialAccounts
        }
      };
    }

    case actions.ADD_SOCIAL_NOTIFICATIONS_HISTORY: {
      return {
        ...state,
        story: {
          ...state.story,
          "social-accounts": action.payload.socialAccounts,
          "social-cards-history": addSocialHistory(action.payload.socialCards || [])
        }
      };
    }

    case actions.UPDATE_STORY_SOCIAL_CARD: {
      const { cardId, value, key } = action.payload;
      const changes = {
        "social-cards": updateSocialCards(state.story["social-cards"] || [], cardId, key, value)
      };
      return updateStory(state, changes, {});
    }

    case actions.UPDATE_STORY_SOCIAL_CARD_ACCOUNT: {
      const { cardId, accountId, accountProvider } = action.payload;
      const changes = {
        "social-cards": updateSocialCardAccount(state.story["social-cards"] || [], cardId, accountId, accountProvider)
      };
      return updateStory(state, changes, {});
    }

    case actions.ADD_STORY_SOCIAL_CARD: {
      const changes = {
        "social-cards": addSocialCard(state.story["social-cards"] || [], action.payload.storyId)
      };
      return updateStory(state, changes, {});
    }

    case actions.DELETE_STORY_SOCIAL_CARD: {
      const changes = {
        "social-cards":
          state.story["social-cards"] &&
          state.story["social-cards"].filter((card: SocialCard) => card.cardId !== action.payload.cardId)
      };
      return updateStory(state, changes, {});
    }

    case actions.UPDATE_EMBARGO: {
      const { embargoedTill = null } = action.payload;
      const changes = {
        "embargoed-till": embargoedTill
      };
      return updateStory(state, changes, {});
    }

    case actions.TOGGLE_SPONSORED_CONTENT: {
      const changes = { metadata: removeKeyFromObject("sponsored-by", state.story.metadata) };
      const uiOpts = { showSponsoredContent: action.payload.value };
      return updateStory(state, changes, uiOpts);
    }

    case actions.UPDATE_STORY_METADATA: {
      const { key, value } = action.payload;
      const changes = {
        metadata: {
          ...state.story.metadata,
          [key]: value
        }
      };
      return updateStory(state, changes, {});
    }

    case actions.LOAD_RECOMMENDED_TAGS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          recommendedTags: action.payload.tags
        }
      };
    }

    case actions.ADD_TAGS: {
      const changes = {
        tags: addTags(state.story.tags, action.payload.tag)
      };
      return updateStory(state, changes, {});
    }

    case actions.UPDATE_ENTITIES_STORY_ATTRIBUTE: {
      const changes = {
        entities:
          isNil(action.payload.value) || isEmpty(action.payload.value)
            ? { ...state.story.entities, storyAttributes: [] }
            : updateEntities(state.story.entities, "storyAttributes", action.payload.value)
      };
      return updateStory(state, changes, {});
    }

    case actions.UPDATE_STORY_TAG: {
      const changes = {
        entities: updateEntities(state.story.entities, "tagEntities", action.payload.tagEntities),
        tags: action.payload.tags,
        seo: {
          ...state.story.seo,
          "meta-keywords": action.payload.updatedKeywords
        }
      };
      return updateStory(state, changes, {});
    }

    case actions.UPDATE_INSPECTOR_DATA: {
      return {
        ...state,
        ui: {
          ...state.ui,
          inspector: action.payload.data
        }
      };
    }

    case actions.SET_IMAGE_FOR_INSPECTOR: {
      return {
        ...state,
        ui: {
          ...state.ui,
          imageForInspector: action.payload.image
        }
      };
    }

    case actions.UPDATE_IMAGE_DATA_FOR_INSPECTOR: {
      return {
        ...state,
        ui: {
          ...state.ui,
          imageForInspector: updateSelectedInspectorImages(state.ui.imageForInspector, action.payload)
        }
      };
    }

    case actions.TOGGLE_CLAIM_REVIEW: {
      const claimReview = state.story.seo["claim-review"] || INITIAL_CLAIM_REVIEW_STATE;

      const changes = {
        seo: {
          ...state.story.seo,
          "claim-review": { ...claimReview, enabled: action.payload.value }
        }
      };

      return updateStory(state, changes, {});
    }

    case actions.UPDATE_STORY_SEARCH_TERM: {
      return {
        ...state,
        app: {
          ...state.app,
          importCard: {
            ...state.app.importCard,
            storySearchTerm: action.payload.term
          }
        },
        ui: {
          ...state.ui,
          loading: true
        }
      };
    }

    case actions.SET_STORY_LIST: {
      return {
        ...state,
        app: {
          ...state.app,
          importCard: {
            ...state.app.importCard,
            storyList: action.payload.storyList
          }
        },
        ui: {
          ...state.ui,
          loading: false
        }
      };
    }

    case actions.SET_SELECTED_STORY: {
      return {
        ...state,
        app: {
          ...state.app,
          importCard: {
            ...state.app.importCard,
            selectedStory: action.payload.selectedStory
          }
        }
      };
    }

    case actions.UPDATE_IMPORT_CARD_ID: {
      return {
        ...state,
        app: {
          ...state.app,
          importCard: {
            ...state.app.importCard,
            selectedCardId: action.payload.cardId
          }
        }
      };
    }

    case actions.RESET_IMPORT_CARD: {
      return {
        ...state,
        app: {
          ...state.app,
          importCard: {
            storySearchTerm: "",
            storyList: [],
            selectedStory: null,
            selectedCardId: ""
          }
        }
      };
    }

    case actions.RESET_IMPORT_CARD_DISPLAY: {
      return {
        ...state,
        app: {
          ...state.app,
          importCard: {
            ...state.app.importCard,
            selectedStory: null,
            selectedCardId: ""
          }
        }
      };
    }

    case actions.UPDATE_SELECTED_PLACE: {
      return {
        ...state,
        app: {
          ...state.app,
          location: {
            selectedPlace: action.payload.place
          }
        }
      };
    }

    case actions.SET_PROJECT: {
      return {
        ...state,
        app: {
          ...state.app,
          asanaProject: action.payload
        }
      };
    }

    case actions.UPDATE_EDITOR_NOTES: {
      const changes = {
        "private-fields": { ...state.story["private-fields"], "editor-notes": action.payload.editorNotes }
      };

      return updateStory(state, changes, {});
    }

    case actions.VIDEO_OEMBED_SEARCH_TERM_CHANGE: {
      return {
        ...state,
        videoOEmbedSelector: {
          ...state.videoOEmbedSelector,
          app: {
            ...state.videoOEmbedSelector.app,
            term: action.payload.term || ""
          }
        }
      };
    }

    case actions.VIDEO_OEMBED_SEARCH_SUCCESS: {
      const incrementedOffset = state.videoOEmbedSelector.ui.pages.offset + action.payload.videos.length;
      return {
        ...state,
        videoOEmbedSelector: {
          ...state.videoOEmbedSelector,
          app: {
            ...state.videoOEmbedSelector.app,
            videos: action.payload.videos
          },
          ui: {
            ...state.videoOEmbedSelector.ui,
            pages: {
              ...state.videoOEmbedSelector.ui.pages,
              offset: incrementedOffset,
              total: action.payload.total,
              currentPage: action.payload.page || null,
              token: {
                ...(action.payload.token && {
                  prev: action.payload.token.prev || null,
                  next: action.payload.token.next || null
                })
              }
            }
          }
        }
      };
    }

    case actions.TOGGLE_OEMBED_INSPECTOR_SEARCH_FILTER: {
      const showSearchFilters = !state.videoOEmbedSelector.ui.showSearchFilters;
      return {
        ...state,
        videoOEmbedSelector: {
          ...state.videoOEmbedSelector,
          ui: {
            ...state.videoOEmbedSelector.ui,
            showSearchFilters
          }
        }
      };
    }

    case actions.VIDEO_OEMBED_SEARCH_FILTERS_CHANGE: {
      return {
        ...state,
        videoOEmbedSelector: {
          ...state.videoOEmbedSelector,
          app: {
            ...state.videoOEmbedSelector.app,
            selectedFilters: {
              ...state.videoOEmbedSelector.app.selectedFilters,
              ...action.payload
            }
          }
        }
      };
    }

    case actions.VIDEO_OEMBED_SEARCH_RESET: {
      return {
        ...state,
        videoOEmbedSelector: {
          ...state.videoOEmbedSelector,
          app: {
            ...state.videoOEmbedSelector.app,
            term: "",
            videos: []
          }
        }
      };
    }

    default:
      return state;
  }
}
