/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose } from "redux";
import wrapPage from "containers/page/page";
import { connect } from "react-redux";
import { loadAdvancedSettings } from "./action-creators";
import { navigateFn } from "../../utils/routes.utils";

class Collections extends React.Component<any, any> {
  componentDidMount() {
    this.props.loadAdvancedSettings();
  }

  render() {
    return (
      <React.Fragment>
        <section className="page-container collections">
          <h1>Foobar</h1>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    settings: state.advancedSettings.uiSettings
  };
};

const mapDispatchToProps = (dispatch) => {
  const navigate = navigateFn(dispatch);

  return {
    loadAdvancedSettings: () => dispatch(loadAdvancedSettings()),
    navigate: (path, params) => navigate(path, params)
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  wrapPage()
)(Collections);
