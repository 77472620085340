/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import reduceReducers from "reduce-reducers";
import generalSettingsReducer from "./pages/settings/settings-reducer";
import configureReducer from "./pages/configure/configure-reducer";

import * as actions from "./actions";
import { INITIAL_STATE } from "./initial-state";
import storySlugSettingsReducer from "pages/settings/pages/configure/story-slug/story-slug-reducer";

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.SETTINGS_SET_ACTIVE_MENU: {
      return {
        ...state,
        ui: {
          ...state.ui,
          activeMenu: action.payload
        }
      };
    }
    case actions.SETTINGS_SET_SUBMENU: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isSubmenuActive: action.payload.status,
          activeSubmenu: action.payload.activeSubmenu
        }
      };
    }

    default:
      return state;
  }
};

export default reduceReducers<any>(settingsReducer, generalSettingsReducer, configureReducer, storySlugSettingsReducer);
