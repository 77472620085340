/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

 import * as React from "react";
 import { t } from "i18n";
 import FieldLabel from "components/field-label/field-label";
 import Facebook from "components/icons/facebook";
 import Google from "components/icons/google";
 import Linkedin from "components/icons/linkedin";
 import TwitterSquare from "components/icons/twitter-square";
 import MicrosoftEntra from "components/icons/microsoft-entra";
 import Button from "components/button/button";
 import styles from "./login-social-and-signin-actions.module.css";
 import WithGAEvent from "helpers/ga-analytics/with-ga-event";
 import { getSessionRedirectUri } from "./utils";
 import { isEmpty } from "lodash";
 interface Props {
   formId?: string;
   submitData: () => void;
   cancelFormSignIn: () => void;
   isSignInDisabled: boolean;
   isSocialLoginDisabled: boolean;
   isBridgekeeperIntegrationEnabled: boolean;
   enabledSocialLoginProviders: string[];
 }

 const socialLogins = {
   facebook: Facebook,
   google: Google,
   twitter: TwitterSquare,
   linkedin: Linkedin,
   "microsoft-entra": MicrosoftEntra
 };

 const socialLoginButton = (
   isBridgekeeperIntegrationEnabled: boolean,
   provider: string,
   isSocialLoginDisabled: boolean,
   enabledSocialLoginProviders: string[]
 ) => {
   const SocialLogin = socialLogins[provider];
   const redirectUri = getSessionRedirectUri();
   const postLoginRedirectUri = redirectUri
     ? `${window.location.origin}${window.location.pathname}?redirect_uri=${redirectUri}`
     : "";
   const redirectParam = redirectUri ? `&redirect-url=${postLoginRedirectUri}` : "";
   const showSocialLoginButton =
     (enabledSocialLoginProviders && enabledSocialLoginProviders.includes(provider)) || !isSocialLoginDisabled;

   if (showSocialLoginButton) {
     const href = isBridgekeeperIntegrationEnabled
       ? `/api/auth/v1/login?auth-provider=${provider}${redirectParam}`
       : `/login?auth-provider=${provider}`;

     return (
       <WithGAEvent label={`${provider}-login`}>
         <a href={href} className={styles["social-icon"]}>
           <SocialLogin width={32} height={32} />
         </a>
       </WithGAEvent>
     );
   } else {
     return null;
   }
 };

 const LoginSocialAndSignInActions: React.SFC<Props> = ({
   formId,
   submitData,
   cancelFormSignIn,
   isSignInDisabled,
   isSocialLoginDisabled,
   isBridgekeeperIntegrationEnabled,
   enabledSocialLoginProviders = []
 }) => {
   return (
     <div className={styles["social-and-signin-container"]}>
       <div className={styles["signin-container"]}>
         <WithGAEvent label="form-signin">
           <Button
             form={formId}
             testId="signin-button"
             type="primary"
             onClick={() => submitData()}
             disabled={isSignInDisabled}
             classname={"social-and-signin-container-button"}>
             {t("loginPage.sign_in")}
           </Button>
         </WithGAEvent>
         <WithGAEvent label="form-cancel-signin">
           <Button type="secondary" onClick={() => cancelFormSignIn()} classname={"social-and-signin-container-button"}>
             {t("loginPage.cancel")}
           </Button>
         </WithGAEvent>
       </div>
       <React.Fragment>
         {!isSocialLoginDisabled || !isEmpty(enabledSocialLoginProviders) ? (
           <FieldLabel label={t("loginPage.or_sign_in_with")} />
         ) : null}

         <div className={styles["social-container"]}>
           {socialLoginButton(
             isBridgekeeperIntegrationEnabled,
             "facebook",
             isSocialLoginDisabled,
             enabledSocialLoginProviders
           )}
           {socialLoginButton(
             isBridgekeeperIntegrationEnabled,
             "google",
             isSocialLoginDisabled,
             enabledSocialLoginProviders
           )}
           {socialLoginButton(
             isBridgekeeperIntegrationEnabled,
             "twitter",
             isSocialLoginDisabled,
             enabledSocialLoginProviders
           )}
           {socialLoginButton(
             isBridgekeeperIntegrationEnabled,
             "linkedin",
             isSocialLoginDisabled,
             enabledSocialLoginProviders
           )}
           {socialLoginButton(
             isBridgekeeperIntegrationEnabled,
             "microsoft-entra",
             isSocialLoginDisabled,
             enabledSocialLoginProviders
           )}
         </div>
       </React.Fragment>
     </div>
   );
 };

 export default LoginSocialAndSignInActions;
