/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export enum MimeType {
  Images = "image/*",
  Videos = "video/*",
  CSV = "text/csv",
  PDF = "application/pdf"
}

/*
  Takes a file or an array of files, and 'accept' as params and returns true if the file or files
  satisfy the type imposed by the 'accept' param and returns false if they don't satisfy.

  The 'accept' param is of the same format as that of the accept attribute of the HTML input element,
  it could either be just one value like 'image/*' or could be a comma separated string containing
  various mime-types or file extensions, eg. ".gif, .mp4, image/*, application/pdf, text/csv"

  Refer: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept
*/

export function validateFileType(
  fileOrFiles: File | File[],
  accept: MimeType | string,
  memoizedRegexes?: { extensionRegexes: RegExp[]; mimeTypeRegexes: RegExp[] }
): boolean {
  let mimeTypeRegexes: RegExp[], extensionRegexes: RegExp[];

  if (memoizedRegexes) {
    mimeTypeRegexes = memoizedRegexes.mimeTypeRegexes;
    extensionRegexes = memoizedRegexes.extensionRegexes;
  } else {
    const acceptFileTypes = accept.split(",").map((type) => type.trim());
    mimeTypeRegexes = acceptFileTypes.map((type) => new RegExp(`^${type}`));
    extensionRegexes = acceptFileTypes.map((type) => new RegExp(`${type}$`));
  }

  if (Array.isArray(fileOrFiles)) {
    return fileOrFiles.every((file) => validateFileType(file, accept, { mimeTypeRegexes, extensionRegexes }));
  }
  return (
    extensionRegexes.some((regex) => regex.test(fileOrFiles.name)) ||
    mimeTypeRegexes.some((regex) => regex.test(fileOrFiles.type))
  );
}

export function getFileNameFromS3Key(s3Key: string) {
  return s3Key
    .split("/")
    .slice(-1)
    .pop();
}
