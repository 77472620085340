/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useState } from "react";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";
import { Format } from "store/form/form";
import {
  PartialAppState,
  CurrentForm,
  FormActionState,
  publishForm,
  retractForm,
  saveForm,
  deleteForm,
  exportSubmissions
} from "store/form/editor";
import Save from "components/icons/save";
import Button from "components/button/button";
import Header from "components/header/header";
import FormActionsMenu from "pages/forms/actions-menu/actions-menu";
import FormModal, { FormModalType } from "pages/forms/modal/modal";
import styles from "./header.module.css";

interface StateProps {
  canCreateForms?: boolean;
  isFormModified: boolean;
  isFormSaving: boolean;
  isFormPublishing: boolean;
  currentForm: CurrentForm;
  isDesktopSizeViewport: boolean;
  formActionState: FormActionState;
}

interface DispatchProps {
  saveForm: () => void;
  publishForm: () => void;
  retractForm: () => void;
  deleteForm: () => void;
  exportSubmissions: (format: Format) => void;
}

type FormEditorHeaderProps = StateProps & DispatchProps;

export const FormEditorHeader: React.FC<FormEditorHeaderProps> = ({
  canCreateForms,
  isFormModified,
  isFormSaving,
  isFormPublishing,
  currentForm,
  formActionState,
  isDesktopSizeViewport,
  saveForm,
  publishForm,
  retractForm,
  deleteForm,
  exportSubmissions
}) => {
  const [modalType, setModalType] = useState<FormModalType | null>(null);
  const isFormOpen = currentForm.status === "draft";
  const isFormPublished = currentForm.status === "published";
  const isSaveButtonDisabled = isFormSaving || isFormPublishing || !isFormModified;
  const isPublishButtonDisabled = isFormSaving || isFormPublishing || isFormPublished;
  const showRetractButton = isFormPublished;
  const showDeleteButton = isFormOpen || isFormPublished;
  const publishButtonText = isFormPublished
    ? t("common.published")
    : isFormPublishing
    ? t("common.publishing")
    : t("common.publish");
  const saveButtonColor = isSaveButtonDisabled ? "var(--mono-3)" : "var(--mono-5)";
  const closeModal = () => setModalType(null);
  const onRetractButtonClick = () => setModalType(FormModalType.Retract);
  const onDeleteButtonClick = () => setModalType(FormModalType.Delete);

  return canCreateForms ? (
    <>
      <Header showAvatar={isDesktopSizeViewport} hideAddNewButton={false}>
        <ul className={styles["form-header-buttons"]}>
          <li className={styles["form-header-button"]}>
            {isFormSaving && (
              <span className={styles["form-saving-text"]} data-test-id="form-saving-text">
                {t("common.saving")}
              </span>
            )}
            <Button
              type="transparent"
              disabled={isSaveButtonDisabled}
              onClick={saveForm}
              data-test-id="form-editor-header-save-button">
              <Save color={saveButtonColor} />
            </Button>
          </li>
          <li className={styles["form-header-button"]}>
            <Button
              type="primary"
              variant="success"
              disabled={isPublishButtonDisabled}
              onClick={publishForm}
              data-test-id="form-editor-header-publish-button">
              {publishButtonText}
            </Button>
          </li>
          <li>
            <FormActionsMenu
              showRetractButton={showRetractButton}
              showDeleteButton={showDeleteButton}
              showExportButton={isFormPublished}
              exportState={formActionState.export}
              onRetractButtonClick={onRetractButtonClick}
              onDeleteButtonClick={onDeleteButtonClick}
              onExportButtonClick={exportSubmissions}
            />
          </li>
        </ul>
      </Header>
      <FormModal modalType={modalType} closeModal={closeModal} retractForm={retractForm} deleteForm={deleteForm} />
    </>
  ) : (
    <Header showAvatar={true} hideAddNewButton={false} />
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    canCreateForms: state.features.canCreateForms,
    isFormModified: state.forms.editor.isFormModified,
    isFormSaving: state.forms.editor.isFormSaving,
    isFormPublishing: state.forms.editor.isFormPublishing,
    currentForm: state.forms.editor.currentForm,
    formActionState: state.forms.editor.formActionState,
    isDesktopSizeViewport: state.viewport.isDesktopSizeViewport
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    saveForm: () => dispatch(saveForm()),
    publishForm: () => dispatch(publishForm()),
    retractForm: () => dispatch(retractForm()),
    deleteForm: () => dispatch(deleteForm()),
    exportSubmissions: (format) => dispatch(exportSubmissions(format))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormEditorHeader);
