/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { getErrorCode } from "utils/error.utils";
import { client } from "./client";
import { HTTPError } from "./errors";

export interface PaginationOptions {
  limit?: number;
  offset?: number;
  total?: number;
}

export interface WebhookDetails {
  id: number;
  url: string;
  secret: string;
  active: boolean;
  "created-at": number;
  events: number[];
}

export interface WebhookLog {
  id: number;
  "event-id": number;
  "resource-id": string;
  title: string;
  success: boolean;
  status: number;
  "created-at": number;
}

export type WebhookPayload = Omit<WebhookDetails, "created-at">;
export type NewWebhook = Omit<WebhookPayload, "id" | "active">;
interface BasicWebhook {
  id: number;
  url: string;
  active: boolean;
}

export type CreatedWebhook = BasicWebhook & { "created-at": number };
export type UpdatedWebhook = BasicWebhook & { "updated-at": number };
export type WebhookData = CreatedWebhook[];
export interface WebhookLogData {
  logs: WebhookLog[];
  pagination: PaginationOptions;
}

export function getAllWebhooks(opts: PaginationOptions): Promise<WebhookData> {
  return client
    .url("/api/v1/webhooks")
    .query(opts)
    .get()
    .json()
    .catch((err: HTTPError) => {
      return Promise.reject(getErrorCode(err));
    }) as Promise<WebhookData>;
}

export function getWebhook(webhookId: number): Promise<WebhookDetails> {
  return client
    .url(`/api/v1/webhooks/${webhookId}`)
    .get()
    .json()
    .catch((err: HTTPError) => {
      return Promise.reject(getErrorCode(err));
    }) as Promise<WebhookDetails>;
}

export function createWebhook(newWebhook: NewWebhook): Promise<CreatedWebhook> {
  return client
    .url(`/api/v1/webhooks`)
    .json(newWebhook)
    .post()
    .json()
    .catch((err: HTTPError) => {
      return Promise.reject(getErrorCode(err));
    }) as Promise<CreatedWebhook>;
}

export function updateWebhook(webhookId: number, updatedWebhook: Partial<WebhookPayload>): Promise<UpdatedWebhook> {
  return client
    .url(`/api/v1/webhooks/${webhookId}`)
    .json(updatedWebhook)
    .patch()
    .json()
    .catch((err: HTTPError) => {
      return Promise.reject(getErrorCode(err));
    }) as Promise<UpdatedWebhook>;
}

export function deleteWebhook(webhookId: number): Promise<void> {
  return client
    .url(`/api/v1/webhooks/${webhookId}`)
    .delete()
    .res()
    .catch((err: HTTPError) => {
      return Promise.reject(getErrorCode(err));
    }) as Promise<void>;
}

export function getWebhookLogs(webhookId: number, opts: PaginationOptions): Promise<WebhookLogData> {
  return client
    .url(`/api/v1/webhook-logs/${webhookId}`)
    .query(opts)
    .get()
    .json()
    .catch((err: HTTPError) => {
      return Promise.reject(getErrorCode(err));
    }) as Promise<WebhookLogData>;
}
