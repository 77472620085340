/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { useState, useEffect, RefObject } from "react";
import { debounce } from "lodash";

export function useRefDimensionsOnResize(domReference: RefObject<HTMLDivElement>, debounceTime = 500) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  function setDimensions() {
    if (domReference.current) {
      const newWidth = domReference.current.clientWidth;
      const newHeight = domReference.current.clientHeight;
      if (width !== newWidth) setWidth(newWidth);
      if (height !== newHeight) setHeight(newHeight);
    }
  }

  const debouncedSetDimensions = debounce(setDimensions, debounceTime);

  useEffect(() => {
    debouncedSetDimensions();
    window.addEventListener("resize", debouncedSetDimensions);
    return () => {
      window.removeEventListener("resize", debouncedSetDimensions);
    };
  });

  return { width, height };
}
