/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { StoryId, Timestamp, CardId } from "./primitive-types";

import { StoryStatus } from "./story";

export enum ActionNames {
  StorySubmit = "story-submit",
  StoryReject = "story-reject",
  StoryProofReadingDone = "story-proof-reading-done",
  StoryApprove = "story-approve",
  StoryQA = "story-qa",
  StoryPublish = "story-publish",
  StoryPublishWithoutWorkflow = "story-publish-without-workflow",
  StorySchedule = "story-schedule",
  StoryCorrectionPublish = "story-correction-publish",
  StoryScheduleModify = "story-schedule-modify",
  StoryScheduleCancel = "story-schedule-cancel",
  StoryRetract = "story-retract",
  StoryClose = "story-close",
  StorySave = "story-save",
  VersionCreated = "version-created",
  DelayedPublish = "delayed-publish",
  StoryAutoPublish = "story-autopublish",
  VersionRestored = "version-restored",
  StoryScheduleWithCollection = "story-schedule-with-collection",
  StoryEdited = "story-edited"
}

export interface Page {
  total: number;
  limit: number;
  offset: number;
}

export interface TimelineEvent {
  id: number;
  "member-id": number;
  changed: Timestamp;
  "to-status": StoryStatus;
  "from-status": StoryStatus;
  "content-id": CardId;
  "content-version-id": CardId;
  "member-name": string;
  "action-name": ActionNames;
}
export interface Timeline {
  events: Array<TimelineEvent>;
  page: Page;
}

interface TimelineParams {
  limit: number;
  offset: number;
}

export const fetchTimelineEvents = (storyId: StoryId, { limit, offset }: TimelineParams): Promise<Timeline> => {
  return client
    .url(`/api/story/${storyId}/activity-log`)
    .query({ limit, offset })
    .get()
    .json();
};
