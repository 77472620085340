/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import produce from "immer";
import { State, INITIAL_SECTIONS_STATE, NEW_SECTION, SectionPageInspectorType } from "./state";
import actions, { ActionTypes } from "./actions";

function reducer(state: State = INITIAL_SECTIONS_STATE, action: ActionTypes) {
  return produce(state, (draft) => {
    switch (action.type) {
      case actions.LIST_SECTIONS:
        draft.ui.main = { loading: true, error: null };
        break;

      case actions.LIST_SECTIONS_SUCCESS:
        draft.ui.main = { loading: false, error: null };
        draft.sections = action.payload.sections;
        break;

      case actions.LIST_SECTIONS_FAILURE:
        draft.ui.main = { loading: false, error: action.payload.error };
        break;

      case actions.REPLACE_CURRENT_SECTION:
        draft.app.currentSection = action.payload.section;
        break;

      case actions.INIT_NEW_SECTION:
        draft.app.currentSection = NEW_SECTION;
        draft.ui.inspector = SectionPageInspectorType.Create;
        break;

      case actions.EDIT_SECTION:
        draft.app.currentSection = action.payload.section;
        draft.ui.inspector = SectionPageInspectorType.Edit;
        break;

      case actions.EDIT_SECTION_FAILURE:
        draft.ui.main = { loading: false, error: action.payload.error };
        break;

      case actions.CANCEL_CREATE_OR_EDIT_SECTION:
        draft.ui.save = { loading: false, error: null };
        draft.app.currentSection = null;
        draft.ui.inspector = null;
        break;

      case actions.SAVE_SECTION:
        draft.ui.save = { loading: true, error: null };
        break;

      case actions.SAVE_SECTION_SUCCESS:
        draft.ui.save = { loading: false, error: null };
        draft.app.currentSection = null;
        draft.ui.inspector = null;
        break;

      case actions.SAVE_SECTION_FAILURE:
        draft.ui.save = { loading: false, error: action.payload.error };
        break;

      case actions.DELETE_SECTION:
        draft.ui.sections[action.payload.section.id] = { loading: true, error: null };
        break;

      case actions.DELETE_SECTION_SUCCESS:
        draft.ui.showConfirmationModel = false;
        draft.app.sectionToBeDeleted = null;
        draft.ui.sections[action.payload.section.id] = { loading: false, error: null };
        break;

      case actions.DELETE_SECTION_FAILURE:
        draft.ui.sections[action.payload.section.id] = { loading: false, error: action.payload.error };
        break;

      case actions.UNDELETE_SECTION:
        draft.ui.sections[action.payload.section.id] = { loading: true, error: null };
        break;

      case actions.UNDELETE_SECTION_SUCCESS:
        draft.ui.sections[action.payload.section.id] = { loading: false, error: null };
        break;

      case actions.UNDELETE_SECTION_FAILURE:
        draft.ui.sections[action.payload.section.id] = { loading: false, error: action.payload.error };
        break;

      case actions.SHOW_CONFIRMATION_MODEL:
        draft.ui.showConfirmationModel = true;
        break;

      case actions.CLOSE_CONFIRMATION_MODEL_AND_RESET_DATA:
        draft.ui.showConfirmationModel = false;
        draft.app.sectionToBeDeleted = null;
        break;

      case actions.SET_SECTION_TO_BE_DELETED:
        draft.app.sectionToBeDeleted = action.payload.section;
        break;

      case actions.SET_GENERATED_SLUG:
        draft.app.currentSection = action.payload.section;
        break;

      case actions.SET_CURRENT_TAB:
        draft.ui.currentTab = action.payload.currentTab;
        break;

      default:
        break;
    }
  });
}

export default reducer;
