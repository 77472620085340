/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import styles from "./category.module.css";

interface Props {
  title: string;
}

const CategoryTitle: React.SFC<Props> = ({ title }) => (
  <h3 className={styles["category-title"]}>
    <span>{title}</span>
  </h3>
);

export default CategoryTitle;
