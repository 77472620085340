/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { connect } from "react-redux";
import { compose } from "redux";
import { format } from "date-fns";
import { ContextMenu, ContextMenuList, ContextMenuItem } from "components/context-menu/context-menu";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import Loader from "../loader";
import Clock from "components/icons/clock";
import Pages from "components/icons/pages";
import File from "components/icons/file";
import styles from "./mytasks.module.css";
import Chip from "components/chip/chip";
import { PartialAppState } from "../state";
import { LoaderState } from "behaviors/loader/state";
import { Asana } from "api/route-data/route-data";
import { Task, MyTasks } from "api/tasks";
import { TaskId, StoryId } from "api/primitive-types";
import TaskManagerLogin from "../taskmanagerlogin/taskmanagerlogin";
import { handleAssociatedStory } from "../tasks";
import NoContentCard from "components/no-content-card/no-content-card";
import { loadAllTasks } from "../async-action-creators";
import ContentHeader from "pages/settings/components/content-header/content-header";

interface Props {
  myTaskLoader: LoaderState;
  isLoggedIn: boolean;
  asana: Asana;
}

interface DispatchProp {
  openStory: (id: StoryId) => void;
  createNewStory: (id: TaskId) => void;
  closeTaskAndRefreshTaskList: (id: TaskId) => void;
  openTask?: (id: TaskId) => void;
  loadAllTasks: () => void;
}

interface StateProps {
  allTasks: MyTasks | null;
  myTaskLoader: LoaderState;
}

type props = Props & StateProps & DispatchProp;

interface taskProps {
  task: Task;
  groupType: string;
}

type TaskProps = taskProps & DispatchProp;

export const TaskMenu: React.SFC<TaskProps> = ({ task, closeTaskAndRefreshTaskList }) => (
  <div id={`task-${task.id}`} className={styles["task-menu"]}>
    <ContextMenuList>
      <ContextMenuItem
        label={t("settings.tasks.taskAction.close")}
        onClick={() => {
          closeTaskAndRefreshTaskList(task.id);
        }}
      />
    </ContextMenuList>
  </div>
);

export const getTaskDueOn = (task: Task, groupType: string) => {
  const style = getComputedStyle(document.body);
  if (task && task["earliest-due-on"])
    if (groupType === "overdue") {
      return (
        <div className={styles["task-time-wrapper-overdue"]}>
          <Clock color={style.getPropertyValue("--danger")} />
          <time className={styles["task-time"]}>{format(new Date(task["earliest-due-on"]), "dd MMM yyyy")}</time>
        </div>
      );
    } else if (groupType === "later") {
      return (
        <div className={styles["task-time-wrapper"]}>
          <Clock />
          <time className={styles["task-time"]}>{format(new Date(task["earliest-due-on"]), "dd MMM yyyy")}</time>
        </div>
      );
    }
  return <div />;
};

export const TaskCard: React.SFC<TaskProps> = ({
  task,
  openStory,
  createNewStory,
  closeTaskAndRefreshTaskList,
  openTask,
  groupType
}) => (
  <div data-test-id="mytasks-tasks">
    <button
      className={styles["task-link"]}
      onClick={() => {
        openTask && openTask(task.id);
      }}>
      <div className={styles["task-card"]} id={`task-${task.id}`} data-test-id={"task-card"}>
        <div className={styles["task-card-content"]} data-test-id="mytasks-task-card-content">
          <div className={styles["task-chips"]} data-test-id="task-chip">
            <Chip value={`${task["total-subtask-count"]} SUB TASKS`} />
          </div>
          <h3 style={{ cursor: "pointer" }} className={styles["task-headline"]} data-test-id="task-name">
            {task.name}
          </h3>
          <div className={styles["task-byline"]} data-test-id="task-byline">
            <div className={styles["task-metadata"]} data-test-id="task-metadata">
              <Pages width="16" height="16" />
              <div className={styles["task-count-details"]} data-test-id="task-count-details">
                {`${task["my-subtask-count"]} of ${task["total-subtask-count"]} tasks assigned to you`}
              </div>
            </div>
            {getTaskDueOn(task, groupType)}
          </div>
        </div>
        <div className={styles["task-card-actions"]} data-test-id="mytasks-task-card-actions">
          <div className={styles["task-action-button"]}>{handleAssociatedStory(task, openStory, createNewStory)}</div>
          {!task.completed && (
            <div id={`task-${task.id}`} data-test-id="story-more-actions" className={styles["story-more-actions"]}>
              <ContextMenu>
                <TaskMenu
                  task={task}
                  openStory={openStory}
                  createNewStory={createNewStory}
                  closeTaskAndRefreshTaskList={closeTaskAndRefreshTaskList}
                  groupType={groupType}
                  loadAllTasks={loadAllTasks}
                />
              </ContextMenu>
            </div>
          )}
        </div>
      </div>
    </button>
  </div>
);

type GroupTaskProps = DispatchProp & { groupTasks: Task[]; groupType: string };

export const GroupTasks: React.SFC<GroupTaskProps> = ({
  groupType,
  groupTasks,
  openStory,
  createNewStory,
  closeTaskAndRefreshTaskList,
  openTask
}) => (
  <div className="group-container" data-test-id="group-container">
    <h4 className="group-header" data-test-id="group-header">
      {t(`tasks.my_tasks.${groupType}`)}
    </h4>
    {groupTasks.map((task) => (
      <TaskCard
        task={task}
        openStory={openStory}
        createNewStory={createNewStory}
        closeTaskAndRefreshTaskList={closeTaskAndRefreshTaskList}
        openTask={openTask}
        groupType={groupType}
        loadAllTasks={loadAllTasks}
      />
    ))}
  </div>
);

export class MyTaskList extends React.Component<props> {
  render() {
    const {
      isLoggedIn,
      allTasks,
      asana,
      myTaskLoader,
      openStory,
      createNewStory,
      closeTaskAndRefreshTaskList,
      openTask,
      loadAllTasks
    } = this.props;
    return (
      <div className="mytasks">
        <LoaderWrapper className="mytasks-progress-main-area" component={Loader} loader={myTaskLoader}>
          {isLoggedIn ? (
            <React.Fragment>
              <ContentHeader
                title={t("tasks.my_tasks.title")}
                actionButtonLabel={"Refresh"}
                handleActionButtonClick={loadAllTasks}
              />
              <div className="task-tab-panel" data-test-id="task-tab-panel">
                {allTasks && !allTasks.message && Object.keys(allTasks).length !== 0 ? (
                  ["overdue", "today", "tomorrow", "later"].map(
                    (groupType) =>
                      allTasks[groupType] && (
                        <GroupTasks
                          groupType={groupType}
                          groupTasks={allTasks[groupType]}
                          openStory={openStory}
                          createNewStory={createNewStory}
                          closeTaskAndRefreshTaskList={closeTaskAndRefreshTaskList}
                          openTask={openTask}
                          loadAllTasks={loadAllTasks}
                        />
                      )
                  )
                ) : (
                  <NoContentCard>
                    <File />
                    <div className="no-content-message">{t("tasks.my_tasks.no_tasks")}</div>
                  </NoContentCard>
                )}
              </div>
            </React.Fragment>
          ) : (
            <TaskManagerLogin asana={asana} />
          )}
        </LoaderWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    allTasks: state.tasks.app.allTasks,
    myTaskLoader: state.tasks.ui.myTaskLoader
  };
};

export default compose(connect(mapStateToProps))(MyTaskList);
