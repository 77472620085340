/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import TextField from "components/text-field/text-field";
import { ButtonWithLoader } from "components/button/button";
import { Integrations, GoogleIndexingConfig, IntegrationConfig } from "api/integrations";
import TextArea from "components/text-area/text-area";
import { getErrorMessage } from "../validate";
import { Callout } from "@quintype/em/components/callout";
import { useState } from "react";
import styles from "./ga3-realtime.module.css";

interface Props {
  integration: { key: Integrations.GoogleIndexing; value: GoogleIndexingConfig; isValid: boolean };
  updateIntegrationField: (key: string, value: Object) => void;
  readOnly?: boolean;
  errors: Error | null;
  validateIntegration?: (integration: IntegrationConfig) => void;
  isValidateInProgress?: boolean;
}

const GoogleIndexing: React.FunctionComponent<Props> = ({
  integration,
  updateIntegrationField,
  validateIntegration,
  readOnly,
  errors,
  isValidateInProgress
}) => {
  const integrationName = integration.key;
  const errorDetail = getErrorMessage(integration.key, "message", errors);
  const [isValidateClicked, setIsValidateClicked] = useState<Boolean>(false);
  return (
    <React.Fragment>
      <TextField
        label={t("integrations.others.google-indexing.client-email")}
        value={integration.value["client-email"]}
        onChange={(value) => updateIntegrationField(integrationName, { "client-email": value })}
        readOnly={readOnly}
        errorMessage={getErrorMessage(integration.key, "client-email", errors)}
      />
      <TextArea
        label={t("integrations.others.google-indexing.private-key")}
        value={integration.value["private-key"]}
        onChange={(value) => !readOnly && updateIntegrationField(integrationName, { "private-key": value })}
        maxRows={10}
        errorMessage={getErrorMessage(integration.key, "private-key", errors)}
      />
      {validateIntegration && (
        <ButtonWithLoader
          loading={isValidateInProgress}
          onClick={() => {
            setIsValidateClicked(true);
            validateIntegration(integration);
          }}>
          {t("integrations.validate-credentials")}
        </ButtonWithLoader>
      )}
      <div className={styles["validation-message"]}>
        {isValidateClicked && showCallout(integration.isValid, errorDetail)}
      </div>
    </React.Fragment>
  );
};

function showCallout(isIntegrationValid: boolean, errorMessage?: string) {
  if (isIntegrationValid) {
    return (
      <Callout
        title={t("integrations.validation-success-title")}
        content={t("integrations.others.google-indexing.usage-info")}
        variant="success"
      />
    );
  }
  if (errorMessage) {
    return <Callout title={t("integrations.validation-error-title")} content={errorMessage} variant="danger" />;
  }
  return;
}
export default GoogleIndexing;
