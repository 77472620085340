/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const SETTINGS_PATH = "/settings";
// General Settings Page
export const SETTINGS_GENERAL_PATH = "/settings/general";

// Configure Page
export const CONFIGURE_FEATURES_PATH = "/settings/configure/features";

export const CONFIGURE_STORY_ELEMENTS_PATH = "/settings/configure/story-elements";

export const CONFIGURE_STORY_TEMPLATES_PATH = "/settings/configure/story-templates";
export const CONFIGURE_ADD_STORY_TEMPLATE_PATH = "/settings/configure/story-templates/new";
export const CONFIGURE_EDIT_STORY_TEMPLATE_PATH = "/settings/configure/story-templates/:id";

export const CONFIGURE_ACCESS_LEVELS_PATH = "/settings/configure/access-levels";
export const CONFIGURE_ADD_ACCESS_LEVEL_PATH = "/settings/configure/access-levels/new";

export const CONFIGURE_PROMOTIONAL_MESSAGES_PATH = "/settings/configure/promotional-messages";

export const CONFIGURE_CONTRIBUTOR_ROLES_PATH = "/settings/configure/contributor-roles";
export const NEW_CONTRIBUTOR_ROLES_PATH = `${CONFIGURE_CONTRIBUTOR_ROLES_PATH}/new`;
export const EDIT_CONTRIBUTOR_ROLES_PATH = `${CONFIGURE_CONTRIBUTOR_ROLES_PATH}/:id`;

export const CONFIGURE_AMP_PATH = "/settings/configure/amp";

export const CONFIGURE_BREAKING_NEWS_PATH = "/settings/configure/breaking-news";
export const BREAKING_NEWS_DEFAULTS_IMAGE_GALLERY = "/settings/configure/breaking-news/hero-image/gallery";

export const CONFIGURE_COLLECTIONS_PATH = "/settings/configure/collection";

export const CONFIGURE_WATERMARK_IMAGES_PATH = "/settings/configure/watermark-images";
export const CONFIGURE_WATERMARK_IMAGES_GALLERY_PATH = "/settings/configure/watermark-images/gallery";

export const CONFIGURE_PUSH_NOTIFICATION_CHANNELS_PATH = "/settings/configure/push-notification-channels";
export const NEW_PUSH_NOTIFICATION_CHANNELS_PATH = `${CONFIGURE_PUSH_NOTIFICATION_CHANNELS_PATH}/new`;

export const CONFIGURE_FORMS_PATH = "/settings/configure/forms";

export const CONFIGURE_STORY_SLUG_PATH = "/settings/configure/story-slug";
