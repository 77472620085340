/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { Route, Switch } from "react-router";

import Webhooks from "./webhooks";
import { LogsRouter } from "./logs/router";
import { WEBHOOKS_PATH, WEBHOOKS_ADD_PATH, WEBHOOKS_EDIT_PATH, WEBHOOKS_LOG_PATH } from "./routes";

export const Router = () => (
  <>
    <Switch>
      <Route exact path={WEBHOOKS_PATH} render={(props) => <Webhooks {...props} title="webhooks" />} />
      <Route
        exact
        path={WEBHOOKS_ADD_PATH}
        render={(props) => <Webhooks {...props} showAddInspector={true} title="webhooks" />}
      />
      <Route
        exact
        path={WEBHOOKS_EDIT_PATH}
        render={(props) => <Webhooks {...props} showEditInspector={true} title="webhooks" />}
      />
      <Route path={WEBHOOKS_LOG_PATH} component={LogsRouter} />
    </Switch>
  </>
);
