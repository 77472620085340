/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import styles from "./story-card.module.css";
import Chevron from "components/icons/chevron";
import Trash from "../../../components/icons/trash";
import Settings from "components/icons/settings";
import classnames from "classnames/bind";

const cx = classnames.bind(styles);

interface DesktopCardEditorProps {
  moveCardUp: () => void;
  moveCardDown: () => void;
  deleteCard: () => void;
  shareCard: () => void;
  isCardDisabled: boolean;
  attributesClass: string;
  isSyndicatedReadOnlyCard?: boolean;
  isStoryLocked?: boolean;
}

const DesktopCardEditor: React.FunctionComponent<DesktopCardEditorProps> = ({
  moveCardUp,
  moveCardDown,
  deleteCard,
  shareCard,
  attributesClass,
  isSyndicatedReadOnlyCard
}) => {
  return (
    <React.Fragment>
      <div
        className={cx(attributesClass, { "story-editor-card-settings": isSyndicatedReadOnlyCard })}
        onClick={() => shareCard()}>
        <Settings width="20" height="20" />
      </div>
      <div className={styles["story-editor-card-action"]} onClick={() => moveCardUp()} data-test-id="up-btn">
        <Chevron variant="up" />
      </div>
      <div className={styles["story-editor-card-action"]} onClick={() => moveCardDown()} data-test-id="down-btn">
        <Chevron variant="down" />
      </div>
      <div className={styles["story-editor-card-action"]} onClick={() => deleteCard()} data-test-id="delete-btn">
        <Trash />
      </div>
    </React.Fragment>
  );
};

export default DesktopCardEditor;
