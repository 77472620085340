/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useCallback, useRef } from "react";
import { AnyAction, compose } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { startCase } from "lodash";
import { t } from "i18n";
import classnames from "classnames/bind";
import {
  PartialAppState,
  CurrentForm,
  FormValidationErrors,
  setCurrentForm,
  setIsFormModified
} from "store/form/editor";
import Chip from "components/chip/chip";
import TextLimitIndicator from "components/text-limit-indicator/text-limit-indicator";
import TextArea from "components/text-area/text-area";
import TextField from "components/text-field/text-field";
import SubTitle from "components/icons/sub-title";
import Headline from "components/icons/headline";
import FormEditorGrid from "pages/forms/editor/grid/grid";
import styles from "./attributes.module.css";
import Button from "components/button/button";

const cx = classnames.bind(styles);

/* TODO move the limits to the forms feature config if there is a requirement to customize */
export const headlineLimit = 100;
export const subheadlineLimit = 440;

interface StateProps {
  headline?: string;
  subheadline?: string | null;
  slug?: string;
  status?: string;
  errors: FormValidationErrors;
  openManageInspector?: () => void;
  publishedAt: number | undefined;
}

interface DispatchProps {
  updateForm: (form: CurrentForm) => void;
}

type FormAttributesProps = StateProps & DispatchProps;

interface HeadingProps {
  type: string;
  value?: string | null;
  isDisabled?: boolean;
  error?: string;
  onChange: (value: string) => void;
}

export const Heading: React.FC<HeadingProps> = React.memo(({ type, value, isDisabled, error, onChange }) => {
  const headingTypes = useRef({
    headline: [Headline, "jumbo", t("forms.editor.attributes.headline.label"), headlineLimit],
    subheadline: [SubTitle, "extra-large", t("forms.editor.attributes.subheadline.label"), subheadlineLimit]
  });
  const [Icon, size, placeholder, limit] = headingTypes.current[type];

  return (
    <>
      <div className={cx("form-attributes-heading-label", type, { "has-error": error })}>
        <div className={cx("form-attributes-heading-icon", { "has-error": error })}>
          <Icon color="currentColor" />
        </div>
        <TextLimitIndicator text={value || ""} limit={limit} />
      </div>
      <div className={cx("form-attributes-heading-text-area", { disabled: isDisabled })}>
        <TextArea
          onChange={onChange}
          placeholder={placeholder}
          value={value || ""}
          errorMessage={error}
          variant="plain"
          size={size}
        />
      </div>
    </>
  );
});

export const FormAttributes: React.FC<FormAttributesProps> = ({
  headline,
  subheadline,
  slug = "",
  status,
  errors,
  updateForm,
  openManageInspector,
  publishedAt
}) => {
  const isPublishedForm = status === "published";
  const isSlugUpdateDisabled = isPublishedForm || !!publishedAt;
  const updateHeadline = useCallback((headline) => updateForm({ headline }), [updateForm]);
  const updateSubheadline = useCallback((subheadline) => updateForm({ subheadline }), [updateForm]);
  const updateSlug = useCallback((slug) => updateForm({ slug }), [updateForm]);

  return (
    <FormEditorGrid>
      <div className={styles["form-attributes-container"]}>
        <div className={styles["form-attributes-settings-container"]}>
          <Chip value={startCase(t("forms.common.form"))} classname={styles["form-attributes-chip"]} invert />
          <Button
            data-test-id="form-editor-manage-btn"
            type="secondary"
            onClick={() => openManageInspector && openManageInspector()}>
            {t("story-editor.manage-button")}
          </Button>
        </div>
        <div className={styles["form-attributes-heading-container"]}>
          <Heading
            type={"headline"}
            value={headline}
            isDisabled={isPublishedForm}
            error={errors.headline}
            onChange={updateHeadline}
          />
          <Heading
            type={"subheadline"}
            value={subheadline}
            isDisabled={isPublishedForm}
            error={errors.subheadline}
            onChange={updateSubheadline}
          />
        </div>
        <div className={styles["form-attributes-slug-container"]}>
          <TextField
            label={t("forms.editor.attributes.slug.label")}
            value={slug}
            disabled={isSlugUpdateDisabled}
            onChange={updateSlug}
            errorMessage={errors.slug}
            hint={t("forms.editor.attributes.slug.hint")}
          />
        </div>
      </div>
    </FormEditorGrid>
  );
};

const mapStateToProps = ({ forms: { editor } }: PartialAppState): StateProps => {
  return {
    headline: editor.currentForm.headline,
    subheadline: editor.currentForm.subheadline,
    slug: editor.currentForm.slug,
    status: editor.currentForm.status,
    errors: editor.errors,
    publishedAt: editor.currentForm.publishedAt
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    updateForm: (form) => {
      dispatch(setCurrentForm(form));
      dispatch(setIsFormModified(true));
    }
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(FormAttributes);
