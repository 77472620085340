/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import FieldLabel from "components/field-label/field-label";
import styles from "./location-search-box.module.css";

interface LocationSearchBoxProps {
  label: string;
  placeholder?: string;
  ref?: React.RefObject<HTMLInputElement> | null | ((instance: HTMLInputElement | null) => void);
  disabled?: boolean;
}

const LocationSearchBox: React.SFC<LocationSearchBoxProps> = React.forwardRef(
  ({ label, placeholder }, ref: React.RefObject<HTMLInputElement>) => {
    return (
      <div className={styles["search-field"]}>
        <FieldLabel htmlFor={`search-field-${label}`} label={label} />
        <input
          className={styles["search-field-input"]}
          data-test-id="search-field-input"
          type="text"
          placeholder={placeholder}
          ref={ref}
          onClick={() => {
            ref && ref.current && ref.current.focus();
          }}
          id={`search-field-${label}`}
        />
      </div>
    );
  }
);

export default LocationSearchBox;
