/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState } from "./state";
import wrapPage from "containers/page/page";
import { LoaderState } from "behaviors/loader/state";
import { loadSections, addNewSection, editSection } from "./async-action-creators";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import SectionsTree from "./components/sections-tree/sections-tree";
import Inspector from "./components/inspector/inspector";
import { RouteComponentProps } from "react-router";
import { SECTIONS_NEW_PATH, SECTION_EDIT_PATH, SECTION_EDIT_WATERMARK_IMAGE } from "./routes";
import { SectionId } from "api/primitive-types";
import Loader from "./loader";
import classnames from "classnames/bind";
import styles from "./sections.module.css";

const cx = classnames.bind(styles);
interface StateProps {
  loader: LoaderState;
  title: string;
}

interface DispatchProps {
  loadSections: () => void;
  addNewSection: () => void;
  editSection: (sectionId: string | SectionId) => void;
}

type Props = RouteComponentProps<any> & StateProps & DispatchProps;

export const Sections: React.SFC<Props> = ({ loader }) => (
  <React.Fragment>
    <section className={cx("sections-page-container", "sections")} data-test-id="sections-page-container">
      <LoaderWrapper className={"sections-progress-main-area"} component={Loader} loader={loader}>
        <SectionsTree />
        <Inspector />
      </LoaderWrapper>
    </section>
  </React.Fragment>
);

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    loader: state.sections.ui.main,
    title: "sections"
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    loadSections: () => dispatch(loadSections()),
    addNewSection: () => dispatch(addNewSection()),
    editSection: (sectionId: string | SectionId) => dispatch(editSection(sectionId))
  };
};

export class SectionWithRoutes extends React.Component<Props> {
  async componentDidMount() {
    await this.props.loadSections();
    this.onEnter();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.match.url !== prevProps.match.url) {
      this.onEnter();
    }
  }

  onEnter() {
    switch (this.props.match.path) {
      case SECTIONS_NEW_PATH:
        this.props.addNewSection();
        break;

      case SECTION_EDIT_PATH:
      case SECTION_EDIT_WATERMARK_IMAGE:
        this.props.editSection(this.props.match.params.id);
        break;

      default:
        break;
    }
  }

  render() {
    return <Sections {...this.props} />;
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), wrapPage())(SectionWithRoutes);
