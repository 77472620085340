/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { Fragment } from "react";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction, compose } from "redux";
import { connect } from "react-redux";
import { t } from "i18n";

import Inspector from "components/inspector/inspector";
import TextField from "components/text-field/text-field";
import Select from "components/select/select";
import Breadcrumbs from "components/breadcrumbs/breadcrumbs";
import FieldLabel from "components/field-label/field-label";
import RadioButton from "components/radio-button/radio-button";

import { PartialAppState, MenuPageInspectorType, MenuPageItemInspectorType } from "../../state";
import { Section } from "api/route-data/route-data";
import {
  updateMenu,
  cancelSaveMenu,
  saveMenu,
  saveMenuItem,
  cancelSaveMenuItem,
  replaceCurrentMenuItem,
  setGeneratedSlug
} from "../../async-action-creators";
import { Tag } from "api/tag";
import { isExistingMenuItem } from "../../utils";
import { UnsavedMenuGroup, MenuGroup } from "api/menu-group";
import { AnyMenuItem, MenuItem, MenuItemType } from "api/menu-item";
import { isClientValidationError } from "utils/validation.utils";
import ColorField from "components/color-field/color-field";
import { SingleTagOrEntity } from "pages/story-editor/components/manage-fields/tags";
import styles from "./inspector.module.css";
import { Entity } from "api/entity";

interface StateProps {
  type: MenuPageItemInspectorType | MenuPageInspectorType | null;
  menu: UnsavedMenuGroup | null;
  saveError: Error | null;
  menuItem: AnyMenuItem | null;
  allSections: Section[];
  allMenuItems: MenuItem[];
  allMenuGroups: MenuGroup[];
  isSubmenuEnabled: boolean;
  isSaving: boolean;
}

interface DispatchProps {
  saveMenu: (menuItem: UnsavedMenuGroup) => void;
  cancelSaveMenu: () => void;
  updateMenu: (data: UnsavedMenuGroup) => void;
  saveMenuItem: (menuItem: AnyMenuItem) => void;
  cancelSaveMenuItem: () => void;
  onChange: (menuItem: AnyMenuItem) => void;
  setSlug: (section: UnsavedMenuGroup) => void;
}

type Props = StateProps & DispatchProps;

type NewMenuFieldsProps = Pick<Props, "setSlug" | "saveError" | "updateMenu"> & {
  menu: UnsavedMenuGroup;
};

const NewMenuFields: React.SFC<NewMenuFieldsProps> = ({ menu, setSlug, saveError, updateMenu }) => (
  <div className={styles["create-new-menu"]}>
    <TextField
      label={t("menu.inspector.name-label")}
      onChange={(value) =>
        updateMenu({
          ...menu,
          name: value
        })
      }
      onBlur={() => setSlug(menu)}
      value={menu.name}
      errorMessage={
        isClientValidationError<UnsavedMenuGroup>(saveError)
          ? saveError.errors.name && saveError.errors.name[0]
          : undefined
      }
    />
    <TextField
      label={t("menu.inspector.slug-label")}
      onChange={(value) =>
        updateMenu({
          ...menu,
          slug: value
        })
      }
      value={menu.slug}
      errorMessage={
        isClientValidationError<UnsavedMenuGroup>(saveError)
          ? saveError.errors.slug && saveError.errors.slug[0]
          : undefined
      }
    />
  </div>
);

type MenuItemFieldsProps = Pick<Props, "saveError" | "onChange"> & {
  menuItem: AnyMenuItem;
};

const MenuItemFields: React.SFC<MenuItemFieldsProps> = ({ menuItem, onChange, saveError }) => (
  <Fragment>
    <TextField
      value={menuItem.title}
      label={t("menu.inspector.title-label")}
      onChange={(title) =>
        onChange({
          ...menuItem,
          title
        })
      }
      hint={t("menu.inspector.title-hint")}
      errorMessage={
        isClientValidationError<AnyMenuItem>(saveError)
          ? saveError.errors.title && saveError.errors.title[0]
          : undefined
      }
    />
    <ColorField
      value={menuItem.data.color}
      label={t("menu.inspector.color-label")}
      hint={t("menu.inspector.color-hint")}
      onChange={(color) => onChange({ ...menuItem, data: { ...menuItem.data, color } })}
      errorMessage={
        isClientValidationError<AnyMenuItem>(saveError)
          ? saveError.errors.data && saveError.errors.data.color && saveError.errors.data.color[0]
          : undefined
      }
    />
    <TextField
      value={menuItem.data["icon-code"] || ""}
      label={t("menu.inspector.icon-code-label")}
      onChange={(iconCode) => onChange({ ...menuItem, data: { ...menuItem.data, "icon-code": iconCode } })}
      hint={t("menu.inspector.icon-code-hint")}
    />
  </Fragment>
);

type ParentSelectorProps = Pick<Props, "allMenuItems" | "onChange"> & {
  menuItem: AnyMenuItem;
};

const ParentSelector: React.SFC<ParentSelectorProps> = ({ allMenuItems, menuItem, onChange }) => (
  <Select
    label={t("menu.inspector.parent-id-label")}
    value={menuItem["parent-id"] ? allMenuItems.find((_menuItem) => _menuItem.id === menuItem["parent-id"]) : null}
    options={allMenuItems.filter(
      (item) => item.id !== menuItem["id"] && !item["ancestor-ids"].includes(menuItem["id"])
    )}
    onChange={(_menuItem) =>
      !Array.isArray(_menuItem) &&
      onChange({ ...menuItem, "parent-id": _menuItem && isExistingMenuItem(_menuItem) ? _menuItem.id : null })
    }
    getOptionLabel={(menuItem: MenuItem) => menuItem.title}
    getOptionValue={(menuItem: MenuItem) => menuItem.id.toString()}
    formatOptionLabel={(menuItem: MenuItem) => (
      <React.Fragment>
        {menuItem.title}
        <Breadcrumbs id={menuItem.id} crumbs={allMenuItems} getCrumbLabel={(menuItem: MenuItem) => menuItem.title} />
      </React.Fragment>
    )}
  />
);

type ItemTypeSelectorProps = Pick<Props, "onChange"> & { menuItem: AnyMenuItem };

const ItemTypeSelector: React.SFC<ItemTypeSelectorProps> = ({ menuItem, onChange }) => (
  <div className={styles["item-type-container"]}>
    <FieldLabel label={t("menu.inspector.item-type-label")} />
    {Object.values(MenuItemType).map((value) => (
      <RadioButton
        id={`menu-inspector-item-type-${value}`}
        name="menu-inspector-item-type"
        key={`menu-inspector-item-type-${value}`}
        label={t(`menu.inspector.item-type-${value}-label`)}
        checked={menuItem["item-type"] === value}
        onChange={() => onChange({ ...menuItem, "item-type": value, "item-id": null })}
        classname={"menu-inspector-radio-button"}
      />
    ))}
  </div>
);

type SectionSelectorProps = Pick<Props, "allSections" | "saveError" | "onChange"> & {
  menuItem: AnyMenuItem;
};

const SectionSelector: React.SFC<SectionSelectorProps> = ({ menuItem, allSections, saveError, onChange }) => (
  <Select
    label={t("menu.inspector.section-id-label")}
    value={menuItem["item-id"] ? allSections.find((section) => section.id === menuItem["item-id"]) : null}
    options={allSections}
    onChange={(section) => !Array.isArray(section) && onChange({ ...menuItem, "item-id": section ? section.id : null })}
    getOptionLabel={(section: Section) => section.name}
    getOptionValue={(section: Section) => section.id.toString()}
    formatOptionLabel={(section: Section) => (
      <React.Fragment>
        {section.name}
        <Breadcrumbs id={section.id} crumbs={allSections} getCrumbLabel={(section: Section) => section.name} />
      </React.Fragment>
    )}
    errorMessage={
      isClientValidationError<AnyMenuItem>(saveError)
        ? saveError.errors["item-id"] && saveError.errors["item-id"][0]
        : undefined
    }
  />
);

type LinkSelectorProps = Pick<Props, "saveError" | "onChange"> & {
  menuItem: AnyMenuItem;
};

const LinkSelector: React.SFC<LinkSelectorProps> = ({ menuItem, onChange, saveError }) => (
  <TextField
    value={menuItem.data.link || ""}
    label={t("menu.inspector.link-label")}
    onChange={(link) => onChange({ ...menuItem, data: { ...menuItem.data, link } })}
    errorMessage={
      isClientValidationError<AnyMenuItem>(saveError)
        ? saveError.errors.data && saveError.errors.data.link && saveError.errors.data.link[0]
        : undefined
    }
  />
);

type TagSelectorProps = Pick<Props, "saveError" | "onChange"> & {
  menuItem: AnyMenuItem;
};

const TagSelector: React.SFC<TagSelectorProps> = ({ menuItem, onChange, saveError }) => (
  <SingleTagOrEntity
    value={menuItem.tag}
    onTagChange={(tag: Tag | Entity) => {
      if (tag) {
        return onChange({ ...menuItem, "item-id": tag.id, tag });
      }
      return onChange({
        ...menuItem,
        "item-id": null,
        tag: null
      });
    }}
    label={t("menu.inspector.tag-id-label")}
    errorMessage={
      isClientValidationError<AnyMenuItem>(saveError)
        ? saveError.errors["item-id"] && saveError.errors["item-id"][0]
        : undefined
    }
  />
);

const MenuInspector: React.SFC<Props> = ({
  menu,
  menuItem,
  saveMenu,
  cancelSaveMenu,
  type,
  saveError,
  saveMenuItem,
  cancelSaveMenuItem,
  onChange,
  allMenuItems,
  allSections,
  isSubmenuEnabled,
  setSlug,
  isSaving,
  updateMenu
}) => {
  const title =
      type === MenuPageInspectorType.Create
        ? t("menu.inspector.create-menu-group-title")
        : type === MenuPageItemInspectorType.Create
        ? t("menu.inspector.add-menu-item-title")
        : t("menu.inspector.update-menu-item-title"),
    actionButtonLabel =
      type === MenuPageInspectorType.Create
        ? t("menu.cta.create")
        : type === MenuPageItemInspectorType.Create
        ? t("menu.cta.add-item")
        : t("menu.cta.update-item");

  return (
    <Inspector
      title={title}
      onClose={() => (type === MenuPageInspectorType.Create ? cancelSaveMenu() : cancelSaveMenuItem())}
      isActive={!!type}
      actionButtonLabel={actionButtonLabel}
      onActionButtonClick={() => (type === MenuPageInspectorType.Create ? saveMenu(menu!) : saveMenuItem(menuItem!))}
      isActionButtonDisabled={isSaving}>
      {menu && <NewMenuFields menu={menu} setSlug={setSlug} saveError={saveError} updateMenu={updateMenu} />}
      {menuItem && (
        <div className={styles["menu-item-container"]}>
          <MenuItemFields menuItem={menuItem} saveError={saveError} onChange={onChange} />
          {isSubmenuEnabled && <ParentSelector allMenuItems={allMenuItems} menuItem={menuItem} onChange={onChange} />}
          <ItemTypeSelector menuItem={menuItem} onChange={onChange} />
          {menuItem["item-type"] === MenuItemType.Section && (
            <SectionSelector menuItem={menuItem} allSections={allSections} saveError={saveError} onChange={onChange} />
          )}
          {menuItem["item-type"] === MenuItemType.Link && (
            <LinkSelector menuItem={menuItem} saveError={saveError} onChange={onChange} />
          )}
          {menuItem["item-type"] === MenuItemType.Tag && (
            <TagSelector menuItem={menuItem} saveError={saveError} onChange={onChange} />
          )}
        </div>
      )}
    </Inspector>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    menu: state.menu.app.currentMenu,
    menuItem: state.menu.app.currentMenuItem,
    allMenuItems: state.menu.menuItems,
    allMenuGroups: state.menu.menuGroups,
    type: state.menu.ui.inspector,
    saveError: state.menu.ui.save.error,
    allSections: state.config.sections,
    isSubmenuEnabled: state.features.isSubmenuEnabled,
    isSaving: state.menu.ui.save.loading
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    saveMenu: (menu: UnsavedMenuGroup) => dispatch(saveMenu(menu)),
    cancelSaveMenu: () => dispatch(cancelSaveMenu()),
    updateMenu: (data: UnsavedMenuGroup) => dispatch(updateMenu(data)),
    saveMenuItem: (menuItem: AnyMenuItem) => dispatch(saveMenuItem(menuItem)),
    cancelSaveMenuItem: () => dispatch(cancelSaveMenuItem()),
    onChange: (menuItem: AnyMenuItem) => dispatch(replaceCurrentMenuItem(menuItem)),
    setSlug: (menuGroup: UnsavedMenuGroup) => dispatch(setGeneratedSlug(menuGroup))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(MenuInspector);

export {
  NewMenuFields,
  MenuItemFields,
  ParentSelector,
  ItemTypeSelector,
  SectionSelector,
  LinkSelector,
  TagSelector,
  MenuInspector
};
