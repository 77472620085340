/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { StaticPage, UnSavedStaticPage } from "api/static-pages";
import { LoaderState, INITIAL_LOADER_STATE } from "behaviors/loader/state";
import { Features } from "api/route-data/route-data";

export interface PartialAppState {
  staticPages: State;
  config: {
    publisherWideBannerMessage: string | null;
  };
  features: Features;
}

export interface State {
  staticPages: StaticPage[];
  totalCount: number | null;
  app: {
    currentStaticPage: StaticPage | UnSavedStaticPage | null;
  };
  ui: {
    main: LoaderState;
    save: LoaderState;
    showDeleteConfirmationModal: boolean;
  };
}
export const emptyStaticPage: UnSavedStaticPage = {
  content: "",
  title: "",
  "source-path": "",
  slug: "",
  metadata: {}
};

export const INITIAL_STATIC_PAGES_STATE: State = {
  staticPages: [],
  totalCount: null,
  app: {
    currentStaticPage: null
  },
  ui: {
    main: INITIAL_LOADER_STATE,
    save: { loading: false, error: null },
    showDeleteConfirmationModal: false
  }
};
