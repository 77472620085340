/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const Trash = ({ width = 24, height = 24, color = "currentColor" }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill={color} viewBox="0 0 24 24" width={width} height={height}>
    <path d="M4.19 5A.85.85 0 0 1 5 4.1h4l.32-.67a.76.76 0 0 1 .28-.33 1 1 0 0 1 .46-.1h4a.93.93 0 0 1 .46.12.76.76 0 0 1 .31.33l.32.67h3.95a.85.85 0 0 1 .84.85v1a.41.41 0 0 1-.42.42H4.61a.44.44 0 0 1-.3-.12.41.41 0 0 1-.12-.3zm13.89 14.39a1.61 1.61 0 0 1-.53 1.13 1.68 1.68 0 0 1-1.16.46H7.74a1.68 1.68 0 0 1-1.16-.46 1.61 1.61 0 0 1-.53-1.13l-.7-11.46a.47.47 0 0 1 .12-.31.39.39 0 0 1 .3-.14h12.59a.36.36 0 0 1 .3.14.47.47 0 0 1 .12.31z" />
  </svg>
);

export default Trash;
