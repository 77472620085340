/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export function addIfNotExists<T>(array: T[], item: T) {
  if (array.includes(item)) {
    return array;
  } else {
    return array.concat([item]);
  }
}
