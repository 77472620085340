/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { useRef, useEffect } from "react";

const usePrevious = (value: any) => {
  const previousRef = useRef();

  useEffect(() => {
    previousRef.current = value;
  });

  return previousRef.current;
};

export default usePrevious;

const useCompare = (value: any) => {
  const previousValue = usePrevious(value);
  return previousValue !== value;
};

export { useCompare };
