/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";

const Unlink = ({ width = 20, height = 20, color = "var(--brand-3)" }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.2499 16.45L17.7499 14.9C18.4166 14.7167 18.9582 14.3625 19.3749 13.8375C19.7916 13.3125 19.9999 12.7 19.9999 12C19.9999 11.1667 19.7082 10.4583 19.1249 9.875C18.5416 9.29167 17.8332 9 16.9999 9H12.9999V7H16.9999C18.3832 7 19.5624 7.4875 20.5374 8.4625C21.5124 9.4375 21.9999 10.6167 21.9999 12C21.9999 12.95 21.7541 13.825 21.2624 14.625C20.7707 15.425 20.0999 16.0333 19.2499 16.45ZM15.8499 13L13.8499 11H15.9999V13H15.8499ZM19.7999 22.6L1.3999 4.2L2.7999 2.8L21.1999 21.2L19.7999 22.6ZM10.9999 17H6.9999C5.61657 17 4.4374 16.5125 3.4624 15.5375C2.4874 14.5625 1.9999 13.3833 1.9999 12C1.9999 10.85 2.3499 9.825 3.0499 8.925C3.7499 8.025 4.6499 7.43333 5.7499 7.15L7.5999 9H6.9999C6.16657 9 5.45824 9.29167 4.8749 9.875C4.29157 10.4583 3.9999 11.1667 3.9999 12C3.9999 12.8333 4.29157 13.5417 4.8749 14.125C5.45824 14.7083 6.16657 15 6.9999 15H10.9999V17ZM7.9999 13V11H9.6249L11.5999 13H7.9999Z"
      fill={color}
    />
  </svg>
);

export default Unlink;
