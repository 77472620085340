/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Blurb = ({ width = 24, height = 24, color = "#c6ccd5" }) => (
  <svg width={width} height={height} fill={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M19.06,4.23a2.16,2.16,0,0,1,1.58.66,2.17,2.17,0,0,1,.67,1.59V16.6a2.29,2.29,0,0,1-2.25,2.25H14L9.6,22.16a.5.5,0,0,1-.26.07.35.35,0,0,1-.28-.13.41.41,0,0,1-.12-.3V18.85H5.56A2.13,2.13,0,0,1,4,18.18a2.13,2.13,0,0,1-.67-1.58V6.48A2.17,2.17,0,0,1,4,4.89a2.16,2.16,0,0,1,1.58-.66ZM20.19,16.6V6.48a1.08,1.08,0,0,0-.34-.79,1,1,0,0,0-.79-.34H5.56a1.06,1.06,0,0,0-.79.34,1.07,1.07,0,0,0-.33.79V16.6a1.07,1.07,0,0,0,.33.79,1.1,1.1,0,0,0,.79.34h4.5V20.4l3.55-2.67h5.45a1.16,1.16,0,0,0,1.13-1.13Z" />
    <rect x="7.31" y="8.98" width="10" height="1" />
    <rect x="7.31" y="12.98" width="10" height="1" />
  </svg>
);

export default Blurb;
