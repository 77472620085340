/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import styles from "./lane.module.css";

interface Props {
  title: string;
  attributes: () => JSX.Element;
}

const Lane: React.SFC<Props> = ({ title, attributes, children }) => (
  <div className={styles["lane"]}>
    <header className={styles["lane-header"]}>
      <h3 className={styles["lane-title"]}>{title}</h3>
      <span className={styles["lane-header-attributes"]}>{attributes()}</span>
    </header>
    <div className={styles["lane-content"]}>{children}</div>
  </div>
);

export default Lane;
