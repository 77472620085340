/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Circle = ({ radius = 24, strokeWidth = 1, fillColor = "currentColor", strokeColor = "#c6ccd5" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={radius * 2} height={radius * 2}>
    <circle
      cx={radius}
      cy={radius}
      r={radius - strokeWidth}
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      fill={fillColor}
      data-test-id="circle-icon"
    />
  </svg>
);

export default Circle;
