/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import actions, {
  loadContributorRoles,
  maybeAddContributorRole,
  updateCurrentContributorRole,
  saveContributorRole,
  saveContributorRoleFailure,
  maybeEditContributorRole,
  maybeDeleteContributorRole,
  deleteContributorRoleSuccess,
  cancelDeleteContributorRole,
  updateContributorRoleFailure,
  cancelSaveContributorRole,
  cancelUpdateContributorRole
} from "./actions";
import { ContributorRole } from "api/contributor-roles";
import { AnyContributorRole } from "./state";

export const loadContributorRolesAction = (contributorRoles: ContributorRole[]): loadContributorRoles => {
  return {
    type: actions.LOAD_CONTRIBUTOR_ROLES,
    payload: contributorRoles
  };
};

export const maybeAddContributorRoleAction = (
  allContributorRoles: ContributorRole[] | null
): maybeAddContributorRole => {
  return {
    type: actions.MAYBE_ADD_CONTRIBUTOR_ROLE,
    payload: {
      allContributorRoles: allContributorRoles
    }
  };
};

export const updateCurrentContributorRoleAction = (
  contributorRole: AnyContributorRole
): updateCurrentContributorRole => {
  return {
    type: actions.UPDATE_CURRENT_CONTRIBUTOR_ROLE,
    payload: contributorRole
  };
};

export const updateContributorRoleFailureAction = (): updateContributorRoleFailure => {
  return {
    type: actions.UPDATE_CURRENT_CONTRIBUTOR_ROLE_FAILURE
  };
};

export const cancelUpdateContributorRoleAction = (): cancelUpdateContributorRole => {
  return {
    type: actions.CANCEL_UPDATE_CONTRIBUTOR_ROLE
  };
};

export const saveContributorRoleAction = (): saveContributorRole => {
  return {
    type: actions.SAVE_CONTRIBUTOR_ROLE
  };
};

export const saveContributorRoleFailureAction = (): saveContributorRoleFailure => {
  return {
    type: actions.SAVE_CONTRIBUTOR_ROLE_FAILURE
  };
};

export const maybeEditContributorRoleAction = (currentContributorRole: ContributorRole): maybeEditContributorRole => {
  return {
    type: actions.MAYBE_EDIT_CONTRIBUTOR_ROLE,
    payload: {
      currentContributorRole: currentContributorRole
    }
  };
};

export const cancelSaveContributorRoleAction = (): cancelSaveContributorRole => {
  return {
    type: actions.CANCEL_SAVE_CONTRIBUTOR_ROLE
  };
};

export const maybeDeleteContributorRoleAction = (contributorRole: ContributorRole): maybeDeleteContributorRole => {
  return {
    type: actions.MAYBE_DELETE_CONTRIBUTOR_ROLE,
    payload: contributorRole
  };
};

export const deleteContributorRoleSuccessAction = (): deleteContributorRoleSuccess => {
  return {
    type: actions.DELETE_CONTRIBUTOR_ROLE_SUCCESS
  };
};

export const cancelDeleteContributorRoleAction = (): cancelDeleteContributorRole => {
  return {
    type: actions.CANCEL_DELETE_CONTRIBUTOR_ROLE
  };
};
