/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { t } from "../../../../i18n";
import { TimelineEvent } from "api/activity-log";

export function summarizeEvent(event: TimelineEvent, isFirstEvent: boolean) {
  switch (event["action-name"]) {
    case "story-retract":
      return t("story-editor.timeline.retracted");
    case "story-close":
      return t("story-editor.timeline.close");
    case "story-publish":
      return t("story-editor.timeline.published");
    case "story-publish-without-workflow":
      return t("story-editor.timeline.published");
    case "story-correction-publish":
      return t("story-editor.timeline.correction-published");
    case "story-approve":
      return t("story-editor.timeline.approved");
    case "story-qa":
      return t("story-editor.timeline.qa");
    case "story-submit":
      return t("story-editor.timeline.submitted");
    case "story-proof-reading-done":
      return t("story-editor.timeline.proof-reading-done");
    case "story-reject":
      return t("story-editor.timeline.rejected");
    case "story-schedule":
      return t("story-editor.timeline.scheduled");
    case "story-save":
      return t("story-editor.timeline.saved");
    case "delayed-publish":
      return t("story-editor.timeline.published");
    case "story-autopublish":
      return t("story-editor.timeline.auto-published");
    case "version-restored":
      return t("story-editor.timeline.restored");
    case "story-schedule-modify":
      return t("story-editor.timeline.story-schedule-modified");
    case "story-schedule-cancel":
      return t("story-editor.timeline.story-schedule-cancelled");
    case "story-schedule-with-collection":
      return t("story-editor.timeline.story-schedule-with-collection");
    case "story-edited":
      return t("story-editor.timeline.story-edited");
    case "version-created": {
      if (isFirstEvent) {
        return t("story-editor.timeline.created");
      }
      return event["from-status"] === "draft" ? t("story-editor.timeline.saved") : t("story-editor.timeline.updated");
    }

    default:
      throw new Error(`[Story Timeline] Could not summarize event ${event.id} with action: ${event["action-name"]}`);
  }
}
