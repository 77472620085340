/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const USERS_PATH = "/settings/users-and-roles/users";
export const USERS_NEW_PATH = "/settings/users-and-roles/users/new";
export const USERS_NEW_PATH_ROLES = "/settings/users-and-roles/users/new/roles";
export const USERS_EDIT_PATH = "/settings/users-and-roles/users/:id";
export const USERS_EDIT_PATH_ROLES = "/settings/users-and-roles/users/:id/roles";
export const USERS_EDIT_PATH_TEAMS = "/settings/users-and-roles/users/:id/teams";
