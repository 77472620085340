/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ThunkDispatch } from "redux-thunk";
import {
  loadCustomFieldsAction,
  loadCustomFieldsSuccessAction,
  saveCustomFieldAction,
  saveCustomFieldFailureAction,
  deleteCustomFieldAction,
  deleteCustomFieldFailureAction,
  loadCustomFieldsFailureAction,
  saveCustomFieldSuccessAction,
  deleteCustomFieldSuccessAction,
  initNewCustomFieldAction,
  editCustomFieldAction,
  cancelCreateOrEditCustomFieldAction,
  editCustomFieldSuccessAction
} from "./action-creators";
import * as APICustomField from "api/custom-field";
import {
  CUSTOM_METADATA_NEW_PATH,
  CUSTOM_METADATA_INDEX_PATH,
  CUSTOM_METADATA_EDIT_PATH
} from "../custom-metadata/routes";
import { navigate } from "utils/routes.utils";
import { NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from "containers/page/actions";
import { t } from "i18n";

export type SuccessFn = (dispatch: ThunkDispatch<any, any, any>) => void;
export type FailureFn = (e: Error, dispatch: ThunkDispatch<any, any, any>) => void;

export const editSuccessFn: SuccessFn = (dispatch) => {
  dispatch({
    type: NOTIFICATION_SUCCESS,
    payload: {
      message: t("custom-metadata.messages.edit-success")
    }
  });

  dispatch(navigate(CUSTOM_METADATA_INDEX_PATH, {}, { refresh: true }));
};

export const editFailureFn: FailureFn = (e, dispatch) => {
  dispatch({
    type: NOTIFICATION_ERROR,
    payload: {
      message: t("custom-metadata.messages.unknown-error")
    }
  });
};

export const loadCustomFields = (
  options: { "field-type": APICustomField.AllowedFieldTypes; hideLoader?: boolean },
  successFn?: SuccessFn,
  failureFn?: FailureFn
) => async (dispatch: ThunkDispatch<any, any, any>) => {
  !options.hideLoader && dispatch(loadCustomFieldsAction());

  try {
    const customFields = await APICustomField.getCustomFields(options);
    dispatch(loadCustomFieldsSuccessAction(customFields));
    if (successFn) {
      successFn(dispatch);
    }
  } catch (e) {
    dispatch(loadCustomFieldsFailureAction(e));
    if (failureFn) {
      failureFn(e, dispatch);
    }
  }
};

export const saveCustomField = (
  customField: APICustomField.UnsavedCustomField,
  successFn?: SuccessFn,
  failureFn?: FailureFn
) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(saveCustomFieldAction());

  try {
    await APICustomField.createCustomField(customField);
    dispatch(saveCustomFieldSuccessAction());
    if (successFn) {
      successFn(dispatch);
    }
  } catch (e) {
    dispatch(saveCustomFieldFailureAction(e));
    if (failureFn) {
      failureFn(e, dispatch);
    }
  }
};

export const deleteCustomField = (
  customField: APICustomField.CustomField,
  successFn?: SuccessFn,
  failureFn?: FailureFn
) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(deleteCustomFieldAction(customField));

  try {
    await APICustomField.deleteCustomField(customField.id);
    dispatch(deleteCustomFieldSuccessAction());
    if (successFn) {
      successFn(dispatch);
    }
  } catch (e) {
    dispatch(deleteCustomFieldFailureAction(e));
    if (failureFn) {
      failureFn(e, dispatch);
    }
  }
};

export const addNewCustomField = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(initNewCustomFieldAction());
  dispatch(navigate(CUSTOM_METADATA_NEW_PATH));
};

export const editCustomMetadataField = (customField: APICustomField.CustomField) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(editCustomFieldAction(customField));
  dispatch(navigate(CUSTOM_METADATA_EDIT_PATH, { id: customField.id }));
  dispatch(loadCustomFields({ "field-type": APICustomField.AllowedFieldTypes.METADATA, hideLoader: true }));
};

export const editCustomField = (customField, successFn?: SuccessFn, failureFn?: FailureFn) => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  try {
    await APICustomField.editCustomField(customField.id, customField);
    dispatch(editCustomFieldSuccessAction());

    if (successFn) {
      successFn(dispatch);
    }
  } catch (e) {
    if (failureFn) {
      failureFn(e, dispatch);
    }
  }
};

export const cancelSaveCustomField = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(navigate(CUSTOM_METADATA_INDEX_PATH));
  dispatch(cancelCreateOrEditCustomFieldAction());
};
