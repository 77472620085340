/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import styles from "./ai-suggestions.module.css";
import classnames from "classnames/bind";
import { ShimmerText } from "react-shimmer-effects";
import Close from "components/icons/close";
import { AllowedStoryFields } from "api/ai";
import { connect } from "react-redux";
import { Story, UnsavedStory } from "api/story";
import { getStoryFieldSuggestion } from "pages/story-editor/async-action-creators";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState } from "pages/story-editor/state";
import { AnyAction } from "redux";
import MagicPencil from "components/icons/magic-pencil";
import TablePagination from "components/table-pagination/table-pagination";
import { getWebsiteLanguage } from "pages/story-editor/utils";
import Copy from "components/icons/copy";
import copyToClipboard from "helpers/copy-to-clipboard";
import { NOTIFICATION_SUCCESS } from "containers/page/actions";
import { t } from "i18n";
import usePrevious from "custom-hooks/previous";
import { useRefDimensionsOnResize } from "custom-hooks/resize";

const cx = classnames.bind(styles);

type OwnProps = {
  storyFieldClassName?: string;
  isOpen: boolean;
  onClose: () => void;
  storyField: AllowedStoryFields;
  characterLimit: number;
  onSuggestionSelect: (suggestion: string) => void;
  currentFieldValue: string;
  onGeneratingSuggestion: (isGeneratingSuggestion: boolean) => void;
  onSuggestionsChange: (suggestions: string[]) => void;
};

type DispatchProps = {
  getFieldSuggestion: (
    story: Story | UnsavedStory,
    field: AllowedStoryFields,
    maxLength: number,
    count: number
  ) => Promise<string[] | null>;
  notificationSuccess: (message: string) => void;
};

type StateProps = {
  story: Story | UnsavedStory;
  websiteLanguage: string;
  isInspectorOpen: boolean;
};

type Props = OwnProps & DispatchProps & StateProps;

const getSuggestionsPerPage = (characterLimit: number, websiteLanguage: string) => {
  return (websiteLanguage === "en" && characterLimit > 500) || (websiteLanguage !== "en" && characterLimit > 250)
    ? 2
    : 4;
};

type Suggestion = {
  text: string;
  loading: boolean;
};

export const AiSuggestions: React.FC<Props> = ({
  storyFieldClassName,
  children,
  isOpen,
  onClose,
  getFieldSuggestion,
  characterLimit,
  story,
  onSuggestionSelect,
  currentFieldValue,
  storyField,
  websiteLanguage,
  isInspectorOpen,
  onGeneratingSuggestion,
  notificationSuccess,
  onSuggestionsChange
}) => {
  const previousIsOpen = usePrevious(isOpen);
  const [suggestionsPerPage, setSuggestionsPerPage] = useState<number>(2);
  useEffect(() => {
    setSuggestionsPerPage(getSuggestionsPerPage(characterLimit, websiteLanguage));
  }, [characterLimit, websiteLanguage]);
  const isElementInViewport = (el: HTMLDivElement): boolean => {
    const bounding = el.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth;
    return bounding.top >= 0 && bounding.left >= 0 && bounding.right <= windowWidth && bounding.bottom <= windowHeight;
  };
  const suggestionListElementRef = useRef<HTMLDivElement | null>(null);
  const scrollSuggestionListToCenter = useCallback(() => {
    if (suggestionListElementRef && suggestionListElementRef.current) {
      if (!isElementInViewport(suggestionListElementRef.current)) {
        suggestionListElementRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [suggestionListElementRef]);

  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const onSuggestionsChangeCallback = useCallback(
    (suggestions: Suggestion[]) => {
      onSuggestionsChange && onSuggestionsChange(suggestions.map((suggestion) => suggestion.text));
    },
    [onSuggestionsChange]
  );

  const generateSuggestions = useCallback(async () => {
    setSuggestions([...[...Array(suggestionsPerPage)].map(() => ({ text: "", loading: true })), ...suggestions]);
    setCurrentPage(1);
    setGeneratingSuggestions(true);
    const existingSuggestions = suggestions.filter((suggestion) => !suggestion.loading);
    const generatedSuggestions = await getFieldSuggestion(story, storyField, characterLimit, suggestionsPerPage);
    if (generatedSuggestions) {
      const existingSuggestionsTexts = existingSuggestions.map((suggestion) => suggestion.text);
      const newSuggestions: Suggestion[] = generatedSuggestions
        .filter((suggestion: string) => !existingSuggestionsTexts.includes(suggestion))
        .map((suggestion) => ({ text: suggestion, loading: false }));
      const updatedSuggestions = [...newSuggestions, ...existingSuggestions];
      setSuggestions(updatedSuggestions);
      onSuggestionsChangeCallback(updatedSuggestions);
      setTimeout(() => {
        isOpen && scrollSuggestionListToCenter();
      }, 200);
    } else {
      // If no valid suggestions, then remove all loading suggestions
      setSuggestions([...existingSuggestions]);
    }
    setGeneratingSuggestions(false);
  }, [
    characterLimit,
    suggestionsPerPage,
    getFieldSuggestion,
    story,
    storyField,
    suggestions,
    isOpen,
    scrollSuggestionListToCenter,
    onSuggestionsChangeCallback
  ]);

  // Generate new suggestions when there are no existing suggestions
  useEffect(() => {
    if (isOpen && previousIsOpen !== isOpen && suggestions.length === 0) {
      (async () => {
        await generateSuggestions();
      })();
    }
  }, [suggestions, isOpen, generateSuggestions, previousIsOpen]);
  const [generatingSuggestions, setGeneratingSuggestions] = useState(false);
  useEffect(() => {
    onGeneratingSuggestion && onGeneratingSuggestion(generatingSuggestions);
  }, [generatingSuggestions, onGeneratingSuggestion]);

  // Pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageSuggestions, setCurrentPageSuggestions] = useState<Suggestion[]>([]);
  useEffect(() => {
    setCurrentPageSuggestions(
      suggestions.slice((currentPage - 1) * suggestionsPerPage, currentPage * suggestionsPerPage)
    );
  }, [suggestions, currentPage, suggestionsPerPage]);

  // When user closes suggestion
  const handleClose = useCallback(() => {
    onClose();
    setTimeout(() => {
      // Move user selected suggestion to top of generated suggestions list on close, so that
      // when opening again selected option will come as first in the list of suggestions
      const userSelectedSuggestion = suggestions.find((suggestion) => suggestion.text === currentFieldValue);
      if (userSelectedSuggestion) {
        const restOfSuggestions = suggestions.filter((suggestion) => suggestion !== userSelectedSuggestion);
        setSuggestions([userSelectedSuggestion, ...restOfSuggestions]);
        // Set current page number as 1
        setCurrentPage(1);
      }
    }, 400);
  }, [onClose, suggestions, currentFieldValue]);

  // Close suggestion component when user clicks outside the div
  const aiSuggestionElementRef = useRef<HTMLDivElement | null>(null);
  const handleMouseClick = useCallback(
    (e: React.MouseEvent<HTMLElement> | Event) => {
      const element = aiSuggestionElementRef && aiSuggestionElementRef.current;
      if (element && element.contains && element.contains(e.target as HTMLElement)) {
        return;
      }
      handleClose();
    },
    [handleClose]
  );
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleMouseClick);
    } else {
      document.removeEventListener("mousedown", handleMouseClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleMouseClick);
    };
  }, [isOpen, handleMouseClick]);

  // Scroll suggestions list container into view after opening
  useEffect(() => {
    if (isOpen && !previousIsOpen) {
      setTimeout(() => {
        scrollSuggestionListToCenter();
      }, 400);
    }
  }, [isOpen, previousIsOpen, scrollSuggestionListToCenter]);

  // Display more lines in shimmer if number of characters is more than 250 to occupy full container height
  const shimmerLines = characterLimit < 250 ? 2 : 3;

  // Copy suggestion to clipboard
  const onCopySuggestionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, suggestion: string) => {
    e.stopPropagation();
    copyToClipboard(suggestion);
    notificationSuccess(t("common.phrases.copied-to-clipboard"));
  };

  // Calculate suggestion element background height
  const aiSuggestionWrapperElementRef = useRef<HTMLDivElement | null>(null);
  const [backgroundHeight, setBackgroundHeight] = useState<string>("calc(100% + 2rem)");
  const { height: suggestionWrapperHeight } = useRefDimensionsOnResize(aiSuggestionWrapperElementRef, 100);
  const previousSuggestionWrapperHeight = (usePrevious(suggestionWrapperHeight) as unknown) as number;
  useEffect(() => {
    // Change background height only when the suggestions height increased or decreased more than 16px.
    // This is to avoid too many shifts if the content change is not significant
    const differenceInHeight = suggestionWrapperHeight - (previousSuggestionWrapperHeight || 0);
    if (differenceInHeight > 0 || differenceInHeight < -16) {
      setBackgroundHeight(`calc(100% + ${suggestionWrapperHeight}px + 4rem)`);
    }
  }, [suggestionWrapperHeight, previousSuggestionWrapperHeight]);

  // When the component is rendered inside story editor content and inspector is open,
  // then width need to be shrunk so that component stays within visible area
  const [shrinkHeaderElementWidth, setShrinkHeaderElementWidth] = useState(false);
  const [shrinkPMEditorElementWidth, setShrinkPMEditorElementWidth] = useState(false);
  useEffect(() => {
    if (aiSuggestionElementRef && aiSuggestionElementRef.current) {
      const isElementInStoryEditorHeader = aiSuggestionElementRef.current.closest("#story-editor__header");
      if (isElementInStoryEditorHeader && isInspectorOpen) {
        return setShrinkHeaderElementWidth(true);
      }
      const isElementInPMEditor = aiSuggestionElementRef.current.closest("#story-editor__editor-container");
      if (isElementInPMEditor && isInspectorOpen) {
        return setShrinkPMEditorElementWidth(true);
      }
    }
    setShrinkHeaderElementWidth(false);
    setShrinkPMEditorElementWidth(false);
  }, [aiSuggestionElementRef, isInspectorOpen]);

  return (
    <div
      className={cx([
        "ai-multi-suggestions",
        {
          "ai-multi-suggestions--open": isOpen,
          "ai-multi-suggestions--shrink-header-element": shrinkHeaderElementWidth,
          "ai-multi-suggestions--shrink-pm-editor-element": shrinkPMEditorElementWidth
        }
      ])}
      ref={aiSuggestionElementRef}>
      <div className={cx("story-field-wrapper")}>
        <div className={cx("story-field", storyFieldClassName)}>{children}</div>
      </div>
      <div className={cx("ai-multi-suggestions__background")} style={{ height: backgroundHeight }}></div>
      <div
        data-test-id={"ai-suggestions-wrapper"}
        className={cx([
          "ai-suggestions-wrapper",
          {
            "ai-suggestions-wrapper--open": isOpen,
            "ai-suggestions-wrapper--shrink-header-element": shrinkHeaderElementWidth,
            "ai-multi-wrapper--shrink-pm-editor-element": shrinkPMEditorElementWidth
          }
        ])}
        ref={aiSuggestionWrapperElementRef}>
        <div className={cx("ai-header")}>
          <button
            data-test-id="ai-suggestions-regenerate-btn"
            disabled={generatingSuggestions}
            className={cx("action-btn")}
            onClick={() => generateSuggestions()}>
            <MagicPencil width={"16"} height={"16"} />
            <span>{t("story-editor.ai-suggestions.regenerate")}</span>
          </button>
          <div data-test-id="ai-suggestions-pagination" className={cx("ai-header__right-side")}>
            <TablePagination
              specifiedPage={currentPage}
              totalCount={suggestions.length}
              perPage={suggestionsPerPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
            <button
              data-test-id="ai-suggestions-close-btn"
              className={cx("action-btn", "action-btn--close-btn")}
              onClick={handleClose}>
              <Close color={"currentColor"} />
            </button>
          </div>
        </div>
        <div className={cx("ai-suggestions")} ref={suggestionListElementRef}>
          {currentPageSuggestions.map((suggestion, idx) => (
            <Fragment key={idx}>
              {suggestion.loading && generatingSuggestions ? (
                <div
                  data-test-id="ai-suggestions-suggestion-loader"
                  key={idx}
                  className={cx("ai-suggestions__suggestion", "ai-suggestions__suggestion--loader")}>
                  <ShimmerText className={cx("shimmer-wrapper")} line={shimmerLines} variant={"secondary"} />
                </div>
              ) : (
                <div
                  data-test-id="ai-suggestions-suggestion-text"
                  key={idx}
                  className={cx("ai-suggestions__suggestion", {
                    "ai-suggestions__suggestion--highlight": suggestion.text === currentFieldValue
                  })}
                  onClick={() => onSuggestionSelect(suggestion.text)}>
                  <p className={cx("ai-suggestions__suggestion__text")}>{suggestion.text}</p>
                  <div
                    data-test-id="ai-suggestions-copy-suggestion-btn"
                    className={cx("action-btn", "ai-suggestions__suggestion__copy")}
                    onClick={(e) => onCopySuggestionClick(e, suggestion.text)}>
                    <Copy width={"20px"} height={"20px"} />
                  </div>
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    story: state.storyEditor.story,
    websiteLanguage: getWebsiteLanguage(state),
    isInspectorOpen:
      state.router &&
      (state.router.location.pathname.includes("manage") ||
        state.router.location.pathname.includes("timeline") ||
        state.router.location.pathname.includes("validator") ||
        state.router.location.pathname.includes("preview"))
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    getFieldSuggestion: (story: Story | UnsavedStory, field: AllowedStoryFields, maxLength: number, count: number) => {
      return dispatch(getStoryFieldSuggestion(story, field, maxLength, count));
    },
    notificationSuccess: (message: string) => dispatch({ type: NOTIFICATION_SUCCESS, payload: { message } })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AiSuggestions);
