/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { createSlice, createAction } from "@reduxjs/toolkit";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";
import { AnyAction } from "redux";

import { notificationError, notificationSuccess } from "action-creators/notification";
import {
  PushNotificationChannel,
  getChannels,
  AnyChannel,
  saveChannel,
  deleteChannel
} from "api/push-notification-channels";
import { LoaderState } from "behaviors/loader/state";
import { Section } from "api/route-data/route-data";
import { CONFIGURE_PUSH_NOTIFICATION_CHANNELS_PATH } from "routes";
import { navigate } from "utils/routes.utils";
import { getErrorMessage } from "utils/error.utils";

interface ChannelsAction<T> {
  payload: T;
}

interface ChannelsPayload {
  channels: PushNotificationChannel[];
}

export const INITIAL_PUSH_NOTIFICATION_CHANNELS_STATE = {
  data: [],
  ui: { main: { loading: true, error: null }, inspector: false, showDeleteConfirmationModal: false },
  config: { sections: [] },
  selectedChannel: { type: null, name: null, section: null },
  channelForDeletion: null
};

export interface PartialAppState {
  data: PushNotificationChannel[];
  ui: { main: LoaderState; inspector?: boolean | null; showDeleteConfirmationModal?: boolean };
  config: {
    sections: Section[];
  };
  selectedChannel: AnyChannel;
  channelForDeletion: PushNotificationChannel | null;
}

const { reducer, actions, name } = createSlice({
  initialState: INITIAL_PUSH_NOTIFICATION_CHANNELS_STATE,
  name: "pushNotificationChannels",
  reducers: {
    initPushNotificationChannel: (state: PartialAppState) => {
      state.ui.main.loading = false;
      state.ui.inspector = true;
    },
    setPushNotificationChannel: (state: PartialAppState, { payload: { selectedChannel } }: any) => {
      state.selectedChannel = selectedChannel;
    },
    getPushNotificationChannelsRequest: (state: PartialAppState) => {
      state.ui.main.loading = true;
    },
    getPushNotificationChannelsSuccess: (
      state: PartialAppState,
      { payload: { channels } }: ChannelsAction<ChannelsPayload>
    ) => {
      if (channels) {
        state.data = channels;
        state.ui.main.loading = false;
      }
    },
    getPushNotificationChannelsFailure: (state: PartialAppState) => {
      state.ui.main.loading = false;
    },
    resetSelectedChannel: (state: PartialAppState) => {
      state.ui.inspector = false;
      state.selectedChannel = { type: null, name: "", section: null };
    },
    maybeDeletePushNotificationChannel: (state: PartialAppState, { payload: { channel } }) => {
      state.ui.showDeleteConfirmationModal = true;
      state.channelForDeletion = channel;
    },
    deletePushNotificationChannelRequest: (state: PartialAppState) => {
      state.ui.showDeleteConfirmationModal = false;
      state.ui.main.loading = true;
    },
    deletePushNotificationChannelSuccess: (state: PartialAppState) => {
      state.ui.main.loading = true;
      state.channelForDeletion = null;
    },
    cancelPushNotificationDelete: (state: PartialAppState) => {
      state.ui.showDeleteConfirmationModal = false;
      state.channelForDeletion = null;
    }
  }
});

export const {
  initPushNotificationChannel,
  setPushNotificationChannel,
  getPushNotificationChannelsSuccess,
  getPushNotificationChannelsFailure,
  resetSelectedChannel,
  maybeDeletePushNotificationChannel,
  deletePushNotificationChannelRequest,
  deletePushNotificationChannelSuccess,
  cancelPushNotificationDelete
} = actions;

function asyncActionCreateHelper(action: string) {
  const actionRequestType = `${name}/${action}Request`;
  const actionSuccessType = `${name}/${action}Success`;
  const actionErrorType = `${name}/${action}Failure`;
  const actionRequest = createAction(actionRequestType);
  const actionSuccess = createAction(actionSuccessType, (data: PushNotificationChannel[]) => ({
    payload: { channels: data }
  }));
  const actionError = createAction(actionErrorType, (error: Error, data?: any) => ({ payload: { error, meta: data } }));
  return [actionRequest, actionSuccess, actionError];
}

export const [getPushNotificationChannelsRequest] = asyncActionCreateHelper("getPushNotificationChannels");

export function getPushNotificationChannels() {
  return (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    dispatch(getPushNotificationChannelsRequest());
    return getChannels()
      .then((channels: PushNotificationChannel[]) => dispatch(getPushNotificationChannelsSuccess({ channels })))
      .catch((error: Error) => {
        const errMsg = getErrorMessage(error, t("settings.push_notification_channels.server_error"));
        dispatch(notificationError(errMsg));
        dispatch(getPushNotificationChannelsFailure());
      });
  };
}

export function deletePushNotificationChannel(channel: PushNotificationChannel) {
  return (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    dispatch(deletePushNotificationChannelRequest());
    return deleteChannel(channel)
      .then(() => {
        dispatch(deletePushNotificationChannelSuccess());
        dispatch(getPushNotificationChannels());
        dispatch(notificationSuccess(t("settings.push_notification_channels.delete_success")));
      })
      .catch((error: Error) => {
        const errMsg = getErrorMessage(error, t("settings.push_notification_channels.server_error"));
        dispatch(notificationError(errMsg));
      });
  };
}

export function savePushNotificationChannel(channel: AnyChannel) {
  return (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return saveChannel(channel)
      .then(() => {
        dispatch(resetSelectedChannel());
        dispatch(navigate(CONFIGURE_PUSH_NOTIFICATION_CHANNELS_PATH));
        dispatch(notificationSuccess(t("settings.push_notification_channels.save_success")));
      })
      .catch((error: Error) => {
        const errMsg = getErrorMessage(error, t("settings.push_notification_channels.server_error"));
        dispatch(notificationError(errMsg));
      });
  };
}

export function closeInspector() {
  return (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    dispatch(resetSelectedChannel());
    dispatch(navigate(CONFIGURE_PUSH_NOTIFICATION_CHANNELS_PATH));
  };
}

export default reducer;
