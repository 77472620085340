/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface Props {
  width?: number;
  height?: number;
}

const Media = ({ width = 24, height = 24 }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox="0 0 24 24">
    <defs>
      <path
        id="a-media"
        d="M19.178 17.414h-7.57l5.532-6.706 3.117 3.482.054-.048v2.138c0 .626-.508 1.134-1.133 1.134m-12.506-.14l2.921-3.757 1.917 1.566.568-.697-2.629-2.147-3.342 4.296c-.02-.083-.05-.163-.05-.255V4.293c0-.625.507-1.134 1.132-1.134h11.99c.624 0 1.132.51 1.132 1.134v8.61l-3.2-3.573-6.668 8.084H7.189c-.19 0-.36-.06-.517-.14M19.178 2a2.295 2.295 0 0 1 2.292 2.293V16.28a2.295 2.295 0 0 1-2.292 2.293H7.188a2.295 2.295 0 0 1-2.29-2.293V4.293A2.295 2.295 0 0 1 7.187 2h11.99zM3.16 18.973c0 .787.64 1.426 1.427 1.426H17.56v1.159H4.586A2.589 2.589 0 0 1 2 18.973v-9.76h1.16v9.76z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <mask id="b-media" fill="currentColor">
        <use xlinkHref="#a-media" />
      </mask>
      <use fill="currentColor" xlinkHref="#a-media" />
      <g fill="none" mask="url(#b-media)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

export default Media;
