/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect, useMemo, useState } from "react";
import { batch, connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "@reduxjs/toolkit";
import { isEmpty, pick } from "lodash";
import t from "i18n";
import { CollectionMetadataMap } from "api/route-data/collection-route-data";
import {
  ActiveFilters,
  addActiveFilters,
  fetchCollections,
  Filters,
  PartialAppState,
  setShowFiltersInspector,
  Template,
  updateFilters
} from "store/collection/collections-dashboard";
import Inspector from "components/inspector/inspector";
import Select from "components/select/select";
import styles from "./inspector.module.css";

const fixedTemplates = {
  default: { id: "default", label: "Default" },
  section: { id: "section", label: "Section" },
  "breaking-news": { id: "breaking-news", label: "Breaking News" }
};

interface StateProps {
  showInspector: boolean;
  filters: Filters;
  activeFilters: string[];
  allTemplates: CollectionMetadataMap;
}

interface DispatchProps {
  closeInspector: VoidFunction;
  applyFilters: (filters: Partial<Filters>) => void;
}

function toTemplateOptions(templates: CollectionMetadataMap) {
  return Object.values(templates)
    .map((template) => pick(template, ["id", "label"]))
    .sort(({ label: templateLabelOne }, { label: templateLabelTwo }) => {
      if (templateLabelOne < templateLabelTwo) return -1;
      if (templateLabelOne > templateLabelTwo) return 1;
      return 0;
    });
}

export const DashboardInspector: React.FC<StateProps & DispatchProps> = ({
  showInspector,
  activeFilters,
  filters,
  applyFilters,
  closeInspector,
  allTemplates
}) => {
  const templateOptions = useMemo<Template[]>(() => toTemplateOptions({ ...allTemplates, ...fixedTemplates }), [
    allTemplates
  ]);
  const [currentFilters, setCurrentFilters] = useState(pick(filters, activeFilters));
  useEffect(() => {
    setCurrentFilters(pick(filters, activeFilters));
  }, [activeFilters, filters, showInspector]);
  const { templates = null } = currentFilters;

  return (
    <Inspector
      isActive={showInspector}
      title={t("common.template")}
      onActionButtonClick={() => applyFilters(currentFilters)}
      actionButtonLabel={t("common.apply")}
      showActionButton={true}
      onClose={closeInspector}>
      <div className={styles["collections-dashboard-inspector"]}>
        <div className={styles["filters-section"]}>
          <Select
            label={t("common.template")}
            options={templateOptions}
            value={templates}
            onChange={(templates: Template[]) =>
              setCurrentFilters(
                isEmpty(templates) ? { ...currentFilters, templates: undefined } : { ...currentFilters, templates }
              )
            }
            getOptionLabel={(template) => template.label}
            getOptionValue={(template) => template.id}
            isClearable={true}
            isMulti={true}
          />
        </div>
      </div>
    </Inspector>
  );
};

function mapStateToProps(state: PartialAppState): StateProps {
  return {
    showInspector: state.collectionsDashboard.ui.showFiltersInspector,
    filters: state.collectionsDashboard.filters,
    activeFilters: state.collectionsDashboard.ui.activeFilters,
    allTemplates: state.config.collectionMetadata
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps {
  return {
    closeInspector: () => dispatch(setShowFiltersInspector(false)),
    applyFilters: (filters) =>
      batch(() => {
        dispatch(updateFilters(filters));
        dispatch(addActiveFilters(Object.keys(filters) as ActiveFilters));
        dispatch(setShowFiltersInspector(false));
        dispatch(fetchCollections({ shouldClearCollections: true, showLoader: true }));
      })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardInspector);
