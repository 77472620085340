/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect, useState } from "react";
import { format } from "timeago.js";
import { Locales, localesMapping, t } from "i18n";
import { formattedDate } from "utils";
import Tooltip from "components/tooltip/tooltip";
import styles from "./last-updated-timer.module.css";

const refreshIntervalInMs = 30000;
const twentyFourHoursInMs = 86400000;
const defaultLocale: Locales = "en-in";
const translationPath = "story-editor.last-updated-timer";

interface Props {
  lastUpdatedTime: number;
  authorName: string | null;
  locale: Locales;
  hide?: boolean;
  onClick: VoidFunction;
}
const LastUpdatedTimer: React.FC<Props> = ({ lastUpdatedTime, locale, authorName, hide, onClick }) => {
  useEffect(() => {
    (localesMapping[locale] || localesMapping[defaultLocale]).registerTimeago();
  }, [locale]);

  const [lastUpdatedTimeRelative, setLastUpdatedTimeRelative] = useState(format(lastUpdatedTime, locale));
  const lastUpdatedTimeAbsolute = formattedDate(lastUpdatedTime, "dd MMM yyyy, hh:mm a");

  useEffect(() => {
    setLastUpdatedTimeRelative(format(lastUpdatedTime, locale));
    const setIntervalId = setInterval(() => {
      setLastUpdatedTimeRelative(format(lastUpdatedTime, locale));
    }, refreshIntervalInMs);

    return () => {
      clearInterval(setIntervalId);
    };
  }, [lastUpdatedTime, locale]);

  const timeDifferenceFromNowInMs = new Date().getTime() - lastUpdatedTime;

  const [lastUpdatedText, lastUpdatedOnHoverText] =
    timeDifferenceFromNowInMs > twentyFourHoursInMs
      ? [
          authorName
            ? t(`${translationPath}.text-absolute`, { time: lastUpdatedTimeAbsolute, authorName })
            : t(`${translationPath}.text-absolute-no-author`, { time: lastUpdatedTimeAbsolute }),
          lastUpdatedTimeRelative
        ]
      : [
          authorName
            ? t(`${translationPath}.text-relative`, { time: lastUpdatedTimeRelative, authorName })
            : t(`${translationPath}.text-relative-no-author`, { time: lastUpdatedTimeRelative }),
          lastUpdatedTimeAbsolute
        ];

  if (hide || !lastUpdatedTime || !locale) return null;

  return (
    <span
      className={styles["last-updated-timer"]}
      onClick={onClick}
      data-for={"last-updated-timer-tooltip"}
      data-tip={true}
      data-test-id={"last-updated-timer"}>
      {lastUpdatedText}
      <Tooltip
        id={"last-updated-timer-tooltip"}
        place="bottom"
        effect="solid"
        value={lastUpdatedOnHoverText}
        classname={styles["last-updated-timer-tooltip"]}
      />
    </span>
  );
};

export default LastUpdatedTimer;
