/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { PartialAppState } from "../../state";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { t } from "i18n";

import Button from "components/button/button";

import Header from "components/header/header";
import { saveBreakingNews, saveExistingBreakingNews } from "../../async-action-creators";
import { BreakingNewsId } from "api/primitive-types";

interface StateProps {
  enableBreakingNewsPublish: boolean;
  savingBreakingNews: boolean;
  breakingNewsId?: BreakingNewsId;
}

interface DispatchProps {
  saveBreakingNews: (id?: BreakingNewsId) => void;
}

type BreakingNewsHeaderProps = StateProps & DispatchProps;

const BreakingNewsHeader: React.SFC<BreakingNewsHeaderProps> = ({
  breakingNewsId,
  enableBreakingNewsPublish,
  saveBreakingNews,
  savingBreakingNews
}) => {
  return (
    <Header>
      {enableBreakingNewsPublish && (
        <Button
          type="primary"
          variant="success"
          disabled={savingBreakingNews}
          onClick={() => saveBreakingNews(breakingNewsId)}>
          {t("breaking-news.publish-button-label")}
        </Button>
      )}
    </Header>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => ({
  enableBreakingNewsPublish: state.breakingNews.ui.enableBreakingNewsPublish,
  savingBreakingNews: state.breakingNews.ui.savingBreakingNews,
  breakingNewsId: state.breakingNews.breakingNews["story-content-id"]
});

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, any, AnyAction>): DispatchProps => ({
  saveBreakingNews: (breakingNewsId) =>
    breakingNewsId && breakingNewsId !== "new" ? dispatch(saveExistingBreakingNews()) : dispatch(saveBreakingNews())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BreakingNewsHeader);

export { BreakingNewsHeader };
