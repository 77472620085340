/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { RouteDataResponse } from "api/route-data/route-data";
import { QrData } from "api/tfa";

enum actions {
  UPDATE_LOGIN_DATA = "@@login/UPDATE_LOGIN_DATA",
  GET_LOGIN_DATA = "@@login/GET_LOGIN_DATA",
  SET_EMAIL_TO_RESET_PASSWORD = "@@login/SET_EMAIL_TO_RESET_PASSWORD",
  UPDATE_RESET_PASSWORD_FIELDS = "@@login/UPDATE_RESET_PASSWORD_FIELDS",
  SET_VALIDATION_ERROR = "@@login/SET_VALIDATION_ERROR",
  CLEAR_VALIDATION_ERROR = "@@login/CLEAR_VALIDATION_ERROR",
  CANCEL_FORM_SIGN_IN = "@@login/CANCEL_FORM_SIGN_IN",
  INITIALIZE_SIGN_IN = "@@login/INITIALIZE_SIGN_IN",
  SIGN_IN_SUCCESS = "@@login/SIGN_IN_SUCCESS",
  ON_ROUTE_CHANGED = "@@login/ON_ROUTE_CHANGED",
  AUTH_TOKEN_SUCCESS = "@@login/AUTH_TOKEN_SUCCESS",
  AUTH_TOKEN_FAILURE = "@@login/AUTH_TOKEN_FAILURE",
  SET_OTP = "@@login/SET_OTP",
  LOAD_TWO_FACTOR_AUTH = "@@login/LOAD_TWO_FACTOR_AUTH",
  SET_TOTP = "@@login/SET_TOTP"
}

export default actions;

export type LoginUpdateData = {
  type: actions.UPDATE_LOGIN_DATA;
  payload: {
    key: string;
    value: string;
  };
};

export type GetLoginData = {
  type: actions.GET_LOGIN_DATA;
  payload: { data: RouteDataResponse };
};

export type SetEmailToResetPassword = {
  type: actions.SET_EMAIL_TO_RESET_PASSWORD;
  payload: { email: string };
};

export type UpdateResetPasswordFields = {
  type: actions.UPDATE_RESET_PASSWORD_FIELDS;
  payload: { key: string; value: string };
};

export type SetValidationError = {
  type: actions.SET_VALIDATION_ERROR;
  payload: { error: Error };
};

export type ClearValidationError = {
  type: actions.CLEAR_VALIDATION_ERROR;
};

export type CancelFormSignIn = {
  type: actions.CANCEL_FORM_SIGN_IN;
};

export type InitializeSignInAction = {
  type: actions.INITIALIZE_SIGN_IN;
};

export type SignInSuccessAction = {
  type: actions.SIGN_IN_SUCCESS;
};

export type OnRouteChanged = {
  type: actions.ON_ROUTE_CHANGED;
};

export type GetTwoFactorAuthTokenSuccessAction = {
  type: actions.AUTH_TOKEN_SUCCESS;
  payload: { token: QrData };
};
export type GetTwoFactorAuthTokenFailureAction = {
  type: actions.AUTH_TOKEN_FAILURE;
  payload: { error: Error };
};

export type OnChangeOTP = {
  type: actions.SET_OTP;
  payload: { otp: string };
};

export type OnChangeTOTP = {
  type: actions.SET_TOTP;
  payload: { totp: string };
};

export type LoadTwoFactorAuth = {
  type: actions.LOAD_TWO_FACTOR_AUTH;
};

export type ActionTypes =
  | LoginUpdateData
  | GetLoginData
  | SetEmailToResetPassword
  | UpdateResetPasswordFields
  | SetValidationError
  | ClearValidationError
  | CancelFormSignIn
  | InitializeSignInAction
  | SignInSuccessAction
  | OnRouteChanged
  | GetTwoFactorAuthTokenSuccessAction
  | GetTwoFactorAuthTokenFailureAction
  | OnChangeOTP
  | LoadTwoFactorAuth
  | OnChangeTOTP;
