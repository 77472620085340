/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useState } from "react";
import { get, isEmpty, negate } from "lodash";
import { connect } from "react-redux";
import SocialCardSchedule from "./social-card-schedule";
import SocialAccount from "./social-account";
import TextArea from "components/text-area/text-area";
import TextAreaWithCount from "components/text-area/text-area-with-count";
import Button from "components/button/button";
import Trash from "components/icons/trash";
import Summary from "pages/story-editor/components/manage-fields/summary";
import styles from "./social-notifications.module.css";
import { actions } from "../../actions";
import { deleteSocialCardAction, addSocialNotificationsHistoryAction } from "pages/story-editor/async-action-creators";

import { t } from "i18n";
import FieldLabel from "components/field-label/field-label";

import {
  selectSocialCardsToDisplay,
  selectSocialCardsNotScheduled,
  selectSocialCardsHistory,
  isPreviousSocialNotificationsBtnEnabled
} from "pages/story-editor/selectors";
import { SocialCard, SocialAccount as SocialAccountType, ScheduledSocialCard, SelectedMedia } from "api/social";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { PartialAppState, Errors, StoryEditorState } from "pages/story-editor/state";
import { Fields } from "api/route-data/story-route-data";
import { AllowedStoryFields } from "api/ai";
import classnames from "classnames/bind";
import { AiGenerateButton } from "pages/story-editor/components/ai-generate-button/ai-generate-button";
import AiSuggestions from "pages/story-editor/components/ai-suggestions/ai-suggestions";

const SOCIAL_SHARE_CHAR_LIMIT = 256;

interface DispatchProps {
  onChange(key: string): void;
  updateSocialCard(cardId: string, key: string, value: string): void;
  toggleSocialAccount(cardId: string, accountId: string, accountProvider: string): void;
  addSocialCard(storyId: string): void;
  addSocialHistory(storyId: string): void;
  deleteSocialCard(cardId: string, contentId: string): void;
}

interface StateProps {
  story: StoryEditorState["story"];
  fieldSpecs: Fields;
  socialAccounts: SocialAccountType[];
  socialCards: Array<SocialCard | ScheduledSocialCard>;
  socialCardsHistory: Array<SocialCard | ScheduledSocialCard>;
  errors: Errors["inspector"];
  socialCardsToDisplay: SocialCard[];
  enablePreviousSocialNotificationsBtn: boolean;
  isStoryLocked: boolean;
  isGenerateSocialShareEnabled: boolean;
}

interface OwnProps {
  disabled?: boolean;
}

type Props = DispatchProps & StateProps & OwnProps;

export function isScheduledSocialCard(value: SocialCard | ScheduledSocialCard): value is ScheduledSocialCard {
  return !value.hasOwnProperty("cardId");
}

const isAccountChecked = (
  socialCards: Array<SocialCard | ScheduledSocialCard>,
  cardId: string,
  accountId: string
): boolean => {
  socialCards = socialCards.filter(negate(isScheduledSocialCard));
  const card = (socialCards as SocialCard[]).find((card) => card.cardId === cardId);
  return card ? card.selectedMedia.some((media: SelectedMedia) => media.id === accountId) : false;
};

interface SocialNotificationsHistoryProps {
  socialAccounts: SocialAccountType[];
  socialCardHistory: SocialCard;
  socialCards: Array<SocialCard | ScheduledSocialCard>;
  isPosted: boolean;
  toggleSocialAccount(cardId: string, accountId: string, accountProvider: string): void;
  isStoryLocked: boolean;
}

const SocialNotificationsHistory: React.SFC<SocialNotificationsHistoryProps> = ({
  socialAccounts,
  socialCardHistory,
  socialCards,
  isPosted,
  toggleSocialAccount,
  isStoryLocked
}) => {
  return (
    <div>
      <FieldLabel label={t("story-editor.inspector.previous-notifications")} />
      <TextArea
        onChange={() => {}}
        disabled={isPosted || socialCardHistory.isScheduled}
        placeholder={t("story-editor.inspector.previous-notifications")}
        value={socialCardHistory.postMessage}
      />
      {socialAccounts && socialAccounts.length > 0 && (
        <div className={styles["social-card-social-accounts"]}>
          {socialAccounts
            .filter((account: SocialAccountType) => account["is-active"])
            .map((account: SocialAccountType) => (
              <SocialAccount
                key={account["account-id"]}
                account={account}
                isAccountChecked={isAccountChecked}
                toggleSocialAccount={toggleSocialAccount}
                socialCard={socialCardHistory}
                socialCards={socialCards}
                isPosted={isPosted || socialCardHistory.isScheduled}
                isStoryLocked={isStoryLocked}
              />
            ))}
        </div>
      )}
    </div>
  );
};

interface SocialCardsToDisplayProps {
  socialCardToDisplay: SocialCard;
  deleteSocialCard(cardId: string, contentId: string): void;
  story: StoryEditorState["story"];
  updateSocialCard(cardId: string, key: string, value: string): void;
  socialAccounts: SocialAccountType[];
  toggleSocialAccount(cardId: string, accountId: string, accountProvider: string): void;
  socialCards: Array<SocialCard | ScheduledSocialCard>;
  isStoryLocked: boolean;
  isGenerateSocialShareEnabled: boolean;
}

const cx = classnames.bind(styles);

const SocialCardsToDisplay: React.SFC<SocialCardsToDisplayProps> = ({
  socialCardToDisplay,
  deleteSocialCard,
  story,
  updateSocialCard,
  socialAccounts,
  toggleSocialAccount,
  socialCards,
  isStoryLocked,
  isGenerateSocialShareEnabled
}) => {
  const [showAiSuggestions, setShowAiSuggestions] = useState(false);
  const [generatingSuggestion, setGeneratingSuggestion] = useState(false);

  return (
    <React.Fragment>
      {!isStoryLocked && (
        <div className={styles["social-card-title-and-action"]}>
          <span
            data-test-id="social-card-delete"
            className={styles["social-card-delete"]}
            onClick={() => deleteSocialCard(socialCardToDisplay.cardId, story["story-content-id"])}>
            <Trash />
          </span>
        </div>
      )}
      <div
        className={cx("social-share-message", {
          "social-share-message--show-ai-generate-btn": isGenerateSocialShareEnabled
        })}>
        <AiSuggestions
          isOpen={showAiSuggestions}
          onClose={() => setShowAiSuggestions(false)}
          storyField={AllowedStoryFields.SOCIAL_SHARE}
          characterLimit={SOCIAL_SHARE_CHAR_LIMIT}
          onSuggestionSelect={(suggestion: string) =>
            updateSocialCard(socialCardToDisplay.cardId, "postMessage", suggestion)
          }
          currentFieldValue={socialCardToDisplay.postMessage}
          onGeneratingSuggestion={(generatingSuggestion: boolean) => setGeneratingSuggestion(generatingSuggestion)}
          onSuggestionsChange={(suggestions: string[]) => {
            if (isEmpty(socialCardToDisplay.postMessage) && suggestions.length > 0) {
              updateSocialCard(socialCardToDisplay.cardId, "postMessage", suggestions[0]);
            }
          }}>
          <TextAreaWithCount
            label={t("story-editor.inspector.social-share-message")}
            placeholder={t("story-editor.inspector.enter-publish-message")}
            value={socialCardToDisplay.postMessage}
            maxValue={SOCIAL_SHARE_CHAR_LIMIT}
            onChange={(message) => updateSocialCard(socialCardToDisplay.cardId, "postMessage", message)}
            disabled={isStoryLocked}
          />
          {isGenerateSocialShareEnabled && (
            <AiGenerateButton
              classname={cx("social-share-message__ai-generate-btn")}
              disabled={isStoryLocked}
              loading={generatingSuggestion}
              onClick={() => setShowAiSuggestions(!showAiSuggestions)}
            />
          )}
        </AiSuggestions>
      </div>
      {socialAccounts && socialAccounts.length > 0 && (
        <div className={styles["social-card-social-accounts"]}>
          {socialAccounts
            .filter((account: SocialAccountType) => account["is-active"])
            .map((account: SocialAccountType) => (
              <SocialAccount
                key={account["account-id"]}
                account={account}
                isAccountChecked={isAccountChecked}
                toggleSocialAccount={toggleSocialAccount}
                socialCard={socialCardToDisplay}
                socialCards={socialCards}
                isPosted={socialCardToDisplay.isPosted}
                isStoryLocked={isStoryLocked}
              />
            ))}
        </div>
      )}

      <SocialCardSchedule
        socialCard={socialCardToDisplay}
        updateSocialCard={updateSocialCard}
        isStoryLocked={isStoryLocked}
      />
    </React.Fragment>
  );
};

const SocialNotifications: React.SFC<Props> = ({
  story,
  socialAccounts,
  socialCards,
  socialCardsToDisplay,
  socialCardsHistory,
  updateSocialCard,
  toggleSocialAccount,
  deleteSocialCard,
  addSocialCard,
  addSocialHistory,
  enablePreviousSocialNotificationsBtn,
  errors,
  fieldSpecs,
  onChange,
  disabled,
  isStoryLocked,
  isGenerateSocialShareEnabled
}) => {
  return (
    <React.Fragment>
      {!get(fieldSpecs, ["summary", "validations", "hidden"]) && (
        <Summary
          value={story["summary"]}
          spec={fieldSpecs["summary"]}
          onChange={onChange("summary")}
          errors={errors && errors["summary"]}
          disabled={disabled}
        />
      )}
      <div className={styles["social-cards"]}>
        {story["story-content-id"] !== "new" && (
          <Button type="secondary" onClick={() => addSocialCard(story["story-content-id"])} disabled={disabled}>
            {t("story-editor.inspector.add-social-card")}
          </Button>
        )}

        {socialCardsToDisplay &&
          socialCardsToDisplay.map((socialCardToDisplay: SocialCard, index: number) => (
            <div className={styles["social-card-container"]} key={`social-card-${index}`}>
              <SocialCardsToDisplay
                socialCardToDisplay={socialCardToDisplay}
                deleteSocialCard={deleteSocialCard}
                story={story}
                updateSocialCard={updateSocialCard}
                socialAccounts={socialAccounts}
                toggleSocialAccount={toggleSocialAccount}
                socialCards={socialCards}
                isStoryLocked={isStoryLocked}
                isGenerateSocialShareEnabled={isGenerateSocialShareEnabled}
              />
            </div>
          ))}
        {story["story-content-id"] === "new" && <span>{t("story-editor.inspector.save-story-to-add-card")}</span>}
      </div>

      <div className={styles["social-cards"]}>
        {story["story-content-id"] !== "new" && (
          <Button
            type="secondary"
            onClick={() => addSocialHistory(story["story-content-id"])}
            disabled={enablePreviousSocialNotificationsBtn}>
            {t("story-editor.inspector.show-previous-social-notifications")}
          </Button>
        )}
        <div>
          {socialCardsHistory &&
            socialCardsHistory.map((socialCardHistory: SocialCard, index: number) => (
              <div className={styles["social-card-container"]} key={`social-card-history${index}`}>
                <SocialNotificationsHistory
                  socialAccounts={socialAccounts}
                  socialCardHistory={socialCardHistory}
                  socialCards={socialCards}
                  isPosted={socialCardHistory.isPosted}
                  toggleSocialAccount={toggleSocialAccount}
                  isStoryLocked={isStoryLocked}
                />
              </div>
            ))}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    story: state.storyEditor.story,
    fieldSpecs: state.storyEditor.storyTemplateFields,
    socialAccounts: state.config["social-share-accounts"],
    socialCards: selectSocialCardsNotScheduled(state),
    socialCardsHistory: selectSocialCardsHistory(state),
    enablePreviousSocialNotificationsBtn: isPreviousSocialNotificationsBtnEnabled(state),
    errors: state.storyEditor.ui.errors.inspector,
    socialCardsToDisplay: selectSocialCardsToDisplay(state),
    isStoryLocked: state.storyEditor.ui.isStoryLocked,
    isGenerateSocialShareEnabled: get(
      state.config,
      ["ai-content-generation", "story", AllowedStoryFields.SOCIAL_SHARE],
      false
    )
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    onChange: (key) => (value: string) => dispatch({ type: actions.UPDATE_STORY, payload: { key, value } }),
    updateSocialCard: (cardId, key, value) =>
      dispatch({ type: actions.UPDATE_STORY_SOCIAL_CARD, payload: { cardId, key, value } }),
    toggleSocialAccount: (cardId, accountId, accountProvider) =>
      dispatch({ type: actions.UPDATE_STORY_SOCIAL_CARD_ACCOUNT, payload: { cardId, accountId, accountProvider } }),
    addSocialCard: (storyId) => dispatch({ type: actions.ADD_STORY_SOCIAL_CARD, payload: { storyId } }),
    addSocialHistory: (storyId) => dispatch(addSocialNotificationsHistoryAction(storyId)),
    deleteSocialCard: (cardId, contentId) => dispatch(deleteSocialCardAction(cardId, contentId))
  };
};

export { SocialNotifications, SocialCardsToDisplay, SocialNotificationsHistory };

export default connect(mapStateToProps, mapDispatchToProps)(SocialNotifications);
