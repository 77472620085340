/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import { connect } from "react-redux";
import { H6, H2, H3, H4, H5 } from "components/icons/formatting-toolbar";
import { PartialAppState } from "pages/story-editor/state";
import styles from "./headings.module.css";

const cx = classnames.bind(styles);

const selectIconSize = (isDesktopSizeViewport: boolean) => {
  return {
    width: isDesktopSizeViewport ? "24" : "20",
    height: isDesktopSizeViewport ? "24" : "20"
  };
};

interface StateProps {
  isDesktopSizeViewport: boolean;
}

interface Props {
  setHeading: (e: React.MouseEvent, level: number) => void;
}

const Headings: React.SFC<Props & StateProps> = ({ setHeading, isDesktopSizeViewport }) => {
  const iconSize = selectIconSize(isDesktopSizeViewport);
  return (
    <ul
      className={cx("format-toolbar-submenu-wrapper", "format-toolbar-headings-submenu")}
      data-test-id="format-toolbar-headings-submenu">
      <li
        className={styles["format-toolbar-submenu-item"]}
        onMouseDown={(e) => setHeading(e, 2)}
        data-test-id="format-toolbar-headings-submenu-item-h2">
        <H2 {...iconSize} />
      </li>
      <li
        className={styles["format-toolbar-submenu-item"]}
        onMouseDown={(e) => setHeading(e, 3)}
        data-test-id="format-toolbar-headings-submenu-item-h3">
        <H3 {...iconSize} />
      </li>
      <li
        className={styles["format-toolbar-submenu-item"]}
        onMouseDown={(e) => setHeading(e, 4)}
        data-test-id="format-toolbar-headings-submenu-item-h4">
        <H4 {...iconSize} />
      </li>
      <li
        className={styles["format-toolbar-submenu-item"]}
        onMouseDown={(e) => setHeading(e, 5)}
        data-test-id="format-toolbar-headings-submenu-item-h5">
        <H5 {...iconSize} />
      </li>
      <li
        className={styles["format-toolbar-submenu-item"]}
        onMouseDown={(e) => setHeading(e, 6)}
        data-test-id="format-toolbar-headings-submenu-item-h6">
        <H6 {...iconSize} />
      </li>
    </ul>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    isDesktopSizeViewport: state.viewport.isDesktopSizeViewport
  };
};

export default connect(mapStateToProps, () => {})(Headings);
