/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import produce from "immer";

import { INITIAL_BREAKING_NEWS_STATE, INITIAL_BREAKING_NEWS } from "./state";
import actions, { ActionTypes } from "./actions";
import { State } from "./state";

const reducer = (state: State = INITIAL_BREAKING_NEWS_STATE, action: ActionTypes) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case actions.FETCH_SOCIAL_ACCOUNTS:
        draft.socialAccounts = action.payload.socialAccounts;
        draft.isSocialAccountsAvailable = action.payload.isSocialAccountsAvailable;
        break;
      case actions.INITIALIZE_NEW_BREAKING_NEWS:
        draft.breakingNews = action.payload.breakingNews;
        draft.ui.linkType = action.payload.linkType;
        break;
      case actions.UPDATE_BREAKING_NEWS:
        draft.breakingNews = action.payload.breakingNews;
        break;
      case actions.SET_LINK_TYPE:
        draft.ui.linkType = action.payload.linkType;
        draft.breakingNews = action.payload.breakingNews;
        break;
      case actions.SHOW_SOCIAL_ACCOUNTS:
        draft.ui.showSocialAccounts = action.payload.showSocialAccounts;
        break;
      case actions.UPDATE_SOCIAL_ACCOUNTS:
        draft.socialAccounts = action.payload.socialAccounts;
        break;
      case actions.UPDATE_STORY_TEMPLATE_FIELDS:
        draft.app.storyTemplateFields = action.payload.storyTemplateFields;
        break;
      case actions.SAVE_STORY_TEMPLATE_FIELDS:
        draft.app.savedStoryTemplateFields = action.payload.storyTemplateFields;
        break;
      case actions.ENABLE_BREAKING_NEWS_PUBLISH:
        draft.ui.enableBreakingNewsPublish = action.payload.enableBreakingNewsPublish;
        break;
      case actions.SAVE_BREAKING_NEWS:
        draft.ui.savingBreakingNews = true;
        break;
      case actions.BREAKING_NEWS_SAVE_SUCCESS:
        draft.ui.savingBreakingNews = false;
        draft.app.validationErrors = {};
        break;
      case actions.BREAKING_NEWS_SAVE_ERROR:
        draft.app.validationErrors = action.payload.validationErrors;
        draft.ui.savingBreakingNews = false;
        break;
      case actions.LOAD_BREAKING_NEWS_STORY:
        draft.ui.loader = { loading: true, error: null };
        break;
      case actions.BREAKING_NEWS_STORY_LOAD_SUCCESS:
        draft.breakingNewsStory = action.payload.breakingNewsStory;
        draft.breakingNews = action.payload.breakingNews;
        draft.ui.loader = { loading: false, error: null };
        break;
      case actions.BREAKING_NEWS_STORY_LOAD_ERROR:
        draft.ui.loader = { loading: false, error: action.payload.error };
        break;
      case actions.CLEAN_UP_BREAKING_NEWS_STATE:
        draft.breakingNews = INITIAL_BREAKING_NEWS;
        draft.app = INITIAL_BREAKING_NEWS_STATE.app;
        draft.breakingNewsStory = null;
        draft.isSocialAccountsAvailable = false;
        draft.socialAccounts = [];
        draft.ui = INITIAL_BREAKING_NEWS_STATE.ui;
        break;
      default:
        break;
    }
  });
};

export default reducer;
