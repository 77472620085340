/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const BREAKING_NEWS = "/breaking-news";
export const NEW_BREAKING_NEWS = "/breaking-news/new";
export const EDIT_BREAKING_NEWS = "/breaking-news/:id";
export const BREAKING_NEWS_ADVANCED_OPTIONS = "/breaking-news/new/advanced-options";
