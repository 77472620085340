/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { ContextMenuList, ContextMenuItem } from "components/context-menu/context-menu";
import { AddNewButton } from "./add-new-button";

import styles from "./add-new-menu.module.css";

interface AddNewMenuWithCustomActionsProps {
  title: string;
  labelPadding: number;
  actions: Array<{
    title: string;
    callback: () => void;
  }>;
}

const AddNewMenuWithCustomActions: React.SFC<AddNewMenuWithCustomActionsProps> = ({ title, labelPadding, actions }) => {
  return (
    <AddNewButton title={title} labelPadding={labelPadding}>
      <div id="add-new" className={styles["add-new-menu"]} data-test-id="add-new-menu-with-custom-options">
        <ContextMenuList>
          {actions.map((action, index) => (
            <ContextMenuItem label={action.title} onClick={action.callback} key={index} />
          ))}
        </ContextMenuList>
      </div>
    </AddNewButton>
  );
};

export default AddNewMenuWithCustomActions;
