/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import TextField from "components/text-field/text-field";
import PasswordField from "components/password-field/password-field";
import { isClientValidationError } from "utils/validation.utils";
import { get } from "lodash";
import "./login-fields.css";
interface Props {
  username: string;
  password: string;
  formId?: string;
  isSignInDisabled: boolean;
  submitData: () => void;
  onChange: (key: string, value: string) => void;
  error: Error;
}
interface ValidateUsername {
  username: string;
}
interface ValidatePassword {
  password: string;
}

const LoginFields: React.SFC<Props> = ({
  username,
  password,
  formId,
  isSignInDisabled,
  submitData,
  onChange,
  error
}) => {
  return (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault();
        !isSignInDisabled && submitData();
      }}>
      <TextField
        label={t("loginPage.form.username_label")}
        value={username}
        onChange={(text) => onChange("username", text)}
        errorMessage={
          isClientValidationError<ValidateUsername>(error) ? get(error, ["errors", "username", "0"]) : undefined
        }
      />
      <PasswordField
        label={t("loginPage.form.password_label")}
        onChange={(password) => onChange("password", password)}
        value={password}
        errorMessage={
          isClientValidationError<ValidatePassword>(error) ? get(error, ["errors", "password", "0"]) : undefined
        }
        classname="login-fields-password-field"
      />
    </form>
  );
};

export default LoginFields;
