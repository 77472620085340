/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import User from "components/icons/user";
import Clock from "components/icons/clock";
import { formattedDate } from "utils";
import NoContentCard from "components/no-content-card/no-content-card";
import File from "components/icons/file";
import { isEmpty } from "lodash";
import { t } from "i18n";
import { SearchedStory } from "api/search";

import styles from "./story-list.module.css";
import { StoryId } from "api/primitive-types";

interface Props {
  storyId: StoryId;
  storyList: SearchedStory[];
  searchTerm: string;
  onStorySelect: (storyId: StoryId, importStoryId: StoryId) => void;
}

const StoryList: React.SFC<Props> = ({ storyId, storyList, searchTerm, onStorySelect }) => (
  <div className={styles["story-list-container"]}>
    {searchTerm.length !== 0 && isEmpty(storyList) && (
      <NoContentCard>
        <File />
        <div className={styles["no-content-message"]}>{t("story-editor.inspector.no-story-found")}</div>
      </NoContentCard>
    )}
    {storyList.map((story) => (
      <div
        className={styles["story-list-item"]}
        onClick={() => onStorySelect(storyId, story.id)}
        key={story.id}
        data-test-id="story-list-item">
        <h3
          className={styles["story-list-headline"]}
          data-test-id="story-list-headline"
          dangerouslySetInnerHTML={{
            __html: story["highlighted-headline"] || story.headline
          }}
        />
        {!story["highlighted-headline"] && (
          <div
            className={styles["story-list-text"]}
            data-test-id="story-list-text"
            dangerouslySetInnerHTML={{
              __html: story["highlighted-text"] || ""
            }}
          />
        )}
        <div className={styles["story-list-details"]} data-test-id="story-list-details">
          <div className={styles["story-authors"]} data-test-id="story-authors">
            <User />
            <span
              dangerouslySetInnerHTML={{
                __html: story["author-name"]
              }}
              data-test-id="author-name"
            />
          </div>
          <div className={styles["story-time-wrapper"]} data-test-id="story-time-wrapper">
            <Clock />
            <time className={styles["story-time"]}>{formattedDate(story["updated-at"])}</time>
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default StoryList;
