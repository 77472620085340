/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface Props {
  width?: number | string;
  height?: number | string;
  fill?: string;
}

const EditNow = ({ width = "24", height = "24", fill = "#000000" }: Props) => (
  <svg width={width} height={height} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Edit</title>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Edit">
        <polygon id="Path" points="0 0 20 0 20 20 0 20"></polygon>
        <path
          d="M2.5,14.375 L2.5,17.5 L5.625,17.5 L14.8416667,8.28333333 L11.7166667,5.15833333 L2.5,14.375 Z M4.93333333,15.8333333 L4.16666667,15.8333333 L4.16666667,15.0666667 L11.7166667,7.51666667 L12.4833333,8.28333333 L4.93333333,15.8333333 Z M17.2583333,4.69166667 L15.3083333,2.74166667 C15.1416667,2.575 14.9333333,2.5 14.7166667,2.5 C14.5,2.5 14.2916667,2.58333333 14.1333333,2.74166667 L12.6083333,4.26666667 L15.7333333,7.39166667 L17.2583333,5.86666667 C17.5833333,5.54166667 17.5833333,5.01666667 17.2583333,4.69166667 Z"
          id="Shape"
          fill={fill}
          fillRule="nonzero"></path>
      </g>
    </g>
  </svg>
);

export default EditNow;
