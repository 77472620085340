/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { StoryTemplate } from "api/route-data/story-route-data";
import { pick, uniqBy } from "lodash";
import React from "react";
import Select from "components/select/select";
import Breadcrumbs from "components/breadcrumbs/breadcrumbs";
import { t } from "i18n";
import { generateHash } from "utils";

export type StoryTemplateSelectorOption = {
  identifier: number;
  name: string;
  slug: string;
  "ancestor-ids": Array<number>;
};

export type StoryTemplateSelectorProps = {
  value: StoryTemplateSelectorOption[];
  storyTemplates: StoryTemplateSelectorOption[];
  onChange: (storyTemplates: StoryTemplateSelectorOption[]) => void;
  disabled?: boolean;
};

export const storyTemplateToSelectorOptions = (storyTemplates: StoryTemplate[]): StoryTemplateSelectorOption[] => {
  return uniqBy(storyTemplates, "slug")
    .map((template) => pick(template, ["name", "slug"]))
    .map((template) => ({
      ...template,
      "ancestor-ids": [],
      identifier: generateHash(template.slug)
    }));
};

export const templateSlugsToTemplateSelectorOptions = (
  selectedTemplateSlugs: string[],
  storyTemplates: StoryTemplate[]
): StoryTemplateSelectorOption[] => {
  return storyTemplateToSelectorOptions(storyTemplates).filter((template) =>
    selectedTemplateSlugs.includes(template.slug)
  );
};

const StoryTemplateSelector: React.FC<StoryTemplateSelectorProps> = ({
  value,
  storyTemplates,
  onChange,
  disabled = false
}) => {
  return (
    <Select
      label={t("settings.configure.story_templates")}
      name="story template"
      value={value}
      options={storyTemplates}
      onChange={(value: Array<StoryTemplateSelectorOption>) => onChange(value)}
      getOptionLabel={(template) => template.name}
      getOptionValue={(template) => template.slug}
      formatOptionLabel={(template) => (
        <>
          {template.name}
          <Breadcrumbs
            id={template.identifier}
            crumbs={storyTemplates}
            getCrumbLabel={(template: any) => template.name}
          />
        </>
      )}
      isMulti={true}
      isDisabled={disabled}
    />
  );
};

export default StoryTemplateSelector;
