/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import wrapPage from "containers/page/page";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AnyAction, compose } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { match, RouteComponentProps } from "react-router";
import { t } from "i18n";
import { navigate } from "utils/routes.utils";
import ContentHeader from "pages/settings/components/content-header/content-header";
import Inspector from "./components/inspector/inspector";
import { LoaderState } from "behaviors/loader/state";
import {
  IntegrationListByType,
  IntegrationsPageInspectorType,
  IntegrationType,
  PartialAppState,
  Publisher,
  Tab
} from "./state";
import { cancelSaveIntegration, loadPublisherIntegrations, loadPublisherSettings } from "./async-action-creators";
import { IntegrationConfig, Integrations as PublisherIntegrations } from "api/integrations";
import { INTEGRATION_NEW_PATH, INTEGRATION_SETTINGS, INTEGRATIONS_LISTING_PATH } from "./routes";
import { ScrollTabList } from "@quintype/em/components/tabs";
import { SelectTabs } from "pages/workspace/components/select-tabs/select-tabs";
import Button from "components/button/button";
import {
  setIntegrationTypeAction,
  startLoadingWithNoErrorAction,
  stopLoadingWithNoErrorAction,
  updateIntegrationListByTypeAction
} from "./action-creators";
import IntegrationsList from "pages/settings/pages/integrations/components/integrations-list/integration-list";
import classnames from "classnames/bind";
import styles from "./integrations.module.css";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import Loader from "pages/settings/pages/integrations/loader";

const cx = classnames.bind(styles);

interface StateProps {
  loader: LoaderState;
  publisherIntegrations: IntegrationConfig[];
  title: string;
  isDesktopSizeViewport: boolean;
  publisherSettings: Publisher;
  integrationListByType: IntegrationListByType;
}

interface DispatchProps {
  loadPublisherIntegrations: () => void;
  openAddIntegrationInspector: (type: IntegrationType) => void;
  switchTab: (integrationType: IntegrationType) => void;
  openIntegrationSettings: (type: IntegrationType) => void;
  closeInspector: (type: IntegrationType) => void;
  loadPublisherSettings: () => void;
  updateIntegrationListByTypeAction: (integrationsByType: IntegrationListByType) => void;
  setIntegrationType: (type: IntegrationType) => void;
  startLoading: () => void;
  stopLoading: () => void;
}

interface OwnProps {
  match: match<{ tabSlug: IntegrationType }>;
  isBannerPresent: boolean;
  inspectorType: IntegrationsPageInspectorType;
  integrationType: IntegrationType;
  integrationName: PublisherIntegrations;
}

type Props = RouteComponentProps<any> & StateProps & DispatchProps & OwnProps;

const getAllTabs = () => {
  return [
    { value: IntegrationType.ALL, label: t("integrations.type.all") },
    { value: IntegrationType.PUSH_NOTIFICATION, label: t("integrations.type.push-notification") },
    { value: IntegrationType.SOCIAL, label: t("integrations.type.social") },
    { value: IntegrationType.MEDIA, label: t("integrations.type.media") },
    { value: IntegrationType.TASK_MANAGEMENT, label: t("integrations.type.task-management") },
    { value: IntegrationType.ANALYTICS, label: t("integrations.type.analytics") },
    { value: IntegrationType.OTHERS, label: t("integrations.type.others") }
  ];
};

const createIntegrationTypeMap = (publisherIntegrations: IntegrationConfig[]) => {
  const integrationTypeMap: IntegrationListByType = {};
  for (const integration of publisherIntegrations) {
    const integrationType = integration.value.type;
    if (!integrationTypeMap[integrationType]) {
      integrationTypeMap[integrationType] = [];
    }
    integrationTypeMap[integrationType].push(integration);
  }
  integrationTypeMap[IntegrationType.ALL] = publisherIntegrations;
  return integrationTypeMap;
};

export const Integrations: React.FC<Props> = ({
  publisherIntegrations,
  isDesktopSizeViewport,
  switchTab,
  openIntegrationSettings,
  loadPublisherIntegrations,
  loadPublisherSettings,
  inspectorType,
  integrationType,
  integrationName,
  openAddIntegrationInspector,
  closeInspector,
  updateIntegrationListByTypeAction,
  setIntegrationType,
  loader,
  startLoading,
  stopLoading
}) => {
  const [allTabs, setAllTabs] = useState<Tab[]>([]);

  useEffect(() => {
    startLoading();
    loadPublisherIntegrations();
    loadPublisherSettings();
    setAllTabs(getAllTabs());
  }, [loadPublisherIntegrations, loadPublisherSettings, startLoading]);

  useEffect(() => {
    if (publisherIntegrations.length > 0) {
      const integrationTypeMap = createIntegrationTypeMap(publisherIntegrations);
      updateIntegrationListByTypeAction(integrationTypeMap);
      stopLoading();
    }
  }, [publisherIntegrations, updateIntegrationListByTypeAction, stopLoading]);

  useEffect(() => {
    setIntegrationType && setIntegrationType(integrationType);
  }, [setIntegrationType, integrationType]);

  return (
    <LoaderWrapper loader={loader} component={Loader} className={"integrations-progress-main-area"}>
      <section className={styles["settings-integrations-page-container"]}>
        <main className={cx("settings-integrations-page-container", "integrations-page")}>
          <ContentHeader title={t("integrations.title")}>
            {integrationType === IntegrationType.PUSH_NOTIFICATION && (
              <Button
                classname={styles["integrations-push-notification-settings"]}
                onClick={() => openIntegrationSettings(integrationType)}
                type={"secondary"}>
                {t("common.settings")}
              </Button>
            )}
            <Button
              onClick={() => openAddIntegrationInspector(integrationType)}
              type={"secondary"}
              classname={styles["integrations-add-new-integration"]}>
              {t("integrations.add_new_integration")}
            </Button>
          </ContentHeader>
          <div className={styles["integrations-tabs"]}>
            {isDesktopSizeViewport ? (
              <ScrollTabList
                tabs={allTabs}
                value={integrationType}
                onChange={(value: IntegrationType) => switchTab(value)}
              />
            ) : (
              <SelectTabs
                options={allTabs}
                value={integrationType}
                onChange={(value) => switchTab(value as IntegrationType)}
                data-test-id="select-tabs"
              />
            )}
          </div>
          <IntegrationsList />
        </main>
        {publisherIntegrations && publisherIntegrations.length > 0 && (
          <Inspector
            inspectorType={inspectorType}
            integrationName={integrationName}
            onClose={() => closeInspector(integrationType)}
          />
        )}
      </section>
    </LoaderWrapper>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    loader: state.integrations.ui.main,
    publisherIntegrations: state.integrations.app.publisherIntegrations,
    title: "integrations",
    isDesktopSizeViewport: state.viewport.isDesktopSizeViewport,
    publisherSettings: state.integrations.app.publisher,
    integrationListByType: state.integrations.app.integrationListByType
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    loadPublisherIntegrations: () => dispatch(loadPublisherIntegrations()),
    openIntegrationSettings: (type: IntegrationType) => dispatch(navigate(INTEGRATION_SETTINGS, { type })),
    openAddIntegrationInspector: (type: IntegrationType) => dispatch(navigate(INTEGRATION_NEW_PATH, { type })),
    switchTab: (type: IntegrationType) => dispatch(navigate(INTEGRATIONS_LISTING_PATH, { type })),
    closeInspector: (type: IntegrationType) => {
      dispatch(navigate(INTEGRATIONS_LISTING_PATH, { type }));
      dispatch(cancelSaveIntegration());
    },
    loadPublisherSettings: () => dispatch(loadPublisherSettings()),
    updateIntegrationListByTypeAction: (integrationsByType: IntegrationListByType) =>
      dispatch(updateIntegrationListByTypeAction(integrationsByType)),
    setIntegrationType: (type: IntegrationType) => dispatch(setIntegrationTypeAction(type)),
    startLoading: () => dispatch(startLoadingWithNoErrorAction()),
    stopLoading: () => dispatch(stopLoadingWithNoErrorAction())
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), wrapPage())(Integrations);
