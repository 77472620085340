/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const COLLECTIONS_PATH = "/content/collections";
export const COLLECTIONS_PUBLISH_PATH = "/content/collections/:collectionId/publish";
export const COLLECTIONS_SELECTED_COLLECTION_PATH = "/content/collections/:collectionId";
export const COLLECTIONS_ALL_ITEMS_PATH = "/content/collections/:collectionId/all-items";
export const COLLECTIONS_CONTENT_SETTINGS_PATH = "/content/collections/:collectionId/settings/content";
export const COLLECTIONS_CONTENT_SETTINGS_NEW_PATH = "/content/collections/new/settings/content";
export const COLLECTIONS_METADATA_SETTINGS_PATH = "/content/collections/:collectionId/settings/metadata";
export const COLLECTIONS_RULES_SETTINGS_PATH = "/content/collections/:collectionId/settings/rules";
export const COLLECTIONS_CONTENT_MEDIA_GALLERY_PATH = "/content/collections/:collectionId/settings/content/gallery";
export const COLLECTIONS_CONTENT_IMAGE_EDIT_PATH =
  "/content/collections/:collectionId/settings/content/image/:mediaKey";
export const COLLECTIONS_DELETE_PATH = "/content/collections/:collectionId/delete";
export const COLLECTIONS_TABS_PATH = "/content/collections#:tab";
export const COLLECTIONS_PREVIEW_PATH = "/content/collections/:collectionId/preview/:previewId";
