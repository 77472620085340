/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { t } from "i18n";
import Spacer from "components/spacer/spacer";
import ExclamationCircle from "components/icons/exclamation-circle";
import styles from "./use-desktop.module.css";

interface Props {
  label?: string;
  hint?: string;
  isDesktopSizeViewport: boolean;
  children: React.ReactNode;
  classname?: string;
}

const WrapUseDesktop: React.FC<Props> = ({ label, hint, isDesktopSizeViewport, children, classname }) => {
  return isDesktopSizeViewport ? (
    <>{children}</>
  ) : (
    <div className={classname} data-test-id="use-desktop">
      <div className={styles["use-desktop-container"]}>
        <ExclamationCircle width={"48"} height={"48"} color={"var(--mono-3)"} />
        <Spacer token={"xxs"} align={"vertical"} />
        <div className={styles["use-desktop-label"]}>{label || t("components.use-desktop.label")}</div>
        <Spacer token={"xs"} align={"vertical"} />
        <div className={styles["use-desktop-hint"]}>{hint || t("components.use-desktop.hint")}</div>
      </div>
    </div>
  );
};

export default WrapUseDesktop;
