/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";
import styles from "./alternatives.module.css";
import { navigateFn } from "utils/routes.utils";
import { Alternative } from "api/story";
import { ClientId } from "api/primitive-types";
import { CoverImage } from "api/story-collection";
import { Image } from "api/search-media-image";
import { Fields } from "api/route-data/story-route-data";
import { AlternativeInspectorErrors, PartialAppState } from "pages/story-editor/state";
import { actions } from "pages/story-editor/actions";
import { clearStoryEditorMediaAction, editImageAction } from "pages/story-editor/async-action-creators";
import { updateAlternative } from "pages/story-editor/action-creators";
import {
  STORY_EDITOR_ALTERNATIVES_IMAGE_PATH,
  STORY_EDITOR_ALTERNATIVES_IMAGE_EDIT_PATH
} from "pages/story-editor/routes";
import TextAreaWithCount from "components/text-area/text-area-with-count";
import ImageField from "components/image-field/image-field";
import { SavedImage } from "components/image-field/types";
import FieldLabel from "components/field-label/field-label";
import CategoryTitle from "components/category-title/category-title";
import { getErrorMessage } from "pages/story-editor/utils";
import { get, isEmpty } from "lodash";

interface StateProps {
  storyId: string;
  alternative: Alternative | {};
  fieldSpecs: Fields;
}

interface ownProps {
  title: string;
  location: string;
  errors: AlternativeInspectorErrors;
}

interface DispatchProps {
  updateAlternative: (location: string, key: string, value?: CoverImage | SavedImage | string) => void;
  navigate: (path: string, params?: { [key: string]: number | string | boolean }) => void;
  editImage: (storyId: string, image: Image, location: string) => void;
  openPhotoEditor: (image: SavedImage | CoverImage, imageId: string, location: string) => void;
  switchToUploadRoute: (mediaKey: string, imageId: ClientId, location: string) => void;
  setSelectedInspectorImage: (images: Array<CoverImage | SavedImage>) => void;
  setImageUploadStatus: (status: { uploading: boolean }) => void;
}

type Props = StateProps & ownProps & DispatchProps;

export const Alternatives: React.SFC<Props> = ({
  title,
  location,
  storyId,
  alternative,
  fieldSpecs = {},
  updateAlternative,
  editImage,
  openPhotoEditor,
  switchToUploadRoute,
  setSelectedInspectorImage,
  setImageUploadStatus,
  errors
}) => {
  function getValue(location: string, field: string) {
    return alternative[location] && alternative[location]["default"][field];
  }
  const heroImageError = isEmpty(getValue(location, "hero-image"))
    ? getErrorMessage(get(errors, `${location}-default-hero-image-hero-image-s3-key`))
    : null;
  const headlineMaxLength =
    get(fieldSpecs, [`${location}-alternative-headline`, "validations", "hard-limit"]) ||
    fieldSpecs.headline.validations["hard-limit"];
  return (
    <div className={styles["manage-alternatives-container"]}>
      <CategoryTitle title={title} />
      <TextAreaWithCount
        label={t("story-editor.inspector.headline")}
        value={getValue(location, "headline")}
        maxValue={headlineMaxLength}
        onChange={(value) => updateAlternative(location, "headline", value)}
        errorMessage={getErrorMessage(get(errors, `${location}-default-headline`))}
      />
      <FieldLabel label={t("story-editor.inspector.hero-image")} />
      <ImageField
        value={getValue(location, "hero-image")}
        updateFocusPoint={(image: CoverImage | SavedImage) => updateAlternative(location, "hero-image", image)}
        showEditImage={true}
        switchToUploadRoute={(imageId) => switchToUploadRoute(storyId, imageId, location)}
        setSelectedMedia={(images) => setSelectedInspectorImage(images)}
        updateImageUploadStatus={setImageUploadStatus}
        openGallery={() => editImage(storyId, getValue(location, "hero-image"), location)}
        onReplace={() => editImage(storyId, getValue(location, "hero-image"), location)}
        onDelete={() => updateAlternative(location, "hero-image")}
        isPhotoEditor={true}
        openPhotoEditor={(image: CoverImage | SavedImage) => openPhotoEditor(image, "", location)}
        updateImageDetails={(image: CoverImage | SavedImage) => updateAlternative(location, "hero-image", image)}
        fieldSpecs={fieldSpecs}
        error={heroImageError}
      />
    </div>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    storyId: state.storyEditor.story["story-content-id"],
    alternative: state.storyEditor.story.alternative,
    fieldSpecs: state.storyEditor.storyTemplateFields
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  const navigate = navigateFn(dispatch);

  return {
    navigate,
    updateAlternative: (location: string, key: string, value: string) => {
      dispatch(updateAlternative(location, key, value));
    },
    editImage: (storyId: string, image: Image, location: string) =>
      dispatch(editImageAction(storyId, location, image, STORY_EDITOR_ALTERNATIVES_IMAGE_PATH)),
    openPhotoEditor: (image: SavedImage | CoverImage, imageId: string, location: string) =>
      dispatch({
        type: actions.OPEN_PHOTO_EDITOR,
        payload: { image, imageId, imageAs: { type: "alternative-image", subtype: location } }
      }),
    switchToUploadRoute: (storyId, imageId, subtype) => {
      navigate(STORY_EDITOR_ALTERNATIVES_IMAGE_EDIT_PATH, { id: storyId, imageId, subtype });
    },
    setSelectedInspectorImage: (image) => dispatch({ type: actions.SET_IMAGE_FOR_INSPECTOR, payload: { image } }),
    setImageUploadStatus: (status) => {
      dispatch(clearStoryEditorMediaAction());
      dispatch({ type: actions.STORY_EDITOR_IMAGE_UPLOADING, payload: { status: status.uploading } });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Alternatives);
