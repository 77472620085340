/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { INITIAL_LOADER_STATE, LoaderState } from "behaviors/loader/state";
import { Task, MyTasks } from "api/tasks";
import { Image } from "api/settings";
import { Asana } from "api/route-data/route-data";

export interface PartialAppState {
  tasks: State;
  config: {
    asana: Asana;
  };
}

export interface ImageError {
  id: number;
  caption: boolean;
  message?: string;
}

export interface Media {
  selectedMedia: Image[] | [];
  taskImages: Image[] | null;
  isUploading: boolean;
  error: ImageError[];
}
export interface State {
  app: {
    allTasks: MyTasks | null;
    currentTask: Task | null;
    media: Media;
    isLoggedIn: boolean;
  };
  ui: {
    taskLoader: LoaderState;
    myTaskLoader: LoaderState;
    showInspector: boolean;
  };
}

export const INITIAL_MEDIA: Media = {
  selectedMedia: [],
  taskImages: [],
  isUploading: false,
  error: []
};
export const INITIAL_TASK_STATE: State = {
  app: {
    allTasks: null,
    isLoggedIn: true,
    currentTask: null,
    media: INITIAL_MEDIA
  },
  ui: {
    taskLoader: INITIAL_LOADER_STATE,
    myTaskLoader: INITIAL_LOADER_STATE,
    showInspector: false
  }
};
