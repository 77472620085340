/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Summary = ({ width = "24", height = "24", color = "#c6ccd5" }) => (
  <svg
    data-name="Layer 1"
    width={width}
    height={height}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24">
    <path d="M4 5.02h16v1.29H4zM4 11.35h16v1.29H4zM4 17.69h10.93v1.29H4z" />
  </svg>
);

export default Summary;
