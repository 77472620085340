/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import ProseMirrorTextArea from "components/prosemirror-text-area/prosemirror-text-area";
import { TextArea } from "@quintype/em/components/text-area";
import { connect } from "react-redux";

interface Props {
  value: string;
  label?: string;
  onChange: (value: string) => void;
  errorMessage?: string;
  styles?: string;
  placeholder?: string;
  disabled?: boolean;
  classname?: string;
  maxRows?: number;
  hint?: string;
  variant?: string;
  size?: "medium" | "extra-large" | "jumbo";
}

const RichTextField: React.FC<Props & StateProps> = ({
  onChange,
  value,
  label,
  errorMessage,
  isRichTextEnabled,
  placeholder,
  variant,
  size,
  maxRows,
  hint
}) => {
  return (
    <>
      {isRichTextEnabled ? (
        <ProseMirrorTextArea onChange={onChange} value={value} label={label} errorMessage={errorMessage} />
      ) : (
        <TextArea
          onChange={onChange}
          value={value}
          label={label}
          errorMessage={errorMessage}
          placeholder={placeholder}
          variant={variant}
          size={size}
          maxRows={maxRows}
          hint={hint}
        />
      )}
    </>
  );
};

interface StateProps {
  isRichTextEnabled: boolean;
}

const mapStateToProps = (state: any): StateProps => {
  return {
    isRichTextEnabled: state.features["rich-text-image-fields"]
  };
};

export default connect(mapStateToProps)(RichTextField);

export { RichTextField };
