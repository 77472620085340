/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

const intl: any = Intl;

export const getLanguageName = (languageCode: string): string => {
  return new intl.DisplayNames(["en"], { type: "language" }).of(languageCode);
};
