/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { getCardWordCount, getCardCharacterCount } from "pages/story-editor/utils";
import { createSelector } from "reselect";
import { PartialAppState } from "./state";
import { stateOwnProps } from "./story-card/story-card";
import { SocialCard } from "api/social";

const getStoryCard = (state: PartialAppState, props: stateOwnProps) =>
  state.storyEditor.story.cards[props.node.attrs.id];

const getStoryElements = (state: PartialAppState, props: stateOwnProps) => state.storyEditor.story["story-elements"];

export function makeCardWordCountSelector() {
  return createSelector(getStoryCard, getStoryElements, getCardWordCount);
}

export function makeCardCharacterCountSelector() {
  return createSelector(getStoryCard, getStoryElements, getCardCharacterCount);
}

function selectSocialCards(state: PartialAppState) {
  return state.storyEditor.story["social-cards"];
}

function selectSocialHistoryCards(state: PartialAppState) {
  return state.storyEditor.story["social-cards-history"];
}

function getAllSocialCardsNotScheduled(socialCards: SocialCard[]) {
  return (
    socialCards &&
    socialCards
      .filter((card) => card)
      .map((card: SocialCard) => {
        if (card["social-cards"]) return card["social-cards"].filter((card: SocialCard) => !card.isScheduled);
        return [card] || [];
      })
      .reduce((a: SocialCard[], b: SocialCard[]) => a.concat(b), [])
  );
}

export const selectSocialCardsNotScheduled = createSelector(selectSocialCards, getAllSocialCardsNotScheduled);

function selectSocialCardsNotPosted(socialCards: SocialCard[]) {
  return socialCards && socialCards.filter((socialCard) => socialCard.isPosted === false);
}

export const selectSocialCardsToDisplay = createSelector(selectSocialCards, (socialCards: SocialCard[]) =>
  selectSocialCardsNotPosted(getAllSocialCardsNotScheduled(socialCards))
);

function isNoSocialCardsPosted(socialCards: SocialCard[]) {
  return socialCards && socialCards.filter((socialCard) => socialCard.isPosted).length <= 0;
}

export const isPreviousSocialNotificationsBtnEnabled = createSelector(
  selectSocialCards,
  (socialCards: SocialCard[]) => {
    const showBtn = isNoSocialCardsPosted(getAllSocialCardsNotScheduled(socialCards));
    if (showBtn === undefined) return true;
    return showBtn;
  }
);

function selectSocialCardsPostedAndScheduled(socialCards: SocialCard[]) {
  return socialCards && socialCards.filter((socialCard) => socialCard.isPosted || socialCard.isScheduled);
}

export const selectSocialCardsHistory = createSelector(selectSocialHistoryCards, (socialCards: SocialCard[]) =>
  selectSocialCardsPostedAndScheduled(getAllSocialCardsNotScheduled(socialCards))
);

export const selectIsReadOnly = ({
  storyEditor: {
    ui: { isViewingReadOnlyVersion, isStoryLocked }
  }
}: PartialAppState) => isViewingReadOnlyVersion || isStoryLocked;
