/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { signFile, uploadFileDetails } from "helpers/api";

/* TODO Move the interfaces to "helpers/api"
   when it is converted to TS */
interface SignFileResponse {
  AWSAccessKeyId: string;
  "Content-Type": string;
  acl: string;
  action: string;
  key: string;
  policy: string;
  signature: string;
  success_action_status: string;
}

export interface UploadFileResponse {
  success: boolean;
  key?: string;
  url?: string;
  "mime-type"?: string;
}

const uploadFile = (
  signedResponse: { success: boolean; file: File; signature: SignFileResponse; "mime-type": string },
  callback: Function
) => {
  const formData = {
    key: signedResponse.signature["key"],
    "Content-Type": signedResponse.signature["Content-Type"],
    success_action_status: signedResponse.signature["success_action_status"],
    policy: signedResponse.signature["policy"],
    AWSAccessKeyId: signedResponse.signature["AWSAccessKeyId"],
    signature: signedResponse.signature["signature"],
    acl: signedResponse.signature["acl"],
    file: signedResponse.file
  };
  const key = signedResponse.signature.key;

  const successResponse: UploadFileResponse = {
    success: true,
    key: key,
    url: signedResponse.signature.action + key,
    "mime-type": signedResponse["mime-type"]
  };

  uploadFileDetails(signedResponse.signature.action, formData)
    .then((res) => callback(successResponse))
    .catch(() => callback({ success: false }));
};

const s3FileUpload = (file: File, callback: Function) => {
  signFile({ "file-name": file.name, "mime-type": file.type }).then(
    (response: SignFileResponse) => {
      const signedResponse = {
        success: true,
        file: file,
        signature: response,
        "mime-type": file.type
      };
      return uploadFile(signedResponse, callback);
    },
    (error) => callback({ success: false })
  );
};

export { s3FileUpload };
