/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Close from "components/icons/close";
import Chevron from "components/icons/chevron";
import classnames from "classnames/bind";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { goBack, RouterState } from "connected-react-router";

import { setBodyOverflow } from "utils/dom.utils";

import styles from "./inspector.module.css";
import { ThunkDispatch } from "redux-thunk";
import { SliderState } from "store/slider/slider";
import { get } from "lodash";
import ButtonWithIcon from "../button/button-with-icon";

type PartialAppState = {
  config: {
    publisherWideBannerMessage: string | null;
  };
  storyEditor: {
    ui: {
      banner: { message: string } | null;
    };
  };
  slider: SliderState;
};

const cx = classnames.bind(styles);

interface OwnProps {
  title: string;
  isActive: boolean;
  level?: 0 | 1 | 2;
  onClose: () => void;
  variant?: "default" | "wide";
  onActionButtonClick?: () => void;
  actionButtonLabel?: string;
  isActionButtonDisabled?: boolean;
  isBackgroundActionDisabled?: boolean;
  className?: string;
  showActionButton?: boolean;
  reference?: React.RefObject<HTMLElement>;
  disableOverlay?: boolean;
  iconComponent?: any;
}

interface StateProps {
  bannerMessage: string | null;
  storyPageBannerMessage: string | null;
  isSliderWindowOpen: boolean;
  isCurrentWindowIsInSlider: boolean;
}

// TODO: Remove this by making this component dumb.
interface DispatchProps {
  closeSubInspector: () => void;
}

type Props = OwnProps & DispatchProps & StateProps;

class Inspector extends React.Component<Props> {
  reference: React.RefObject<HTMLElement> | null = null;

  static defaultProps: Pick<Props, "variant" | "level"> = {
    variant: "default",
    level: 0
  };

  componentDidMount() {
    if (this.props.isActive) {
      this.open();
    }
    this.reference = this.props.reference || React.createRef<HTMLElement>();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.isActive !== prevProps.isActive) {
      this.props.isActive ? this.open() : setBodyOverflow("auto");
    }
  }

  open = () => {
    if (this.props.level === 0 && !this.props.disableOverlay) {
      setBodyOverflow("hidden");
    }
  };

  close = () => {
    this.props.onClose();
    if (this.props.level === 0 && !this.props.disableOverlay) {
      setBodyOverflow("auto");
    }
  };

  render() {
    const {
      isActive,
      title,
      closeSubInspector,
      onActionButtonClick,
      actionButtonLabel,
      children,
      isActionButtonDisabled,
      variant,
      level,
      isBackgroundActionDisabled,
      bannerMessage,
      storyPageBannerMessage,
      disableOverlay = false,
      className,
      showActionButton = true,
      iconComponent,
      isSliderWindowOpen,
      isCurrentWindowIsInSlider
    } = this.props;

    const classes = cx(
      "inspector",
      { "is-active": isActive },
      { "inspector--wide": variant === "wide" },
      { "inspector--default": variant === "default" },
      { "inspector__level-0": level === 0 },
      { "inspector__level-1": level === 1 },
      { "inspector__level-2": level === 2 },
      { "with-banner": bannerMessage || storyPageBannerMessage },
      { "without-overlay": disableOverlay },
      { "with-slider-open": isSliderWindowOpen },
      { "in-slider": isCurrentWindowIsInSlider },
      className
    );

    const wrapperClasses = cx("inspector-wrapper", {
      "inspector--is-active": isActive,
      "inspector--disable-user-select": !disableOverlay
    });

    return (
      <React.Fragment>
        <div className={isBackgroundActionDisabled ? wrapperClasses : ""}>
          <aside ref={this.reference} className={classes} id="inspector-container" data-test-id="inspector-container">
            <header className={styles["inspector-header"]} data-test-id="inspector-header">
              {level === 0 && (
                <div className={styles["inspector-close"]} data-test-id="inspector-close-btn" onClick={this.close}>
                  <Close />
                </div>
              )}
              {level !== 0 && (
                <div className={styles["inspector-close"]} data-test-id="inspector-close" onClick={closeSubInspector}>
                  <Chevron variant="left" />
                </div>
              )}
              <h3 className={styles["inspector-title"]}>{title}</h3>
              <div className={styles["inspector-action"]} data-test-id="inspector-action">
                {actionButtonLabel && onActionButtonClick && showActionButton && (
                  <ButtonWithIcon
                    type="primary"
                    onClick={onActionButtonClick}
                    disabled={isActionButtonDisabled}
                    testId="inspector-action-btn"
                    icon={iconComponent}
                    dir="right">
                    {actionButtonLabel}
                  </ButtonWithIcon>
                )}
              </div>
            </header>
            <div className={cx("inspector-content", "content", className)} data-test-id="inspector-content">
              {children}
            </div>
          </aside>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    bannerMessage: state.config.publisherWideBannerMessage,
    storyPageBannerMessage: state.storyEditor.ui.banner && state.storyEditor.ui.banner.message,
    isSliderWindowOpen: get(state, ["slider", "sliderWindow", "open"], false),
    isCurrentWindowIsInSlider: get(state, ["slider", "currentWindow", "isInSlider"], false)
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RouterState, void, AnyAction>): DispatchProps => {
  return {
    closeSubInspector: () => dispatch(goBack())
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Inspector);

export { Inspector };
