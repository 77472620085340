/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { t } from "i18n";
import { PUSH_NOTIFICATION_INDEX_PATH } from "../push-notification/routes";
import { COLLECTIONS_PATH } from "pages/collections/routes";
import { STATIC_PAGES_INDEX_PATH } from "../static-pages/routes";
import { FORMS_PATH } from "pages/forms/routes";

const menuItems = ({
  enableCollectionsInSidebar,
  enablePushNotificationInSidebar,
  enableStaticPageInSidebar,
  enableFormsInSidebar
}: {
  enableCollectionsInSidebar?: boolean;
  enablePushNotificationInSidebar?: boolean;
  enableStaticPageInSidebar?: boolean;
  enableFormsInSidebar?: boolean;
}) => {
  let items: Array<{ label: string; path: string } | any> = [];
  if (enableCollectionsInSidebar) {
    items = [...items, { label: t("collections.collections"), path: COLLECTIONS_PATH }];
  }
  if (enablePushNotificationInSidebar) {
    items = [...items, { label: t("push-notification.title"), path: PUSH_NOTIFICATION_INDEX_PATH }];
  }
  if (enableStaticPageInSidebar) {
    items = [...items, { label: t("staticPages.title"), path: STATIC_PAGES_INDEX_PATH }];
  }
  if (enableFormsInSidebar) {
    items = [...items, { label: t("forms.dashboard.title"), path: FORMS_PATH }];
  }
  return items;
};

export default menuItems;
