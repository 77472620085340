/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { liftListItem, wrapInList } from "prosemirror-schema-list";
import { DOMSerializer, Node, NodeType, Schema, Slice, Fragment, DOMParser, ResolvedPos } from "prosemirror-model";
import { EditorState, NodeSelection, TextSelection, Transaction } from "prosemirror-state";
import { canSplit } from "prosemirror-transform";
import {
  findNodeClosestToPos,
  findParentNodeClosestToPos,
  findParentNodeClosestToPosByAttrs,
  findParentNodeOfType
} from "../operations/find";
import { selectElementText, setTextSelectionFromTransaction } from "../operations/selection";
import { deleteFromTransaction } from "../operations/story-elements/delete";
import copyToClipboard from "helpers/copy-to-clipboard";
import { splitElementAction } from "../async-action-creators";
import { StoryElementType } from "api/story";
import { store } from "store";
import { schema } from "./schema";
import { EditorView } from "prosemirror-view";
import { isTextContentPasteableInStoryElement } from "./utils";
import { actions } from "../actions";

export const nop = () => true;

export function toggleList(schema: Schema, listType: NodeType) {
  function isList(node: Node) {
    return node.type === schema.nodes.bullet_list || node.type === schema.nodes.ordered_list;
  }

  const wrap = wrapInList(listType);

  return (state: EditorState, dispatch: (tr: Transaction<Schema>) => void) => {
    const bulletListParentDetails = findParentNodeClosestToPos(state, "bullet_list");
    const orderedListParentDetails = findParentNodeClosestToPos(state, "ordered_list");

    const parentNodeDetails = bulletListParentDetails || orderedListParentDetails;
    const parentNode = parentNodeDetails && parentNodeDetails.node;
    const parentNodePosition = parentNodeDetails && parentNodeDetails.pos;

    if (parentNode && parentNodePosition) {
      if (parentNode.type === listType) {
        return wrap(state, dispatch);
      }

      if (isList(parentNode)) {
        const tr = state.tr;
        tr.setNodeMarkup(parentNodePosition, listType);
        if (dispatch) dispatch(tr);
        return true;
      }
    }

    return wrap(state, dispatch);
  };
}

export function selectTextNode(schema: Schema, listType: NodeType) {
  return (state: EditorState, dispatch: (tr: Transaction<Schema>) => void) => {
    const txn = selectElementText(state);
    if (dispatch && txn) dispatch(txn);
    return true;
  };
}

export const handleUndo = () => {
  return (editorState: EditorState, dispatch: (tr: Transaction<Schema>) => void) => {
    store.dispatch({ type: actions.DUMMY_UNDO });
    return false;
  };
};

export const handleRedo = () => {
  return (editorState: EditorState, dispatch: (tr: Transaction<Schema>) => void) => {
    store.dispatch({ type: actions.DUMMY_REDO });
    return false;
  };
};

export const handleDelete = () => {
  return (editorState: EditorState, dispatch: (tr: Transaction<Schema>) => void) => {
    store.dispatch({ type: actions.DUMMY_DELETE });
    const { anchor, head } = editorState.selection,
      tr = editorState.tr;
    if (isCursorAtEndOfLine(tr)) {
      return false;
    }

    if (isBlockOfContentSelected(editorState)) {
      return deleteSelectedContentBlock(tr, anchor, head, editorState, dispatch);
    }

    const newTrn = setTextSelectionFromTransaction(deleteFromTransaction(tr, anchor, anchor + 1), anchor);
    if (dispatch) dispatch(newTrn);
    return true;
  };
};

function isCursorAtEndOfLine(tr) {
  return tr.selection.$anchor.pos === tr.selection.$anchor.end(tr.selection.$anchor.depth);
}

function isBlockOfContentSelected(editorState: EditorState) {
  return !editorState.selection.empty;
}

function deleteSelectedContentBlock(
  tr,
  anchor,
  head,
  editorState: EditorState,
  dispatch: (tr: Transaction<Schema>) => void
) {
  // Detect selection from left to right and right to left.
  const minPosition = anchor > head ? head : anchor;
  const maxPosition = anchor < head ? head : anchor;
  let newTr = setTextSelectionFromTransaction(deleteFromTransaction(tr, minPosition, maxPosition), minPosition);

  const node = findParentNodeClosestToPosByAttrs(editorState, "text");
  // Nodes that are TextBlock like <quote> already return correct anchor so don't need incrementing
  const nodeInsertPos = node && node.start && (node.node.isTextblock ? node.start : node.start + 1);
  if (node && newTr.selection.anchor === nodeInsertPos) {
    const innerNode = newTr.selection.$anchor.nodeAfter;
    if (innerNode && innerNode.type.spec.group === "block")
      newTr = newTr.insert(nodeInsertPos, editorState.schema.node("paragraph"));
    const transactionWithSelection = setTextSelectionFromTransaction(newTr, nodeInsertPos);
    if (dispatch) dispatch(transactionWithSelection);
    return true;
  }
  if (dispatch) dispatch(newTr);
  return true;
}

export const handleBackspace = () => {
  return (editorState: EditorState, dispatch: (tr: Transaction<Schema>) => void) => {
    store.dispatch({ type: actions.DUMMY_BACKSPACE });
    const { anchor, head } = editorState.selection,
      tr = editorState.tr;
    // Beginning of the editor.
    if ((tr.selection.anchor <= 1 || tr.selection.$anchor.parentOffset === 0) && tr.selection.empty) {
      const parentNP =
          findParentNodeOfType(editorState, "bullet_list") || findParentNodeOfType(editorState, "ordered_list"),
        listItemNP = findParentNodeOfType(editorState, "list_item"),
        lift = liftListItem(schema.nodes.list_item);
      if (listItemNP && parentNP) {
        if (listItemNP.pos - parentNP.pos > 1 || anchor - listItemNP.pos > 2) {
          // not first <li> || not first char of <li> -> PM will handle
          return false;
        }
        return lift(editorState, dispatch);
      }
      return false;
    }

    if (isBlockOfContentSelected(editorState)) {
      return deleteSelectedContentBlock(tr, anchor, head, editorState, dispatch);
    }

    //Move the cursor by 2 positions if it is in the beginning of the line. If not
    //delete the text and move the cursor by one position.
    const newTrn = setTextSelectionFromTransaction(deleteFromTransaction(tr, anchor - 1, anchor), anchor - 1);
    if (dispatch) dispatch(newTrn);
    return true;
  };
};

function serializeSelectedContentForClipboard(editorState: EditorState): any {
  const wrapMap = {
    thead: ["table"],
    colgroup: ["table"],
    col: ["table", "colgroup"],
    tr: ["table", "tbody"],
    td: ["table", "tbody", "tr"],
    th: ["table", "tbody", "tr"]
  };

  const { anchor, head } = editorState.selection;
  const slice = editorState.selection.content();
  let content = slice.content;
  let openStart = slice.openStart;
  let openEnd = slice.openEnd;

  while (
    openStart > 1 &&
    openEnd > 1 &&
    content.childCount === 1 &&
    content.firstChild &&
    content.firstChild.childCount === 1
  ) {
    openStart--;
    openEnd--;
    content = content.firstChild.content;
  }

  // Remove parent nodes that cannot be pasted into any story element, only copy
  // pasteable child nodes
  while (
    openStart === 1 &&
    openEnd === 1 &&
    content.childCount === 1 &&
    content.firstChild &&
    !isTextContentPasteableInStoryElement(content.firstChild)
  ) {
    content = content.firstChild.content;
  }

  const schemaSerializer: DOMSerializer<Schema> = DOMSerializer.fromSchema(schema);
  const wrap = document.createElement("div");
  wrap.appendChild(schemaSerializer.serializeFragment(content));

  let firstChild = wrap.firstChild as HTMLElement | null,
    needsWrap;
  while (firstChild && firstChild.nodeType === 1 && (needsWrap = wrapMap[firstChild.nodeName.toLowerCase()])) {
    for (let i = needsWrap.length - 1; i >= 0; i--) {
      const wrapper = document.createElement(needsWrap[i]);
      while (wrap.firstChild) {
        wrapper.appendChild(wrap.firstChild);
      }
      wrap.appendChild(wrapper);
    }
    firstChild = wrap.firstChild as HTMLElement;
  }

  const text = editorState.doc.textBetween(anchor > head ? head : anchor, anchor < head ? head : anchor, "\r\n\r\n");

  return { dom: wrap.innerHTML, text: text };
}

export const handleTextCut = () => {
  return (
    editorState: EditorState,
    dispatch: (tr: Transaction<Schema>) => void,
    view: EditorView,
    e: ClipboardEvent
  ) => {
    store.dispatch({ type: actions.DUMMY_CUT });
    if (editorState.selection.empty) {
      return true;
    }

    const ref = serializeSelectedContentForClipboard(editorState) as any;
    const refDom = ref.dom as HTMLElement;
    const refText = ref.text as string;

    // IE and Edge's clipboard interface is completely broken
    copyToClipboard(refText, refDom, e);

    return handleBackspace()(editorState, dispatch);
  };
};

export const splitElement = () => {
  return (editorState: EditorState, dispatch: (tr: Transaction<Schema>) => void) => {
    const currentNodeType = findNodeClosestToPos(editorState);
    if (currentNodeType.name === "paragraph") {
      const currentStoryElementNP = findParentNodeClosestToPosByAttrs(editorState, "text");
      const cursorPosition = editorState.selection;
      currentStoryElementNP &&
        store.dispatch(splitElementAction(StoryElementType.Text, currentStoryElementNP.node, cursorPosition.head));
      return true;
    }
    return false;
  };
};

export const handleEnter = () => {
  return (editorState: EditorState, dispatch: (tr: Transaction<Schema>) => void) => {
    let ref = editorState.selection;
    let $from = ref.$from;
    let $to = ref.$to;

    if (editorState.selection instanceof NodeSelection) {
      const nodeSelection = ref as NodeSelection;
      if (nodeSelection.node.isBlock) {
        if (!$from.parentOffset || !canSplit(editorState.doc, $from.pos)) {
          return false;
        }

        return true;
      }
    }

    if (!$from.parent.isBlock) {
      return false;
    }

    if (dispatch) {
      let tr = editorState.tr;

      // Enter when text is selected should delete text and add a newline
      if (ref instanceof TextSelection) {
        tr = tr.deleteSelection();
      }

      let atEnd = $to.parentOffset === $to.parent.content.size;
      let currentNodeType = findNodeClosestToPos(editorState);
      const currentStoryElementNP = findParentNodeClosestToPosByAttrs(editorState, "text");

      if (currentStoryElementNP) {
        const parentNode = $from.node(-1);
        // When applying styles to the text in a story element, pressing enter at the end of the
        // text should use the default styling of the parent element.
        if (parentNode) {
          const nodeType = parentNode.contentMatchAt($from.indexAfter(-1)).defaultType;
          if (nodeType) {
            currentNodeType = nodeType as NodeType<any>;
          }
        }
        let types = null as any;
        //If the parent node type is a list item, split the list and move the cursor out of the list
        if (parentNode.type.name === "list_item") {
          tr.split(tr.mapping.map($from.pos), 2, types);
          return false;
        }

        if (!atEnd && canSplit(tr.doc, tr.mapping.map($from.pos), 1, types)) {
          tr.split(tr.mapping.map($from.pos), 1, types);
        } else {
          types = [{ type: currentNodeType }];
          if (canSplit(tr.doc, tr.mapping.map($from.pos), 1, [{ type: currentNodeType }])) {
            // Prosemirror eats up a \n, hence using tr.split that creates a <p> entry.
            tr.split(tr.mapping.map($from.pos), 1, types);
          }
        }

        dispatch(tr);
      }
    }
    return true;
  };
};

/*
The parseFromClipboard function was so far being imported from prosemirror-view.
As of version 1.24.0, it is marked as internal function and can not be imported anymore.

Below code for parseFromClipboard function is copied from clipboard.ts
https://github.com/ProseMirror/prosemirror-view/blob/1e385a1ca99f26b6e9e1baa022d83d43261940e0/src/clipboard.ts#L41

*/

//** parseFromClipboard starts here **//

// Read a slice of content from the clipboard (or drop data).
export function parseFromClipboard(
  view: EditorView,
  text: string,
  html: string | null,
  plainText: boolean,
  $context: ResolvedPos
) {
  store.dispatch({ type: actions.DUMMY_PASTE });
  let inCode = $context.parent.type.spec.code;
  let dom: HTMLElement | undefined, slice: Slice | undefined;
  if (!html && !text) return null;
  let asText = text && (plainText || inCode || !html);
  if (asText) {
    view.someProp("transformPastedText", (f) => {
      text = f(text, inCode || plainText);
    });
    if (inCode)
      return text ? new Slice(Fragment.from(view.state.schema.text(text.replace(/\r\n?/g, "\n"))), 0, 0) : Slice.empty;
    let parsed = view.someProp("clipboardTextParser", (f) => f(text, $context, plainText));
    if (parsed) {
      slice = parsed;
    } else {
      let marks = $context.marks();
      let { schema } = view.state,
        serializer = DOMSerializer.fromSchema(schema);
      dom = document.createElement("div");
      text.split(/(?:\r\n?|\n)+/).forEach((block) => {
        let p = dom!.appendChild(document.createElement("p"));
        if (block) p.appendChild(serializer.serializeNode(schema.text(block, marks)));
      });
    }
  } else {
    view.someProp("transformPastedHTML", (f) => {
      html = f(html!);
    });
    dom = readHTML(html!);
  }

  let contextNode = dom && dom.querySelector("[data-pm-slice]");
  let sliceData = contextNode && /^(\d+) (\d+)(?: -(\d+))? (.*)/.exec(contextNode.getAttribute("data-pm-slice") || "");
  if (sliceData && sliceData[3])
    for (let i = +sliceData[3]; i > 0 && dom!.firstChild; i--) dom = dom!.firstChild as HTMLElement;

  if (!slice) {
    let parser =
      view.someProp("clipboardParser") || view.someProp("domParser") || DOMParser.fromSchema(view.state.schema);
    slice = parser.parseSlice(dom!, {
      preserveWhitespace: !!(asText || sliceData),
      context: $context,
      // @ts-ignore
      ruleFromNode(dom) {
        if (dom.nodeName === "BR" && !dom.nextSibling && dom.parentNode && !inlineParents.test(dom.parentNode.nodeName))
          return { ignore: true };
        return null;
      }
    });
  }
  if (sliceData) {
    slice = addContext(closeSlice(slice, +sliceData[1], +sliceData[2]), sliceData[4]);
  } else {
    // HTML wasn't created by ProseMirror. Make sure top-level siblings are coherent
    slice = Slice.maxOpen(normalizeSiblings(slice.content, $context), true);
    if (slice.openStart || slice.openEnd) {
      let openStart = 0,
        openEnd = 0;
      for (
        let node = slice.content.firstChild;
        openStart < slice.openStart && !node!.type.spec.isolating;
        openStart++, node = node!.firstChild
      ) {}
      for (
        let node = slice.content.lastChild;
        openEnd < slice.openEnd && !node!.type.spec.isolating;
        openEnd++, node = node!.lastChild
      ) {}
      slice = closeSlice(slice, openStart, openEnd);
    }
  }

  view.someProp("transformPasted", (f) => {
    slice = f(slice!);
  });
  return slice;
}

const inlineParents = /^(a|abbr|acronym|b|cite|code|del|em|i|ins|kbd|label|output|q|ruby|s|samp|span|strong|sub|sup|time|u|tt|var)$/i;

// Takes a slice parsed with parseSlice, which means there hasn't been
// any content-expression checking done on the top nodes, tries to
// find a parent node in the current context that might fit the nodes,
// and if successful, rebuilds the slice so that it fits into that parent.
//
// This addresses the problem that Transform.replace expects a
// coherent slice, and will fail to place a set of siblings that don't
// fit anywhere in the schema.
function normalizeSiblings(fragment: Fragment, $context: ResolvedPos) {
  if (fragment.childCount < 2) return fragment;
  for (let d = $context.depth; d >= 0; d--) {
    let parent = $context.node(d);
    let match = parent.contentMatchAt($context.index(d));
    let lastWrap: readonly NodeType[] | undefined,
      result: Node[] | null = [];
    fragment.forEach((node) => {
      if (!result) return;
      let wrap = match.findWrapping(node.type),
        inLast;
      if (!wrap) return (result = null);
      if (
        (inLast =
          result.length && lastWrap!.length && addToSibling(wrap, lastWrap!, node, result[result.length - 1], 0))
      ) {
        result[result.length - 1] = inLast;
      } else {
        if (result.length) result[result.length - 1] = closeRight(result[result.length - 1], lastWrap!.length);
        let wrapped = withWrappers(node, wrap);
        result.push(wrapped);
        match = match.matchType(wrapped.type)!;
        lastWrap = wrap;
      }
      return;
    });
    if (result) return Fragment.from(result);
  }
  return fragment;
}

function withWrappers(node: Node, wrap: readonly NodeType[], from = 0) {
  for (let i = wrap.length - 1; i >= from; i--) node = wrap[i].create(null, Fragment.from(node));
  return node;
}

// Used to group adjacent nodes wrapped in similar parents by
// normalizeSiblings into the same parent node
function addToSibling(
  wrap: readonly NodeType[],
  lastWrap: readonly NodeType[],
  node: Node,
  sibling: Node,
  depth: number
): Node | undefined {
  if (depth < wrap.length && depth < lastWrap.length && wrap[depth] === lastWrap[depth]) {
    let inner = addToSibling(wrap, lastWrap, node, sibling.lastChild!, depth + 1);
    if (inner) return sibling.copy(sibling.content.replaceChild(sibling.childCount - 1, inner));
    let match = sibling.contentMatchAt(sibling.childCount);
    if (match.matchType(depth === wrap.length - 1 ? node.type : wrap[depth + 1]))
      return sibling.copy(sibling.content.append(Fragment.from(withWrappers(node, wrap, depth + 1))));
  }
  return;
}

function closeRight(node: Node, depth: number) {
  if (depth === 0) return node;
  let fragment = node.content.replaceChild(node.childCount - 1, closeRight(node.lastChild!, depth - 1));
  let fill = node.contentMatchAt(node.childCount).fillBefore(Fragment.empty, true)!;
  return node.copy(fragment.append(fill));
}

function closeRange(fragment: Fragment, side: number, from: number, to: number, depth: number, openEnd: number) {
  let node = side < 0 ? fragment.firstChild! : fragment.lastChild!,
    inner = node.content;
  if (depth < to - 1) inner = closeRange(inner, side, from, to, depth + 1, openEnd);
  if (depth >= from)
    inner =
      side < 0
        ? node
            .contentMatchAt(0)!
            .fillBefore(inner, fragment.childCount > 1 || openEnd <= depth)!
            .append(inner)
        : inner.append(node.contentMatchAt(node.childCount)!.fillBefore(Fragment.empty, true)!);
  return fragment.replaceChild(side < 0 ? 0 : fragment.childCount - 1, node.copy(inner));
}

function closeSlice(slice: Slice, openStart: number, openEnd: number) {
  if (openStart < slice.openStart)
    slice = new Slice(
      closeRange(slice.content, -1, openStart, slice.openStart, 0, slice.openEnd),
      openStart,
      slice.openEnd
    );
  if (openEnd < slice.openEnd)
    slice = new Slice(closeRange(slice.content, 1, openEnd, slice.openEnd, 0, 0), slice.openStart, openEnd);
  return slice;
}

// Trick from jQuery -- some elements must be wrapped in other
// elements for innerHTML to work. I.e. if you do `div.innerHTML =
// "<td>..</td>"` the table cells are ignored.
const wrapMap: { [node: string]: string[] } = {
  thead: ["table"],
  tbody: ["table"],
  tfoot: ["table"],
  caption: ["table"],
  colgroup: ["table"],
  col: ["table", "colgroup"],
  tr: ["table", "tbody"],
  td: ["table", "tbody", "tr"],
  th: ["table", "tbody", "tr"]
};

let _detachedDoc: Document | null = null;
function detachedDoc() {
  return _detachedDoc || (_detachedDoc = document.implementation.createHTMLDocument("title"));
}

function readHTML(html: string) {
  let metas = /^(\s*<meta [^>]*>)*/.exec(html);
  if (metas) html = html.slice(metas[0].length);
  let elt = detachedDoc().createElement("div");
  let firstTag = /<([a-z][^>\s]+)/i.exec(html),
    wrap;
  if ((wrap = firstTag && wrapMap[firstTag[1].toLowerCase()]))
    html =
      wrap.map((n) => "<" + n + ">").join("") +
      html +
      wrap
        .map((n) => "</" + n + ">")
        .reverse()
        .join("");
  elt.innerHTML = html;
  if (wrap) for (let i = 0; i < wrap.length; i++) elt = elt.querySelector(wrap[i]) || elt;
  return elt;
}

function addContext(slice: Slice, context: string) {
  if (!slice.size) return slice;
  let schema = slice.content.firstChild!.type.schema,
    array;
  try {
    array = JSON.parse(context);
  } catch (e) {
    return slice;
  }
  let { content, openStart, openEnd } = slice;
  for (let i = array.length - 2; i >= 0; i -= 2) {
    let type = schema.nodes[array[i]];
    if (!type || type.hasRequiredAttrs()) break;
    content = Fragment.from(type.create(array[i + 1], content));
    openStart++;
    openEnd++;
  }
  return new Slice(content, openStart, openEnd);
}

//** parseFromClipboard ends here **//
