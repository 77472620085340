/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import TextField from "components/text-field/text-field";
import { get } from "lodash";
import { AmpConfig, ColorsTypes } from "api/amp-config";
import styles from "./colors-config.module.css";

interface Props {
  ampConfig: AmpConfig;
  saveErrors: Error | null;
  updateConfig: (key: string, changes: ColorsTypes) => void;
}

const ColorsConfig: React.SFC<Props> = ({ ampConfig, saveErrors, updateConfig }) => {
  return (
    <React.Fragment>
      <div className={styles["amp-config-category-details-container"]}>
        <h3 className={styles["amp-config-title"]}>{t("settings.amp.color.title")}</h3>
        <p className={styles["amp-config-description"]}>{t("settings.amp.color.description")}</p>
      </div>
      <div className={styles["amp-config-fields-container"]}>
        <TextField
          label={t("settings.amp.color.primary_color")}
          value={ampConfig.colors.primary}
          onChange={(value) => updateConfig("colors", { ...ampConfig.colors, primary: value })}
          hint="e.g. #FFFFFF"
          errorMessage={get(saveErrors, ["colors", "primary", "code"])}
        />
        <TextField
          label={t("settings.amp.color.secondary_color")}
          value={ampConfig.colors.secondary}
          onChange={(value) => updateConfig("colors", { ...ampConfig.colors, secondary: value })}
          hint="e.g. #FFFFFF"
          errorMessage={get(saveErrors, ["colors", "secondary", "code"])}
        />
        <TextField
          label={t("settings.amp.color.header_background")}
          value={ampConfig.colors["header-background"]}
          onChange={(value) => updateConfig("colors", { ...ampConfig.colors, "header-background": value })}
          hint="e.g. #FFFFFF"
          errorMessage={get(saveErrors, ["colors", "header-background", "code"])}
        />
        <TextField
          label={t("settings.amp.color.footer_background")}
          value={ampConfig.colors["footer-background"]}
          onChange={(value) => updateConfig("colors", { ...ampConfig.colors, "footer-background": value })}
          hint="e.g. #FFFFFF"
          errorMessage={get(saveErrors, ["colors", "footer-background", "code"])}
        />
        <TextField
          label={t("settings.amp.color.footer_text_color")}
          value={ampConfig.colors["footer-text-color"]}
          onChange={(value) => updateConfig("colors", { ...ampConfig.colors, "footer-text-color": value })}
          hint="e.g. #FFFFFF"
          errorMessage={get(saveErrors, ["colors", "footer-text-color", "code"])}
        />
        <TextField
          label={t("settings.amp.color.section_text_color")}
          value={ampConfig.colors["section-text-color"]}
          onChange={(value) => updateConfig("colors", { ...ampConfig.colors, "section-text-color": value })}
          hint="e.g. #FFFFFF"
          errorMessage={get(saveErrors, ["colors", "section-text-color", "code"])}
        />
      </div>
    </React.Fragment>
  );
};

export default ColorsConfig;
