/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState, State } from "../../state";
import { navigate } from "utils/routes.utils";
import { loadTags, changeSpecifiedPageAction } from "../../async-action-creators";
import { Tag } from "api/tag";
import Button from "components/button/button";
import TagsList from "../tags-list/tags-list";
import { TAGS_NEW_PATH, TAG_EDIT_PATH } from "../../routes";
import { t } from "i18n";
import "./tags-container.css";
import TablePagination from "components/table-pagination/table-pagination";
import SearchBar from "components/search-bar/search-bar";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import TagLoader from "../../tag-loader";
import { LoaderState } from "behaviors/loader/state";

interface StateProps {
  tags: Tag[];
  tagsCount: number;
  uiTags: State["ui"]["tags"];
  specifiedPage: number;
  searchTerm: string;
  loader: LoaderState;
  hasTagCreatePermission: boolean;
}

interface DispatchProps {
  loadTags: (searchTerm?: string) => void;
  addNewTag: () => void;
  editTag: (tag: Tag) => void;
  changeSpecifiedPage: (pageNumber: number) => void;
}

type Props = StateProps & DispatchProps;

const TagsContainer: React.SFC<Props> = ({
  tags,
  tagsCount,
  searchTerm,
  specifiedPage,
  uiTags,
  loader,
  loadTags,
  addNewTag,
  editTag,
  changeSpecifiedPage,
  hasTagCreatePermission
}) => (
  <React.Fragment>
    <main className="entities-wrapper" data-test-id="entities-wrapper">
      <header className="tags-header" data-test-id="tags-header">
        <h1 className="tags-title">{t("tags.title")}</h1>
        <div className="tags-action-button">
          <Button testId="tags-action-btn" type="secondary" disabled={!hasTagCreatePermission} onClick={addNewTag}>
            {t("tags.cta.create")}
          </Button>
        </div>
      </header>
      <div className="tags-table-container" data-test-id="tags-table-container">
        <div className="pagination-top" data-test-id="pagination-top">
          <div className="tags-container-searchbar">
            <SearchBar value={searchTerm} onChange={loadTags} placeholder={t("tags.search-placeholder")} />
          </div>
          <TablePagination
            specifiedPage={specifiedPage}
            onPageChange={(pageNumber) => changeSpecifiedPage(pageNumber)}
            totalCount={tagsCount}
            perPage={20}
          />
        </div>
        <LoaderWrapper className="tags-progress-main-area" component={TagLoader} loader={loader}>
          <TagsList tags={tags} editTag={editTag} uiTags={uiTags} isLoading={loader.loading} />
        </LoaderWrapper>
      </div>
    </main>
  </React.Fragment>
);

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    tags: state.tags.tags,
    tagsCount: state.tags.pagination["tags-count"],
    uiTags: state.tags.ui.tags,
    specifiedPage: state.tags.ui.specifiedPage,
    searchTerm: state.tags.app.searchTerm,
    loader: state.tags.ui.main,
    hasTagCreatePermission: state.features.hasTagCreatePermission
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    loadTags: (searchTerm?: string) => dispatch(loadTags(searchTerm)),
    addNewTag: () => dispatch(navigate(TAGS_NEW_PATH)),
    editTag: (tag: Tag) => dispatch(navigate(TAG_EDIT_PATH, { id: tag.id })),
    changeSpecifiedPage: (pageNumber: number) => dispatch(changeSpecifiedPageAction(pageNumber))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TagsContainer);

export { TagsContainer };
