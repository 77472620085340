/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import Inspector from "components/inspector/inspector";
import Select from "components/select/select";
import RadioButton from "components/radio-button/radio-button";
import Checkbox from "components/checkbox/checkbox";
import TaskPublishInspector from "../task-planner/task-publish-inspector";
import { actions } from "../../actions";
import styles from "./publish-inspector.module.css";
import { WorkflowActions } from "api/story";

import { navigateFn } from "utils/routes.utils";
import { STORY_EDITOR_PATH } from "pages/story-editor/routes";
import { publishOrSchedulePublishAction } from "pages/story-editor/async-action-creators";

import { t } from "i18n";
import DatePickerCustom from "components/date-picker/date-picker";
import { Callout } from "@quintype/em/components/callout";

const canShowMinorVersionPublish = (isPublishLater, workflowTransitions) => {
  const allActions = workflowTransitions.map((transition) => transition.action);
  return !isPublishLater && allActions.includes(WorkflowActions.StoryCorrectionPublish);
};
class PublishInspector extends React.Component<any, any> {
  constructor(props) {
    super(props);
    const isStoryScheduleWithCollectionEnabled =
      this.props.isStoryScheduleWithCollectionEnabled && this.props.accessStoryCollectionPublish;
    this.state = {
      publishTime: this.props.publishAt,
      isPublishLater: this.props.story && this.props.story["publish-at"] ? true : false,
      isScheduledStoryVersion: this.props.story && this.props.story["publish-at"] ? true : false,
      retainLastPublishedTime:
        this.props.story && this.props.story["last-published-at"]
          ? this.props.story["story-template"] !== "live-blog"
          : false,
      scheduleTypes: [
        {
          label: t("story-editor.inspector.schedule-publish-options.with-time"),
          action: WorkflowActions.StorySchedule
        }
      ],
      scheduleTypesWithEmbargo: [
        {
          label: t("story-editor.inspector.schedule-publish-options.with-time"),
          action: WorkflowActions.StorySchedule
        },
        {
          label: t("story-editor.inspector.schedule-publish-options.with-embargo-ends"),
          action: WorkflowActions.StoryScheduleWhenEmbargoEnds
        }
      ],
      scheduleType: {
        label: t("story-editor.inspector.schedule-publish-options.with-time"),
        action: WorkflowActions.StorySchedule
      }
    };

    if (isStoryScheduleWithCollectionEnabled) {
      this.state.scheduleTypes.push({
        label: t("story-editor.inspector.schedule-publish-options.with-collection"),
        action: WorkflowActions.StoryScheduleWithCollection
      });
    }
    this.togglePublishLater = this.togglePublishLater.bind(this);
    this.setScheduleType = this.setScheduleType.bind(this);
    this.toggleRetainLastPublishedtime = this.toggleRetainLastPublishedtime.bind(this);
    this.getTransition = this.getTransition.bind(this);
    this.updatePublishTime = this.updatePublishTime.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.story["last-published-at"] &&
      this.props.story["story-template"] !== prevProps.story["story-template"]
    ) {
      this.setState({ retainLastPublishedTime: this.props.story["story-template"] !== "live-blog" });
    }
  }

  static getDerivedStateFromProps(props, state) {
    let isScheduledStoryVersion = props.story && props.story["publish-at"] ? true : false;

    if (!props.story["publish-at"] && state.isScheduledStoryVersion !== isScheduledStoryVersion) {
      return {
        isPublishLater: props.story && props.story["publish-at"] ? true : false,
        isScheduledStoryVersion,
        publishTime: props.story["publish-at"]
      };
    }
    return null;
  }

  updatePublishTime(timestamp) {
    this.setState({
      publishTime: timestamp
    });
  }

  togglePublishLater() {
    this.state.isPublishLater && this.updatePublishTime(null);

    this.setState({
      isPublishLater: !this.state.isPublishLater
    });
  }

  getTransition = () => {
    if (this.state.isPublishLater) {
      return this.state.scheduleType.action;
    } else if (this.state.retainLastPublishedTime) {
      return WorkflowActions.StoryCorrectionPublish;
    } else if (
      !this.props.canStoryPublish &&
      !this.props.story["is-embargoed"] &&
      this.props.isStoryScheduleWithCollectionEnabled &&
      this.props.accessStoryCollectionPublish
    ) {
      return WorkflowActions.StoryScheduleWithCollection;
    } else {
      return WorkflowActions.StoryPublish;
    }
  };

  toggleRetainLastPublishedtime() {
    this.setState({ retainLastPublishedTime: !this.state.retainLastPublishedTime });
  }

  setScheduleType(scheduleType) {
    this.setState({ scheduleType });
  }

  render() {
    const {
      title,
      isActive,
      onClose,
      asana,
      publishInspectorValidationErrors,
      publishOrSchedulePublish,
      updatePublishAt,
      publishAt,
      story,
      isStoryScheduleWithCollectionEnabled,
      workflowTransitions,
      isStoryPublishing,
      canStoryPublish,
      accessStoryCollectionPublish
    } = this.props;

    const { scheduleTypesWithEmbargo, scheduleTypes, scheduleType } = this.state;
    const storyEmbargoed = story["is-embargoed"];
    const scheduleOptions = storyEmbargoed ? scheduleTypesWithEmbargo : scheduleTypes;

    return (
      <Inspector
        title={title}
        isActive={isActive}
        onClose={() => {
          this.state.isScheduledStoryVersion
            ? this.updatePublishTime(publishAt)
            : this.state.isPublishLater && this.togglePublishLater();
          onClose();
        }}
        actionButtonLabel={t("story-editor.inspector.publish")}
        isActionButtonDisabled={
          isStoryPublishing ||
          (this.state.isPublishLater &&
            !(
              (isStoryScheduleWithCollectionEnabled &&
                accessStoryCollectionPublish &&
                scheduleType &&
                scheduleType.action === WorkflowActions.StoryScheduleWithCollection) ||
              (scheduleType && scheduleType.action === WorkflowActions.StoryScheduleWhenEmbargoEnds) ||
              this.state.publishTime
            ))
        }
        onActionButtonClick={() => {
          this.state.publishTime !== this.props.publishAt && updatePublishAt(this.state.publishTime);
          publishOrSchedulePublish(
            this.state.isScheduledStoryVersion ? WorkflowActions.StoryScheduleModify : this.getTransition()
          );
        }}>
        {!canStoryPublish && isStoryScheduleWithCollectionEnabled && accessStoryCollectionPublish ? (
          <>
            <h3
              className={styles["story-publish-inspector-sub-title"]}
              data-test-id="story-publish-inspector-sub-title">
              {t("story-editor.inspector.schedule-with-collection")}
            </h3>
            <p className={styles["schedule-text"]} data-test-id="schedule-publish-message">
              {t("story-editor.inspector.schedule-with-collection-publish-text")}
            </p>
          </>
        ) : (
          <>
            {storyEmbargoed && (
              <div>
                <Callout
                  title={t("story-editor.inspector.embargo.publish-callout-title")}
                  content={t("story-editor.inspector.embargo.publish-callout-content")}
                  variant="info"
                />
                <br />
              </div>
            )}
            <h3 data-test-id="story-publish-inspector-title" className={styles["story-publish-inspector-title"]}>
              {t("story-editor.inspector.ready-to-publish")}
            </h3>
            <p data-test-id="story-publish-inspector-publish-text" className={styles["publish-text"]}>
              {t("story-editor.inspector.publish-text")}
            </p>
            <div className={styles["publish-options"]} data-test-id="publish-radio-options">
              <RadioButton
                id="story-editor-inspector-publish-now"
                label={t("common.publish-now")}
                checked={!this.state.isPublishLater}
                name="publish"
                onChange={() => this.togglePublishLater()}
                isDisabled={this.state.isScheduledStoryVersion}
                classname={"story-editor-publish-inspector-radio-button"}
              />

              <RadioButton
                id="story-editor-inspector-publish-later"
                label={t("common.publish-later")}
                checked={this.state.isPublishLater}
                name="publish"
                onChange={() => this.togglePublishLater()}
                classname={"story-editor-publish-inspector-radio-button"}
              />
            </div>
            {canShowMinorVersionPublish(this.state.isPublishLater, workflowTransitions) && (
              <div className={styles["story-correction-publish"]} data-test-id="story-correction-publish">
                <Checkbox
                  label={t("story-editor.inspector.retain-last-published-time")}
                  id="retain-last-published-time"
                  checked={this.state.retainLastPublishedTime}
                  onChange={() => this.toggleRetainLastPublishedtime()}
                />
              </div>
            )}
            {this.state.isPublishLater && (
              <React.Fragment>
                {isStoryScheduleWithCollectionEnabled && !story["is-published"] && (
                  <div className={styles["schedule-publish"]} data-test-id="story-schedule-publish">
                    <h3
                      className={styles["story-publish-inspector-sub-title"]}
                      data-test-id="story-publish-inspector-sub-title">
                      {t("story-editor.inspector.schedule-options-text")}
                    </h3>
                    <Select
                      name="Schedule Type"
                      value={[scheduleType]}
                      options={scheduleOptions}
                      onChange={(scheduleType) => this.setScheduleType(scheduleType)}
                      getOptionLabel={(scheduleType) => scheduleType.label}
                      getOptionValue={(scheduleType) => scheduleType.action}
                      isMulti={false}
                      isDisabled={this.state.isScheduledStoryVersion}
                    />
                  </div>
                )}
                {scheduleType && scheduleType.action === WorkflowActions.StorySchedule && (
                  <React.Fragment>
                    <h3
                      className={styles["story-publish-inspector-sub-title"]}
                      data-test-id="story-publish-inspector-sub-title">
                      {t("story-editor.inspector.schedule-publish-text")}
                    </h3>
                    <p className={styles["schedule-text"]} data-test-id="schedule-publish-message">
                      {t("story-editor.inspector.schedule-publish-message")}
                    </p>

                    <DatePickerCustom
                      showTimeSelect
                      onChange={this.updatePublishTime}
                      selectedDate={this.state.publishTime}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            {publishInspectorValidationErrors && (
              <p className={styles["text-field-error-message"]} data-test-id="text-field-error-message">
                {publishInspectorValidationErrors}
              </p>
            )}
            {story["task-id"] && <TaskPublishInspector taskId={story["task-id"]} asana={asana} />}
          </>
        )}
      </Inspector>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    story: state.storyEditor.story,
    publishAt: state.storyEditor.story["publish-at"],
    publishInspector: state.storyEditor.ui.publishInspector,
    publishInspectorValidationErrors: state.storyEditor.ui.publishInspectorValidationErrors,
    isStoryModified: state.storyEditor.ui.isStoryModified,
    workflowTransitions: state.storyEditor.ui.workflowTransitions,
    isStoryScheduleWithCollectionEnabled: state.features.isStoryScheduleWithCollectionEnabled,
    isStoryPublishing: state.storyEditor.ui.isStoryPublishing,
    canStoryPublish: state.config.canStoryPublish,
    accessStoryCollectionPublish: state.config.accessStoryCollectionPublish
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const navigate = navigateFn(dispatch);

  return {
    navigate,
    closeAdvancedInspector: () => navigate(STORY_EDITOR_PATH, { id: ownProps.storyId }),
    updatePublishInspector: (key) => dispatch({ type: actions.UPDATE_PUBLISH_INSPECTOR, payload: { key } }),
    publishOrSchedulePublish: (transition) => dispatch(publishOrSchedulePublishAction(transition)),
    updatePublishAt: (timestamp) => dispatch({ type: actions.UPDATE_STORY_PUBLISH_AT, payload: { timestamp } })
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(PublishInspector);

export { PublishInspector };
