/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Button from "components/button/button";
import classnames from "classnames/bind";
import Plus from "components/icons/plus";
import includes from "lodash/includes";

import { t } from "i18n";
import FieldLabel from "components/field-label/field-label";
import Chip from "components/chip/chip";
import styles from "./recommended-tags.module.css";

const cx = classnames.bind(styles);

const RecommendedTag = ({ tag, storyTags, addRecommendedTag, hasTagCreatePermission }) => {
  return (
    <div
      className={cx("recommended-tag", { "is-added": includes(storyTags, tag) })}
      onClick={() => addRecommendedTag(tag, hasTagCreatePermission)}>
      <Chip
        value={tag.name}
        placeIconRight={() =>
          hasTagCreatePermission ? (
            <span className={styles["add-tag"]}>
              <Plus width="16" height="16" fill="#5f6978" />
            </span>
          ) : null
        }
      />
    </div>
  );
};

const RecommendedTags = ({
  getRecommendedTags,
  storyId,
  recommendedAndPublisherTags,
  addRecommendedTag,
  storyTags,
  hasTagCreatePermission
}) => {
  const { "publisher-tags": publisherTags, "recommended-tags": recommendedTags } = recommendedAndPublisherTags;
  return (
    <React.Fragment>
      <Button onClick={() => getRecommendedTags(storyId)}>{t("story-editor.inspector.recommend-tags")}</Button>
      <div className={styles["recommended-tags-container"]}>
        {recommendedTags && (
          <div className={styles["recommended-tags"]}>
            <FieldLabel label={t("story-editor.inspector.recommended-tags")} />
            {recommendedTags.map((tag, index) => (
              <RecommendedTag
                key={`recommended-tag-${index}`}
                storyTags={storyTags}
                tag={tag}
                addRecommendedTag={addRecommendedTag}
                hasTagCreatePermission={hasTagCreatePermission}
              />
            ))}
          </div>
        )}

        {publisherTags && (
          <div className={styles["publisher-tags"]}>
            <FieldLabel label={t("story-editor.inspector.publisher-tags")} />
            {publisherTags.map((tag, index) => (
              <RecommendedTag
                key={`recommended-tag-${index}`}
                storyTags={storyTags}
                tag={tag}
                addRecommendedTag={addRecommendedTag}
                hasTagCreatePermission={true}
              />
            ))}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default RecommendedTags;
export { RecommendedTag };
