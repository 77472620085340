/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import Tasks from "./tasks";
import { TASKS_INDEX_PATH, TASK_PATH, TASK_NEW_IMAGE_PATH } from "./routes";

export const Router = () => (
  <React.Fragment>
    <Switch>
      <Route exact path={TASKS_INDEX_PATH} render={(props) => <Tasks {...props} />} />
      <Route exact path={TASK_PATH} render={(props) => <Tasks {...props} />} />
      <Route exact path={TASK_NEW_IMAGE_PATH} render={(props) => <Tasks {...props} />} />
    </Switch>
  </React.Fragment>
);
