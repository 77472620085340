/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { AnyContributorRole } from "pages/settings/pages/configure/contributor-roles/state";
import { ContributorRoleId } from "./primitive-types";

export interface ContributorRole {
  id: number;
  name: string;
  type?: string;
}

export const getContributorRole = (id: ContributorRoleId): Promise<ContributorRole> => {
  return client
    .url(`/api/v1/contributor-roles/${id}`)
    .get()
    .json();
};

export const getContributorRoles = (): Promise<Array<ContributorRole>> => {
  return client
    .url("/api/v1/contributor-roles")
    .get()
    .json();
};

export const createContributorRole = (contributorRole: AnyContributorRole): Promise<any> => {
  return client
    .url("/api/v1/contributor-roles")
    .post({ roles: [contributorRole] })
    .json()
    .catch((err) => {
      return Promise.reject(JSON.parse(err.message));
    });
};

export const updateContributorRole = (contributorRole: ContributorRole): Promise<any> => {
  return client
    .url("/api/v1/contributor-roles")
    .put({ roles: [contributorRole] })
    .json()
    .catch((err) => {
      return Promise.reject(JSON.parse(err.message));
    });
};

export const deleteContributorRole = (id: ContributorRoleId): Promise<any> => {
  return client
    .url("/api/v1/contributor-roles")
    .json({ "role-ids": [id] })
    .delete();
};
