/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { CategoryName } from "api/roles";

export const actionCategories = [
  { label: CategoryName.Content, value: CategoryName.Content },
  { label: CategoryName.Admin, value: CategoryName.Admin },
  { label: CategoryName.Analytics, value: CategoryName.Analytics }
];
