/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import * as NotificationSystem from "react-notification-system";
import ExclamationCircle from "components/icons/exclamation-circle";
import CheckCircle from "components/icons/check-circle";

interface Props {
  notification: NotificationSystem.Notification;
}

const SnackbarMessage: React.SFC<Props> = ({ notification }) => {
  return (
    <React.Fragment>
      {(notification.level === "error" || notification.level === "info") && <ExclamationCircle />}
      {notification.level === "success" && <CheckCircle />}
      <span className="notification-message-text" data-test-id="notification-message-text">
        {notification.message}
      </span>
    </React.Fragment>
  );
};

export default SnackbarMessage;
