/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { getAblyToken } from "api/ably-token";

class AblyPresenceAdapter {
  realtime: any;
  constructor(cb) {
    if (window["Ably"]) {
      this.realtime = new window["Ably"].Realtime({
        closeOnUnload: true,
        // queueMessages: false,
        authCallback: (params, callback) => {
          getAblyToken().then((tokenRequest) => {
            callback(null, tokenRequest);
            cb();
          });
        }
      });
    } else {
      console.error("Ably Realtime client library not found.");
    }
  }

  isReady() {
    return !!this.realtime;
  }

  subscribeConnection(listener) {
    this.realtime.connection.on(["connected", "disconnected"], listener);
  }

  subscribe(channel, listener) {
    channel.presence.subscribe(listener);
  }

  unsubscribe(channel, listener) {
    channel.presence.unsubscribe(listener);
  }

  enter(channel, data) {
    channel.presence.enter(data);
  }

  leave(channel, data?) {
    channel && channel.presence.leave(data);
  }

  update(channel, data) {
    channel.presence.update(data);
  }

  getCurrentMembers(channel, callback) {
    channel.presence.get(callback);
  }

  getChannel(name) {
    return this.realtime.channels.get(name);
  }

  getClientId() {
    return this.realtime.auth.clientId;
  }
}

export default AblyPresenceAdapter;
