/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { SearchBar as EmSearchBar } from "@quintype/em/components/search-bar";
interface SearchBarProps {
  /** Value for the search bar to render */
  value: string;
  /** onChange callback */
  onChange: (term: string) => void;
  /** Placeholder for search bar */
  placeholder?: string;
  /** Render a border around the search bar */
  hasBorder?: boolean;
}

const SearchBar = (props: SearchBarProps) => <EmSearchBar {...props} />;

export default SearchBar;
