/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as pathToRegExp from "path-to-regexp";
import { push } from "connected-react-router";
import { isEmptyObject } from "./object.utils";
import { Dispatch } from "redux";
import { Location } from "history";

function makeQueryString(queryParams: Object) {
  let urlSearchParams = new URLSearchParams();

  for (const key in queryParams) {
    if (queryParams.hasOwnProperty(key)) {
      const value = queryParams[key];
      if (value !== undefined) {
        urlSearchParams.set(key, value);
      }
    }
  }

  const queryString = urlSearchParams.toString();

  return queryString.length ? queryString : null;
}

let routeCache: { [key: string]: pathToRegExp.PathFunction } = {};

export function route(path: string, pathParams = {}, queryParams = {}) {
  routeCache[path] = routeCache[path] || pathToRegExp.compile(path);

  const compiledPath = routeCache[path](pathParams),
    queryString = !isEmptyObject(queryParams) && makeQueryString(queryParams);
  return queryString ? compiledPath + "?" + queryString : compiledPath;
}

/**
 @deprecated
 TODO: This function was a mistake. The initial workflow we came up with is
 mapDispatchToProps calls navigateFn, and passes the result down to its children.

 However, in later features, we've found that assigning dispatch(navigate(...))
 to a prop is a better idea, because it makes component testing much simpler
 by stubbing props instead of stubbing the navigate fn itself.

 Essentially, this is deprecated.
 */
export const navigateFn = (dispatch: Dispatch) => {
  return (path: string, pathParams?: {}, queryParams?: {}) => dispatch(push(route(path, pathParams, queryParams)));
};

export const navigate = (path: string, pathParams?: {}, queryParams?: {}) => (dispatch: Dispatch) => {
  dispatch(push(route(path, pathParams, queryParams)));
};

export type Navigate = ReturnType<typeof navigateFn>;

export { goBack } from "connected-react-router";

/*
Testing this function isn't possible as of 14 Nov 2018 because:
  We use react-scripts-3.1.0
  which uses jest 20.0.4
  which uses jsdom 9.12.0
  which doesn't support URLSearchParams.

  jsdom 11 does.
*/
export function isQueryParamTruthy(queryParams: URLSearchParams, key: string) {
  const value = queryParams.get(key);
  /*
    Allow:  ?param (value = "")
            ?param=true (value = "true")
            ?param=1 (value = "1")
            ?param=anything-else (value = "anything-else")

    Deny: ? (value = null)
          ?param=0 (value = "0")
          ?param=false (value = "false")
  */
  return ![null, "false", "0"].includes(value);
}

export function locationHasTruthyQueryParam(location: Location, key: string) {
  const queryParams = new URLSearchParams(location.search);
  return isQueryParamTruthy(queryParams, key);
}

export function refresh() {
  return window.location.reload();
}
