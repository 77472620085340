/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Analytics, RangeFilter, Top } from "api/analytics";
import { AnalyticsFeatures, AnalyticsRouteDataConfig } from "api/route-data/analytics-route-data";
import { INITIAL_LOADER_STATE, LoaderState } from "behaviors/loader/state";
import { getDateRange } from "./utils";
import { AuthorId } from "api/primitive-types";

export interface PartialAppState {
  config: AnalyticsRouteDataConfig;
  features: AnalyticsFeatures;
  analytics: State;
}

export interface DateRange {
  name: string;
  "display-name": string;
}

export interface CurrentFilter {
  dimension: string;
  metrics: string;
  dateRange: DateRange;
}

export interface Range {
  "range-1": RangeFilter;
  "range-2": RangeFilter;
}

export type PartialFilterOptions = Partial<CurrentFilter>;

interface State {
  analytics: Analytics | null;
  authorStories: Top[];
  app: {
    currentFilter: CurrentFilter;
    customRange: Range;
    authorId: AuthorId | null;
  };
  ui: {
    main: LoaderState;
    dateRangeComparison: string;
    isAnalyticsLoading: boolean;
    shareUrl: string;
    isAnalyticsShareVisible: boolean;
    isSublistOpen: boolean;
  };
}

const INITIAL_STATE: State = {
  analytics: null,
  authorStories: [],
  app: {
    currentFilter: {
      dimension: "story",
      metrics: "view",
      dateRange: { name: "day", "display-name": "Day" }
    },
    customRange: getDateRange("day"),
    authorId: null
  },
  ui: {
    main: INITIAL_LOADER_STATE,
    dateRangeComparison: "",
    isAnalyticsLoading: false,
    shareUrl: "",
    isAnalyticsShareVisible: false,
    isSublistOpen: false
  }
};

export default INITIAL_STATE;
