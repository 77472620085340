/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import actions, {
  LoadPromotionalMessagesRequestAction,
  LoadPromotionalMessagesSuccessAction,
  LoadPromotionalMessagesFailureAction,
  SavePromotionalMessagesInitAction,
  OnConfigChangeAction
} from "./actions";
import { PromotionalMessages, Config } from "api/promotional-messages";

export function loadPromotionalMessagesRequestAction(): LoadPromotionalMessagesRequestAction {
  return { type: actions.LOAD_PROMOTIONAL_MESSAGES_INIT };
}
export function loadPromotionalMessagesSuccessAction(
  promotionalMessages: PromotionalMessages
): LoadPromotionalMessagesSuccessAction {
  return { type: actions.LOAD_PROMOTIONAL_MESSAGES_SUCCESS, payload: { promotionalMessages } };
}
export function loadPromotionalMessagesFailureAction(error: Error): LoadPromotionalMessagesFailureAction {
  return { type: actions.LOAD_PROMOTIONAL_MESSAGES_FAILURE, payload: { error } };
}
export function savePromotionalMessagesInitAction(): SavePromotionalMessagesInitAction {
  return { type: actions.SAVE_PROMOTIONAL_MESSAGES_INIT };
}
export function onConfigChangeAction(config: Config): OnConfigChangeAction {
  return { type: actions.UPDATE_PROMOTIONAL_MESSAGE_CONFIG, payload: { config } };
}
