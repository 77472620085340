/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState, CustomURLsInspectorType } from "./state";
import wrapPage from "containers/page/page";
import { RouteComponentProps } from "react-router";
import { CUSTOM_URL_NEW_PATH, CUSTOM_URL_EDIT_PATH } from "./routes";

import styles from "./custom-url.module.css";
import CustomURLsList from "./components/custom-urls-list/custom-urls-list";
import { loadCustomURLs, addNewCustomURL, editCustomURL, deleteCurrentCustomURL } from "./async-action-creators";
import Button from "components/button/button";
import CustomURLsInspector from "./components/inspector/inspector";
import { CustomURLId } from "api/primitive-types";
import { t } from "i18n";
import ConfirmationModal from "components/confirmation-modal/confirmation-modal";
import { toggleDeleteConfirmationAction } from "./action-creators";

interface StateProps {
  inspectorType: CustomURLsInspectorType;
  showDeleteConfirmation: boolean;
  title: string;
}

interface DispatchProps {
  loadCustomUrls: () => void;
  addNewCustomURLItem: () => void;
  editCustomURLItem: (CustomUrlId: CustomURLId) => void;
  deleteCurrentCustomURLItem: () => void;
  toggleDeleteConfirmationModal: () => void;
}

type Props = RouteComponentProps<any> & StateProps & DispatchProps;

export const CustomURL: React.SFC<Props> = ({
  inspectorType,
  addNewCustomURLItem,
  deleteCurrentCustomURLItem,
  showDeleteConfirmation,
  toggleDeleteConfirmationModal
}) => (
  <React.Fragment>
    <section className={styles["page-container custom-url"]} data-test-id="custom-urls-page-container">
      <header className={styles["custom-urls-header"]} data-test-id="custom-urls-header">
        <h3 className={styles["custom-urls-title"]} data-test-id="custom-urls-title">
          {t("customURLs.customURL_plural")}
        </h3>
        <div className={styles["custom-urls-action-button"]}>
          <Button testId="custom-urls-action-btn" type="secondary" onClick={() => addNewCustomURLItem()}>
            {t("customURLs.add_new")}
          </Button>
        </div>
      </header>
      <CustomURLsList />
      <CustomURLsInspector inspectorType={inspectorType} />
      <ConfirmationModal
        showConfirmationModal={showDeleteConfirmation}
        headerText={t("customURLs.confirmation.headerText")}
        text={t("customURLs.confirmation.text")}
        confirmButtonLabel={t("customURLs.confirmation.delete")}
        cancelButtonLabel={t("customURLs.confirmation.cancel")}
        onConfirm={() => deleteCurrentCustomURLItem()}
        onCancel={() => toggleDeleteConfirmationModal()}
        onClose={() => toggleDeleteConfirmationModal()}
      />
    </section>
  </React.Fragment>
);

const mapStateToProps = (state: PartialAppState, ownProps: any): StateProps => {
  return {
    inspectorType: ownProps.inspectorType,
    showDeleteConfirmation: state.customURLs.ui.showDeleteConfirmation,
    title: "custom_url"
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    loadCustomUrls: () => dispatch(loadCustomURLs({})),
    addNewCustomURLItem: () => dispatch(addNewCustomURL()),
    editCustomURLItem: (customURLId: CustomURLId) => dispatch(editCustomURL(customURLId)),
    deleteCurrentCustomURLItem: () => dispatch(deleteCurrentCustomURL()),
    toggleDeleteConfirmationModal: () => dispatch(toggleDeleteConfirmationAction())
  };
};

export class CustomURLWithRoutes extends React.Component<Props> {
  async componentDidMount() {
    await this.props.loadCustomUrls();
    this.onEnter();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.match.url !== prevProps.match.url) {
      this.onEnter();
    }
  }

  onEnter() {
    switch (this.props.match.path) {
      case CUSTOM_URL_NEW_PATH:
        this.props.addNewCustomURLItem();
        break;

      case CUSTOM_URL_EDIT_PATH:
        this.props.editCustomURLItem(this.props.match.params.id);
        break;

      default:
        break;
    }
  }

  render() {
    return <CustomURL {...this.props} />;
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), wrapPage())(CustomURLWithRoutes);
