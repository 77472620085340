/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState } from "./state";
import wrapPage from "containers/page/page";
import { LoaderState } from "behaviors/loader/state";
import { loadAttributes, addNewAttribute, editAttribute, loadEntityTypes } from "./async-action-creators";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import AttributesTree from "./components/attributes-tree/attributes-tree";
import Inspector from "./components/inspector/inspector";
import { RouteComponentProps } from "react-router";
import { ATTRIBUTES_NEW_PATH, ATTRIBUTE_EDIT_PATH } from "./routes";
import Loader from "./loader";
import classnames from "classnames/bind";
import styles from "./attributes.module.css";

const cx = classnames.bind(styles);

interface StateProps {
  loader: LoaderState;
  title: string;
}

interface DispatchProps {
  loadAttributes: () => void;
  loadEntityTypes: () => void;
  addNewAttribute: () => void;
  editAttribute: (attributeName: string) => void;
}

type Props = RouteComponentProps<any> & StateProps & DispatchProps;

export const Attributes: React.SFC<Props> = ({ loader }) => (
  <React.Fragment>
    <section className={cx("page-container", "attributes")}>
      <LoaderWrapper className={"attributes-progress-main-area"} component={Loader} loader={loader}>
        <AttributesTree />
        <Inspector />
      </LoaderWrapper>
    </section>
  </React.Fragment>
);

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    loader: state.attributes.ui.main,
    title: "attributes"
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    loadAttributes: () => dispatch(loadAttributes()),
    addNewAttribute: () => dispatch(addNewAttribute()),
    loadEntityTypes: () => dispatch(loadEntityTypes()),
    editAttribute: (attributeName: string) => dispatch(editAttribute(attributeName))
  };
};

export class AttributesWithRoutes extends React.Component<Props> {
  async componentDidMount() {
    await this.props.loadAttributes();
    await this.props.loadEntityTypes();
    this.onEnter();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.match.url !== prevProps.match.url) {
      this.onEnter();
    }
  }

  onEnter() {
    switch (this.props.match.path) {
      case ATTRIBUTES_NEW_PATH:
        this.props.addNewAttribute();
        break;

      case ATTRIBUTE_EDIT_PATH:
        this.props.editAttribute(this.props.match.params.name);
        break;

      default:
        break;
    }
  }

  render() {
    return <Attributes {...this.props} />;
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  wrapPage()
)(AttributesWithRoutes);
