/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Spinner from "components/spinner/spinner";
import Plus from "components/icons/plus";
import { LoaderState } from "behaviors/loader/state";
import { t } from "i18n";

interface LoaderProps {
  loader: LoaderState;
}

const Loader: React.SFC<LoaderProps> = ({ loader: { loading, error } }) => {
  if (loading) {
    return <Spinner />;
  }
  return (
    <React.Fragment>
      <Plus width="48" height="48" />
      <p> {t("push_notification_channels.server_error")} </p>
    </React.Fragment>
  );
};

export default Loader;
