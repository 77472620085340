/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AnyTag, Tag, UnsavedTag } from "api/tag";

export function isExistingTag(tag: AnyTag): boolean {
  return !!(tag as Tag).id;
}

export function tagToUnsavedTag(tag: Tag): UnsavedTag {
  return {
    name: tag.name,
    slug: tag.slug,
    "meta-description": tag["meta-description"],
    "meta-title": tag["meta-title"]
  };
}
