/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose, AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";

import { PartialAppState, AdvancedSearchOpts } from "pages/media-library/state";
import styles from "./advanced-search.module.css";
import { updateAdvancedSearchOpts, toggleShowAdvancedSearch } from "pages/media-library/action-creators";

import { Author, searchAuthors } from "api/author";
import AsyncSelect from "components/select/async";
import classnames from "classnames/bind";
import Close from "components/icons/close";
import Location from "components/location/location";
import TextField from "components/text-field/text-field";
import NumberField from "components/number-field/number-field";
import { DateFilter, DateFilterTypes } from "components/date-filter/date-filter";
import "react-datepicker/dist/react-datepicker.css";

import debounce from "p-debounce";
import Button from "components/button/button";
import FieldLabel from "components/field-label/field-label";
import { DateRangePicker } from "@quintype/em/components/date-range-picker";
import { getUnixTime, fromUnixTime, set } from "date-fns";
import DateFilterSelect from "components/date-filter-select/date-filter-select";

const cx = classnames.bind(styles);
const loadAuthorsData = debounce(searchAuthors, 250);

interface OwnProps {
  setMediaItemsOnSearch: (advancedSearchOpts: AdvancedSearchOpts) => void;
}

interface StateProps {
  advancedSearchOpts: AdvancedSearchOpts;
  showAdvancedSearch: boolean;
  showLocationFilters?: boolean;
}

interface DispatchProps {
  toggleShowAdvancedSearchAction: () => void;
  updateAdvancedSearchOptsAction: (value: AdvancedSearchOpts) => void;
}

type AdvancedSearchProps = OwnProps & StateProps & DispatchProps;

const dateRangeFilter = (startDate, endDate) => {
  const formatStartDate = startDate && set(fromUnixTime(startDate), { hours: 12, minutes: 0, seconds: 0 });
  const formatEndDate = endDate && set(fromUnixTime(endDate), { hours: 11, minutes: 59, seconds: 59 });

  return {
    type: DateFilterTypes.Custom,
    from: formatStartDate.getTime(),
    to: formatEndDate.getTime()
  };
};

const AdvancedSearch: React.SFC<AdvancedSearchProps> = ({
  advancedSearchOpts = {},
  updateAdvancedSearchOptsAction,
  toggleShowAdvancedSearchAction,
  setMediaItemsOnSearch,
  showAdvancedSearch = true,
  showLocationFilters = false
}) => {
  const AdvancedSearchClass = cx("advanced-search-wrapper");
  const filter = advancedSearchOpts["uploaded-at"];

  let selectedFromDate;
  let selectedToDate;
  if (filter && filter.type === DateFilterTypes.Custom) {
    selectedFromDate = filter.from;
    selectedToDate = filter.to;
  }

  const onDateRangeChange = (startStamp, endStamp) => {
    const filter = dateRangeFilter(startStamp, endStamp);
    updateAdvancedSearchOptsAction({ "uploaded-at": filter });
  };

  return (
    <div className={AdvancedSearchClass}>
      <div className={styles["top-label-row"]}>
        <FieldLabel label={t("advanced-search.title")} />
        <span
          className={styles["advanced-search-close"]}
          data-test-id="advanced-search-close"
          onClick={() => {
            toggleShowAdvancedSearchAction();
            showAdvancedSearch && setMediaItemsOnSearch({});
          }}>
          <Close />
        </span>
      </div>
      <div className={styles["filter-row"]}>
        <div data-test-id="advanced-search-attribution-text-field">
          <TextField
            value={advancedSearchOpts.attribution || ""}
            label={t("mediaLibrary.attribution")}
            onChange={(attribution: string) => updateAdvancedSearchOptsAction({ attribution: attribution || null })}
          />
        </div>
        <div data-test-id="advanced-search-author-name-text-field">
          <AsyncSelect
            label={t("mediaLibrary.author-name")}
            value={advancedSearchOpts.author}
            onChange={(author: Author) => updateAdvancedSearchOptsAction({ author })}
            getOptionLabel={(author) => author.name}
            getOptionValue={(author) => author.name}
            loadOptions={loadAuthorsData}
            defaultOptions={true}
            isClearable={true}
          />
        </div>
      </div>

      {showLocationFilters && (
        <div data-test-id="advanced-search-geo-tag-filters">
          <FieldLabel label={t("advanced-search.geo-tag-filter")} />
          <div className={styles["filter-row"]}>
            <div data-test-id="advanced-search-geo-tag-location-filter">
              <Location
                className="advanced-search-location-container"
                latitude={advancedSearchOpts && advancedSearchOpts.location && advancedSearchOpts["location"].lat}
                longitude={advancedSearchOpts && advancedSearchOpts.location && advancedSearchOpts["location"].lon}
                onChange={(location: { lat: number; lon: number }) =>
                  updateAdvancedSearchOptsAction({ location: { lat: location.lat, lon: location.lon } })
                }
                searchBoxLabel={t("common.location")}
                showMapInterface={false}
              />
            </div>
            <div data-test-id="advanced-search-geo-tag-radius-filter">
              <NumberField
                value={advancedSearchOpts["geo-radius"]}
                label={t("mediaLibrary.radius")}
                onChange={(geoRadius) => updateAdvancedSearchOptsAction({ "geo-radius": geoRadius })}
                readOnly={advancedSearchOpts.location ? false : true}
              />
            </div>
          </div>
        </div>
      )}

      <div className={styles["filter-row"]}>
        <div data-test-id="advanced-search-upload-date-select-field">
          <DateFilterSelect
            label={t("advanced-search.uploaded-at")}
            value={advancedSearchOpts["uploaded-at"] || null}
            onChange={(value: DateFilter) => updateAdvancedSearchOptsAction({ "uploaded-at": value })}
            classname="filters-form-date-filter-container"
          />
        </div>

        {filter && filter.type === DateFilterTypes.Custom && (
          <div className={styles["date-range-picker-container"]}>
            <DateRangePicker
              startTimeStamp={selectedFromDate && getUnixTime(selectedFromDate)}
              endTimeStamp={selectedToDate && getUnixTime(selectedToDate)}
              onDateRangeSelect={onDateRangeChange}
            />
          </div>
        )}
      </div>

      <div className={styles["apply-button"]} data-test-id="media-gallery-advanced-search-apply-btn">
        <Button type="primary" onClick={() => setMediaItemsOnSearch(advancedSearchOpts)}>
          {t("common.apply")}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    advancedSearchOpts: state.mediaLibrary.advancedSearchOpts,
    showAdvancedSearch: state.mediaLibrary.ui.showAdvancedSearch,
    showLocationFilters: state.features.enableEXIFLocation
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    toggleShowAdvancedSearchAction: () => dispatch(toggleShowAdvancedSearch()),
    updateAdvancedSearchOptsAction: (value: AdvancedSearchOpts) => dispatch(updateAdvancedSearchOpts(value))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AdvancedSearch);

export { AdvancedSearch };
