/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useState } from "react";
import { t } from "i18n";

import Spinner from "components/spinner/spinner";
import { setSelectedFocusPoint, setDefaultFocusPoint, removeFocusPoint, FocusPoint } from "./focus-point";
import { useOverlay, useImageData } from "custom-hooks/image";
import styles from "./image-preview-with-actions.module.css";
import { Image } from "api/search-media-image";
import ImageActionBar, { ImageAction, ImageActionType } from "components/image-action-bar/image-action-bar";
import classnames from "classnames/bind";
import { Dimension } from "./utils";

const cx = classnames.bind(styles);

interface ImagePreviewWithActionsProps {
  isUploading: boolean;
  image: Image;
  imageIndex: number;
  isEditable?: boolean;
  deleteMedia(imageIndex: number): void;
  updateImageData(key: string, value: string | number | null, index: number): void;
  imageUploadFailed: boolean;
}

const ImagePreviewWithActions: React.FC<ImagePreviewWithActionsProps> = ({
  isUploading,
  image,
  imageIndex,
  isEditable,
  deleteMedia,
  updateImageData,
  imageUploadFailed
}) => {
  const imageContainer = React.createRef<HTMLDivElement>();
  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
  const { imageDimensions, focusPoint } = useImageData(image);
  const { containerDimensions, overlayProperties, overlayPoint } = useOverlay(
    imageContainer,
    imageDimensions,
    focusPoint
  );
  const imageActions: ImageAction[] = [
    new ImageAction(
      ImageActionType.ClearFocusPoint,
      (event) => removeFocusPoint(event, image.metadata, updateImageData, imageIndex),
      imageUploadFailed || !focusPoint
    ),
    new ImageAction(
      ImageActionType.SetFocusPoint,
      (event) =>
        setDefaultFocusPoint(
          image.metadata,
          (overlayProperties && overlayProperties.dimensions) as Dimension,
          updateImageData,
          imageIndex,
          containerDimensions
        ),
      imageUploadFailed || !!focusPoint
    ),
    new ImageAction(ImageActionType.DownloadImage, undefined, imageUploadFailed || !isEditable || !image),
    new ImageAction(ImageActionType.DeleteImage, async (e) => deleteMedia(imageIndex), isEditable && !imageUploadFailed)
  ];

  return (
    <React.Fragment>
      {isUploading ? (
        <div className={styles["image-loader"]}>
          <Spinner message={t("mediaLibrary.uploading")} />
        </div>
      ) : (
        <div className={styles["image-preview-with-actions"]}>
          {imageUploadFailed ? (
            <div className={styles["image-loader"]}>
              <h2>{t("mediaLibrary.image_not_uploaded")}</h2>
            </div>
          ) : (
            <div className={styles["image-preview-container"]} ref={imageContainer}>
              <img
                src={image.url}
                className={styles["image-preview"]}
                alt={image["alt-text"] || ""}
                onLoad={(e) => setIsImageLoading(false)}
              />
              {focusPoint && overlayPoint && overlayProperties && (
                <div
                  style={overlayProperties.style}
                  className={styles["image-preview-overlay"]}
                  onClick={(e) => setSelectedFocusPoint(e, image, imageIndex, updateImageData, containerDimensions)}>
                  <FocusPoint x={overlayPoint.x} y={overlayPoint.y} />
                </div>
              )}
            </div>
          )}
          <div
            className={cx("image-actions", {
              "image-actions--upload-failed": imageUploadFailed,
              "image-actions--hide": isImageLoading
            })}>
            <ImageActionBar actions={imageActions} image={image} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ImagePreviewWithActions;
