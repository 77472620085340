/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState } from "../menu/state";
import wrapPage from "containers/page/page";
import { LoaderState } from "behaviors/loader/state";
import { addNewMenu, loadMenuItems, addNewMenuItem, editMenuItem, resetMenu } from "../menu/async-action-creators";
import MenuActionBar from "pages/manage/menu/components/action-bar/action-bar";
import Inspector from "./components/inspector/inspector";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import MenuItemsTree from "./components/menu-items-tree/menu-items-tree";
import { RouteComponentProps } from "react-router";
import {
  MENU_INDEX_PATH,
  MENU_NEW_PATH,
  MENU_ITEMS_INDEX_PATH,
  MENU_ITEM_NEW_PATH,
  MENU_ITEM_EDIT_PATH
} from "./routes";
import { navigate } from "utils/routes.utils";
import Loader from "./components/loader/loader";
import { MenuGroup } from "api/menu-group";
import { MenuItemId } from "api/primitive-types";
import styles from "./menu.module.css";

interface StateProps {
  loader: LoaderState;
  selectedMenu: MenuGroup | null;
  title: string;
}

interface DispatchProps {
  addNewMenu: () => void;
  loadMenuItems: (menu: MenuGroup) => void;
  redirectTo: (path: string) => void;
  addNewMenuItem: () => void;
  editMenuItem: (menuItemId: string | MenuItemId) => void;
  resetMenu: () => void;
}

type Props = RouteComponentProps<any> & StateProps & DispatchProps;

export const Menu: React.SFC<Props> = ({ loader, selectedMenu }) => (
  <React.Fragment>
    <Inspector />
    <section className={styles["menu-page-container"]}>
      <LoaderWrapper
        className={"menu-progress-main-area"}
        component={Loader}
        loader={loader}
        selectedMenu={selectedMenu}
        loadMenuItems={loadMenuItems}>
        <MenuItemsTree />
      </LoaderWrapper>
    </section>
  </React.Fragment>
);

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    loader: state.menu.ui.main,
    selectedMenu: state.menu.app.selectedMenu,
    title: "menu"
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    addNewMenu: () => dispatch(addNewMenu()),
    loadMenuItems: (menuId: MenuGroup) => dispatch(loadMenuItems(menuId)),
    redirectTo: (path) => dispatch(navigate(path)),
    addNewMenuItem: () => dispatch(addNewMenuItem()),
    editMenuItem: (menuItemId: string | MenuItemId) => dispatch(editMenuItem(menuItemId)),
    resetMenu: () => dispatch(resetMenu())
  };
};

export class MenuWithRoutes extends React.Component<Props> {
  componentDidMount() {
    this.onEnter();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.match.url !== prevProps.match.url) {
      this.onEnter();
    }
  }

  componentWillUnmount() {
    this.props.resetMenu();
  }

  onEnter() {
    switch (this.props.match.path) {
      case MENU_NEW_PATH:
        this.props.addNewMenu();
        break;
      case MENU_ITEMS_INDEX_PATH:
        if (this.props.selectedMenu) {
          this.props.loadMenuItems(this.props.selectedMenu);
        } else {
          this.props.redirectTo(MENU_INDEX_PATH);
        }
        break;
      case MENU_ITEM_NEW_PATH:
        if (this.props.selectedMenu) {
          this.props.addNewMenuItem();
        } else {
          this.props.redirectTo(MENU_INDEX_PATH);
        }
        break;

      case MENU_ITEM_EDIT_PATH:
        if (this.props.selectedMenu) {
          this.props.editMenuItem(this.props.match.params.itemId);
        } else {
          this.props.redirectTo(MENU_INDEX_PATH);
        }
        break;

      default:
        break;
    }
  }

  render() {
    return <Menu {...this.props} />;
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  wrapPage({ ActionBarComponent: MenuActionBar })
)(MenuWithRoutes);
