/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect, useState } from "react";
import { t } from "i18n";

import Inspector from "components/inspector/inspector";
import Form, { FormConfig } from "pages/manage/custom-fields/form";
import {
  AllowedFieldTypes,
  AllowedFieldSubTypes,
  AllowedValueTypes,
  UnsavedCustomField,
  CustomField
} from "api/custom-field";
import { get } from "lodash";
import StoryTemplateSelector, {
  templateSlugsToTemplateSelectorOptions,
  storyTemplateToSelectorOptions
} from "pages/manage/menu/components/story-template-selector/story-template-selector";
import Checkbox from "components/checkbox/checkbox";
import { StoryTemplate } from "api/route-data/story-route-data";

interface OwnProps {
  active: boolean;
  onActionButtonClick: (customField: UnsavedCustomField) => void;
  cancelSave: () => void;
  storyTemplates: StoryTemplate[];
  actionButtonLabel: string;
  title: string;
  currentCustomField?: CustomField | null | undefined;
}

type Props = OwnProps;

const CustomMetadataInspector: React.SFC<Props> = ({
  active,
  onActionButtonClick,
  cancelSave,
  storyTemplates,
  actionButtonLabel,
  title,
  currentCustomField
}) => {
  const initialCustomField: UnsavedCustomField = {
    name: "",
    "field-type": AllowedFieldTypes.METADATA,
    "field-sub-type": AllowedFieldSubTypes.STORY,
    "is-mandatory": false,
    "value-type": AllowedValueTypes.DESCRIPTION,
    config: (currentCustomField && currentCustomField.config) || null
  };
  const [customField, setCustomField] = useState<CustomField | UnsavedCustomField>(initialCustomField);
  useEffect(() => {
    currentCustomField && setCustomField(currentCustomField);
  }, [currentCustomField]);

  const isEnabledForAllTemplates = get(customField, ["config", "enabled-for-all-templates"], true);
  const selectedTemplates = get(customField, ["config", "content-templates"], []);

  const customFieldFormConfig: FormConfig = {
    customField,
    allowedDataTypes: [{ displayName: "Text Area", type: AllowedValueTypes.DESCRIPTION }]
  };

  const onChangeHandler = (partialCustomField: Partial<UnsavedCustomField>) => {
    setCustomField({ ...customField, ...partialCustomField });
  };

  const onCloseInspector = () => {
    setCustomField(initialCustomField);
    cancelSave();
  };

  return (
    <Inspector
      title={title}
      onClose={onCloseInspector}
      isActive={active}
      actionButtonLabel={actionButtonLabel}
      isActionButtonDisabled={!customField.name}
      onActionButtonClick={() => {
        onActionButtonClick(customField);
      }}>
      <Form config={customFieldFormConfig} onChange={onChangeHandler} />
      <Checkbox
        data-test-id="custom-metadata-checkbox"
        id="custom-metadata-checkbox"
        checked={isEnabledForAllTemplates}
        label={t("custom-metadata.inspector.story-templates-checkbox-label")}
        onChange={(checked) => {
          onChangeHandler({
            config: {
              ...customField.config,
              "enabled-for-all-templates": checked
            }
          });
        }}
        disabled={false}
      />
      <br />
      {!isEnabledForAllTemplates && (
        <StoryTemplateSelector
          data-test-id="story-templates-selector"
          value={templateSlugsToTemplateSelectorOptions(selectedTemplates, storyTemplates)}
          storyTemplates={storyTemplateToSelectorOptions(storyTemplates)}
          onChange={(selectedTemplates) => {
            const contentTemplates = selectedTemplates.map((template) => template.slug);
            onChangeHandler({
              config: {
                ...customField.config,
                "content-templates": contentTemplates
              }
            });
          }}
        />
      )}
    </Inspector>
  );
};

export default CustomMetadataInspector;
