/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AnyStory, Card } from "api/story";
import { CardId } from "api/primitive-types";
import { addIfNotExists } from "utils/array.utils";
import produce from "immer";

export function pinCard(story: AnyStory, cardId: CardId): AnyStory {
  const existingPinnedCard = Object.values(story.cards).find(
    (card: Card) => card.metadata && card.metadata.attributes && card.metadata.attributes["is-pinned?"]
  );

  const updatedCards = produce(story.cards, (cards: { [key: string]: Card }) => {
    if (existingPinnedCard && existingPinnedCard.id !== cardId) {
      const cardDetails = cards[existingPinnedCard.id];
      const newMetadata = cardDetails.metadata
        ? {
            ...cardDetails.metadata,
            attributes: {
              ...cardDetails.metadata.attributes,
              "is-pinned?": false
            }
          }
        : cardDetails.metadata;

      cards[existingPinnedCard.id] = { ...cardDetails, metadata: newMetadata };
    }
  });
  const newUpdatedCards = addIfNotExists(story["updated-cards"], existingPinnedCard && existingPinnedCard.id);

  const cardDetails = updatedCards[cardId];
  const newMetadata = cardDetails.metadata
    ? {
        ...cardDetails.metadata,
        attributes: {
          ...cardDetails.metadata.attributes,
          "is-pinned?": cardDetails.metadata.attributes && !cardDetails.metadata.attributes["is-pinned?"]
        }
      }
    : { attributes: { "is-pinned?": true } };

  const newStory = {
    ...story,
    cards: { ...updatedCards, [cardId]: { ...updatedCards[cardId], metadata: newMetadata } },
    "updated-cards": addIfNotExists(newUpdatedCards, cardId)
  };

  return newStory;
}

export function addKeyEvent(story: AnyStory, cardId: CardId): AnyStory {
  const cardDetails = story.cards[cardId];
  const newMetadata =
    cardDetails && cardDetails.metadata
      ? {
          ...cardDetails.metadata,
          attributes: {
            ...cardDetails.metadata.attributes,
            "key-event": cardDetails.metadata.attributes && !cardDetails.metadata.attributes["key-event"]
          }
        }
      : { attributes: { "key-event": true } };

  const newStory = {
    ...story,
    cards: { ...story.cards, [cardId]: { ...story.cards[cardId], metadata: newMetadata } },
    "updated-cards": addIfNotExists(story["updated-cards"], cardId)
  };

  return newStory;
}

// Function to update the event timestamp of a specific card in a story
export function updateCardEventTimestamp(
  story: AnyStory, // The story object containing all cards
  cardId: CardId, // The ID of the card to update
  timestamp: Date | number // The new event timestamp (can be a Date object or a timestamp in milliseconds)
): AnyStory {
  //should return unchanged state if no payload is provided
  if (!cardId || !timestamp) {
    return story;
  }
  // Retrieve the details of the card to be updated
  const cardDetails = story.cards[cardId];

  // Create a new object for the updated card with the new timestamp
  const updatedCardDetails = {
    ...cardDetails, // Spread existing card properties
    "card-event-at": timestamp // Update the timestamp for the event
  };

  // Create a new story object with the updated card details
  const newStory = {
    ...story, // Retain other story properties
    cards: {
      ...story.cards, // Spread existing cards
      [cardId]: { ...updatedCardDetails } // Replace the updated card's details
    },
    "updated-cards": addIfNotExists(story["updated-cards"], cardId) // Ensure the updated cardId is added to the "updated-cards" list if not already present
  };

  // Return the new story with the updated card
  return newStory;
}
