/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import { NEW_BREAKING_NEWS, BREAKING_NEWS_ADVANCED_OPTIONS, EDIT_BREAKING_NEWS } from "./routes";

import BreakingNewsWithRoutes from "./breaking-news-with-routes";

const Router = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={NEW_BREAKING_NEWS} component={BreakingNewsWithRoutes} />
        <Route exact path={EDIT_BREAKING_NEWS} component={BreakingNewsWithRoutes} />
        <Route exact path={BREAKING_NEWS_ADVANCED_OPTIONS} component={BreakingNewsWithRoutes} />
      </Switch>
    </React.Fragment>
  );
};

export { Router };
