/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState, CustomURLsPagination } from "../../state";
import {
  loadCustomURLs,
  loadCustomURLsByPage,
  loadCustomURLsBySearchTerm,
  setCurrentCustomURLForEdit
} from "../../async-action-creators";
import Loader from "../../loader";
// import { t } from "i18n";
import styles from "./custom-urls-list.module.css";
import { CustomURL } from "api/custom-url";
import SearchBar from "components/search-bar/search-bar";
import TablePagination from "components/table-pagination/table-pagination";
import ReactTable, { RowInfo } from "react-table";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import { LoaderState } from "behaviors/loader/state";
import { t } from "i18n";
import classnames from "classnames/bind";

interface StateProps {
  customURLItems: CustomURL[];
  searchTerm: string;
  pagination: CustomURLsPagination;
  loader: LoaderState;
}

interface DispatchProps {
  loadCustomURLItems: () => void;
  loadCustomURLItemsByPage: (pageNumber: number) => void;
  loadCustomURLItemsBySearchTerm: (searchTerm: string) => void;
  setCurrentCustomURLItemForEdit: (customURL: CustomURL) => void;
}

type Props = StateProps & DispatchProps;

const cx = classnames.bind(styles);

const CustomURLsList: React.SFC<Props> = ({
  customURLItems,
  searchTerm,
  loadCustomURLItemsBySearchTerm,
  pagination,
  loadCustomURLItemsByPage,
  loader,
  setCurrentCustomURLItemForEdit
}) => {
  const wrapperClasses = cx("custom-urls-list", { "custom-urls-is-loading": loader.loading });
  return (
    <React.Fragment>
      <div className={wrapperClasses} data-test-id="custom-urls-list-wrapper">
        <div className={styles["pagination-top"]} data-test-id="custom-urls-pagination-top">
          <div className={styles["custom-urls-list-search-bar"]} data-test-id="custom-urls-list-search-bar">
            <SearchBar
              placeholder={t("customURLs.search_placeholder")}
              value={searchTerm}
              onChange={(searchTerm) => loadCustomURLItemsBySearchTerm(searchTerm)}
            />
          </div>
          <TablePagination
            specifiedPage={pagination.currentPage}
            onPageChange={(pageNumber) => loadCustomURLItemsByPage(pageNumber)}
            totalCount={pagination.total}
            perPage={pagination.itemsPerPage}
          />
        </div>
        <LoaderWrapper className={"custom-url-progress-main-area"} component={Loader} loader={loader}>
          <div data-test-id="custom-urls-react-table">
            <ReactTable
              showPaginationTop={false}
              showPaginationBottom={false}
              showPagination={false}
              loading={false}
              showPageSizeOptions={false}
              defaultPageSize={1}
              sortable={false}
              pageSize={
                customURLItems.length > pagination.itemsPerPage ? pagination.itemsPerPage : customURLItems.length
              }
              noDataText={t("customURLs.errors.no_data")}
              data={customURLItems}
              columns={[
                {
                  Header: t("customURLs.list.sourcePath"),
                  accessor: "source-path",
                  style: { "overflow-wrap": "break-word", padding: "0 10px" },
                  headerStyle: { padding: "0 10px" }
                },
                {
                  Header: t("customURLs.list.destinationPath"),
                  accessor: "destination-path",
                  style: { "overflow-wrap": "break-word", padding: "0 10px" },
                  headerStyle: { padding: "0 10px" }
                },
                {
                  Header: t("customURLs.list.type"),
                  accessor: "type",
                  style: { padding: "0 10px" },
                  headerStyle: { padding: "0 10px" },
                  maxWidth: 100
                },
                {
                  Header: t("customURLs.list.statusCode"),
                  accessor: "status-code",
                  style: { padding: "0 10px" },
                  headerStyle: { padding: "0 10px" },
                  maxWidth: 150
                }
              ]}
              onPageChange={(pageNumber) => loadCustomURLItemsByPage(pageNumber)}
              pages={Math.ceil(pagination.total / pagination.itemsPerPage)}
              className={styles["custom-urls-table"]}
              LoadingComponent={() => null}
              getTrProps={(state: any, rowInfo: RowInfo) => ({
                onClick: () => setCurrentCustomURLItemForEdit(rowInfo.original)
              })}
            />
          </div>
        </LoaderWrapper>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    customURLItems: state.customURLs.customURLs,
    searchTerm: state.customURLs.app.searchTerm,
    pagination: state.customURLs.pagination,
    loader: state.customURLs.ui.main
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    loadCustomURLItems: () => dispatch(loadCustomURLs({})),
    loadCustomURLItemsByPage: (pageNumber) => dispatch(loadCustomURLsByPage(pageNumber)),
    loadCustomURLItemsBySearchTerm: (searchTerm: string) => dispatch(loadCustomURLsBySearchTerm(searchTerm)),
    setCurrentCustomURLItemForEdit: (customURL: CustomURL) => dispatch(setCurrentCustomURLForEdit(customURL))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CustomURLsList);

export { CustomURLsList };
