/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { get } from "lodash";
import TextField from "components/text-field/text-field";
import NumberField from "components/number-field/number-field";
import { FcmConfig, Integrations } from "api/integrations";
import { isClientValidationError } from "utils/validation.utils";
import TextArea from "components/text-area/text-area";

interface Props {
  integration: { key: Integrations.Fcm; value: FcmConfig };
  updateIntegrationField: (key: string, value: Object) => void;
  readOnly?: boolean;
  errors: Error | null;
}

const Fcm: React.SFC<Props> = ({ integration, updateIntegrationField, readOnly, errors }) => {
  const integrationName = integration.key;
  return (
    <React.Fragment>
      <TextField
        label={t("integrations.fields.fcm.project-id")}
        value={integration.value["project-id"]}
        onChange={(value) => updateIntegrationField(integrationName, { "project-id": value })}
        readOnly={readOnly}
        errorMessage={
          isClientValidationError<FcmConfig>(errors) ? get(errors, ["errors", "value", "project-id", "0"]) : undefined
        }
      />
      <TextField
        label={t("integrations.fields.fcm.client-email")}
        value={integration.value["client-email"]}
        onChange={(value) => updateIntegrationField(integrationName, { "client-email": value })}
        readOnly={readOnly}
        errorMessage={
          isClientValidationError<FcmConfig>(errors) ? get(errors, ["errors", "value", "client-email", "0"]) : undefined
        }
      />
      <TextArea
        label={t("integrations.fields.fcm.private-key")}
        value={integration.value["private-key"]}
        onChange={(value) => updateIntegrationField(integrationName, { "private-key": value })}
        errorMessage={
          isClientValidationError<FcmConfig>(errors) ? get(errors, ["errors", "value", "private-key", "0"]) : undefined
        }
      />
      <TextField
        label={t("integrations.fields.fcm.server-key")}
        value={integration.value["server-key"]}
        onChange={(value) => updateIntegrationField(integrationName, { "server-key": value })}
        readOnly={readOnly}
        errorMessage={
          isClientValidationError<FcmConfig>(errors) ? get(errors, ["errors", "value", "server-key", "0"]) : undefined
        }
      />
      <NumberField
        label={t("integrations.fields.fcm.ttl-web-in-hours")}
        value={integration.value["ttl-web-in-hours"]}
        onChange={(value: number) => updateIntegrationField(integrationName, { "ttl-web-in-hours": value || 0 })}
        min={0}
      />
      <NumberField
        label={t("integrations.fields.fcm.ttl-mobile-in-hours")}
        value={integration.value["ttl-mobile-in-hours"]}
        onChange={(value: number) => updateIntegrationField(integrationName, { "ttl-mobile-in-hours": value || 0 })}
        min={0}
      />
    </React.Fragment>
  );
};

export default Fcm;
