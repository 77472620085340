/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import Clock from "components/icons/clock";
import InfiniteScrollList from "components/infinite-scroll-list/infinite-scroll-list";
import styles from "./static-pages-list.module.css";
import { StaticPage } from "api/static-pages";
import { formattedDate } from "utils";
import { ThunkDispatch } from "redux-thunk";
import { STATIC_PAGES_EDIT_PATH } from "../../routes";
import { navigate } from "utils/routes.utils";
import NoContentCard from "components/no-content-card/no-content-card";
import File from "components/icons/file";
import Spinner from "components/spinner/spinner";
import { t } from "i18n";
import { loadNextStaticPagesAction } from "../../async-action-creators";
import Chip from "components/chip/chip";
interface StateProps {
  staticPages: StaticPage[];
  errors?: any;
  totalPages: number;
}

interface DispatchProps {
  switchToEditRoute: Function;
  loadMoreStaticPages: Function;
}

type StaticPageListProps = StateProps & DispatchProps;

const StaticPagesList: React.SFC<StaticPageListProps> = ({
  staticPages,
  errors,
  totalPages,
  switchToEditRoute,
  loadMoreStaticPages
}: StaticPageListProps) => {
  return (
    <section className={styles["static-pages-list-container"]} data-test-id="static-pages-list-container">
      <InfiniteScrollList
        dataLength={staticPages.length}
        next={() => {
          loadMoreStaticPages(staticPages.length);
        }}
        hasMore={totalPages - staticPages.length > 0}
        loader={<Spinner message={t("staticPages.loading")} />}
        endMessage={
          !errors &&
          totalPages === 0 && (
            <NoContentCard>
              <File />
              <div className={styles["no-content-message"]}>{t("staticPages.list.noPages")}</div>
            </NoContentCard>
          )
        }>
        {staticPages.map((staticPage) => (
          <div
            key={`static-page-list-${staticPage["created-at"]}`}
            className={styles["static-pages-item"]}
            data-test-id="static-pages-item"
            onClick={() => switchToEditRoute(staticPage.id)}>
            <h3 className={styles["static-pages-item-title"]} data-test-id="static-pages-item-title">
              {staticPage.title}
            </h3>
            <div className={styles["static-pages-item-meta"]} data-test-id="static-pages-item-meta">
              {staticPage.status && (
                <div className={styles["static-pages-item-status"]} data-test-id="static-pages-item-status">
                  <Chip value={staticPage.status} invert />
                </div>
              )}
              <div
                className={styles["static-pages-item-updated-container"]}
                data-test-id="static-pages-item-updated-container">
                <Clock />
                <time className={styles["static-pages-item-updated-at"]} data-test-id="static-pages-item-updated-at">
                  {formattedDate(staticPage["created-at"])}
                </time>
              </div>
            </div>
          </div>
        ))}
      </InfiniteScrollList>
    </section>
  );
};
const mapStateToProps = (state: any): StateProps => {
  return {
    staticPages: state.staticPages.staticPages,
    errors: state.staticPages.ui.main.error,
    totalPages: state.staticPages.totalCount
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
  return {
    switchToEditRoute: (id: string) => dispatch(navigate(STATIC_PAGES_EDIT_PATH, { id })),
    loadMoreStaticPages: (offset: number) => dispatch(loadNextStaticPagesAction(offset))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(StaticPagesList);

export { StaticPagesList };
