/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Member, Team, Role } from "api/users";
import { RoleTypes } from "api/roles";
import { LoaderState, INITIAL_LOADER_STATE } from "behaviors/loader/state";
import { Features, Config } from "api/route-data/route-data";

export interface UserTeam extends Team {
  checked: boolean;
}

export interface UserRole extends RoleTypes {
  checked: boolean;
}

export interface SelectedUser extends Member {
  username: string;
  metadata: { [key: string]: string };
  "can-login": boolean;
  settings: {
    bold?: boolean;
    "dark-mode"?: boolean;
    "default-manage-story-inspector"?: boolean;
    "language-preference"?: string;
  };
  social: {
    [key: string]: string;
  };
}

export interface UsersPageType {
  usersPage: State;
}

export interface PartialAppState {
  usersAndRoles: UsersPageType;
  features: Features;
  config: Config;
  viewport: {
    isDesktopSizeViewport: boolean;
  };
}

export interface UsersPagination {
  total: number;
  offset: number;
  limit: number;
}

export interface NewUser {
  "avatar-url": string | null;
  metadata: { [x: string]: string } | {};
  username: string;
  password: string;
  name: string;
  email: string;
  "communication-email": string;
  roles: Role[];
  "can-login": boolean;
  bio?: string;
  social: { [x: string]: string };
  "temp-s3-key": string | null;
}

export type ValidationError = {
  name?: string[];
  email?: string[];
  username?: string[];
  password?: string[];
  slug?: string[];
  roles?: any[];
};

export interface State {
  users: SelectedUser[];
  usersPagination: UsersPagination;
  usersPerPage: number;
  selectedUser: SelectedUser | {};
  newUser: NewUser;
  ui: {
    searchTerm: string;
    isUserProfileModified: boolean;
    currentPage: number;
    errors: ValidationError | null;
    uploading: boolean;
    imageError: string;
    allRoles: UserRole[] | any[];
    allTeams: UserTeam[] | any[];
    main: LoaderState;
    inspector: LoaderState;
    teamsInspector: LoaderState;
    loadingAvatar: {};
    isDarkModeConfirmationModalVisible: boolean;
    isManageStoryInspectorModalVisible: boolean;
    isManageLanguageInspectorModalVisible: boolean;
    isToggleLoginConfirmationModalVisible: boolean;
    banner: { message: string };
    saving: boolean;
    canLoginModified: boolean;
  };
}

export const INITIAL_USER_STATE = {
  users: [],
  usersPagination: {
    total: 0,
    offset: 0,
    limit: 20
  },
  usersPerPage: 20,
  selectedUser: {},
  newUser: {
    "avatar-url": null,
    metadata: {},
    username: "",
    password: "",
    name: "",
    email: "",
    "communication-email": "",
    roles: [],
    "can-login": false,
    social: {},
    "temp-s3-key": null
  },
  ui: {
    searchTerm: "",
    isUserProfileModified: false,
    currentPage: 1,
    errors: {},
    uploading: false,
    imageError: "",
    allRoles: [],
    allTeams: [],
    main: INITIAL_LOADER_STATE,
    inspector: INITIAL_LOADER_STATE,
    teamsInspector: INITIAL_LOADER_STATE,
    loadingAvatar: false,
    banner: { message: "" },
    saving: false,
    isDarkModeConfirmationModalVisible: false,
    isManageStoryInspectorModalVisible: false,
    isManageLanguageInspectorModalVisible: false,
    isToggleLoginConfirmationModalVisible: false,
    canLoginModified: false
  }
};
