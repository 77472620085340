/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const STORY_EDITOR_PATH = "/story/:id";
export const STORY_EDITOR_NEW_PATH = "/story/new";
export const STORY_EDITOR_PATH_REFRESH = "/story/";
export const STORY_EDITOR_READ_ONLY_PATH = "/story/:id/version/:versionId";
export const STORY_EDITOR_TEMPLATE_PATH = `${STORY_EDITOR_PATH}/template`;
export const STORY_EDITOR_HERO_IMAGE_GALLERY_PATH = `${STORY_EDITOR_PATH}/media-gallery/hero-image`;
export const STORY_EDITOR_IMAGE_INSPECTOR_PATH = `${STORY_EDITOR_PATH}/story/inspector/:elementType/:imageId`;
export const STORY_EDITOR_STORY_IMAGE_PATH = `${STORY_EDITOR_PATH}/story/image/:imageId`;
export const STORY_EDITOR_IMAGE_ELEMENT_GALLERY_PATH = `${STORY_EDITOR_PATH}/media-gallery/image/:imageId`;
export const STORY_EDITOR_OEMBED_VIDEO_SELECTOR_PATH = `${STORY_EDITOR_PATH}/oembed-video/selector/:storyElementClientId`;

export const STORY_EDITOR_MANAGE_PATH = `${STORY_EDITOR_PATH}/manage`;
export const STORY_EDITOR_MANAGE_ADVANCED_PATH = `${STORY_EDITOR_PATH}/manage/advanced`;
export const STORY_EDITOR_MANAGE_ADVANCED_METADATA_PATH = `${STORY_EDITOR_MANAGE_ADVANCED_PATH}/metadata`;
export const STORY_EDITOR_MANAGE_ADVANCED_ATTRIBUTES_PATH = `${STORY_EDITOR_MANAGE_ADVANCED_PATH}/attributes`;
export const STORY_EDITOR_MANAGE_ADVANCED_SOCIAL_NOTIFICATIONS_PATH = `${STORY_EDITOR_MANAGE_ADVANCED_PATH}/social-notifications`;
export const STORY_EDITOR_ALTERNATIVES_PATH = `${STORY_EDITOR_PATH}/alternatives`;
export const STORY_EDITOR_ALTERNATIVES_IMAGE_PATH = `${STORY_EDITOR_ALTERNATIVES_PATH}/image/:subtype`;
export const STORY_EDITOR_ALTERNATIVES_IMAGE_EDIT_PATH = `${STORY_EDITOR_ALTERNATIVES_PATH}/image/:subtype/:imageId`;
export const STORY_EDITOR_CARD_SHARE = `${STORY_EDITOR_PATH}/card/:cardId/share`;
export const STORY_EDITOR_CARD_ATTRIBUTES = `${STORY_EDITOR_PATH}/card/:cardId/attributes`;
export const STORY_EDITOR_CARD_SHARE_IMAGE_PATH = `${STORY_EDITOR_CARD_SHARE}/image`;

export const STORY_EDITOR_PUBLISH = `${STORY_EDITOR_PATH}/publish`;

export const STORY_EDITOR_POLL_EDIT = `${STORY_EDITOR_PATH}/poll/:storyElementId`;

export const STORY_EDITOR_IMAGE_GALLERY_ELEMENT_GALLERY_PATH = `${STORY_EDITOR_PATH}/media-gallery/image-gallery/:storyElementClientId`;
export const STORY_EDITOR_IMAGE_GALLERY_ELEMENT_EDIT_PATH = `${STORY_EDITOR_PATH}/image-gallery/edit/:storyElementId`;

export const STORY_EDITOR_STORY_SEARCH_PATH = `${STORY_EDITOR_PATH}/story-search`;
export const STORY_EDITOR_IMPORT_PATH = "/story/:id/import/:importStoryId";
export const STORY_EDITOR_PLAGIARISM_REPORT = "/story/:id/plagiarism-report";
export const STORY_EDITOR_NOTES_PATH = `${STORY_EDITOR_PATH}/editor-notes`;
export const STORY_EDITOR_EMBARGO_PATH = `${STORY_EDITOR_PATH}/embargo`;
