/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Text = ({ width = "24", height = "24", color = "#c6ccd5" }) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={color}
    viewBox="0 0 24 24">
    <path d="M7.01 11.99h1.01V5.85H11v-.86H4.04v.86h2.97v6.14zM9.64 9.86h10.31v1H9.64zM4.96 12.86h15v1h-15zM4.96 15.86h15v1h-15zM4.96 18.86h15v1h-15z" />
  </svg>
);

export default Text;
