/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PartialAppState {
  errorPage: ErrorPageState;
}

export interface ErrorPageState {
  error: Error;
}

export enum ErrorType {
  Generic = "generic",
  NotFound = "not-found",
  Unauthorized = "unauthorized",
  FeatureNotSetup = "feature-not-setup",
  GlobalAppCrash = "global-app-crash"
}

export type Error = {
  type: ErrorType;
  customMessage?: string;
} | null;

const initialState: ErrorPageState = {
  error: null
};

const { reducer, actions } = createSlice({
  initialState,
  name: "page-errors",
  reducers: {
    hideErrorPage: (state: ErrorPageState) => {
      state.error = null;
    },
    showGenericErrorPage: (state: ErrorPageState, action: PayloadAction<string | undefined>) => {
      state.error = { type: ErrorType.Generic, customMessage: action.payload };
    },
    showNotFoundPage: (state: ErrorPageState, action: PayloadAction<string | undefined>) => {
      state.error = { type: ErrorType.NotFound, customMessage: action.payload };
    },
    showUnauthorizedPage: (state: ErrorPageState, action: PayloadAction<string | undefined>) => {
      state.error = { type: ErrorType.Unauthorized, customMessage: action.payload };
    },
    showFeatureNotSetupPage: (state: ErrorPageState, action: PayloadAction<string | undefined>) => {
      state.error = { type: ErrorType.FeatureNotSetup, customMessage: action.payload };
    },
    showGlobalAppCrashPage: (state: ErrorPageState, action: PayloadAction<string | undefined>) => {
      state.error = { type: ErrorType.GlobalAppCrash, customMessage: action.payload };
    }
  }
});

export const {
  hideErrorPage,
  showGenericErrorPage,
  showNotFoundPage,
  showUnauthorizedPage,
  showFeatureNotSetupPage,
  showGlobalAppCrashPage
} = actions;

export default reducer;
