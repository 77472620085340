/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction, compose } from "redux";
import styles from "./collection-settings.module.css";
import { t } from "i18n";
import {
  pdfAsCollectionMetadataSlug,
  PartialAppState,
  loadSettings,
  removeFeatureSettings,
  updateFeatureSettings
} from "store/secret-mode/secret-mode";
import { Feature, FeatureSettings } from "store/feature/feature";
import { enableSaveButton } from "store/secret-mode/secret-mode";
import Spinner from "components/spinner/spinner";
import SettingsContainer from "components/settings/settings-container/settings-container";
import SettingsSection from "components/settings/settings-section/settings-section";
import SwitchSetting from "components/switch-setting/switch-setting";
import Spacer from "components/spacer/spacer";
import NumberField from "components/number-field/number-field";

const TRANSLATION_PATH = "secret-mode.collection";

interface StateProps {
  isLoading: boolean;
  loadingFailed: boolean;
  pdfCollectionMetadata: Feature;
}

interface DispatchProps {
  loadCollectionSettings: ({ featureSlugs }: { featureSlugs: string[] }) => void;
  updateFeatureSettings: (setting: FeatureSettings) => void;
  unloadFeatures: (features: string[]) => void;
}

type CollectionSettingsProps = StateProps & DispatchProps;

// TODO Remove hints for number fields when they have ability to display units
export const CollectionSettings: React.FC<CollectionSettingsProps> = ({
  isLoading,
  loadingFailed,
  pdfCollectionMetadata,
  loadCollectionSettings,
  updateFeatureSettings,
  unloadFeatures
}) => {
  useEffect(() => {
    loadCollectionSettings({ featureSlugs: [pdfAsCollectionMetadataSlug] });
    return () => unloadFeatures([pdfAsCollectionMetadataSlug]);
  }, [loadCollectionSettings, unloadFeatures]);

  if (isLoading) {
    return <Spinner message={t(`${TRANSLATION_PATH}.loading`)} classname={styles["collection-settings-spinner"]} />;
  }

  const pdfCollectionMetadataConfig = (pdfCollectionMetadata && pdfCollectionMetadata.config) || {};

  return loadingFailed ? null : (
    <SettingsContainer>
      <SettingsSection title={t(`${TRANSLATION_PATH}.collection-settings.title`)}>
        {pdfCollectionMetadata && (
          <>
            <SwitchSetting
              label={t(`${TRANSLATION_PATH}.collection-settings.pdf-collection-label`)}
              toggle={pdfCollectionMetadata.toggle}
              hint={t(`${TRANSLATION_PATH}.collection-settings.pdf-collection-hint`)}
              onChange={(checked) => {
                const change = { ...pdfCollectionMetadata, toggle: checked };
                updateFeatureSettings({ [pdfAsCollectionMetadataSlug]: change });
              }}
            />

            <Spacer token={"l"} align={"vertical"} />

            {pdfCollectionMetadata.toggle && (
              <div className={styles["file-size-container"]}>
                <NumberField
                  label={t(`${TRANSLATION_PATH}.collection-settings.min-pdf-size-label`)}
                  value={pdfCollectionMetadataConfig["minSize"]}
                  hint={t(`${TRANSLATION_PATH}.collection-settings.min-pdf-size-hint`)}
                  min={0}
                  readOnly={!pdfCollectionMetadata.toggle}
                  classname={styles["pdf-min-size-field"]}
                  onChange={(minSizeInBytes) =>
                    updateFeatureSettings({
                      [pdfAsCollectionMetadataSlug]: {
                        ...pdfCollectionMetadata,
                        config: { ...pdfCollectionMetadataConfig, minSize: minSizeInBytes }
                      }
                    })
                  }
                />
                <NumberField
                  label={t(`${TRANSLATION_PATH}.collection-settings.max-pdf-size-label`)}
                  value={pdfCollectionMetadataConfig["maxSize"]}
                  hint={t(`${TRANSLATION_PATH}.collection-settings.max-pdf-size-hint`)}
                  min={pdfCollectionMetadataConfig["minSize"]}
                  readOnly={!pdfCollectionMetadata.toggle}
                  classname={styles["pdf-max-size-field"]}
                  onChange={(maxSizeInBytes) =>
                    updateFeatureSettings({
                      [pdfAsCollectionMetadataSlug]: {
                        ...pdfCollectionMetadata,
                        config: { ...pdfCollectionMetadataConfig, maxSize: maxSizeInBytes }
                      }
                    })
                  }
                />
              </div>
            )}
          </>
        )}
      </SettingsSection>
    </SettingsContainer>
  );
};

const mapStateToProps = ({ secretMode: { app, ui } }: PartialAppState) => {
  return {
    isLoading: ui.isLoading,
    loadingFailed: ui.loadingFailed,
    pdfCollectionMetadata: app.features[pdfAsCollectionMetadataSlug]
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => ({
  loadCollectionSettings: (params) => dispatch(loadSettings(params)),
  updateFeatureSettings: (setting) => {
    dispatch(updateFeatureSettings(setting));
    dispatch(enableSaveButton());
  },
  unloadFeatures: (features) => dispatch(removeFeatureSettings(features))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(CollectionSettings);
