/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { LoaderState } from "behaviors/loader/state";
import { PartialAppState, InspectorType } from "../custom-fields/state";
import { AllowedFieldTypes, CustomField, UnsavedCustomField } from "api/custom-field";
import {
  loadCustomFields,
  saveCustomField,
  deleteCustomField,
  SuccessFn,
  FailureFn,
  addNewCustomField,
  editCustomField,
  cancelSaveCustomField,
  editSuccessFn,
  editFailureFn,
  editCustomMetadataField
} from "../custom-fields/async-action-creators";
import { navigate } from "utils/routes.utils";

import Inspector from "./components/inspector";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import Loader from "./loader";
import CustomMetadataTree from "./components/custom-metadata-tree";
import { CUSTOM_METADATA_INDEX_PATH } from "./routes";
import { NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from "containers/page/actions";
import { t } from "i18n";
import { StoryTemplate } from "api/route-data/story-route-data";

interface StateProps {
  main: LoaderState;
  customMetadataFields: Array<CustomField>;
  save: LoaderState | null;
  deleteState: LoaderState | null; //named as deleteState since delete is a Javascript keyword
  storyTemplates: StoryTemplate[];
  inspectorType: InspectorType | null;
  currentCustomField?: CustomField | null | undefined;
}

interface DispatchProps {
  loadCustomMetadataFields: () => void;
  openAddInspector: () => void;
  saveCustomMetadataField: (customField: UnsavedCustomField) => void;
  cancelSave: () => void;
  deleteCustomMetadataField: (customField: CustomField) => void;
  addNewCustomField: () => void;
  editCustomMetadataField: (customField: CustomField) => void;
  updateExistingCustomMetadata: (customField: CustomField) => void;
}

interface OwnProps {
  shouldOpenAddInspector?: boolean;
  currentCustomField?: CustomField | null | undefined;
}

type Props = OwnProps & StateProps & DispatchProps;

export const CustomMetadata: React.FC<Props> = ({
  main,
  customMetadataFields,
  loadCustomMetadataFields,
  saveCustomMetadataField,
  cancelSave,
  deleteCustomMetadataField,
  shouldOpenAddInspector = false,
  openAddInspector,
  storyTemplates,
  inspectorType,
  editCustomMetadataField,
  currentCustomField,
  updateExistingCustomMetadata
}) => {
  const actionButtonLabel =
    inspectorType === InspectorType.Edit ? t("attributes.cta.update") : t("attributes.cta.create");
  const title =
    inspectorType === InspectorType.Create
      ? t("custom-metadata.inspector.create-title")
      : t("custom-metadata.inspector.edit-title");
  const actionButtonClickHandler =
    inspectorType === InspectorType.Create ? saveCustomMetadataField : updateExistingCustomMetadata;

  return (
    <React.Fragment>
      <section className="page-container">
        <LoaderWrapper
          className={"metadata-progress-main-area"}
          component={Loader}
          loader={main}
          loadCustomMetadata={() => loadCustomMetadataFields()}>
          <CustomMetadataTree
            customMetadataFields={customMetadataFields}
            deleteCustomMetadataField={deleteCustomMetadataField}
            openAddInspector={openAddInspector}
            editCustomMetadataField={editCustomMetadataField}
          />
          <Inspector
            title={title}
            active={shouldOpenAddInspector}
            onActionButtonClick={actionButtonClickHandler}
            cancelSave={cancelSave}
            storyTemplates={storyTemplates}
            actionButtonLabel={actionButtonLabel}
            currentCustomField={currentCustomField}
          />
        </LoaderWrapper>
      </section>
    </React.Fragment>
  );
};

const mapStateToProps = (state: PartialAppState) => {
  return {
    main: state.customFields.ui.main,
    save: state.customFields.ui.save,
    deleteState: state.customFields.ui.delete,
    customMetadataFields: state.customFields.customFields,
    storyTemplates: state.config.storyTemplates,
    inspectorType: state.customFields.ui.inspectorType,
    currentCustomField: state.customFields.app.currentCustomField
  };
};

const saveSuccessFn: SuccessFn = (dispatch) => {
  dispatch({
    type: NOTIFICATION_SUCCESS,
    payload: {
      message: t("custom-metadata.messages.save-success")
    }
  });

  dispatch(navigate(CUSTOM_METADATA_INDEX_PATH, {}, { refresh: true }));
};

const saveFailureFn: FailureFn = (e, dispatch) => {
  const errorInfo = JSON.parse(e.message);

  if (errorInfo.status && errorInfo.status === 409) {
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: {
        message: t("custom-metadata.messages.validations.duplicate-name")
      }
    });
  } else {
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: {
        message: t("custom-metadata.messages.unknown-error")
      }
    });
  }
};

const deleteSuccessFn: SuccessFn = (dispatch) => {
  dispatch({
    type: NOTIFICATION_SUCCESS,
    payload: {
      message: t("custom-metadata.messages.delete-success")
    }
  });

  dispatch(navigate(CUSTOM_METADATA_INDEX_PATH, {}, { refresh: true }));
};

const deleteFailureFn: FailureFn = (_, dispatch) => {
  dispatch({
    type: NOTIFICATION_ERROR,
    payload: {
      message: t("custom-metadata.messages.delete-failure")
    }
  });
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => ({
  loadCustomMetadataFields: () => dispatch(loadCustomFields({ "field-type": AllowedFieldTypes.METADATA })),
  openAddInspector: () => dispatch(addNewCustomField()),
  editCustomMetadataField: (customField: CustomField) => dispatch(editCustomMetadataField(customField)),
  saveCustomMetadataField: (customField: UnsavedCustomField) =>
    dispatch(saveCustomField(customField, saveSuccessFn, saveFailureFn)),
  updateExistingCustomMetadata: (customField: UnsavedCustomField) =>
    dispatch(editCustomField(customField, editSuccessFn, editFailureFn)),
  deleteCustomMetadataField: (customField: CustomField) =>
    dispatch(deleteCustomField(customField, deleteSuccessFn, deleteFailureFn)),
  cancelSave: () => dispatch(cancelSaveCustomField())
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomMetadata);
