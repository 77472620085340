/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { get } from "lodash";
import { t } from "i18n";

import { PartialAppState, RolesPageInspectorType } from "../state";
import { cancelSaveRoleAndClearCurrentRoleFromInspector, saveRole, updateParent } from "../async-action-creators";
import { switchActionCategoryTabAction, updateRole } from "../action-creators";
import TextField from "components/text-field/text-field";
import Select from "components/select/select";
import RadioButton from "components/radio-button/radio-button";
import Checkbox from "components/checkbox/checkbox";
import { FixedTabList, Panel } from "components/tabs/tabs";
import Inspector from "components/inspector/inspector";
import { RoleTypes, ActionCategoriesTypes, ActionTypes, AnyRole, CategoryName } from "api/roles";
import { actionCategories } from "../action-categories";
import { getEligibleParents, updateRoleWithCategoriesActions } from "../utils";
import { isClientValidationError } from "utils/validation.utils";
import FieldLabel from "components/field-label/field-label";
import ErrorMessage from "components/error-message/error-message";
import styles from "./inspector.module.css";

interface StateProps {
  inspectorType: RolesPageInspectorType | null;
  role: AnyRole | null;
  roles: RoleTypes[];
  actionCategoriesWithActions: ActionCategoriesTypes;
  selectedActionCategoryTab: string;
  errors: Error | null;
}

interface DispatchProps {
  cancelSaveRoles: () => void;
  updateRole: (updatedRole: AnyRole) => void;
  saveRole: (role: AnyRole) => void;
  updateParent: (parent: { id: number; name: string }) => void;
  switchActionCategoryTab: (value: string) => void;
}

type Props = StateProps & DispatchProps;

const isParentAction = (action: ActionTypes, role: AnyRole, roles: RoleTypes[]) => {
  let isActionDisabled = false;
  const parentRole = roles && roles.find((parentRole) => parentRole.id === role["parent-role-id"]);
  if (parentRole) {
    const actionCategories = parentRole ? parentRole["action-categories"] : [];
    const actions = actionCategories[CategoryName.Content]
      .concat(actionCategories[CategoryName.Admin])
      .concat(actionCategories[CategoryName.Analytics]);
    isActionDisabled = actions.find((item: string) => item === action.name);
  } else {
    isActionDisabled = false;
  }

  return isActionDisabled;
};

function isActionPartOfRole(role: AnyRole, { category, action }: { category: string; action: ActionTypes }) {
  const existingAction =
    role["action-categories"][category] &&
    role["action-categories"][category].find((actionName: string) => actionName === action.name);

  return !!existingAction;
}

const RolesInspector: React.SFC<Props> = ({
  cancelSaveRoles,
  inspectorType,
  roles,
  role,
  actionCategoriesWithActions,
  selectedActionCategoryTab,
  switchActionCategoryTab,
  updateRole,
  saveRole,
  errors,
  updateParent
}) => {
  const title =
      inspectorType === RolesPageInspectorType.Create
        ? t("users-and-roles.create_role")
        : t("users-and-roles.edit_role"),
    actionButtonLabel =
      inspectorType === RolesPageInspectorType.Create ? t("users-and-roles.create") : t("users-and-roles.update");
  return (
    <Inspector
      title={title}
      isActive={!!inspectorType}
      onClose={() => cancelSaveRoles()}
      actionButtonLabel={actionButtonLabel}
      onActionButtonClick={() => saveRole(role!)}>
      {role && (
        <React.Fragment>
          <TextField
            label={t("users-and-roles.role_fields.name")}
            value={role.name}
            onChange={(value) => updateRole({ ...role, name: value })}
            hint={t("users-and-roles.role_fields.name_hint")}
            errorMessage={
              //TODO: move to I18n
              //TODO: I18n translate server error message
              isClientValidationError<AnyRole>(errors) ? get(errors, ["errors", "name", "0"]) : get(errors, ["message"])
            }
          />
          <Select
            label={t("users-and-roles.role_fields.extract_role_of")}
            options={getEligibleParents(roles, role)}
            getOptionLabel={(role) => role.name}
            getOptionValue={(role) => role.id.toString()}
            placeholder={
              getEligibleParents(roles, role).length === 0
                ? t("users-and-roles.role_fields.select_parent_placeholder_when_no_parent")
                : t("users-and-roles.role_fields.select_parent_placeholder")
            }
            value={
              role["parent-role-id"]
                ? getEligibleParents(roles, role).find((parentRole) => parentRole.id === role["parent-role-id"])
                : undefined
            }
            onChange={(parent: { id: number; name: string }) => updateParent(parent)}
          />
          <div className={styles["role-author-container"]}>
            <FieldLabel label={t("users-and-roles.role_fields.is_author")} classname="role-full-width-label" />
            <RadioButton
              id="users-and-roles-is-author-yes"
              label={t("users-and-roles.role_fields.yes")}
              name="is-author"
              checked={role["is-author"] === true}
              onChange={(value) => updateRole({ ...role, "is-author": true })}
            />
            <RadioButton
              id="users-and-roles-is-author-no"
              label={t("users-and-roles.role_fields.no")}
              name="is-author"
              checked={role["is-author"] === false}
              onChange={(value) => updateRole({ ...role, "is-author": false })}
            />
          </div>
          <div className={styles["role-default-container"]}>
            <FieldLabel label={t("users-and-roles.role_fields.is_default")} classname="role-full-width-label" />
            <RadioButton
              id="users-and-roles-is-default-yes"
              label={t("users-and-roles.role_fields.yes")}
              name="is-default"
              checked={role["is-default"] === true}
              onChange={(value) => updateRole({ ...role, "is-default": true })}
            />
            <RadioButton
              id="users-and-roles-is-default-no"
              label={t("users-and-roles.role_fields.no")}
              name="is-default"
              checked={role["is-default"] === false}
              onChange={(value) => updateRole({ ...role, "is-default": false })}
            />
          </div>
          {errors && (
            <ErrorMessage
              message={isClientValidationError<AnyRole>(errors) ? get(errors, ["errors", "actions", "0"]) : ""}
            />
          )}
          <FixedTabList
            tabs={actionCategories}
            value={selectedActionCategoryTab}
            onChange={(value) => switchActionCategoryTab(value)}
          />
          <Panel>
            <div className={styles["actions-container"]} data-test-id="actions-container">
              {actionCategoriesWithActions[selectedActionCategoryTab].map((action: ActionTypes, index: number) => (
                <Checkbox
                  label={action.name}
                  id={`action.name-${index}`}
                  key={`action.name-${index}`}
                  disabled={isParentAction(action, role, roles)}
                  checked={isActionPartOfRole(role, { action, category: selectedActionCategoryTab })}
                  onChange={(value) =>
                    updateRole(updateRoleWithCategoriesActions(role, selectedActionCategoryTab, action.name, value))
                  }
                />
              ))}
            </div>
          </Panel>
        </React.Fragment>
      )}
    </Inspector>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  const rolesPage = state.usersAndRoles.rolesPage;
  return {
    inspectorType: rolesPage.ui.inspector,
    role: rolesPage.app.currentRole,
    roles: rolesPage.roles,
    errors: rolesPage.ui.saveErrors,
    selectedActionCategoryTab: rolesPage.ui.selectedActionCategoryTab,
    actionCategoriesWithActions: state.config["action-categories"]
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    cancelSaveRoles: () => dispatch(cancelSaveRoleAndClearCurrentRoleFromInspector()),
    updateRole: (updatedRole) => dispatch(updateRole(updatedRole)),
    switchActionCategoryTab: (value) => dispatch(switchActionCategoryTabAction(value)),
    saveRole: (role) => dispatch(saveRole(role)),
    updateParent: (parent) => dispatch(updateParent(parent))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RolesInspector);

export { RolesInspector };
