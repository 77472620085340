/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import InfiniteScrollList from "components/infinite-scroll-list/infinite-scroll-list";
import { compose, AnyAction } from "redux";
import { connect } from "react-redux";
import { t } from "i18n";
import { navigate } from "utils/routes.utils";
import { STORY_EDITOR_READ_ONLY_PATH } from "../../routes";
import Chip from "components/chip/chip";
import Button from "components/button/button";
import Close from "components/icons/close";

import Spinner from "components/spinner/spinner";
import styles from "./timeline.module.css";
import { formattedDate } from "../../../../utils";
import { summarizeEvent } from "./event-summary";
import { fetchTimeline, closeTimelineAction, setTimelineEventAction } from "../../async-action-creators";

import { openStoryRestoreModal, setIsViewingReadOnlyVersion } from "../../action-creators";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState } from "../../state";
import { StoryId } from "api/primitive-types";
import { Page, TimelineEvent } from "api/activity-log";
import { Features } from "api/route-data/story-route-data";
import { get } from "lodash";

const cx = classnames.bind(styles);

interface StateProps {
  storyId: StoryId;
  readOnlyStoryVersion: StoryId;
  events: Array<TimelineEvent>;
  page: Page;
  selectedEvent: TimelineEvent;
  features: Features;
  isStoryLocked: boolean;
  bannerMessage: string | null;
  storyPageBannerMessage: string | null;
  isSliderWindowOpen: boolean;
}

interface DispatchProps {
  setReadOnly: (isReadOnly: boolean) => void;
  fetchTimeline: (storyId: StoryId, page: Page) => void;
  openStoryRestoreModal: (value: boolean, versionId: StoryId) => void;
  viewStoryVersion: (id: StoryId, versionId: StoryId, timeline: boolean) => void;
  closeTimeline: () => void;
  setTimelineEvent: (event: TimelineEvent) => void;
}

type Props = StateProps & DispatchProps;

class Timeline extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchTimeline(this.props.storyId, this.props.page);
    this.props.setReadOnly(true);
  }

  loadMore = () => {
    this.props.fetchTimeline(this.props.storyId, { ...this.props.page, offset: this.props.page.offset + 10 });
  };

  componentWillUnmount() {
    this.props.setReadOnly(false);
  }

  render() {
    const eventClasses = (event: TimelineEvent) =>
      cx("story-timeline-event", {
        "is-selected":
          this.props.selectedEvent.id === event.id ||
          this.props.selectedEvent["content-version-id"] === event["content-version-id"]
      });

    const containerClass = cx("story-timeline-container", {
      "with-banner": this.props.bannerMessage || this.props.storyPageBannerMessage,
      "with-slider-open": this.props.isSliderWindowOpen
    });

    return (
      <div id="story-timeline-container" className={containerClass} data-test-id="story-timeline-container">
        <div className={styles["story-timeline-header"]} data-test-id="story-timeline-header">
          <div
            className={styles["story-timeline-close"]}
            onClick={this.props.closeTimeline}
            data-test-id="story-timeline-close">
            <Close />
          </div>
          <h3 className={styles["story-timeline-title"]} data-test-id="story-timeline-title">
            {t("story-editor.timeline.title")}
          </h3>
        </div>
        <InfiniteScrollList
          scrollableTarget="story-timeline-container"
          dataLength={this.props.events.length}
          next={this.loadMore}
          hasMore={this.props.page.total - this.props.events.length > 0}
          loader={Spinner}>
          <ul className={styles["story-timeline-events"]} data-test-id="story-timeline-events">
            {this.props.events.map((event, index) => (
              <li key={`timeline-event-${event.id}`} className={eventClasses(event)} data-test-id="timeline-event">
                <div
                  className={styles["story-timeline-event-container"]}
                  data-test-id="story-timeline-event-container"
                  onClick={() => this.props.setTimelineEvent(event)}>
                  <p className={styles["story-timeline-event-meta-name"]} data-test-id="story-timeline-event-meta-name">
                    {event["member-name"]}
                  </p>
                  <p className={styles["story-timeline-event-meta-date"]} data-test-id="story-timeline-event-meta-date">
                    {formattedDate(event["changed"], "MMM dd, hh:mm a")}
                  </p>
                  <Chip value={summarizeEvent(event, index === this.props.page.total - 1)} invert={true} />
                  {this.props.features.isActivityLogViewStoryVersionEnabled &&
                    (event.id === this.props.selectedEvent.id ||
                      event["content-version-id"] === this.props.selectedEvent["content-version-id"]) && (
                      <div className={styles["story-timeline-actions"]} data-test-id="story-timeline-actions">
                        <Button
                          testId="story-editor-timeline-view-btn"
                          type="default"
                          onClick={() =>
                            this.props.viewStoryVersion(this.props.storyId, event["content-version-id"], true)
                          }
                          disabled={this.props.readOnlyStoryVersion === event["content-version-id"]}
                          classname={styles["story-timeline-actions-button"]}>
                          {t("story-editor.timeline.view")}
                        </Button>
                        {this.props.features.isActivityLogRestoreStoryVersionEnabled &&
                          this.props.events[0]["content-version-id"] !== event["content-version-id"] &&
                          !this.props.isStoryLocked && (
                            <Button
                              testId="story-editor-timeline-restore-btn"
                              type="default"
                              onClick={() => this.props.openStoryRestoreModal(true, event["content-version-id"])}
                              classname={styles["story-timeline-actions-button "]}>
                              {t("story-editor.timeline.restore")}
                            </Button>
                          )}
                      </div>
                    )}
                </div>
              </li>
            ))}
          </ul>
        </InfiniteScrollList>
      </div>
    );
  }
}

const mapStateToProps = (state: PartialAppState) => {
  return {
    storyId: state.storyEditor.story["story-content-id"],
    readOnlyStoryVersion: state.storyEditor.story["story-version-id"],
    events: state.storyEditor.timeline.events,
    page: state.storyEditor.timeline.page,
    selectedEvent: state.storyEditor.ui.timelineEvent,
    features: state.features,
    isStoryLocked: state.storyEditor.ui.isStoryLocked,
    bannerMessage: state.config.publisherWideBannerMessage,
    storyPageBannerMessage: state.storyEditor.ui.banner && state.storyEditor.ui.banner.message,
    isSliderWindowOpen: get(state, ["slider", "sliderWindow", "open"], false)
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    fetchTimeline: (storyId, page) => dispatch(fetchTimeline(storyId, page)),
    setReadOnly: (isReadOnly) => dispatch(setIsViewingReadOnlyVersion(isReadOnly)),
    openStoryRestoreModal: (value, versionId) => dispatch(openStoryRestoreModal(value, versionId)),
    viewStoryVersion: (id, versionId, timeline) => {
      dispatch(navigate(STORY_EDITOR_READ_ONLY_PATH, { id, versionId }, { timeline }));
    },
    closeTimeline: () => dispatch(closeTimelineAction()),
    setTimelineEvent: (event) => dispatch(setTimelineEventAction(event))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Timeline);

export { Timeline };
