/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import Button from "components/button/button";
import Spinner from "components/spinner/spinner";
import Tooltip from "components/tooltip/tooltip";
import NoContentCard from "components/no-content-card/no-content-card";
import ExclamationCircle from "components/icons/exclamation-circle";
import { loadTaskData, closeTask, closeAllSubtasks } from "./async-action-creators";

import styles from "./task-publish-inspector.module.css";

import { t } from "i18n";
import TaskManagerLogin from "pages/user/tasks/taskmanagerlogin/taskmanagerlogin";

export class TaskPublishComponent extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.getOpenSubtasks = this.getOpenSubtasks.bind(this);
    this.showLoaderOrError = this.showLoaderOrError.bind(this);
  }

  componentDidMount() {
    this.props.loadTaskData(this.props.taskId);
  }

  getOpenSubtasks(task) {
    const subtasks = task && task.subtasks ? task.subtasks.filter((subtask) => !subtask.completed) : [];
    return subtasks;
  }

  showLoaderOrError() {
    if (this.props.isLoading) {
      return <Spinner />;
    } else if (this.props.error) {
      if (this.props.error.status === 403) {
        return;
      }
      const errorData = JSON.parse(this.props.error.message);
      if (errorData.status === 401) {
        return <TaskManagerLogin asana={this.props.asana} />;
      } else {
        return (
          <NoContentCard>
            <div className={styles["task-error-retry-message"]}>
              <ExclamationCircle width="48" height="48" color="#5f6978" />
              <div className={styles["task-error-message"]}>{t("tasks.messages.error")}</div>
              <button
                className={styles["task-error-retry-link"]}
                onClick={() => {
                  this.props.loadTaskData(this.props.taskId);
                }}>
                {t("tasks.task_action.retry")}
              </button>
            </div>
          </NoContentCard>
        );
      }
    }
    return null;
  }

  render() {
    const { isLoading, task, closeTask, closeAllSubtasks } = this.props;
    const openSubtasks = this.getOpenSubtasks(task);
    return (
      <div className={styles["story-subtask-status"]} data-test-id="story-subtask-status">
        {this.showLoaderOrError()}
        {!isLoading && task && task.subtasks && task.subtasks.length > 0 && (
          <div className={styles["story-subtask-list"]} data-test-id="story-subtask-list">
            <h3 data-test-id="tasks-messages-update-subtask">{t("tasks.messages.update_subtasks")}</h3>
            {task.subtasks.map((subtask, index) => (
              <div className={styles["story-subtask-row"]} key={`subtask-${index}`} data-test-id="story-subtask-row">
                <div
                  className={styles["story-subtask-assignee-container"]}
                  data-test-id="story-subtask-assignee-container"
                  data-tip
                  data-for={`assignee-image-${subtask.id}`}>
                  <span
                    className={styles["story-subtask-assignee-image-container"]}
                    data-test-id="story-subtask-assignee-image-container">
                    {subtask.assignee && subtask.assignee.photo && (
                      <img
                        className={styles["story-subtask-assignee-image"]}
                        src={subtask.assignee.photo.image_60x60}
                        alt={subtask.assignee.name}
                      />
                    )}
                  </span>
                  <Tooltip
                    id={`assignee-image-${subtask.id}`}
                    place="bottom"
                    effect="solid"
                    value={subtask.assignee ? subtask.assignee.name : "Unassigned"}
                  />
                </div>
                <span className={styles["story-subtask-name"]} data-test-id="story-subtask-name">
                  {subtask.name}
                </span>
                {!subtask.completed && (
                  <Button
                    type="default"
                    testId="close-subtask-btn"
                    onClick={() => {
                      closeTask(subtask.id);
                    }}>
                    {t("tasks.task_action.done")}
                  </Button>
                )}
              </div>
            ))}
            {openSubtasks.length > 0 && (
              <div className={styles["story-subtask-footer"]} data-test-id="story-subtask-footer">
                <span className={styles["footer-text"]} data-test-id="story-subtask-footer-text">
                  {t("tasks.task_action.close_all_subtasks", {
                    subtaskCount: openSubtasks.length
                  })}
                </span>
                <Button
                  type="secondary"
                  testId="close-subtask-btn"
                  onClick={() => {
                    closeAllSubtasks(openSubtasks);
                  }}>
                  {t("tasks.task_action.close_from_story")}
                </Button>
              </div>
            )}
          </div>
        )}
        {!isLoading && task && !task.completed && (
          <div className={styles["story-subtask-footer"]} data-test-id="story-subtask-footer">
            <span className={styles["footer-text"]} data-test-id="story-subtask-footer-text">
              {t("tasks.task_action.close_task")}
            </span>
            <Button
              type="secondary"
              testId="close-task-btn"
              onClick={() => {
                closeTask(task.id);
              }}>
              {t("tasks.task_action.close_from_story")}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    story: state.storyEditor.story,
    task: state.storyEditor.taskState.task,
    isLoading: state.storyEditor.ui.taskUI.isLoading,
    error: state.storyEditor.ui.taskUI.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadTaskData: (taskId) => dispatch(loadTaskData(taskId)),
    closeTask: (taskId) => dispatch(closeTask(taskId)),
    closeAllSubtasks: (taskList) => dispatch(closeAllSubtasks(taskList))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TaskPublishComponent);
