/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const WORKSPACE_PATH = "/workspace";
export const WORKSPACE_TABS_PATH = `${WORKSPACE_PATH}/:tabSlug(all|open|pending-approval|approved|qa|proof-reading-done|published|rejected|scheduled|edited)`;
export const WORKSPACE_FILTERS_PATH = `${WORKSPACE_TABS_PATH}/filters`;
export const WORKSPACE_BULK_ACTION_PATH = `${WORKSPACE_TABS_PATH}/bulk-actions`;
export const WORKSPACE_SAVE_FILTER_PATH = `${WORKSPACE_TABS_PATH}/save-filter`;
export const WORKSPACE_SAVED_FILTERS_PATH = `${WORKSPACE_TABS_PATH}/filters/saved`;
export const WORKSPACE_DELETE_STORY_PATH = `${WORKSPACE_TABS_PATH}/stories/:storyId/delete`;
