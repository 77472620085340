/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RouteComponentProps } from "react-router";
import { t } from "i18n";

import wrapPage from "containers/page/page";
import ContentHeader from "../../../components/content-header/content-header";
import { RoleTypes } from "api/roles";
import { PartialAppState } from "./state";
import Inspector from "./components/inspector";
import { loadRolesData, editRole, addNewRole } from "./async-action-creators";
import Loader from "./loader";
import { LoaderState } from "behaviors/loader/state";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import Role from "./components/role";
import { ROLES_EDIT_PATH, ROLES_NEW_PATH } from "./routes";

import classnames from "classnames/bind";
import styles from "./roles.module.css";
import { navigateFn } from "utils/routes.utils";

const cx = classnames.bind(styles);
interface StateProps {
  roles: RoleTypes[];
  loader: LoaderState;
  title: string;
}

interface DispatchProps {
  loadRolesData: () => void;
  editRole: (roleId: string) => void;
  addNewRole: () => void;
}

type Props = RouteComponentProps<any> & StateProps & DispatchProps & { isBannerPresent: boolean };

export const Roles: React.SFC<Props> = ({ roles, loader, addNewRole }) => {
  return (
    <React.Fragment>
      <main className={cx("roles-page-container", "roles-page")}>
        <LoaderWrapper className="roles-progress-main-area" component={Loader} loader={loader}>
          <ContentHeader
            title={t("users-and-roles.roles")}
            actionButtonLabel={t("users-and-roles.create_role")}
            actionButtonType="primary"
            handleActionButtonClick={() => addNewRole()}
          />
          {roles.map((role) => (
            <Role role={role} key={`role-${role.id}`} />
          ))}
        </LoaderWrapper>
        <Inspector />
      </main>
    </React.Fragment>
  );
};

export class RolesWithRoutes extends React.Component<Props> {
  async componentDidMount() {
    await this.props.loadRolesData();
    this.onEnter();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.match.url !== prevProps.match.url) {
      this.onEnter();
    }
  }

  onEnter() {
    switch (this.props.match.path) {
      case ROLES_NEW_PATH:
        this.props.addNewRole();
        break;

      case ROLES_EDIT_PATH:
        this.props.editRole(this.props.match.params.id);
        break;

      default:
        break;
    }
  }

  render() {
    return <Roles {...this.props} />;
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    roles: state.usersAndRoles.rolesPage.roles,
    loader: state.usersAndRoles.rolesPage.ui.main,
    title: "roles"
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, any, AnyAction>): DispatchProps => {
  const navigate = dispatch(navigateFn);
  return {
    loadRolesData: () => dispatch(loadRolesData()),
    editRole: (roleId) => dispatch(editRole(roleId)),
    addNewRole: () => {
      navigate(ROLES_NEW_PATH);
      dispatch(addNewRole());
    }
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  wrapPage()
)(RolesWithRoutes);
