/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { get } from "lodash";
import { t } from "i18n";

import { Switch } from "components/switch/switch";
import Checkbox from "components/checkbox/checkbox";
import TextField from "components/text-field/text-field";
import styles from "./field-container.module.css";
import { TemplateFieldErrors } from "../shared-types";
import FieldLabel from "components/field-label/field-label";

interface Props {
  fieldToDisplay: { label: string; path: string; hasCharacterLength: boolean };
  field: {
    "can-be-hidden-in-editor?": boolean;
    validations: {
      hidden: boolean;
      "hard-limit": number;
      mandatory: boolean;
    };
  };
  onFieldChange: (key: string, value: Object) => void;
  setErrors: (a: { [path: string]: TemplateFieldErrors }) => void;
  errors: TemplateFieldErrors;
  id: number;
  hideFieldToggle?: boolean;
}

const FieldContainer: React.SFC<Props> = ({
  field,
  onFieldChange,
  setErrors,
  errors,
  id,
  fieldToDisplay,
  hideFieldToggle
}) => {
  const isFieldEnabled = !get(field, ["validations", "hidden"], false);
  const isHiddenInEditor = get(field, ["can-be-hidden-in-editor?"]) === false;
  const isFieldMandatory = get(field, ["validations", "mandatory"]);

  const getErrorMessage = (validation: string) => {
    return get(errors, ["config", "fields", `${fieldToDisplay.path}`, "validations", validation]);
  };

  return (
    <div className={styles["template-field-container"]}>
      <FieldLabel label={fieldToDisplay.label} />
      {!isHiddenInEditor && !hideFieldToggle ? (
        <Switch
          checked={isFieldEnabled}
          disabled={isHiddenInEditor}
          onChange={(value) => onFieldChange(fieldToDisplay.path, { hidden: !value })}
          id={`template-fields-${id}`}
          classname={"field-container-switch"}
        />
      ) : (
        <div></div>
      )}

      <Checkbox
        id={fieldToDisplay.label}
        label={t("settings.storyTemplates.mandatory_field")}
        checked={isFieldMandatory}
        onChange={(value) => onFieldChange(fieldToDisplay.path, { mandatory: !isFieldMandatory })}
        disabled={!isFieldEnabled || isHiddenInEditor}
        classname={"story-template-checkbox"}
      />
      {/*Empty div is to create an empty slot in grid*/}
      <div></div>
      {fieldToDisplay.hasCharacterLength && (
        <>
          <TextField
            label={t("settings.storyTemplates.min_characters")}
            value={get(field, ["validations", "min-limit"], "").toString()}
            onChange={(value) => onFieldChange(fieldToDisplay.path, { "min-limit": value.trim() })}
            errorMessage={getErrorMessage("min-limit")}
            readOnly={!isFieldEnabled}
            classname={"max-character-limit"}
          />
          <TextField
            label={t("settings.storyTemplates.max_characters")}
            value={get(field, ["validations", "hard-limit"], "").toString()}
            onChange={(value) => onFieldChange(fieldToDisplay.path, { "hard-limit": value.trim() })}
            errorMessage={getErrorMessage("hard-limit")}
            readOnly={!isFieldEnabled}
            classname={"max-character-limit"}
          />
        </>
      )}
    </div>
  );
};
export default FieldContainer;
