/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface Icon {
  color?: string;
  width?: string;
  height?: string;
}

const Home = ({ width = "24", height = "24", color = "var(--white)" }: Icon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    fill={color}
    viewBox="0 0 24 24">
    <defs>
      <path
        id="a-home"
        d="M23.91 10.76v.045c.06.03.09.082.09.157a.446.446 0 0 1-.045.202l-.584.719c-.03.06-.082.09-.157.09a.28.28 0 0 1-.202-.09L21.125 10.4v10.287c0 .39-.142.727-.427 1.011a1.381 1.381 0 0 1-1.01.427h-5.211a.52.52 0 0 1-.382-.157.52.52 0 0 1-.158-.382v-5.93h-2.874v5.93a.52.52 0 0 1-.158.382.52.52 0 0 1-.382.157h-5.21c-.39 0-.727-.142-1.011-.427a1.381 1.381 0 0 1-.427-1.01V10.4l-1.887 1.483a.214.214 0 0 1-.202.067.21.21 0 0 1-.157-.112l-.584-.674A.446.446 0 0 1 1 10.962a.28.28 0 0 1 .09-.202l10.512-8.446c.27-.21.569-.314.898-.314.33 0 .629.105.898.314l6.29 5.077V5.145c0-.09.03-.158.09-.203a.299.299 0 0 1 .179-.067h.898c.06 0 .12.022.18.067.06.045.09.113.09.203v3.414l2.785 2.2zm-4.223 9.927V9.232l-7.007-5.66c-.12-.09-.24-.09-.36 0l-7.008 5.66v11.455h4.313v-5.93a.52.52 0 0 1 .157-.381.52.52 0 0 1 .382-.157h4.672a.52.52 0 0 1 .382.157.52.52 0 0 1 .157.382v5.93h4.312z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b-home" fill={color}>
        <use xlinkHref="#a-home" />
      </mask>
      <use fill={color} xlinkHref="#a-home" />
      <g fill={color} mask="url(#b-home)">
        <path d="M0 0h24v24H0z" />
      </g>
      <path d="M0 0h24v24H0z" mask="url(#b-home)" />
    </g>
  </svg>
);

export default Home;
