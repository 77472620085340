/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import classnames from "classnames/bind";
import { Top } from "api/analytics";
import Delta from "../delta/delta";

import styles from "./top-list.module.css";
import { getTopListHeader } from "pages/analytics/utils";
import Chevron from "components/icons/chevron";
import { AuthorId, SectionId, StoryId } from "api/primitive-types";
import Button from "components/button/button";
import Spinner from "components/spinner/spinner";

const cx = classnames.bind(styles);

interface Props {
  list: Top[];
  dimension: string;
  metrics: string;
  subList?: Top[];
  openSublist?: (id: AuthorId | SectionId | StoryId) => void;
  isSublistOpen?: boolean;
  openItemId?: null | AuthorId;
  closeSublist?: () => void;
  showDelta?: boolean;
}

interface SubListItemProps {
  item: Top;
}

const SubListItem: React.SFC<SubListItemProps> = ({ item }) => {
  return (
    <li className={styles["top-sublist__item"]} key={item.id}>
      <h5 className={styles["top-sublist__item-title"]}>{item.name}</h5>
      <div className={styles["top-sublist__item-view"]}>
        <span>{item.count}</span>
        <Delta value={item.change} variant="medium" />
      </div>
    </li>
  );
};

const TopList: React.SFC<Props> = ({
  dimension,
  metrics,
  list,
  subList,
  openSublist,
  isSublistOpen,
  openItemId,
  closeSublist,
  showDelta = true
}) => {
  return (
    <div className={styles["analytics-top-list-container"]}>
      <h3 className={styles["analytics-top-list-container__title"]}>
        {getTopListHeader(dimension, metrics, list.length)}
      </h3>
      <ul className={cx("analytics-top-list-container__top-list", "top-list")}>
        <li className={cx("top-list__item", "item-header")}>
          <span>{t(`analytics.${dimension}`)}</span>
          <span>{t(`analytics.${metrics}`)}</span>
        </li>
        {list &&
          list.map((item) => (
            <React.Fragment>
              {openItemId === item.id ? (
                <li>
                  <div className={styles["top-list__item"]}>
                    <h5 className={styles["top-list__item-title"]}>
                      {item.name}
                      {openSublist && !isSublistOpen && (
                        <Button
                          type="transparent"
                          onClick={() => openSublist(item.id)}
                          classname="analytics-top-list-item-title-button">
                          <Chevron fill="#5f6978" variant="down" />
                        </Button>
                      )}
                      {isSublistOpen && (
                        <Button
                          type="transparent"
                          onClick={() => closeSublist && closeSublist()}
                          classname={styles["analytics-top-list-item-title-button"]}>
                          <Chevron fill="#5f6978" variant="up" />
                        </Button>
                      )}
                    </h5>
                    <div className={styles["top-list__item-view"]}>
                      <span>{item.count}</span>
                      {showDelta ? <Delta value={item.change} variant="medium" /> : null}
                    </div>
                  </div>
                  <ul className={cx("analytics-top__sublist", { "is--open": isSublistOpen })}>
                    {subList && subList.length !== 0 ? subList.map((item) => <SubListItem item={item} />) : <Spinner />}
                  </ul>
                </li>
              ) : (
                <li>
                  <div className={styles["top-list__item"]}>
                    <h5 className={styles["top-list__item-title"]}>
                      {item.name}
                      {openSublist && (
                        <Button type="transparent" onClick={() => openSublist(item.id)}>
                          <Chevron fill="#5f6978" variant="down" />
                        </Button>
                      )}
                    </h5>
                    <div className={styles["top-list__item-view"]}>
                      <span>{item.count}</span>
                      {showDelta ? <Delta value={item.change} variant="medium" /> : null}
                    </div>
                  </div>
                </li>
              )}
            </React.Fragment>
          ))}
      </ul>
    </div>
  );
};

export default TopList;
