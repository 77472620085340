/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { replace } from "connected-react-router";
import isEmpty from "lodash/isEmpty";
import React, { createRef } from "react";
import { connect } from "react-redux";
import { AnyAction, compose } from "redux";

import {
  STORY_EDITOR_HERO_IMAGE_GALLERY_PATH,
  STORY_EDITOR_IMPORT_PATH,
  STORY_EDITOR_MANAGE_ADVANCED_METADATA_PATH,
  STORY_EDITOR_PATH
} from "pages/story-editor/routes";
import { formattedDate } from "utils";
import { locationHasTruthyQueryParam, navigateFn, route } from "../../utils/routes.utils";
import { currentCardLoading, PartialAppState } from "./state";

import Chevron from "components/icons/chevron";
import Spinner from "components/spinner/spinner";
import wrapPage from "containers/page/page";
import { wrapRealtime } from "helpers/ably/realtime-provider";
import HeaderCard from "./header-card/header-card";
import ProseMirrorStoryEditor from "./prosemirror/story-editor";

import StoryEditorInspector from "pages/story-editor/components/inspector/inspector";
import PublishInspector from "pages/story-editor/components/publish-inspector/publish-inspector";

import { getStory, Story, StoryTemplate, UnsavedStory, WorkflowTransitions } from "api/story";
import { setIsStoryModifiedState, setStoryTransitionStatus } from "./action-creators";
import { lockStoryForEdit, updateCurrentMemberIsEditing } from "./lock-story-for-edit";
import classnames from "classnames/bind";
import { actions } from "./actions";
import {
  cancelPublishAndSaveAction,
  cancelPublishAndSaveStoryForBlockingAutoSaveAction,
  closeTimelineAction,
  getStoryAction,
  loadMoreCardsAction,
  loadMoreStoryElementsAction,
  loadStory,
  resetStoryEditorStateAction,
  saveStoryAction,
  setSelectedStoryAction,
  setTemplateAction,
  updateEmbargoAction,
  updateRouteDataAction
} from "./async-action-creators";
import StoryEditorHeader from "./components/header/header";
import Preview from "./components/preview/preview";
import Timeline from "./components/timeline/timeline";
import InsertCardBar, { Position } from "./insert-card-bar/insert-card-bar";
import { INITIAL_UNSAVED_STORY } from "./reducers/initial-state";
import styles from "./story-editor.module.css";

import { TimelineEvent } from "api/activity-log";
import { CardId, StoryId } from "api/primitive-types";
import { Asana } from "api/route-data/route-data";
import { Fields, StoryTemplateFields } from "api/route-data/story-route-data";
import { NOTIFICATION_INFO } from "containers/page/actions";
import { ConnectionState, RealtimeMember } from "helpers/ably/types";
import { Location } from "history";
import { t } from "i18n";
import CleanUpEmptyElementsModal from "pages/story-editor/components/clean-up-modal/clean-up-empty-elements-modal";
import ManageBar from "pages/story-editor/components/manage-bar/manage-bar";
import RestoreStoryVersionModal from "pages/story-editor/components/restore-modal/restore-modal";
import { match as Match } from "react-router";
import { ThunkDispatch } from "redux-thunk";
import { history } from "store";
import { selectIsDesktopSizeViewport } from "store/viewport";
import EmbargoBanner from "./components/embargo/banner";
import EmbargoInspector, { InspectorType } from "./components/embargo/embargo";
import { addTemplateCards } from "./action-creators";
import InfoBar from "./components/info-bar/info-bar";
import LoadMoreCards from "./components/load-more-cards/load-more-cards";
import PhotoEditorWrapper from "./components/photo-editor-wrapper/photo-editor-wrapper";
import SeoScore from "./components/seo-score/seo-score";
import { Member } from "api/route-data/route-data";
import { get, size } from "lodash";
import { VisibleOnScroll } from "custom-hooks/scroll";

const cx = classnames.bind(styles);

const MILLIS_BETWEEN_AUTO_SAVES = 250;
const MILLIS_SINCE_LAST_UPDATE = 10 * 1000;

interface OwnProps {
  match: Match<{ id: string; cardId: CardId; storyId: StoryId; versionId: StoryId; importStoryId: StoryId }>;
  location: Location;
  blockStorySave: boolean;
  cancelPublishAndSave(updateStoryStatus?: Function): void;
  realtimeUpdateCurrentMember(realtimeCurrentMember: RealtimeMember): void;
  readOnlyVersionId?: StoryId;
  showPublishInspector: boolean;
  realtimeMembers: RealtimeMember[];
  realtimeCurrentMember: RealtimeMember;
  realtimeConnectionState: ConnectionState;
}

interface StateProps {
  story: Story;
  readOnlyStoryVersionId?: StoryId;
  isBlockConcurrentStoryEditEnabled: boolean;
  storyId: StoryId;
  isDesktopSizeViewport: boolean;
  templateFields: StoryTemplateFields;
  storyTemplateFields: Fields;
  showPreviewPane: boolean;
  showTimelinePane: boolean;
  showSeoScorePane: boolean;
  isStoryModified: boolean;
  lastUpdatedTime: number;
  locationDetails: Match<{ importStoryId: StoryId }>;
  isStoryLocked: boolean;
  latestTimelineEvent: TimelineEvent | null;
  readOnlyTimelineEvent: TimelineEvent;
  changeStatusAction: AnyAction | null;
  cardId?: CardId;
  disableStorySave: boolean;
  publisherId: number;
  asanaCreds: Asana;
  title: string;
  isReferenceUrlEnabled: boolean;
  numberOfCardsToLoad: number;
  numberOfCardsShown: number;
  cardsLoaded: CardId[];
  currentCardLoading: currentCardLoading;
  cardsToLoad: CardId[];
  isStorySaving: boolean;
  isBannerPresent?: boolean;
  showStoryInspector: boolean;
  showEmbargoInspector: boolean;
  member: Member;
  isSliderWindowOpen: boolean;
  isCurrentStoryPrint: boolean;
}

interface DispatchProps {
  navigate(path: string, params?: { [key: string]: number | string | boolean }): void;
  getStory(storyId: StoryId, params?: { versionId?: StoryId }, loadAllElements?: boolean): void;
  updateRouteData(path: string, params: any): void;
  replaceRoute(id: string): void;
  loadStory(story: UnsavedStory, opts: { [key: string]: any }): void;
  resetIsStoryModified(): void;
  setTemplate(storyTemplate: string): void;
  openGalleryInspector(): void;
  setWorkflowTransition(workflowTransitions: WorkflowTransitions): void;
  saveStory(callback?: Function, action?: AnyAction): void;
  cancelPublishAndSaveStoryForBlockingAutoSave(callback: Function): void;
  showSnackBar(message: string): void;
  closeTimeline(): void;
  setImportStory(importStoryId: StoryId): void;
  closeAll(storyId?: StoryId): void;
  resetStoryEditorState(): void;
  loadMoreCards(story: Story, numberOfCardsShown: number, numberOfCardsToLoad: number): void;
  loadMoreElements(): void;
  setStoryAndWorkflowTransition(opts: { story: Story; "workflow-transitions": WorkflowTransitions }): void;
  cancelPublishAndSave(): void;
  updateStoryLockState(value: boolean): void;
  updateEmbargo(timestamp: number | null): void;
  addTemplateCards(): void;
  openAdvancedSettingInspector(storyId: StoryId, template): void;
}

type StoryEditorProps = OwnProps & StateProps & DispatchProps;

interface StoryEditorState {
  autoSavedAt: number | null;
  autoSaveIntervalId?: number;
  lazyLoadIntervalId: number | null;
  isStoryDataInitialized: boolean;
}

const BlockEdit: React.FC<any> = ({ show, onClick }) => {
  return show ? (
    <div className={styles["block-container"]} data-test-id="block-container-btn" onClick={onClick} />
  ) : null;
};

const VersionInfo = ({
  timelineOpenCls,
  previewOpenCls,
  seoScoreOpenCls,
  latestTimelineEvent,
  closeTimeline,
  storyInspectorOpenCls,
  story
}) => {
  const versionBar = cx(
    "story-editor-version-bar",
    timelineOpenCls,
    previewOpenCls,
    seoScoreOpenCls,
    storyInspectorOpenCls
  );

  const lastUpdatedTime = formattedDate(latestTimelineEvent && latestTimelineEvent.changed, "hh:mm a, dd MMM yyyy");
  const lastUpdatedMessage = `${t("story-editor.last-update-message")} ${lastUpdatedTime}`;

  // TODO Make this display local time
  const currentVersionTime = formattedDate(story["updated-at"], "E MMM dd yyyy HH:mm:ss zz");
  const currentVersionMessage = `${t(
    "story-editor.current-version-message"
  )} "${currentVersionTime} (Indian Standard Time)".`;

  return (
    <>
      <div className={styles["story-editor-last-updated"]} data-test-id="story-editor-last-updated">
        {lastUpdatedMessage}
      </div>
      <div className={versionBar} data-test-id="story-editor-version-bar">
        <span
          className={styles["story-editor-version-go-back"]}
          data-test-id="story-editor-version-go-back-btn"
          onClick={closeTimeline}>
          <Chevron fill="var(--white)" variant="left" />
        </span>
        <p className={styles["story-editor-version-text"]} data-test-id="story-editor-version-text">
          {currentVersionMessage}
        </p>
      </div>
    </>
  );
};

class StoryEditor extends React.Component<StoryEditorProps, StoryEditorState> {
  insertCardBarRef: React.RefObject<HTMLSpanElement>;
  observer: IntersectionObserver;

  constructor(props: StoryEditorProps) {
    super(props);
    this.handleWindowClose = this.handleWindowClose.bind(this);
    this.clearLazyLoadingInterval = this.clearLazyLoadingInterval.bind(this);
    this.toggleStoryEdit = this.toggleStoryEdit.bind(this);
    this.autoSave = this.autoSave.bind(this);
    this.closeAll = this.closeAll.bind(this);
    this.insertCardBarRef = createRef<HTMLSpanElement>();
    this.state = { autoSavedAt: null, lazyLoadIntervalId: null, isStoryDataInitialized: false };

    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const { loadMoreElements, currentCardLoading, cardsToLoad } = this.props;
          const elementsLeftToLoad = cardsToLoad.length !== 0 || currentCardLoading;
          if (entry.isIntersecting && elementsLeftToLoad) {
            loadMoreElements();
            return (
              this.state.lazyLoadIntervalId ||
              this.setState({
                lazyLoadIntervalId: window.setInterval(loadMoreElements, 250)
              })
            );
          }
          if (this.state.lazyLoadIntervalId) {
            this.clearLazyLoadingInterval();
          }
        });
      },
      {
        rootMargin: "0px 0px 800px"
      }
    );
  }

  reObserveIntersection() {
    const intersectionTarget = this.insertCardBarRef.current;
    if (intersectionTarget) {
      this.observer.unobserve(intersectionTarget);
      this.observer.observe(intersectionTarget);
    }
  }

  componentDidUpdate = (prevProps: StoryEditorProps) => {
    if (this.props.templateFields !== prevProps.templateFields) {
      this.props.setTemplate(this.props.story["story-template"]);
    }

    if (this.props.storyTemplateFields !== prevProps.storyTemplateFields) {
      this.insertCardBarRef.current && this.observer.observe(this.insertCardBarRef.current);
    }

    if (
      this.props.cardsToLoad.length === 0 &&
      this.props.currentCardLoading === null &&
      this.state.lazyLoadIntervalId
    ) {
      this.clearLazyLoadingInterval();
    }

    if (this.props.locationDetails.url !== prevProps.locationDetails.url) {
      if (prevProps.readOnlyStoryVersionId || this.props.readOnlyStoryVersionId) {
        const params = this.props.readOnlyStoryVersionId ? { versionId: this.props.readOnlyStoryVersionId } : {};
        this.props.getStory(this.props.storyId, params, true);
      }

      if (this.props.locationDetails.path === STORY_EDITOR_IMPORT_PATH) {
        this.props.setImportStory(this.props.locationDetails.params.importStoryId);
      }

      this.props.updateRouteData(this.props.locationDetails.path, this.props.locationDetails.params);
    }
    // Following observer is responsible for loading cards on scroll
    // We need to re-initialize observer when cards length change from 0 to more than zero (First card load)
    if (size(get(prevProps.story, "cards", {})) === 0 && size(get(this.props.story, "cards", {})) > 0) {
      this.reObserveIntersection();
    }

    // Initialize story data once only after config is loaded from route data call
    if (!this.state.isStoryDataInitialized && this.props.templateFields) {
      let currentStoryTemplate: StoryTemplate = INITIAL_UNSAVED_STORY["story-template"];
      if (this.props.storyId === "new") {
        const searchParams = getSearchParameters(this.props.location.search);
        const provider = searchParams.get("provider");
        const taskId = searchParams.get("task-id");
        currentStoryTemplate = searchParams
          ? (searchParams.get("template") as StoryTemplate)
          : INITIAL_UNSAVED_STORY["story-template"];
        this.props.loadStory(
          { ...INITIAL_UNSAVED_STORY, ...{ provider, "task-id": taskId }, "story-template": currentStoryTemplate },
          {}
        );
        this.props.setTemplate(currentStoryTemplate);
        this.props.addTemplateCards();
      } else {
        const params = this.props.readOnlyStoryVersionId ? { versionId: this.props.readOnlyStoryVersionId } : {};
        this.props.getStory(this.props.storyId, params);
      }
      this.setState({ isStoryDataInitialized: true });
    }
  };

  componentDidMount() {
    this.setState({ autoSaveIntervalId: window.setInterval(this.autoSave, MILLIS_BETWEEN_AUTO_SAVES) });
    window.addEventListener("beforeunload", this.handleWindowClose);
    const isDefaultManageBarOpenEnabled =
      this.props.member && this.props.member.settings && this.props.member.settings["default-manage-story-inspector"];

    if (isDefaultManageBarOpenEnabled && this.props.isDesktopSizeViewport) {
      const searchParams = getSearchParameters(this.props.location.search);
      const currentStoryTemplate: any = searchParams
        ? (searchParams.get("template") as StoryTemplate)
        : INITIAL_UNSAVED_STORY["story-template"];

      this.props.updateRouteData(STORY_EDITOR_MANAGE_ADVANCED_METADATA_PATH, this.props.storyId);
      this.props.openAdvancedSettingInspector(this.props.storyId, currentStoryTemplate);

      if (this.props.storyId === "new") {
        this.props.setTemplate(currentStoryTemplate);
        this.props.addTemplateCards();
      }
    } else {
      this.props.updateRouteData(this.props.locationDetails.path, this.props.locationDetails.params);
      //Block navigation when navigating away from story page
      history.block((location, action) => {
        if (
          this.props.isStoryModified &&
          history.location.pathname.startsWith("/story/") &&
          !location.pathname.includes("story") &&
          action !== "REPLACE"
        ) {
          return t("story-editor.unsaved_changes_confirm");
        }
      });
    }
  }

  autoSave() {
    if (
      this.props.lastUpdatedTime !== this.state.autoSavedAt &&
      Date.now() - this.props.lastUpdatedTime > MILLIS_SINCE_LAST_UPDATE &&
      this.props.isStoryModified &&
      !this.props.disableStorySave
    ) {
      this.setState({ autoSavedAt: this.props.lastUpdatedTime });
      if (this.props.story["status"] === "scheduled") {
        this.props.cancelPublishAndSave();
      } else {
        this.props.saveStory();
      }
    }
  }

  handleWindowClose(event: any) {
    event.preventDefault();
    const confirmationMessage = t("story-editor.unsaved_changes_confirm");
    // Need to add another condition while the image is uploading.
    if (this.props.isStoryModified) {
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleWindowClose);
    clearInterval(this.state.autoSaveIntervalId);
    this.props.resetStoryEditorState();
    this.observer.disconnect();
  }

  clearLazyLoadingInterval() {
    clearInterval(this.state.lazyLoadIntervalId || undefined);
    this.setState({ lazyLoadIntervalId: null });
  }

  toggleStoryEdit(toggle: boolean) {
    const {
      "story-content-id": storyId,
      "story-version-id": currentStoryVersionId,
      status: currentStatus
    } = this.props.story;

    const isCurrentStateReadOnly = !(this.props.realtimeCurrentMember && this.props.realtimeCurrentMember.isEditing);
    const shouldNextStateBeReadOnly = !toggle;
    const isStoryModified = this.props.isStoryModified;

    if (toggle) {
      getStory(storyId).then((response) => {
        const { "story-version-id": latestStoryVersionId, status: latestStatus } = response.story;

        const isCurrentStoryVersionTheLatest =
          currentStoryVersionId === latestStoryVersionId && currentStatus === latestStatus;

        const newLockState = lockStoryForEdit(
          isCurrentStateReadOnly,
          shouldNextStateBeReadOnly,
          isStoryModified,
          isCurrentStoryVersionTheLatest
        );

        const isNewStateReadOnly = newLockState.readOnly;

        this.props.updateStoryLockState(isNewStateReadOnly);

        if (isNewStateReadOnly !== isCurrentStateReadOnly) {
          this.props.realtimeUpdateCurrentMember({
            ...this.props.realtimeCurrentMember,
            isEditing: !isNewStateReadOnly
          });
        }

        if (newLockState.useVersion === "latest") {
          const numberOfCardsToDisplay = this.isLiveBlog()
            ? this.props.cardsLoaded.length || this.props.numberOfCardsToLoad
            : this.props.cardsLoaded.length;

          this.props.loadStory(response.story, { numberOfCardsToLoad: numberOfCardsToDisplay });
          this.props.setWorkflowTransition(response["workflow-transitions"]);
          this.reObserveIntersection();
        }
      });
    } else if (isStoryModified) {
      this.props.cancelPublishAndSaveStoryForBlockingAutoSave(() => {
        const newLockState = lockStoryForEdit(isCurrentStateReadOnly, shouldNextStateBeReadOnly, isStoryModified, true);
        this.props.updateStoryLockState(true);

        if (newLockState.readOnly !== isCurrentStateReadOnly) {
          this.props.realtimeUpdateCurrentMember({
            ...this.props.realtimeCurrentMember,
            isEditing: false
          });
        }
      });
    } else {
      const newLockState = lockStoryForEdit(isCurrentStateReadOnly, shouldNextStateBeReadOnly, isStoryModified, true);

      this.props.updateStoryLockState(true);

      if (newLockState.readOnly !== isCurrentStateReadOnly) {
        this.props.realtimeUpdateCurrentMember({
          ...this.props.realtimeCurrentMember,
          isEditing: false
        });
      }
    }
  }

  closeAll() {
    this.props.closeAll(this.props.storyId);
  }

  isLiveBlog() {
    return this.props.story["story-template"] === "live-blog";
  }

  render() {
    const {
      story,
      showPreviewPane,
      showTimelinePane,
      showStoryInspector,
      showSeoScorePane,
      numberOfCardsShown,
      realtimeCurrentMember,
      realtimeConnectionState,
      realtimeMembers,
      readOnlyStoryVersionId,
      isBannerPresent,
      storyId,
      isStoryModified,
      isStorySaving,
      storyTemplateFields,
      isStoryLocked,
      showSnackBar,
      loadMoreCards,
      numberOfCardsToLoad,
      showPublishInspector,
      asanaCreds,
      cardId,
      latestTimelineEvent,
      showEmbargoInspector,
      closeTimeline,
      isSliderWindowOpen,
      isCurrentStoryPrint
    } = this.props;
    const showSpinner =
      isEmpty(storyTemplateFields) || // Config not loaded from route data yet
      !this.state.isStoryDataInitialized || // Story data not initialized yet
      (storyId !== "new" && storyId !== story.id); // Story data not fetched from API call yet
    if (showSpinner) {
      return <Spinner classname={styles["story-page loader"]} message={t("spinner.loading")} />;
    }

    /* TODO: [Sunil | 30-06-21]
       Rethink passing these classes as props, which is leading to
       unnecessary coupling and that too for styles. Ideally the
       respective components should style themselves based on the changed state.
     */

    const storyPageGridCls = cx("story-page-grid-container", {
      "story-page-preview--open": showPreviewPane,
      "story-page-timeline--open": showTimelinePane,
      "story-page-validator--open": showSeoScorePane,
      "story-editor-inspector--open": showStoryInspector
    });

    const timelineOpenCls = { "timeline--open": showTimelinePane };
    const previewOpenCls = { "preview--open": showPreviewPane };
    const seoScoreOpenCls = { "seoScore--open": showSeoScorePane };
    const isBannerPresentCls = { "banner-present": isBannerPresent };
    const slideWindowOpenCls = { "slider-window--open": isSliderWindowOpen };
    const storyInspectorOpenCls = { "story-editor-inspector--open": showStoryInspector };

    const settingsVersionsCls = cx("settings-versions-container", {
      ...timelineOpenCls,
      ...storyInspectorOpenCls,
      "validator--open": showSeoScorePane
    });

    const manageContainerCls = cx(
      "manage-container",
      timelineOpenCls,
      previewOpenCls,
      seoScoreOpenCls,
      isBannerPresentCls,
      storyInspectorOpenCls,
      slideWindowOpenCls
    );

    const isReadOnly = !!(isStoryLocked || readOnlyStoryVersionId);
    const isLiveBlog = story["story-template"] === "live-blog";
    const showLiveBlogLoadMoreCards = isLiveBlog && numberOfCardsShown < story.tree.length;
    const storyHasCards = story.cards && Object.keys(story.cards).length > 0;
    const isPublished = story.status === "published";
    const isScheduled = story.status === "scheduled";
    const isPendingForApproval = story.status === "pending-approval";
    const isEmbargoed = story["is-embargoed"];
    const showPublishedInfoBar = isPublished && !isStoryModified && !isStorySaving && !readOnlyStoryVersionId;
    const showScheduledInfoBar = isScheduled && !isStoryModified && !isStorySaving && !readOnlyStoryVersionId;
    const isLazyLoading = !!this.state.lazyLoadIntervalId;
    const embargoedTill = story["embargoed-till"] || new Date().getTime();
    const embargoInfoBarMessage = `${t("story-editor.info-bar.embargoed-story")}  ${formattedDate(embargoedTill)}`;

    const embargoBannerContainerCls = cx("settings-versions-container", {
      ...timelineOpenCls,
      ...storyInspectorOpenCls,
      "validator--open": showSeoScorePane,
      "embargo-banner-container": !showTimelinePane && !showStoryInspector && !showPreviewPane && !showSeoScorePane,
      "embargo-banner-not-present": !isBannerPresent,
      "embargo-inspector-open": showStoryInspector,
      "embargo-seo-pane-open": showSeoScorePane
    });

    return (
      <>
        <div className={styles["story-page-container"]}>
          <div className={storyPageGridCls}>
            <div className={styles["story-page-grid-story-contents"]}>
              <section className={styles["story-page-header-wrapper"]}>
                <VisibleOnScroll>
                  {(show) => (
                    <div className={cx(manageContainerCls, { "manage-container--hide": !show })}>
                      <ManageBar
                        storyInspectorOpenCls={storyInspectorOpenCls}
                        timelineOpenCls={timelineOpenCls}
                        previewOpenCls={previewOpenCls}
                        seoScoreOpenCls={seoScoreOpenCls}
                        toggleStoryEdit={this.toggleStoryEdit}
                        realtimeCurrentMember={realtimeCurrentMember}
                        realtimeConnectionState={realtimeConnectionState}
                        realtimeMembers={realtimeMembers}
                        readOnlyStoryVersionId={readOnlyStoryVersionId}
                        storyId={storyId}
                      />
                    </div>
                  )}
                </VisibleOnScroll>
                {isEmbargoed && story["embargoed-till"] && !showTimelinePane && (
                  <div className={embargoBannerContainerCls}>
                    <EmbargoBanner content={embargoInfoBarMessage} />
                  </div>
                )}
                <div className={styles["story-page-header-container"]} data-test-id="story-page-header-container">
                  <BlockEdit show={isStoryLocked} onClick={() => showSnackBar(t("story-editor.read_only_mode"))} />

                  <div className={settingsVersionsCls}>
                    {readOnlyStoryVersionId && (
                      <VersionInfo
                        timelineOpenCls={timelineOpenCls}
                        previewOpenCls={previewOpenCls}
                        storyInspectorOpenCls={storyInspectorOpenCls}
                        seoScoreOpenCls={seoScoreOpenCls}
                        latestTimelineEvent={latestTimelineEvent}
                        closeTimeline={closeTimeline}
                        story={story}
                      />
                    )}

                    {showPublishedInfoBar && <InfoBar content={t("story-editor.info-bar.published-story")} />}

                    {showScheduledInfoBar && <InfoBar content={t("story-editor.info-bar.scheduled-story")} />}

                    {isCurrentStoryPrint && isPendingForApproval && (
                      <InfoBar content={t("story-editor.info-bar.print-story")} />
                    )}
                  </div>

                  <HeaderCard
                    storyInspectorOpenCls={storyInspectorOpenCls}
                    readOnlyStoryVersionId={readOnlyStoryVersionId}
                    timelineOpenCls={timelineOpenCls}
                    previewOpenCls={previewOpenCls}
                    seoScoreOpenCls={seoScoreOpenCls}
                  />
                </div>
              </section>

              <div
                id="story-editor__editor-container"
                className={styles["editor-container"]}
                data-test-id="editor-container">
                {isLiveBlog && (
                  <InsertCardBar position={Position.TOP} readOnlyStoryVersionId={readOnlyStoryVersionId} />
                )}

                {storyHasCards && <ProseMirrorStoryEditor readOnly={isReadOnly} />}

                {isLazyLoading && <Spinner message={t("story-editor.loading_more_elements")} />}

                {showLiveBlogLoadMoreCards && (
                  <LoadMoreCards
                    numberOfCardsShown={numberOfCardsShown}
                    totalNumberOfCards={story.tree.length}
                    onClick={() => loadMoreCards(story, numberOfCardsShown, numberOfCardsToLoad)}
                  />
                )}

                {!isLiveBlog && (
                  <span ref={this.insertCardBarRef}>
                    <InsertCardBar readOnlyStoryVersionId={readOnlyStoryVersionId} />
                  </span>
                )}
              </div>
            </div>

            {showPreviewPane && <Preview />}

            {showTimelinePane && <Timeline />}

            {showSeoScorePane && <SeoScore />}
          </div>
          <StoryEditorInspector />
          <PublishInspector isActive={showPublishInspector} onClose={this.closeAll} asana={asanaCreds} />

          <EmbargoInspector
            isActive={showEmbargoInspector}
            embargoedTill={embargoedTill}
            onClose={() => {
              this.props.saveStory();
              this.closeAll();
            }}
            updateEmbargo={this.props.updateEmbargo}
            inspectorType={isEmbargoed ? InspectorType.EDIT : InspectorType.CREATE}
          />

          <RestoreStoryVersionModal />

          <CleanUpEmptyElementsModal />

          <PhotoEditorWrapper storyId={storyId} cardId={cardId} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: PartialAppState, ownProps: OwnProps): StateProps => {
  const title = state.storyEditor.story["story-content-id"] === "new" ? "new_story" : state.storyEditor.story.headline;
  return {
    templateFields: state.config.templateFields,
    member: state.config.member,
    story: state.storyEditor.story as Story,
    storyId: ownProps.match.params.id,
    storyTemplateFields: state.storyEditor.storyTemplateFields,
    showPreviewPane: locationHasTruthyQueryParam(ownProps.location, "preview"),
    showTimelinePane: locationHasTruthyQueryParam(ownProps.location, "timeline"),
    showSeoScorePane: locationHasTruthyQueryParam(ownProps.location, "validator"),
    showStoryInspector: ownProps.location.pathname.includes("manage"),
    showEmbargoInspector: ownProps.location.pathname.includes("embargo"),
    isStoryModified: state.storyEditor.ui.isStoryModified,
    lastUpdatedTime: state.storyEditor.ui.lastUpdatedTime,
    isCurrentStoryPrint: state.storyEditor.ui.isCurrentStoryPrint,
    locationDetails: ownProps.match,
    isBlockConcurrentStoryEditEnabled: state.features.isBlockConcurrentStoryEditEnabled,
    isStoryLocked: state.storyEditor.ui.isStoryLocked,
    latestTimelineEvent: state.storyEditor.timeline.events[0],
    readOnlyTimelineEvent: state.storyEditor.ui.timelineEvent,
    readOnlyStoryVersionId: ownProps.match.params.versionId,
    changeStatusAction: state.storyEditor.ui.changeStatusAction,
    cardId: ownProps.match.params.cardId && ownProps.match.params.cardId,
    disableStorySave:
      state.storyEditor.story["story-template"] === "live-blog" ||
      ownProps.blockStorySave ||
      state.storyEditor.app.photoEditor.visible ||
      state.storyEditor.ui.isStatusTransitionInProgress ||
      state.storyEditor.ui.isStoryChangeInProgress ||
      state.storyEditor.ui.isStorySaving ||
      state.storyEditor.ui.isFormattingToolbarActive,
    publisherId: state.config.publisher.id,
    asanaCreds: state.config.asana,
    title,
    isReferenceUrlEnabled: state.features.isReferenceUrlEnabled,
    numberOfCardsToLoad: state.config.numberOfCardsToLoad,
    numberOfCardsShown: state.storyEditor.ui.numberOfCardsShown,
    cardsLoaded: state.storyEditor.ui.cardsLoaded,
    currentCardLoading: state.storyEditor.ui.currentCardLoading,
    cardsToLoad: state.storyEditor.ui.cardsToLoad,
    isStorySaving: state.storyEditor.ui.isStorySaving,
    isDesktopSizeViewport: selectIsDesktopSizeViewport(state),
    isBannerPresent: !!(
      state.config.publisherWideBannerMessage ||
      (state.storyEditor.ui.banner && state.storyEditor.ui.banner.message)
    ),
    isSliderWindowOpen: get(state, ["slider", "sliderWindow", "open"], false)
  };
};

const getSearchParameters = (queryParams: string) => {
  const searchString = queryParams.substring(1);
  return new URLSearchParams(searchString);
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<PartialAppState, any, AnyAction>,
  ownProps: OwnProps
): DispatchProps => {
  const navigate = navigateFn(dispatch);

  return {
    navigate,
    getStory: (storyId, params: { versionId?: StoryId }, loadAllElements) =>
      dispatch(getStoryAction(storyId, params, loadAllElements)),
    updateRouteData: (path, params) => dispatch(updateRouteDataAction(path, params)),
    replaceRoute: (id) => dispatch(replace(route(STORY_EDITOR_PATH, { id }))),
    loadStory: (story, opts) => dispatch(loadStory(story, opts)),
    resetIsStoryModified: () => dispatch(setIsStoryModifiedState(false)),
    setTemplate: (storyTemplate) => dispatch(setTemplateAction(storyTemplate)),
    openGalleryInspector: () => navigate(STORY_EDITOR_HERO_IMAGE_GALLERY_PATH, { id: ownProps.match.params.id }),
    setWorkflowTransition: (workflowTransitions) =>
      dispatch({ type: actions.SET_WORKFLOW_TRANSITIONS, payload: { workflowTransitions } }),
    setStoryAndWorkflowTransition: ({ story, "workflow-transitions": workflowTransitions }) =>
      dispatch({ type: actions.SET_STORY, payload: { story, workflowTransitions } }),
    updateStoryLockState: (value) => dispatch({ type: actions.UPDATE_STORY_LOCK, payload: { value } }),
    saveStory: (callback, action) => dispatch(saveStoryAction(callback, action)),
    cancelPublishAndSaveStoryForBlockingAutoSave: (callback) =>
      dispatch(cancelPublishAndSaveStoryForBlockingAutoSaveAction(callback)),
    showSnackBar: (message) => dispatch({ type: NOTIFICATION_INFO, payload: { message, action: null } }),
    closeTimeline: () => dispatch(closeTimelineAction()),
    setImportStory: (importStoryId) => dispatch(setSelectedStoryAction(importStoryId)),
    closeAll: () => {
      dispatch(setStoryTransitionStatus(false));
      navigate(STORY_EDITOR_PATH, { id: ownProps.match.params.id });
    },
    resetStoryEditorState: () => dispatch(resetStoryEditorStateAction()),
    loadMoreCards: (story, numberOfCardsShown, numberOfCardsToLoad) => {
      dispatch(loadMoreCardsAction(story, numberOfCardsShown, numberOfCardsToLoad));
    },
    loadMoreElements: () => {
      dispatch(loadMoreStoryElementsAction());
    },
    cancelPublishAndSave: () => dispatch(cancelPublishAndSaveAction()),
    updateEmbargo: (timestamp) => dispatch(updateEmbargoAction(timestamp)),
    addTemplateCards: () => dispatch(addTemplateCards()),
    openAdvancedSettingInspector: (storyId, template) => {
      const advancedPath = STORY_EDITOR_MANAGE_ADVANCED_METADATA_PATH.replace(":id", storyId);
      const targetPath = template ? `${advancedPath}?template=${template}` : advancedPath;
      return dispatch(replace(targetPath));
    }
  };
};

const sortMembersByOpenedAt = (memberA: RealtimeMember, memberB: RealtimeMember) => memberA.openedAt - memberB.openedAt;

const makeChannelName = ({ publisherId, match }: { publisherId: string; match: Match<{ id: string }> }) => {
  const storyId = match.params.id;
  return storyId !== "new" ? publisherId + "::story::" + storyId : undefined;
};

const updateCurrentMember = (
  action: AnyAction,
  { members, currentMember }: { members: RealtimeMember[]; currentMember: RealtimeMember },
  storyStatus: any
) => {
  return updateCurrentMemberIsEditing(action, { members, currentMember }, storyStatus);
};

export default compose<any>(
  connect(mapStateToProps, mapDispatchToProps),
  wrapPage({
    HeaderComponent: StoryEditorHeader,
    isStoryPage: true
  }),
  wrapRealtime({
    makeChannelName,
    updateCurrentMemberIsEditing: updateCurrentMember,
    sortMechanism: sortMembersByOpenedAt
  })
)(StoryEditor);

export { StoryEditor };
