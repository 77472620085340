/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Spinner from "components/spinner/spinner";
import Plus from "components/icons/plus";
import Button from "components/button/button";
import Close from "components/icons/close";
import { LoaderState } from "behaviors/loader/state";
import { TimeoutError } from "api/errors";
import { MenuItemNotFoundError } from "../../errors";
import { t } from "i18n";
import { MenuGroup } from "api/menu-group";
import { MenuGroupId } from "api/primitive-types";

interface LoaderProps {
  loader: LoaderState;
  loadMenuItems: (menuId: MenuGroupId) => void;
  selectedMenu: MenuGroup | null;
}

const Loader: React.SFC<LoaderProps> = ({ loader: { loading, error }, loadMenuItems, selectedMenu }) => {
  if (selectedMenu && loading) {
    return <Spinner message={t("menu.messages.loading-list")} />;
  }
  if (selectedMenu && error) {
    if (error instanceof TimeoutError) {
      return (
        <React.Fragment>
          <Plus width="48" height="48" />
          <p>{t("menu.messages.could-not-load-list")}</p>
          <p>
            <Button onClick={() => loadMenuItems(selectedMenu.id)}>{t("menu.cta.load-retry")}</Button>
          </p>
        </React.Fragment>
      );
    }

    if (error instanceof MenuItemNotFoundError) {
      return (
        <React.Fragment>
          <Close width="48" height="48" />
          <p>{t("menu.messages.not-found", { id: error.id })}</p>
        </React.Fragment>
      );
    }
  }
  if (selectedMenu) {
    return <p>{t("menu.messages.unknown-error")}</p>;
  } else {
    return null;
  }
};

export default Loader;
