/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { AnyAction } from "@reduxjs/toolkit";
import { batch, connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";
import {
  deleteCollection,
  PartialAppState,
  setCurrentlyDeleting,
  setShowDeleteModal
} from "store/collection/collections-dashboard";
import ConfirmationModal from "components/confirmation-modal/confirmation-modal";

interface StateProps {
  showModal: boolean;
}

interface DispatchProps {
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
}

const DeleteModal: React.FC<StateProps & DispatchProps> = ({ showModal, onConfirm, onCancel }) => {
  return (
    <ConfirmationModal
      showConfirmationModal={showModal}
      headerText={t("collections.dashboard.messages.delete-confirm")}
      text={t("common.phrases.cannot-be-undone")}
      confirmButtonLabel={t("common.delete")}
      cancelButtonLabel={t("common.cancel")}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
};

function mapStateToProps(state: PartialAppState): StateProps {
  return {
    showModal: state.collectionsDashboard.ui.showDeleteModal
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps {
  return {
    onConfirm: () => dispatch(deleteCollection()),
    onCancel: () =>
      batch(() => {
        dispatch(setCurrentlyDeleting(null));
        dispatch(setShowDeleteModal(false));
      })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);
