/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useState } from "react";
import { CoverImage } from "api/story-collection";
import { Image, AnyImage } from "api/search-media-image";
import { SavedImage } from "./types";
import { FocusPoint } from "pages/media-library/components/image-preview-with-actions/focus-point";
import {
  setSelectedFocusPoint,
  setDefaultFocusPoint,
  removeFocusPoint
} from "pages/story-editor/story-element-types/image/focus-point";
import { useOverlay, useImageData } from "custom-hooks/image";
import styles from "./edit-image.module.css";
import ImageDetails from "./image-details/image-details";
import ImageActionBar, { ImageAction, ImageActionType } from "components/image-action-bar/image-action-bar";
import classnames from "classnames/bind";

const cx = classnames.bind(styles);

export interface Props {
  value: AnyImage | SavedImage | CoverImage;
  disableDelete?: boolean;
  onDelete: () => void;
  disableReplace?: boolean;
  onReplace: () => void;
  isPhotoEditor?: boolean;
  openPhotoEditor?: (value: SavedImage | CoverImage) => void;
  updateFocusPoint?: (image: Image) => void;
  updateImage?: (image: Image | SavedImage | CoverImage) => void;
  fieldSpecs?: any;
}

const EditImage: React.SFC<Props> = ({
  value,
  disableReplace = false,
  onReplace,
  disableDelete = false,
  onDelete,
  isPhotoEditor = false,
  openPhotoEditor,
  updateFocusPoint,
  updateImage,
  fieldSpecs
}) => {
  const caption = value.caption as string;
  const { imageDimensions, focusPoint } = useImageData(value);
  const imageContainer = React.createRef<HTMLDivElement>();
  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
  const { overlayProperties, overlayPoint } = useOverlay(imageContainer, imageDimensions, focusPoint);
  const imageActions: ImageAction[] = [
    new ImageAction(
      ImageActionType.ClearFocusPoint,
      (e) =>
        overlayProperties &&
        updateFocusPoint &&
        "metadata" in value &&
        updateFocusPoint({
          ...value,
          metadata: removeFocusPoint(e, value.metadata!)
        } as Image),
      !focusPoint || !updateFocusPoint
    ),
    new ImageAction(
      ImageActionType.SetFocusPoint,
      (e) =>
        overlayProperties &&
        updateFocusPoint &&
        "metadata" in value &&
        updateFocusPoint({
          ...value,
          metadata: setDefaultFocusPoint(e, value.metadata!, overlayProperties.dimensions)
        } as Image),
      !!focusPoint || !updateFocusPoint
    ),
    new ImageAction(ImageActionType.DownloadImage),
    new ImageAction(ImageActionType.ReplaceImage, onReplace, disableReplace),
    new ImageAction(
      ImageActionType.EditImage,
      (e) => {
        e && e.stopPropagation();
        openPhotoEditor && openPhotoEditor(value as SavedImage | CoverImage);
      },
      !isPhotoEditor
    ),
    new ImageAction(ImageActionType.DeleteImage, onDelete, disableDelete)
  ];

  return (
    <React.Fragment>
      <div className={cx("image-field", { "image-field--update-image": updateImage })} ref={imageContainer}>
        <img
          className={styles["image-field-image"]}
          src={"url" in value ? value.url : value["cover-image-url"]}
          alt={caption}
          onLoad={(e) => setIsImageLoading(false)}
        />
        {updateFocusPoint && (
          <div
            style={overlayProperties ? (overlayProperties.style as React.CSSProperties) : undefined}
            className={styles["image-preview-overlay"]}
            onClick={(e) =>
              overlayProperties &&
              updateFocusPoint &&
              "metadata" in value &&
              updateFocusPoint({
                ...value,
                metadata: setSelectedFocusPoint(e, value.metadata!, overlayProperties.dimensions)
              } as Image)
            }>
            {overlayPoint && <FocusPoint x={overlayPoint.x} y={overlayPoint.y} />}
          </div>
        )}
        <div className={cx("image-field-actions", { "image-field-actions--hide": isImageLoading })}>
          <ImageActionBar actions={imageActions} image={value as Image} />
        </div>
      </div>
      {updateImage && <ImageDetails image={value} updateImage={updateImage} fieldSpecs={fieldSpecs} />}
    </React.Fragment>
  );
};

export default EditImage;
