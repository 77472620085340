/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const LastPublishedClock = ({ width = "16", height = "16", color = "var(--mono-5)" }) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill={color}>
    <path d="M7.33334 14.6333C6.48889 14.5444 5.70278 14.3139 4.975 13.9416C4.24722 13.5694 3.61389 13.0861 3.075 12.4916C2.53611 11.8972 2.11111 11.2166 1.8 10.45C1.48889 9.6833 1.33334 8.86664 1.33334 7.99997C1.33334 6.98886 1.53611 6.05553 1.94167 5.19997C2.34722 4.34442 2.9 3.61108 3.6 2.99997H2V1.66664H6V5.66664H4.66667V3.84997C4.05556 4.33886 3.56945 4.94164 3.20834 5.6583C2.84722 6.37497 2.66667 7.15553 2.66667 7.99997C2.66667 9.36664 3.11389 10.5472 4.00834 11.5416C4.90278 12.5361 6.01111 13.1166 7.33334 13.2833V14.6333ZM7.05 11.0666L4.23334 8.2333L5.16667 7.29997L7.05 9.1833L10.8333 5.39997L11.7667 6.34997L7.05 11.0666ZM10 14.3333V10.3333H11.3333V12.15C11.9444 11.65 12.4306 11.0444 12.7917 10.3333C13.1528 9.62219 13.3333 8.84442 13.3333 7.99997C13.3333 6.6333 12.8861 5.45275 11.9917 4.4583C11.0972 3.46386 9.98889 2.8833 8.66667 2.71664V1.36664C10.3556 1.5333 11.7778 2.24442 12.9333 3.49997C14.0889 4.75553 14.6667 6.25553 14.6667 7.99997C14.6667 9.01108 14.4639 9.94442 14.0583 10.8C13.6528 11.6555 13.1 12.3889 12.4 13H14V14.3333H10Z" />
  </svg>
);

export default LastPublishedClock;
