/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { PublisherId, SectionId, Timestamp, MenuItemId, TagId, MenuGroupId } from "./primitive-types";
import { Tag } from "./tag";
import { Entity } from "./entity";

export enum MenuItemType {
  Section = "section",
  Tag = "tag",
  Link = "link",
  Placeholder = "placeholder"
}

interface MenuItemData {
  color: string;
  link?: string;
  "icon-code"?: string;
}

export interface MenuItem {
  id: MenuItemId;
  "publisher-id": PublisherId;
  "parent-id": MenuItemId | null;
  "ancestor-ids": MenuItemId[];

  rank: number;

  title: string;
  "menu-group-slug": string | null;
  "menu-group-id": MenuGroupId | null;

  "item-type": MenuItemType;
  "item-id": SectionId | TagId | null;

  "section-name": string | null;
  "section-slug": string | null;

  "tag-name": string | null;
  tag: Tag | Entity | null;

  data: MenuItemData;

  "created-at": Timestamp;
  "updated-at": Timestamp;
}

export type UnsavedMenuItem = Pick<
  MenuItem,
  "title" | "parent-id" | "item-type" | "item-id" | "menu-group-slug" | "data" | "tag" | "menu-group-id"
>;

export type AnyMenuItem = MenuItem | UnsavedMenuItem;

export enum MenuItemMoveDirection {
  Up = "up",
  Down = "down"
}

export interface MenuItemsListResponse {
  "menu-items": MenuItem[];
}

interface MenuItemSaveResponse {
  "menu-item": MenuItem;
}

export enum EntityType {
  Entity = "entity"
}

export type UnsavedMenuItemType = MenuItemType | EntityType;

export type MenuItemToSave = Pick<
  MenuItem,
  "title" | "parent-id" | "item-id" | "menu-group-slug" | "data" | "tag" | "menu-group-id"
> & {
  "item-type": UnsavedMenuItemType;
};

export const saveNewMenuItem = (menuItem: MenuItemToSave): Promise<MenuItemSaveResponse> => {
  const { tag, ...rest } = menuItem;

  return client
    .url("/api/menu-item")
    .json({ "menu-item": rest })
    .post()
    .json();
};

export const updateMenuItem = (id: MenuItemId, menuItem: MenuItemToSave): Promise<MenuItemSaveResponse> => {
  const { tag, ...rest } = menuItem;

  return client
    .url(`/api/menu-item/${id}`)
    .json({ "menu-item": rest })
    .put()
    .json();
};

export const deleteMenuItem = (menuItem: MenuItem): Promise<void> => {
  return client
    .url(`/api/menu-item/${menuItem.id}`)
    .delete()
    .res();
};

export const moveMenuItem = (menuItem: MenuItem, direction: MenuItemMoveDirection): Promise<void> => {
  return client
    .url(`/api/menu-item/${menuItem.id}/move?format=itsman-x`)
    .json({ direction })
    .put()
    .res();
};
