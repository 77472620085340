/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const AlsoRead = ({ width = 24, height = 24, color = "#c6ccd5" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    fill={color}>
    <defs />
    <path
      d="M612.41 358.73h-13a2.37 2.37 0 0 1-2.37-2.37v-13.05a2.37 2.37 0 0 1 2.37-2.36h13a2.37 2.37 0 0 1 2.37 2.36v13.05a2.37 2.37 0 0 1-2.37 2.37zm-13-16.78a1.36 1.36 0 0 0-1.36 1.36v13.05a1.36 1.36 0 0 0 1.36 1.36h13a1.36 1.36 0 0 0 1.36-1.36v-13.05a1.36 1.36 0 0 0-1.36-1.36z"
      transform="translate(-597 -338)"
    />
    <path d="M3.34 6.83h11.1v1.21H3.34zM3.34 10.89h11.1v1.21H3.34zM3.34 14.77h6.64v1.21H3.34z" />
    <path
      d="M615.35 338H602.3a2.37 2.37 0 0 0-2.23 1.6h1.14a1.33 1.33 0 0 1 1.09-.59h13.05a1.36 1.36 0 0 1 1.36 1.36v13a1.35 1.35 0 0 1-.61 1.11v1.13a2.37 2.37 0 0 0 1.61-2.24v-13a2.37 2.37 0 0 0-2.36-2.37"
      transform="translate(-597 -338)"
    />
  </svg>
);

export default AlsoRead;
