/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { FormsConfig } from "api/route-data/route-data";
import { EmailListType } from "store/route-data";
import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ThunkDispatch } from "redux-thunk";
import * as api from "api/forms";
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from "containers/page/actions";
import { t } from "i18n";

export interface FormSettingsState {
  currentMailingList: EmailListType;
  ui: {
    isSaveButtonEnabled: boolean;
  };
}

export const initialState: FormSettingsState = {
  currentMailingList: [],
  ui: {
    isSaveButtonEnabled: false
  }
};

export interface PartialAppState {
  features: {
    isFormsEnabled: boolean;
    canCreateForms: boolean;
  };
  config: {
    forms: FormsConfig;
  };
  forms: {
    settings: FormSettingsState;
  };
}

const { reducer, actions } = createSlice({
  name: "form-settings",
  initialState,
  reducers: {
    updateCurrentMailingList: (state: FormSettingsState, action: PayloadAction<EmailListType>) => {
      state.currentMailingList = action.payload;
    },
    enableSaveButton: (state: FormSettingsState) => {
      state.ui.isSaveButtonEnabled = true;
    },
    disableSaveButton: (state: FormSettingsState) => {
      state.ui.isSaveButtonEnabled = false;
    }
  }
});

export const { updateCurrentMailingList, enableSaveButton, disableSaveButton } = actions;

export { reducer };
export default reducer;

export const saveSettings = () => async (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  getState: () => PartialAppState
) => {
  try {
    const currentMailingList = getState().forms.settings.currentMailingList;
    await api.updateDefaultMailingList(currentMailingList);
    dispatch(disableSaveButton());
    dispatch({ type: NOTIFICATION_SUCCESS, payload: { message: t("forms.messages.update-forms-config-success") } });
  } catch (error) {
    dispatch({ type: NOTIFICATION_ERROR, payload: { message: t("forms.messages.update-forms-config-fail") } });
  }
};
