/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import User from "components/icons/user";
import Clock from "components/icons/clock";
import { formattedDate } from "utils";
import { NavLink } from "react-router-dom";
import { Chip } from "@quintype/em/components/chip";
import FirstPublishedClock from "components/icons/first-published-icon";
import Calendar from "components/icons/calendar";
import DesktopIcon from "components/icons/desktop";
import MobileIcon from "components/icons/mobile";
import { ToolTip } from "@quintype/em/components/tooltip";

import { PushNotificationListItem, Target } from "api/push-notification";
import { PushNotificationId } from "api/primitive-types";
import { TabValue } from "../../tab-list";
import { AnyAction, compose } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState } from "../../state";
import { PUSH_NOTIFICATION_EDIT_PATH } from "../../routes";
import { route } from "utils/routes.utils";

import { t } from "i18n";
import styles from "./push-notification-list.module.css";
import { connect } from "react-redux";
import { isArray } from "lodash";

interface StateProps {
  currentTab: TabValue;
}

interface DispatchProps {
  route: (id: PushNotificationId) => string;
}

interface PushNotificationRowProps {
  pushNotification: PushNotificationListItem;
  route: (id: PushNotificationId) => string;
  currentTab: TabValue;
}

interface PlatformStatus {
  web?: string;
  mobile?: string;
}

interface TargetCategory {
  platform?: { web?: { provider?: string }; mobile?: { provider?: string } };
}

const showMessage = (
  tab: TabValue,
  pushNotification: PushNotificationListItem,
  route: (id: PushNotificationId) => string
) => {
  switch (tab) {
    case TabValue.SCHEDULED:
      return (
        <NavLink
          to={route(pushNotification.id.toString())}
          className={styles["push-notification-headline"]}
          data-test-id="push-notification-headline"
          dangerouslySetInnerHTML={{
            __html: pushNotification["highlighted-headline"] || pushNotification.message
          }}></NavLink>
      );
    case TabValue.PUBLISHED:
      return (
        <h3
          className={styles["push-notification-headline"]}
          data-test-id="push-notification-headline"
          dangerouslySetInnerHTML={{
            __html: pushNotification["highlighted-headline"] || pushNotification.message
          }}
        />
      );
    default:
      return (
        <h3
          className={styles["push-notification-headline"]}
          data-test-id="push-notification-headline"
          dangerouslySetInnerHTML={{
            __html: pushNotification["highlighted-headline"] || pushNotification.message
          }}
        />
      );
  }
};

export const PushNotificationRow: React.FC<PushNotificationRowProps> = ({ pushNotification, currentTab, route }) => {
  const getStatusIcon = (status) => {
    switch (status) {
      case "published":
        return <FirstPublishedClock />;
      case "scheduled":
        return <Calendar width={16} height={16} />;
      default:
        return <Clock />;
    }
  };

  const getStatusTime = (pushNotification) => {
    return pushNotification.status === "scheduled" ? pushNotification["publish-at"] : pushNotification["updated-at"];
  };

  const getPlatformWithStatus = (
    logs: Array<{
      platform: string;
      status: string;
    }> = [],
    targetCategories: Array<Target> | null = []
  ) => {
    const getNotificationIconColor = (platform) => {
      const platformLogs = logs.filter((log) => log.platform === platform);
      if (platformLogs.length === 0) {
        return "var(--brand-1)";
      }
      if (platformLogs.some((log) => log.status === "failed")) {
        return "var(--danger)";
      }
      if (platformLogs.every((log) => log.status === "success")) {
        return "var(--success)";
      }
      return;
    };

    const platformStatus: PlatformStatus = {};

    if (isArray(targetCategories)) {
      targetCategories.forEach((category: TargetCategory) => {
        if (category?.platform && category.platform.web) {
          platformStatus.web = getNotificationIconColor("web");
        }
        if (category?.platform && category.platform.mobile) {
          platformStatus.mobile = getNotificationIconColor("mobile");
        }
      });
    }
    return platformStatus;
  };

  const platformStatus = getPlatformWithStatus(pushNotification.logs, pushNotification["target-categories"]);
  const defaultIconColor =
    pushNotification.status === "scheduled" || pushNotification.status === "cancelled"
      ? "var(--mono-5)"
      : "var(--white)";

  return (
    <div className={styles["push-notification-card"]} data-test-id="push-notification-card">
      <div className={styles["push-notification-card-content"]} data-test-id="push-notification-card-content">
        <div className={styles["push-notification-status"]}>
          {pushNotification["status"] !== "failed" && pushNotification["status"] && (
            <Chip value={pushNotification["status"]} invert />
          )}
          {pushNotification["linked-story-id"] && (
            <a href={`/story/${pushNotification["linked-story-id"]}`} target="_blank" rel="noopener noreferrer">
              <Chip value={"story"} invert />
            </a>
          )}
          {platformStatus.web && (
            <div
              className={styles["push-notification-platform"]}
              style={{
                backgroundColor: platformStatus.web
              }}
              data-test-id="web-platform"
              data-for="web"
              data-tip>
              <DesktopIcon fill={platformStatus.web} color={defaultIconColor} />
              <ToolTip
                id="web"
                value={t("common.web")}
                place="top"
                effect="solid"
                classname={styles["card-time-tooltip"]}
              />
            </div>
          )}
          {platformStatus.mobile && (
            <div
              className={styles["push-notification-platform"]}
              style={{
                backgroundColor: platformStatus.mobile
              }}
              data-test-id="mobile-platform"
              data-for="mobile"
              data-tip>
              <MobileIcon fill={platformStatus.mobile} color={defaultIconColor} />
              <ToolTip
                id="mobile"
                value={t("common.mobile")}
                place="top"
                effect="solid"
                classname={styles["card-time-tooltip"]}
              />
            </div>
          )}
        </div>
        {showMessage(currentTab, pushNotification, route)}
        <div className={styles["push-notification-byline"]} data-test-id="push-notification-byline">
          <div className={styles["push-notification-authors"]} data-test-id="push-notification-authors">
            <User />
            <div className={styles["push-notification-author"]} data-test-id="push-notification-author">
              <span
                dangerouslySetInnerHTML={{
                  __html: pushNotification["author-name"]
                }}
              />
            </div>
          </div>
          {pushNotification && (
            <div
              className={styles["push-notification-time-wrapper"]}
              data-test-id={`${pushNotification.status}-push-notification-time-wrapper`}>
              {getStatusIcon(pushNotification.status)}
              <time className={styles["push-notification-time"]} data-test-id="push-notification-time">
                {formattedDate(getStatusTime(pushNotification))}
              </time>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    currentTab: state.pushNotification.ui.currentTab
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    route: (id) => route(PUSH_NOTIFICATION_EDIT_PATH, { type: "scheduled", pushNotificationId: id })
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(PushNotificationRow);
