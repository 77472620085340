/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

export const submitLoginData = (params: { username: string; password: string }) => {
  return client
    .url("/api/member/login")
    .json(params)
    .post()
    .json()
    .catch((err) => {
      return Promise.reject(JSON.parse(err.message));
    });
};

export const resetPassword = (params: { password: string; "repeat-password": string; token: string }) => {
  return client
    .url("/api/member/password")
    .json(params)
    .post()
    .res()
    .catch((err) => {
      return Promise.reject(JSON.parse(err.message));
    });
};

export const resetPasswordBKProxy = (params: { "new-password": string; "confirm-password": string; token: string }) => {
  return client
    .url("/api/auth/v1/users/reset-password-with-token")
    .json(params)
    .post()
    .json()
    .catch((err) => {
      return Promise.reject(JSON.parse(err.text).error);
    });
};

export const forgotPassword = (email: string) => {
  return client
    .url("/api/member/forgot-password")
    .json({ email: email })
    .post()
    .res()
    .catch((err) => {
      return Promise.reject(JSON.parse(err.message));
    });
};

export const forgotPasswordBKProxy = (email: string) => {
  return client
    .url("/api/auth/v1/users/forgot-password")
    .json({ email: email })
    .post()
    .res()
    .catch((err) => {
      return Promise.reject(JSON.parse(err.text).error);
    });
};

export const getCurrentUser = () => {
  return client
    .url("/api/auth/v1/users/me")
    .get()
    .json()
    .catch((err) => {
      return Promise.resolve(null);
    });
};
