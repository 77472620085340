/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";

const Receipt = ({ width = "24", height = "24", color = "currentColor" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox="0 0 24 24">
    <defs>
      <path
        d="M20.5 2a2.513 2.513 0 0 1 2.3 2.5v3a2.5 2.5 0 0 1-2.5 2.5H19v11a.5.5 0 0 1-.8.4l-2.9-2.175a.5.5 0 0 0-.6 0l-3.4 2.55a.5.5 0 0 1-.6 0l-3.4-2.55a.5.5 0 0 0-.6 0L3.8 21.4A.5.5 0 0 1 3 21V4a2 2 0 0 1 2-2h15.5zM16 3.199 5 3.2a.8.8 0 0 0-.794.7L4.2 4l-.001 15.599 1.781-1.334a1.7 1.7 0 0 1 1.907-.09l.133.09 2.979 2.234 2.981-2.234a1.7 1.7 0 0 1 1.907-.09l.133.09 1.779 1.335V3.2H16v-.001zM16 13.9v1.2H6v-1.2h10zm0-3v1.2H6v-1.2h10zm0-3v1.2H6V7.9h10zm4.3-4.7a1.3 1.3 0 0 0-1.293 1.167L19 4.5 18.999 6H19v2.8h1.3a1.3 1.3 0 0 0 1.293-1.167L21.6 7.5v-3a1.3 1.3 0 0 0-1.3-1.3zm-2.136 0H17.8v1.277l.008-.18c.032-.399.158-.772.356-1.097z"
        id="tstv4lvz3a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="zf3t16zglb" fill="#fff">
        <use xlinkHref="#tstv4lvz3a" />
      </mask>
      <use fill="#979797" fillRule="nonzero" xlinkHref="#tstv4lvz3a" />
      <g mask="url(#zf3t16zglb)" fill="#F9F9F9">
        <path d="M0 0h24v24H0z" />
      </g>
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
);

export default Receipt;
