/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { EntityTypeName } from "./primitive-types";

export enum EntityPropertyType {
  String = "string",
  Array = "array"
}

export enum ArrayElementType {
  Image = "image",
  Entity = "entity"
}

export interface Property {
  display: string;
  key: string;
  type: EntityPropertyType;
  "entity-type"?: EntityTypeName;
  "array-element-type"?: ArrayElementType;
  "array-element-entity-type"?: EntityTypeName;
}

export interface Template {
  type: string;
  properties: Property[];
}

export interface EntityType {
  id: number;
  "publisher-id": number;
  name: string;
  template: Template;

  "deleted-at": null;

  "created-at": number;
  "updated-at": number;
}

export interface EntityTypesResponse {
  "entity-types": EntityType[];
}

export const listEntityTypes = (): Promise<EntityTypesResponse> => {
  return client
    .url("/api/entity-type")
    .get()
    .json();
};
