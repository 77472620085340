/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const BlockQuote = ({ width = 24, height = 24, color = "#c6ccd5" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height} fill={color}>
    <path
      d="M12.23 3.77H3V12h4.6c.2 3.89-2.49 4.25-2.6 4.26l-.41.05v3.95h.49c4.39-.3 7.18-4.64 7.24-8.48s-.07-7.47-.07-7.5zm-.81 7.93c-.06 3.29-2.33 7-5.92 7.53v-2.18c1-.29 3.38-1.41 3-5.58v-.41H3.91V4.68h7.45c.03 1.06.11 3.94.06 7.02zm9.49-7.49v-.44H13.7v.91H20c0 1.06.11 3.94.05 7-.06 3.29-2.32 7-5.92 7.53v-2.16c1-.29 3.38-1.41 3-5.58v-.41H13.7V12h2.56c.19 3.89-2.49 4.25-2.61 4.26l-.4.05v3.95h.48c4.39-.3 7.18-4.64 7.25-8.48s-.07-7.47-.07-7.5"
      fillRule="evenodd"
    />
  </svg>
);

export default BlockQuote;
