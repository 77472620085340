/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";
import * as api from "api/social-accounts";
import {
  socialAccountsRequestAction,
  socialAccountsSuccessAction,
  socialAccountsFailureAction
} from "./action-creators";
import { SocialAccount } from "api/social-accounts";
import { NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from "containers/page/actions";

export const loadSocialSharePageData = () => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(socialAccountsRequestAction());

  try {
    const response = await api.getSocialAccounts();
    dispatch(socialAccountsSuccessAction(response["social-accounts"]));
  } catch (error) {
    dispatch(socialAccountsFailureAction(error));
  }
};

export const toggleSocialAccount = (account: SocialAccount) => async (dispatch: ThunkDispatch<any, any, any>) => {
  try {
    await api.toggleSocialAccount(account);
    dispatch(loadSocialSharePageData());
    dispatch({ type: NOTIFICATION_SUCCESS, payload: { message: t("social_share.update-success") } });
  } catch (error) {
    dispatch({ type: NOTIFICATION_ERROR, payload: { message: error } });
  }
};

export const deleteSocialAccount = (accountId: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
  try {
    await api.deleteSocialAccount(accountId);
    dispatch(loadSocialSharePageData());
    dispatch({ type: NOTIFICATION_SUCCESS, payload: { message: t("social_share.delete-success") } });
  } catch (error) {
    dispatch({ type: NOTIFICATION_ERROR, payload: { message: error } });
  }
};
