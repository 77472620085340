/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Switch, Route } from "react-router";

import StoryAnalytics from "./story-analytics";
import { SECTION_ANALYTICS, AUTHOR_ANALYTICS, ANALYTICS_PATH } from "./routes";
import SectionAnalytics from "./section-analytics";
import AuthorAnalytics from "./author-analytics";

export const Router = () => {
  return (
    <Switch>
      <Route path={SECTION_ANALYTICS} render={(props) => <SectionAnalytics {...props} title="section_analytics" />} />
      <Route path={AUTHOR_ANALYTICS} render={(props) => <AuthorAnalytics {...props} title="author_analytics" />} />
      <Route path={ANALYTICS_PATH} render={(props) => <StoryAnalytics {...props} title="story_analytics" />} />
    </Switch>
  );
};
