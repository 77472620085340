/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const SetFocus = ({ width = "24", height = "24", color = "#5f6978" }) => (
  <svg fill={color} width={width} height={height} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.16666667,12.5 L4.16666667,15.8333333 L7.5,15.8333333 L7.5,17.5 L4.16666667,17.5 C3.25,17.5 2.5,16.75 2.5,15.8333333 L2.5,15.8333333 L2.5,12.5 L4.16666667,12.5 Z M17.5,12.5 L17.5,15.8333333 C17.5,16.75 16.75,17.5 15.8333333,17.5 L15.8333333,17.5 L12.5,17.5 L12.5,15.8333333 L15.8333333,15.8333333 L15.8333333,12.5 L17.5,12.5 Z M10.8333333,6.66666667 L10.833,9.583 L13.75,9.58333333 L13.75,11.25 L10.833,11.249 L10.8333333,14.1666667 L9.16666667,14.1666667 L9.166,11.249 L6.25,11.25 L6.25,9.58333333 L9.166,9.583 L9.16666667,6.66666667 L10.8333333,6.66666667 Z M7.5,2.5 L7.5,4.16666667 L4.16666667,4.16666667 L4.16666667,7.5 L2.5,7.5 L2.5,4.16666667 C2.5,3.25 3.25,2.5 4.16666667,2.5 L4.16666667,2.5 L7.5,2.5 Z M15.8333333,2.5 C16.75,2.5 17.5,3.25 17.5,4.16666667 L17.5,4.16666667 L17.5,7.5 L15.8333333,7.5 L15.8333333,4.16666667 L12.5,4.16666667 L12.5,2.5 Z"></path>
  </svg>
);

export default SetFocus;
