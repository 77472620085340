/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { LoaderState, INITIAL_LOADER_STATE } from "behaviors/loader/state";
import { AssociatedStoryDefaults, ValidationErrors } from "api/breaking-news-defaults";
import { Features } from "api/route-data/story-route-data";

export interface State {
  associatedStoryDefaults: AssociatedStoryDefaults | null;
  ui: {
    main: LoaderState;
    saving: boolean;
    isAssociatedStoryDefaultsModified: boolean;
    errors: ValidationErrors | null;
  };
}

export interface PartialAppState {
  breakingNewsDefaults: State;
  features: Features;
}

export const INITIAL_BREAKING_NEWS_DEFAULTS: State = {
  associatedStoryDefaults: null,
  ui: {
    main: INITIAL_LOADER_STATE,
    saving: false,
    isAssociatedStoryDefaultsModified: false,
    errors: null
  }
};
