/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const PlusCircle = ({ width = "24", height = "24", color = "var(--mono-4)" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill={color} width={width} height={height} viewBox="0 0 24 24">
    <path d="M19.6 16.26a9 9 0 0 1-3.18 3.18 8.64 8.64 0 0 1-8.72 0 8.81 8.81 0 0 1-3.18-3.18 8.42 8.42 0 0 1-1.18-4.36 8.4 8.4 0 0 1 1.18-4.36A8.66 8.66 0 0 1 7.7 4.36a8.4 8.4 0 0 1 4.36-1.18 8.42 8.42 0 0 1 4.36 1.18 8.81 8.81 0 0 1 3.18 3.18 8.64 8.64 0 0 1 0 8.72zm-1-8.18A7.64 7.64 0 0 0 12 4.3a7.39 7.39 0 0 0-3.8 1 7.59 7.59 0 0 0-2.72 2.8 7.45 7.45 0 0 0-1 3.81 7.36 7.36 0 0 0 1 3.8 7.59 7.59 0 0 0 2.78 2.76 7.46 7.46 0 0 0 3.82 1 7.35 7.35 0 0 0 3.79-1 7.51 7.51 0 0 0 2.76-2.78 7.61 7.61 0 0 0 0-7.61zm-2.07 4a.41.41 0 0 1-.13.3.4.4 0 0 1-.29.12h-3.42V16a.41.41 0 0 1-.12.3.38.38 0 0 1-.3.13h-.42a.38.38 0 0 1-.3-.13.42.42 0 0 1-.13-.3v-3.47H8a.41.41 0 0 1-.3-.12.44.44 0 0 1-.12-.3v-.42a.41.41 0 0 1 .12-.3.38.38 0 0 1 .3-.13h3.44V7.82a.42.42 0 0 1 .13-.3.41.41 0 0 1 .3-.12h.42a.41.41 0 0 1 .42.42v3.44h3.45a.37.37 0 0 1 .29.13.38.38 0 0 1 .13.3z" />
  </svg>
);

export default PlusCircle;
