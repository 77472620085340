/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Save = ({ width = "24", height = "24", color = "#132437" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill={color}
      fillRule="evenodd"
      d="M20.453 7.578c.365.365.547.807.547 1.328v10.469c0 .52-.182.964-.547 1.328a1.808 1.808 0 0 1-1.328.547H5.375c-.52 0-.964-.182-1.328-.547a1.808 1.808 0 0 1-.547-1.328V5.625c0-.52.182-.964.547-1.328a1.808 1.808 0 0 1 1.328-.547h10.469c.52 0 .963.182 1.328.547l3.281 3.281zM14.75 5h-7.5v3.75h7.5V5zm5 14.375V8.906a.52.52 0 0 0-.195-.43l-3.282-3.28A1.043 1.043 0 0 0 16 5v4.063c0 .26-.091.481-.273.664a.904.904 0 0 1-.665.273H6.937a.904.904 0 0 1-.664-.273A.904.904 0 0 1 6 9.063V5h-.625a.608.608 0 0 0-.45.176.608.608 0 0 0-.175.449v13.75c0 .182.059.332.176.45a.608.608 0 0 0 .449.175h13.75a.608.608 0 0 0 .45-.176.608.608 0 0 0 .175-.449zm-7.5-7.812c.937 0 1.745.338 2.422 1.015.677.677 1.015 1.485 1.015 2.422 0 .938-.338 1.745-1.015 2.422-.677.677-1.485 1.016-2.422 1.016-.938 0-1.745-.339-2.422-1.016S8.812 15.938 8.812 15c0-.937.339-1.745 1.016-2.422s1.484-1.015 2.422-1.015zm0 5.625c.599 0 1.113-.215 1.543-.645.43-.43.644-.944.644-1.543s-.214-1.113-.644-1.543a2.105 2.105 0 0 0-1.543-.644c-.599 0-1.113.214-1.543.644-.43.43-.645.944-.645 1.543s.215 1.113.645 1.543c.43.43.944.645 1.543.645z"
    />
  </svg>
);

export default Save;
