/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useRef, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { t } from "i18n";
import styles from "./opinion-poll-inspector.module.css";
import { updatePollDetails, addNewPollImage } from "pages/story-editor/async-action-creators";
import { notificationError } from "action-creators/notification";
import { getErrorMessage } from "pages/story-editor/utils";
import TextField from "components/text-field/text-field";
import Trash from "components/icons/trash";
import Plus from "components/icons/plus";
import Checkbox from "components/checkbox/checkbox";
import Select from "components/select/select";
import Spinner from "components/spinner/spinner";
import AddNewFile from "components/add-new-file/add-new-file";
import FieldLabel from "components/field-label/field-label";
import ImageActionBar, { ImageAction, ImageActionType } from "components/image-action-bar/image-action-bar";
import classnames from "classnames/bind";

const cx = classnames.bind(styles);

export const updateSettings = (settings, key, value) => {
  return { ...settings, [key]: value };
};

export const addOpinion = (opinions) => {
  const newOpinion = {
    text: "",
    type: "text",
    metadata: {},
    "percentage-votes": 0
  };

  return [...opinions, newOpinion];
};

export const removeOpinion = (allOpinions, index) => {
  let updatedAllOpinions = allOpinions.slice();
  updatedAllOpinions.splice(index, 1);
  return updatedAllOpinions;
};

export const updateOpinion = (allOpinions, index, value) => {
  let updatedAllOpinions = allOpinions.slice();
  const currentOpinion = updatedAllOpinions[index];
  const updatedOpinion = { ...currentOpinion, text: value };
  updatedAllOpinions[index] = updatedOpinion;
  return updatedAllOpinions;
};

export const generateVotingValue = (value) =>
  value ? { label: "Anonymous", value: true } : { label: "Login", value: false };

export const generateVotingResultsValue = (value) =>
  value === "always" ? { label: "Always", value: "always" } : { label: "Login and Vote", value: "logged-in-voted" };

const OpinionPollInspector = ({
  onChange,
  errors,
  poll,
  pollSaving,
  imageUploading,
  addNewPollImage,
  CDNCname,
  uploadError
}) => {
  const imageUploadInput = useRef<HTMLInputElement>(null);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);

  if (!poll) {
    return null;
  }

  if (pollSaving) {
    return <Spinner message={t("story-editor.inspector.saving_poll")} />;
  }

  const imageActions: ImageAction[] = [
    new ImageAction(ImageActionType.DownloadImage),
    new ImageAction(ImageActionType.ReplaceImage, () => {
      if (imageUploadInput.current) {
        imageUploadInput.current.click();
      }
    }),
    new ImageAction(ImageActionType.DeleteImage, () => onChange("hero-image", {}))
  ];

  return (
    <div className={styles["opinion-poll-inspector-container"]}>
      <div className={styles["opinion-poll-inspector-field"]}>
        <TextField
          key={`opinion-poll-topic`}
          value={poll.topic}
          label={t("story-editor.inspector.topic")}
          onChange={(value) => onChange("topic", value)}
          errorMessage={getErrorMessage(errors && errors.topic)}
        />
      </div>

      <div className={styles["opinion-poll-inspector-field"]}>
        <TextField
          key={`opinion-poll-desc`}
          value={poll.description}
          label={t("story-editor.inspector.description")}
          onChange={(value) => onChange("description", value)}
        />
      </div>

      <React.Fragment>
        <FieldLabel label={t("story-editor.inspector.hero_image")} />

        {imageUploading && (
          <div className={styles["opinion-poll-image-spinner"]}>
            <Spinner
              message={t("story-editor.inspector.uploading_image")}
              classname="opinion-poll-image-spinner-spinner"
            />
          </div>
        )}

        {!imageUploading && (
          <React.Fragment>
            {!(poll["hero-image"] && (poll["hero-image"]["temporary-key"] || poll["hero-image"]["s3-key"])) &&
              !imageUploading && (
                <React.Fragment>
                  <AddNewFile
                    accept="image/*"
                    addNewFile={addNewPollImage}
                    addNewText={t("story-editor.inspector.add_image")}
                    classnames="opinion-poll-add-new-image"
                    onError={uploadError}
                    dropzoneMessage={t("mediaLibrary.on-drag-message")}
                  />
                  <p className={styles["opinion-poll-image-info"]}>{t("story-editor.inspector.poll_image_info")}</p>
                </React.Fragment>
              )}
            {poll["hero-image"] && (poll["hero-image"]["temporary-key"] || poll["hero-image"]["s3-key"]) && (
              <div className={styles["opinion-poll-image-container"]}>
                <img
                  className={styles["opinion-poll-image-field-image"]}
                  src={
                    poll["hero-image"]["s3-key"] ? `${CDNCname}${poll["hero-image"]["s3-key"]}` : poll["hero-image"].url
                  }
                  alt={poll.topic}
                  data-test-id="opinion-poll-image"
                  onLoad={(e) => setIsImageLoading(false)}
                />
                <input
                  type="file"
                  id="poll-image"
                  accept="image/*"
                  onChange={(e) => e.target.files && addNewPollImage(Array.from(e.target.files))}
                  className={styles["opinion-poll-image-file"]}
                  ref={imageUploadInput}
                />
                <ImageActionBar
                  classname={cx("poll-image-action-bar", { "poll-image-action-bar--hide": isImageLoading })}
                  data-test-id="image-action-buttons"
                  actions={imageActions}
                  image={poll["hero-image"]}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>

      {poll &&
        poll.opinions.map((opinion, index) => (
          <div
            key={`opinion-poll-inspector-opinion-${index}`}
            className={styles["opinion-poll-inspector-field-wrapper"]}>
            <div className={styles["opinion-poll-field-input-container"]}>
              <TextField
                key={`opinion-poll-topic`}
                value={opinion.text}
                label={`${t("story-editor.inspector.option")} ${index + 1}`}
                onChange={(value) => onChange("opinions", updateOpinion(poll.opinions, index, value))}
                errorMessage={
                  errors && errors.opinion.code === "presence"
                    ? opinion.text.length === 0 && errors && getErrorMessage(errors.opinion)
                    : opinion.text.length > 0 && errors && getErrorMessage(errors.opinion)
                }
              />
            </div>
            {index > 1 && (
              <div
                className={styles["opinion-poll-field-delete"]}
                onClick={() => onChange("opinions", removeOpinion(poll.opinions, index))}>
                <Trash />
              </div>
            )}
          </div>
        ))}

      <div
        className={styles["opinion-poll-inspector-add-option"]}
        onClick={() => onChange("opinions", addOpinion(poll.opinions))}>
        <Plus fill="currentColor" width={16} height={16} />
        <span className={styles["add-option-label"]}>{t("story-editor.inspector.add_option")}</span>
      </div>

      <FieldLabel label={t("story-editor.inspector.settings")} classname="opinion-poll-settings" />
      <div className={styles["opinion-poll-inspector-field"]}>
        <Select
          options={[
            { label: "Login", value: false },
            { label: "Anonymous", value: true }
          ]}
          label={t("story-editor.inspector.voting")}
          value={poll && generateVotingValue(poll.settings["anonymous-voting?"])}
          onChange={(option: any) =>
            onChange("settings", updateSettings(poll.settings, "anonymous-voting?", option.value))
          }
        />
      </div>
      <div className={styles["opinion-poll-inspector-field"]}>
        <Select
          options={[
            { label: "Login and Vote", value: "logged-in-voted" },
            { label: "Always", value: "always" }
          ]}
          label={t("story-editor.inspector.view_results")}
          value={poll && generateVotingResultsValue(poll.settings["show-results"])}
          onChange={(option: any) => onChange("settings", updateSettings(poll.settings, "show-results", option.value))}
        />
      </div>
      <div className={styles["opinion-poll-inspector-field"]}>
        <Checkbox
          id={"opinion-poll-allow-change-vote"}
          label={t("story-editor.inspector.allow_to_change_vote")}
          checked={poll && poll.settings["change-vote?"]}
          onChange={(value) => onChange("settings", updateSettings(poll.settings, "change-vote?", value))}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    story: state.storyEditor.story,
    CDNCname: state.config["cdn-cname"],
    imageUploading: state.storyEditor.ui.pollImageUploading,
    poll:
      state.storyEditor.ui.storyElements[ownProps.storyElementId] &&
      state.storyEditor.ui.storyElements[ownProps.storyElementId].poll,
    errors:
      state.storyEditor.ui.storyElements[ownProps.storyElementId] &&
      state.storyEditor.ui.storyElements[ownProps.storyElementId]["error"],
    pollSaving:
      state.storyEditor.ui.storyElements[ownProps.storyElementId] &&
      state.storyEditor.ui.storyElements[ownProps.storyElementId]["saving"]
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: (key, value) => dispatch(updatePollDetails(ownProps.storyElementId, key, value)),
    addNewPollImage: (files) => dispatch(addNewPollImage(files, ownProps)),
    uploadError: (error) => dispatch(notificationError(error))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(OpinionPollInspector);

export { OpinionPollInspector };
