/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import Inspector from "components/inspector/inspector";
import { t } from "i18n";
import MediaGallery from "pages/media-library/components/media-gallery/media-gallery";
import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { navigateFn } from "utils/routes.utils";
import { updateCollectionAction } from "../../async-action-creators";
import { actions } from "../../actions";
import {
  COLLECTIONS_CONTENT_IMAGE_EDIT_PATH,
  COLLECTIONS_CONTENT_SETTINGS_PATH,
  COLLECTIONS_SELECTED_COLLECTION_PATH
} from "../../routes";

const ImageInspector = ({
  updateCollection,
  chooseCoverImage,
  stagingSelectedCollection,
  isActive,
  closeSettingsInspector,
  switchToImageEdit,
  selectedCollectionCoverImages,
  setCollectionCoverImages,
  coverImageUploading,
  setCollectionCoverImagesUploadStatus
}) => (
  <Inspector
    title={t("collections.choose_image")}
    actionButtonLabel={t("collections.choose")}
    onActionButtonClick={() => chooseCoverImage(stagingSelectedCollection.id)}
    isActive={isActive}
    onClose={() => closeSettingsInspector(stagingSelectedCollection.id)}
    variant="wide"
    isActionButtonDisabled={true}
    level={1}>
    <MediaGallery
      showSelectMediaProvider={true}
      switchToUploadRoute={(mediaKey) => switchToImageEdit(stagingSelectedCollection.id, mediaKey)}
      setSelectedMedia={(images) => setCollectionCoverImages(images)}
      updateImageUploadStatus={(status) => setCollectionCoverImagesUploadStatus(status)}
      showEditImage={true}
      scrollableTarget="inspector-container"
    />
  </Inspector>
);

const mapStateToProps = (state, ownProps) => {
  return {
    stagingSelectedCollection: state.collections.collectionPage.stagingSelectedCollection,
    selectedCollectionCoverImages: state.collections.collectionPage.selectedCollectionCoverImages,
    coverImageUploading: state.collections.ui.coverImageUploading.uploading
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const navigate = navigateFn(dispatch);

  return {
    closeSettingsInspector: (collectionId) => {
      dispatch({ type: actions.RESET_ERRORS });
      dispatch({ type: actions.RESET_STAGING_SELECTED_COLLECTION });
      navigate(COLLECTIONS_SELECTED_COLLECTION_PATH, { collectionId: collectionId || "new" });
    },
    switchToImageEdit: (collectionId, mediaKey) =>
      navigate(COLLECTIONS_CONTENT_IMAGE_EDIT_PATH, { collectionId: collectionId || "new", mediaKey }),
    setCollectionCoverImages: (images) => dispatch({ type: actions.SET_COVER_IMAGES, payload: { images } }),
    chooseCoverImage: (collectionId) => {
      dispatch({ type: actions.UPDATE_COVER_IMAGE });
      navigate(COLLECTIONS_CONTENT_SETTINGS_PATH, { collectionId: collectionId || "new" });
    },
    setCollectionCoverImagesUploadStatus: (status) =>
      dispatch({ type: actions.TOGGLE_COVER_IMAGE_UPLOADING, payload: { status: status.uploading } }),
    updateCollection: () => dispatch(updateCollectionAction())
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ImageInspector);
