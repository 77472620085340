/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { base64Decode } from "utils";

const cloneScriptNode = (node) => {
  let script = document.createElement("script");
  script.text = node.innerHTML;
  script.async = true;
  for (let i = node.attributes.length - 1; i >= 0; i--) {
    script.setAttribute(node.attributes[i].name, node.attributes[i].value);
  }
  return script;
};

const replaceScriptNodes = (node) => {
  if (node.tagName === "SCRIPT") {
    node.parentNode.replaceChild(cloneScriptNode(node), node);
  } else {
    let i = 0;
    let children = node.childNodes;
    while (i < children.length) {
      replaceScriptNodes(children[i++]);
    }
  }
};

class Embed extends React.Component<any, any> {
  preview: any;
  constructor(props) {
    super(props);
    this.preview = React.createRef();
    this.state = {
      embed: ""
    };
  }

  componentWillMount() {
    if (this.props.embed) {
      this.setState({ embed: base64Decode(this.props.embed) });
    }
  }

  componentDidMount() {
    replaceScriptNodes(this.preview.current);
    // Need to run this piece of code to fix image not showing up if the url is changed. Known issue with Instagram API, have to use this workaround until they fix it.
    if (window["instgrm"]) {
      window["instgrm"].Embeds.process();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (base64Decode(props.embed) !== state.embed) {
      return {
        embed: base64Decode(props.embed)
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps): boolean {
    return nextProps.embed !== this.props.embed || nextProps.showPreview !== this.props.showPreview;
  }

  render() {
    const embedData = this.props.showPreview ? this.state.embed : null;
    return <div ref={this.preview} dangerouslySetInnerHTML={{ __html: embedData }} />;
  }
}

export default Embed;
