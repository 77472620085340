/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { TextFieldWithButton } from "components/text-field/text-field";
import { t } from "i18n";

const SaveFilters = ({ filterName, updateFilterName, saveFilters, errorMessage }: any) => (
  <TextFieldWithButton
    label={t("workspace.save_filter_inspector.filter_name")}
    placeholder={t("workspace.save_filter_inspector.enter_filter_name")}
    value={filterName}
    onChange={updateFilterName}
    errorMessage={errorMessage}
    onButtonClick={() => saveFilters(filterName)}
    buttonLabel={t("workspace.save_filter_inspector.save")}
    readOnly={false}
    classname={"save-filter-textfield"}
  />
);

export default SaveFilters;
