/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState } from "../../state";
import { navigateFn } from "utils/routes.utils";
import { loadAttributes, deleteAttribute } from "../../async-action-creators";
import { Attribute } from "api/story-attributes";
import Button from "components/button/button";
import RootAttributesList from "../attributes-list/attributes-list";
import { ATTRIBUTES_NEW_PATH } from "../../routes";
import { t } from "i18n";
import styles from "./attributes-tree.module.css";

interface StateProps {
  attributes: Attribute[];
}

interface DispatchProps {
  loadAttributes: () => void;
  addNewAttribute: () => void;
  deleteAttribute: (attribute: Attribute) => void;
}

type Props = StateProps & DispatchProps;

const AttributesTree: React.SFC<Props> = ({ attributes, addNewAttribute, deleteAttribute }) => (
  <React.Fragment>
    <div>
      <header className={styles["attribute-header"]} data-test-id="attribute-header">
        <h3 className={styles["attribute-title"]} data-test-id="attribute-title">
          {t("attributes.title")}
        </h3>
        <Button type="secondary" onClick={addNewAttribute} testId="attribute-action-btn">
          {t("attributes.cta.create")}
        </Button>
      </header>
      <RootAttributesList attributes={attributes} deleteAttribute={deleteAttribute} />
    </div>
  </React.Fragment>
);

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    attributes: state.attributes.attributes
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  const navigate = dispatch(navigateFn);

  return {
    loadAttributes: () => dispatch(loadAttributes()),
    addNewAttribute: () => navigate(ATTRIBUTES_NEW_PATH),
    deleteAttribute: (attribute) => dispatch(deleteAttribute(attribute))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AttributesTree);

export { AttributesTree };
