/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { ContextMenuItem } from "components/context-menu/context-menu";
import styles from "./workflow-buttons.module.css";

const WorkflowButtons = ({
  workflowTransitions,
  updateStoryStatus,
  isStoryLocked,
  isStatusTransitionInProgress,
  isCurrentStoryPrint,
  currentStoryAction
}) => {
  let failureWorkflowActions = workflowTransitions.filter(
    (transition) =>
      transition.action === "story-retract" ||
      transition.action === "story-reject" ||
      transition.action === "story-close" ||
      transition.style === "story-schedule-modify" ||
      transition.action === "story-schedule-cancel"
  );
  // Removing the reject work flow for a print story present in the printed swimlane
  if (isCurrentStoryPrint && currentStoryAction === "pending-approval") {
    failureWorkflowActions = failureWorkflowActions.filter((transition) => transition.action !== "story-reject");
  }
  if (failureWorkflowActions.length === 0) {
    return null;
  }
  return (
    <div className={styles["workflow-buttons"]}>
      {failureWorkflowActions.map((transition) => {
        return (
          <ContextMenuItem
            label={t(`story-editor.header.${transition["action"]}`)}
            onClick={() => updateStoryStatus(transition.action)}
            isDisabled={isStoryLocked || isStatusTransitionInProgress}
          />
        );
      })}
    </div>
  );
};

export default WorkflowButtons;
