/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { compose, AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Edit from "components/icons/edit";
import Trash from "components/icons/trash";
import Chip from "components/chip/chip";
import styles from "./card-details.module.css";
import classnames from "classnames/bind";
import { removeProtocol } from "utils";
import { selectMenuGroups, selectSections } from "store/domain/selectors";
import { get } from "lodash";
import {
  PartialAppState,
  Domain,
  Sections,
  MenuGroups,
  Collections,
  InspectorStateType,
  ConfirmationModalType,
  CurrentDomain,
  openInspector,
  showConfirmationModal,
  replaceCurrentDomain
} from "store/domain/domain";

const cx = classnames.bind(styles);
const TRANSLATION_PATH = "secret-mode.domain-manager.card-details";

interface StateProps {
  allSections: Sections;
  allMenuGroups: MenuGroups;
  allDomainsCollections: Collections;
}

interface OwnProps {
  domain: Domain;
}

interface DispatchProps {
  openInspector: (domain: CurrentDomain) => void;
  onDeleteClick: (domain: CurrentDomain) => void;
}

type CardDetailsProps = OwnProps & DispatchProps & StateProps;

function DisplayArray({ label, children }: { label: string; children: React.ReactNode }) {
  return (
    <div className={styles["domain-field"]}>
      {`${label}: `}
      <span>{children}</span>
    </div>
  );
}

function URL({ value, classes }: { value: string; classes: Array<string> }) {
  return (
    <a className={cx("url", ...classes)} href={value} target="_blank" rel="noopener noreferrer">
      {removeProtocol(value)}
    </a>
  );
}

export function CardDetails({
  domain,
  openInspector,
  allSections,
  allMenuGroups,
  allDomainsCollections,
  onDeleteClick
}: CardDetailsProps) {
  const {
    name,
    "host-url": hostUrl,
    "section-ids": sectionIds,
    "menu-groups": menuGroupIds,
    "home-collection-id": homeCollectionId,
    "beta-host-url": betaHostUrl,
    config
  } = domain;

  const sections = sectionIds.map((sectionId) => (
    <Chip key={sectionId} classname={styles["chip"]} value={get(allSections, [sectionId, "name"])} />
  ));

  const menuGroups = menuGroupIds.map((menuGroupId) => (
    <Chip key={menuGroupId} classname={styles["chip"]} value={get(allMenuGroups, [menuGroupId, "name"])} />
  ));

  const homeCollection = homeCollectionId && allDomainsCollections[homeCollectionId].name;

  const sectionsUI = <DisplayArray label={t(`${TRANSLATION_PATH}.sections-label`)}>{sections}</DisplayArray>;

  const menuGroupsUI = menuGroups.length ? (
    <DisplayArray label={t(`${TRANSLATION_PATH}.menu-groups-label`)}>{menuGroups}</DisplayArray>
  ) : null;

  const homeCollectionUI = homeCollection && (
    <div className={styles["domain-field"]}>
      {`${t(`${TRANSLATION_PATH}.home-collection-label`)}:  `}
      <span className={styles["domain-field-value"]}>{homeCollection}</span>
    </div>
  );

  const languageNameUI = get(config, ["language", "name"]) && (
    <div className={styles["domain-field"]}>
      {`${t("common.name")}:  `}
      <span className={styles["domain-field-value"]}>{get(config, ["language", "name"])}</span>
    </div>
  );

  const languageIsoCodeUI = get(config, ["language", "iso-code"]) && (
    <div className={styles["domain-field"]}>
      {`${t(`${TRANSLATION_PATH}.language-iso-code-label`)}:  `}
      <span className={styles["domain-field-value"]}>{get(config, ["language", "iso-code"])}</span>
    </div>
  );

  const languageIetfCodeUI = get(config, ["language", "ietf-code"]) && (
    <div className={styles["domain-field"]}>
      {`${t(`${TRANSLATION_PATH}.language-ietf-code-label`)}:  `}
      <span className={styles["domain-field-value"]}>{get(config, ["language", "ietf-code"])}</span>
    </div>
  );

  const languageDirectionUI = get(config, ["language", "direction"]) && (
    <div className={styles["domain-field"]}>
      {`${t("common.direction")}:  `}
      <span className={styles["domain-field-value"]}>{get(config, ["language", "direction"])}</span>
    </div>
  );

  const betaHostUI = betaHostUrl && (
    <div className={styles["domain-field"]}>
      {`${t(`${TRANSLATION_PATH}.beta-host-label`)}:  `}
      <URL value={betaHostUrl} classes={["domain-field-value"]}></URL>
    </div>
  );

  const showLanguageConfig = languageNameUI || languageIsoCodeUI || languageIetfCodeUI || languageDirectionUI;

  return (
    <div className={styles["card-container"]} data-test-id="card-container">
      <div className={styles["header-container"]}>
        <div className={styles["header"]}>
          <div className={styles["domain-name"]} data-test-id="domain-name">
            {name}
          </div>
          <URL value={hostUrl} classes={["domain-host-url", "domain-field"]}></URL>
        </div>
        <div className={styles["context-menu"]}>
          <span
            onClick={() => openInspector(domain)}
            className={styles["domain-action-btn"]}
            data-test-id="edit-button">
            <Edit width={18} height={18} color="var(--mono-4)" />
          </span>
          <span
            onClick={() => onDeleteClick(domain)}
            className={styles["domain-action-btn"]}
            data-test-id="delete-button">
            <Trash width={18} height={18} color="var(--mono-4)" />
          </span>
        </div>
      </div>
      {sectionsUI}
      {menuGroupsUI}
      {homeCollectionUI}
      {showLanguageConfig && (
        <div className={styles["language-container"]}>
          <div className={styles["domain-field"]}>Language</div>
          <div className={styles["language-config"]}>
            {languageNameUI}
            {languageIsoCodeUI}
            {languageIetfCodeUI}
            {languageDirectionUI}
          </div>
        </div>
      )}
      {betaHostUI}
    </div>
  );
}

const mapStateToProps = (state: PartialAppState) => {
  return {
    allDomainsCollections: state.domain.app.collections,
    allSections: selectSections(state),
    allMenuGroups: selectMenuGroups(state)
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
  return {
    openInspector: (domain: Domain) => dispatch(openInspector(InspectorStateType.Edit, domain)),
    onDeleteClick: (domain: CurrentDomain) => {
      dispatch(replaceCurrentDomain(domain));
      dispatch(showConfirmationModal(ConfirmationModalType.Delete));
    }
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CardDetails);
