/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import styles from "./grid.module.css";

export default function FormEditorGrid({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <div className={styles["form-editor-container"]}>
      <div className={styles["form-editor-content"]}>{children}</div>
    </div>
  );
}
