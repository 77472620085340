/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { findElementNP } from "../find";
import { addIfNotExists } from "utils/array.utils";
import { removeKeyFromObject } from "utils";
import { AnyStory, StoryElement, CompositeStoryElement, ChildStoryElement } from "api/story";
import { EditorState, Transaction } from "prosemirror-state";
import { StoryElementId } from "api/primitive-types";
import { getDistinctStoryElements } from "pages/story-editor/utils";
import { setTextSelectionToDocumentEnd } from "../selection";

function deleteElementFromStory(
  story: AnyStory,
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement
): AnyStory {
  const cardId = storyElement["card-id"],
    card = story.cards[cardId],
    cardTree = card && card.tree,
    newCardTree = cardTree && cardTree.filter((elementId) => elementId !== storyElement.id),
    updatedCards = cardId ? addIfNotExists(story["updated-cards"], cardId) : story["updated-cards"];

  return {
    ...story,
    cards: {
      ...story.cards,
      [cardId]: {
        ...story.cards[cardId],
        tree: getDistinctStoryElements(newCardTree)
      }
    },
    "story-elements": removeKeyFromObject(storyElement.id, story["story-elements"]),
    entities: removeKeyFromObject(`storyElement_${storyElement.id}`, story.entities),
    "updated-cards": updatedCards
  };
}

function deleteElementFromCompositeElement(story: AnyStory, storyElement: ChildStoryElement): AnyStory {
  const compositeStoryElementId = storyElement["composite-element-id"];
  const compositeStoryElement = story["story-elements"][compositeStoryElementId] as CompositeStoryElement;
  const compositeStoryElementTree = compositeStoryElement.tree;
  const newTree = compositeStoryElementTree.filter((elementId) => elementId !== storyElement.id);

  const modifiedStoryElements = removeKeyFromObject(storyElement.id, story["story-elements"]);
  const updatedCards = compositeStoryElement["card-id"]
    ? addIfNotExists(story["updated-cards"], compositeStoryElement["card-id"])
    : story["updated-cards"];

  return {
    ...story,
    "story-elements": {
      ...modifiedStoryElements,
      [compositeStoryElementId]: {
        ...modifiedStoryElements[compositeStoryElementId],
        tree: newTree
      }
    },
    "updated-cards": updatedCards
  };
}

function deleteElementFromEditorState(
  editorState: EditorState,
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement
): EditorState {
  const tr = editorState.tr;
  const elementNP = findElementNP(editorState, storyElement);
  if (elementNP) {
    // Delete element from a card and apply transaction
    tr.delete(elementNP.pos, elementNP.pos + elementNP.node.nodeSize);
    const updatedEditorState = editorState.apply(tr);

    // Set cursor to document end
    const trWithFocus = setTextSelectionToDocumentEnd(updatedEditorState.tr, updatedEditorState);
    return updatedEditorState.apply(trWithFocus);
  }
  return editorState;
}

function deleteStoryElement(
  story: AnyStory,
  editorState: EditorState,
  storyElement: StoryElement | ChildStoryElement | CompositeStoryElement
): {
  story: AnyStory;
  editorState: EditorState;
} {
  const newStory = storyElement["composite-element-id"]
    ? deleteElementFromCompositeElement(story, storyElement as ChildStoryElement)
    : deleteElementFromStory(story, storyElement);
  const newEditorState = deleteElementFromEditorState(editorState, storyElement);

  return {
    story: newStory,
    editorState: newEditorState
  };
}

function removeChildElementsFromCompositeElement(
  story: AnyStory,
  childStoryElementIds: Array<StoryElementId>
): AnyStory {
  return childStoryElementIds.reduce((accumulator: AnyStory, childStoryElementId: StoryElementId) => {
    let childElement = story["story-elements"][childStoryElementId] as ChildStoryElement;
    return deleteElementFromCompositeElement(accumulator, childElement);
  }, story);
}

function deleteCompositeStoryElement(
  story: AnyStory,
  editorState: EditorState,
  storyElement: CompositeStoryElement
): {
  story: AnyStory;
  editorState: EditorState;
} {
  const childStoryElementIds = storyElement.tree;
  const newStoryWithoutChildElements = removeChildElementsFromCompositeElement(story, childStoryElementIds);
  const newStory = deleteElementFromStory(newStoryWithoutChildElements, storyElement);
  const newEditorState = deleteElementFromEditorState(editorState, storyElement);

  return {
    story: newStory,
    editorState: newEditorState
  };
}

function deleteFromTransaction(tr: Transaction, from: number, to: number) {
  return tr.delete(from, to);
}

export { deleteStoryElement, deleteElementFromEditorState, deleteCompositeStoryElement, deleteFromTransaction };
