/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { t } from "i18n";

const generateAdvancedInspectorTabs = ({ showAttributes }) => {
  const attributeTab = {
    label: t("story-editor.inspector.attributes"),
    value: "attributes"
  };

  let tabs = [
    {
      label: t("story-editor.inspector.metadata"),
      value: "metadata"
    },
    {
      label: t("story-editor.inspector.social"),
      value: "social-notifications"
    }
  ];

  showAttributes && tabs.splice(1, 0, attributeTab);

  return tabs;
};
export default generateAdvancedInspectorTabs;
