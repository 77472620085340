/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

type Props = {
  dataLength: number;
  next: () => void;
  hasMore: boolean;
  loader: React.ReactNode;
  scrollableTarget?: React.ReactNode;
  endMessage?: React.ReactNode;
  style?: React.CSSProperties;
  hasChildren?: boolean;
  pullDownToRefresh?: boolean;
  refreshFunction?: () => void;
};
const InfiniteScrollList: React.FC<Props> = ({
  dataLength,
  next,
  hasMore,
  loader,
  scrollableTarget = "main-layout",
  endMessage,
  style,
  hasChildren,
  children,
  pullDownToRefresh,
  refreshFunction
}) => {
  return (
    <InfiniteScroll
      hasChildren={hasChildren}
      style={style}
      dataLength={dataLength}
      next={next}
      hasMore={hasMore}
      loader={loader}
      endMessage={endMessage}
      pullDownToRefresh={pullDownToRefresh}
      scrollableTarget={scrollableTarget}
      refreshFunction={refreshFunction}>
      {children}
    </InfiniteScroll>
  );
};

export default InfiniteScrollList;
