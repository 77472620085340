/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import TextField from "../../../../components/text-field/text-field";
import Button from "../../../../components/button/button";
import Trash from "../../../../components/icons/trash";
import { addElementToCompositeStoryElement, deleteStoryElementAction } from "../../async-action-creators";

import styles from "./reference.module.css";
import { t } from "i18n";
import { ChildStoryElement, CompositeStoryElement } from "api/story";
import { PartialAppState } from "pages/story-editor/state";
import { StoryElementId } from "api/primitive-types";

interface StateProps {
  readOnly: boolean;
}

interface DispatchProps {
  deleteElement: (storyElement: ChildStoryElement) => void;
  addElement: (storyElement: CompositeStoryElement) => void;
}

interface ComponentProps {
  storyElement: CompositeStoryElement;
  childElements: { [x: string]: ChildStoryElement };
  onChange: (id: StoryElementId, changes: Partial<ChildStoryElement>) => void;
}

type Props = StateProps & DispatchProps & ComponentProps;

class References extends React.Component<Props> {
  formHtml = ({ name, url, description }: { name: string; url: string; description: string }) => {
    return "<div><p>" + name + '</p><p><a href="' + url + '">' + description + "</a></p></div>";
  };

  onChange = (reference: ChildStoryElement, value: string, field: string) => {
    const metadata = { ...reference.metadata, [field]: value };
    const text = this.formHtml(metadata);
    const changes = { metadata, text };
    this.props.onChange(reference.id, changes);
  };

  render() {
    const referencesIds = this.props.storyElement.tree;
    const childElements = this.props.childElements;

    return (
      <div className={styles["reference-story-element-container"]}>
        {referencesIds.map((referenceId) => (
          <div className={styles["reference-story-element-fields"]} key={referenceId}>
            <TextField
              label={t("story-editor.story-element.name")}
              value={childElements[referenceId].metadata.name}
              onChange={(value) => this.onChange(childElements[referenceId], value, "name")}
              disabled={this.props.readOnly}
            />
            <TextField
              label={t("story-editor.story-element.url")}
              value={childElements[referenceId].metadata.url}
              onChange={(value) => this.onChange(childElements[referenceId], value, "url")}
              disabled={this.props.readOnly}
            />
            <TextField
              label={t("story-editor.story-element.description")}
              value={childElements[referenceId].metadata.description}
              onChange={(value) => this.onChange(childElements[referenceId], value, "description")}
              disabled={this.props.readOnly}
            />
            {!this.props.readOnly && (
              <div
                data-test-id="delete-reference"
                className={styles["reference-story-element-delete"]}
                onClick={() => this.props.deleteElement(childElements[referenceId])}>
                <Trash width={16} height={16} /> Delete Reference
              </div>
            )}
          </div>
        ))}
        {!this.props.readOnly && (
          <div className={styles["reference-story-element-add"]} data-test-id="add-reference">
            <Button type="secondary" onClick={() => this.props.addElement(this.props.storyElement)}>
              {t("story-editor.story-element.add-reference")}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: PartialAppState) => {
  return {
    readOnly: state.storyEditor.ui.isStoryLocked
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    deleteElement: (storyElement: ChildStoryElement) => dispatch(deleteStoryElementAction(storyElement)),
    addElement: (storyElement: CompositeStoryElement) => dispatch(addElementToCompositeStoryElement(storyElement))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(References);

export { References };
