/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

export interface VideoSignature {
  asset_id: string;
  upload_url: string;
  collection_id: string;
  acl: string;
  status: string;
  output: {
    format: string;
    status_url: string;
    playback_url: string;
    thumbnail_url: string[];
    preview_thumbnails_url: string;
  };
}

export const getSignedUrl = (payload: any): Promise<VideoSignature> => {
  return client
    .url("/api/video/signed-url")
    .post({
      source_id: payload["gumletCollectionId"],
      format: "MP4",
      mp4_access: true,
      title: payload["file"]["name"]
    })
    .json();
};
