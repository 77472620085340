/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ClientValidationError, ServerValidationError, validate } from "utils/validation.utils";
import { IntegrationConfig, Integrations } from "api/integrations";
import { get } from "lodash";
import { t } from "i18n";

const constraintsBrightcove = {
  "client-id": { presence: { allowEmpty: false } },
  "client-secret": { presence: { allowEmpty: false } },
  "policy-key": { presence: { allowEmpty: false } }
};
const constraintsPolltype = {
  "api-key": { presence: { allowEmpty: false } }
};
const constraintsPushEngage = {
  "api-key": { presence: { allowEmpty: false } }
};
const constraintsGoogleMaps = {
  "api-key": { presence: { allowEmpty: false } }
};
const constraintsCommon = {
  "api-key": { presence: { allowEmpty: false } },
  "api-secret": { presence: { allowEmpty: false } }
};

const constraintsCopyscape = {
  username: { presence: { allowEmpty: false } },
  "api-key": { presence: { allowEmpty: false } }
};

const constraintsFastly = {
  "access-key": { presence: { allowEmpty: false } },
  "service-id": { presence: { allowEmpty: false } }
};

const constraintsMandrill = {
  "api-key": { presence: { allowEmpty: false } }
};

const constraintsMoengage = {
  "app-id": { presence: { allowEmpty: false } },
  "api-secret": { presence: { allowEmpty: false } },
  "data-center-id": { presence: { allowEmpty: false } }
};

const constraintsFcm = {
  "project-id": { presence: { allowEmpty: false } },
  "client-email": { presence: { allowEmpty: false } },
  "private-key": { presence: { allowEmpty: false } }
};

const constraintsOneSignal = {
  "api-key": { presence: { allowEmpty: false } },
  "app-id": { presence: { allowEmpty: false } }
};

const constraintsWru = {
  mid: { presence: { allowEmpty: false } }
};

const constraintsVidible = {
  "buyer-company-id": { presence: { allowEmpty: false } },
  "company-id": { presence: { allowEmpty: false } },
  "player-id": { presence: { allowEmpty: false } },
  "playlist-id": { presence: { allowEmpty: false } }
};

const constraintsGA3Realtime = {
  "view-id": { presence: { allowEmpty: false } },
  "private-key": { presence: { allowEmpty: false } },
  "client-email": { presence: { allowEmpty: false } }
};

const constraintsGoogleIndexing = {
  "private-key": { presence: { allowEmpty: false } },
  "client-email": { presence: { allowEmpty: false } }
};
const constraintsIzooto = {
  "api-key": { presence: false }
};

function getSpecificIntegrationConstraints(integrationName: string, integrationValue: any) {
  switch (integrationName) {
    case Integrations.Brightcove:
      return constraintsBrightcove;
    case Integrations.Polltype:
      return constraintsPolltype;
    case Integrations.GoogleMaps:
      return constraintsGoogleMaps;
    case Integrations.Moengage:
      return constraintsMoengage;
    case Integrations.Fastly:
      return constraintsFastly;
    case Integrations.Copyscape:
      return constraintsCopyscape;
    case Integrations.OneSignal:
      return constraintsOneSignal;
    case Integrations.Wru:
      return constraintsWru;
    case Integrations.Mandrill:
      return constraintsMandrill;
    case Integrations.Fcm:
      return constraintsFcm;
    case Integrations.Facebook:
    case Integrations.Twitter:
    case Integrations.Linkedin:
    case Integrations.Getty:
    case Integrations.Metype:
      return constraintsCommon;
    case Integrations.Vidible:
      return constraintsVidible;
    case Integrations.PushEngage:
      return constraintsPushEngage;
    case Integrations.Izooto:
      return constraintsIzooto;
    case Integrations.GoogleAnalytics3Realtime:
      return constraintsGA3Realtime;
    case Integrations.GoogleIndexing:
      return constraintsGoogleIndexing;

    default:
      const MandateAllConstraint = Object.keys(integrationValue).reduce((a, v) => {
        return { ...a, [v]: { presence: { allowEmpty: false } } };
      }, {});
      return MandateAllConstraint;
  }
}

export function validateIntegrationConfig(selectedIntegration: IntegrationConfig) {
  const saveErrors = validate(
    { ...selectedIntegration.value },
    getSpecificIntegrationConstraints(selectedIntegration.key, { ...selectedIntegration.value })
  );
  const errors = saveErrors
    ? Object.assign({}, selectedIntegration, {
        value: Object.assign({}, saveErrors)
      })
    : null; //TODO: For typescript to work
  return errors;
}

export function getErrorMessage(integration: Integrations, field: string, errors: Error | null) {
  if (errors instanceof ClientValidationError) {
    return get(errors, ["errors", "value", field, "0"]);
  }

  if (errors instanceof ServerValidationError) {
    const error = get(errors, ["errors", field]);
    if (!error) return;
    if (integration === Integrations.GoogleAnalytics3Realtime) {
      switch (field) {
        case "view-id":
          return t("integrations.analytics.ga3-realtime.validation-errors.invalid-view-id");

        case "private-key":
          return t("integrations.analytics.ga3-realtime.validation-errors.invalid-private-key");

        case "client-email":
          return t("integrations.analytics.ga3-realtime.validation-errors.invalid-client-email");
        case "message":
          // This is a dynamic error message from GA, hence not translating this
          return error;
      }
    } else if (integration === Integrations.GoogleIndexing) {
      switch (field) {
        case "private-key":
          return t("integrations.others.google-indexing.validation-errors.invalid-private-key");

        case "client-email":
          return t("integrations.others.google-indexing.validation-errors.invalid-client-email");
        case "message":
          // This is a dynamic error message from GI, hence not translating this
          return error;
      }
    } else if (integration === Integrations.GoogleAnalytics4Reporting) {
      switch (field) {
        case "property-id":
          return t("integrations.analytics.ga4-reporting.validation-errors.invalid-property-id");

        case "private-key":
          return t("integrations.analytics.ga4-reporting.validation-errors.invalid-private-key");

        case "client-email":
          return t("integrations.analytics.ga4-reporting.validation-errors.invalid-client-email");
        case "message":
          // This is a dynamic error message from GA, hence not translating this
          return error;
      }
    }
  }
  return null;
}
