/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import Roles from "./roles";
import { ROLES_PATH, ROLES_EDIT_PATH, ROLES_NEW_PATH } from "./routes";

export const Router = () => (
  <React.Fragment>
    <Switch>
      <Route exact path={ROLES_PATH} render={(props) => <Roles {...props} />} />
      <Route exact path={ROLES_EDIT_PATH} component={Roles} />
      <Route exact path={ROLES_NEW_PATH} component={Roles} />
    </Switch>
  </React.Fragment>
);
