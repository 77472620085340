/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const CUSTOM_METADATA_INDEX_PATH = "/manage/custom-metadata";
export const CUSTOM_METADATA_NEW_PATH = "/manage/custom-metadata/new";
export const CUSTOM_METADATA_EDIT_PATH = "/manage/custom-metadata/:id";
