/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import classnames from "classnames/bind";

import Headline from "components/icons/headline";
import SubTitle from "components/icons/sub-title";
import Media from "components/icons/media";

// import { StoryId } from "api/primitive-types";
import { PartialAppState } from "../../state";

import styles from "./story-display.module.css";
import { Story, StoryElementsUI } from "api/story";
import NoContentCard from "components/no-content-card/no-content-card";
import File from "components/icons/file";
import Spinner from "components/spinner/spinner";
import { t } from "i18n";
import { UUID, CardId } from "api/primitive-types";
import { updateImportCardIdAction, importCardAction } from "../../async-action-creators";
import CardDisplay from "./card-display";
import DownArrowCircle from "components/icons/down-arrow-circle";

const cx = classnames.bind(styles);

interface StateProps {
  selectedStory: Story | null;
  loading: boolean;
  selectedCardId: CardId | "";
  isBannerPresent: boolean;
  storyElementsUI?: StoryElementsUI;
}

interface DispatchProps {
  onCardSelect: (importCardId: UUID) => void;
  onImportCard: (cardId: CardId) => void;
}

type Props = StateProps & DispatchProps;

const StoryDisplay: React.SFC<Props> = ({
  loading,
  selectedStory,
  onCardSelect,
  selectedCardId,
  onImportCard,
  isBannerPresent,
  storyElementsUI
}) => {
  const cardClass = (cardContentId: CardId) =>
    cx("story-display", "card", { "is-selected": cardContentId === selectedCardId });

  const importBarClass = cx("import-sticky-bar", { "with-banner": isBannerPresent });

  if (!selectedStory && !loading) {
    return (
      <NoContentCard>
        <File />
      </NoContentCard>
    );
  }
  return (
    <React.Fragment>
      {!selectedStory && loading && <Spinner message={t("story-editor.inspector.fetching-selected-story")} />}
      {selectedStory && (
        <div className={styles["story-display-container"]} data-test-id="story-display-container">
          <div className={styles["header-card"]}>
            <Headline />
            <h3 className={styles["story-title"]}>{selectedStory.headline}</h3>
            {selectedStory.subheadline && (
              <React.Fragment>
                <SubTitle />
                <div
                  className={styles["story-subtitle"]}
                  dangerouslySetInnerHTML={{
                    __html: selectedStory.subheadline || ""
                  }}
                />
              </React.Fragment>
            )}

            <div className={styles["hero-image"]}>
              {selectedStory["hero-image"].url ? (
                <img src={selectedStory["hero-image"].url} alt={selectedStory["hero-image"]["alt-text"] || undefined} />
              ) : (
                <NoContentCard>
                  <Media />
                </NoContentCard>
              )}
            </div>
          </div>
          <div className={styles["cards"]} data-test-id="story-display-cards">
            {selectedStory.tree.map(({ "content-id": cardContentId }) => (
              <div className={cardClass(cardContentId)} onClick={() => onCardSelect(cardContentId)} key={cardContentId}>
                <CardDisplay
                  storyElementIds={selectedStory.cards[cardContentId]["tree"]}
                  allStoryElements={selectedStory["story-elements"]}
                  storyElementsUI={storyElementsUI}
                />
              </div>
            ))}
          </div>
          {selectedCardId.length !== 0 && (
            <div
              className={importBarClass}
              data-test-id="story-display-import-card"
              onClick={() => onImportCard(selectedCardId)}>
              <DownArrowCircle /> {t("story-editor.inspector.import-this-card")}
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    selectedStory: state.storyEditor.app.importCard.selectedStory,
    loading: state.storyEditor.ui.loading,
    selectedCardId: state.storyEditor.app.importCard.selectedCardId,
    isBannerPresent: !!(
      state.config.publisherWideBannerMessage ||
      (state.storyEditor.ui.banner && state.storyEditor.ui.banner.message)
    ),
    storyElementsUI: state.storyEditor.ui.storyElements
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    onCardSelect: (importCardId) => dispatch(updateImportCardIdAction(importCardId)),
    onImportCard: (cardId) => dispatch(importCardAction(cardId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoryDisplay);

export { StoryDisplay };
