/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState } from "../../state";
import { navigate } from "utils/routes.utils";
import { moveMenuItem, deleteMenuItem, toggleDeleteConfirmation } from "../../async-action-creators";
import { MenuItem, MenuItemMoveDirection } from "api/menu-item";
import ConfirmationModal from "components/confirmation-modal/confirmation-modal";
import Button from "components/button/button";
import RootMenuItemsList from "../menu-items-list/menu-items-list";
import { MENU_NEW_PATH, MENU_ITEM_NEW_PATH } from "../../routes";
import { t } from "i18n";
import styles from "./menu-items-tree.module.css";
import { MenuGroup } from "api/menu-group";
import { MenuGroupId } from "api/primitive-types";

interface StateProps {
  selectedMenu: MenuGroup | null;
  menuItems: MenuItem[];
  deleteConfirmationVisible: boolean;
  menuItemToBeDeleted: MenuItem | null;
}

interface DispatchProps {
  addNewMenu: () => void;
  addNewMenuItem: (menuId: MenuGroupId) => void;
  moveMenuItem: (menuItem: MenuItem, direction: MenuItemMoveDirection) => void;
  deleteMenuItem: (menuItem: MenuItem) => void;
  toggleDeleteConfirmation: (menuItem: MenuItem | null) => void;
}

type Props = StateProps & DispatchProps;

const MenuItemsTree: React.SFC<Props> = ({
  addNewMenu,
  selectedMenu,
  menuItems,
  addNewMenuItem,
  moveMenuItem,
  deleteMenuItem,
  deleteConfirmationVisible,
  toggleDeleteConfirmation,
  menuItemToBeDeleted
}) => (
  <React.Fragment>
    <div>
      {selectedMenu && (
        <header className={styles["menu-item-header"]} data-test-id="menu-item-header">
          <h3 className={styles["menu-item-title"]} data-test-id="menu-item-title">
            {t("menu.menu-group-title")}
            <span className={styles["menu-name"]}>: {selectedMenu.name}</span>
          </h3>

          <Button type="secondary" onClick={() => addNewMenuItem(selectedMenu.id)}>
            {t("menu.cta.add-item")}
          </Button>
        </header>
      )}
      <RootMenuItemsList
        addNewMenu={addNewMenu}
        selectedMenu={selectedMenu}
        allMenuItems={menuItems}
        moveMenuItem={moveMenuItem}
        deleteMenuItem={toggleDeleteConfirmation}
      />
      <ConfirmationModal
        showConfirmationModal={deleteConfirmationVisible}
        headerText={t("menu.confirmation_modal.delete_confirmation")}
        text={t("menu.confirmation_modal.delete_message")}
        confirmButtonLabel={t("menu.confirmation_modal.delete")}
        cancelButtonLabel={t("menu.confirmation_modal.cancel")}
        onConfirm={() => {
          if (menuItemToBeDeleted) {
            deleteMenuItem(menuItemToBeDeleted);
            toggleDeleteConfirmation(null);
          } else {
            toggleDeleteConfirmation(null);
          }
        }}
        onCancel={() => toggleDeleteConfirmation(null)}
        onClose={() => toggleDeleteConfirmation(null)}
      />
    </div>
  </React.Fragment>
);

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    selectedMenu: state.menu.app.selectedMenu,
    menuItems: state.menu.menuItems,
    menuItemToBeDeleted: state.menu.app.itemToBeDeleted,
    deleteConfirmationVisible: state.menu.ui.deleteConfirmationVisible
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    addNewMenu: () => dispatch(navigate(MENU_NEW_PATH)),
    addNewMenuItem: (menuId: MenuGroupId) => dispatch(navigate(MENU_ITEM_NEW_PATH, { menuId })),
    moveMenuItem: (menuItem, direction) => dispatch(moveMenuItem(menuItem, direction)),
    deleteMenuItem: (menuItem) => dispatch(deleteMenuItem(menuItem)),
    toggleDeleteConfirmation: (menuItem: MenuItem | null) => dispatch(toggleDeleteConfirmation(menuItem))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MenuItemsTree);

export { MenuItemsTree };
