/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction, compose } from "redux";
import { t } from "i18n";
import styles from "./story-settings.module.css";
import {
  PartialAppState,
  loadSettings,
  updateFeatureSettings,
  attachmentSlug,
  entityAsTagSlug,
  textToSpeechSlug,
  removeFeatureSettings
} from "store/secret-mode/secret-mode";
import { Feature, FeatureSettings } from "store/feature/feature";
import { enableSaveButton } from "store/secret-mode/secret-mode";
import Spinner from "components/spinner/spinner";
import SettingsContainer from "components/settings/settings-container/settings-container";
import SettingsSection from "components/settings/settings-section/settings-section";
import SwitchSetting from "components/switch-setting/switch-setting";
import NumberField from "components/number-field/number-field";
import Spacer from "components/spacer/spacer";
import Checkbox from "components/checkbox/checkbox";
import { useId } from "react-id-generator";

const TRANSLATION_PATH = "secret-mode.story";

interface StateProps {
  isLoading: boolean;
  loadingFailed: boolean;
  attachment: Feature;
  textToSpeech: Feature;
  entityAsTag: Feature;
}

interface DispatchProps {
  loadStorySettings: ({ featureSlugs }: { featureSlugs: string[] }) => void;
  updateFeatureSettings: (setting: FeatureSettings) => void;
  unloadFeatures: (features: string[]) => void;
}

type StorySettingsProps = StateProps & DispatchProps;

const textToSpeechOptions = {
  all: "All",
  none: "None"
};

// TODO Remove hints for number fields when they have ability to display units
export const StorySettings: React.FC<StorySettingsProps> = ({
  isLoading,
  loadingFailed,
  loadStorySettings,
  textToSpeech,
  attachment,
  entityAsTag,
  updateFeatureSettings,
  unloadFeatures
}) => {
  useEffect(() => {
    loadStorySettings({ featureSlugs: [attachmentSlug, entityAsTagSlug, textToSpeechSlug] });
    return () => unloadFeatures([attachmentSlug, entityAsTagSlug, textToSpeechSlug]);
  }, [loadStorySettings, unloadFeatures]);

  const [checkboxId] = useId(1, "sm-text-to-speech-checkbox");

  const attachmentConfig = (attachment && attachment.config) || {};
  const textToSpeechConfig = (textToSpeech && textToSpeech.config) || {};

  if (isLoading) {
    return <Spinner message={t(`${TRANSLATION_PATH}.loading`)} classname={styles["story-settings-spinner"]} />;
  }

  return loadingFailed ? null : (
    <SettingsContainer>
      <SettingsSection title={t(`${TRANSLATION_PATH}.story-settings.title`)}>
        {attachment && (
          <NumberField
            label={t(`${TRANSLATION_PATH}.story-settings.attachment-label`)}
            value={attachmentConfig["max-size-in-mb"]}
            hint={t(`${TRANSLATION_PATH}.story-settings.attachment-hint`)}
            min={0}
            onChange={(maxSizeInMB) =>
              updateFeatureSettings({
                [attachmentSlug]: {
                  ...attachment,
                  config: { ...attachmentConfig, "max-size-in-mb": maxSizeInMB }
                }
              })
            }
          />
        )}

        {entityAsTag && (
          <SwitchSetting
            label={t(`${TRANSLATION_PATH}.story-settings.entity-as-tag-label`)}
            toggle={entityAsTag.toggle}
            hint={t(`${TRANSLATION_PATH}.story-settings.entity-as-tag-hint`)}
            onChange={(checked) => {
              const changed = { ...entityAsTag, toggle: checked };
              updateFeatureSettings({ [entityAsTagSlug]: changed });
            }}
          />
        )}
      </SettingsSection>

      <SettingsSection title={t(`${TRANSLATION_PATH}.story-settings.text-to-speech.title`)}>
        {textToSpeech && (
          <>
            <SwitchSetting
              label={t(`${TRANSLATION_PATH}.story-settings.text-to-speech.label`)}
              toggle={textToSpeech.toggle}
              onChange={(checked) => {
                const changed = { ...textToSpeech, toggle: checked };
                updateFeatureSettings({ [textToSpeechSlug]: changed });
              }}
            />

            <Spacer token={"m"} align={"vertical"} />

            {textToSpeech.toggle && (
              <div className={styles["text-to-speech-checkbox-container"]}>
                <Checkbox
                  id={checkboxId}
                  checked={textToSpeechConfig["generate-for"] === textToSpeechOptions.all}
                  onChange={(checked) => {
                    const changed = {
                      ...textToSpeech,
                      config: {
                        ...textToSpeechConfig,
                        "generate-for": checked ? textToSpeechOptions.all : textToSpeechOptions.none
                      }
                    };
                    updateFeatureSettings({ [textToSpeechSlug]: changed });
                  }}
                />
                <label className={styles["text-to-speech-checkbox-label"]} htmlFor={checkboxId}>
                  {t(`${TRANSLATION_PATH}.story-settings.text-to-speech.checkbox-label`)}
                </label>
              </div>
            )}
          </>
        )}
      </SettingsSection>
    </SettingsContainer>
  );
};

const mapStateToProps = ({ secretMode: { app, ui } }: PartialAppState) => {
  return {
    isLoading: ui.isLoading,
    loadingFailed: ui.loadingFailed,
    attachment: app.features[attachmentSlug],
    textToSpeech: app.features[textToSpeechSlug],
    entityAsTag: app.features[entityAsTagSlug]
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => ({
  loadStorySettings: (params) => dispatch(loadSettings(params)),
  updateFeatureSettings: (setting) => {
    dispatch(updateFeatureSettings(setting));
    dispatch(enableSaveButton());
  },
  unloadFeatures: (features) => dispatch(removeFeatureSettings(features))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(StorySettings);
