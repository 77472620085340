/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { t } from "i18n";
import { Switch } from "components/switch/switch";
import TextField from "components/text-field/text-field";
import ImageField from "components/image-field/image-field";

import styles from "./card-share.module.css";

const CardShare = ({
  cardId,
  toggleCardShare,
  canShareCard,
  card,
  updateCardShareValues,
  storyHeadline,
  onChooseImage,
  onReplaceImage,
  onDelete,
  openPhotoEditor,
  setSelectedMedia
}) => {
  return (
    <div>
      <div className={styles["card-share-toggle"]}>
        Card sharing
        <Switch
          id={cardId}
          onChange={(value) => toggleCardShare(cardId, value)}
          checked={canShareCard}
          disabled={false}
        />
      </div>
      {canShareCard && (
        <div>
          <TextField
            label={t("story-editor.inspector.headline")}
            placeholder={storyHeadline}
            value={card.metadata["social-share"] && card.metadata["social-share"].title}
            onChange={(text) => updateCardShareValues(cardId, "title", text)}
          />
          <TextField
            label={t("story-editor.story-element.summary")}
            placeholder={t("story-editor.story-element.summary-placeholder")}
            value={card.metadata["social-share"] && card.metadata["social-share"].message}
            onChange={(text) => updateCardShareValues(cardId, "message", text)}
          />
          <ImageField
            setSelectedMedia={setSelectedMedia}
            value={card.metadata && card.metadata["social-share"].image}
            openGallery={(image) => onChooseImage(image[0])}
            onReplace={onReplaceImage}
            onDelete={() => onDelete(cardId)}
            isPhotoEditor={true}
            openPhotoEditor={openPhotoEditor}
          />
        </div>
      )}
    </div>
  );
};

export default CardShare;
