/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import Settings from "./pages/settings/settings";
import Features from "./pages/configure/features/features";
import StoryElements from "./pages/configure/story-elements/story-elements";
import StoryTemplates from "./pages/configure/story-templates/story-templates";
import AccessLevels from "./pages/configure/access-levels/access-levels";
import PromotionalMessages from "./pages/configure/promotional-messages/promotional-messages";
import ContributorRoles from "./pages/configure/contributor-roles/contributor-roles";
import Amp from "./pages/configure/amp-config/amp-config";
import BreakingNewsDefaults from "./pages/configure/breaking-news/breaking-news-defaults";
import WatermarkImage from "./pages/configure/watermark-image/watermark-image";
import PushNotificationChannels from "./pages/configure/push-notification-channels/push-notification-channels";

import { IntegrationsRouter } from "./pages/integrations/router";
import { Router as RolesRouter } from "./pages/users-and-roles/roles/router";
import { Router as UsersRouter } from "./pages/users-and-roles/users/router";
import { Router as SocialSharingRouter } from "./pages/social-share/router";
import { Router as WebhooksRouter } from "./pages/webhooks/router";

import * as routes from "./routes";
import CollectionsConfigureWithRoutes from "./pages/configure/collections/collections-configure";
import FormsSettings from "pages/settings/pages/configure/forms/forms";
import StorySlug from "pages/settings/pages/configure/story-slug/story-slug";

export const Router = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route
          exact
          path={routes.SETTINGS_GENERAL_PATH}
          render={(props) => <Settings {...props} activeMenu="general" title="general_settings" />}
        />
        <Route path="/settings/social-sharing" component={SocialSharingRouter} />
        <Route path="/settings/integrations" component={IntegrationsRouter} />
        <Route path="/settings/users-and-roles/users" component={UsersRouter} />
        <Route path="/settings/users-and-roles/author-users" component={UsersRouter} />
        <Route path="/settings/users-and-roles/roles" component={RolesRouter} />
        <Route path="/settings/webhooks" component={WebhooksRouter} />
        <Route
          exact
          path={routes.CONFIGURE_FEATURES_PATH}
          render={(props) => (
            <Features {...props} showSubmenu="features" activeMenu="configure" title="configure_features" />
          )}
        />
        <Route
          exact
          path={routes.CONFIGURE_FORMS_PATH}
          render={(props) => (
            <FormsSettings {...props} showSubmenu="features" activeMenu="configure" title="forms_settings" />
          )}
        />
        <Route
          exact
          path={routes.CONFIGURE_STORY_TEMPLATES_PATH}
          render={(props) => (
            <StoryTemplates
              {...props}
              showSubmenu="story-templates"
              activeMenu="configure"
              title="configure_story_templates"
            />
          )}
        />
        <Route
          exact
          path={routes.CONFIGURE_ADD_STORY_TEMPLATE_PATH}
          render={(props) => (
            <StoryTemplates
              {...props}
              showAddInspector={true}
              showSubmenu="story-templates"
              activeMenu="configure"
              title="configure_add_story_templates"
            />
          )}
        />
        <Route
          exact
          path={routes.CONFIGURE_EDIT_STORY_TEMPLATE_PATH}
          render={(props) => (
            <StoryTemplates
              {...props}
              showEditInspector={true}
              showSubmenu="story-templates"
              activeMenu="configure"
              title="configure_edit_story_templates"
            />
          )}
        />
        <Route
          exact
          path={routes.CONFIGURE_STORY_ELEMENTS_PATH}
          render={(props) => (
            <StoryElements
              {...props}
              showSubmenu="story-elements"
              activeMenu="configure"
              title="configure_story_elements"
            />
          )}
        />
        <Route
          exact
          path={routes.CONFIGURE_ACCESS_LEVELS_PATH}
          render={(props) => (
            <AccessLevels
              {...props}
              showSubmenu="access-levels"
              activeMenu="configure"
              title="configure_access_level"
            />
          )}
        />
        <Route
          exact
          path={routes.CONFIGURE_ADD_ACCESS_LEVEL_PATH}
          render={(props) => (
            <AccessLevels
              {...props}
              showSubmenu="access-levels"
              activeMenu="configure"
              showInspector={true}
              title="configure_add_access_level"
            />
          )}
        />
        <Route
          exact
          path={routes.CONFIGURE_PROMOTIONAL_MESSAGES_PATH}
          render={(props) => (
            <PromotionalMessages
              {...props}
              showSubmenu="promotional-messages"
              activeMenu="configure"
              title="configure_promotional_messages"
            />
          )}
        />
        <Route
          exact
          path={routes.CONFIGURE_CONTRIBUTOR_ROLES_PATH}
          render={(props) => (
            <ContributorRoles
              {...props}
              showSubmenu="contributor-roles"
              activeMenu="configure"
              title="configure_contributor_roles"
            />
          )}
        />
        <Route
          exact
          path={routes.NEW_CONTRIBUTOR_ROLES_PATH}
          render={(props) => (
            <ContributorRoles
              {...props}
              showSubmenu="contributor-roles"
              activeMenu="configure"
              showInspector={true}
              title="configure_contributor_roles"
            />
          )}
        />
        <Route
          exact
          path={routes.EDIT_CONTRIBUTOR_ROLES_PATH}
          render={(props) => (
            <ContributorRoles
              {...props}
              showSubmenu="contributor-roles"
              activeMenu="configure"
              showInspector={true}
              title="configure_contributor_roles"
            />
          )}
        />
        <Route
          exact
          path={routes.CONFIGURE_AMP_PATH}
          render={(props) => <Amp {...props} showSubmenu="amp" activeMenu="configure" title="configure_amp" />}
        />
        <Route
          exact
          path={routes.CONFIGURE_COLLECTIONS_PATH}
          render={(props) => (
            <CollectionsConfigureWithRoutes
              {...props}
              showSubmenu="collections"
              activeMenu="configure"
              title="configure_collections"
            />
          )}
        />

        <Route exact={true} path={routes.CONFIGURE_BREAKING_NEWS_PATH} component={BreakingNewsDefaults} />

        <Route
          exact
          path={routes.BREAKING_NEWS_DEFAULTS_IMAGE_GALLERY}
          render={(props) => <BreakingNewsDefaults {...props} showMediaGallery={true} />}
        />

        <Route
          exact
          path={routes.CONFIGURE_WATERMARK_IMAGES_PATH}
          render={(props) => (
            <WatermarkImage {...props} showSubmenu="watermark-images" activeMenu="configure" title="watermark_images" />
          )}
        />
        <Route
          exact
          path={routes.CONFIGURE_WATERMARK_IMAGES_GALLERY_PATH}
          render={(props) => (
            <WatermarkImage
              {...props}
              showSubmenu="watermark-images"
              activeMenu="configure"
              title="watermark_images"
              showMediaGallery={true}
            />
          )}
        />

        <Route
          exact
          path={routes.CONFIGURE_PUSH_NOTIFICATION_CHANNELS_PATH}
          render={(props) => (
            <PushNotificationChannels
              {...props}
              showSubmenu="push-notification-channels"
              activeMenu="configure"
              title="push_notification_channels"
            />
          )}
        />
        <Route
          exact
          path={routes.NEW_PUSH_NOTIFICATION_CHANNELS_PATH}
          render={(props) => (
            <PushNotificationChannels
              {...props}
              showSubmenu="push-notification-channels"
              activeMenu="configure"
              title="add_push_notification_channels"
            />
          )}
        />
        <Route
          exact
          path={routes.CONFIGURE_STORY_SLUG_PATH}
          render={(props) => <StorySlug {...props} activeMenu="configure" title="configure_story_slug" />}
        />
      </Switch>
    </React.Fragment>
  );
};
