/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

/*
NP = an object with Node + Position.

Functions in this file should return:
  undefined
  a node position
  an object with multiple node positions
*/

import { EditorState, Transaction } from "prosemirror-state";
import { Node, Schema, NodeType, Slice } from "prosemirror-model";
import { Card, StoryElement, CompositeStoryElement, ChildStoryElement } from "api/story";
import { isTextContentPasteableInStoryElement } from "../prosemirror/utils";

export type NodePosition = {
  node: Node<Schema>;
  start?: number;
  pos: number;
} | null;

export function findLastCardNP(editorState: EditorState): NodePosition {
  let found: NodePosition = null;

  editorState.doc.descendants((node: Node<Schema>, pos: number) => {
    if (node === editorState.doc.lastChild) {
      found = { node, pos };
      return false;
    }

    return true;
  });

  return found;
}

export function findCardNP(editorState: EditorState<Schema>, card: Card): NodePosition {
  let found: NodePosition = null;

  editorState.doc.descendants((node: Node<Schema>, pos: number) => {
    if (node.type.name === "card" && node.attrs.id === card["content-id"]) {
      found = { node, pos };
      return false;
    }

    return true;
  });

  return found;
}

export function findElementNP(
  editorState: EditorState<Schema> | Transaction<Schema>,
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement
): NodePosition {
  let found: NodePosition = null;
  if (!storyElement) {
    return found;
  }

  editorState.doc.descendants((node: Node<Schema>, pos: number) => {
    if (node.type.spec.group === "story_element" && node.attrs.id === storyElement.id) {
      found = { node, pos };
      return false;
    }

    return true;
  });

  return found;
}

export function findElementWithClientIdNP(
  editorState: EditorState<Schema>,
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement
): NodePosition {
  let found: NodePosition = null;

  editorState.doc.descendants((node: Node<Schema>, pos: number) => {
    if (node.type.spec.group === "story_element" && node.attrs["client-id"] === storyElement["client-id"]) {
      found = { node, pos };
      return false;
    }

    return true;
  });

  return found;
}

export function findParentNodeOfType(editorState: EditorState<Schema>, type: string): NodePosition {
  const { $from } = editorState.selection;
  for (let i = $from.depth; i > 0; i--) {
    const node = $from.node(i);
    if (node.type.name === type) {
      return {
        pos: i > 0 ? $from.before(i) : 0,
        node
      };
    }
  }
  return null;
}

export const findParentNodeClosestToPos = (editorState: EditorState<Schema>, type: string): NodePosition => {
  const { $from } = editorState.selection;
  for (let i = $from.depth; i > 0; i--) {
    const node = $from.node(i);
    if (node.type.name === type) {
      return {
        pos: i > 0 ? $from.before(i) : 0,
        start: $from.start(i),
        node
      };
    }
  }
  return null;
};

export const findParentNodeClosestToPosByAttrs = (editorState: EditorState<Schema>, type: string): NodePosition => {
  const { $from } = editorState.selection;
  for (let i = $from.depth; i > 0; i--) {
    const node = $from.node(i);
    if (node.attrs.type && node.attrs.type === type) {
      return {
        pos: i > 0 ? $from.before(i) : 0,
        start: $from.start(i),
        node
      };
    }
  }
  return null;
};

// Change this to node type
export const findNodeClosestToPos = (editorState: EditorState<Schema> | Transaction<Schema<any, any>>): NodeType => {
  const { $from } = editorState.selection;
  const node = $from.node();
  return node.type;
};

export const findChildrenOfNode = (node: Node, type: string): Node[] => {
  let found: Node[] = [];
  node.descendants((node: Node, pos: number, parent: Node) => {
    if (node.type.name === type) {
      found.push(node);
    }
  });
  return found;
};

export const findActualNodeClosestToPos = (editorState: EditorState<Schema> | Transaction<Schema<any, any>>): Node => {
  const { $from } = editorState.selection;
  const node = $from.node();
  return node;
};

export function findAllMatches(
  regex: RegExp,
  sourceString: string,
  aggregator: Array<RegExpExecArray> = []
): Array<RegExpExecArray> {
  const arr = regex.exec(sourceString);

  if (arr === null) return aggregator;

  const newString = sourceString.slice(arr.index + arr[0].length);
  const index =
    aggregator.length && aggregator[aggregator.length - 1]
      ? aggregator[aggregator.length - 1].index + aggregator[aggregator.length - 1][0].length + arr.index
      : arr.index;
  return findAllMatches(regex, newString, aggregator.concat([{ ...arr, index }]));
}

const findParagraphNodes = (node: Node, predicate: (node: Node) => boolean): NodePosition[] => {
  if (!node) {
    throw new Error('Invalid "node" parameter');
  }

  const result: NodePosition[] = [];
  node.descendants((child: Node, pos: number) => {
    if (predicate(child)) {
      result.push({ node: child, pos });
      return false;
    }
    return;
  });
  return result;
};

// https://github.com/atlassian/prosemirror-utils/blob/master/src/node.js
const flatten = (node: Node, descend: boolean = true): NodePosition[] => {
  if (!node) {
    throw new Error('Invalid "node" parameter');
  }
  const result: NodePosition[] = [];
  node.descendants((child: Node, pos: number) => {
    result.push({ node: child, pos });
    if (!descend) {
      return false;
    }
    return;
  });
  return result;
};

const findChildren = (node: Node, predicate: (node: Node) => boolean, descend: boolean): NodePosition[] => {
  if (!node) {
    throw new Error('Invalid "node" parameter');
  } else if (!predicate) {
    throw new Error('Invalid "predicate" parameter');
  }
  return flatten(node, descend).filter((child: NodePosition) => child && predicate(child.node));
};

const findTextNodes = (node: Node, descend: boolean): NodePosition[] => {
  return findChildren(node, (child: Node) => child.isText, descend);
};

export const findTextNodesInSlice = (slice: Slice): NodePosition[] => {
  const result: NodePosition[] = [];
  if (slice.content) {
    slice.content.forEach((node) => {
      result.push(...findTextNodes(node, true));
    });
  }
  return result.filter((nodeNP) => nodeNP && nodeNP.node);
};

export const findParagraphNodesInSlice = (slice: Slice): NodePosition[] => {
  const result: NodePosition[] = [];
  if (slice.content) {
    slice.content.forEach((node) => {
      result.push(...findParagraphNodes(node, (child: Node) => isTextContentPasteableInStoryElement(child)));
    });
  }
  return result.filter((nodeNP) => nodeNP && nodeNP.node);
};

export const findFirstEditableCursorPosition = (nodePosition: NodePosition): number | null => {
  if (nodePosition) {
    const node: Node = nodePosition.node;
    return nodePosition.pos + node.nodeSize - node.content.size;
  }
  return null;
};
