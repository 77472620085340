/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import styles from "./no-content-card.module.css";

const NoContentMessage: React.SFC = () => <div className={styles["no-content-message"]}>No Content</div>;

const NoContentCard: React.SFC = ({ children = <NoContentMessage /> }) => (
  <div className={styles["has-no-content"]} data-test-id="no-content-card">
    {children}
  </div>
);

export default NoContentCard;
