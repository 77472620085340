/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";

import BaseHeader from "components/header/header";
import Button from "components/button/button";

import { PartialAppState } from "./state";
import { ThunkDispatch } from "redux-thunk";
import { saveAssociatedStoryDefaults } from "./async-action-creators";
import { connect } from "react-redux";

interface StateProps {
  isAssociatedStoryDefaultsModified: boolean;
  saving: boolean;
}

interface DispatchProps {
  saveAssociatedStoryDefaults: () => void;
}

type HeaderProps = StateProps & DispatchProps;

const Header: React.SFC<HeaderProps> = ({ isAssociatedStoryDefaultsModified, saveAssociatedStoryDefaults, saving }) => {
  return (
    <BaseHeader>
      {isAssociatedStoryDefaultsModified && (
        <Button variant="success" type="primary" onClick={saveAssociatedStoryDefaults} disabled={saving}>
          {t("settings.breaking-news-defaults.save-button-label")}
        </Button>
      )}
    </BaseHeader>
  );
};

const mapStateToProps = (state: PartialAppState) => ({
  isAssociatedStoryDefaultsModified: state.breakingNewsDefaults.ui.isAssociatedStoryDefaultsModified,
  saving: state.breakingNewsDefaults.ui.saving
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  saveAssociatedStoryDefaults: () => dispatch(saveAssociatedStoryDefaults())
});

export { Header };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
