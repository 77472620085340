/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ThunkDispatch } from "redux-thunk";
import { ParsedQuery } from "query-string";
import { t } from "i18n";

import { getAnalytics as getAnalyticsAPI, Filters } from "api/analytics";
import {
  analyticsSuccessAction,
  setFilterOptions,
  setCustomRange,
  authorStoriesSuccessAction
} from "./action-creators";
import { getDateRange, getDateRangeFromQueryParam } from "./utils";
import { NOTIFICATION_ERROR } from "containers/page/actions";
import { AnalyticsConfig } from "api/route-data/analytics-route-data";
import { PartialAppState } from "pages/analytics/state";
import { AuthorId } from "api/primitive-types";
import { DIMENSION } from "./constants";

const defaultFilterParams: Filters = {
  tags: [],
  "author-ids": [],
  sections: [],
  "assignee-ids": [],
  "story-templates": [],
  devices: [],
  "referrer-ids": []
};

const optionalParams = { "published-stories": false, "top-count": 50 };

export const getAnalytics = (
  config: AnalyticsConfig,
  {
    dimension,
    metrics,
    period,
    customRange
  }: { dimension: string; metrics: string; period: string; customRange?: ParsedQuery },
  filterParams = defaultFilterParams
) => async (dispatch: ThunkDispatch<any, any, any>) => {
  const currentPeriod = config && config.periods.find((item) => item.name === period);
  if (currentPeriod) {
    dispatch(setFilterOptions({ dateRange: currentPeriod, metrics, dimension }));
  }

  let dateRange;
  try {
    if (period === "custom" && customRange) {
      dateRange = getDateRangeFromQueryParam(customRange);
      dispatch(setCustomRange(dateRange));
    } else {
      dateRange = getDateRange(period);
    }

    const storyAnalyticsResponse = await getAnalyticsAPI(
      dimension,
      metrics,
      { ...filterParams, ...dateRange },
      optionalParams
    );
    dispatch(analyticsSuccessAction(storyAnalyticsResponse));
  } catch (e) {
    dispatch({ type: NOTIFICATION_ERROR, payload: { message: t("analytics.messages.fetch_error") } });
  }
};

export const getAuthorStories = (authorId: AuthorId) => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const { metrics, dateRange: period } = getState().analytics.app.currentFilter;
  const filterParams = { ...defaultFilterParams, "author-ids": [authorId] };
  let dateRange;
  if (period.name === "custom") {
    dateRange = getState().analytics.app.customRange;
  } else {
    dateRange = getDateRange(period.name);
  }

  try {
    const authorStories = await getAnalyticsAPI(
      DIMENSION.STORY,
      metrics,
      { ...filterParams, ...dateRange },
      { ...optionalParams, "top-count": 10 }
    );
    dispatch(authorStoriesSuccessAction(authorStories.top));
  } catch (e) {
    dispatch({ type: NOTIFICATION_ERROR, payload: { message: t("analytics.messages.fetch_error") } });
  }
};
