/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { ToolTip as EmTooltip } from "@quintype/em/components/tooltip";
import { TooltipProps } from "react-tooltip";

interface Props {
  value: string;
  id: string;
  place?: "top" | "right" | "bottom" | "left";
  effect?: "float" | "solid";
  offset?: Offset;
  classname?: string;
  /* default type is dark */
  type?: "dark" | "success" | "warning" | "error" | "info" | "light";
}

interface Offset extends TooltipProps {
  top?: number;
  right?: number;
  left?: number;
  bottom?: number;
}

const ToolTip = (props: Props) => <EmTooltip {...props} />;

export default ToolTip;
export { ToolTip };
