/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Share = ({ width = 24, height = 24, color = "#5f6978" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 24 24">
    <path
      d="M16.54 14.24a3.37 3.37 0 0 1 2.43 1A3.39 3.39 0 0 1 19 20a3.37 3.37 0 0 1-2.43 1 3.33 3.33 0 0 1-2.42-1 3.24 3.24 0 0 1-1-2.4 3.48 3.48 0 0 1 .07-.73l-3.67-2.24a3.32 3.32 0 0 1-2.14.73 3.3 3.3 0 0 1-2.42-1A3.36 3.36 0 0 1 5 9.6a3.31 3.31 0 0 1 2.42-1 3.33 3.33 0 0 1 2.14.74l3.63-2.24a3.51 3.51 0 0 1-.07-.74 3.23 3.23 0 0 1 1-2.39 3.33 3.33 0 0 1 2.42-1A3.37 3.37 0 0 1 19 4a3.26 3.26 0 0 1 1 2.39 3.27 3.27 0 0 1-1 2.4 3.48 3.48 0 0 1-4.6.21l-3.63 2.25a4.09 4.09 0 0 1 0 1.48L14.4 15a3.35 3.35 0 0 1 2.14-.76z"
      data-name="Layer 2"
    />
  </svg>
);

export default Share;
