/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { validate } from "utils/validation.utils";
import { AmpConfig } from "api/amp-config";
import { t as translator } from "i18n";

const numericality = (t: typeof translator) => {
  return {
    onlyInteger: true,
    greaterThan: 0,
    lessThan: 999999999,
    message: t("errors.validations.number-and-length")
  };
};

const constraints = (t: typeof translator) => {
  return {
    "doubleclick.body-ad.width": { numericality: numericality(t) },
    "doubleclick.body-ad.height": { numericality: numericality(t) },
    "doubleclick.top-ad.width": { numericality: numericality(t) },
    "doubleclick.top-ad.height": { numericality: numericality(t) },
    "doubleclick.bottom-ad.width": { numericality: numericality(t) },
    "doubleclick.bottom-ad.height": { numericality: numericality(t) },
    "comscore-tracking-vars.c1": { numericality: numericality(t) },
    "comscore-tracking-vars.c2": { numericality: numericality(t) }
  };
};

export function validateAmpConfig(ampConfig: AmpConfig) {
  return validate(ampConfig, constraints(translator));
}
