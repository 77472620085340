/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import formatDate from "date-fns/format";

import styles from "./active-filters-list.module.css";
import { FilterSet } from "store/consumer/consumer";
import { DateFilter } from "components/date-filter/date-filter";
import Close from "components/icons/close";
import { t } from "i18n";

const getDateFilterDisplay = (dateFilter: DateFilter) => {
  switch (dateFilter.type) {
    case "today": {
      return t("consumers.date_filter_types.today");
    }
    case "this-week": {
      return t("consumers.date_filter_types.this_week");
    }
    case "this-month": {
      return t("consumers.date_filter_types.this_month");
    }
    case "custom": {
      const { from, to } = dateFilter;
      if (from && to) {
        return `${formatDate(from, "dd/MM/yyyy")} - ${formatDate(to, "dd/MM/yyyy")}`;
      }
      return "";
    }
    default:
      return "";
  }
};

const generateActiveFilters = (filters: FilterSet) => {
  const filterLabels = {
    "created-at": t("consumers.date_filter_types.created_at")
  };
  const activeFilters: any = [];
  filters["created-at"] &&
    filters["created-at"].type &&
    activeFilters.push(`${filterLabels["created-at"]} : ${getDateFilterDisplay(filters["created-at"])}`);
  return activeFilters;
};

interface ActiveFiltersListProps {
  filterSet: FilterSet;
  clearFilters: () => void;
}

const ActiveFiltersList: React.FC<ActiveFiltersListProps> = ({ filterSet, clearFilters }) => {
  if (generateActiveFilters(filterSet).length) {
    return (
      <div
        className={styles["consumers-selected-filters-container"]}
        data-test-id="consumers-selected-filters-container">
        <div>
          {generateActiveFilters(filterSet).map((filter, index) => (
            <span className={styles["consumers-filter-label"]} data-test-id="consumers-filter-label" key={index}>
              {filter}
              <div className={styles["clear-selected-filters"]} onClick={() => clearFilters()}>
                <Close width={"12"} height={"12"} />
              </div>
            </span>
          ))}
        </div>
      </div>
    );
  } else {
    return null;
  }
};
export default ActiveFiltersList;
