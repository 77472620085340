/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Plugin, Transaction, EditorState } from "prosemirror-state";

export default () => {
  return new Plugin({
    filterTransaction: (transaction: Transaction, editorState: EditorState) => {
      if (transaction.before.childCount > transaction.doc.content.childCount) {
        return transaction.getMeta("userAction") === "cardDelete";
      }
      return true;
    }
  });
};
