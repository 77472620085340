/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import MediaList from "../media-list/media-list";
import Media from "components/icons/media";
import NoContentCard from "components/no-content-card/no-content-card";
import { t } from "i18n";
import InfiniteScrollList from "components/infinite-scroll-list/infinite-scroll-list";
import Spinner from "components/spinner/spinner";
import styles from "./external-media.module.css";
import { ExistingOpts, Media as MediaType } from "pages/media-library/state";

interface ExternalMediaProps {
  showSearchMediaText: boolean;
  searchTerm: string;
  mediaItems: Array<MediaType>;
  editMedia: (media: MediaType) => void;
  loadNextData: (opts: ExistingOpts) => void;
  loadDataOptions?: ExistingOpts;
  totalCount: number;
  scrollableTarget?: string;
  showEditImage?: boolean;
}

const ExternalMedia: React.FC<ExternalMediaProps> = ({
  mediaItems,
  showSearchMediaText,
  showEditImage,
  totalCount,
  searchTerm,
  scrollableTarget,
  loadDataOptions = {},
  editMedia,
  loadNextData
}) => {
  return (
    <React.Fragment>
      {showSearchMediaText || mediaItems.length <= 0 ? (
        <NoContentCard>
          <Media />
          <div className={styles["no-content-message"]} data-test-id="no-content-message">
            {showSearchMediaText ? (
              <span data-test-id="ext-media-search-above">{t("mediaLibrary.search_for_media_above")}</span>
            ) : (
              <span data-test-id="ext-media-no-results">{t("mediaLibrary.no_results_found")}</span>
            )}
          </div>
        </NoContentCard>
      ) : (
        <InfiniteScrollList
          dataLength={mediaItems.length}
          next={() => {
            loadNextData({ ...loadDataOptions, searchTerm, offset: mediaItems.length, limit: 20 });
          }}
          hasMore={totalCount - mediaItems.length > 0}
          loader={<Spinner message={t("mediaLibrary.loading")} />}
          scrollableTarget={scrollableTarget}
          endMessage={
            <NoContentCard>
              <Media />
              <div className={styles["no-content-message"]}>{t("mediaLibrary.no_more_results_found")}</div>
            </NoContentCard>
          }>
          <div className={styles["media-gallery"]} data-test-id="media-gallery">
            <MediaList mediaItems={mediaItems} editMedia={editMedia} showEditImage={showEditImage} />
          </div>
        </InfiniteScrollList>
      )}
    </React.Fragment>
  );
};

export default ExternalMedia;
