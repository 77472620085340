/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";
import AdvancedSettings from "./advanced-settings";
import { ADVANCED_SETTINGS_PATH } from "./routes";

export const Router = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route
          exact
          path={ADVANCED_SETTINGS_PATH}
          render={(props) => <AdvancedSettings {...props} title="advanced-settings" />}
        />
      </Switch>
    </React.Fragment>
  );
};
