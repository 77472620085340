/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import produce from "immer";
import { State, INITIAL_PUSH_NOTIFICATION_STATE, NEW_PUSH_NOTIFICATION } from "./state";
import actions, { ActionTypes } from "./actions";
import { isClientValidationError } from "utils/validation.utils";
import { UnsavedPushNotification } from "api/push-notification";
import { PushNotificationServerValidationError, PushNotificationDateValidationError } from "./errors";
import { getStringTabValue } from "./tab-list";

function reducer(state: State = INITIAL_PUSH_NOTIFICATION_STATE, action: ActionTypes) {
  return produce(state, (draft) => {
    switch (action.type) {
      case actions.LIST_PUSH_NOTIFICATIONS:
        draft.ui.main = { loading: true, error: null };
        break;

      case actions.LIST_PUSH_NOTIFICATIONS_SUCCESS:
        draft.ui.main = { loading: false, error: null };
        draft.pushNotifications = action.payload["push-notifications"];
        draft.totalCount = action.payload.aggregations.status[getStringTabValue(draft.ui.currentTab)];
        draft.aggregations = action.payload.aggregations;
        break;

      case actions.LIST_PUSH_NOTIFICATIONS_FAILURE:
        draft.ui.main = { loading: false, error: action.payload.error };
        break;

      case actions.INIT_PUSH_NOTIFICATION:
        draft.ui.save = { loading: false, error: null };
        draft.ui.details = { loading: false, error: null };
        draft.app.currentPushNotification = NEW_PUSH_NOTIFICATION;
        break;

      case actions.SAVE_PUSH_NOTIFICATION:
        draft.ui.save = { loading: true, error: null };
        break;

      case actions.SAVE_PUSH_NOTIFICATION_SUCCESS:
        draft = INITIAL_PUSH_NOTIFICATION_STATE;
        break;

      case actions.SAVE_PUSH_NOTIFICATION_FAILURE:
        if (
          isClientValidationError<UnsavedPushNotification>(action.payload) ||
          action.payload instanceof PushNotificationServerValidationError ||
          action.payload instanceof PushNotificationDateValidationError
        ) {
          draft.ui.save = { loading: false, error: null };
          draft.ui.details = { loading: false, error: action.payload };
        } else {
          draft.ui.save = { loading: false, error: action.payload };
        }
        break;

      case actions.LOAD_PUSH_NOTIFICATION:
        draft.ui.save = { loading: false, error: null };
        draft.ui.details = { loading: false, error: null };
        draft.app.currentPushNotification = action.payload.pushNotification;
        break;

      case actions.UPDATE_CURRENT_PUSH_NOTIFICATION:
        const currentPushNotification = action.payload;
        draft.app.currentPushNotification = currentPushNotification;
        break;

      case actions.CANCEL_PUBLISH:
        draft.ui.isInspectorActive = false;
        break;

      case actions.MAYBE_PUBLISH:
        draft.ui.isInspectorActive = true;
        break;

      case actions.UPDATE_PUBLISH_LATER_STATE:
        draft.ui.isPublishLater = action.payload;
        break;

      case actions.LOAD_PUSH_NOTIFICATION_FAILURE:
        draft.ui.save = { loading: false, error: action.payload };
        break;

      case actions.UPDATE_TAB:
        draft.ui.currentTab = action.payload;
        break;

      case actions.UPDATE_SEARCH_TERM:
        draft.ui.searchTerm = action.payload;
        break;

      default:
        break;
    }
  });
}

export default reducer;
