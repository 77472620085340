/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const LeftArrow = ({ width = "24", height = "24", color = "#5f6978" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height} fill={color}>
    <path fill="none" d="M.03.13h24v24h-24z" id="Layer_1" data-name="Layer 1" />
    <path
      d="M12.82 20.81a.58.58 0 0 1-.34.11.42.42 0 0 1-.3-.11l-8-8a.36.36 0 0 1-.15-.3.37.37 0 0 1 .15-.31l8-8a.39.39 0 0 1 .3-.11.51.51 0 0 1 .34.11l.27.3a.45.45 0 0 1 .11.31.6.6 0 0 1-.11.34L6.3 11.81h14.26a.45.45 0 0 1 .33.14.41.41 0 0 1 .13.32v.38a.44.44 0 0 1-.13.32.44.44 0 0 1-.33.13H6.3l6.79 6.76a.58.58 0 0 1 .11.34.41.41 0 0 1-.11.3z"
      id="Layer_2"
      data-name="Layer 2"
    />
  </svg>
);

export default LeftArrow;
