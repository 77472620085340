/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState } from "pages/story-editor/state";
import { t } from "i18n";
import TextArea from "components/text-area/text-area";
import { updateEditorNotes } from "../../action-creators";
import { get } from "lodash";

interface StateProps {
  editorNotes: string;
}

interface DispatchProps {
  onChange: (value: string) => void;
}

type Props = StateProps & DispatchProps;

const EditorNotes: React.SFC<Props> = (props: Props) => {
  return (
    <TextArea
      label={t("story-editor.inspector.note")}
      value={props.editorNotes}
      onChange={(value: string) => props.onChange(value)}
    />
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    editorNotes: get(state, ["storyEditor", "story", "private-fields", "editor-notes"], "")
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
  return {
    onChange: (value: string) => {
      return dispatch(updateEditorNotes(value));
    }
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditorNotes);

export { EditorNotes };
