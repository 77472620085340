/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { get } from "lodash";
import PasswordField from "components/password-field/password-field";
import { isClientValidationError } from "utils/validation.utils";
import "./reset-password-fields.css";

interface Props {
  onChangeResetPassword: (key: string, value: string) => void;
  error: Error;
}

export interface ValidatePasswords {
  newPassword: string;
  confirmPassword: string;
}

const ResetPasswordFields: React.SFC<Props> = ({ onChangeResetPassword, error }) => {
  return (
    <React.Fragment>
      <PasswordField
        label={t("loginPage.new_password")}
        onChange={(value) => onChangeResetPassword("newPassword", value)}
        classname="reset-password-fields-password-field"
        errorMessage={
          isClientValidationError<ValidatePasswords>(error) ? get(error, ["errors", "newPassword", "0"]) : undefined
        }
      />
      <PasswordField
        label={t("loginPage.re-enter_password")}
        onChange={(value) => onChangeResetPassword("confirmPassword", value)}
        classname="reset-password-fields-password-field"
        errorMessage={
          isClientValidationError<ValidatePasswords>(error) ? get(error, ["errors", "confirmPassword", "0"]) : undefined
        }
      />
    </React.Fragment>
  );
};

export default ResetPasswordFields;
