/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { compose, AnyAction } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState } from "./state";
import { t } from "i18n";

import ContentHeader from "../../../components/content-header/content-header";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import Loader from "./loader";
import { LoaderState } from "behaviors/loader/state";
import Inspector from "./inspector";
import DeleteConfirmationModal from "components/confirmation-modal/confirmation-modal";

import WrapPage from "containers/page/page";
import { CONFIGURE_PUSH_NOTIFICATION_CHANNELS_PATH, NEW_PUSH_NOTIFICATION_CHANNELS_PATH } from "pages/settings/routes";
import { PushNotificationChannel, AnyChannel } from "api/push-notification-channels";
import {
  getPushNotificationChannels,
  initPushNotificationChannel,
  maybeDeletePushNotificationChannel,
  deletePushNotificationChannel,
  cancelPushNotificationDelete
} from "store/push-notification-channels";
import { navigate } from "utils/routes.utils";

import styles from "./push-notification-channels.module.css";
import classnames from "classnames/bind";

import { Button } from "@quintype/em/components/button";
import { Trash } from "@quintype/em/icons/trash";

const cx = classnames.bind(styles);

interface StateProps {
  channels: PushNotificationChannel[];
  loaderState: LoaderState;
  showInspector: boolean;
  currentChannel: AnyChannel;
  showDeleteConfirmationModal: boolean;
  channelForDeletion: PushNotificationChannel;
}

interface DispatchProps {
  loadPushNotificationChannels: () => void;
  addNewPushNotificationChannels: () => void;
  initPushNotificationChannel: () => void;
  maybeDeletePushNotificationChannel: (channel: PushNotificationChannel) => void;
  deletePushNotificationChannel: (channel: PushNotificationChannel) => void;
  cancelPushNotificationDelete: () => void;
}

type Props = RouteComponentProps<any> & StateProps & DispatchProps;

export class PushNotificationChannelsComponent extends React.Component<Props> {
  componentDidMount() {
    this.onEnter();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.match.url !== prevProps.match.url) {
      this.onEnter();
    }
  }

  onEnter() {
    switch (this.props.match.path) {
      case CONFIGURE_PUSH_NOTIFICATION_CHANNELS_PATH:
        this.props.loadPushNotificationChannels();
        break;

      case NEW_PUSH_NOTIFICATION_CHANNELS_PATH:
        this.props.initPushNotificationChannel();
        break;

      default:
        break;
    }
  }

  render() {
    const {
      channels,
      showInspector,
      currentChannel,
      maybeDeletePushNotificationChannel,
      deletePushNotificationChannel,
      cancelPushNotificationDelete,
      showDeleteConfirmationModal,
      channelForDeletion
    } = this.props;
    return (
      <LoaderWrapper className={styles["loader-wrapper"]} component={Loader} loader={this.props.loaderState}>
        <main className={styles["page-container"]}>
          <ContentHeader
            title={t("settings.push_notification_channels.title")}
            description={t("settings.push_notification_channels.description")}
            actionButtonLabel={t("settings.push_notification_channels.add_channels_button")}
            handleActionButtonClick={() => this.props.addNewPushNotificationChannels()}
          />
          <div
            className={styles["push-notification-channels-container"]}
            data-test-id="push-notification-channels-container">
            <div
              className={cx("push-notification-channels-row", "push-notification-channels-header")}
              data-test-id="push-notification-channels-header">
              <span>{t("settings.push_notification_channels.name_header")}</span>
              <span>{t("settings.push_notification_channels.type_header")}</span>
            </div>
            {channels &&
              channels.length > 0 &&
              channels.map((channel: PushNotificationChannel) => (
                <div
                  className={styles["push-notification-channels-row"]}
                  data-test-id="push-notification-channels-row"
                  key={`push-notification-channels-${channel.id}`}>
                  <span>{channel.name}</span>
                  <span>{channel["resource-type"]}</span>
                  <Button
                    classname={styles["push-notification-channels-trash-btn"]}
                    type="transparent"
                    onClick={() => maybeDeletePushNotificationChannel(channel)}>
                    <Trash />
                  </Button>
                </div>
              ))}
          </div>
          <Inspector isActive={showInspector} currentChannel={currentChannel} />
        </main>
        <DeleteConfirmationModal
          showConfirmationModal={showDeleteConfirmationModal}
          headerText={t("settings.push_notification_channels.delete_modal.title")}
          text={t("settings.push_notification_channels.delete_modal.info")}
          confirmButtonLabel={t("settings.push_notification_channels.delete_modal.confirm_button_label")}
          cancelButtonLabel={t("settings.push_notification_channels.delete_modal.cancel_button_label")}
          onConfirm={() => deletePushNotificationChannel(channelForDeletion)}
          onCancel={() => cancelPushNotificationDelete()}
          onClose={() => cancelPushNotificationDelete()}
        />
      </LoaderWrapper>
    );
  }
}

function mapDispatchToProps(dispatch: ThunkDispatch<PartialAppState, any, AnyAction>) {
  return {
    loadPushNotificationChannels: () => dispatch(getPushNotificationChannels()).catch(() => null),
    addNewPushNotificationChannels: () => {
      dispatch(navigate(NEW_PUSH_NOTIFICATION_CHANNELS_PATH));
    },
    initPushNotificationChannel: () => dispatch(initPushNotificationChannel()),
    maybeDeletePushNotificationChannel: (channel: PushNotificationChannel) =>
      dispatch(maybeDeletePushNotificationChannel({ channel })),
    deletePushNotificationChannel: (channel: PushNotificationChannel) =>
      dispatch(deletePushNotificationChannel(channel)),
    cancelPushNotificationDelete: () => dispatch(cancelPushNotificationDelete())
  };
}

function mapStateToProps(state: PartialAppState) {
  return {
    channels: (state.pushNotificationChannels && state.pushNotificationChannels.data) || [],
    loaderState: state.pushNotificationChannels.ui.main,
    showInspector: state.pushNotificationChannels.ui.inspector,
    currentChannel: state.pushNotificationChannels.selectedChannel,
    channelForDeletion: state.pushNotificationChannels.channelForDeletion,
    showDeleteConfirmationModal: state.pushNotificationChannels.ui.showDeleteConfirmationModal
  };
}

export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  WrapPage({ isStoryPage: false })
)(PushNotificationChannelsComponent);
