/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { compose, AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Inspector from "components/inspector/inspector";
import Select from "components/select/select";
import AsyncSelect from "components/select/async";
import { RulesSortBy, ExpandedRulesContentType } from "api/story-collection";
import { listCollectionItems, CollectionWrapper } from "api/collection-items";
import TextField from "components/text-field/text-field";
import Spinner from "components/spinner/spinner";
import CategoryTitle from "components/category-title/category-title";
import { pick, get, set, cloneDeep } from "lodash";
import debounce from "p-debounce";
import { selectMenuGroups, selectSectionOpts, selectSections } from "store/domain/selectors";
import {
  PartialAppState,
  InspectorStateType,
  ValidationError,
  DataError,
  CurrentDomain,
  MenuGroup,
  MenuGroups,
  Section,
  Sections,
  Collection,
  Collections,
  ConfirmationModalType,
  inspectorCancelSave,
  onDomainFormChange,
  validateAndShowConfirmationModal,
  LanguageConfig
} from "store/domain/domain";

export const TRANSLATION_PATH = "secret-mode.domain-manager.inspector";

interface StateProps {
  inspectorState: InspectorStateType | null;
  isActionButtonEnabled: boolean;
  isInspectorLoading: boolean;
  currentDomain: CurrentDomain;
  allSections: Sections;
  allMenuGroups: MenuGroups;
  allDomainsCollections: Collections;
  menuGroupOpts: Array<MenuGroup>;
  sectionOpts: Array<Section>;
  validationErrors: ValidationError;
  dataErrors: DataError;
}

interface DispatchProps {
  cancelSave: () => void;
  onChange: (domain: CurrentDomain) => void;
  validateAndShowModal: (domain: CurrentDomain, modalType: ConfirmationModalType) => void;
}

type DomainInspectorProps = StateProps & DispatchProps;

const searchCollections = debounce(async function(term: string): Promise<Collection[]> {
  const query = {
    "content-type": ExpandedRulesContentType.Collection,
    "exclude-template": "author",
    sort: RulesSortBy.CreatedAt,
    limit: 10,
    offset: 0,
    q: term
  };
  const results = await listCollectionItems(query);
  return results.items.map((item: CollectionWrapper) => pick(item.collection, ["id", "name"]));
}, 1000);

const directionMapping = {
  ltr: "Left-to-Right",
  rtl: "Right-to-Left"
};

const directions = [
  { value: "ltr", name: directionMapping["ltr"] },
  { value: "rtl", name: directionMapping["rtl"] }
];

export function DomainInspector({
  inspectorState,
  isInspectorLoading,
  isActionButtonEnabled,
  currentDomain,
  allSections,
  allMenuGroups,
  allDomainsCollections,
  sectionOpts,
  menuGroupOpts,
  validationErrors,
  dataErrors,
  onChange,
  cancelSave,
  validateAndShowModal
}: DomainInspectorProps) {
  const isActionButtonDisabled = !(inspectorState === InspectorStateType.Create || isActionButtonEnabled);
  const [title, actionButtonLabel] =
    inspectorState === InspectorStateType.Create
      ? [t(`${TRANSLATION_PATH}.add-title`), t(`${TRANSLATION_PATH}.add-button-label`)]
      : [t(`${TRANSLATION_PATH}.update-title`), t(`${TRANSLATION_PATH}.update-button-label`)];
  const modalType =
    inspectorState === InspectorStateType.Create ? ConfirmationModalType.Create : ConfirmationModalType.Edit;

  const sectionIds = currentDomain["section-ids"] || [];
  const menuGroupIds = currentDomain["menu-groups"] || [];
  const name = currentDomain.name || "";
  const homeCollectionId = currentDomain["home-collection-id"];
  const hostUrl = currentDomain["host-url"] || "";
  const betaHostUrl = currentDomain["beta-host-url"] || "";
  const language: LanguageConfig = get(currentDomain, ["config", "language"], {
    name: "",
    "iso-code": "",
    "ietf-code": "",
    direction: ""
  });

  const sections = sectionIds.map((sectionId: number) => allSections[sectionId]);
  const menuGroups = menuGroupIds.map((menuGroupId: number) => allMenuGroups[menuGroupId]);
  const homeCollection = homeCollectionId ? allDomainsCollections[homeCollectionId] : null;

  return (
    <Inspector
      title={title}
      onClose={cancelSave}
      isActive={!!inspectorState}
      actionButtonLabel={actionButtonLabel}
      isActionButtonDisabled={isActionButtonDisabled}
      onActionButtonClick={() => validateAndShowModal(currentDomain, modalType)}>
      <TextField
        value={name}
        label={t(`${TRANSLATION_PATH}.name-label`)}
        hint={t(`${TRANSLATION_PATH}.name-hint`)}
        onChange={(name: string) => onChange({ ...currentDomain, name: name })}
        errorMessage={validationErrors.name || dataErrors.name}
        data-test-id="inspector-name-field"
      />
      <TextField
        value={hostUrl}
        label={t(`${TRANSLATION_PATH}.host-url-label`)}
        hint={t(`${TRANSLATION_PATH}.host-url-hint`)}
        placeholder={t(`${TRANSLATION_PATH}.host-url-placeholder`)}
        onChange={(hostUrl: string) => onChange({ ...currentDomain, "host-url": hostUrl })}
        errorMessage={validationErrors["host-url"] || dataErrors["host-url"]}
        data-test-id="inspector-host-url-field"
      />
      <Select
        value={sections}
        label={t(`${TRANSLATION_PATH}.sections-label`)}
        helpText={t(`${TRANSLATION_PATH}.sections-hint`)}
        onChange={(sections: Section[]) =>
          onChange({ ...currentDomain, "section-ids": sections.map((section: Section) => section.id) })
        }
        getOptionLabel={(section: Section) => section.name}
        getOptionValue={(section: Section) => section.id.toString()}
        isMulti={true}
        options={sectionOpts}
        errorMessage={validationErrors["section-ids"]}
        data-test-id="inspector-sections-select"
      />
      <Select
        value={menuGroups}
        label={t(`${TRANSLATION_PATH}.menu-groups-label`)}
        helpText={t(`${TRANSLATION_PATH}.menu-groups-hint`)}
        onChange={(menuGroups: MenuGroup[]) =>
          onChange({ ...currentDomain, "menu-groups": menuGroups.map((menuGroup: MenuGroup) => menuGroup.id) })
        }
        getOptionLabel={(menuGroup: MenuGroup) => menuGroup.name}
        getOptionValue={(menuGroup: MenuGroup) => menuGroup.id.toString()}
        isMulti={true}
        options={menuGroupOpts}
      />
      <AsyncSelect
        value={homeCollection}
        label={t(`${TRANSLATION_PATH}.home-collection-label`)}
        helpText={t(`${TRANSLATION_PATH}.home-collection-hint`)}
        onChange={(collection: Collection) =>
          onChange({ ...currentDomain, "home-collection-id": collection && collection.id })
        }
        getOptionLabel={(collection: Collection) => collection.name}
        getOptionValue={(collection: Collection) => collection.id.toString()}
        loadOptions={searchCollections}
        isClearable={true}
        defaultOptions={true}
        cacheOptions={true}
      />
      <TextField
        value={betaHostUrl}
        label={t(`${TRANSLATION_PATH}.beta-host-label`)}
        hint={t(`${TRANSLATION_PATH}.beta-host-hint`)}
        placeholder={t(`${TRANSLATION_PATH}.beta-host-placeholder`)}
        onChange={(betaHostUrl: string) =>
          onChange({ ...currentDomain, "beta-host-url": betaHostUrl !== "" ? betaHostUrl : null })
        }
        errorMessage={validationErrors["beta-host-url"]}
      />
      <CategoryTitle title={t("common.language")} />
      <TextField
        value={language.name}
        label={t("common.name")}
        placeholder={t(`${TRANSLATION_PATH}.language-name-placeholder`)}
        onChange={(languageName: string) =>
          onChange(set(cloneDeep(currentDomain), ["config", "language", "name"], languageName))
        }
        errorMessage={validationErrors.name || dataErrors.name}
      />
      <TextField
        label={t(`${TRANSLATION_PATH}.language-iso-code-label`)}
        placeholder={t(`${TRANSLATION_PATH}.language-iso-code-placeholder`)}
        value={language["iso-code"]}
        onChange={(isoCode: string) =>
          onChange(set(cloneDeep(currentDomain), ["config", "language", "iso-code"], isoCode))
        }
      />
      <TextField
        label={t(`${TRANSLATION_PATH}.language-ietf-code-label`)}
        placeholder={t(`${TRANSLATION_PATH}.language-ietf-code-placeholder`)}
        value={language["ietf-code"]}
        onChange={(ietfCode: string) =>
          onChange(set(cloneDeep(currentDomain), ["config", "language", "ietf-code"], ietfCode))
        }
      />
      <Select
        label={t("common.direction")}
        options={directions}
        getOptionLabel={(direction) => direction.name}
        getOptionValue={(direction) => direction.value}
        value={{ value: language!.direction, name: directionMapping[language.direction] || "" }}
        onChange={(direction: { name: string; value: string }) =>
          onChange(set(cloneDeep(currentDomain), ["config", "language", "direction"], direction.value))
        }
      />
      {isInspectorLoading && <Spinner message={t(`${TRANSLATION_PATH}.fetching`)} />}
    </Inspector>
  );
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    inspectorState: state.domain.ui.inspectorState,
    isInspectorLoading: state.domain.ui.isInspectorLoading,
    isActionButtonEnabled: state.domain.ui.isActionButtonEnabled,
    currentDomain: state.domain.app.currentDomain,
    allDomainsCollections: state.domain.app.collections,
    menuGroupOpts: state.menu.menuGroups,
    allSections: selectSections(state),
    allMenuGroups: selectMenuGroups(state),
    sectionOpts: selectSectionOpts(state),
    validationErrors: state.domain.app.errors.validation,
    dataErrors: state.domain.app.errors.data
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    cancelSave: () => dispatch(inspectorCancelSave()),
    onChange: (domain) => dispatch(onDomainFormChange(domain)),
    validateAndShowModal: (domain, modalType) => dispatch(validateAndShowConfirmationModal(domain, modalType))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(DomainInspector);
