/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Config, RouteDataResponse, Features as GlobalFeatures } from "./route-data";
import { StoryTemplateFields, FieldLimits, Section } from "./story-route-data";
import * as defaults from "api/breaking-news-defaults";
import { Attribute } from "api/story-attributes";

export interface BreakingNewsType {
  name: string;
  "story-group": string;
  "collection-id"?: number;
}

export interface Features extends GlobalFeatures {
  isBreakingNewsAssociateStoryEnabled: boolean;
}

export type AssociatedStoryDefaults = defaults.AssociatedStoryDefaults | null;

export interface BreakingNewsConfig extends Config {
  "breaking-news-types": BreakingNewsType[];
  "associated-story-defaults": AssociatedStoryDefaults;
  "template-fields": StoryTemplateFields;
  sections: Section[];
  fieldLimits: FieldLimits;
  storyAttributes: Attribute[];
}

export interface BreakingNewsRouteDataResponse extends RouteDataResponse {
  config: BreakingNewsConfig;
  features: Features;
}

export const DEFAULT_BREAKING_NEWS_TYPE_NAME = "Breaking News";
export const DEFAULT_BREAKING_NEWS_TYPE_SLUG = "breaking-news";
