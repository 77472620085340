/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Sidebar from "components/sidebar/sidebar";
import { t } from "i18n";
import Menu from "components/menu/menu";
import { match } from "react-router";
import { Features } from "api/route-data/route-data";
import { BANDWIDTH_BILLING_PATH, SAGE_BILLING_PATH } from "pages/billing/routes";

interface PartialAppState {
  features: Features;
}
interface StateProps {
  canAccessBilling?: boolean;
}

type Props = StateProps & {
  match: match;
  setActiveSidebar: (type: string) => void;
  isBannerPresent: boolean;
};

const menuItems = () => [
  { label: t("billing.sidebar-bandwidth-title"), pathName: BANDWIDTH_BILLING_PATH, path: BANDWIDTH_BILLING_PATH },
  { label: t("billing.sidebar-sage-title"), pathName: SAGE_BILLING_PATH, path: SAGE_BILLING_PATH }
];

export class BillingSidebar extends React.Component<Props> {
  render() {
    return (
      <Sidebar isBannerPresent={this.props.isBannerPresent}>
        <Menu
          items={menuItems()}
          getItemLabel={(item) => item.label}
          routeTo={(item) => item.path}
          isItemSelected={(item) => item.path === this.props.match.path || this.props.match.path.includes(item.path)}
          title={t("billing.sidebar-title")}
        />
      </Sidebar>
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    canAccessBilling: state.features.canAccessBilling
  };
};

export default compose(connect(mapStateToProps))(BillingSidebar);
