/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { WorkspaceRealtimeData } from "api/workspace";
import { get, take } from "lodash";

export const isNewStoriesAdded = (prevRealtimeData: WorkspaceRealtimeData, newRealtimeData: WorkspaceRealtimeData) => {
  const newStoriesLength = newRealtimeData.recentStories.length;
  const previousStoriesSet = new Set(take(prevRealtimeData.recentStories, newStoriesLength));
  for (const story of newRealtimeData.recentStories) {
    if (!previousStoriesSet.has(story)) {
      return true; // New story found
    }
  }
  return false; // No new stories
};

export const getChangedStoryStatuses = (
  prevRealtimeData: WorkspaceRealtimeData,
  newRealtimeData: WorkspaceRealtimeData
): { changeInCountStatuses: string[]; increaseInCountStatuses: string[] } => {
  const currentAggregationCount = newRealtimeData.aggregations.status;
  const previousAggregationCount = prevRealtimeData.aggregations.status;
  return Object.keys(currentAggregationCount).reduce(
    (
      {
        changeInCountStatuses,
        increaseInCountStatuses
      }: { changeInCountStatuses: string[]; increaseInCountStatuses: string[] },
      status: string
    ) => {
      const increaseInCount = currentAggregationCount[status] > previousAggregationCount[status];
      const changeInCount = currentAggregationCount[status] !== previousAggregationCount[status];
      return {
        changeInCountStatuses: changeInCount ? [...changeInCountStatuses, status] : changeInCountStatuses,
        increaseInCountStatuses: increaseInCount ? [...increaseInCountStatuses, status] : increaseInCountStatuses
      };
    },
    { changeInCountStatuses: [], increaseInCountStatuses: [] }
  );
};

export const scrollToTopOfScreen = () => {
  // Scroll to top of the screen
  const mainLayout = document.querySelector("#main-layout");
  if (mainLayout) {
    const scrollTopPosition = mainLayout.scrollTop;
    if (scrollTopPosition > 100) {
      mainLayout.scrollTop = 0;
    }
  }
};

export const convertWorkspaceResponseToRealtimeData = (workspaceResponse): WorkspaceRealtimeData => {
  return {
    aggregations: get(workspaceResponse, ["aggregations"], {}),
    computedAt: workspaceResponse["computed-at"],
    recentStories: get(workspaceResponse, ["stories"], []).map((story) => story["id"]),
    filterHash: get(workspaceResponse, "filter-hash")
  };
};
