/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { get } from "lodash";
import TextField from "components/text-field/text-field";
import { MoengageConfig, Integrations } from "api/integrations";
import { isClientValidationError } from "utils/validation.utils";

interface Props {
  integration: { key: Integrations.Moengage; value: MoengageConfig };
  updateIntegrationField: (key: string, value: Object) => void;
  readOnly?: boolean;
  errors: Error | null;
}

const Moengage: React.SFC<Props> = ({ integration, updateIntegrationField, readOnly, errors }) => {
  const integrationName = integration.key;
  return (
    <React.Fragment>
      <TextField
        label={t("integrations.fields.app-id")}
        value={integration.value["app-id"]}
        onChange={(value) => updateIntegrationField(integrationName, { "app-id": value })}
        readOnly={readOnly}
        errorMessage={
          isClientValidationError<MoengageConfig>(errors) ? get(errors, ["errors", "value", "app-id", "0"]) : undefined
        }
      />
      <TextField
        label={t("integrations.fields.api-secret")}
        value={integration.value["api-secret"]}
        onChange={(value) => updateIntegrationField(integrationName, { "api-secret": value })}
        readOnly={readOnly}
        errorMessage={
          isClientValidationError<MoengageConfig>(errors)
            ? get(errors, ["errors", "value", "api-secret", "0"])
            : undefined
        }
      />
      <TextField
        label={t("integrations.fields.data-center-id")}
        value={integration.value["data-center-id"]}
        onChange={(value) => updateIntegrationField(integrationName, { "data-center-id": value })}
        readOnly={readOnly}
        errorMessage={
          isClientValidationError<MoengageConfig>(errors)
            ? get(errors, ["errors", "value", "data-center-id", "0"])
            : undefined
        }
      />
    </React.Fragment>
  );
};

export default Moengage;
