/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { pick as _pick, reduce as _reduce, compact as _compact, find as _find } from "lodash";
import { removeKeyFromObject, isNotNullish } from "utils";
import {
  Card,
  StoryElement,
  Story,
  AnyStory,
  StoryElements,
  Tree,
  CompositeStoryElement,
  ChildStoryElement
} from "api/story";
import { CardId } from "api/primitive-types";
import { Image } from "api/search-media-image";
import { isNewCardId } from "./data/card";
import { negate } from "lodash";
import { getDistinctStoryElements } from "pages/story-editor/utils";

const serverKeys = [
  "updated-at",
  "story-content-id",
  "slug",
  "last-published-at",
  "content-created-at",
  "published-at",
  "content-updated-at",
  "version",
  "created-at",
  "publisher-id",
  "sequence-no",
  "assignee-id",
  "author-name",
  "is-published",
  "is-embargoed",
  "published-json",
  "owner-name",
  "publisher-id",
  "status",
  "story-version-id",
  "access",
  "first-published-at",
  "assignee-name",
  "author-id",
  "owner-id",
  "bullet-type",
  "id",
  "publish-at",
  "entities",
  "linked-entities",
  "private-fields",
  "story-features",
  "authors",
  "last-updated-by"
];

const getCardTree = (
  clientCard: Card,
  serverCard: Card,
  clientStoryElements: StoryElements,
  serverStoryElements: StoryElements
) => {
  let clientCardIds = clientCard.tree.map((storyElementId) => clientStoryElements[storyElementId]["client-id"]);

  var tree = clientCardIds.map((clientId) => {
    let serverElement = _find(
      serverStoryElements,
      (serverStoryElement) => serverStoryElement["client-id"] === clientId
    );

    let clientElement = _find(
      clientStoryElements,
      (clientStoryElement) => clientStoryElement["client-id"] === clientId
    );

    return serverElement ? serverElement.id : clientElement && clientElement.id;
  });
  const newTree = _compact(tree);
  getDistinctStoryElements(newTree);
  return newTree;
};

const mergeKeys = (clientObj: Object, serverObj: Object, keys: string[]) => ({
  ...clientObj,
  ..._pick(serverObj, keys)
});

const mergeTree = (clientTree: Tree[], serverTree: Tree[]): Tree[] => {
  return clientTree.map((clientCard) => {
    let serverCard = _find(serverTree, (card) => card["client-id"] === clientCard["client-id"]);
    if (serverCard) {
      clientCard["content-id"] = serverCard["content-id"];
      clientCard["content-type"] = serverCard["content-type"];
      clientCard["version-id"] = serverCard["version-id"];
      clientCard["card-added-at"] = serverCard["card-added-at"];
      clientCard["card-updated-at"] = serverCard["card-updated-at"];
    }
    return clientCard;
  });
};

const mergeStoryElement = (
  clientStoryElement: StoryElement | CompositeStoryElement | ChildStoryElement,
  serverStoryElement: StoryElement | CompositeStoryElement | ChildStoryElement,
  keys: string[]
) => {
  let storyElement = {};
  if (clientStoryElement.type === "image") {
    storyElement = mergeKeys(clientStoryElement, serverStoryElement, [
      "id",
      "family-id",
      "composite-element-id",
      "card-id"
    ]);

    if (storyElement["image"] && storyElement["image"]["temp-key"] && serverStoryElement["temp-image-key"]) {
      if (storyElement["image"]["temp-key"] === serverStoryElement["temp-image-key"]) {
        let newImage = mergeKeys(storyElement["image"], serverStoryElement.image || {}, ["url", "key"]);
        storyElement["image"] = removeKeyFromObject("temp-key", newImage);
      }
    }

    return storyElement;
  } else if (clientStoryElement.type === "composite") {
    return mergeKeys(clientStoryElement, serverStoryElement, ["id", "family-id", "card-id", "tree"]);
  } else {
    return mergeKeys(clientStoryElement, serverStoryElement, keys);
  }
};

const mergeStoryElements = (clientStoryElements: StoryElements, serverStoryElements: StoryElements) => {
  let storyElements = _reduce(
    clientStoryElements,
    (result, clientStoryElement, key) => {
      // Gets one server story elements based on client ids
      let serverStoryElement = _find(
        serverStoryElements,
        (storyElement) => clientStoryElement["client-id"] === storyElement["client-id"]
      );
      if (serverStoryElement) {
        key = serverStoryElement.id;
        // Merges client and servers story elements. Merges only id, family id, card id and card version id.
        result[key] = mergeStoryElement(clientStoryElement, serverStoryElement, [
          "id",
          "family-id",
          "card-id",
          "card-version-id",
          "composite-element-id"
        ]);

        return result;
      }
      return { ...result, [clientStoryElement.id]: clientStoryElement };
    },
    {}
  );
  return { "story-elements": storyElements };
};

const mergeCard = (
  clientCard: Card,
  serverCard: Card,
  clientStoryElements: StoryElements,
  serverStoryElements: StoryElements
) => {
  return {
    ...mergeKeys(clientCard, serverCard, [
      "id",
      "content-id",
      "version",
      "content-version-id",
      "card-added-at",
      "card-updated-at",
      "status"
    ]),
    tree: getCardTree(clientCard, serverCard, clientStoryElements, serverStoryElements)
  };
};

const mergeCards = (
  clientCards: { [key: string]: Card },
  cardIdMapping: { [key: string]: Card },
  clientStoryElements: StoryElements,
  serverStoryElements: StoryElements
) => {
  return _reduce(
    clientCards,
    (result, clientCard, key) => {
      let serverCard = cardIdMapping[key];
      if (serverCard) {
        result[serverCard["content-id"]] = mergeCard(clientCard, serverCard, clientStoryElements, serverStoryElements);
      } else {
        result[key] = clientCard;
      }
      return result;
    },
    {}
  );
};

const alternativeHeroImage = (story: AnyStory) => {
  return (
    story["alternative"] &&
    story["alternative"]["home"] &&
    story["alternative"]["home"]["default"] &&
    story["alternative"]["home"]["default"]["hero-image"]
  );
};

const isheroImageAlreadySaved = (clientHeroImage: Image, serverHeroImage: Image) => {
  return (
    clientHeroImage &&
    clientHeroImage["temp-key"] &&
    serverHeroImage &&
    serverHeroImage["temp-key"] &&
    clientHeroImage["temp-key"] === serverHeroImage["temp-key"]
  );
};

const storyLevelChanges = (clientStory: AnyStory, serverStory: Story): { [key: string]: any } => {
  var changes = {};
  const clientStoryAlternativeHeroImage = alternativeHeroImage(clientStory),
    serverStoryAlternativeHeroImage = alternativeHeroImage(serverStory);
  if (isheroImageAlreadySaved(clientStory["hero-image"], serverStory["hero-image"])) {
    changes["hero-image"] = removeKeyFromObject("temp-key", serverStory["hero-image"]);
  }
  if (isheroImageAlreadySaved(clientStoryAlternativeHeroImage, serverStoryAlternativeHeroImage)) {
    changes["alternative"] = serverStory["alternative"];
    changes["alternative"]["home"]["default"]["hero-image"] = removeKeyFromObject(
      "temp-key",
      serverStoryAlternativeHeroImage
    );
  }
  changes["tags"] = clientStory["tags"] || [];
  changes["sections"] = clientStory["sections"] || [];
  changes["entities"] = clientStory["entities"] || {};
  return changes;
};

const mergeModifiedCards = (clientModifiedCards: Array<CardId>, cardIdMapping: { [key: string]: Card }): CardId[] => {
  return clientModifiedCards.map((clientCardId) => {
    let serverCard = cardIdMapping[clientCardId];
    if (serverCard) {
      return serverCard["content-id"];
    }
    return clientCardId;
  });
};

const cardChanges = (
  clientStory: AnyStory,
  serverStory: Story,
  clientStoryElements: StoryElements,
  serverStoryElements: StoryElements
) => {
  var clientCards = clientStory.cards;
  var serverCards = serverStory.cards;
  var cardIdMapping = _reduce(
    clientCards,
    (result, clientCard, key) => {
      let serverCard = _find(serverCards, (card) => card["client-id"] === clientCard["client-id"]);
      if (serverCard) {
        result[key] = serverCard;
      }
      return result;
    },
    {}
  );

  return {
    cards: mergeCards(clientCards, cardIdMapping, clientStoryElements, serverStoryElements),
    tree: mergeTree(clientStory.tree, serverStory.tree),
    "updated-cards": mergeModifiedCards(clientStory["updated-cards"], cardIdMapping)
  };
};

const mergeStory = (clientStory: AnyStory, serverStory: Story): Story => {
  let serverData = _pick(serverStory, serverKeys);
  let cards = cardChanges(clientStory, serverStory, clientStory["story-elements"], serverStory["story-elements"]);
  let storyChanges = storyLevelChanges(clientStory, serverStory);
  let storyElements = mergeStoryElements(clientStory["story-elements"], serverStory["story-elements"]);
  return {
    ...clientStory,
    ...serverData,
    ...cards,
    ...storyChanges,
    ...storyElements
  } as Story;
};

function findMatchingServerCard(cardId: CardId, clientStory: AnyStory, serverStory: Story): CardId | null {
  const clientCardId = clientStory.cards[cardId];
  const clientId = clientCardId && clientCardId["client-id"];
  const matchingServerCard = Object.values(serverStory.cards).find((card) => card["client-id"] === clientId);
  return matchingServerCard ? matchingServerCard["content-id"] : null;
}

export function mergeCardsLoaded(cardsLoaded: CardId[], clientStory: AnyStory, serverStory: Story): CardId[] {
  const oldCards = cardsLoaded.filter(negate(isNewCardId));
  const newCards: CardId[] = cardsLoaded
    .filter(isNewCardId)
    .map((id) => findMatchingServerCard(id, clientStory, serverStory))
    .filter(isNotNullish);
  return oldCards.concat(newCards);
}

export default mergeStory;
