/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as actions from "../containers/page/actions";

const initialState = {
  message: "",
  level: "error",
  position: "bc"
};

function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case actions.NOTIFICATION_SUCCESS: {
      return {
        ...state,
        message: action.payload.message,
        level: "success",
        onRemove: action.payload.onRemove
      };
    }
    case actions.NOTIFICATION_ERROR: {
      return {
        ...state,
        message: action.payload.message,
        level: "error",
        action: action.payload.action
      };
    }
    case actions.NOTIFICATION_INFO: {
      return {
        ...state,
        message: action.payload.message,
        level: "info"
      };
    }
    case actions.NOTIFICATION_RESET: {
      return initialState;
    }
    default:
      return state;
  }
}

export default notificationReducer;
