/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { base64Decode } from "utils";
import { generateIframeContent } from "./generate-iframe-content";
import styles from "./sandboxed-js-embed.module.css";

interface Props {
  embed: string;
}

class SandboxedJsEmbed extends React.Component<Props> {
  private preview: React.RefObject<HTMLIFrameElement>;
  constructor(props: Props) {
    super(props);
    this.preview = React.createRef();
  }

  updateIframe(iFrame: HTMLIFrameElement) {
    const document = iFrame.contentDocument;
    if (document && this.props.embed) {
      document.body.innerHTML = base64Decode(this.props.embed);
      generateIframeContent(document.body, document);
    }
  }

  componentDidMount() {
    const iFrame = this.preview.current;
    if (iFrame) {
      this.updateIframe(iFrame);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const iFrame = this.preview.current,
      iFrameBody = iFrame && iFrame.contentDocument && iFrame.contentDocument.getElementsByTagName("body"),
      iFrameBodyHtml = iFrameBody && iFrameBody[0] && iFrameBody[0].innerHTML;

    if (this.props.embed !== prevProps.embed || iFrameBodyHtml === "") {
      if (iFrame) {
        this.updateIframe(iFrame);
      }
    }
  }

  render() {
    return (
      <div>
        {this.props.embed && (
          <iframe
            title="Sandboxed"
            ref={this.preview}
            width="100%"
            frameBorder="0"
            className={styles["sandboxed-iframe"]}
          />
        )}
      </div>
    );
  }
}

export default SandboxedJsEmbed;
