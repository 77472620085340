/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import Select from "components/select/select";
import { Target } from "api/push-notification";
import { PushNotificationChannel } from "api/push-notification-channels";
import { get } from "lodash";

export interface PushNotificationChannelSelectProps {
  index: number;
  targets: Target[];
  onUpdate: (change: Target[]) => void;
  disabled?: boolean;
  channels: PushNotificationChannel[];
}

const PushNotificationChannelSelect: React.FC<PushNotificationChannelSelectProps> = ({
  targets,
  index,
  onUpdate,
  disabled,
  channels
}) => {
  const selectedTargetChannel = get(targets, [index, "channel"]);
  const targetGroupChannels = targets ? targets.map((target) => target.channel) : [];
  const filteredChannels = channels.filter((channel) => !targetGroupChannels.includes(channel.name));
  return (
    <Select
      onChange={(val: { id: string; name: string }) => {
        if (index < targets.length) {
          targets[index].channel = val.name;
        } else {
          targets.push({ channel: val.name });
        }
        onUpdate(targets);
      }}
      value={selectedTargetChannel ? { id: selectedTargetChannel, name: selectedTargetChannel } : null}
      options={filteredChannels.map((channel) => ({ id: channel.id.toString(), name: channel.name }))}
      getOptionLabel={(target) => target["name"]}
      getOptionValue={(target) => target["name"]}
      hideError={true}
      isDisabled={disabled}
    />
  );
};

export default PushNotificationChannelSelect;
