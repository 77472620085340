/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { CollectionsConfig, CollectionEntityType } from "api/collection-config";

enum actions {
  LOAD_COLLECTIONS_CONFIG = "@@settings/LOAD_COLLECTIONS_CONFIG",
  UPDATE_COLLECTIONS_CONFIG = "@@settings/UPDATE_COLLECTIONS_CONFIG",
  RESET_COLLECTIONS_CONFIG = "@@settings/RESET_COLLECTIONS_CONFIG"
}

export default actions;

export type LoadCollectionsConfig = {
  type: actions.LOAD_COLLECTIONS_CONFIG;
  payload: {
    allEntityTypes: CollectionEntityType[];
    collectionsConfig: CollectionsConfig;
  };
};

export type UpdateCollectionsConfig = {
  type: actions.UPDATE_COLLECTIONS_CONFIG;
  payload: {
    collectionsConfig: CollectionsConfig;
  };
};
export type ResetConfig = {
  type: actions.RESET_COLLECTIONS_CONFIG;
};
export type ActionTypes = LoadCollectionsConfig | UpdateCollectionsConfig | ResetConfig;
