/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { createSlice, createAction } from "@reduxjs/toolkit";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";
import { get } from "lodash";
import { AnyAction } from "redux";

import { notificationError, notificationSuccess } from "action-creators/notification";
import { save, get as getWatermarkImageAPI, deleteWatermark, WatermarkImage } from "api/watermark-images";
import { AnyImage } from "api/search-media-image";
import { Section } from "api/route-data/story-route-data";
import sectionActions from "pages/manage/sections/actions";
import { parseError, getErrorMessage } from "utils/error.utils";

export const INITIAL_WATERMARK_IMAGE_STATE = {
  data: { type: "default", "type-id": null, image: null },
  ui: { isWatermarkImageModified: false, action: null }
};

export interface State {
  data: WatermarkImage;
  ui: { isWatermarkImageModified: boolean };
}

export interface PartialAppState {
  watermarkImage: State;
  config: {
    "cdn-cname": string;
  };
}

interface WatermarkImagePayload {
  watermarkImage: WatermarkImage;
}

interface ImagePayload {
  image: AnyImage | null;
}

interface SectionPayload {
  section: Section;
}

interface WatermarkAction<T> {
  payload: T;
}

const { reducer, actions, name } = createSlice({
  initialState: INITIAL_WATERMARK_IMAGE_STATE,
  name: "watermarkImages",
  reducers: {
    setWatermarkImage: (state: State, { payload: { image } }: WatermarkAction<ImagePayload>) => {
      state.data.image = image;
      state.ui.isWatermarkImageModified = true;
    },
    saveWatermarkImageSuccess: (
      state: State,
      { payload: { watermarkImage } }: WatermarkAction<WatermarkImagePayload>
    ) => {
      state.data = watermarkImage;
      state.ui.isWatermarkImageModified = false;
    },
    setWatermarkImageSection: (state: State, { payload: { section } }: WatermarkAction<SectionPayload>) => {
      state.data.type = "section";
      state.data["type-id"] = section.id;
    },
    getWatermarkImageSuccess: (
      state: State,
      { payload: { watermarkImage } }: WatermarkAction<WatermarkImagePayload>
    ) => {
      if (watermarkImage) {
        state.data = watermarkImage;
      }
    },
    getWatermarkImageFailure: (state: State, { payload: { meta } }: any) => {
      state.data = { ...INITIAL_WATERMARK_IMAGE_STATE.data };
      if (meta && meta.section) {
        state.data.type = "section";
        state.data["type-id"] = meta.section.id;
      }
    }
  },
  extraReducers: {
    [sectionActions.INIT_NEW_SECTION]: () => {
      return INITIAL_WATERMARK_IMAGE_STATE;
    }
  }
});

export const {
  setWatermarkImage,
  saveWatermarkImageSuccess,
  setWatermarkImageSection,
  getWatermarkImageSuccess,
  getWatermarkImageFailure
} = actions;

function asyncActionCreateHelper(action: string) {
  const actionRequestType = `${name}/${action}Request`;
  const actionSuccessType = `${name}/${action}Success`;
  const actionErrorType = `${name}/${action}Failure`;
  const actionRequest = createAction(actionRequestType);
  const actionSuccess = createAction(actionSuccessType, (data: WatermarkImage) => ({
    payload: { watermarkImage: data }
  }));
  const actionError = createAction(actionErrorType, (error: Error, data?: any) => ({ payload: { error, meta: data } }));
  return [actionRequest, actionSuccess, actionError];
}

export const [saveWatermarkImageRequest, _, saveWatermarkImageFailure] = asyncActionCreateHelper("saveWatermarkImage");
export const [getWatermarkImageRequest] = asyncActionCreateHelper("getWatermarkImage");

export function saveWatermarkImage({ fireErrorAction = true } = {}) {
  return (dispatch: ThunkDispatch<any, any, AnyAction>, getState: () => PartialAppState) => {
    const state = getState();
    const imageHost = state.config["cdn-cname"];
    const watermarkImage = get(state, ["watermarkImage", "data"], { id: undefined, image: undefined });
    const watermarkImageId = watermarkImage && watermarkImage.id;
    if (watermarkImage.image) {
      dispatch(saveWatermarkImageRequest());
      return save(watermarkImage, { imageHost })
        .then((watermarkImage: WatermarkImage) => dispatch(saveWatermarkImageSuccess({ watermarkImage })))
        .catch((error) => {
          fireErrorAction &&
            dispatch(notificationError(getErrorMessage(error, t("settings.watermark_images.server_error"))));
          dispatch(saveWatermarkImageFailure(getErrorMessage(error, t("settings.watermark_images.server_error"))));
          if (!fireErrorAction) {
            throw new Error(error.message);
          }
        });
    } else if (watermarkImageId) {
      return deleteWatermark(watermarkImageId)
        .then(() => {
          dispatch(saveWatermarkImageSuccess({ watermarkImage: INITIAL_WATERMARK_IMAGE_STATE.data }));
          dispatch(notificationSuccess(t("settings.watermark_images.delete_success_message")));
        })
        .catch((error) => {
          fireErrorAction &&
            dispatch(notificationError(getErrorMessage(error, t("settings.watermark_images.server_error"))));
          if (!fireErrorAction) {
            throw new Error(error.message);
          }
        });
    }
    return Promise.resolve();
  };
}

export function getWatermarkImage(opts?: { section: Section }) {
  const { section = null } = opts || {};
  return (dispatch: ThunkDispatch<any, any, AnyAction>, getState: () => PartialAppState) => {
    const imageHost = getState().config["cdn-cname"];
    dispatch(getWatermarkImageRequest());
    return getWatermarkImageAPI(imageHost, opts)
      .then((watermarkImage: WatermarkImage) => dispatch(getWatermarkImageSuccess({ watermarkImage })))
      .catch((error: Error) => {
        let errorObj = parseError(error);
        if (get(errorObj, ["status"]) !== 404) {
          dispatch(notificationError(getErrorMessage(error, t("settings.watermark_images.server_error"))));
          dispatch(getWatermarkImageFailure({ error, meta: { section } }));
        } else {
          dispatch(getWatermarkImageFailure({ error: null, meta: { section } }));
        }
        throw new Error(error.message);
      });
  };
}

export default reducer;
