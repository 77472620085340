/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import kebabCase from "lodash/kebabCase";
import { t } from "i18n";
import { STORY_EDITOR_NEW_PATH } from "pages/story-editor/routes";
import { COLLECTIONS_CONTENT_SETTINGS_NEW_PATH } from "pages/collections/routes";
import { NEW_BREAKING_NEWS } from "pages/breaking-news/routes";
import { USERS_NEW_PATH } from "pages/settings/pages/users-and-roles/users/routes";
import { PUSH_NOTIFICATION_NEW_PATH } from "pages/content/push-notification/routes";
import { FORMS_EDITOR_PATH_EDIT } from "pages/forms/routes";
import { generatePath } from "react-router";
import WithGAEvent from "helpers/ga-analytics/with-ga-event";
import styles from "./add-new-menu.module.css";
import {
  AdvancedContextMenuItem,
  AdvancedContextSubmenu
} from "components/advanced-context-menu/advanced-context-menu";
import { filterStoryTemplateWithPrint } from "utils";

interface ComponentProps {
  children: React.ReactNode;
}

interface AddNewMenuProps {
  canCreateBreakingNews?: boolean;
  canCreateCollections?: boolean;
  canCreateStory?: boolean;
  canAddUser?: boolean;
  canCreatePushNotifications?: boolean;
  canCreateForms?: boolean;
  storyTemplates?: any;
  Component: React.ComponentType<ComponentProps>;
  isStoryPrintEnabled?: boolean;
}

const addNewItem = (label: string, openWindow: string, canShow?: boolean) => {
  return (
    <WithGAEvent label={`header-add-new-${kebabCase(label)}`}>
      {canShow && (
        <AdvancedContextMenuItem onClick={() => window.open(openWindow)}>{t(label)} </AdvancedContextMenuItem>
      )}
    </WithGAEvent>
  );
};

const addNewItemWithSubMenu = (label: string, openWindow: string, canShow?: boolean, storyTemplates?: any) => {
  return (
    <WithGAEvent label={`header-add-new-${kebabCase(label)}`}>
      {canShow && (
        <AdvancedContextSubmenu label={t(label)}>
          {storyTemplates &&
            storyTemplates.map((template) => {
              return (
                <AdvancedContextMenuItem
                  onClick={() => {
                    window.open(`${openWindow}?template=${template.slug}`);
                  }}>
                  {template.name}
                </AdvancedContextMenuItem>
              );
            })}
        </AdvancedContextSubmenu>
      )}
    </WithGAEvent>
  );
};

const AddNewMenu: React.SFC<AddNewMenuProps> = ({
  canCreateStory,
  canCreateBreakingNews,
  canCreatePushNotifications,
  canCreateCollections,
  canAddUser,
  canCreateForms,
  storyTemplates,
  isStoryPrintEnabled,
  Component
}) => {
  return (
    <Component>
      <div id="add-new" className={styles["add-new-menu"]} data-test-id="add-new-menu-list">
        {addNewItem("text_story", STORY_EDITOR_NEW_PATH, canCreateStory)}
        {addNewItemWithSubMenu(
          "story",
          STORY_EDITOR_NEW_PATH,
          canCreateStory,
          filterStoryTemplateWithPrint(isStoryPrintEnabled, storyTemplates)
        )}
        {addNewItem("breaking_news", NEW_BREAKING_NEWS, canCreateBreakingNews)}
        {addNewItem(
          "push_notification",
          generatePath(PUSH_NOTIFICATION_NEW_PATH, { type: "all" }),
          canCreatePushNotifications
        )}
        {addNewItem("collection", COLLECTIONS_CONTENT_SETTINGS_NEW_PATH, canCreateCollections)}
        {addNewItem("user", USERS_NEW_PATH, canAddUser)}
        {addNewItem("form", generatePath(FORMS_EDITOR_PATH_EDIT, { formId: "new" }), canCreateForms)}
      </div>
    </Component>
  );
};

export default AddNewMenu;
