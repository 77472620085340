/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

export interface Configuration {
  id: number;
  slug: string;
  name: string;
  toggle: boolean;
  description: null | string;
  disabled: boolean;
  hidden: boolean;
  config: Config | null;
  "default-config": DefaultConfig | {};
  "sort-order": number;
  "default-value": boolean;
  "default-value-for-lite-publisher": boolean;
  "feature-id": number | null;
  "config-schema"?: null;
  "category-id": number;
  "category-name": "Features" | "Story Elements";
  "content-type"?: string;
}

export interface Config {
  "editable-fields"?: string[];
  fonts?: FontConfig;
  colors?: ColorConfig;
  "gtm-id"?: string;
  "logo-url"?: string;
  doubleclick?: DoubleclickConfig;
  "comscore-tracking-vars"?: ComscoreTrackingVars;
  "invalid-elements-strategy"?: string;
  "google-analytics-tracking-id"?: string;
  default?: Default;
  sources?: Sources;
  values?: string[];
  "no-of-visible-cards"?: number;
}

export interface ColorConfig {
  primary: string;
  secondary: string;
  "footer-background": string;
  "footer-text-color": string;
  "header-background": string;
  "section-text-color": string;
}

export interface ComscoreTrackingVars {
  c1: number;
  c2: null;
}

export interface Default {
  text: string;
  toggle: boolean;
}

export interface DoubleclickConfig {
  "top-ad": Ad;
  "body-ad": Ad;
  "bottom-ad": Ad;
}

export interface Ad {
  width: number;
  height: number;
  "unit-path": string;
}

export interface FontConfig {
  primary: Font;
  secondary: Font;
}

export interface Font {
  url: string;
  family: string;
}

export interface Sources {
  ugc: Default;
  syndicated: Default;
}

export interface DefaultConfig {
  fonts?: FontConfig;
  colors?: ColorConfig;
  doubleclick?: DoubleclickConfig;
  "story-save-timeout"?: number;
}

export const getConfigureData = (): Promise<Array<Configuration>> => {
  return client
    .url("/api/select-features")
    .get()
    .json();
};

export const updateConfigurationData = (configuration: Array<Configuration>) => {
  return client
    .url("/api/select-features")
    .json({ configuration })
    .post()
    .res();
};
