/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Story, StoryElement, StoryElementType } from "../api/story";
import striptags from "striptags";

const wordCount = (s: string) => {
  return s.split(/\s+/).filter(function(word) {
    return word !== "";
  }).length;
};

const removeHtmlTags = (text: string) => {
  return striptags(text);
};

const cardWordCount = (card: StoryElement) => {
  return card.type === "text" && card.text ? wordCount(removeHtmlTags(card.text)) : 0;
};

export const storyWordCount = (story: Story) => {
  return Object.values(story["story-elements"])
    .map(cardWordCount)
    .reduce((a, b) => a + b, 0);
};

const cardCharacterCount = (card: StoryElement) => {
  return card.type === "text" && card.text ? removeHtmlTags(card.text).length : 0;
};

export const storyCharacterCount = (story: Story) => {
  return Object.values(story["story-elements"])
    .map(cardCharacterCount)
    .reduce((a, b) => a + b, 0);
};

export const storyContent = (story: Story) => {
  let htmlStoryText = Object.values(story["story-elements"])
    .filter((storyElement) => storyElement.type === StoryElementType.Text)
    .map((storyElement) => storyElement.text)
    .join(" ");
  return removeHtmlTags(htmlStoryText);
};

export const nonEmptyStoryImageElements = (story: Story) => {
  return Object.values(story["story-elements"]).filter(
    (storyElement) => storyElement.type === StoryElementType.Image && storyElement.image && storyElement.image.url
  );
};

export const getStoryImages = (story: Story) => {
  let storyImages = nonEmptyStoryImageElements(story).map((imageStoryElement) => {
    return (
      imageStoryElement.image && { image: imageStoryElement.image.url, "caption-text": imageStoryElement.image.caption }
    );
  });
  let heroImage = story["hero-image"];
  if (heroImage && heroImage.url) {
    storyImages.push({ image: heroImage.url, "caption-text": heroImage.caption });
  }
  return storyImages;
};
