/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { OverflowProperty } from "csstype";

export function setBodyOverflow(value: OverflowProperty) {
  if (document.body) {
    document.body.style.overflow = value;
  }
}

export function decodeHTML(html: string): string {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}
