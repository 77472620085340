/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface Props {
  width?: number | string;
  height?: number | string;
}

const Controller = ({ width = 24, height = 24 }: Props) => (
  <svg width={width} height={height} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Group 17 Copy 2</title>
    <g id="Modes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard-Copy-2" transform="translate(-174.000000, -119.000000)">
        <g id="Group-17-Copy-2" transform="translate(174.000000, 119.000000)">
          <g id="article-24px">
            <rect id="Rectangle" x="0" y="0" width="20" height="20" />
            <path
              d="M16.2220277,2 C17.1509039,2 17.9193378,2.72199013 17.993859,3.6324999 L17.9997812,3.77775347 L17.9999606,9.80267039 C17.4557961,9.48789374 16.8576069,9.25612464 16.2227067,9.12467689 L16.2220277,3.77775347 L3.77775347,3.77775347 L3.77775347,16.2220277 L9.12467689,16.2227067 C9.25612464,16.8576069 9.48789374,17.4557961 9.80267039,17.9999606 L3.77775347,17.9997812 C2.84887728,17.9997812 2.08044334,17.2777911 2.00592214,16.3672813 L2,16.2220277 L2,3.77775347 C2,2.84887728 2.72199013,2.08044334 3.6324999,2.00592214 L3.77775347,2 L16.2220277,2 Z M9.02540905,14.4441133 L5.55550693,14.4442743 L5.55550693,12.6665208 L9.47067368,12.6665069 C9.23622014,13.2213741 9.08284873,13.8188595 9.02540905,14.4441133 Z M10.6300263,10.8886087 L5.55550693,10.8887673 L5.55550693,9.11101386 L13.8473991,9.11059985 C12.5905383,9.35515172 11.4738684,9.99203356 10.6300263,10.8886087 Z M14.4442743,5.55550693 L14.4442743,7.3332604 L5.55550693,7.3332604 L5.55550693,5.55550693 L14.4442743,5.55550693 Z"
              id="Combined-Shape"
              fill="var(--brand-3)"
              fillRule="nonzero"
            />
          </g>
          <g id="settings-24px" transform="translate(11.000000, 11.000000)" fill="var(--brand-3)" fillRule="nonzero">
            <path
              d="M7.05847066,4.39166667 C7.07560495,4.26666667 7.08417209,4.1375 7.08417209,4 C7.08417209,3.86666667 7.07560495,3.73333333 7.05418708,3.60833333 L7.92375227,2.95 C8.00085657,2.89166667 8.02227443,2.77916667 7.97515514,2.69583333 L7.15270925,1.3125 C7.10130638,1.22083333 6.99421707,1.19166667 6.89997848,1.22083333 L5.87620469,1.62083333 C5.66202607,1.4625 5.43499674,1.32916667 5.18226597,1.22916667 L5.02805736,0.170833333 C5.01092307,0.0708333333 4.92525163,0 4.82244589,0 L3.17755411,0 C3.07474837,0 2.9933605,0.0708333333 2.97622621,0.170833333 L2.8220176,1.22916667 C2.56928684,1.32916667 2.33797393,1.46666667 2.12807888,1.62083333 L1.10430509,1.22083333 C1.0100665,1.1875 0.902977192,1.22083333 0.851574324,1.3125 L0.0334120058,2.69583333 C-0.0179908624,2.78333333 -0.000856572974,2.89166667 0.084814874,2.95 L0.954380061,3.60833333 C0.932962199,3.73333333 0.915827909,3.87083333 0.915827909,4 C0.915827909,4.12916667 0.924395054,4.26666667 0.945812916,4.39166667 L0.0762477293,5.05 C-0.000856572974,5.10833333 -0.0222744347,5.22083333 0.0248448611,5.30416667 L0.847290752,6.6875 C0.89869362,6.77916667 1.00578293,6.80833333 1.10002152,6.77916667 L2.12379531,6.37916667 C2.33797393,6.5375 2.56500326,6.67083333 2.81773403,6.77083333 L2.97194264,7.82916667 C2.9933605,7.92916667 3.07474837,8 3.17755411,8 L4.82244589,8 C4.92525163,8 5.01092307,7.92916667 5.02377379,7.82916667 L5.1779824,6.77083333 C5.43071316,6.67083333 5.66202607,6.5375 5.87192112,6.37916667 L6.89569491,6.77916667 C6.9899335,6.8125 7.09702281,6.77916667 7.14842568,6.6875 L7.97087157,5.30416667 C8.02227443,5.2125 8.00085657,5.10833333 7.9194687,5.05 L7.05847066,4.39166667 Z M4,5.5 C3.15185268,5.5 2.45791395,4.825 2.45791395,4 C2.45791395,3.175 3.15185268,2.5 4,2.5 C4.84814732,2.5 5.54208605,3.175 5.54208605,4 C5.54208605,4.825 4.84814732,5.5 4,5.5 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Controller;
