/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";

import TextField from "components/text-field/text-field";
import TextArea from "components/text-area/text-area";
import { SEOMetadata } from "api/seo-metadata";

interface Props {
  seoMetadata: SEOMetadata | null;
  updateSeoMetadata: (changes: Object) => void;
}

const SeoMetadata: React.SFC<Props> = ({ seoMetadata, updateSeoMetadata }) => {
  return (
    <React.Fragment>
      <div data-test-id="general-settings-page-title">
        <TextField
          placeholder={t("settings.seoMetadata.fields.page_title")}
          label={t("settings.seoMetadata.fields.page_title")}
          value={(seoMetadata && seoMetadata["page-title"]) || ""}
          onChange={(value) => updateSeoMetadata({ "page-title": value })}
        />
      </div>
      <div data-test-id="general-settings-meta-title">
        <TextField
          placeholder={t("settings.seoMetadata.fields.meta_title")}
          label={t("settings.seoMetadata.fields.meta_title")}
          value={(seoMetadata && seoMetadata["title"]) || ""}
          onChange={(value) => updateSeoMetadata({ title: value })}
        />
      </div>
      <div data-test-id="general-settings-description">
        <TextField
          placeholder={t("settings.seoMetadata.fields.description")}
          label={t("settings.seoMetadata.fields.description")}
          value={(seoMetadata && seoMetadata.description) || ""}
          onChange={(value) => updateSeoMetadata({ description: value })}
        />
      </div>
      <div data-test-id="general-settings-keywords">
        <TextArea
          placeholder={t("settings.seoMetadata.fields.keywords")}
          label={t("settings.seoMetadata.fields.keywords")}
          value={(seoMetadata && seoMetadata.keywords) || ""}
          onChange={(value) => updateSeoMetadata({ keywords: value })}
        />
      </div>
    </React.Fragment>
  );
};

export default SeoMetadata;
