/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import markerIcon from "assets/icons/default-map-location-marker";

const g: any = global;

interface GoogleMap {
  map: google.maps.Map;
  markers: Array<google.maps.Marker>;
  polygon: Array<google.maps.Polygon>;
}

const initialCallbacks: (() => void)[] = [];
g.runAllGmapsCallbacks = () => initialCallbacks.forEach((callback) => callback());

const defaultMapOpts: google.maps.MapOptions = {
  zoom: 4,
  center: { lat: 20.257947, lng: 77.661336 },
  mapTypeControl: false,
  panControl: false,
  zoomControl: false,
  streetViewControl: false
};

const createMap = (elementToAttachMap: HTMLDivElement | null, mapOpts: google.maps.MapOptions) =>
  new g.google.maps.Map(elementToAttachMap, { ...defaultMapOpts, ...mapOpts });

const clearMarkers = (markers: Array<google.maps.Marker>) => {
  markers.forEach((marker: google.maps.Marker) => marker.setMap(null));
  markers = [];
};

const createPlaceMarkers = (
  mapWithMetadata: GoogleMap,
  place: google.maps.places.PlaceResult,
  bounds: google.maps.LatLngBounds
) => {
  if (!place.geometry) {
    return;
  }
  const location = place.geometry.location;
  mapWithMetadata.markers.push(
    new g.google.maps.Marker({
      map: mapWithMetadata.map,
      icon: markerIcon,
      title: place.name,
      position: location
    })
  );
  if (place.geometry.viewport) {
    bounds.union(place.geometry.viewport);
  } else {
    bounds.extend(place.geometry.location);
  }
};

const markSearchResults = (
  mapWithMetadata: GoogleMap,
  searchBox: google.maps.places.SearchBox,
  bounds: google.maps.LatLngBounds,
  placeSearchCallback: (val: {}) => void,
  placeSelectedCallBack: (value: string) => void,
  opts: { zoom?: number }
) => {
  const zoom = opts.zoom || defaultMapOpts.zoom;

  clearMarkers(mapWithMetadata.markers);
  let places = searchBox.getPlaces();
  if (places.length === 0) {
    return;
  }
  zoom && mapWithMetadata.map.setZoom(zoom);
  if (places.length === 1 && places[0].formatted_address && places[0].geometry) {
    const location = places[0].geometry.location;
    placeSelectedCallBack(places[0].formatted_address);
    placeSearchCallback({ lat: location.lat(), lon: location.lng() });
  }
  places.forEach((place: google.maps.places.PlaceResult) => createPlaceMarkers(mapWithMetadata, place, bounds));
  mapWithMetadata.map.fitBounds(bounds);
};

export const createMapWithMarkers: (
  elementToAttachMap: HTMLDivElement | null,
  mapOpts: google.maps.MapOptions | null
) => GoogleMap = (elementToAttachMap: HTMLDivElement | null, mapOpts: google.maps.MapOptions) => {
  return { map: createMap(elementToAttachMap, mapOpts), markers: [], polygon: [] };
};

export const initializeGoogleMaps = (apiKey: string, callback: () => void) => {
  if (!(g.google && g.google.maps)) {
    initialCallbacks.push(callback);
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places,drawing&callback=runAllGmapsCallbacks`;
    script.type = "text/javascript";
    script.async = true;
    script.defer = true;
    document.getElementsByTagName("body")[0].appendChild(script);
  } else {
    callback();
  }
};

export const addDefaultMarker = (
  mapWithMetadata: GoogleMap,
  location: google.maps.LatLngLiteral,
  setSelectedPlace: (value: string) => void,
  opts: { zoom?: number }
) => {
  const zoom = opts.zoom || defaultMapOpts.zoom;

  clearMarkers(mapWithMetadata.markers);

  mapWithMetadata.markers.push(
    new g.google.maps.Marker({
      map: mapWithMetadata.map,
      icon: markerIcon,
      position: location
    })
  );

  zoom && mapWithMetadata.map.setZoom(zoom);
  let geocoder = new g.google.maps.Geocoder();
  geocoder.geocode({ location }, (results: any) => setSelectedPlace(results[0].formatted_address));
};

export const addDefaultPolygon = (mapWithMetadata: GoogleMap, polygonVertices: Array<google.maps.LatLngLiteral>) => {
  clearMarkers(mapWithMetadata.markers);

  let newPolygon = new g.google.maps.Polygon({
    paths: polygonVertices,
    strokeColor: "#FFFFF",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0
  });

  newPolygon.setMap(mapWithMetadata.map);

  let bounds = new g.google.maps.LatLngBounds();
  polygonVertices.forEach((vertex) => {
    bounds.extend(vertex);
  });
  mapWithMetadata.map.fitBounds(bounds);
};

export const attachSearchBox = (
  mapWithMetadata: GoogleMap,
  searchTextField: HTMLInputElement | null,
  placeSearchCallback: (val: {}) => void,
  placeSelectedCallBack: (value: string) => void,
  opts: { zoom?: number }
) => {
  if (searchTextField) {
    const searchBox = new g.google.maps.places.SearchBox(searchTextField);
    let bounds = new g.google.maps.LatLngBounds();
    searchBox.addListener("places_changed", () =>
      markSearchResults(mapWithMetadata, searchBox, bounds, placeSearchCallback, placeSelectedCallBack, opts)
    );
  }
};

const convertToLatLng = (location: string): google.maps.LatLngLiteral => {
  const locationArray = location.split(",");
  return { lat: parseFloat(locationArray[0]), lng: parseFloat(locationArray[1]) };
};

export const initializeDrawer = (
  mapWithMetadata: GoogleMap,
  onPolygonComplete: (polygonVertices: Array<google.maps.LatLngLiteral>) => void
) => {
  let selectedShape: any = null;

  function setSelection(shape: any) {
    if (selectedShape) {
      selectedShape.setMap(null);
      selectedShape = null;
    }
    selectedShape = shape;
  }

  let drawingManager = new g.google.maps.drawing.DrawingManager({
    drawingMode: g.google.maps.drawing.OverlayType.POLYGON,
    drawingControl: true,
    drawingControlOptions: {
      position: g.google.maps.ControlPosition.TOP_CENTER,
      drawingModes: [g.google.maps.drawing.OverlayType.POLYGON]
    },
    polygonOptions: {
      strokeColor: "#FFFFF",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0
    }
  });
  drawingManager.setMap(mapWithMetadata.map);

  g.google.maps.event.addListener(drawingManager, "polygoncomplete", (polygon: any) => {
    if (polygon.getPath().getLength() < 3) {
      polygon.setMap(null);
    } else {
      let polygonVertices: Array<google.maps.LatLngLiteral> = [];
      for (let i = 0; i < polygon.getPath().getLength(); i++) {
        polygonVertices.push(
          convertToLatLng(
            polygon
              .getPath()
              .getAt(i)
              .toUrlValue(6)
          )
        );
      }
      onPolygonComplete(polygonVertices);

      let newShape = polygon;
      setSelection(newShape);
    }
  });
};
