/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { get } from "lodash";
import { t } from "i18n";
import classnames from "classnames/bind";

import { CustomField } from "api/route-data/story-route-data";
import TextArea from "components/text-area/text-area";

import styles from "../../header-card/header-card.module.css";
import CustomFieldIcon from "components/icons/custom-field";

const cx = classnames.bind(styles);

interface CustomMetadataProps {
  customMetadataFields: CustomField[];
  customMetadata: object;
  updateMetadata: (key: string, value: string) => void;
}

export const CustomMetadata: React.SFC<CustomMetadataProps> = ({
  customMetadataFields,
  customMetadata,
  updateMetadata
}) => {
  return customMetadataFields.length > 0 ? (
    <React.Fragment>
      {customMetadataFields.map((field) => (
        <CustomMetadataField
          key={field["key-name"]}
          field={field}
          value={get(customMetadata, [field["key-name"]], "")}
          updateMetadata={updateMetadata}
        />
      ))}
    </React.Fragment>
  ) : null;
};

interface CustomMetadataFieldProps {
  field: CustomField;
  value: string;
  updateMetadata: (key: string, value: string) => void;
}

export const CustomMetadataField: React.SFC<CustomMetadataFieldProps> = ({ field, value, updateMetadata }) => {
  return (
    <React.Fragment>
      <div className={cx("story-field-label")} data-test-id="story-field-label">
        <div className={styles["custom-field-icon"]} data-test-id="story-field-icon">
          <CustomFieldIcon />
        </div>
      </div>

      <div className={styles["custom-metadata"]} data-test-id={`custom-metadata-${field["key-name"]}`}>
        <TextArea
          onChange={(value) => updateMetadata(field["key-name"], value)}
          placeholder={t("story-editor.header-card.custom-metadata-field-placeholder", {
            customFieldName: field.name
          })}
          value={value}
          variant="plain"
          size="extra-large"
        />
      </div>
    </React.Fragment>
  );
};

export default CustomMetadata;
