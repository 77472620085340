/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction, compose } from "redux";
import { match } from "react-router";
import { t } from "i18n";
import { navigate } from "utils/routes.utils";
import wrapPage from "containers/page/page";
import { showUnauthorizedPage } from "store/error-page";
import { PartialAppState, TabType, setCurrentTab, disableSaveButton, clearErrors } from "store/secret-mode/secret-mode";
import { SECRET_MODE_TABS_PATH } from "pages/secret-mode/routes";
import { ScrollTabList } from "@quintype/em/components/tabs";
import { SelectTabs } from "pages/workspace/components/select-tabs/select-tabs";
import SecretModeHeader from "pages/secret-mode/components/secret-mode-header";
import DomainManager from "pages/secret-mode/domain-manager/domain-manager";
import ContentHeader from "pages/settings/components/content-header/content-header";
import GeneralSettings from "pages/secret-mode/general-settings/general-settings";
import StorySettings from "pages/secret-mode/story-settings/story-settings";
import CollectionSettings from "pages/secret-mode/collection-settings/collection-settings";
import LanguageSettings from "pages/secret-mode/language-settings/language-settings";

interface StateProps {
  isAdmin: boolean;
  isDomainManagerEnabled: boolean;
  isDesktopSizeViewport: boolean;
  currentTab: TabType;
}

interface DispatchProps {
  showUnauthorizedPage: () => void;
  switchTab: (currentTab: TabType) => void;
}

interface OwnProps {
  match: match<{ tabSlug: TabType }>;
}

type SecretModeProps = StateProps & DispatchProps & OwnProps;

const tabComponentMapping = {
  [TabType.General]: <GeneralSettings />,
  [TabType.DomainManager]: <DomainManager />,
  [TabType.Story]: <StorySettings />,
  [TabType.Collection]: <CollectionSettings />,
  [TabType.Language]: <LanguageSettings />
};

function getAllTabs({ isDomainManagerEnabled }: { isDomainManagerEnabled: boolean }) {
  const allTabs = [
    { value: TabType.General, label: t("secret-mode.tabs.general") },
    { value: TabType.Story, label: t("secret-mode.tabs.story") },
    { value: TabType.Collection, label: t("secret-mode.tabs.collection") },
    { value: TabType.Language, label: t("secret-mode.tabs.language") }
  ];
  isDomainManagerEnabled && allTabs.push({ value: TabType.DomainManager, label: t("secret-mode.tabs.domain") });
  return allTabs;
}

export const SecretMode: React.FC<SecretModeProps> = ({
  isAdmin,
  isDomainManagerEnabled,
  isDesktopSizeViewport,
  currentTab,
  match,
  switchTab,
  showUnauthorizedPage
}) => {
  const previousTab = useRef<TabType>();
  const allTabs = getAllTabs({ isDomainManagerEnabled });

  useEffect(() => {
    if (previousTab.current !== currentTab) {
      const tab = match.params.tabSlug;
      if (tab) {
        previousTab.current = tab;
        switchTab(tab);
      }
    }
  });

  if (isAdmin) {
    return (
      <>
        <ContentHeader title={t("secret-mode.title")} />
        {isDesktopSizeViewport ? (
          <ScrollTabList tabs={allTabs} value={currentTab} onChange={(value: TabType) => switchTab(value)} />
        ) : (
          <SelectTabs
            options={allTabs}
            value={currentTab}
            onChange={(value) => switchTab(value as TabType)}
            data-test-id="select-tabs"
          />
        )}
        {tabComponentMapping[currentTab]}
      </>
    );
  }

  showUnauthorizedPage();
  return null;
};

const mapStateToProps = ({ features, secretMode, viewport }: PartialAppState) => {
  return {
    title: "secret-mode",
    isAdmin: features.isAdmin,
    isDomainManagerEnabled: features.isDomainManagerEnabled,
    isDesktopSizeViewport: viewport.isDesktopSizeViewport,
    currentTab: secretMode.app.currentTab
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => ({
  showUnauthorizedPage: () => dispatch(showUnauthorizedPage()),
  switchTab: (currentTab) => {
    dispatch(setCurrentTab(currentTab));
    dispatch(disableSaveButton());
    dispatch(clearErrors());
    dispatch(navigate(SECRET_MODE_TABS_PATH, { tabSlug: currentTab }));
  }
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  wrapPage({ HeaderComponent: SecretModeHeader })
)(SecretMode);
