/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Answer = ({ width = "24", height = "24", color = "#c6ccd5" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 24 24">
    <path d="M14.31 16.67l-1-2.7H9.62l-.95 2.7H7l3.63-9.76h1.73L16 16.67zm-1.39-4.07L12 10c-.07-.18-.16-.46-.28-.84l-.24-.84a15.42 15.42 0 0 1-.48 1.75l-.88 2.53z" />
    <path d="M19.24 4.19a.57.57 0 0 1 .56.56v14.48a.56.56 0 0 1-.56.56H4.76a.56.56 0 0 1-.56-.56V4.75a.57.57 0 0 1 .56-.56h14.48m0-1.2H4.76A1.76 1.76 0 0 0 3 4.75v14.48A1.76 1.76 0 0 0 4.76 21h14.48A1.76 1.76 0 0 0 21 19.23V4.75A1.76 1.76 0 0 0 19.24 3z" />
  </svg>
);

export default Answer;
