/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Sidebar from "components/sidebar/sidebar";
import { t } from "i18n";
import styles from "./sidebar.module.css";

type Props = {
  isBannerPresent: boolean;
  isDesktopSizeViewport: boolean;
};

export class MediaLibrarySidebar extends React.Component<Props> {
  render() {
    if (this.props.isDesktopSizeViewport) return null;
    return (
      <div>
        <Sidebar isBannerPresent={this.props.isBannerPresent}>
          <h3 className={styles["sidebar-title"]}>{t("mediaLibrary.sidebar.title")}</h3>
        </Sidebar>
      </div>
    );
  }
}

export default MediaLibrarySidebar;
