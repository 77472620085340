/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { batch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { isNil, omit, omitBy, pick, reject } from "lodash";
import { t } from "i18n";
import { navigate } from "utils/routes.utils";
import { transform } from "utils";
import { COLLECTIONS_TABS_PATH } from "pages/collections/routes";
import { ViewportState } from "store/viewport";
import { CollectionRouteData } from "api/route-data/collection-route-data";
import { Collection, CollectionWrapper, listCollectionItems } from "api/collection-items";
import * as storyCollectionAPI from "api/story-collection";
import * as pinnedCollectionsAPI from "api/pinned-collections";
import { notificationError, notificationSuccess } from "containers/page/actions";

export const collectionFetchLimit = 12;
export const defaultTab = "pinned";
const pinnedCollectionFilters = ["q", "limit", "templates"];
const defaultFilters = {
  "content-type": "collection",
  fields: "updated-at,created-at,publish-at,data-source,is-pinned"
};
const isScheduledFilters = { "is-scheduled": true, "is-published": false };

export interface PartialAppState {
  config: CollectionRouteData;
  features: { hasCollectionEditPermission: boolean };
  collectionsDashboard: State;
  viewport: ViewportState;
}

export type Template = { id: string; label: string };

export interface Filters {
  templates?: Template[];
  limit?: number;
  q?: string;
  sort: string[];
  "is-scheduled"?: boolean;
}

export type ActiveFilters = Array<keyof Filters>;
export type Tab = "pinned" | "all" | "scheduled";

export interface State {
  collections: Collection[];
  filters: Filters;
  filtersMatchCount: number;
  ui: {
    currentTab?: Tab;
    currentlyDeleting: number | null;
    showDeleteModal: boolean;
    showLoader: boolean;
    showFiltersInspector: boolean;
    activeFilters: ActiveFilters;
  };
}

export const initialState: State = {
  collections: [],
  filters: {
    sort: ["updated-at:desc"],
    limit: collectionFetchLimit
  },
  filtersMatchCount: 0,
  ui: {
    currentlyDeleting: null,
    showDeleteModal: false,
    showLoader: false,
    showFiltersInspector: false,
    activeFilters: []
  }
};

function resetKeys(currentObj, initialObj, keys) {
  return keys.reduce((result, key) => {
    const initialValue = initialObj[key];
    return initialValue ? { ...result, [key]: initialValue } : omit(result, key);
  }, currentObj);
}

const { reducer, actions } = createSlice({
  name: "collections-dashboard",
  initialState,
  reducers: {
    replaceCollections: (state: State, action: PayloadAction<Collection[]>) => {
      state.collections = action.payload;
    },
    insertCollection: (state: State, action: PayloadAction<{ collection: Collection; index: number }>) => {
      const { collection, index } = action.payload;
      state.collections = [...state.collections.slice(0, index), collection, ...state.collections.slice(index)];
      state.filtersMatchCount = ++state.filtersMatchCount;
    },
    updateCollection: (state: State, action: PayloadAction<{ id: number; change: Partial<Collection> }>) => {
      const { id, change } = action.payload;
      state.collections = state.collections.map((collection) =>
        collection.id === id ? { ...collection, ...change } : collection
      );
    },
    removeCollection: (state: State, action: PayloadAction<number>) => {
      state.collections = reject(state.collections, (collection) => collection.id === action.payload);
      state.filtersMatchCount = --state.filtersMatchCount;
    },
    updateFilters: (state: State, action: PayloadAction<Partial<Filters>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters: (state: State, action: PayloadAction<string[]>) => {
      const filters = action.payload;
      state.filters = resetKeys(state.filters, initialState.filters, filters);
    },
    resetState: () => initialState,
    setFiltersMatchCount: (state: State, action: PayloadAction<number>) => {
      state.filtersMatchCount = action.payload;
    },
    setCurrentTab: (state: State, action: PayloadAction<Tab>) => {
      state.ui.currentTab = action.payload;
    },
    setCurrentlyDeleting: (state: State, action: PayloadAction<number | null>) => {
      state.ui.currentlyDeleting = action.payload;
    },
    setShowLoader: (state: State, action: PayloadAction<boolean>) => {
      state.ui.showLoader = action.payload;
    },
    setShowDeleteModal: (state: State, action: PayloadAction<boolean>) => {
      state.ui.showDeleteModal = action.payload;
    },
    setShowFiltersInspector: (state: State, action: PayloadAction<boolean>) => {
      state.ui.showFiltersInspector = action.payload;
    },
    addActiveFilters: (state: State, action: PayloadAction<ActiveFilters>) => {
      state.ui.activeFilters = action.payload;
    },
    removeActiveFilter: (state: State, action: PayloadAction<string>) => {
      state.ui.activeFilters = reject(state.ui.activeFilters, (activeFilter) => activeFilter === action.payload);
    },
    clearActiveFilters: (state: State) => {
      state.filters = resetKeys(state.filters, initialState.filters, state.ui.activeFilters);
      state.ui.activeFilters = [];
    }
  }
});

export const {
  replaceCollections,
  insertCollection,
  updateCollection,
  removeCollection,
  updateFilters,
  resetFilters,
  resetState,
  setFiltersMatchCount,
  setCurrentTab,
  setCurrentlyDeleting,
  setShowLoader,
  setShowDeleteModal,
  setShowFiltersInspector,
  addActiveFilters,
  removeActiveFilter,
  clearActiveFilters
} = actions;

async function fetchUsingPinnedCollectionsAPI(filters: Filters) {
  const transformedFilters = transform(filters, {
    templates: (key, value) => [key, value && value.map((template) => template.id).join(",")]
  });
  const sanitizedFilters = omitBy(pick(transformedFilters, pinnedCollectionFilters), isNil);
  const response = await pinnedCollectionsAPI.getPinnedCollections(sanitizedFilters);
  const collections = response.data;
  const filtersMatchCount = response.hits;
  return { collections, filtersMatchCount };
}

async function fetchUsingCollectionItemsAPI(filters: Filters) {
  const transformedFilters = transform(filters, {
    templates: (key, value) => ["template", value && value.map((template) => template.id).join(",")],
    sort: (key, value) => [key, value && value.join(",")]
  });
  const sanitizedFilters = omitBy(transformedFilters, isNil);
  const response = await listCollectionItems(sanitizedFilters);
  const collections = response.items.map((item) => (item as CollectionWrapper).collection);
  const filtersMatchCount = response.hits;
  return { collections, filtersMatchCount };
}

const tabToHandler = {
  pinned: async (filters) => await fetchUsingPinnedCollectionsAPI(filters),
  all: async (filters) => await fetchUsingCollectionItemsAPI({ ...filters, ...defaultFilters }),
  scheduled: async (filters) =>
    await fetchUsingCollectionItemsAPI({ ...filters, ...defaultFilters, ...isScheduledFilters })
};

type FetchCollectionsOptions = { shouldClearCollections?: boolean; showLoader?: boolean };

export function fetchCollections({ shouldClearCollections, showLoader }: FetchCollectionsOptions) {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>, getState: () => PartialAppState) => {
    try {
      const {
        filters,
        ui: { currentTab }
      } = getState().collectionsDashboard;

      shouldClearCollections && dispatch(replaceCollections([]));
      showLoader && dispatch(setShowLoader(true));

      const handler = tabToHandler[currentTab || defaultTab];

      const response = await handler(filters);

      batch(() => {
        dispatch(replaceCollections(response.collections));
        dispatch(setFiltersMatchCount(response.filtersMatchCount));
        dispatch(setShowLoader(false));
      });
    } catch (e) {
      batch(() => {
        dispatch(setShowLoader(false));
        dispatch(notificationError(t("collections.dashboard.messages.load-fail")));
      });
    }
  };
}

function switchTab(tab) {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
    batch(() => {
      dispatch(setCurrentTab(tab));
      dispatch(navigate(COLLECTIONS_TABS_PATH, { tab: tab }));
      dispatch(updateFilters({ limit: collectionFetchLimit }));
    });
    await dispatch(fetchCollections({ showLoader: true, shouldClearCollections: true }));
  };
}

export function renderTab(newTab) {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>, getState: () => PartialAppState) => {
    const shouldNotRenderTab = newTab ? getState().collectionsDashboard.ui.currentTab === newTab : false;
    if (shouldNotRenderTab) return;
    await dispatch(switchTab(newTab || defaultTab));
    const isPinnedCollectionsEmptyOnFirstLoad = !getState().collectionsDashboard.collections.length && !newTab;
    if (isPinnedCollectionsEmptyOnFirstLoad) await dispatch(switchTab("all"));
  };
}

export function pinCollection(collectionId: number) {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
    try {
      dispatch(updateCollection({ id: collectionId, change: { "is-pinned": true } }));
      await pinnedCollectionsAPI.pinCollection(collectionId);
    } catch (error) {
      batch(() => {
        dispatch(updateCollection({ id: collectionId, change: { "is-pinned": false } }));
        dispatch(notificationError(t("collections.dashboard.messages.pin-fail")));
      });
    }
  };
}

export function unpinCollection(collectionId: number) {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>, getState: () => PartialAppState) => {
    const {
      collections,
      ui: { currentTab }
    } = getState().collectionsDashboard;

    const index = collections.findIndex((collection) => collection.id === collectionId);
    const collection = collections[index];
    try {
      currentTab === "pinned"
        ? dispatch(removeCollection(collectionId))
        : dispatch(updateCollection({ id: collectionId, change: { "is-pinned": false } }));
      await pinnedCollectionsAPI.unpinCollection(collectionId);
    } catch (error) {
      batch(() => {
        currentTab === "pinned"
          ? dispatch(insertCollection({ index, collection }))
          : dispatch(updateCollection({ id: collectionId, change: { "is-pinned": true } }));
        dispatch(notificationError(t("collections.dashboard.messages.unpin-fail")));
      });
    }
  };
}

export function deleteCollection() {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>, getState: () => PartialAppState) => {
    try {
      const collectionId = getState().collectionsDashboard.ui.currentlyDeleting;
      await storyCollectionAPI.deleteCollection(collectionId!);
      batch(() => {
        dispatch(setCurrentlyDeleting(null));
        dispatch(setShowDeleteModal(false));
        dispatch(removeCollection(collectionId!));
        dispatch(notificationSuccess(t("collections.delete_success")));
      });
    } catch (error) {
      dispatch(notificationError(t("collections.delete_failure")));
    }
  };
}

export default reducer;
