/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect, useRef } from "react";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";
import { ErrorType, Error, PartialAppState, hideErrorPage } from "store/error-page";
import Spacer from "components/spacer/spacer";
import ExclamationCircle from "components/icons/exclamation-circle";
import styles from "./error-page.module.css";
import Button from "components/button/button";
import { navigate, refresh } from "utils/routes.utils";
import ExclamationTriangle from "components/icons/exclamation-triangle";

interface GlobalAppCrashActionsProps {
  goToHomePage: () => void;
}

export const GlobalAppCrashActions: React.FC<GlobalAppCrashActionsProps> = ({ goToHomePage }) => {
  return (
    <div data-test-id="global-app-crash-container">
      <Spacer token={"l"} align={"vertical"} />
      <Button type="primary" onClick={refresh} testId="global-app-crash-refresh-btn">
        {t("error-page.global-app-crash.actions.refresh")} <Spacer token="xxs"></Spacer>
      </Button>
      <Spacer token="m"></Spacer>
      <Button type="secondary" onClick={goToHomePage} testId="global-app-crash-go-to-home-btn">
        {t("error-page.global-app-crash.actions.go-to-home")} <Spacer token="xxs"></Spacer>
      </Button>
    </div>
  );
};

interface StateProps {
  error: Error;
}

interface DispatchProps {
  hideErrorPage: () => void;
  goToHomePage: () => void;
}

type ErrorPageProps = StateProps & DispatchProps;

export const WrapErrorPage: React.FC<ErrorPageProps> = ({ children, error, hideErrorPage, goToHomePage }) => {
  const exclamationCircleIcon = <ExclamationCircle width={"48"} height={"48"} color={"var(--mono-3)"} />;
  const exclamationTriangleIcon = <ExclamationTriangle width={"70"} height={"70"} color={"var(--mono-3)"} />;

  const errorTypes = useRef({
    [ErrorType.Generic]: [exclamationCircleIcon, t("error-page.generic.type"), t("error-page.generic.message")],
    [ErrorType.NotFound]: [exclamationCircleIcon, t("error-page.not-found.type"), t("error-page.not-found.message")],
    [ErrorType.Unauthorized]: [
      exclamationCircleIcon,
      t("error-page.unauthorized.type"),
      t("error-page.unauthorized.message")
    ],
    [ErrorType.FeatureNotSetup]: [
      exclamationCircleIcon,
      t("error-page.feature-not-setup.type"),
      t("error-page.feature-not-setup.message")
    ],
    [ErrorType.GlobalAppCrash]: [
      exclamationTriangleIcon,
      t("error-page.global-app-crash.type"),
      t("error-page.global-app-crash.message"),
      <GlobalAppCrashActions goToHomePage={goToHomePage} />
    ]
  });

  useEffect(() => hideErrorPage, [hideErrorPage]);

  if (error) {
    const [errorIcon, errorType, errorMessage, errorActions] = errorTypes.current[error.type];
    return (
      <div className={styles["error-page-container"]} data-test-id="error-page-container">
        {errorIcon}
        <Spacer token={"s"} align={"vertical"} />
        <div className={styles["error-page-type"]}>{errorType}</div>
        <Spacer token={"s"} align={"vertical"} />
        <div className={styles["error-page-message"]}>{error.customMessage || errorMessage}</div>
        {errorActions}
      </div>
    );
  }
  return <>{children}</>;
};

const mapStateToProps = (state: PartialAppState): StateProps => ({
  error: state.errorPage.error
});

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => ({
  hideErrorPage: () => dispatch(hideErrorPage()),
  goToHomePage: () => dispatch(navigate("/workspace/all"))
});

export default connect(mapStateToProps, mapDispatchToProps)(WrapErrorPage);
