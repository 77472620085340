/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Inspector from "components/inspector/inspector";
import { connect } from "react-redux";
import { PartialAppState, setMailingList, setIsFormModified } from "store/form/editor";
import { EmailListType } from "store/route-data";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useEffect, useState } from "react";
import { FORMS_EDITOR_PATH_EDIT } from "pages/forms/routes";
import { navigateFn } from "utils/routes.utils";
import EmailList from "pages/forms/email-list/email-list";
import isEqual from "lodash/isEqual";
import { t } from "i18n";
import { NOTIFICATION_SUCCESS } from "containers/page/actions";

interface StateProps {
  mailingList: EmailListType;
  defaultMailingList: EmailListType;
}

interface DispatchProps {
  closeInspector: (formId: string) => void;
  setMailingList: (formId: string, mailingList: EmailListType, closeInspector?: boolean) => void;
}

interface OwnProps {
  isOpen: boolean;
  formId: string;
  isNewForm: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

export const FormsInspector: React.FC<Props> = ({
  isOpen,
  closeInspector,
  mailingList,
  setMailingList,
  formId,
  isNewForm,
  defaultMailingList
}) => {
  const [invalidEmailList, setInvalidEmailList] = useState<EmailListType>([]);
  const [allEmailList, setAllEmailList] = useState<EmailListType>(mailingList);
  const [currentInput, setCurrentInput] = useState<string>("");

  useEffect(() => {
    if (isNewForm && defaultMailingList.length > 0) {
      setMailingList(formId, defaultMailingList);
      setAllEmailList(defaultMailingList);
    }
  }, [isNewForm, defaultMailingList, setMailingList, formId]);

  const handleOnChange = ({ all, invalid }) => {
    setAllEmailList(all);
    setInvalidEmailList(invalid);
  };

  return (
    <Inspector
      title={t("forms.editor.inspector.mailing-list.title")}
      onClose={() => {
        setAllEmailList(mailingList);
        closeInspector(formId);
      }}
      isActive={isOpen}
      onActionButtonClick={() => setMailingList(formId, allEmailList, true)}
      showActionButton={true}
      actionButtonLabel={t("common.done")}
      isActionButtonDisabled={
        invalidEmailList.length > 0 || currentInput.length > 0 || isEqual(allEmailList, mailingList)
      }>
      {isOpen && (
        <EmailList
          label={t("forms.editor.inspector.mailing-list.label")}
          hint={t("forms.editor.inspector.mailing-list.description")}
          value={allEmailList}
          onChange={handleOnChange}
          onInputChange={(input) => setCurrentInput(input)}
        />
      )}
    </Inspector>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => ({
  mailingList: state.forms.editor.currentForm.mailingList as EmailListType,
  defaultMailingList: state.config.forms.defaultMailingList
});

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  const navigate = navigateFn(dispatch);
  return {
    closeInspector: (formId: string) => {
      navigate(FORMS_EDITOR_PATH_EDIT, { formId: formId });
    },
    setMailingList: (formId: string, mailingList, closeInspector?: boolean) => {
      dispatch(setMailingList(mailingList));
      dispatch(setIsFormModified(true));
      if (closeInspector) {
        dispatch({
          type: NOTIFICATION_SUCCESS,
          payload: { message: t("forms.editor.inspector.mailing-list.mailing_list_changed_message") }
        });
        navigate(FORMS_EDITOR_PATH_EDIT, { formId: formId });
      }
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormsInspector);
