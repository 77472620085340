/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";

const Clone = ({ width = "20", height = "20", color = "currentColor" }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="2" fill={color} />
    <g clip-path="url(#clip0_3027_7220)">
      <path
        d="M5.91667 17C5.59583 17 5.32118 16.884 5.09271 16.652C4.86424 16.4199 4.75 16.141 4.75 15.8151V7.52087H5.91667V15.8151H12.3333V17H5.91667Z"
        fill="white"
      />
      <path
        d="M11.1758 12.1L14.2656 8.88656L13.4727 8.06188L11.1621 10.4648L10.0137 9.27047L9.23438 10.0809L11.1758 12.1ZM8.46875 14.375C8.16797 14.375 7.91048 14.2636 7.69629 14.0409C7.4821 13.8181 7.375 13.5503 7.375 13.2375V4.1375C7.375 3.82469 7.4821 3.5569 7.69629 3.33414C7.91048 3.11138 8.16797 3 8.46875 3H12.8438L16.125 6.4125V13.2375C16.125 13.5503 16.0179 13.8181 15.8037 14.0409C15.5895 14.2636 15.332 14.375 15.0312 14.375H8.46875ZM12.2969 6.98125V4.1375H8.46875V13.2375H15.0312V6.98125H12.2969Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3027_7220">
        <rect width="14" height="14" fill="white" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </svg>
);

export default Clone;
