/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const ClonedToPrint = ({ width = "22", height = "20", color = "currentColor" }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="2" fill={color} />
    <path
      d="M7 14.5C6.725 14.5 6.48958 14.4021 6.29375 14.2063C6.09792 14.0104 6 13.775 6 13.5V5.5L6.8375 6.3375L7.6625 5.5L8.5 6.3375L9.3375 5.5L10.1625 6.3375L11 5.5L11.8375 6.3375L12.6625 5.5L13.5 6.3375L14.3375 5.5L15.1625 6.3375L16 5.5V13.5C16 13.775 15.9021 14.0104 15.7063 14.2063C15.5104 14.4021 15.275 14.5 15 14.5H7ZM7 13.5H10.5V10.5H7V13.5ZM11.5 13.5H15V12.5H11.5V13.5ZM11.5 11.5H15V10.5H11.5V11.5ZM7 9.5H15V8H7V9.5Z"
      fill="white"
    />
    <circle cx="5" cy="10" r="3" fill={color} />
    <g clip-path="url(#clip0_3027_7227)">
      <rect x="3" y="8" width="4" height="4" rx="2" fill={color} />
      <path
        d="M5.80204 10.1666H3.66663V9.83329H5.80204L5.02427 8.89996L5.22218 8.66663L6.33329 9.99996L5.22218 11.3333L5.02427 11.1L5.80204 10.1666Z"
        fill="white"
        stroke="white"
        stroke-width="0.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_3027_7227">
        <rect x="3" y="8" width="4" height="4" rx="2" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ClonedToPrint;
