/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Tag } from "api/tag";
import { t } from "i18n";
import ReactTable, { RowInfo } from "react-table";
import { formattedDate } from "utils";
import { State } from "../../state";
import StoryCount from "../story-count/story-count";

interface TagsListProps {
  tags: Tag[];
  editTag: (tag: Tag) => void;
  uiTags: State["ui"]["tags"];
  isLoading: boolean;
}

const TagsList: React.SFC<TagsListProps> = ({ tags, editTag, uiTags, isLoading }) => {
  return (
    <div data-test-d="tags-react-table">
      <ReactTable
        showPaginationTop={false}
        showPaginationBottom={false}
        showPagination={false}
        loading={false}
        showPageSizeOptions={false}
        sortable={false}
        pageSize={tags.length > 20 ? 20 : tags.length}
        noDataText={isLoading ? t("tags.messages.loading-list") : t("tags.messages.tags-not-found")}
        data={tags}
        columns={[
          {
            Header: t("tags.table.name-column-label"),
            accessor: "name",
            style: { padding: "0 10px" },
            headerStyle: { padding: "0 10px" }
          },
          {
            Header: t("tags.table.slug-column-label"),
            accessor: "slug",
            style: { padding: "0 10px" },
            headerStyle: { padding: "0 10px" }
          },
          {
            Header: t("tags.table.story-count-column-label"),
            id: "storyCount",
            accessor: (tag: Tag) => {
              const uiTag = uiTags[tag.id];
              return uiTag && <StoryCount uiTag={uiTag} />;
            },
            maxWidth: 100,
            style: { padding: "0 10px" },
            headerStyle: { padding: "0 10px" }
          },
          {
            Header: t("tags.table.created-at-column-label"),
            id: "createdAt",
            accessor: (tag: Tag) => formattedDate(tag["created-at"], "dd MMM yyyy"),
            maxWidth: 100,
            style: { padding: "0 10px" },
            headerStyle: { padding: "0 10px" }
          },
          {
            Header: t("tags.table.created-by-column-label"),
            accessor: "member-name",
            maxWidth: 150,
            style: { padding: "0 10px" },
            headerStyle: { padding: "0 10px" }
          }
        ]}
        className="tags-table"
        LoadingComponent={() => null}
        getTrProps={(state: any, rowInfo: RowInfo) => ({
          onClick: () => editTag(rowInfo.original as Tag)
        })}
      />
    </div>
  );
};

export default TagsList;
