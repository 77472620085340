/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import GenericIntegration from "../generic-integration";
import { IntegrationConfig } from "api/integrations";
import Button from "components/button/button";
import { IntegrationType, PartialAppState } from "pages/settings/pages/integrations/state";
import { connect } from "react-redux";
import { navigate } from "utils/routes.utils";
import { INTEGRATION_SETTINGS } from "pages/settings/pages/integrations/routes";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { t } from "i18n";

interface DispatchProps {
  openIntegrationSettings: (type: IntegrationType) => void;
}

interface OwnProps {
  integration: IntegrationConfig;
  updateIntegrationField: (key: string, value: Object) => void;
  readOnly?: boolean;
  errors: Error | null;
}

type Props = DispatchProps & OwnProps;

const DefaultProviders: React.FC<Props> = (props) => {
  const { openIntegrationSettings } = props;
  return (
    <React.Fragment>
      <GenericIntegration {...props} />
      <Button
        onClick={() => openIntegrationSettings(IntegrationType.PUSH_NOTIFICATION)}
        type="link"
        data-test-id="integration-settings-btn">
        {t("integrations.push-notification.change-default-providers")}
      </Button>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    openIntegrationSettings: (type: IntegrationType) => dispatch(navigate(INTEGRATION_SETTINGS, { type }))
  };
};

export default connect(() => {}, mapDispatchToProps)(DefaultProviders);

export { DefaultProviders };
