/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

// import * as actions from "./actions";

const initialState = {
  ui: {
    settings: {}
  }
};

const advancedSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default advancedSettingsReducer;
