/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { navigate } from "utils/routes.utils";
import { pick, get, isEmpty } from "lodash";
import { t } from "i18n";
import classnames from "classnames/bind";
import { actions } from "pages/story-editor/actions";
import { STORY_EDITOR_ALTERNATIVES_PATH, STORY_EDITOR_HERO_IMAGE_GALLERY_PATH } from "pages/story-editor/routes";
import { ImageId, StoryId } from "api/primitive-types";
import { Errors } from "pages/story-editor/state";
import { Image as ImageType } from "api/search-media-image";
import { CustomField, Fields } from "api/route-data/story-route-data";
import { Story } from "api/story";
import { getErrorMessage } from "pages/story-editor/utils";
import DoubleStar from "components/icons/double-star";
import Image from "components/icons/story-elements/image";
import Headline from "components/icons/headline";
import SubTitle from "components/icons/sub-title";
import ExternalUrl from "components/icons/external-url";
import TextArea from "components/text-area/text-area";
import TextField from "components/text-field/text-field";
import ReviewFields from "pages/story-editor/header-card/review-header-fields";
import CustomMetadata from "pages/story-editor/components/custom-metadata/custom-metadata";
import HeroImage from "pages/story-editor/components/hero-image/hero-image";
import TextLimitIndicator from "components/text-limit-indicator/text-limit-indicator";
import styles from "./header-card.module.css";
import { getTemplateCustomMetadata } from "./utils";
import { fetchAllStoryTemplates } from "pages/settings/pages/configure/action-creator";
import { AiGenerateButton } from "../components/ai-generate-button/ai-generate-button";
import { AllowedStoryFields } from "api/ai";
import AiSuggestions from "pages/story-editor/components/ai-suggestions/ai-suggestions";

const cx = classnames.bind(styles);

const defaultHeadlineLimit = 66;
const defaultSubheadlineLimit = 200;

interface StateProps {
  story: Story;
  storyTemplateFields: Fields;
  customMetadataFields: CustomField[];
  errors: Errors;
  isStoryLocked: boolean;
  isAlternativesEnabled: boolean;
  isFocusPointMandatory: boolean;
  storyTemplates: {};
  showGenerateHeadlineBtn: boolean;
  showGenerateSubHeadlineBtn: boolean;
}

interface DispatchProps {
  loadAllStoryTemplates: () => void;
  onChangeOfStory(key: string, value: any): void;
  openAlternativesInspector(storyId): void;
  updateStoryHeroImageData(image: ImageType): void;
  deleteStoryHeroImage(): void;
  updateMetadata(key: string, value: any): void;
  openPhotoEditor(image: ImageType, imageAs: { type: string; subtype?: string }, imageId: ImageId): void;
  openMediaGallery(storyId): void;
}

interface OwnProps {
  timelineOpenCls: any;
  storyInspectorOpenCls: any;
  previewOpenCls: any;
  seoScoreOpenCls: any;
  readOnlyStoryVersionId: StoryId;
}

type Props = StateProps & DispatchProps & OwnProps;

export const HeaderCard: React.FC<Props> = React.memo(
  ({
    story,
    storyTemplateFields,
    customMetadataFields,
    onChangeOfStory,
    openMediaGallery,
    updateStoryHeroImageData,
    deleteStoryHeroImage,
    updateMetadata,
    errors = {},
    isStoryLocked,
    readOnlyStoryVersionId,
    openPhotoEditor,
    openAlternativesInspector,
    timelineOpenCls,
    previewOpenCls,
    storyInspectorOpenCls,
    seoScoreOpenCls,
    isFocusPointMandatory,
    loadAllStoryTemplates,
    showGenerateHeadlineBtn,
    storyTemplates,
    showGenerateSubHeadlineBtn
  }) => {
    useEffect(() => {
      loadAllStoryTemplates();
    }, [loadAllStoryTemplates]);

    const storyId = story["story-content-id"];
    const storyTemplate = story["story-template"];
    const headlineLimit = get(storyTemplateFields, ["headline", "validations", "hard-limit"], defaultHeadlineLimit);
    const subheadlineLimit = get(
      storyTemplateFields,
      ["subheadline", "validations", "hard-limit"],
      defaultSubheadlineLimit
    );

    const [generatingHeadline, setGeneratingHeadline] = useState(false);
    const [generatingSubHeadline, setGeneratingSubHeadline] = useState(false);

    const metadataFields = getTemplateCustomMetadata(customMetadataFields, storyTemplate, storyTemplates);

    const customMetadata =
      story.metadata &&
      pick(
        story.metadata,
        metadataFields.map((val) => val["key-name"])
      );
    const handleHeadlineChange = useCallback((headline) => onChangeOfStory("headline", headline), [onChangeOfStory]);
    const handleSubHeadlineChange = useCallback((subheadline) => onChangeOfStory("subheadline", subheadline), [
      onChangeOfStory
    ]);
    const handleReferenceUrlChange = useCallback((value) => updateMetadata("reference-url", value), [updateMetadata]);
    const isReadOnly = !!(isStoryLocked || readOnlyStoryVersionId);

    const storyHeader = cx("story-header", timelineOpenCls, previewOpenCls, seoScoreOpenCls, storyInspectorOpenCls);

    const [showHeadlineAiSuggestions, setShowHeadlineAiSuggestions] = useState(false);
    const [showSubHeadlineAiSuggestions, setSubShowHeadlineAiSuggestions] = useState(false);

    return (
      <section id="story-editor__header" className={storyHeader} data-test-id="editor-header">
        {isReadOnly && <div className={styles["block"]} />}
        <AiSuggestions
          characterLimit={headlineLimit}
          storyFieldClassName={cx("story-header__item", "story-header__item--ai-suggestions")}
          isOpen={showHeadlineAiSuggestions}
          onClose={() => setShowHeadlineAiSuggestions(false)}
          storyField={AllowedStoryFields.HEADLINE}
          onSuggestionSelect={(suggestion: string) => handleHeadlineChange(suggestion)}
          currentFieldValue={story.headline}
          onGeneratingSuggestion={(generatingSuggestion: boolean) => setGeneratingHeadline(generatingSuggestion)}
          onSuggestionsChange={(suggestions: string[]) => {
            if (isEmpty(story.headline) && suggestions.length > 0) {
              handleHeadlineChange(suggestions[0]);
            }
          }}>
          <div
            className={cx("story-field-label", "headline", `${errors["headline"] ? "has-error" : ""}`)}
            data-test-id="story-field-label-headline">
            <div className={cx("title-icon", `${errors["headline"] ? "has-error" : ""}`)} data-test-id="title-icon">
              <Headline color="currentColor" />
            </div>
            <TextLimitIndicator text={story.headline} limit={headlineLimit} />
            {showGenerateHeadlineBtn && (
              <div className={cx("ai-generate-content")}>
                <AiGenerateButton
                  classname={cx("ai-generate-content__btn")}
                  disabled={isReadOnly}
                  loading={generatingHeadline}
                  onClick={() => setShowHeadlineAiSuggestions(!showHeadlineAiSuggestions)}
                />
              </div>
            )}
          </div>

          <div
            className={cx("story-title", "story-title-text-area", isReadOnly ? "story-title-text-area--readonly" : "")}
            data-test-id="story-title-text-area">
            <TextArea
              classname={styles["story-title__textarea"]}
              onChange={handleHeadlineChange}
              placeholder={t("story-editor.header-card.title")}
              value={story.headline}
              errorMessage={getErrorMessage(errors["headline"])}
              variant="plain"
              size="jumbo"
              disabled={isReadOnly}
            />
          </div>
        </AiSuggestions>

        <AiSuggestions
          storyFieldClassName={cx("story-header__item", "story-header__item--ai-suggestions")}
          isOpen={showSubHeadlineAiSuggestions}
          onClose={() => setSubShowHeadlineAiSuggestions(false)}
          storyField={AllowedStoryFields.SUBHEADLINE}
          characterLimit={subheadlineLimit}
          onSuggestionSelect={(suggestion: string) => handleSubHeadlineChange(suggestion)}
          currentFieldValue={story.subheadline}
          onGeneratingSuggestion={(generatingSuggestion: boolean) => setGeneratingSubHeadline(generatingSuggestion)}
          onSuggestionsChange={(suggestions: string[]) => {
            if (isEmpty(story.subheadline) && suggestions.length > 0) {
              handleSubHeadlineChange(suggestions[0]);
            }
          }}>
          {!storyTemplateFields.subheadline.validations["hidden"] && (
            <div
              className={cx("story-field-label", "subheadline", `${errors["subheadline"] ? "has-error" : ""}`)}
              data-test-id="story-field-label-subheadline">
              <div className={cx("title-icon", `${errors["subheadline"] ? "has-error" : ""}`)}>
                <SubTitle color="currentColor" />
              </div>
              <TextLimitIndicator text={story.subheadline} limit={subheadlineLimit} />
              {showGenerateSubHeadlineBtn && (
                <div className={cx("ai-generate-content")}>
                  <AiGenerateButton
                    classname={cx("ai-generate-content__btn")}
                    disabled={isReadOnly}
                    loading={generatingSubHeadline}
                    onClick={() => setSubShowHeadlineAiSuggestions(!showSubHeadlineAiSuggestions)}
                  />
                </div>
              )}
            </div>
          )}
          {!storyTemplateFields.subheadline.validations["hidden"] && (
            <div
              className={cx("story-subtitle", { "story-subtitle--readonly": isReadOnly })}
              data-test-id="story-subtitle">
              <TextArea
                classname={styles["story-subtitle__textarea"]}
                onChange={handleSubHeadlineChange}
                placeholder={t("story-editor.header-card.subtitle")}
                value={story.subheadline}
                errorMessage={getErrorMessage(errors["subheadline"])}
                variant="plain"
                size="extra-large"
                disabled={isReadOnly}
              />
            </div>
          )}
        </AiSuggestions>
        <div className={cx("story-header__item")}>
          {!storyTemplateFields["hero-image-s3-key"].validations["hidden"] && (
            <div
              data-test-id="story-field-label-hero-image"
              className={cx(
                "story-field-label",
                "hero-image",
                `${errors["hero-image"] && errors["hero-image"]["key"] ? "has-error" : ""}`
              )}>
              <Image color="currentColor" />
            </div>
          )}
          {!storyTemplateFields["hero-image-s3-key"].validations["hidden"] && (
            <HeroImage
              image={story["hero-image"]}
              openMediaGallery={() => openMediaGallery(storyId)}
              updateImageData={updateStoryHeroImageData}
              deleteImage={deleteStoryHeroImage}
              errors={errors && errors["hero-image"]}
              openPhotoEditor={openPhotoEditor}
              onSetAlternativesButtonClick={() => openAlternativesInspector(storyId)}
              readOnly={isReadOnly}
              isFocusPointMandatory={isFocusPointMandatory}
              story={story}
            />
          )}

          {story["story-template"] === "review" && (
            <React.Fragment>
              <div
                className={cx(
                  "story-field-label",
                  "review",
                  `${errors["review-rating"] || errors["review-title"] ? "has-error" : ""}`
                )}
                data-test-id="story-field-label-review">
                <div
                  className={cx(
                    "title-icon",
                    `${errors["review-rating"] || errors["review-title"] ? "has-error" : ""}`
                  )}>
                  <DoubleStar color="currentColor" />
                </div>
              </div>
              <ReviewFields reviewData={story.metadata} updateMetadata={updateMetadata} errors={errors} />
            </React.Fragment>
          )}

          {story["story-template"] === "news-elsewhere" && (
            <React.Fragment>
              <div
                className={cx("story-field-label", "reference-url", `${errors["reference-url"] ? "has-error" : ""}`)}
                data-test-id="story-field-label-reference-url">
                <div className={cx("title-icon", `${errors["reference-url"] ? "has-error" : ""}`)}>
                  <ExternalUrl color="currentColor" />
                </div>
              </div>
              <div className={styles["story-reference-url-container"]} data-test-id="story-reference-url-container">
                <TextField
                  label={t("story-editor.header-card.reference-url")}
                  onChange={handleReferenceUrlChange}
                  placeholder={t("story-editor.header-card.url")}
                  value={story.metadata && story.metadata["reference-url"]}
                  errorMessage={getErrorMessage(errors["reference-url"])}
                />
              </div>
            </React.Fragment>
          )}

          <CustomMetadata
            customMetadataFields={metadataFields}
            customMetadata={customMetadata}
            updateMetadata={updateMetadata}
          />
        </div>
      </section>
    );
  }
);

function mapStateToProps(state): StateProps {
  return {
    story: state.storyEditor.story as Story,
    storyTemplateFields: state.storyEditor.storyTemplateFields,
    customMetadataFields: state.config.customMetadataFields,
    errors: state.storyEditor.ui.errors.editor,
    isStoryLocked: state.storyEditor.ui.isStoryLocked,
    isAlternativesEnabled: state.features.isAlternativeHeadlineAndImageEnabled,
    isFocusPointMandatory: get(
      state.storyEditor.storyTemplateFields,
      ["hero-image-focus-point", "validations", "mandatory"],
      false
    ),
    storyTemplates: state.settingsPage.customStoryTemplates,
    showGenerateHeadlineBtn: get(state.config, ["ai-content-generation", "story", AllowedStoryFields.HEADLINE], false),
    showGenerateSubHeadlineBtn: get(
      state.config,
      ["ai-content-generation", "story", AllowedStoryFields.SUBHEADLINE],
      false
    )
  };
}

function mapDispatchToProps(dispatch): DispatchProps {
  return {
    onChangeOfStory: (key, value) => dispatch({ type: actions.UPDATE_STORY, payload: { key, value } }),
    openMediaGallery: (storyId) => dispatch(navigate(STORY_EDITOR_HERO_IMAGE_GALLERY_PATH, { id: storyId })),
    openAlternativesInspector: (storyId) => dispatch(navigate(STORY_EDITOR_ALTERNATIVES_PATH, { id: storyId })),
    updateStoryHeroImageData: (image) => dispatch({ type: actions.UPDATE_HERO_IMAGE_DATA, payload: { image } }),
    deleteStoryHeroImage: () => dispatch({ type: actions.DELETE_HERO_IMAGE }),
    updateMetadata: (key, value) => dispatch({ type: actions.UPDATE_STORY_METADATA, payload: { key, value } }),
    openPhotoEditor: (image, imageAs, imageId = "") =>
      dispatch({ type: actions.OPEN_PHOTO_EDITOR, payload: { image, imageAs, imageId } }),
    loadAllStoryTemplates: () => dispatch(fetchAllStoryTemplates())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderCard);
