/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

import { StoryElementId } from "api/primitive-types";

import { StoryElements, StoryElementsUI } from "api/story";
import StoryElementDisplay from "./story-element-display";

interface Props {
  storyElementIds: StoryElementId[];
  allStoryElements: StoryElements;
  storyElementsUI?: StoryElementsUI;
}

const CardDisplay: React.SFC<Props> = ({ storyElementIds, allStoryElements, storyElementsUI }) => (
  <React.Fragment>
    {storyElementIds.map((id) => (
      <StoryElementDisplay
        storyElement={allStoryElements[id]}
        allStoryElements={allStoryElements}
        storyElementUI={storyElementsUI && storyElementsUI[id]}
      />
    ))}
  </React.Fragment>
);

export default CardDisplay;
