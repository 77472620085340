/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { compose, AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { goBack } from "connected-react-router";
import { t } from "i18n";
import classnames from "classnames/bind";
import {
  STORY_EDITOR_PATH,
  STORY_EDITOR_ALTERNATIVES_IMAGE_EDIT_PATH,
  STORY_EDITOR_CARD_SHARE,
  STORY_EDITOR_IMAGE_INSPECTOR_PATH
} from "pages/story-editor/routes";
import { actions } from "pages/story-editor/actions";
import {
  setSelectedImageAction,
  getTemplateValueAction,
  onChangeOfTemplateAction,
  savePollAction,
  setMultiSelectedMediaInspectorAction,
  setMediaForMultiSelectAction,
  editInspectorImageAction,
  clearStoryEditorMediaAction
} from "pages/story-editor/async-action-creators";
import { navigateFn, Navigate } from "utils/routes.utils";
import Inspector from "components/inspector/inspector";
import Select from "components/select/select";
import StorySearch from "pages/story-editor/components/story-search/story-search";
import StoryDisplay from "pages/story-editor/components/story-display/story-display";
import MandatoryInspector from "pages/story-editor/components/mandatory-inspector/mandatory-inspector";
import AdvancedInspector from "pages/story-editor/components/advanced-inspector/advanced-inspector";
import AlternativesInspector from "pages/story-editor/components/alternatives-inspector/alternatives-inspector";
import StoryImageInspector from "pages/story-editor/components/inspector/edit-image-inspector";
import MediaGallery from "pages/media-library/components/media-gallery/media-gallery";
import ImageUpload from "pages/media-library/components/image-upload/image-upload";
import CardSettingsInspector from "pages/story-editor/components/card-settings-inspector/card-settings-inspector";
import OpinionPollInspector from "../opinion-poll-inspector/opinion-poll-inspector";
import { clearMediaForMultiSelectAction } from "pages/media-library/action-creators";
import { resetImportCard } from "pages/story-editor/action-creators";
import PlagiarismReport from "../plagiarism-report/plagiarism-report";
import EditorNotes from "../editor-notes/editor-notes";
import Spinner from "components/spinner/spinner";
import styles from "./inspector.module.css";
import { InspectorData, InspectorDataParams } from "pages/story-editor/components/inspector/inspector-data";
import { PartialAppState } from "pages/story-editor/state";
import { StoryId, StoryElementId, ImageId, CardId, ClientId, TaskId } from "api/primitive-types";
import { StoryTemplate } from "api/route-data/story-route-data";
import { Image, AnyImage } from "api/search-media-image";
import { StoryTemplate as StoryTemplateType } from "api/story";
import OEmbedVideoSelector from "../oembed-video-selector/oembed-video-selector";
import { ImageError } from "pages/media-library/state";
import { filterStoryTemplateWithPrint } from "utils";

interface StateProps {
  storyTemplates: StoryTemplate[];
  storyId: StoryId;
  storyTemplate: StoryTemplateType;
  storyHeadline: string;
  storyTask: TaskId;
  inspectorData: InspectorData;
  isAlternativesEnabled: boolean;
  uploading: boolean;
  selectedMedia: Image[] | null;
  isStoryLocked: boolean;
  isStoryImageHyperlinkEnabled: boolean;
  errors: ImageError[];
  isStoryPrintEnabled: boolean;
}

interface DispatchProps {
  navigate: Navigate;
  closeInspector: (params: InspectorDataParams) => void;
  previousInspector: () => void;
  switchToUploadRoute: (storyId: StoryId, imageId: ImageId, subtype: string) => void;
  setSelectedInspectorImage: (image: Image[] | null) => void;
  setImageUploadStatus: (status: { uploading: boolean }) => void;
  updateImageData: (key: string, value: any, imageIndex: number) => void;
  getTemplateValue: (template: string, storyTemplates: StoryTemplate[]) => StoryTemplate | null;
  onChangeOfTemplate: (template: StoryTemplate, id: StoryId) => void;
  setSelectedImage: (media: AnyImage[], mediaAs: { type: string; subtype?: string }, imageId?: ClientId) => void;
  setSelectedMedia: (storyId: StoryId, cardId: CardId, image: Image) => void;
  savePollDetails: (storyElementId: StoryElementId) => void;
  setMediaForMultiSelect: (media: Image[], storyElementClientId: ClientId, storyId: StoryId) => void;
  addMediatoGalleryElement: (storyElementId: StoryElementId) => void;
  clearMediaForMultiSelect: (storyId: StoryId) => void;
  closeStorySearchInspector: (storyId: StoryId) => void;
  setSelectedGalleryImage: (storyId: string, image: Image | Image[], imageId?: string, elementType?: string) => void;
  clearMediaForStoryEditor: () => void;
}

type StoryEditorInspectorProps = StateProps & DispatchProps;

const cx = classnames.bind(styles);

const Uploading = () => (
  <div className={styles["image-upload-container"]}>
    <Spinner message={t("story-editor.inspector.uploading")} />
  </div>
);

export const StoryEditorInspector: React.FC<StoryEditorInspectorProps> = ({
  storyId,
  storyTemplate,
  storyTemplates,
  getTemplateValue,
  onChangeOfTemplate,
  inspectorData,
  closeInspector,
  previousInspector,
  switchToUploadRoute,
  selectedMedia,
  uploading,
  setSelectedInspectorImage,
  setImageUploadStatus,
  setSelectedImage,
  updateImageData,
  setSelectedMedia,
  isStoryLocked,
  savePollDetails,
  clearMediaForMultiSelect,
  addMediatoGalleryElement,
  setMediaForMultiSelect,
  closeStorySearchInspector,
  storyHeadline,
  storyTask,
  isAlternativesEnabled,
  isStoryImageHyperlinkEnabled,
  setSelectedGalleryImage,
  clearMediaForStoryEditor,
  isStoryPrintEnabled,
  errors
}) => {
  const { inspectorName, navigationButton, params, title, active, actionButton, variant } = inspectorData;
  const { storyElementId, storyElementClientId, subtype, imageId, cardId, elementType } = params;
  const shouldRenderInspector = inspectorName !== "alternativesInspector" || isAlternativesEnabled;
  const templateList = filterStoryTemplateWithPrint(isStoryPrintEnabled, storyTemplates);

  if (!shouldRenderInspector) {
    return null;
  }

  const actionButtonClickHandler = ({ inspectorName }: InspectorData) => {
    if (inspectorName === "imageEditInspector") {
      return selectedMedia && setSelectedImage(selectedMedia, { type: "alternative-hero-image", subtype });
    }
    if (inspectorName === "opinionPollInspector") {
      return storyElementId && savePollDetails(storyElementId);
    }
    if (inspectorName === "imageGalleryMultiSelect") {
      return storyElementClientId && addMediatoGalleryElement(storyElementClientId);
    }
    if (inspectorName === "StoryImageWithEditInspector") {
      clearMediaForStoryEditor();
      if (elementType === "multiImage" && selectedMedia && selectedMedia.length) {
        return imageId && setMediaForMultiSelect(selectedMedia, imageId, storyId);
      }
      return imageId && imageId !== "new"
        ? selectedMedia && setSelectedImage(selectedMedia, { type: "image-element" }, imageId)
        : selectedMedia && setSelectedImage(selectedMedia, { type: "hero-image" });
    }
    return;
  };

  const closeButtonClickHandler = ({ inspectorName, navigationButton }: InspectorData) => {
    clearMediaForStoryEditor();
    if (navigationButton === "close") {
      if (inspectorName === "storySearchInspector") {
        return closeStorySearchInspector(storyId);
      } else {
        return closeInspector({ id: storyId });
      }
    }
    if (navigationButton === "back") {
      return previousInspector();
    }
    if (navigationButton === "closeMultiMediaGallery") {
      return clearMediaForMultiSelect(storyId);
    }
  };

  const isStoryImportInspector = inspectorName === "storyImportInspector";
  const disableOverlay = inspectorName === "mandatoryInspector" || inspectorName === "advancedInspector";
  return (
    <div
      data-test-id="story-editor-inspector"
      className={cx("story-editor-inspector", {
        "import-card-story-container": isStoryImportInspector
      })}>
      <Inspector
        title={title}
        onClose={() => closeButtonClickHandler(inspectorData)}
        level={navigationButton === "close" || navigationButton === "closeMultiMediaGallery" ? 0 : 1}
        isBackgroundActionDisabled={true}
        isActive={active}
        disableOverlay={disableOverlay}
        actionButtonLabel={actionButton}
        onActionButtonClick={() => actionButtonClickHandler(inspectorData)}
        variant={variant}
        className={isStoryImportInspector ? "story-import-inspector" : ""}
        isActionButtonDisabled={uploading}>
        {inspectorName === "templateInspector" && (
          <div className={styles["template-inspector-container"]}>
            <Select
              value={getTemplateValue(storyTemplate, templateList)}
              options={templateList}
              onChange={(template: StoryTemplate) => onChangeOfTemplate(template, storyId)}
              getOptionLabel={(storyTemplate) =>
                t(`story-editor.story-template.${storyTemplate.slug}`, storyTemplate.name)
              }
              getOptionValue={(storyTemplate) => storyTemplate.slug}
            />
          </div>
        )}
        {inspectorName === "imageGalleryWithoutEditInspector" &&
          (uploading ? (
            <Uploading />
          ) : (
            <MediaGallery
              showSelectMediaProvider={true}
              initialSearchTerm={!imageId ? storyHeadline : ""}
              setSelectedMedia={(images) => setSelectedGalleryImage(storyId, images, imageId)}
              showEditImage={false}
              updateImageUploadStatus={setImageUploadStatus}
              opts={{ "task-id": storyTask }}
              scrollableTarget="inspector-container"
            />
          ))}
        {inspectorName === "mandatoryInspector" && <MandatoryInspector />}
        {inspectorName === "advancedInspector" && <AdvancedInspector />}
        {inspectorName === "alternativesInspector" && <AlternativesInspector />}
        {inspectorName === "StoryImageWithEditInspector" && <StoryImageInspector />}

        {inspectorName === "imageGalleryWithEditInspector" &&
          (uploading ? (
            <Uploading />
          ) : (
            <MediaGallery
              showSelectMediaProvider={true}
              showEditImage={true}
              switchToUploadRoute={(imageId) => subtype && switchToUploadRoute(storyId, imageId, subtype)}
              setSelectedMedia={(images: Image[]) => setSelectedInspectorImage(images)}
              updateImageUploadStatus={setImageUploadStatus}
              opts={{ "task-id": storyTask }}
              scrollableTarget="inspector-container"
            />
          ))}
        {inspectorName === "imageEditInspector" && (
          <ImageUpload
            selectedImages={selectedMedia}
            updateImageData={updateImageData}
            isUploading={uploading}
            onDeleteMedia={previousInspector}
            isLinkAuthorEnabled={false}
            isStoryImageHyperlinkEnabled={isStoryImageHyperlinkEnabled}
            errors={errors}
          />
        )}
        {inspectorName === "cardSettingsInspector" && cardId && (
          <CardSettingsInspector params={{ id: storyId, cardId }} />
        )}
        {inspectorName === "cardImageGalleryInspector" && (
          <MediaGallery
            showSelectMediaProvider={true}
            setSelectedMedia={(image: Image[]) => cardId && setSelectedMedia(storyId, cardId, image[0])}
            showEditImage={false}
            updateImageUploadStatus={setImageUploadStatus}
            opts={{ "task-id": storyTask }}
            scrollableTarget="inspector-container"
          />
        )}
        {inspectorName === "opinionPollInspector" && (
          <OpinionPollInspector storyElementId={storyElementId} storyId={storyId} />
        )}
        {inspectorName === "imageGalleryMultiSelect" &&
          (uploading ? (
            <Uploading />
          ) : (
            <MediaGallery
              showSelectMediaProvider={true}
              canMultiSelect={true}
              setSelectedMedia={(images) =>
                setSelectedGalleryImage(storyId, images, imageId || storyElementClientId, "multiImage")
              }
              updateImageUploadStatus={false}
              enableMultipleUploads={true}
              showEditImage={false}
              opts={{ "task-id": storyTask }}
              scrollableTarget="inspector-container"
            />
          ))}
        {inspectorName === "storySearchInspector" && <StorySearch />}
        {inspectorName === "storyImportInspector" && <StoryDisplay />}
        {inspectorName === "storyPlagiarismReport" && <PlagiarismReport />}
        {inspectorName === "editorNotes" && <EditorNotes />}
        {inspectorName === "oEmbedVideoSelector" && <OEmbedVideoSelector storyElementClientId={storyElementClientId} />}
      </Inspector>
    </div>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    storyTemplates: state.config.storyTemplates,
    storyId: state.storyEditor.story["story-content-id"],
    storyTemplate: state.storyEditor.story["story-template"],
    storyHeadline: state.storyEditor.story["headline"],
    storyTask: state.storyEditor.story["task-id"],
    inspectorData: state.storyEditor.ui.inspector,
    isAlternativesEnabled: state.features.isAlternativeHeadlineAndImageEnabled,
    uploading: state.storyEditor.ui.imageUploading,
    selectedMedia: state.storyEditor.ui.imageForInspector,
    isStoryLocked: state.storyEditor.ui.isStoryLocked,
    isStoryImageHyperlinkEnabled: state.features.enableStoryImageHyperlink,
    errors: state.storyEditor.errors,
    isStoryPrintEnabled: state.features.isStoryPrintEnabled || false
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>): DispatchProps => {
  const navigate = navigateFn(dispatch);

  return {
    navigate,
    closeInspector: (params) => navigate(STORY_EDITOR_PATH, params),
    previousInspector: () => dispatch(goBack()),
    switchToUploadRoute: (storyId, imageId, subtype) => {
      navigate(STORY_EDITOR_ALTERNATIVES_IMAGE_EDIT_PATH, { id: storyId, imageId, subtype });
    },
    setSelectedInspectorImage: (image) => dispatch({ type: actions.SET_IMAGE_FOR_INSPECTOR, payload: { image } }),
    setImageUploadStatus: (status) =>
      dispatch({ type: actions.STORY_EDITOR_IMAGE_UPLOADING, payload: { status: status.uploading } }),
    setSelectedImage: (media, mediaAs, imageId) => {
      dispatch(setSelectedImageAction(media, mediaAs, imageId));
    },
    setSelectedGalleryImage: (storyId: string, image: Image, imageId?: string, elementType?: string) => {
      dispatch(editInspectorImageAction(storyId, image, STORY_EDITOR_IMAGE_INSPECTOR_PATH, imageId, elementType));
    },
    updateImageData: (key, value, imageIndex) =>
      dispatch({ type: actions.UPDATE_IMAGE_DATA_FOR_INSPECTOR, payload: { key, value, imageIndex } }),
    getTemplateValue: (template, storyTemplates) => getTemplateValueAction(template, storyTemplates),
    onChangeOfTemplate: (template, storyId) => dispatch(onChangeOfTemplateAction(template, storyId)),
    setSelectedMedia: (storyId, cardId, image) => {
      const mappedImage = {
        url: image.url,
        metadata: image.metadata,
        "temp-s3-key": image["temp-image-key"],
        attribution: "",
        caption: ""
      };
      navigate(STORY_EDITOR_CARD_SHARE, { id: storyId, cardId });
      dispatch({ type: actions.CARD_SHARE_UPDATE, payload: { key: "image", value: mappedImage, cardId } });
    },
    savePollDetails: (storyElementId) => dispatch(savePollAction(storyElementId)),
    setMediaForMultiSelect: (media, storyElementClientId, storyId) => {
      dispatch(setMediaForMultiSelectAction(media, storyElementClientId, storyId));
    },
    addMediatoGalleryElement: (storyElementId) => dispatch(setMultiSelectedMediaInspectorAction(storyElementId)),
    clearMediaForMultiSelect: (storyId) => {
      dispatch(clearStoryEditorMediaAction());
      dispatch(clearMediaForMultiSelectAction());
      navigate(STORY_EDITOR_PATH, { id: storyId });
    },
    clearMediaForStoryEditor: () => {
      dispatch(clearStoryEditorMediaAction());
    },
    closeStorySearchInspector: (storyId) => {
      dispatch(resetImportCard());
      navigate(STORY_EDITOR_PATH, { id: storyId });
    }
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(StoryEditorInspector);
