/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Switch } from "components/switch/switch";
import Edit from "components/icons/edit";
import styles from "./toggles.module.css";

interface ToggleableItem {
  id: number;
  name: string;
  slug: string;
  toggle: boolean;
  description: string | null;
}

interface ItemWithDisabled {
  disabled: boolean;
}

function instanceOfItemWithDisabled(object: any): object is ItemWithDisabled {
  return "disabled" in object;
}
interface Props<T> {
  toggles: Array<T>;
  onChange?: (value: T) => void;
  onEdit?: (value: string) => void;
}

function Toggles<T extends ToggleableItem>({ toggles, onChange, onEdit }: Props<T>) {
  return (
    <ul className={styles["category-toggles-container"]} data-test-id="category-toggles-container">
      {toggles.map((item, index) => {
        return (
          <li key={`item-${index}`} className={styles["category-item"]} data-test-id="category-item">
            <h4 className={styles["category-item-title"]} data-test-id="category-item-title">
              {item.name}
            </h4>

            {item["content-type"] && (
              <span
                className={styles["category-item-edit-action"]}
                data-test-id="category-item-edit-action-btn"
                onClick={() => onEdit && onEdit(item.slug)}>
                <Edit />
              </span>
            )}
            <Switch
              id={item.id.toString()}
              onChange={(value) => onChange && onChange({ ...item, toggle: value })}
              checked={item.toggle}
              disabled={instanceOfItemWithDisabled(item) && item.disabled}
            />
            {item.description && (
              <p className={styles["category-item-description"]} data-test-id="category-item-description">
                {item.description}
              </p>
            )}
          </li>
        );
      })}
    </ul>
  );
}

export default Toggles;
