/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect } from "react";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction, compose } from "redux";
import { connect } from "react-redux";
import queryString from "query-string";

import wrapPage from "containers/page/page";
import usePrevious from "custom-hooks/previous";
import { CUSTOM_METADATA_EDIT_PATH, CUSTOM_METADATA_INDEX_PATH, CUSTOM_METADATA_NEW_PATH } from "./routes";
import { PartialAppState } from "../custom-fields/state";
import { loadCustomFields } from "../custom-fields/async-action-creators";
import { AllowedFieldTypes } from "api/custom-field";
import CustomMetadata from "./custom-metadata";

const CustomMetadataWithRoutes: React.FC<any> = ({ match: { path }, location: { search }, loadCustomFields }) => {
  const previousPath = usePrevious(path);
  const { refresh = false } = queryString.parse(search);
  useEffect(() => {
    if ((path === CUSTOM_METADATA_INDEX_PATH && refresh) || (previousPath !== path && !previousPath)) {
      loadCustomFields();
    }
  });

  switch (path) {
    case CUSTOM_METADATA_INDEX_PATH:
      return <CustomMetadata />;
    case CUSTOM_METADATA_NEW_PATH:
      return <CustomMetadata shouldOpenAddInspector={true} />;
    case CUSTOM_METADATA_EDIT_PATH:
      return <CustomMetadata shouldOpenAddInspector={true} />;
  }

  return <div />;
};

const mapStateToProps = () => ({
  title: "custom_metadata"
});

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => ({
  loadCustomFields: () => dispatch(loadCustomFields({ "field-type": AllowedFieldTypes.METADATA }))
});

export default compose(connect(mapStateToProps, mapDispatchToProps), wrapPage({}))(CustomMetadataWithRoutes);
