/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { t } from "i18n";

import TextField from "components/text-field/text-field";
import Select from "components/select/select";
import { UnsavedCustomField, AllowedValueTypes } from "api/custom-field";

interface DataType {
  displayName: string;
  type: AllowedValueTypes;
}

export interface FormConfig {
  customField: UnsavedCustomField;
  allowedDataTypes: Array<DataType>;
}

interface OwnProps {
  config: FormConfig;
  onChange: (UnsavedCustomField: Partial<UnsavedCustomField>) => void;
}

type Props = OwnProps;

const Form: React.SFC<Props> = ({ config: { customField, allowedDataTypes }, onChange }) => {
  const dataType = allowedDataTypes.find((dataType) => dataType.type === customField["value-type"]) as DataType;

  return (
    <React.Fragment>
      <TextField
        value={customField.name}
        label={t("custom-field.form.name-label")}
        onChange={(name) => onChange({ name })}
      />

      <Select
        label={t("custom-metadata.inspector.data-type-label")}
        value={{ type: customField["value-type"], displayName: dataType.displayName }}
        options={allowedDataTypes}
        onChange={(dataType: DataType) => onChange({ "value-type": dataType.type })}
        getOptionLabel={(dataType: DataType) => dataType.displayName}
        getOptionValue={(dataType: DataType) => dataType.type}
      />
    </React.Fragment>
  );
};

export default Form;
