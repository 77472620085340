/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const ARCHIVED_LIBRARY_PATH = "/archived-library";
export const ARCHIVED_LIBRARY_UPLOAD = `${ARCHIVED_LIBRARY_PATH}/upload/:mediaKey`;
export const ARCHIVED_LIBRARY_STORIES = `${ARCHIVED_LIBRARY_PATH}/stories/:mediaKey`;
