/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import AsyncSelect from "components/select/async";

import Select from "components/select/select";
import FreeTextAttributeInput from "../free-text-attribute-input/free-text-attribute-input";
import { getErrorMessage } from "pages/story-editor/utils";
import { flatten, isRange, convertRange } from "utils";
import { Attribute } from "api/story-attributes";
import { Entity } from "api/entity";
import { StoryAttribute } from "api/route-data/story-route-data";

const getAttributeValues = (attribute: Attribute) => {
  const options = attribute.values;
  return flatten(
    options.map((option) => {
      return isRange(option) ? convertRange(option) : option;
    })
  );
};

const setAttributeValue = (attribute: Attribute, entity: Entity | Array<Entity>) =>
  attribute["attribute-level"] === "multi" && Array.isArray(entity)
    ? entity
    : [{ id: entity["id"], name: entity["name"], type: entity["type"] }];

interface Props {
  attributes: Array<StoryAttribute | Attribute>;
  onAttributeChange: (
    name: string,
    val: Array<string | Entity | { id: string; name: string; type: string }> | null,
    type?: string
  ) => void;
  selectedAttributes: { [key: string]: Array<string | Entity> };
  isFreeTextAttributesEnabled: boolean;
  errors?: object;
  getAttributesOfTypeEntityOptions: (val: string, values: string[]) => void;
  disabled?: boolean;
}

const Attributes: React.SFC<Props> = ({
  attributes,
  onAttributeChange,
  selectedAttributes,
  isFreeTextAttributesEnabled,
  getAttributesOfTypeEntityOptions,
  errors,
  disabled
}) => {
  return (
    <React.Fragment>
      {attributes.map((attribute: Attribute, index: number) => {
        const isAttributeEntityType = attribute["data-type"] === "entity";
        return (
          <React.Fragment key={`story-attribute-${index}`}>
            {isAttributeEntityType && (
              <AsyncSelect
                loadOptions={(val) => getAttributesOfTypeEntityOptions(val, attribute.values)}
                defaultOptions={true}
                value={(selectedAttributes && selectedAttributes[attribute.name]) || []}
                onChange={(entity: Entity | Array<Entity> | null) => {
                  onAttributeChange(attribute.name, entity && setAttributeValue(attribute, entity), "entity");
                }}
                isMulti={attribute["attribute-level"] === "multi"}
                isClearable={true}
                label={attribute["display-name"]}
                getOptionLabel={(entity: Entity) => entity.name}
                getOptionValue={(entity: Entity) => entity.name}
                errorMessage={
                  attribute["is-mandatory"]
                    ? errors &&
                      getErrorMessage(errors[attribute.name] || errors["story-attributes"] || errors["attributes"])
                    : undefined
                }
                isDisabled={disabled}
              />
            )}
            {isFreeTextAttributesEnabled && !isAttributeEntityType && (
              <FreeTextAttributeInput
                attribute={attribute}
                onChange={(val: Array<string>) => onAttributeChange(attribute.name, val)}
                value={((selectedAttributes && selectedAttributes[attribute.name]) || []) as Array<string>}
                options={getAttributeValues(attribute)}
                errorMessage={
                  attribute["is-mandatory"]
                    ? errors &&
                      getErrorMessage(errors[attribute.name] || errors["story-attributes"] || errors["attributes"])
                    : undefined
                }
                disabled={disabled}
              />
            )}

            {!isFreeTextAttributesEnabled && !isAttributeEntityType && (
              <Select
                options={getAttributeValues(attribute)}
                value={selectedAttributes && selectedAttributes[attribute.name]}
                onChange={(val: Array<string>) => onAttributeChange(attribute.name, val)}
                isMulti={true}
                label={attribute["display-name"]}
                getOptionLabel={(attribute: string) => attribute}
                getOptionValue={(attribute: string) => attribute}
                errorMessage={
                  attribute["is-mandatory"]
                    ? errors &&
                      getErrorMessage(errors[attribute.name] || errors["story-attributes"] || errors["attributes"])
                    : undefined
                }
                isDisabled={disabled}
              />
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default Attributes;
