/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { t } from "i18n";
import { validate } from "utils/validation.utils";
import { CurrentDomain } from "store/domain/domain";

export const ERRORS_TRANSLATION_PATH = "secret-mode.domain-manager.errors.validation";

export function validateDomain(domain: CurrentDomain) {
  return validate(domain, {
    name: {
      presence: { allowEmpty: false, message: "^" + t(`${ERRORS_TRANSLATION_PATH}.empty-name`) }
    },
    "host-url": {
      presence: { allowEmpty: false, message: "^" + t(`${ERRORS_TRANSLATION_PATH}.empty-host-url`) },
      url: { message: "^" + t(`${ERRORS_TRANSLATION_PATH}.invalid-host-url`) }
    },
    "section-ids": {
      presence: { allowEmpty: false, message: "^" + t(`${ERRORS_TRANSLATION_PATH}.empty-sections`) }
    },
    "beta-host-url": {
      urlAllowEmpty: { message: "^" + t(`${ERRORS_TRANSLATION_PATH}.invalid-beta-host`) }
    }
  });
}
