/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import AnimatedLoader from "components/icons/animated-loader";
import styles from "./spinner.module.css";
import classnames from "classnames/bind";

const cx = classnames.bind(styles);

interface Props {
  message?: string;
  classname?: string;
}

const Spinner: React.SFC<Props> = ({ message, classname }) => {
  return (
    <div className={cx("spinner", classname)}>
      <AnimatedLoader /> <h5 className={styles["spinner-message"]}>{message}</h5>
    </div>
  );
};

export default Spinner;
