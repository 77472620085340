/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import wretch from "wretch";
import { tokenMiddleware, timeoutMiddleware, TokenApiFn } from "./middleware";

const client = wretch();

interface BasicParams {
  from?: number;
  to?: number;
  q?: string;
}

interface FetchConsumersParams extends BasicParams {
  limit: number;
  offset: number;
}

export function generateUserReport(tokenFn: TokenApiFn, integrationId: number, realmId: number, params: BasicParams) {
  return Object.keys(params).length === 0 && params.constructor === Object
    ? client
        .middlewares([tokenMiddleware(tokenFn, integrationId), timeoutMiddleware(10000)])
        .url(`/api/auth/v1/users/report`)
        .post({
          "target-realm": realmId
        })
        .json()
    : client
        .middlewares([tokenMiddleware(tokenFn, integrationId), timeoutMiddleware(10000)])
        .url(`/api/auth/v1/users/report`)
        .query(params)
        .post({
          "target-realm": realmId
        })
        .json();
}

export function fetchConsumers(
  tokenFn: TokenApiFn,
  integrationId: number,
  realmId: number,
  params: FetchConsumersParams
) {
  const searchParams = {
    ...params,
    "target-realm": realmId
  };
  return client
    .middlewares([tokenMiddleware(tokenFn, integrationId, "get", "query"), timeoutMiddleware(10000)])
    .url(`/api/auth/v1/users/consumer-search`)
    .query(searchParams)
    .get()
    .json()
    .catch((err) => {
      return Promise.reject(JSON.parse(err.message));
    });
}

export function deleteConsumerAPI(tokenFn: TokenApiFn, integrationId: number, realmId: number, consumerId: number) {
  const deleteConsumerParams = {
    "target-realm": realmId
  };
  return client
    .middlewares([tokenMiddleware(tokenFn, integrationId, "get", "query"), timeoutMiddleware(10000)])
    .url(`/api/auth/v1/users/${consumerId}`)
    .query(deleteConsumerParams)
    .delete()
    .json()
    .catch((err) => {
      return Promise.reject(JSON.parse(err.message));
    });
}
