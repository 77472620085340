/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import TextArea from "components/text-area/text-area";
import { getErrorMessage } from "pages/story-editor/utils";
import { ValidationError } from "api/breaking-news";

import { t } from "i18n";

interface CustomSlugProps {
  value?: string;
  onChange(value: string): void;
  errors?: ValidationError | null;
  disabled?: boolean;
}

const CustomSlug = ({ value, onChange, errors, disabled }: CustomSlugProps) => (
  <TextArea
    label={t("story-editor.inspector.custom-slug")}
    value={value || ""}
    onChange={onChange}
    errorMessage={errors ? getErrorMessage(errors) : ""}
    maxRows={10}
    disabled={disabled}
  />
);

export default CustomSlug;
