/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { debounce } from "lodash";
import classnames from "classnames/bind";
import EditorView from "pages/story-editor/prosemirror/editor-view";
import { EditorState } from "prosemirror-state";
import { DOMParser, DOMSerializer } from "prosemirror-model";
import { textAreaSchema } from "pages/story-editor/prosemirror/schema";
import { keymap } from "prosemirror-keymap";
import { buildKeymap } from "pages/story-editor/plugins/keymap";
import { baseKeymap } from "prosemirror-commands";
import { toolbar } from "./toolbar";
import { EditorStateToHTMLForTextArea, htmlToPlainText } from "pages/story-editor/prosemirror/utils";
import styles from "./prosemirror-text-area.module.css";
import { history } from "prosemirror-history";
import FieldLabel from "components/field-label/field-label";
import ErrorMessage from "components/error-message/error-message";
import { safeTextSelection } from "pages/story-editor/operations/selection";
import { entityLinkTooltipPlugin } from "pages/story-editor/plugins/tooltip";

const cx = classnames.bind(styles);

interface Props {
  onChange: (htmlString: string) => void;
  value: string;
  label?: string;
  errorMessage?: string;
  classname?: string;
}

interface State {
  editorState: EditorState;
  value: string;
}

const convertValueToNode = (value: string) => {
  let node = document.createElement("div");
  node.innerHTML = value;
  return node;
};

const serializer = DOMSerializer.fromSchema(textAreaSchema);

class ProseMirrorTextArea extends React.Component<Props, State> {
  debouncedOnChange: (htmlString: string) => void;

  getEditorInitialState = () => {
    const schema = textAreaSchema;
    return EditorState.create({
      schema,
      doc: DOMParser.fromSchema(textAreaSchema).parse(convertValueToNode(this.props.value), {
        preserveWhitespace: true
      }),
      plugins: [
        history(),
        keymap(buildKeymap(textAreaSchema)),
        keymap(baseKeymap),
        toolbar(),
        entityLinkTooltipPlugin()
      ]
    });
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      editorState: this.getEditorInitialState(),
      value: this.props.value
    };

    this.debouncedOnChange = debounce(this.onChange, 250);
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    // Conditions in which this is invoked -
    // Case 1 - when dispatch is invoked by PM engine, OnEditorStateChange can change editorState but props.value doesn't change until onChange is invoked,
    // in this case, nextProps.value is the old value and prevState.value is old but prevState.editorState is new. Do nothing in this case.
    if (nextProps && prevState && nextProps.value !== prevState.value) {
      const prevEditorStateValue = EditorStateToHTMLForTextArea(serializer, prevState.editorState);
      if (prevEditorStateValue !== nextProps.value) {
        // Case 2 - New image is loaded, nextProps.value is new and prevState.editorState and prevState.value are old, update editorState and value.
        const { doc, tr } = prevState.editorState;
        const selection = safeTextSelection(doc, 0, doc.content.size);
        const transaction = tr.setSelection(selection).replaceSelectionWith(
          DOMParser.fromSchema(textAreaSchema).parse(convertValueToNode(nextProps.value), {
            preserveWhitespace: true
          }),
          false
        );

        return {
          editorState: prevState.editorState.apply(transaction),
          value: nextProps.value
        };
      } else {
        // Case 3 - OnEditorStateChange can change state and nextProps.value has changed since onChange was already invoked.
        // In this case, nextProps.value is different from prevState.value but editorState doesn't need to change again. Only update state.value.
        return {
          editorState: prevState.editorState,
          value: nextProps.value
        };
      }
    } else return null;
  }

  onEditorStateChange = (editorState: EditorState) => {
    this.setState({ editorState: editorState, value: this.props.value }, () => {
      const htmlText = EditorStateToHTMLForTextArea(serializer, editorState);
      this.debouncedOnChange(htmlToPlainText(htmlText).length === 0 ? "" : htmlText);
    });
  };

  onChange(htmlString: string) {
    this.props.onChange(htmlString);
  }

  render() {
    const { label, errorMessage } = this.props;
    const editorState = this.state.editorState;
    return (
      <div
        data-test-id="text-area-wrapper"
        className={cx("prosemirror-text-area-editor-wrapper", this.props.classname, { "has-error": errorMessage })}>
        {label && <FieldLabel label={label} />}
        {editorState && (
          <EditorView
            editorState={editorState}
            onEditorStateChange={this.onEditorStateChange}
            classname={`${styles["prosemirror-text-area-editor"]} prosemirror-text-area`}
            skipHandlePaste={true}
          />
        )}
        <ErrorMessage message={errorMessage ? errorMessage : ""} />
      </div>
    );
  }
}

export default ProseMirrorTextArea;
