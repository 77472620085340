/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import actions, {
  ListSectionsAction,
  ListSectionsSuccessAction,
  ListSectionsFailureAction,
  ReplaceCurrentSectionAction,
  InitNewSectionAction,
  SaveSectionAction,
  SaveSectionSuccessAction,
  SaveSectionFailureAction,
  UndeleteSectionAction,
  UndeleteSectionSuccessAction,
  UndeleteSectionFailureAction,
  DeleteSectionAction,
  DeleteSectionSuccessAction,
  DeleteSectionFailureAction,
  CancelCreateOrEditSectionAction,
  EditSectionAction,
  EditSectionFailureAction,
  SetGeneratedSlugAction,
  SetCurrentTabAction
} from "./actions";
import { Section, AnySection, SectionTree } from "api/section";
import { TabList } from "./state";

export function listSectionsAction(): ListSectionsAction {
  return { type: actions.LIST_SECTIONS };
}

export function listSectionsSuccessAction(sections: SectionTree[]): ListSectionsSuccessAction {
  return { type: actions.LIST_SECTIONS_SUCCESS, payload: { sections } };
}

export function listSectionsFailureAction(error: Error): ListSectionsFailureAction {
  return { type: actions.LIST_SECTIONS_FAILURE, payload: { error } };
}

export function replaceCurrentSectionAction(section: AnySection | null): ReplaceCurrentSectionAction {
  return { type: actions.REPLACE_CURRENT_SECTION, payload: { section } };
}

export function initNewSectionAction(): InitNewSectionAction {
  return { type: actions.INIT_NEW_SECTION };
}

export function editSectionAction(section: Section): EditSectionAction {
  return { type: actions.EDIT_SECTION, payload: { section } };
}

export function editSectionFailureAction(error: Error): EditSectionFailureAction {
  return { type: actions.EDIT_SECTION_FAILURE, payload: { error } };
}

export function cancelCreateOrEditSectionAction(section: AnySection): CancelCreateOrEditSectionAction {
  return { type: actions.CANCEL_CREATE_OR_EDIT_SECTION, payload: { section } };
}

export function saveSectionAction(section: AnySection): SaveSectionAction {
  return { type: actions.SAVE_SECTION, payload: { section } };
}

export function saveSectionSuccessAction(section: Section): SaveSectionSuccessAction {
  return { type: actions.SAVE_SECTION_SUCCESS, payload: { section } };
}

export function saveSectionFailureAction(section: AnySection, error: Error): SaveSectionFailureAction {
  return { type: actions.SAVE_SECTION_FAILURE, payload: { section, error } };
}

export function deleteSectionAction(section: Section): DeleteSectionAction {
  return { type: actions.DELETE_SECTION, payload: { section } };
}

export function deleteSectionSuccessAction(section: Section): DeleteSectionSuccessAction {
  return { type: actions.DELETE_SECTION_SUCCESS, payload: { section } };
}

export function deleteSectionFailureAction(section: Section, error: Error): DeleteSectionFailureAction {
  return { type: actions.DELETE_SECTION_FAILURE, payload: { section, error } };
}

export function undeleteSectionAction(section: Section): UndeleteSectionAction {
  return { type: actions.UNDELETE_SECTION, payload: { section } };
}

export function undeleteSectionSuccessAction(section: Section): UndeleteSectionSuccessAction {
  return { type: actions.UNDELETE_SECTION_SUCCESS, payload: { section } };
}

export function undeleteSectionFailureAction(section: Section, error: Error): UndeleteSectionFailureAction {
  return { type: actions.UNDELETE_SECTION_FAILURE, payload: { section, error } };
}

export function setGeneratedSlugAction(section: AnySection): SetGeneratedSlugAction {
  return { type: actions.SET_GENERATED_SLUG, payload: { section } };
}

export function setCurrentTab(tab: TabList): SetCurrentTabAction {
  return { type: actions.SET_CURRENT_TAB, payload: { currentTab: tab } };
}
