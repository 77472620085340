/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { DOMSerializer, Fragment, Node, Schema } from "prosemirror-model";
import { get, map } from "lodash";
import { fragmentToHTML, updateQuoteHTMLWithAttribution } from "./utils";
import { getDistinctStoryElements, groupByPathFirst } from "../utils";

import { schema } from "./schema";
import { EditorState } from "prosemirror-state";
import {
  AnyStory,
  Card,
  ChildStoryElement,
  CompositeStoryElement,
  StoryElement,
  StoryElements,
  StoryElementType
} from "api/story";
import { ElementType, PMElement } from "./types";
import { CardId, StoryElementId } from "api/primitive-types";
import { currentCardLoading } from "../state";
import { ItsmanWindow } from "containers/page/page";
import { getReduxActionLog } from "utils";

const w = window as Window & Partial<ItsmanWindow>;

const schemaSerializer: DOMSerializer<Schema> = DOMSerializer.fromSchema(schema);

function storyElementText(PMStoryElement: PMElement): StoryElement {
  const PMParagraph =
    (PMStoryElement.content && PMStoryElement.content.map((node) => Node.fromJSON(schema, node))) || [];
  const fragment = Fragment.fromArray(PMParagraph),
    text = fragmentToHTML(schemaSerializer, fragment);

  if (PMStoryElement.attrs)
    return {
      type: StoryElementType.Text,
      "card-id": PMStoryElement.attrs["card-id"],
      "card-version-id": PMStoryElement.attrs["card-version-id"],
      "client-id": PMStoryElement.attrs["client-id"],
      "family-id": PMStoryElement.attrs["family-id"],
      description: "",
      title: "",
      text,
      id: PMStoryElement.attrs["id"],
      metadata: {},
      subtype: PMStoryElement.attrs["subtype"]
    };
  return {
    type: StoryElementType.Text,
    "card-id": "",
    id: "",
    metadata: {},
    subtype: null
  };
}
function storyElementQuestion(PMStoryElement: PMElement, storyElement: StoryElement): StoryElement {
  const PMQuestion = PMStoryElement.content;
  const questionContent = PMQuestion && PMQuestion[1];

  const PMParagraph =
    (questionContent &&
      questionContent.content &&
      questionContent.content.map((node) => Node.fromJSON(schema, node))) ||
    [];
  const fragment = Fragment.fromArray(PMParagraph),
    text = fragmentToHTML(schemaSerializer, fragment);
  return { ...storyElement, text };
}

function storyElementAnswer(PMStoryElement: PMElement, storyElement: StoryElement): StoryElement {
  const PMAnswer = PMStoryElement.content;
  const answerContent = PMAnswer && PMAnswer[1];

  const PMParagraph =
    (answerContent && answerContent.content && answerContent.content.map((node) => Node.fromJSON(schema, node))) || [];
  const fragment = Fragment.fromArray(PMParagraph),
    text = fragmentToHTML(schemaSerializer, fragment);
  return { ...storyElement, text };
}

export function storyElementBigFact(PMStoryElement: PMElement, storyElement: StoryElement): StoryElement {
  const PMBigFactNode =
    (PMStoryElement.content && PMStoryElement.content.map((node) => Node.fromJSON(schema, node))) || [];
  const bigFactNode = [PMBigFactNode[0], PMBigFactNode[2]];
  const bigFactFragment = Fragment.fromArray(bigFactNode),
    bigFactHTML = fragmentToHTML(schemaSerializer, bigFactFragment);

  const PMBigFact =
    PMStoryElement.content &&
    PMStoryElement.content.map((node: PMElement): (string | undefined)[] => {
      return ((node && node.content) || []).map((node: PMElement): string | undefined => node.text);
    });

  const bigFactTitle = PMBigFact && PMBigFact[0] && PMBigFact[0].join("");
  const bigFactDescription = (PMBigFact && PMBigFact[2] && PMBigFact[2].join("")) || "";

  const text = `<div>${bigFactHTML}</div>`;

  const newStoryElement = {
    ...storyElement,
    text,
    metadata: { ...storyElement.metadata, content: bigFactTitle, attribution: bigFactDescription }
  };
  return newStoryElement;
}

export function storyElementQuote(PMStoryElement: PMElement, storyElement: StoryElement): StoryElement {
  const PMQuoteNode =
    (PMStoryElement.content && PMStoryElement.content.map((node) => Node.fromJSON(schema, node))) || [];
  const quoteNode = [PMQuoteNode[0], PMQuoteNode[2]];
  const quoteFragment = Fragment.fromArray(quoteNode),
    quoteHTML = fragmentToHTML(schemaSerializer, quoteFragment);
  const PMQuote =
    PMStoryElement.content &&
    PMStoryElement.content.map((node: PMElement): (string | undefined)[] => {
      return ((node && node.content) || []).map((node: PMElement): string | undefined => node.text);
    });
  const content = PMQuote && PMQuote[0] && PMQuote[0].join("");
  const attribution = (storyElement && storyElement.metadata && storyElement.metadata.attribution) || "";
  const text = updateQuoteHTMLWithAttribution(attribution, quoteHTML);
  const newStoryElement = { ...storyElement, text, metadata: { ...storyElement.metadata, content, attribution } };
  return newStoryElement;
}

function storyElementBlurb(PMStoryElement: PMElement, storyElement: StoryElement): StoryElement {
  const PMBlurbContent =
    PMStoryElement.content &&
    PMStoryElement.content.map((node: PMElement): (string | undefined)[] => {
      return ((node && node.content) || []).map((node: PMElement): string | undefined => node.text);
    });

  const content = PMBlurbContent && PMBlurbContent[0] && PMBlurbContent[0].join("");

  const PMBlurbNode =
    (PMStoryElement.content && PMStoryElement.content.map((node) => Node.fromJSON(schema, node))) || [];
  const blurbFragment = Fragment.fromArray(PMBlurbNode),
    blurbHTML = fragmentToHTML(schemaSerializer, blurbFragment);

  const newStoryElement = { ...storyElement, text: blurbHTML, metadata: { ...storyElement.metadata, content } };
  return newStoryElement;
}

function storyElementTitle(PMStoryElement: PMElement, storyElement: StoryElement): StoryElement {
  const PMTitle =
    PMStoryElement.content &&
    PMStoryElement.content.map((node: PMElement): string | undefined => {
      return node.content && node.content[0].text;
    });

  const text = PMTitle && PMTitle[0];

  const newStoryElement = { ...storyElement, text };
  return newStoryElement;
}

export function getCtaOptions(node: Node) {
  const ctaOptionNodes = get(node, ["content", "content"], []);
  return ctaOptionNodes.map((c) => {
    return { type: c.attrs.helperName, label: c.attrs.label };
  });
}

export function storyElementCTAElement(PMStoryElement: PMElement, storyElement: StoryElement): StoryElement {
  const TitleNodePositionInCtaSchema = 1;
  const ctaTitle = getContentFromNodePath(PMStoryElement, ["content", TitleNodePositionInCtaSchema, "content"]);

  const UrlNodePositionInCtaSchema = 3;
  const ctaUrl = getContentFromNodePath(PMStoryElement, ["content", UrlNodePositionInCtaSchema, "content"]);

  const updatedMetadata = {
    ...storyElement.metadata,
    "cta-title": ctaTitle,
    "cta-url": ctaUrl
  };
  return buildCtaElementTextUsingMetadata({ ...storyElement, metadata: { ...updatedMetadata } });
}

function getContentFromNodePath(PMStoryElement: PMElement, path: any[]) {
  const content = get(PMStoryElement, path);
  const contentItems = (content && content.map((node) => Node.fromJSON(schema, node))) || [];
  return fragmentToHTML(schemaSerializer, Fragment.fromArray(contentItems));
}

export function buildCtaElementTextUsingMetadata(storyElement: StoryElement) {
  const metadata = storyElement.metadata;
  if (!metadata) {
    return storyElement;
  }
  const target = metadata["open-in-new-tab"] ? "_blank" : "";
  const noFollow = metadata["no-follow"] ? "nofollow" : "";
  const href = metadata["cta-url"];
  const anchorText = `<span class="cta-text">${metadata["cta-title"]}</span>`;
  const text = `<a class="cta-anchor" href="${href}" target="${target}" rel="${noFollow}">${anchorText}</a>`;
  return { ...storyElement, text };
}

function storyElementQAndA(PMStoryElement: PMElement, storyElement: StoryElement): StoryElement {
  const PMQAndA = PMStoryElement.content;
  const questionContent = PMQAndA && PMQAndA[1].content;
  const questionParagraph = (questionContent && questionContent.map((node) => Node.fromJSON(schema, node))) || [];
  const questionFragment = Fragment.fromArray(questionParagraph);
  const question = fragmentToHTML(schemaSerializer, questionFragment);

  const answerContent = PMQAndA && PMQAndA[4].content;
  const answerParagraph = (answerContent && answerContent.map((node) => Node.fromJSON(schema, node))) || [];
  const answerFragment = Fragment.fromArray(answerParagraph);
  const answer = fragmentToHTML(schemaSerializer, answerFragment);

  const text = `<div><div class="question">${question}</div><div class="answer">${answer}</div></div>`;
  const newStoryElement = { ...storyElement, text, metadata: { ...storyElement.metadata, question, answer } };
  return newStoryElement;
}

function storyElements(
  PMStoryElements: { [index: string]: PMElement },
  storyElements: Array<StoryElement | CompositeStoryElement | ChildStoryElement>
): StoryElement[] {
  return map(PMStoryElements, (PMStoryElement: PMElement): any => {
    const storyElement = storyElements.find(
      (element) => PMStoryElement.attrs !== undefined && element.id === PMStoryElement.attrs["id"]
    );
    if (PMStoryElement.type && PMStoryElement.type === "story_element_text") {
      return storyElementText(PMStoryElement);
    } else if (PMStoryElement.type && PMStoryElement.type === "answer") {
      return storyElement && storyElementAnswer(PMStoryElements[storyElement.id], storyElement as StoryElement);
    } else if (PMStoryElement.type && PMStoryElement.type === "question") {
      return storyElement && storyElementQuestion(PMStoryElements[storyElement.id], storyElement as StoryElement);
    } else if (PMStoryElement.type && PMStoryElement.type === "quote") {
      return storyElement && storyElementQuote(PMStoryElements[storyElement.id], storyElement as StoryElement);
    } else if (PMStoryElement.type && PMStoryElement.type === "blurb") {
      return storyElement && storyElementBlurb(PMStoryElements[storyElement.id], storyElement as StoryElement);
    } else if (PMStoryElement.type && PMStoryElement.type === "bigfact") {
      return storyElement && storyElementBigFact(PMStoryElements[storyElement.id], storyElement as StoryElement);
    } else if (PMStoryElement.type && PMStoryElement.type === "q_and_a_element") {
      return storyElement && storyElementQAndA(PMStoryElements[storyElement.id], storyElement as StoryElement);
    } else if (PMStoryElement.type && PMStoryElement.type === "title") {
      return storyElement && storyElementTitle(PMStoryElements[storyElement.id], storyElement as StoryElement);
    } else if (PMStoryElement.type && PMStoryElement.type === ElementType.Cta) {
      return storyElement && storyElementCTAElement(PMStoryElements[storyElement.id], storyElement as StoryElement);
    } else return storyElement;
  }).filter((element) => element);
}

type PMCard = { [index: string]: PMElement };

function getAllPMCards(editorState: EditorState<Schema>) {
  return groupByPathFirst(editorState.doc.toJSON().content, "attrs.id");
}

export function getPMCardStoryElements(PMCard: PMElement, story: AnyStory) {
  const cardId = get(PMCard, ["attrs", "id"]);
  const cardVersionId = get(PMCard, ["attrs", "content-version-id"], "");
  const cardTree = get(PMCard, "content", []) as PMElement[];

  // Create story elements list from PM card tree and update card details
  const getStoryElementId = (element: PMElement) => get(element, ["attrs", "id"]);
  const isValidElement = (storyElementId) => get(story["story-elements"], [storyElementId, "id"]);
  const createStoryElement = (storyElementId) => {
    const storyElement = story["story-elements"][storyElementId];
    return { ...storyElement, "card-id": cardId, "card-version-id": cardVersionId };
  };
  return cardTree
    .map(getStoryElementId)
    .filter(isValidElement)
    .map(createStoryElement);
}

function updateStoryElementsOfCard(PMCards: PMCard, story: AnyStory, card: Card) {
  try {
    const PMCard: PMCard = groupByPathFirst(
      (PMCards[card["content-id"]] && PMCards[card["content-id"]].content) || [],
      "attrs.id"
    );
    const storyElementsOfCurrentCard = getPMCardStoryElements(PMCards[card["content-id"]], story);
    return storyElements(PMCard, storyElementsOfCurrentCard);
  } catch (error) {
    w.newrelic &&
      w.newrelic.noticeError(error, {
        errorType: "editor_state_to_story_error",
        errorDescription: "Error in editor state to story conversion",
        errorInfo: JSON.stringify({ card, story }),
        reduxActionLog: JSON.stringify({ actions: getReduxActionLog() })
      });
    throw error;
  }
}

function getAllStoryElements(
  story: AnyStory,
  storyEditorElements: Array<StoryElement | CompositeStoryElement | ChildStoryElement>[]
) {
  return storyEditorElements.reduce(
    (obj: StoryElements, items: Array<StoryElement | CompositeStoryElement | ChildStoryElement>) => {
      items.map((item) => {
        if (item.type === "composite") {
          (item as CompositeStoryElement).tree.map((se) => (obj[se] = story["story-elements"][se]));
        }
        obj[item.id] = item;
        return null;
      });
      return obj;
    },
    {}
  );
}

function getRemainingElements(card: Card, currentCard: currentCardLoading): StoryElementId[] {
  if (currentCard && currentCard.card === card["content-id"]) {
    return card.tree.filter((element: string) => currentCard.elements.includes(element));
  }
  return [];
}

function updateCard(PMCards: PMCard, card: Card, currentCard: currentCardLoading = null) {
  const PMCard: PMCard = groupByPathFirst(
    (PMCards[card["content-id"]] && PMCards[card["content-id"]].content) || [],
    "attrs.id"
  );
  const newTree = map(PMCard, (element: PMElement) => {
    return element.attrs && element.attrs["id"];
  })
    .filter((element) => element)
    .concat(getRemainingElements(card, currentCard));
  getDistinctStoryElements(newTree);
  return {
    ...card,
    tree: newTree
  };
}

function getAllCards(selectedCards: Card[]) {
  let allCards = {};
  selectedCards.forEach((card) => (allCards[card["id"]] = card));
  return allCards;
}

function editorStateToStory(
  editorState: EditorState<Schema>,
  story: AnyStory,
  unloadedCards: CardId[],
  currentCard: currentCardLoading
): AnyStory {
  const PMCards: PMCard = getAllPMCards(editorState);
  const seElementsArray = map(story.cards, (card: Card) => {
    if (unloadedCards.includes(card["content-id"])) {
      return card.tree.map((element) => story["story-elements"][element]);
    }
    return updateStoryElementsOfCard(PMCards, story, card).concat(
      getRemainingElements(card, currentCard).map((element) => story["story-elements"][element]) as StoryElement[]
    );
  });
  const allStoryElements = getAllStoryElements(story, seElementsArray);
  const selectedCards = map(story.cards, (card: Card) => {
    if (unloadedCards.includes(card["content-id"])) {
      return card;
    }
    return updateCard(PMCards, card, currentCard);
  });
  const allCards = getAllCards(selectedCards);
  return { ...story, "story-elements": allStoryElements, cards: allCards };
}

function editorStateToLiveBlogStory(
  editorState: EditorState<Schema>,
  story: AnyStory,
  unloadedCards: CardId[]
): AnyStory {
  const PMCards: PMCard = getAllPMCards(editorState);
  const seElementsArray = map(story.cards, (card: Card) => {
    if (unloadedCards.includes(card["content-id"])) {
      return card.tree.map((element) => story["story-elements"][element]);
    }
    return updateStoryElementsOfCard(PMCards, story, card);
  });
  const allStoryElements = getAllStoryElements(story, seElementsArray);
  const selectedCards = map(story.cards, (card: Card) => {
    if (unloadedCards.includes(card["content-id"])) {
      return card;
    }
    return updateCard(PMCards, card);
  });
  const allCards = getAllCards(selectedCards);
  return { ...story, "story-elements": allStoryElements, cards: allCards };
}

export { editorStateToStory, editorStateToLiveBlogStory };
