/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { BadgeValue } from "components/badge/badge";
import { route } from "../../../../utils/routes.utils";
import { COLLECTIONS_SELECTED_COLLECTION_PATH } from "../../routes";

import CollectionManage from "pages/collections/components/collection-manage/collection-manage";

import { t } from "i18n";
import { CollectionWrapper, AssociatedMetadata } from "api/story-collection";
import { CollectionLayoutOptions, CollectionTemplateOptions } from "api/template-options";
import TypeCardComponent, { CollectionItemLocation } from "../type-card/type-card";
import styles from "./collection-type-card.module.css";

export interface Props {
  item: CollectionWrapper;
  isSelected: boolean;
  isDragging?: boolean;
  badge: BadgeValue;
  toggleCollectionItem: (item: CollectionWrapper) => void;
  type: CollectionItemLocation;
  isCardExpanded: boolean;
  updatePageViewsThreshold: (index: number) => void;
  index: number;
  cancelCardExpanded: (index: number, key: string, value: any) => void;
  updateStagingAssociatedMetadata: (name: string, val: string | number | boolean) => void;
  stagingAssociatedMetadata: AssociatedMetadata;
  changeAssociatedMetadataLayout: (name: string, options: CollectionLayoutOptions[]) => void;
  updateAssociatedMetadata: (index: number, key: string, associatedMetadata: AssociatedMetadata) => void;
  templateOptions: CollectionTemplateOptions;
  isAssociatedMetadataEnabled: boolean;
  expandCollectionCard: (item: CollectionWrapper, index: number) => void;
  hasCollectionRankNestedCollectionPermission: boolean;
}

const TypeCard = TypeCardComponent<CollectionWrapper>();

const CollectionTypeCard: React.SFC<Props> = ({
  item,
  isSelected,
  isDragging,
  badge,
  toggleCollectionItem,
  type,
  updatePageViewsThreshold,
  index,
  updateStagingAssociatedMetadata,
  isCardExpanded,
  stagingAssociatedMetadata,
  changeAssociatedMetadataLayout,
  cancelCardExpanded,
  updateAssociatedMetadata,
  templateOptions,
  isAssociatedMetadataEnabled,
  expandCollectionCard,
  hasCollectionRankNestedCollectionPermission
}) => {
  const cardItem = item.collection;

  const renderManageComponent = () => {
    return (
      <CollectionManage
        item={item}
        templateOptions={templateOptions}
        updateStagingAssociatedMetadata={updateStagingAssociatedMetadata}
        updatePageViewsThreshold={updatePageViewsThreshold}
        index={index}
        stagingAssociatedMetadata={stagingAssociatedMetadata}
        changeAssociatedMetadataLayout={changeAssociatedMetadataLayout}
        updateAssociatedMetadata={updateAssociatedMetadata}
        cancelCardExpanded={cancelCardExpanded}
      />
    );
  };

  return (
    <TypeCard
      item={item}
      cardItem={cardItem}
      isSelected={isSelected}
      isDragging={isDragging}
      badge={badge}
      template={(cardItem && cardItem.template) || t("collections.collections")}
      toggleCollectionItem={toggleCollectionItem}
      type={type}
      isCardExpanded={isCardExpanded}
      href={route(COLLECTIONS_SELECTED_COLLECTION_PATH, { collectionId: item.collection.id })}
      headline={item.collection.name}
      headlineClassname={styles["collection-headline"]}
      onClickManageButton={() => expandCollectionCard(item, index)}
      renderManageComponent={renderManageComponent}
      showStar={hasCollectionRankNestedCollectionPermission}
      showManage={type === CollectionItemLocation.Sorted && isAssociatedMetadataEnabled}
    />
  );
};

export default CollectionTypeCard;
