/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Table = ({ width = 24, height = 24, color = "#c6ccd5" }) => (
  <svg
    data-name="Layer 1"
    width={width}
    height={height}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24">
    <path d="M19.62 3.89H4.28A1.34 1.34 0 0 0 3 5.22v12.34a1.33 1.33 0 0 0 1.33 1.33h15.29A1.32 1.32 0 0 0 21 17.56V5.22a1.33 1.33 0 0 0-1.38-1.33zm-5.17 1v3.5h-5v-3.5zm0 4.5v4h-5v-4zM4 5.22a.33.33 0 0 1 .33-.33h4.12v3.5H4zm0 4.17h4.5v4H4zm.33 8.5a.33.33 0 0 1-.33-.33v-3.17h4.5v3.5zm5.17 0v-3.5h5v3.5zm10.5-.33a.33.33 0 0 1-.33.33h-4.22v-3.5H20zm0-4.17h-4.5v-4H20zm0-5h-4.5v-3.5h4.17a.33.33 0 0 1 .33.33z" />
  </svg>
);

export default Table;
