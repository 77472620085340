/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { Asana } from "api/route-data/route-data";
import NoContentCard from "components/no-content-card/no-content-card";
import styles from "./taskmanagerlogin.module.css";
import ExclamationCircle from "components/icons/exclamation-circle";

interface OwnProps {
  asana: Asana;
}

type Props = OwnProps;

export const asanaConnect = (asana: Asana) => {
  const encodedRedirectUri = asana["redirect-uri"] && encodeURIComponent(asana["redirect-uri"]);
  const loginUrl = `https://app.asana.com/-/oauth_authorize?response_type=code&client_id=${asana["client-id"]}&redirect_uri=${encodedRedirectUri}`;
  window.open(loginUrl, "_blank", "height=600,width=800,left=250,top=100,resizable=yes", true);
};

class TaskManagerLogin extends React.Component<Props> {
  componentDidMount() {
    window.addEventListener("message", function(e) {
      if (e.data && e.data.login) {
        window.location.reload();
      }
    });
  }
  render() {
    const { asana } = this.props;
    return (
      <div className={styles["taskmanager-login-container"]}>
        <NoContentCard>
          <div className={styles["taskmanager-login-no-content-message"]}>
            <ExclamationCircle width="48" height="48" color="#5f6978" />
            <div className={styles["taskmanager-login-message"]}>{t("settings.tasks.messages.asana_login")}</div>
            <button
              className={styles["taskmanager-connect-now-link"]}
              onClick={() => {
                asanaConnect(asana);
              }}>
              {t("settings.tasks.messages.asana_connect")}
            </button>
          </div>
        </NoContentCard>
      </div>
    );
  }
}

export default TaskManagerLogin;
