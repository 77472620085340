/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { IntegrationType } from "pages/settings/pages/integrations/state";

export enum Integrations {
  Brightcove = "brightcove",
  Copyscape = "copyscape",
  Facebook = "facebook",
  Fastly = "fastly",
  Getty = "getty",
  Linkedin = "linkedin",
  Mandrill = "mandrill",
  Metype = "metype",
  Moengage = "moengage",
  OneSignal = "one-signal",
  Polltype = "polltype",
  Twitter = "twitter",
  Wru = "wru",
  Fcm = "fcm",
  GoogleMaps = "google-maps",
  Vidible = "vidible",
  PushEngage = "push-engage",
  Asana = "asana",
  Izooto = "izooto",
  GoogleAnalytics3Realtime = "ga3-realtime",
  GoogleAnalytics4Reporting = "ga4-reporting",
  DefaultProviders = "default-providers",
  Dailymotion = "dailymotion",
  GoogleIndexing = "google-indexing"
}

export interface IntegrationsResponse {
  linkedin?: LinkedinConfig;
  facebook?: FacebookConfig;
  polltype?: PolltypeConfig;
  twitter?: TwitterConfig;
  wru?: WruConfig;
  mandrill?: MandrillConfig;
  copyscape?: CopyscapeConfig;
  getty?: GettyConfig;
  brightcove?: BrightcoveConfig;
  metype?: MetypeConfig;
  "one-signal"?: OneSignalConfig;
  fastly?: FastlyConfig;
  moengage?: MoengageConfig;
  fcm?: FcmConfig;
  vidible?: VidibleConfig;
  "ga3-realtime"?: GoogleAnalytics3RealtimeConfig;
  dailymotion?: DailymotionConfig;
  "google-indexing"?: GoogleIndexingConfig;
  "ga4-reporting"?: GoogleAnalytics4ReportingConfig;
}

export interface SupportedIntegrationsResponse {
  linkedin: LinkedinConfig;
  facebook: FacebookConfig;
  polltype: PolltypeConfig;
  twitter: TwitterConfig;
  wru: WruConfig;
  mandrill: MandrillConfig;
  copyscape: CopyscapeConfig;
  getty: GettyConfig;
  brightcove: BrightcoveConfig;
  metype: MetypeConfig;
  "one-signal": OneSignalConfig;
  fastly: FastlyConfig;
  moengage: MoengageConfig;
  vidible: VidibleConfig;
  "ga3-realtime": GoogleAnalytics3RealtimeConfig;
  dailymotion: DailymotionConfig;
  "google-indexing": GoogleIndexingConfig;
  "ga4-reporting": GoogleAnalytics4ReportingConfig;
}

export interface BaseConfig {
  type: IntegrationType;
  disabled?: boolean;
}

export interface LinkedinConfig extends BaseConfig {
  "api-key": string;
  "api-secret": string;
}

export interface FacebookConfig extends BaseConfig {
  "api-key": string;
  "api-secret": string;
}

export interface TwitterConfig extends BaseConfig {
  "api-key": string;
  "api-secret": string;
}

export interface GettyConfig extends BaseConfig {
  "api-key": string;
  "api-secret": string;
}

export interface MetypeConfig extends BaseConfig {
  "api-key": string;
  "api-secret": string;
}

export interface BrightcoveConfig extends BaseConfig {
  "client-id": string;
  "client-secret": string;
  "policy-key": string;
}

export interface CopyscapeConfig extends BaseConfig {
  username: string;
  "api-key": string;
}

export interface FastlyConfig extends BaseConfig {
  "access-key": string;
  "service-id": string;
}

export interface MandrillConfig extends BaseConfig {
  "api-key": string;
  "contact-publisher-template-name": string;
  "forgot-password-template-name": string;
  "invite-user-template-name": string;
  "welcome-email-template-name": string;
  "story-published-template-name": string;
  "verification-email-template-name": string;
}

export interface VidibleConfig extends BaseConfig {
  "buyer-company-id": string;
  "company-id": string;
  "player-id": string;
  "playlist-id": string;
}

export interface MoengageConfig extends BaseConfig {
  "app-id": string;
  "api-secret": string;
  "data-center-id": string;
}

export interface FcmConfig extends BaseConfig {
  "server-key": string;
  "ttl-web-in-hours": number;
  "ttl-mobile-in-hours": number;
}

export interface OneSignalConfig extends BaseConfig {
  "api-key": string;
  "app-id": string;
}

export interface PolltypeConfig extends BaseConfig {
  "api-key": string;
}

export interface WruConfig extends BaseConfig {
  mid: string;
}

export interface ApiKeyConfig extends BaseConfig {
  "api-key": string;
}

export interface IzootoConfig extends BaseConfig {
  "api-key": string;
  "audience-id": string;
  "android-api-key": string;
  "ios-api-key": string;
}

export interface GoogleAnalytics3RealtimeConfig extends BaseConfig {
  "client-email": string;
  "private-key": string;
  "view-id": string;
}

export interface GoogleAnalytics4ReportingConfig extends BaseConfig {
  "client-email": string;
  "private-key": string;
  "property-id": string;
  timezone: string;
}

export interface GoogleIndexingConfig extends BaseConfig {
  "client-email": string;
  "private-key": string;
}

export interface DefaultProvidersConfig extends BaseConfig {
  mobile: string;
  web: string;
}

export interface DailymotionConfig extends BaseConfig {
  "player-id": string;
}

export type IntegrationConfig =
  | { key: Integrations.Brightcove; value: BrightcoveConfig }
  | { key: Integrations.Copyscape; value: CopyscapeConfig }
  | { key: Integrations.Fastly; value: FastlyConfig }
  | { key: Integrations.Wru; value: WruConfig }
  | { key: Integrations.Mandrill; value: MandrillConfig }
  | { key: Integrations.Moengage; value: MoengageConfig }
  | { key: Integrations.OneSignal; value: OneSignalConfig }
  | { key: Integrations.Polltype; value: PolltypeConfig }
  | { key: Integrations.Facebook; value: FacebookConfig }
  | { key: Integrations.Twitter; value: TwitterConfig }
  | { key: Integrations.Linkedin; value: LinkedinConfig }
  | { key: Integrations.Getty; value: GettyConfig }
  | { key: Integrations.Metype; value: MetypeConfig }
  | { key: Integrations.Fcm; value: FcmConfig }
  | { key: Integrations.GoogleMaps; value: ApiKeyConfig }
  | { key: Integrations.Vidible; value: VidibleConfig }
  | { key: Integrations.PushEngage; value: ApiKeyConfig }
  | { key: Integrations.Izooto; value: IzootoConfig }
  | { key: Integrations.GoogleAnalytics3Realtime; value: GoogleAnalytics3RealtimeConfig; isValid: boolean }
  | { key: Integrations.Dailymotion; value: DailymotionConfig }
  | { key: Integrations.GoogleIndexing; value: GoogleIndexingConfig; isValid: boolean }
  | { key: Integrations.GoogleAnalytics4Reporting; value: GoogleAnalytics4ReportingConfig; isValid: boolean }
  | { key: Integrations.DefaultProviders; value: DefaultProvidersConfig };

export const getPublisherIntegrations = (): Promise<IntegrationsResponse> => {
  return client
    .url("/api/v1/integrations")
    .get()
    .json();
};

export const getSupportedIntegrations = (): Promise<SupportedIntegrationsResponse> => {
  return client
    .url("/api/v1/all-integrations")
    .get()
    .json();
};

export const savePublisherIntegration = (integration: IntegrationConfig) => {
  return client
    .url("/api/v1/integrations")
    .json({ [integration.key]: integration.value })
    .patch()
    .json();
};

export const validatePublisherIntegration = (integration: IntegrationConfig) => {
  return client
    .url("/api/v1/integrations/validate")
    .json({ [integration.key]: integration.value })
    .post()
    .json();
};

export const deletePublisherIntegration = (integrationName: string) => {
  return client
    .url(`/api/v1/integrations/${integrationName}`)
    .delete()
    .json()
    .catch((err) => {
      return Promise.reject(JSON.parse(err.message));
    });
};

export const togglePublisherIntegration = (integration: IntegrationConfig) => {
  return client
    .url(`/api/v1/integrations`)
    .json({ [integration.key]: integration.value })
    .patch()
    .json();
};
