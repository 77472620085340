/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { Fragment, useEffect, useRef, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { t } from "i18n";
import { connect } from "react-redux";
import classnames from "classnames/bind";
import styles from "./image.module.css";
import { StoryElement } from "api/story";
import { Image as ImageType } from "api/search-media-image";
import { setSelectedFocusPoint, setDefaultFocusPoint, removeFocusPoint, FocusPoint } from "./focus-point";
import { useImageMetadata, useFocusPointMetadata, useOverlay } from "custom-hooks/image";
import {
  updateImageElement,
  openGalleryInspector,
  editInspectorImageAction
} from "pages/story-editor/async-action-creators";
import { Dimension, Coordinate } from "pages/media-library/components/image-preview-with-actions/utils";
import AddNewMedia from "components/add-new-media/add-new-media";
import { DropzoneMessageVariant } from "components/dropzone/dropzone-message";
import RichTextField from "components/rich-text-field/rich-text-field";
import { StoryEditorState } from "pages/story-editor/state";
import ImageActionBar, { ImageActionType, ImageAction } from "components/image-action-bar/image-action-bar";
import { get } from "lodash";
import TextArea from "components/text-area/text-area";
import { STORY_EDITOR_IMAGE_INSPECTOR_PATH } from "pages/story-editor/routes";
import { getErrorMessage } from "pages/story-editor/utils";

const cx = classnames.bind(styles);

interface ImageOverlayProps {
  storyElementClientId: string;
  image: ImageType;
  dispatch(action: any): void;
  openPhotoEditor(image: ImageType, id: string): void;
  imageDimensions: Dimension;
  overlayProperties: {
    style: React.CSSProperties;
    dimensions: Dimension;
  };
  overlayPoint: Coordinate | false | null | undefined;
  isImageLoading: boolean;
}

const ImageOverlay: React.SFC<ImageOverlayProps & StoryEditorProps> = ({
  storyElementClientId,
  image,
  imageDimensions,
  overlayProperties,
  overlayPoint,
  dispatch,
  openPhotoEditor,
  isStoryLocked,
  isImageLoading
}) => {
  const imagePreviewOverlay = useRef<HTMLDivElement>(null);
  const imageActions: ImageAction[] = [
    new ImageAction(
      ImageActionType.ClearFocusPoint,
      (e) => {
        dispatch(
          updateImageElement(storyElementClientId, {
            metadata: removeFocusPoint(e, imageDimensions)
          })
        );
      },
      !overlayPoint || isStoryLocked
    ),
    new ImageAction(
      ImageActionType.SetFocusPoint,
      (e) => {
        dispatch(
          updateImageElement(storyElementClientId, {
            metadata: setDefaultFocusPoint(e, imageDimensions, overlayProperties.dimensions)
          })
        );
      },
      !!overlayPoint || isStoryLocked
    ),
    new ImageAction(ImageActionType.DownloadImage),
    new ImageAction(
      ImageActionType.ReplaceImage,
      (e) => dispatch(openGalleryInspector(e, storyElementClientId)),
      isStoryLocked
    ),
    new ImageAction(ImageActionType.EditImage, (e) => openPhotoEditor(image, storyElementClientId), isStoryLocked)
  ];

  useEffect(() => {
    const element = imagePreviewOverlay.current;
    if (!element) return;
    for (const styleProperty in overlayProperties.style) {
      element.style[styleProperty] = overlayProperties.style[styleProperty];
    }
  }, [overlayProperties, isImageLoading]);

  return (
    <div
      ref={imagePreviewOverlay}
      className={styles["image-preview-overlay"]}
      onClick={(event) => {
        dispatch(
          updateImageElement(storyElementClientId, {
            metadata: setSelectedFocusPoint(event, imageDimensions, overlayProperties.dimensions)
          })
        );
      }}>
      {overlayPoint && !isStoryLocked && (
        <Fragment>
          <FocusPoint x={overlayPoint.x} y={overlayPoint.y} />
        </Fragment>
      )}
      <div className={cx("image-preview-action-bar", { "image-preview-action-bar--hide": isImageLoading })}>
        <ImageActionBar actions={imageActions} image={image} />
      </div>
    </div>
  );
};

interface ImageProps {
  storyElement: StoryElement;
  dispatch(action: any): void;
  openPhotoEditor(image: ImageType, id: string): void;
  isDisabled?: boolean;
  error: any;
}

interface StoryEditorProps {
  isStoryLocked: boolean;
  isStoryImageHyperlinkEnabled?: boolean;
  fieldSpecs?: any;
}

const getCompositeFieldErrors = (error: any): any => {
  return {
    ...(get(error, ["hyperlink", "code"], null) === "url" && { hyperlink: true }),
    ...((get(error, ["alt-text", "code"], null) === "min-count" ||
      get(error, ["alt-text", "code"], null) === "max-count" ||
      get(error, ["alt-text", "code"], null) === "presence") && {
      "alt-text": true
    })
  };
};

const getFieldErrors = (error: any): any => {
  return {
    ...(get(error, "code", null) === "url" && { hyperlink: true }),
    ...((get(error, "code", null) === "min-count" ||
      get(error, "code", null) === "max-count" ||
      get(error, "code", null) === "presence") && {
      "alt-text": true
    })
  };
};

const Image: React.FC<ImageProps & StoryEditorProps> = ({
  storyElement,
  dispatch,
  openPhotoEditor,
  isDisabled = false,
  isStoryLocked,
  isStoryImageHyperlinkEnabled = false,
  error = {},
  fieldSpecs
}) => {
  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
  const storyElementClientId = storyElement["client-id"];
  const image = storyElement.image;
  const imageContainer = React.createRef<HTMLDivElement>();

  const imageDimensions = useImageMetadata(image);
  const { focusPoint } = useFocusPointMetadata(image);
  const { overlayProperties, overlayPoint } = useOverlay(imageContainer, imageDimensions, focusPoint);

  const imageError = error && getFieldErrors(error);
  const fieldErrors = isEmpty(imageError) ? getCompositeFieldErrors(error) : imageError;

  return (
    <Fragment>
      {image && image["url"] ? (
        <div className={cx("image-element-container", { "image-element-disabled": isDisabled })}>
          <div style={{ position: "relative" }} ref={imageContainer}>
            <img
              src={image.url}
              alt={image["alt-text"] || ""}
              className={styles["image-preview"]}
              onLoad={(e) => setIsImageLoading(false)}
            />
            {overlayProperties && overlayProperties.dimensions && overlayProperties.dimensions.width > 0 && (
              <ImageOverlay
                storyElementClientId={storyElementClientId!}
                image={image}
                dispatch={dispatch}
                openPhotoEditor={openPhotoEditor}
                imageDimensions={imageDimensions}
                overlayProperties={overlayProperties}
                overlayPoint={overlayPoint}
                isStoryLocked={isStoryLocked}
                isImageLoading={isImageLoading}
              />
            )}
          </div>
          <div className={styles["image-element-details"]}>
            <RichTextField
              label={t("story-editor.story-element.caption")}
              value={image.caption || ""}
              onChange={(caption) => dispatch(updateImageElement(storyElementClientId!, { caption: caption }))}
            />
            <RichTextField
              label={t("story-editor.story-element.attribution")}
              value={image.attribution || ""}
              onChange={(attribution) =>
                dispatch(updateImageElement(storyElementClientId!, { attribution: attribution }))
              }
            />
            {!(fieldSpecs["image-alt-text"] && fieldSpecs["image-alt-text"].validations.hidden) && (
              <TextArea
                label={t("story-editor.story-element.alt-text")}
                value={image["alt-text"] || ""}
                onChange={(altText) => dispatch(updateImageElement(storyElementClientId!, { "alt-text": altText }))}
                errorMessage={
                  (fieldErrors["alt-text"] && getErrorMessage(error)) || (error && getErrorMessage(error["alt-text"]))
                }
              />
            )}
            {isStoryImageHyperlinkEnabled && (
              <TextArea
                label={t("story-editor.story-element.hyperlink")}
                value={image.hyperlink || ""}
                onChange={(hyperlink) => dispatch(updateImageElement(storyElementClientId!, { hyperlink: hyperlink }))}
                errorMessage={fieldErrors.hyperlink && t("story-editor.url")}
              />
            )}
          </div>
        </div>
      ) : (
        <AddNewMedia
          openGallery={(e: React.MouseEvent) => dispatch(openGalleryInspector(e, storyElementClientId as string))}
          setSelectedMedia={(images: ImageType, storyId: string) => {
            dispatch(
              editInspectorImageAction(storyId, images, STORY_EDITOR_IMAGE_INSPECTOR_PATH, storyElementClientId)
            );
          }}
          classname={cx("image-element-add-new-image", { "image-element-disabled": isDisabled })}
          dropzoneMessageSize={DropzoneMessageVariant.Medium}
          data-test-id={"image-element-add-new-image"}
        />
      )}
    </Fragment>
  );
};

interface PartialAppState {
  features: { enableStoryImageHyperlink: boolean };
  storyEditor: StoryEditorState;
}

const mapStateToProps = (state: PartialAppState) => {
  return {
    isStoryLocked: state.storyEditor.ui.isStoryLocked,
    isStoryImageHyperlinkEnabled: state.features.enableStoryImageHyperlink,
    fieldSpecs: state.storyEditor.storyTemplateFields
  };
};

export default connect(mapStateToProps)(Image);
export { ImageOverlay };
export { Image };
