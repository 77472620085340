/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { Tag } from "./tag";
import { Timestamp, StoryId } from "./primitive-types";
import { Author } from "./author";

export interface TagIdStoryCountMap {
  [tagId: number]: number;
}

export interface SearchedStory {
  "updated-at": Timestamp;
  "author-name": string;
  headline: string;
  "highlighted-text": string | null;
  id: StoryId;
  "author-id": number;
  "highlighted-headline": string | null;
  authors: Author[] | [];
}

interface StorySearchResponse {
  hits: number;
  stories: SearchedStory[];
  collections: [];
  aggregations: null;
}

export const fetchStoryCountsForTags = (tags: Tag[]): Promise<TagIdStoryCountMap> => {
  return client
    .url("/api/search")
    .query({ "tag-id": tags.map((tag) => tag.id).join(","), aggregation: "story-counts-for-tags" })
    .get()
    .json((response) => response.aggregations["tag-id"]);
};

export const fetchStoryForImport = (searchTerm: string): Promise<StorySearchResponse> => {
  return client
    .url("/api/search")
    .query({
      q: searchTerm,
      highlighter: "story-page",
      fields: "updated-at,id,authors,author-id,author-name,headline,highlighted-headline,highlighted-text"
    })
    .get()
    .json();
};
