/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose, AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState } from "../../state";
import { navigate } from "utils/routes.utils";
import styles from "./attribute-row.module.css";
import Chip from "components/chip/chip";
import { t } from "i18n";
import { ContextMenu, ContextMenuList, ContextMenuItem } from "components/context-menu/context-menu";
import { Attribute } from "api/story-attributes";
import { ATTRIBUTE_EDIT_PATH } from "../../routes";

interface DispatchProps {
  changeRoute: (attribute: Attribute) => void;
}

type AttributeRowProps = DispatchProps & {
  attribute: Attribute;
  deleteAttribute?: (attribute: Attribute) => void;
};

const AttributeRow: React.SFC<AttributeRowProps> = ({ attribute, deleteAttribute, changeRoute }) => {
  return (
    <React.Fragment>
      <li
        className={styles["attributes-list-item"]}
        data-test-id="attributes-list-item"
        onClick={() => changeRoute(attribute)}>
        <div className={styles["attribute-row-info"]}>
          {attribute.type && (
            <Chip value={t(`attributes.inspector.type-${attribute.type}-label`)} classname={"attributes-list-chip"} />
          )}
          <div className="attribute-link">{attribute["display-name"]}</div>
        </div>
        <p className={styles["attribute-values"]} data-test-id="attribute-values">
          {attribute.values.join(", ")}
        </p>
        <div
          data-test-id="attribute-menu-trigger"
          id={`attribute-${attribute.name}`}
          className={styles["attribute-row-actions"]}>
          <ContextMenu>
            <div id={`attribute-${attribute.name}`}>
              <ContextMenuList>
                <ContextMenuItem
                  label={t("attributes.cta.delete")}
                  isDanger
                  onClick={() => deleteAttribute && deleteAttribute(attribute)}
                />
              </ContextMenuList>
            </div>
          </ContextMenu>
        </div>
      </li>
    </React.Fragment>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    changeRoute: (attribute) => dispatch(navigate(ATTRIBUTE_EDIT_PATH, { name: attribute.name }))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AttributeRow);

export { AttributeRow };
