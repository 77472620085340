/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { validate, ValidationResult } from "utils/validation.utils";
import { prettify } from "validate.js";
import { UnsavedCustomURL, CustomURL, AnyCustomURL, CustomURLType } from "api/custom-url";

export function validateCustomURL(customURL: UnsavedCustomURL): ValidationResult<UnsavedCustomURL>;
export function validateCustomURL(customURL: CustomURL): ValidationResult<CustomURL>;
export function validateCustomURL(customURL: AnyCustomURL): ValidationResult<AnyCustomURL> {
  return validate(
    customURL,
    {
      "source-path": {
        presence: { allowEmpty: false }
      },
      type: {
        inclusion: { within: Object.values(CustomURLType) }
      }
    },
    {
      prettify: (value: string) => {
        switch (value) {
          case "source-path":
            return "Source Path";
          default:
            return prettify(value);
        }
      }
    }
  );
}
