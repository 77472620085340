/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Plugin, Transaction, EditorState } from "prosemirror-state";
import { Schema } from "prosemirror-model";
import { findParentNodeClosestToPosByAttrs } from "../operations/find";
import { safeTextSelection } from "../operations/selection";

export default () => {
  return new Plugin({
    appendTransaction: (
      transactions: Array<Transaction<Schema>>,
      oldState: EditorState<Schema>,
      newState: EditorState<Schema>
    ) => {
      const nearNodePostion = findParentNodeClosestToPosByAttrs(oldState, "text"),
        { anchor, head } = newState.selection,
        tr = newState.tr;

      if (nearNodePostion && nearNodePostion.start && !newState.selection.empty) {
        if (tr.selection.head < nearNodePostion.start) {
          tr.setSelection(safeTextSelection(tr.doc, nearNodePostion.start, anchor > head ? anchor : head));
          return tr;
        }
        if (tr.selection.head > nearNodePostion.start + nearNodePostion.node.nodeSize) {
          tr.setSelection(
            safeTextSelection(
              tr.doc,
              anchor > head ? head : anchor,
              nearNodePostion.start + nearNodePostion.node.nodeSize
            )
          );
          return tr;
        }

        if (
          tr.selection.head === nearNodePostion.start + nearNodePostion.node.nodeSize - 3 &&
          anchor < nearNodePostion.start
        ) {
          tr.setSelection(
            safeTextSelection(
              tr.doc,
              anchor > head ? anchor : head,
              nearNodePostion.start + nearNodePostion.node.nodeSize - 3
            )
          );
          return tr;
        }
      }
      return tr;
    }
  });
};
