/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

const redirectUriKey = "redirect_uri";

export function getSessionRedirectUri() {
  try {
    return window.sessionStorage.getItem(redirectUriKey);
  }
  catch(e) {
    return null
  }
}

export function setSessionRedirectUri(redirectUri: string) {
  try {
    window.sessionStorage.setItem(redirectUriKey, redirectUri);
  }
  catch(e) {}
}

export function removeSessionRedirectUri() {
  try {
    window.sessionStorage.removeItem(redirectUriKey);
  }
  catch(e) {}
}
