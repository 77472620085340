/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { TagId, PublisherId, MemberId, Timestamp } from "./primitive-types";

interface Pagination {
  "tags-count": number;
}

export enum TagType {
  Manual = "manual",
  Automated = "automated"
}

export interface Tag {
  id: TagId;
  "publisher-id": PublisherId;

  name: string;
  slug: string | null;
  "meta-description": string | null;
  "meta-title": string | null;

  "member-name": string | null;
  "member-id": MemberId | null;

  type?: TagType | null;

  "created-at": Timestamp;
  "updated-at": Timestamp;
}

export type UnsavedTag = Pick<Tag, "name" | "meta-description" | "meta-title" | "slug">;

export type AnyTag = Tag | UnsavedTag;

export interface TagListResponse {
  tags: Tag[];
  pagination: Pagination;
}

export const searchTags = (name: string): Promise<TagListResponse> => {
  const params = name ? { name, limit: 20 } : { limit: 20 };
  return client
    .url("/api/tag")
    .query(params)
    .get()
    .json();
};

export const listTags = (name: string = "", offset: number = 0, limit: number = 20): Promise<TagListResponse> => {
  return client
    .url("/api/tag")
    .query({ name, "order-by": "name", offset, limit })
    .get()
    .json();
};

export const fetchTag = (id: TagId): Promise<Tag> => {
  return client
    .url(`/api/tag/${id}`)
    .get()
    .json();
};

export const saveNewTag = (tag: UnsavedTag): Promise<TagId> => {
  return client
    .url("/api/tag")
    .json(tag)
    .post()
    .json();
};

export const updateTag = (id: TagId, tag: UnsavedTag): Promise<void> => {
  return client
    .url(`/api/tag/${id}`)
    .json(tag)
    .post()
    .res();
};
