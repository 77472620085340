/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";
import * as api from "api/roles";
import { AnyRole } from "api/roles";
import produce from "immer";

import {
  listRolesAction,
  listRolesSuccessAction,
  listRolesFailureAction,
  cancelSaveRolesAction,
  editRoleAction,
  saveRoleSuccessAction,
  addNewRoleAction,
  saveRoleActionFailure,
  deleteRoleActionRequest,
  deleteRoleActionFailure,
  updateRole
} from "./action-creators";
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from "containers/page/actions";
import { navigate } from "utils/routes.utils";
import { ROLES_PATH } from "./routes";
import { PartialAppState } from "./state";
import { toClientValidationError } from "utils/validation.utils";
import { validateRole } from "./validate";

export const loadRolesData = () => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(listRolesAction());
  try {
    const roles = await api.getRoles();
    dispatch(listRolesSuccessAction(roles));
  } catch (error) {
    dispatch(listRolesFailureAction(error));
  }
};

export const deleteRole = (roleId: number) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(deleteRoleActionRequest());
  try {
    await api.deleteRoleFromRolesList(roleId);
    dispatch(loadRolesData());
    dispatch({
      type: NOTIFICATION_SUCCESS,
      payload: { message: t("users-and-roles.role-delete-success") }
    });
  } catch (error) {
    dispatch(deleteRoleActionFailure());
    dispatch({
      type: NOTIFICATION_ERROR,
      //TODO: move to I18n
      payload: { message: error.error.message }
    });
  }
};

export const cancelSaveRoleAndClearCurrentRoleFromInspector = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(navigate(ROLES_PATH));
  dispatch(cancelSaveRolesAction());
};

export const editRole = (roleId: string) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const roles = getState().usersAndRoles.rolesPage.roles;
  const selectedRole = roles.find((role) => role.id === parseInt(roleId));
  if (selectedRole) {
    dispatch(editRoleAction(selectedRole));
  }
};

export const addNewRole = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(addNewRoleAction());
};

export const saveRole = (role: AnyRole) => async (dispatch: ThunkDispatch<any, any, any>) => {
  const actionCategories = role["action-categories"];
  const roleWithAPICompatibleFormat = produce(role, (draft) => {
    draft.actions = actionCategories.content.concat(actionCategories.admin).concat(actionCategories.analytics);
    delete draft["action-categories"];
  });

  const errors = validateRole(roleWithAPICompatibleFormat);

  if (errors) {
    dispatch(saveRoleActionFailure(toClientValidationError<AnyRole>(errors)));
    return;
  }

  try {
    if ("id" in role) {
      await api.updateRole(role.id, roleWithAPICompatibleFormat);
    } else {
      await api.saveRole(roleWithAPICompatibleFormat);
    }
    dispatch(loadRolesData());
    dispatch(saveRoleSuccessAction());
    dispatch({
      type: NOTIFICATION_SUCCESS,
      payload: { message: t("users-and-roles.role-update-success") }
    });

    dispatch(cancelSaveRoleAndClearCurrentRoleFromInspector());
  } catch (e) {
    dispatch(saveRoleActionFailure(e.error));
  }
};

export const updateParent = (parent: { id: number; name: string }) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const role = getState().usersAndRoles.rolesPage.app.currentRole;
  const roles = getState().usersAndRoles.rolesPage.roles;
  const parentRole = parent && roles.find((role) => role.id === parent.id);

  if (parentRole) {
    dispatch(
      updateRole(
        Object.assign({}, role, {
          "parent-role-id": parentRole.id,
          "parent-role-name": parentRole.name,
          "action-categories": parentRole["action-categories"]
        })
      )
    );
  } else {
    dispatch(
      updateRole(
        Object.assign({}, role, {
          "parent-role-id": null,
          "parent-role-name": null
        })
      )
    );
  }
};
