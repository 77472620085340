/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect, useState } from "react";
import { AnyAction, compose } from "redux";
import wrapPage from "containers/page/page";
import { connect } from "react-redux";
import { match as Match } from "react-router";
import { navigate } from "utils/routes.utils";
import {
  deleteWebhook,
  PartialAppState,
  updateWebhookStatus,
  getWebhook,
  openAddWebhookInspector,
  getWebhooks,
  resetWebhookData
} from "store/webhook/webhook";
import { ThunkDispatch } from "redux-thunk";
import { WebhookData } from "api/webhooks";
import InfiniteScrollList from "components/infinite-scroll-list/infinite-scroll-list";
import ReactTable from "react-table";
import { t } from "i18n";
import ContentHeader from "pages/settings/components/content-header/content-header";
import { Switch } from "components/switch/switch";
import Trash from "components/icons/trash";
import Button from "components/button/button";
import DeleteConfirmationModal from "components/confirmation-modal/confirmation-modal";
import EditInspector from "./components/edit-inspector/edit-inspector";
import AddInspector from "./components/add-inspector/add-inspector";
import Spinner from "components/spinner/spinner";
import NoContentCard from "components/no-content-card/no-content-card";
import File from "components/icons/file";
import styles from "./webhooks.module.css";
import { WEBHOOKS_LOG_PATH } from "./logs/routes";

interface OwnProps {
  showAddInspector?: boolean;
  showEditInspector?: boolean;
  match: Match;
}
interface StateProps {
  webhooks: WebhookData;
  moreWebhooksToLoad: boolean;
}
interface DispatchProps {
  loadWebhooks(offset: number): void;
  deleteWebhook(webhookId: number): void;
  updateWebhookStatus(webhookId: number, status: boolean): void;
  editWebhook(webhookId: number): void;
  openAddInspector: VoidFunction;
  resetWebhookData: VoidFunction;
  viewLogs(webhookId: number): void;
}

type WebhooksProps = OwnProps & StateProps & DispatchProps;

function Webhooks({
  loadWebhooks,
  webhooks,
  deleteWebhook,
  updateWebhookStatus,
  openAddInspector,
  editWebhook,
  showAddInspector,
  showEditInspector,
  moreWebhooksToLoad,
  resetWebhookData,
  viewLogs
}: WebhooksProps) {
  const initialOffset = 0;
  const [isDeleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [selectedWebhookId, setSelectedWebhookId] = useState<number | null>(null);

  useEffect(() => {
    loadWebhooks(initialOffset);
    return resetWebhookData;
  }, [loadWebhooks, resetWebhookData]);

  return (
    <>
      <section>
        <main>
          <ContentHeader
            title={t("settings.webhooks.title")}
            description={t("settings.webhooks.description")}
            actionButtonLabel={t("settings.webhooks.add_webhook_button")}
            handleActionButtonClick={openAddInspector}
          />
          {!moreWebhooksToLoad && !webhooks.length ? (
            <NoContentCard>
              <File />
              <div className="no-content-message">{t("settings.webhooks.no_webhooks")}</div>
            </NoContentCard>
          ) : (
            <InfiniteScrollList
              style={{ overflow: "hidden" }}
              dataLength={webhooks.length}
              next={() => loadWebhooks(webhooks.length)}
              hasMore={moreWebhooksToLoad}
              loader={<Spinner message={t("settings.webhooks.loading")} />}>
              {webhooks.length ? (
                <div className={styles["webhooks-table-container"]} data-test-id="webhooks-table-container">
                  <div data-test-id="webhooks-react-table">
                    <ReactTable
                      manual
                      showPaginationTop={false}
                      showPaginationBottom={false}
                      showPagination={false}
                      loading={false}
                      showPageSizeOptions={false}
                      sortable={false}
                      pageSize={webhooks.length}
                      data={webhooks}
                      columns={[
                        {
                          Header: t("settings.webhooks.table_header.url"),
                          accessor: "url",
                          Cell: ({ value, original }) => (
                            <span
                              className={styles["webhook-url-container"]}
                              onClick={() => editWebhook(original.id)}
                              data-test-id="webhook-url-container">
                              <span className={styles["webhook-url"]}>{value}</span>
                            </span>
                          )
                        },
                        {
                          Header: t("settings.webhooks.table_header.status"),
                          accessor: "active",
                          Cell: ({ value, original }) => (
                            <Switch
                              checked={value}
                              onChange={(checked) => {
                                updateWebhookStatus(original.id, checked);
                              }}
                            />
                          )
                        },
                        {
                          Cell: ({ original }) => (
                            <Button type="link" onClick={() => viewLogs(original.id)}>
                              {t("settings.webhooks.view_logs_button")}
                            </Button>
                          )
                        },
                        {
                          Cell: ({ original }) => (
                            <Button
                              type="link"
                              onClick={() => {
                                setSelectedWebhookId(original.id);
                                setDeleteConfirmationModalOpen(true);
                              }}>
                              <Trash color="var(--mono-4)" />
                            </Button>
                          )
                        }
                      ]}
                      className={styles["webhooks-table"]}
                      LoadingComponent={() => null}
                    />
                  </div>
                </div>
              ) : null}
            </InfiniteScrollList>
          )}
        </main>
      </section>
      <DeleteConfirmationModal
        showConfirmationModal={isDeleteConfirmationModalOpen}
        headerText={t("settings.webhooks.delete_modal.title")}
        text={t("settings.webhooks.delete_modal.info")}
        confirmButtonLabel={t("settings.webhooks.delete_modal.confirm_button_label")}
        cancelButtonLabel={t("settings.webhooks.delete_modal.cancel_button_label")}
        onConfirm={() => {
          selectedWebhookId && deleteWebhook(selectedWebhookId);
          setDeleteConfirmationModalOpen(false);
          setSelectedWebhookId(null);
        }}
        onCancel={() => setDeleteConfirmationModalOpen(false)}
      />
      <EditInspector isActive={!!showEditInspector} />
      <AddInspector isActive={!!showAddInspector} />
    </>
  );
}

function mapStateToProps(state: PartialAppState): StateProps {
  return {
    webhooks: state.webhooks.webhooks,
    moreWebhooksToLoad: state.webhooks.app.moreWebhooksToLoad
  };
}

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    loadWebhooks: (offset) => dispatch(getWebhooks(offset)),
    deleteWebhook: (webhookId) => dispatch(deleteWebhook(webhookId)),
    updateWebhookStatus: (webhookId, status) => dispatch(updateWebhookStatus(webhookId, status)),
    editWebhook: (webhookId) => dispatch(getWebhook(webhookId)),
    openAddInspector: () => dispatch(openAddWebhookInspector()),
    resetWebhookData: () => dispatch(resetWebhookData()),
    viewLogs: (webhookId) => dispatch(navigate(WEBHOOKS_LOG_PATH, { id: webhookId }))
  };
};
export default compose(connect(mapStateToProps, mapDispatchToProps), wrapPage({}))(Webhooks);
export { Webhooks };
