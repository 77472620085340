/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { INITIAL_AMP_STATE, State } from "./state";
import actions, { ActionTypes } from "./actions";

export default function ampReducer(state: State = INITIAL_AMP_STATE, action: ActionTypes) {
  switch (action.type) {
    case actions.LIST_AMP_CONFIG: {
      return {
        ...state,
        ui: {
          ...state.ui,
          main: { loading: true, error: null }
        }
      };
    }

    case actions.LIST_AMP_CONFIG_SUCCESS: {
      return {
        ...state,
        ampConfig: action.payload.ampConfig,
        ui: {
          ...state.ui,
          main: { loading: false, error: null },
          isAmpConfigModified: false,
          saveErrors: { loading: false, error: null }
        }
      };
    }

    case actions.LIST_AMP_CONFIG_FAILURE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          main: { loading: false, error: action.payload.error }
        }
      };
    }
    case actions.UPDATE_AMP_CONFIG: {
      return {
        ...state,
        ampConfig: {
          ...state.ampConfig,
          [action.payload.key]: action.payload.changes
        },
        ui: {
          ...state.ui,
          isAmpConfigModified: true
        }
      };
    }
    case actions.SET_ERRORS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          saveErrors: { loading: false, error: action.payload.errors }
        }
      };
    }
    default:
      return state;
  }
}
