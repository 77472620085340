/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { signPollFile, uploadPollFileDetails } from "api/polls";

interface Response {
  [key: string]: string;
}

interface SignedResponse {
  success: boolean;
  file: { name: string; type: string };
  "mime-type": string;
  signature: Response;
}

const uploadFile = (signedResponse: SignedResponse, callback: (value: {}) => void) => {
  const formData = {
    key: signedResponse.signature["key"],
    "Content-Type": signedResponse.signature["Content-Type"],
    success_action_status: signedResponse.signature["success_action_status"],
    policy: signedResponse.signature["policy"],
    AWSAccessKeyId: signedResponse.signature["AWSAccessKeyId"],
    signature: signedResponse.signature["signature"],
    acl: signedResponse.signature["acl"],
    file: signedResponse.file
  };
  const key = signedResponse.signature.key;
  const successResponse = {
    success: true,
    key: key,
    url: signedResponse.signature.action + key,
    "mime-type": signedResponse["mime-type"]
  };

  uploadPollFileDetails(signedResponse.signature.action, formData)
    .then((res) => callback(successResponse))
    .catch(() => callback({ success: false }));
};

const s3FileUpload = (file: { name: string; type: string }, callback: (value: {}) => void) => {
  signPollFile({ "file-name": file.name, "mime-type": file.type }).then(
    (response) => {
      let signedResponse = {
        success: true,
        file: file,
        signature: response,
        "mime-type": file.type
      };
      return uploadFile(signedResponse, callback);
    },
    (error) => callback({ success: false })
  );
};

export { s3FileUpload };
