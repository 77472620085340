/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const ENTITIES_LOADING = "@@entities/LOADING";
export const ENTITIES_FETCH_SELECTED_TEMPLATE_OPTIONS = "@@entities/FETCH_SELECTED_TEMPLATE_OPTIONS";
export const ENTITIES_SET_DATA = "@@entities/SET_DATA";
export const ENTITIES_SET_SELECTED_ENTITY = "@@entities/SET_SELECTED_ENTITY";
export const ENTITIES_SET_SELECTED_TEMPLATE_OPTIONS = "@@entities/SET_SELECTED_TEMPLATE_OPTIONS";
export const ENTITIES_SET_FIELD_EMPTY_ERROR = "@@entities/SET_FIELD_EMPTY_ERROR";
export const ENTITIES_SET_SELECTED_ENTITY_IMAGES = "@@entities/SET_SELECTED_IMAGES";
export const ENTITIES_UPDATE_PAGINATION = "@@entities/UPDATE_PAGINATION";
export const ENTITIES_UPDATE_CURRENT_PAGE = "@@entities/UPDATE_CURRENT_PAGE";
export const ENTITIES_UPDATE_SEARCH_TERM = "@@entities/UPDATE_SEARCH_TERM";
export const ENTITIES_UPDATE_SELECTED_ENTITY = "@@entities/UPDATE_SELECTED_ENTITY";
export const ENTITIES_UPDATE_SELECTED_ENTITY_IMAGES_DATA = "@@entities/UPDATE_SELECTED_ENTITY_IMAGES_DATA";
export const ENTITIES_UPDATE_SELECTED_ENTITY_IMAGES_UPLOAD_STATUS =
  "@@entities/UPDATE_SELECTED_ENTITY_IMAGES_UPLOAD_STATUS";
export const ENTITIES_UPDATE_FIELD_IMAGE_ERROR = "@@entities/UPDATE_FIELD_IMAGE_ERROR";
export const ENTITIES_RESET_SELECTED_ENTITY = "@@entities/RESET_SELECTED_ENTITY";
export const ENTITIES_REMOVE_FIELD_ERROR = "@@entities/REMOVE_FIELD_ERROR";
export const ENTITIES_IMAGE_UPLOAD_KEY = "@@entities/ENTITIES_IMAGE_UPLOAD_KEY";
