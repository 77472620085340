/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import {
  ContentType,
  CollectionItemWrapper,
  Collection,
  CoverImage,
  AssociatedMetadata,
  AnyCollection,
  UnsavedAutomatedCollectionWithRules
} from "api/story-collection";
import { ListCollectionItemsResponse, Story } from "api/collection-items";
import { CollectionTemplateOptions } from "api/template-options";

export enum actions {
  ADD_ITEM = "@@collections/ADD_ITEM",
  CHANGE_PAGE_VIEWS = "@@collections/CHANGE_PAGE_VIEWS",
  CHANGE_COLLECTION_LAYOUT = "@@collections/CHANGE_COLLECTION_LAYOUT",
  CANCEL_CARD_EXPAND = "@@collections/CANCEL_CARD_EXPAND",

  LOAD_LHS_COLLECTION_ITEMS = "@@collections/LOAD_LHS_COLLECTION_ITEMS",
  SET_LHS_REFRESH_STATE = "@@collections/SET_LHS_REFRESH_STATE",
  LOAD_MORE_LHS_COLLECTION_ITEMS = "@@collections/LOAD_MORE_LHS_COLLECTION_ITEMS",
  LOAD_TEMPLATE_OPTIONS = "@@collections/LOAD_TEMPLATE_OPTIONS",
  PUBLISHED = "@@collections/PUBLISHED",
  PUBLISHING = "@@collections/PUBLISHING",
  RESET_FILTER = "@@collections/RESET_FILTER",
  SET_IS_COLLECTION_MODIFIED_STATE = "@@collections/SET_IS_COLLECTION_MODIFIED_STATE",
  RESET = "@@collections/RESET",
  RESET_ERRORS = "@@collections/RESET_ERRORS",
  RESET_STAGING_SELECTED_COLLECTION = "@@collections/RESET_STAGING_SELECTED_COLLECTION",
  REMOVE_ITEM = "@@collections/REMOVE_ITEM",
  REMOVE_COVER_IMAGE = "@@collections/REMOVE_COVER_IMAGE",
  REMOVE_FIELD_ERROR = "@@collections/REMOVE_FIELD_ERROR",
  SET_SELECTED_COLLECTION = "@@collections/SET_SELECTED_COLLECTION",
  SET_COVER_IMAGES = "@@collections/SET_COVER_IMAGES",
  SET_FIELD_EMPTY_ERROR = "@@collections/SET_FIELD_EMPTY_ERROR",
  SET_STAGING_ASSOCIATED_METADATA = "@@collections/SET_STAGING_ASSOCIATED_METADATA",
  SWITCH_LANE_TAB = "@@collections/SWITCH_LANE_TAB",
  TOGGLE_COVER_IMAGE_UPLOADING = "@@collections/TOGGLE_COVER_IMAGE_UPLOADING",
  TOGGLE_ADVANCED_SEARCH = "@@collections/TOGGLE_ADVANCED_SEARCH",
  CLOSE_ADVANCED_SEARCH = "@@collections/CLOSE_ADVANCED_SEARCH",
  TOGGLE_CARD_EXPAND = "@@collections/TOGGLE_CARD_EXPAND",
  UPDATE_STAGING_SELECTED_COLLECTION = "@@collections/UPDATE_STAGING_SELECTED_COLLECTION",
  UPDATE_SELECTED_COLLECTION = "@@collections/UPDATE_SELECTED_COLLECTION",
  UPDATE_SWAPPED_ITEMS = "@@collections/UPDATE_SWAPPED_ITEMS",
  UPDATE_SORTED_ITEMS = "@@collections/UPDATE_SORTED_ITEMS",
  UPDATE_FILTER = "@@collections/UPDATE_FILTER",
  UPDATE_PAGE_VIEWS = "@@collections/UPDATE_PAGE_VIEWS",
  UPDATE_STAGING_ASSOCIATED_METADATA = "@@collections/UPDATE_STAGING_ASSOCIATED_METADATA",
  UPDATE_COLLECTION_CARD = "@@collections/UPDATE_COLLECTION_CARD",
  UPDATE_COVER_IMAGE = "@@collections/UPDATE_COVER_IMAGE",
  UPDATE_COVER_IMAGE_DATA = "@@collections/UPDATE_COVER_IMAGE_DATA",
  UPDATE_EDITED_COVER_IMAGE = "@@collections/UPDATE_EDITED_COVER_IMAGE",
  UPDATE_STAGING_SELECTED_COLLECTION_METADATA = "@@collections/UPDATE_STAGING_SELECTED_COLLECTION_METADATA",
  UPDATE_STAGING_SELECTED_COLLECTION_RULES = "@@collections/UPDATE_STAGING_SELECTED_COLLECTION_RULES",
  PDF_UPLOAD = "@@collections/PDF_UPLOAD",
  PDF_UPLOAD_SUCCESS = "@@collections/PDF_UPLOAD_SUCCESS",
  PDF_UPLOAD_FAILURE = "@@collections/PDF_UPLOAD_FAILURE",

  LHS_LOAD_START = "@@collections/LHS_LOAD_START",
  LHS_LOAD_END = "@@collections/LHS_LOAD_START",
  LHS_LOAD_SUCCESS = "@@collections/LHS_LOAD_SUCCESS",
  LHS_LOAD_FAILURE = "@@collections/LHS_LOAD_FAILURE",

  SINGLE_COLLECTION_PAGE_LOADING = "@@collections/SINGLE_COLLECTION_PAGE_LOADING",
  SINGLE_COLLECTION_PAGE_LOAD_SUCCESS = "@@collections/SINGLE_COLLECTION_PAGE_LOAD_SUCCESS",
  SINGLE_COLLECTION_PAGE_LOAD_FAILURE = "@@collections/SINGLE_COLLECTION_PAGE_LOAD_FAILURE",

  SET_STORY_CARD_PAGE_VIEWS_SUCCESS = "@@collections/SET_STORY_CARD_PAGE_VIEWS_SUCCESS",
  SET_MORE_STORY_CARD_PAGE_VIEWS_SUCCESS = "@@collections/SET_MORE_STORY_CARD_PAGE_VIEWS_SUCCESS",
  SET_STORY_CARD_PAGE_VIEWS_ERROR = "@@collections/SET_STORY_CARD_PAGE_VIEWS_ERROR",

  OPEN_PHOTO_EDITOR = "@@collections/OPEN_PHOTO_EDITOR",
  CLOSE_PHOTO_EDITOR = "@@collections/CLOSE_PHOTO_EDITOR",
  SET_SCHEDULE_COLLECTION_DATE_ERROR = "@@collections/SET_SCHEDULE_COLLECTION_DATE_ERROR",
  TOGGLE_POLYGON_SELECTION = "@@collections/TOGGLE_POLYGON_SELECTION",
  UPDATE_GEO_POLYGON = "@@collections/UPDATE_GEO_POLYGON"
}

export type AddItemAction = { type: actions.ADD_ITEM; payload: { item: CollectionItemWrapper } };
export type ChangePageViewsAction = { type: actions.CHANGE_PAGE_VIEWS };
export type ChangeCollectionLayoutAction = { type: actions.CHANGE_COLLECTION_LAYOUT };
export type CancelCardExpandAction = { type: actions.CANCEL_CARD_EXPAND };

export type LoadLhsCollectionItemsAction = {
  type: actions.LOAD_LHS_COLLECTION_ITEMS;
  payload: { contentType: ContentType; data: ListCollectionItemsResponse };
};

export type RefreshContentAction = {
  type: actions.SET_LHS_REFRESH_STATE;
  payload: { refreshingLHS: boolean };
};

export type LoadMoreLhsCollectionItemsAction = {
  type: actions.LOAD_MORE_LHS_COLLECTION_ITEMS;
  payload: { contentType: ContentType; data: ListCollectionItemsResponse };
};
export type LoadTemplateOptionsAction = {
  type: actions.LOAD_TEMPLATE_OPTIONS;
  payload: { templateOptions: CollectionTemplateOptions };
};
export type PublishedAction = { type: actions.PUBLISHED; payload: { collection: Collection } };
export type PublishingAction = { type: actions.PUBLISHING };
export type ResetFilterAction = { type: actions.RESET_FILTER };
export type ResetAction = { type: actions.RESET };
export type ResetErrorsAction = { type: actions.RESET_ERRORS };
export type ResetStagingSelectedCollectionAction = {
  type: actions.RESET_STAGING_SELECTED_COLLECTION;
};
export type RemoveItemAction = { type: actions.REMOVE_ITEM; payload: { item: CollectionItemWrapper } };
export type RemoveCoverImageAction = { type: actions.REMOVE_COVER_IMAGE };
export type RemoveFieldErrorAction = { type: actions.REMOVE_FIELD_ERROR; payload: { field: string } };
export type SetSelectedCollectionAction = {
  type: actions.SET_SELECTED_COLLECTION;
  payload: { collection: AnyCollection | UnsavedAutomatedCollectionWithRules };
};
export type SetCoverImagesAction = { type: actions.SET_COVER_IMAGES; payload: { images: CoverImage[] } };
export type SetFieldEmptyErrorAction = { type: actions.SET_FIELD_EMPTY_ERROR; payload: { field: string } };
export type SetStagingAssociatedMetadataAction = {
  type: actions.SET_STAGING_ASSOCIATED_METADATA;
  payload: { associatedMetadata: AssociatedMetadata };
};
export type SwitchLaneTabAction = { type: actions.SWITCH_LANE_TAB };
export type ToggleCoverImageUploadingAction = { type: actions.TOGGLE_COVER_IMAGE_UPLOADING };
export type ToggleAdvancedSearchAction = { type: actions.TOGGLE_ADVANCED_SEARCH };
export type CloseAdvancedSearchAction = { type: actions.CLOSE_ADVANCED_SEARCH };

export type ToggleCardExpandAction = {
  type: actions.TOGGLE_CARD_EXPAND;
  payload: { index: number };
};
export type UpdateStagingSelectedCollectionAction = {
  type: actions.UPDATE_STAGING_SELECTED_COLLECTION;
  payload: { key: string; value: any };
};
export type UpdateSelectedCollectionAction = { type: actions.UPDATE_SELECTED_COLLECTION };

export type UpdateSwappedItemsAction = { type: actions.UPDATE_SWAPPED_ITEMS };
export type UpdateSortedItemsAction = { type: actions.UPDATE_SORTED_ITEMS };
export type UpdateFilterAction = { type: actions.UPDATE_FILTER };
export type UpdatePageViewsAction = { type: actions.UPDATE_PAGE_VIEWS };
export type UpdateStagingAssociatedMetadataAction = {
  type: actions.UPDATE_STAGING_ASSOCIATED_METADATA;
};
export type UpdateCollectionCardAction = { type: actions.UPDATE_COLLECTION_CARD };
export type UpdateCoverImageAction = { type: actions.UPDATE_COVER_IMAGE };
export type UpdateCoverImageDataAction = { type: actions.UPDATE_COVER_IMAGE_DATA };
export type UpdateStagingSelectedCollectionMetadataAction = {
  type: actions.UPDATE_STAGING_SELECTED_COLLECTION_METADATA;
};
export type UpdateStagingSelectedCollectionRulesAction = {
  type: actions.UPDATE_STAGING_SELECTED_COLLECTION_RULES;
  payload: { key: string; value: any };
};
export type PdfUploadAction = { type: actions.PDF_UPLOAD; payload: string };
export type PdfUploadSuccess = { type: actions.PDF_UPLOAD_SUCCESS };
export type PdfUploadFailure = { type: actions.PDF_UPLOAD_FAILURE };
export type LhsLoadStartAction = { type: actions.LHS_LOAD_START };
export type LhsLoadEndAction = { type: actions.LHS_LOAD_END };
export type LhsLoadSuccessAction = { type: actions.LHS_LOAD_SUCCESS };
export type LhsLoadFailureAction = { type: actions.LHS_LOAD_FAILURE };
export type SingleCollectionPageLoadingAction = { type: actions.SINGLE_COLLECTION_PAGE_LOADING };
export type SingleCollectionPageLoadSuccessAction = {
  type: actions.SINGLE_COLLECTION_PAGE_LOAD_SUCCESS;
};
export type SingleCollectionPageLoadFailureAction = {
  type: actions.SINGLE_COLLECTION_PAGE_LOAD_FAILURE;
  payload: { error: Error };
};

export type SetStoryCardPageViewsSuccess = {
  type: actions.SET_STORY_CARD_PAGE_VIEWS_SUCCESS;
  payload: { stories: Story[] };
};

export type SetStoryCardPageViewsError = {
  type: actions.SET_STORY_CARD_PAGE_VIEWS_ERROR;
  payload: { error: Error };
};

export type setScheduleCollectionDateError = {
  type: "@@collections/SET_SCHEDULE_COLLECTION_DATE_ERROR";
  payload: { field: string };
};

export type TogglePolygonSelectionAction = {
  type: actions.TOGGLE_POLYGON_SELECTION;
};

export type UpdateGeoPolygon = {
  type: actions.UPDATE_GEO_POLYGON;
  payload: { geoPolygon: Array<google.maps.LatLngLiteral> };
};

export type SetIsCollectionModifiedStateAction = {
  type: actions.SET_IS_COLLECTION_MODIFIED_STATE;
  payload: { isCollectionModified: boolean };
};

export type ActionTypes =
  | AddItemAction
  | ChangePageViewsAction
  | ChangeCollectionLayoutAction
  | CancelCardExpandAction
  | LoadLhsCollectionItemsAction
  | RefreshContentAction
  | LoadTemplateOptionsAction
  | PublishedAction
  | PublishingAction
  | ResetFilterAction
  | ResetAction
  | ResetErrorsAction
  | ResetStagingSelectedCollectionAction
  | RemoveItemAction
  | RemoveCoverImageAction
  | RemoveFieldErrorAction
  | SetSelectedCollectionAction
  | SetCoverImagesAction
  | SetFieldEmptyErrorAction
  | SetStagingAssociatedMetadataAction
  | SwitchLaneTabAction
  | ToggleCoverImageUploadingAction
  | ToggleAdvancedSearchAction
  | ToggleCardExpandAction
  | UpdateStagingSelectedCollectionAction
  | UpdateSelectedCollectionAction
  | UpdateSwappedItemsAction
  | UpdateSortedItemsAction
  | UpdateFilterAction
  | UpdatePageViewsAction
  | UpdateStagingAssociatedMetadataAction
  | UpdateCollectionCardAction
  | UpdateCoverImageAction
  | UpdateCoverImageDataAction
  | UpdateStagingSelectedCollectionMetadataAction
  | UpdateStagingSelectedCollectionRulesAction
  | LhsLoadStartAction
  | LhsLoadEndAction
  | LhsLoadSuccessAction
  | LhsLoadFailureAction
  | SingleCollectionPageLoadingAction
  | SingleCollectionPageLoadSuccessAction
  | SingleCollectionPageLoadFailureAction
  | setScheduleCollectionDateError
  | PdfUploadAction
  | PdfUploadSuccess
  | PdfUploadFailure
  | TogglePolygonSelectionAction
  | UpdateGeoPolygon
  | SetIsCollectionModifiedStateAction;

export default actions;
