/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Story, StoryElement, CompositeStoryElement, ChildStoryElement, StoryElements } from "api/story";
import { CardId } from "api/primitive-types";
import { removeKeyFromObject } from "utils";
import { flatten, isEmpty } from "lodash";

function newCompositeAndChildStoryElements(
  allStoryElements: StoryElements,
  newCompositeStoryElement: CompositeStoryElement
) {
  const childStoryElements = newCompositeStoryElement.tree.map(
    (storyElementId) => allStoryElements && allStoryElements[storyElementId]
  );
  const newChildStoryElements = childStoryElements.map(({ "family-id": _, ...childElement }) => {
    return {
      ...childElement,
      id: "new-story-element-" + Math.random(),
      "composite-element-id": newCompositeStoryElement.id,
      "client-id": "client-story-element-" + Math.random()
    };
  });
  return [
    { ...newCompositeStoryElement, tree: newChildStoryElements.map((storyElement) => storyElement.id) },
    ...newChildStoryElements
  ];
}

function newCardAndStoryElements(
  allStoryElements: StoryElements,
  importStoryElements: Array<StoryElement | CompositeStoryElement | ChildStoryElement>
) {
  const id = "new-card-" + Math.random();
  const newElements = importStoryElements.map(({ "family-id": _, ...storyElement }) => {
    const element = {
      ...storyElement,
      id: "new-story-element-" + Math.random(),
      "card-id": id,
      "client-id": "client-story-element-" + Math.random()
    };
    if (element.hasOwnProperty("card-version-id")) {
      return removeKeyFromObject("card-version-id", element);
    } else {
      return element;
    }
  });

  const newCard = {
    id,
    "content-id": id,
    "client-id": "client-card-" + Math.random(),
    tree: newElements.map((storyElement) => storyElement.id),
    metadata: {
      "social-share": {},
      attributes: {}
    },
    "content-type": "card"
  };

  const newStoryElements = newElements.filter((element) => !element.hasOwnProperty("tree"));
  const newCompositeStoryElements = newElements.filter((element) => element.hasOwnProperty("tree"));

  const compositeStoryElementsAndChild = isEmpty(newCompositeStoryElements)
    ? []
    : flatten(
        newCompositeStoryElements.map((element) =>
          newCompositeAndChildStoryElements(allStoryElements, element as CompositeStoryElement)
        )
      );

  return {
    newCard,
    newStoryElements: [...newStoryElements, ...compositeStoryElementsAndChild]
  };
}

export function convertImportDataToNew(importStory: Story, importCardId: CardId) {
  const allStoryElements = importStory["story-elements"];
  const importCardData = importStory.cards[importCardId];
  const importStoryElementIds = importCardData.tree ? importCardData.tree : [];
  const importStoryElements = importStoryElementIds.map(
    (storyElementId) => allStoryElements && allStoryElements[storyElementId]
  );

  return newCardAndStoryElements(allStoryElements, importStoryElements);
}
