/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { RouteComponentProps } from "react-router";
import { format } from "date-fns";

import Button from "components/button/button";
import CheckCircle from "components/icons/check-circle";
import Circle from "components/icons/circle";
import wrapPage from "containers/page/page";
import { LoaderState } from "behaviors/loader/state";
import { Task, Image } from "api/tasks";
import { TaskId, StoryId, ImageId } from "api/primitive-types";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import { STORY_EDITOR_PATH, STORY_EDITOR_PATH_REFRESH } from "../../story-editor/routes";

import { loadTask, loadAllTasks, closeTask, closeTaskAndRefreshTaskList } from "./async-action-creators";
import Loader from "./loader";
import { PartialAppState, Media } from "./state";
import { TASKS_INDEX_PATH, TASK_PATH, TASK_NEW_IMAGE_PATH } from "./routes";
import TaskInspector from "./inspector/inspector";
import MyTaskList from "./mytasks/mytasks";

import styles from "./tasks.module.css";
import { t } from "i18n";

import { navigate } from "utils/routes.utils";
import { setTaskImage, showInspector, updateImageUploadStatus } from "./async-action-creators";
import MediaGallery from "pages/media-library/components/media-gallery/media-gallery";

import { Asana } from "api/route-data/route-data";
import TaskManagerLogin from "./taskmanagerlogin/taskmanagerlogin";

interface StateProps {
  currentTask: Task | null;
  taskLoader: LoaderState;
  media: Media;
  displayInspector: boolean;
  isUploading: boolean;
  isLoggedIn: boolean;
  asana: Asana;
}

interface DispatchProp {
  loadTask: (id: TaskId) => void;
  loadAllTasks: () => void;
  closeTask: (id: TaskId) => void;
  closeTaskAndRefreshTaskList: (id: TaskId) => void;
  openStory: (id: StoryId) => void;
  createNewStory: (id: TaskId) => void;
  setTaskImage: (image: Image[]) => void;
  switchToImageEdit: (mediaKey: any, id: String) => void;
  showInspector: (showInspector: boolean) => void;
  updateImageUploadStatus: (status: boolean) => void;
  openTask: (id: TaskId) => void;
}

type Props = RouteComponentProps<any> & DispatchProp & StateProps & { title: string };

const fetchTaskStatus = (task: Task) => {
  const style = getComputedStyle(document.body);
  const status = task.completed ? (
    <span className={styles["subtask-status"]} data-test-id="subtask-status">
      <CheckCircle color={style.getPropertyValue("--success")} width={"20"} height={"20"} />
    </span>
  ) : (
    <span className={styles["subtask-status"]} data-test-id="subtask-status">
      <Circle strokeColor={style.getPropertyValue("--mono-3")} radius={8} fillColor={"#fff"} />
    </span>
  );
  return status;
};

export const handleAssociatedStory = (
  task: Task,
  openStory: (id: StoryId) => void,
  createNewStory: (id: TaskId) => void
) => {
  const button = task["story-id"] ? (
    <Button
      testId="open-task-story-button"
      type="secondary"
      onClick={(event) => {
        if (event && event.stopPropagation) event.stopPropagation();
        openStory(task["story-id"]!);
      }}>
      {t("tasks.story.open")}
    </Button>
  ) : (
    <Button
      testId="create-task-story-button"
      type="secondary"
      onClick={(event) => {
        if (event && event.stopPropagation) event.stopPropagation();
        createNewStory(task.id);
      }}>
      {t("tasks.story.create")}
    </Button>
  );
  return button;
};

export const fetchTaskCompletionStats = (subTasks: Task[]) => {
  const completedTasks = subTasks.filter((task) => task.completed).length;
  const totalCount = subTasks.length;
  return `${t("tasks.subtasks.title")} (${completedTasks}/${totalCount})`;
};

export const TaskPage: React.SFC<Props> = ({
  taskLoader,
  media,
  isUploading,
  currentTask,
  closeTask,
  createNewStory,
  openStory,
  setTaskImage,
  switchToImageEdit,
  isLoggedIn,
  updateImageUploadStatus,
  asana
}) => {
  return (
    <React.Fragment>
      {isLoggedIn ? (
        <LoaderWrapper className="tasks-progress-main-area" component={Loader} loader={taskLoader}>
          {currentTask && (
            <div>
              <div className={styles["task-header"]}>
                <h3 className={styles["task-page-title"]}>{currentTask.name}</h3>
                <div className={styles["task-action-buttons-container"]}>
                  {handleAssociatedStory(currentTask, openStory, createNewStory)}
                  {!currentTask.completed && (
                    <Button
                      testId="close-parent-task"
                      type="secondary"
                      onClick={() => {
                        closeTask(currentTask.id);
                      }}>
                      {t("tasks.task_action.close")}
                    </Button>
                  )}
                </div>
              </div>

              {currentTask.subtasks && (
                <section className={styles["subtask-container"]}>
                  <h4 className={styles["subtask-title"]}>{fetchTaskCompletionStats(currentTask.subtasks)}</h4>

                  <div className="subtask-row subtask-header">
                    <span>{t("tasks.subtasks.item")}</span>
                    <span>{t("tasks.subtasks.assigned_to")}</span>
                    <span>{t("tasks.subtasks.deadline")}</span>
                  </div>
                  {currentTask.subtasks &&
                    currentTask.subtasks.map((value: Task, index: number) => (
                      <div className={styles["subtask-row"]} key={`subtask-${index}`}>
                        <div className={styles["subtask-name"]}>
                          {fetchTaskStatus(value)}
                          <span>{value.name}</span>
                        </div>
                        <span>{value.assignee ? value.assignee.name : t("tasks.subtasks.not_assigned")}</span>
                        <span>{value["due-on"] ? format(new Date(value["due-on"] as string), "dd MMM yyyy") : ""}</span>
                        {!value.completed && (
                          <Button
                            type="default"
                            testId="close-subtask"
                            onClick={() => {
                              closeTask(value.id);
                            }}>
                            {t("tasks.task_action.close")}
                          </Button>
                        )}
                      </div>
                    ))}
                </section>
              )}
              <div className={styles["media-gallery-container"]}>
                <h4 className={styles["subtask-title"]}>{t("tasks.media.uploaded_media")}</h4>
                <MediaGallery
                  showSelectMediaProvider={false}
                  hideMediaGalleryActions={true}
                  enableMultipleUploads={true}
                  switchToUploadRoute={(mediaKey: any) => switchToImageEdit(mediaKey, currentTask.id)}
                  setSelectedMedia={(image: Image[]) => setTaskImage(image)}
                  updateImageUploadStatus={(status: { uploading: boolean }) =>
                    updateImageUploadStatus(status.uploading)
                  }
                  showEditImage={true}
                  opts={{ "task-id": currentTask.id }}
                />
              </div>
              <TaskInspector />
            </div>
          )}
        </LoaderWrapper>
      ) : (
        <TaskManagerLogin asana={asana} />
      )}
    </React.Fragment>
  );
};

export class TasksWithRoutes extends React.Component<Props> {
  async componentDidMount() {
    this.onEnter();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.match.url !== prevProps.match.url) {
      this.onEnter();
    }
  }

  onEnter() {
    switch (this.props.match.path) {
      case TASKS_INDEX_PATH:
        this.props.showInspector(false);
        this.props.loadAllTasks();
        break;
      case TASK_PATH:
        this.props.showInspector(false);
        if (this.props.currentTask === null || this.props.currentTask.id !== this.props.match.params.id)
          this.props.loadTask(this.props.match.params.id);
        break;
      case TASK_NEW_IMAGE_PATH:
        if (this.props.currentTask === null || this.props.currentTask.id !== this.props.match.params.id)
          this.props.loadTask(this.props.match.params.id);
        this.props.showInspector(true);
        break;
      default:
        break;
    }
  }

  render() {
    switch (this.props.match.path) {
      case TASKS_INDEX_PATH:
        return <MyTaskList {...this.props} />;

      case TASK_PATH:
        return <TaskPage {...this.props} />;

      case TASK_NEW_IMAGE_PATH:
        return <TaskPage {...this.props} />;

      default:
        return <MyTaskList {...this.props} />;
    }
  }
}

const mapStateToProps = (state: PartialAppState): StateProps & { title: string } => {
  return {
    isLoggedIn: state.tasks.app.isLoggedIn,
    currentTask: state.tasks.app.currentTask,
    taskLoader: state.tasks.ui.taskLoader,
    media: state.tasks.app.media,
    displayInspector: state.tasks.ui.showInspector,
    isUploading: state.tasks.app.media.isUploading,
    asana: state.config.asana,
    title: "tasks"
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProp => {
  return {
    setTaskImage: (image: Image[]) => dispatch(setTaskImage(image)),
    loadTask: (id: TaskId) => dispatch(loadTask(id)),
    loadAllTasks: () => dispatch(loadAllTasks()),
    openTask: (id: TaskId) => dispatch(navigate(TASK_PATH, { id })),
    closeTask: (id: TaskId) => dispatch(closeTask(id)),
    closeTaskAndRefreshTaskList: (id: TaskId) => dispatch(closeTaskAndRefreshTaskList(id)),
    openStory: (id: StoryId) => (window.location.href = `${STORY_EDITOR_PATH_REFRESH}${id}`),
    switchToImageEdit: (id: ImageId, taskId: String) =>
      dispatch(navigate(TASK_NEW_IMAGE_PATH, { imageId: id, id: taskId })),
    createNewStory: (id: TaskId) =>
      dispatch(
        navigate(
          STORY_EDITOR_PATH,
          {
            id: "new"
          },
          {
            provider: "asana",
            "task-id": id
          }
        )
      ),
    showInspector: (displayInspector: boolean) => dispatch(showInspector(displayInspector)),
    updateImageUploadStatus: (status: boolean) => dispatch(updateImageUploadStatus(status))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), wrapPage())(TasksWithRoutes);
