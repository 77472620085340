/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import TextField from "components/text-field/text-field";
import ProseMirrorTextArea from "components/prosemirror-text-area/prosemirror-text-area";
import AsyncSelect from "components/select/async";
import AsyncCreatableSelect from "components/select/async-creatable";
import Select from "components/select/select";
import { loadTags } from "pages/collections/utils";
import Breadcrumbs from "components/breadcrumbs/breadcrumbs";
import "react-day-picker/lib/style.css";
import { t } from "i18n";
import {
  MetadataFields,
  AdditionalMetadataField,
  AdditionalMetadataFieldValue
} from "api/route-data/collection-route-data";
import { AnyCollection, Metadata as MetadataType } from "api/story-collection";
import { Section } from "api/route-data/route-data";
import { getEntities } from "helpers/api";
import { EntityInCollection } from "api/entity";
import FieldLabel from "components/field-label/field-label";
import DatePickerCustom from "components/date-picker/date-picker";

import { kebabCase } from "lodash";
import styles from "./metadata.module.css";

interface Props {
  metadataFields: MetadataFields;
  stagingSelectedCollection: AnyCollection;
  updateStagingSelectedCollection: (key: string, value: any) => void;
  updateStagingSelectedCollectionMetadata: (key: string, value: any) => void;
  sections: Section[];
  additionalMetadataFields: AdditionalMetadataField[];
  updateStagingSelectedCollectionEntities: (
    selectedEntities: EntityInCollection[],
    entityType: string,
    metadata: MetadataType
  ) => void;
  enabledEntityTypes: string[];
  isAdditionalCollectionMetadataEnabled: boolean;
  hasTagCreatePermission: boolean;
}

const getAdditionalMetadataValue = (
  metadata: AdditionalMetadataFieldValue | string,
  defaultValue: AdditionalMetadataFieldValue | string | undefined
) => {
  if (metadata && Array.isArray(metadata)) {
    return metadata[0];
  } else {
    return [defaultValue];
  }
};

const generateOptions = (values: Array<AdditionalMetadataFieldValue | string> | undefined) => {
  if (values) {
    return values.map((value: string, index: number) => ({ id: index + 1, name: value, key: kebabCase(value) }));
  }
  return [];
};

const Metadata: React.SFC<Props> = ({
  metadataFields,
  stagingSelectedCollection,
  updateStagingSelectedCollection,
  updateStagingSelectedCollectionMetadata,
  sections,
  additionalMetadataFields,
  updateStagingSelectedCollectionEntities,
  enabledEntityTypes,
  isAdditionalCollectionMetadataEnabled,
  hasTagCreatePermission
}) => (
  <div className={styles["collections-metadata-settings"]}>
    {metadataFields.tags && (
      <AsyncCreatableSelect
        value={stagingSelectedCollection.metadata.tags}
        onChange={(tags) => updateStagingSelectedCollectionMetadata("tags", tags)}
        getNewOptionData={(inputValue: string, optionLabel: string) => ({
          name: optionLabel
        })}
        getOptionLabel={(tag) => tag.name}
        getOptionValue={(tag) => tag.name}
        loadOptions={(term: string) => loadTags(term).then(({ tags }: any) => tags)}
        isMulti={true}
        label={t("collections.metadata.tags")}
        defaultOptions={true}
        cacheOptions={true}
        isValidNewOption={(inputValue, selectValue, selectOptions) =>
          hasTagCreatePermission
            ? inputValue.trim().length === 0 || selectOptions.find((option) => option.name === inputValue)
              ? false
              : true
            : false
        }
      />
    )}
    {/* Make this into a prosemirror/quill field */}
    {metadataFields.snapshot && (
      <ProseMirrorTextArea
        value={stagingSelectedCollection.metadata.snapshot ? stagingSelectedCollection.metadata.snapshot.body : ""}
        label={t("collections.metadata.snapshot")}
        onChange={(snapshot) => updateStagingSelectedCollectionMetadata("snapshot", { body: snapshot })}
      />
    )}
    {metadataFields.section && (
      <Select
        name="sections"
        label={t("collections.metadata.section")}
        value={stagingSelectedCollection.metadata.section}
        options={sections}
        onChange={(section) => updateStagingSelectedCollectionMetadata("section", [section])}
        getOptionLabel={(section) => section.name}
        getOptionValue={(section) => section.id.toString()}
        formatOptionLabel={(section) => (
          <React.Fragment>
            {section.name}
            <Breadcrumbs id={section.id} crumbs={sections} getCrumbLabel={(section) => section.name} />
          </React.Fragment>
        )}
      />
    )}
    {enabledEntityTypes &&
      enabledEntityTypes.map((entityType) => (
        <AsyncSelect
          value={
            stagingSelectedCollection.metadata.entities &&
            stagingSelectedCollection.metadata.entities.collectionEntities &&
            stagingSelectedCollection.metadata.entities.collectionEntities[entityType]
          }
          onChange={(selectedEntities: EntityInCollection[]) =>
            updateStagingSelectedCollectionEntities(selectedEntities, entityType, stagingSelectedCollection.metadata)
          }
          getOptionLabel={(entity: EntityInCollection) => entity.name}
          getOptionValue={(entity: EntityInCollection) => entity.name}
          loadOptions={(term: string) => getEntities({ q: term, type: entityType }).then(({ entities }) => entities)}
          isMulti={true}
          label={`Associate to ${entityType}`}
          defaultOptions={true}
          cacheOptions={true}
        />
      ))}
    {metadataFields.price && (
      <TextField
        value={
          (stagingSelectedCollection["price-amount"] && stagingSelectedCollection["price-amount"].toString()) || ""
        }
        label={t("collections.metadata.price")}
        onChange={(value) => updateStagingSelectedCollection("price-amount", parseInt(value, 10))}
      />
    )}
    {metadataFields.date && (
      <div className={styles["collection-date-picker"]}>
        <FieldLabel label={t("collections.metadata.collection_date")} />
        <DatePickerCustom
          onChange={(date: Date | number) => {
            if (!Number.isNaN(date as number)) {
              updateStagingSelectedCollection("collection-date", date);
            }
          }}
          selectedDate={stagingSelectedCollection["collection-date"]}
          showTimeZone={false}
          dateInUTC={true}
        />
      </div>
    )}
    <TextField
      value={stagingSelectedCollection.slug}
      label={t("collections.metadata.slug")}
      onChange={(value) => updateStagingSelectedCollection("slug", value.trim())}
    />
    {isAdditionalCollectionMetadataEnabled &&
      additionalMetadataFields &&
      additionalMetadataFields.map((field, index) => {
        const fieldSlug = field.slug;
        if (field.type === "string") {
          return (
            <TextField
              key={`collection-additional-metadata-${index}`}
              value={stagingSelectedCollection.metadata[fieldSlug]}
              label={field.name}
              onChange={(val) => updateStagingSelectedCollectionMetadata(fieldSlug, val)}
            />
          );
        } else if (field.type === "array") {
          return (
            <Select
              key={`collection-additional-metadata-${index}`}
              name={fieldSlug}
              label={field.name}
              value={getAdditionalMetadataValue(stagingSelectedCollection.metadata[fieldSlug], field.defaultValue)}
              options={generateOptions(field.values)}
              getOptionLabel={(value: AdditionalMetadataFieldValue) => value.name}
              getOptionValue={(value: AdditionalMetadataFieldValue) => value.name}
              onChange={(value) => updateStagingSelectedCollectionMetadata(fieldSlug, [value])}
            />
          );
        } else return null;
      })}
  </div>
);

export default Metadata;
