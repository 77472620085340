/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import styles from "./realtime-members.module.css";
import classnames from "classnames/bind";
import { t } from "i18n";

const cx = classnames.bind(styles);

const RealTimeMembers = ({ realtimeMembers, realtimeCurrentMember }) => {
  if (!realtimeCurrentMember) {
    return null;
  }
  const firstThreeMembers = realtimeMembers.slice(0, 3);
  return (
    <div className={styles["collection-realtime-members"]} data-test-id="collection-realtime-members">
      <ul className={styles["collection-realtime-members-list"]} data-test-id="collection-realtime-members-list">
        {firstThreeMembers.map((member, index) => (
          <li
            key={`realtime-members-${member.id}`}
            data-test-id="realtime-members"
            className={cx("collection-realtime-member", `member-${index + 1}`)}>
            <span
              className={styles["collection-realtime-member-image"]}
              data-test-id="collection-realtime-member-image">
              {member.avatarImageUrl && <img src={member.avatarImageUrl} alt={member.name} />}
            </span>
          </li>
        ))}
      </ul>
      {realtimeMembers && realtimeMembers.length > 3 && (
        <span className={styles["collection-ably-message"]} data-test-id="collection-ably-message">
          {t("collections.ably-members-message", { count: realtimeMembers.length - 3 })}
        </span>
      )}
      <ul className={styles["members-info-list"]} data-test-id="members-info-list">
        {realtimeMembers.map((member) => (
          <li className={styles["member-info"]} key={member.id} data-test-id="member-info">
            <span className={cx("collection-realtime-member-image", "dropdown-image")}>
              {member.avatarImageUrl && <img src={member.avatarImageUrl} alt={member.name} />}
            </span>
            <span className={styles["member-name"]} data-test-id="member-name">
              {member.name}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default RealTimeMembers;
