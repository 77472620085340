/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { CustomField } from "api/custom-field";
import { LoaderState, INITIAL_LOADER_STATE } from "behaviors/loader/state";
import { StoryTemplate } from "api/route-data/story-route-data";

export interface PartialAppState {
  customFields: State;
  config: {
    storyTemplates: StoryTemplate[];
  };
}

export enum InspectorType {
  Create = "create",
  Edit = "edit"
}

export interface State {
  customFields: Array<CustomField>;
  app: {
    customFieldToBeDeleted: CustomField | null;
    currentCustomField: CustomField | null;
  };
  ui: {
    main: LoaderState;
    save: LoaderState | null;
    delete: LoaderState | null;
    inspectorType: InspectorType | null;
  };
}

export const INITIAL_STATE: State = {
  customFields: [],
  app: {
    customFieldToBeDeleted: null,
    currentCustomField: null
  },
  ui: {
    main: INITIAL_LOADER_STATE,
    save: null,
    delete: null,
    inspectorType: null
  }
};
