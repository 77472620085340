/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";
import { ThunkDispatch } from "redux-thunk";
import { Location } from "history";
import { isEmpty } from "lodash";
import { PartialAppState, renderTab, resetState } from "store/collection/collections-dashboard";
import wrapPage from "containers/page/page";
import Header from "pages/collections/dashboard/components/header/header";
import Tabs from "pages/collections/dashboard/components/tabs/tabs";
import CollectionList from "pages/collections/dashboard/components/collection-list/collection-list";
import DeleteModal from "pages/collections/dashboard/components/modal/modal";
import Inspector from "pages/collections/dashboard/components/inspector/inspector";

interface StateProps {
  title: string;
}

interface DispatchProps {
  renderTab: (tab) => void;
  resetState: VoidFunction;
}

interface OwnProps {
  location: Location;
}

type Props = StateProps & DispatchProps & OwnProps;

const locationHashValue = (location: Location) => {
  const hashValue = location.hash.replace(/^#/, "");
  return isEmpty(hashValue) ? null : hashValue;
};

export const CollectionsDashboard: React.FC<Props> = ({ location, resetState, renderTab }) => {
  useEffect(() => {
    renderTab(locationHashValue(location));
  }, [renderTab, location]);

  useEffect(() => resetState, [resetState]);

  return (
    <>
      <Header />

      <Tabs />

      <CollectionList />

      <DeleteModal />

      <Inspector />
    </>
  );
};

function mapStateToProps(): StateProps {
  return {
    title: "collections-dashboard"
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps {
  return {
    renderTab: (tab) => dispatch(renderTab(tab)),
    resetState: () => dispatch(resetState())
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps), wrapPage({}))(CollectionsDashboard);
