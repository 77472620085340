/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import Sitemap from "./sitemap";
import { SITEMAP_PATH } from "./routes";

export const Router = () => (
  <React.Fragment>
    <Switch>
      <Route exact path={SITEMAP_PATH} component={Sitemap} />
    </Switch>
  </React.Fragment>
);
