/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const SpellChecker = ({ width = 24, height = 24, color = "currentColor" }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 24 24"
    width={width}
    height={height}>
    <title>spell-check</title>
    <path d="M21.89,13.3,21,12.37a.4.4,0,0,0-.57,0h0l-5.78,5.78L12,15.49a.39.39,0,0,0-.56,0h0l-.93.93a.39.39,0,0,0,0,.56h0l3.83,3.88a.39.39,0,0,0,.56,0h0l7-7a.4.4,0,0,0,0-.56ZM7.78,4.42a.55.55,0,0,0-.51-.36H6a.53.53,0,0,0-.5.36L3,11.82a.53.53,0,0,0,.34.67.45.45,0,0,0,.16,0h.56a.53.53,0,0,0,.5-.37L5,10.93H8.38l.41,1.22a.53.53,0,0,0,.5.37h.56a.53.53,0,0,0,.53-.53.51.51,0,0,0,0-.17ZM5.5,9.35,6.68,6l1.1,3.35Zm6.73,3.17h3A2.52,2.52,0,0,0,16.79,8a2.52,2.52,0,0,0-.61-3.5,2.56,2.56,0,0,0-1.44-.45H12.23a.79.79,0,0,0-.8.79v6.87a.8.8,0,0,0,.8.8ZM13,5.64h1.72a.93.93,0,0,1,0,1.85H13Zm0,3.44h2.25a.93.93,0,0,1,0,1.85H13Z" />
  </svg>
);

export default SpellChecker;
