/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { RoleTypes, ActionCategoriesTypes, UnsavedRole, AnyRole, CategoryName } from "api/roles";
import { LoaderState, INITIAL_LOADER_STATE } from "behaviors/loader/state";

export interface RolesPageTypes {
  rolesPage: State;
}

export interface PartialAppState {
  usersAndRoles: RolesPageTypes;
  config: {
    "action-categories": ActionCategoriesTypes;
  };
}

export enum RolesPageInspectorType {
  Create = "create",
  Edit = "edit"
}

export const NEW_ROLE: UnsavedRole = {
  "parent-role-id": null,
  "parent-role-name": null,
  "is-author": true,
  "is-default": false,
  name: "",
  "action-categories": {
    content: [],
    admin: [],
    analytics: []
  }
};

export interface State {
  roles: RoleTypes[];
  role: RoleTypes | null;
  app: {
    currentRole: AnyRole | null;
  };
  ui: {
    main: LoaderState;
    inspector: RolesPageInspectorType | null;
    selectedActionCategoryTab: string;
    saveErrors: Error | null;
  };
}

export const INITIAL_ROLES_STATE: State = {
  roles: [],
  role: null,
  app: {
    currentRole: null
  },
  ui: {
    main: INITIAL_LOADER_STATE,
    inspector: null,
    selectedActionCategoryTab: CategoryName.Content,
    saveErrors: null
  }
};
