/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { IntegrationConfig, IntegrationsResponse, SupportedIntegrationsResponse } from "api/integrations";
import { difference, sortBy } from "lodash";
import { IntegrationType } from "./state";

export function convertAPIDataToUICompatibleFormat(data: IntegrationsResponse) {
  const modifiedData = Object.entries(data).map(([k, v]) => ({
    key: k,
    value: "disabled" in v ? v : { ...v, disabled: false }
  }));
  return sortBy(modifiedData, (integration: IntegrationConfig) => integration.key);
}

export function getIntegrationsAvailableToAdd(
  publisherIntegrations: IntegrationsResponse,
  supportedIntegrations: SupportedIntegrationsResponse,
  integrationType: IntegrationType
) {
  const publisherIntegrationKeys = Object.keys(publisherIntegrations);
  let supportedIntegrationKeys = Object.keys(supportedIntegrations);
  if (integrationType && integrationType !== IntegrationType.ALL) {
    supportedIntegrationKeys = supportedIntegrationKeys.filter(
      (integrationKey) => supportedIntegrations && supportedIntegrations[integrationKey].type === integrationType
    );
  }
  return difference(supportedIntegrationKeys, publisherIntegrationKeys);
}
