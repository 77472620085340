/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import actions, {
  LoadStaticPagesSuccess,
  InitLoadStaticPages,
  LoadStaticPagesFailure,
  LoadNextStaticPagesSuccess,
  InitLoadNextStaticPages,
  LoadNextStaticPagesFailure,
  SetCurrentStaticPage,
  SetCurrentStaticPageError,
  UpdateCurrentStaticPage,
  InitSaveStaticPage,
  SaveStaticPageSuccess,
  SaveStaticPageFailure,
  InitPublishStaticPage,
  PublishStaticPageSuccess,
  PublishStaticPageFailure,
  InitRetractStaticPage,
  RetractStaticPageSuccess,
  RetractStaticPageFailure,
  InitDeleteStaticPage,
  DeleteStaticPageSuccess,
  DeleteStaticPageFailure,
  OpenDeleteConfirmation,
  CloseDeleteConfirmation
} from "./actions";
import { StaticPage, UnSavedStaticPage } from "api/static-pages";

export function initLoadStaticPages(): InitLoadStaticPages {
  return {
    type: actions.INIT_LOAD_STATIC_PAGES
  };
}

export function initLoadNextStaticPages(): InitLoadNextStaticPages {
  return {
    type: actions.INIT_LOAD_NEXT_STATIC_PAGES
  };
}

export function initSaveStaticPage(): InitSaveStaticPage {
  return {
    type: actions.INIT_SAVE_STATIC_PAGE
  };
}

export function saveStaticPageSuccess(): SaveStaticPageSuccess {
  return {
    type: actions.SAVE_STATIC_PAGE_SUCCESS
  };
}

export function saveStaticPageFailure(error: any): SaveStaticPageFailure {
  return {
    type: actions.SAVE_STATIC_PAGE_FAILURE,
    payload: { error }
  };
}

export function initPublishStaticPage(): InitPublishStaticPage {
  return {
    type: actions.INIT_PUBLISH_STATIC_PAGE
  };
}

export function publishStaticPageSuccess(): PublishStaticPageSuccess {
  return {
    type: actions.PUBLISH_STATIC_PAGE_SUCCESS
  };
}

export function publishStaticPageFailure(error: any): PublishStaticPageFailure {
  return {
    type: actions.PUBLISH_STATIC_PAGE_FAILURE,
    payload: { error }
  };
}

export function initRetractStaticPage(): InitRetractStaticPage {
  return {
    type: actions.INIT_RETRACT_STATIC_PAGE
  };
}

export function retractStaticPageSuccess(): RetractStaticPageSuccess {
  return {
    type: actions.RETRACT_STATIC_PAGE_SUCCESS
  };
}

export function retractStaticPageFailure(error: any): RetractStaticPageFailure {
  return {
    type: actions.RETRACT_STATIC_PAGE_FAILURE,
    payload: { error }
  };
}

export function initDeleteStaticPage(): InitDeleteStaticPage {
  return {
    type: actions.INIT_DELETE_STATIC_PAGE
  };
}

export function deleteStaticPageSuccess(): DeleteStaticPageSuccess {
  return {
    type: actions.DELETE_STATIC_PAGE_SUCCESS
  };
}

export function deleteStaticPageFailure(error: any): DeleteStaticPageFailure {
  return {
    type: actions.DELETE_STATIC_PAGE_FAILURE,
    payload: { error }
  };
}

export function loadStaticPagesSuccess(staticPages: StaticPage[], totalCount: number): LoadStaticPagesSuccess {
  return { type: actions.LOAD_STATIC_PAGES_SUCCESS, payload: { staticPages, totalCount } };
}

export function loadStaticPagesFailure(error: any): LoadStaticPagesFailure {
  return {
    type: actions.LOAD_STATIC_PAGES_FAILURE,
    payload: { error: error.error }
  };
}

export function loadNextStaticPagesSuccess(staticPages: StaticPage[], totalCount: number): LoadNextStaticPagesSuccess {
  return { type: actions.LOAD_NEXT_STATIC_PAGES_SUCCESS, payload: { staticPages, totalCount } };
}

export function loadNextStaticPagesFailure(error: any): LoadNextStaticPagesFailure {
  return {
    type: actions.LOAD_NEXT_STATIC_PAGES_FAILURE,
    payload: { error: error.error }
  };
}

export function setCurrentStaticPage(staticPage: StaticPage | UnSavedStaticPage): SetCurrentStaticPage {
  return {
    type: actions.SET_CURRENT_STATIC_PAGE,
    payload: { staticPage }
  };
}

export function updateCurrentStaticPage(key: string, value: any): UpdateCurrentStaticPage {
  return {
    type: actions.UPDATE_CURRENT_STATIC_PAGE,
    payload: { key, value }
  };
}

export function setCurrentStaticPageError(error: any): SetCurrentStaticPageError {
  return {
    type: actions.SET_CURRENT_STATIC_PAGE_ERROR,
    payload: { error }
  };
}

export function openDeleteConfirmation(): OpenDeleteConfirmation {
  return {
    type: actions.OPEN_DELETE_CONFIRMATION
  };
}

export function closeDeleteConfirmation(): CloseDeleteConfirmation {
  return {
    type: actions.CLOSE_DELETE_CONFIRMATION
  };
}
