/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import CollectionCard from "pages/collections/components/collection-card/collection-card";
import { CollectionItemLocation } from "../type-card/type-card";
import { CollectionItemWrapper } from "api/story-collection";
import { CollectionTemplateOptions } from "api/template-options";
import { Attribute } from "api/story-attributes";
import { PageViews } from "pages/collections/initial-state";
import styles from "./collection-item-list.module.css";

const nop = () => undefined;

const isSelected = (item: CollectionItemWrapper, sortedItems: CollectionItemWrapper[]) =>
  !!sortedItems.find((_item) => _item.id.toString() === item.id.toString());

interface Props {
  items: any;
  storiesPageViews: PageViews | null;
  selectedCollectionItems: CollectionItemWrapper[];
  toggleCollectionItem: (item: CollectionItemWrapper) => void;
  showBadge?: boolean;
  templateOptions: CollectionTemplateOptions;
  attributes: Attribute[];
  hasCollectionRankNestedCollectionPermission: boolean;
}

const CollectionItemList: React.SFC<Props> = ({
  items,
  storiesPageViews,
  selectedCollectionItems,
  toggleCollectionItem,
  showBadge,
  attributes,
  templateOptions,
  hasCollectionRankNestedCollectionPermission
}) => {
  let sortedItems = selectedCollectionItems ? selectedCollectionItems : items;
  return (
    <div className={styles["collection-item-list"]} data-test-id="collection-item-list">
      {items.map((item: any, index: number) => (
        <CollectionCard
          key={item.id}
          item={item}
          pageViews={storiesPageViews && storiesPageViews[item.id]}
          isSelected={isSelected(item, sortedItems)}
          toggleCollectionItem={toggleCollectionItem}
          badge={showBadge ? index + 1 : null}
          type={CollectionItemLocation.Unsorted}
          changePageViewsThreshold={nop}
          updatePageViewsThreshold={nop}
          index={index}
          isCardExpanded={false}
          toggleCardExpanded={nop}
          cancelCardExpanded={nop}
          threshold={item.threshold}
          updateStagingAssociatedMetadata={nop}
          stagingAssociatedMetadata={{}}
          changeAssociatedMetadataLayout={nop}
          updateAssociatedMetadata={nop}
          isAssociatedMetadataEnabled={false}
          expandCollectionCard={nop}
          attributes={attributes}
          templateOptions={templateOptions}
          hasCollectionRankNestedCollectionPermission={hasCollectionRankNestedCollectionPermission}
        />
      ))}
    </div>
  );
};

export default CollectionItemList;
