/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import styles from "./context-menu-item.module.css";

const cx = classnames.bind(styles);
interface ContextMenuItemProps {
  label: string;
  isActive?: boolean;
  isDisabled?: boolean;
  isDanger?: boolean;
  onClick: () => void;
}
const contextMenuRef = React.createRef<HTMLLIElement>();

const ContextMenuItem = ({ label, isActive, isDisabled, isDanger, onClick }: ContextMenuItemProps) => (
  <li
    ref={contextMenuRef}
    tabIndex={0}
    data-test-id="context-menu-list-item"
    className={cx(
      "context-menu-list-item",
      { "is-active": isActive },
      { "is-disabled": isDisabled },
      { "is-danger": isDanger }
    )}
    onClick={() => {
      onClick();
      contextMenuRef.current && contextMenuRef.current.focus();
    }}>
    {label}
  </li>
);

export { ContextMenuItem };
