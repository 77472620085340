/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import Select from "components/select/select";
import { Platform, DEFAULT_CHANNEL, DEFAULT_PROVIDER, TargetPlatform } from "api/push-notification";
import { Target } from "api/push-notification";
import { capitalize } from "lodash";

export interface PushNotificationPlatformSelectProps {
  defaultChannel?: string;
  index: number;
  targets: Array<Target>;
  onUpdate: (change: Array<Target>) => void;
  disabled?: boolean;
}

export const reducePlatform = (targetGroup: Target): Platform | null => {
  if (targetGroup && targetGroup.platform) {
    if (targetGroup.platform[Platform.WEB] && targetGroup.platform[Platform.MOBILE]) {
      return Platform.ALL;
    } else if (targetGroup.platform[Platform.WEB]) {
      return Platform.WEB;
    } else if (targetGroup.platform[Platform.MOBILE]) {
      return Platform.MOBILE;
    }
  }
  return null;
};

export const expandPlatform = (platform: Platform): TargetPlatform => {
  const webPlatform = {
    [Platform.WEB]: {
      provider: DEFAULT_PROVIDER
    }
  };
  const mobilePlatform = {
    [Platform.MOBILE]: {
      provider: DEFAULT_PROVIDER
    }
  };
  if (platform === Platform.ALL) {
    return {
      ...webPlatform,
      ...mobilePlatform
    };
  } else if (platform === Platform.WEB) {
    return webPlatform;
  } else return mobilePlatform;
};

const PushNotificationPlatformSelect: React.FC<PushNotificationPlatformSelectProps> = ({
  targets,
  index,
  onUpdate,
  defaultChannel = DEFAULT_CHANNEL,
  disabled
}) => {
  const selectedTarget = targets ? targets[index] : null;
  return (
    <Select
      onChange={(platform: Platform) => {
        const formattedPlatform = expandPlatform(platform);
        if (index < targets.length) {
          targets[index].platform = formattedPlatform;
        } else {
          targets.push({ channel: defaultChannel, platform: formattedPlatform });
        }
        onUpdate(targets);
      }}
      value={selectedTarget ? [reducePlatform(selectedTarget)] : []}
      options={[Platform.ALL, Platform.WEB, Platform.MOBILE]}
      getOptionLabel={(target) => (target && capitalize(target)) || ""}
      getOptionValue={(target) => (target && target.toString()) || ""}
      hideError={true}
      isDisabled={disabled}
    />
  );
};

export default PushNotificationPlatformSelect;
