/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import CreatableSelect from "components/select/creatable";
import { generateMetaKeywords } from "pages/story-editor/async-action-creators";
import { getErrorMessage } from "pages/story-editor/utils";

import { t } from "i18n";

const MetaKeywords = ({ value, onChange, errors, disabled = false }) => (
  <CreatableSelect
    value={generateMetaKeywords(value)}
    onChange={onChange}
    isMulti={true}
    label={t("story-editor.inspector.meta-keywords")}
    errorMessage={getErrorMessage(errors)}
    isDisabled={disabled}
  />
);

export default MetaKeywords;
