/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const ANALYTICS_INDEX_PATH = "/analytics";
export const ANALYTICS_PATH = `${ANALYTICS_INDEX_PATH}/:dimension(story|section|author)/:metrics(view|visit|share)/:period`;
export const STORY_ANALYTICS = `${ANALYTICS_INDEX_PATH}/:dimension(story)/:metrics(view|visit|share)/:period`;
export const SECTION_ANALYTICS = `${ANALYTICS_INDEX_PATH}/:dimension(section)/:metrics(view|visit|share)/:period`;
export const AUTHOR_ANALYTICS = `${ANALYTICS_INDEX_PATH}/:dimension(author)/:metrics(view|visit|share)/:period`;
