/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";

function CustomField({ width = 24, height = 24, fill = "#c6ccd5" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={fill}>
      <path d="M20.7 8.16l-8.47-4a.54.54 0 00-.43 0l-8.47 4a.5.5 0 000 .9l8.47 4a.54.54 0 00.22.05.54.54 0 00.21-.05l8.47-4a.49.49 0 000-.9zM12 12.08L4.71 8.61 12 5.13l7.31 3.48z"></path>
      <path d="M20.27 11.55L12 15.47l-8.24-3.92a.49.49 0 00-.66.24.5.5 0 00.23.66l8.47 4a.42.42 0 00.22.05.41.41 0 00.21-.05l8.47-4a.5.5 0 00.24-.66.5.5 0 00-.67-.24z"></path>
      <path d="M20.27 14.94L12 18.87l-8.24-3.93a.5.5 0 00-.43.9l8.47 4a.54.54 0 00.22 0 .52.52 0 00.21 0l8.47-4a.49.49 0 00.24-.66.51.51 0 00-.67-.24z"></path>
    </svg>
  );
}

export default CustomField;
