/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";

import { formatBytesToHumanReadable, formattedDate } from "utils";
import styles from "./image-metadata.module.css";

const ImageMetadata = ({ imageMetadata, imageFileName, imageUploadedAt }) => {
  return (
    <div className={styles["image-metadata"]}>
      {imageUploadedAt && (
        <>
          <span>{t("mediaLibrary.file_name")}: </span>
          <bdi className={styles["image-metadata-field"]}>
            {imageFileName ? imageFileName : t("mediaLibrary.not_available")}
          </bdi>
        </>
      )}

      <span>{t("mediaLibrary.file_size")}: </span>

      <span>
        {formatBytesToHumanReadable(imageMetadata["file-size"])
          ? formatBytesToHumanReadable(imageMetadata["file-size"])
          : t("mediaLibrary.not_available")}
      </span>

      {imageMetadata["mime-type"] && <span>{t("mediaLibrary.file_type")}: </span>}
      {imageMetadata["mime-type"] && <span> {imageMetadata["mime-type"]} </span>}

      {imageMetadata.width && <span>{t("mediaLibrary.dimensions")}: </span>}
      {imageMetadata.width && (
        <bdi className={styles["image-metadata-field"]}>{`${imageMetadata.width} x ${imageMetadata.height}`}</bdi>
      )}

      {imageUploadedAt && <span>{t("mediaLibrary.uploaded_at")}:</span>}
      {imageUploadedAt && <bdi className={styles["image-metadata-field"]}>{formattedDate(imageUploadedAt)}</bdi>}
    </div>
  );
};

export default ImageMetadata;
