/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

export interface RoleTypes {
  name: string;
  id: number;
  "parent-role-id": null | number;
  "parent-role-name": null | string;
  "is-author": boolean;
  "is-default": boolean;
  "action-categories": ActionCategories;
  actions?: Array<string>;
}

export interface ActionCategories {
  content: string[];
  admin: string[];
  analytics: string[];
}

export enum CategoryName {
  Admin = "admin",
  Analytics = "analytics",
  Content = "content"
}

export interface ActionCategoriesTypes {
  analytics: ActionTypes[];
  admin: ActionTypes[];
  content: ActionTypes[];
}

export interface ActionTypes {
  name: string;
  "action-description": null;
}

export type UnsavedRole = Pick<
  RoleTypes,
  "name" | "is-author" | "is-default" | "parent-role-id" | "parent-role-name" | "actions" | "action-categories"
>;

export type AnyRole = UnsavedRole | RoleTypes;

export const getRoles = (): Promise<RoleTypes[]> => {
  return client
    .url(`/api/role?format=itsman-x`)
    .get()
    .json();
};

export const deleteRoleFromRolesList = (roleId: number) => {
  return client
    .url(`/api/role/${roleId}`)
    .delete()
    .res()
    .catch((err) => {
      return Promise.reject(JSON.parse(err.message));
    });
};

export const updateRole = (id: number, role: UnsavedRole) => {
  return client
    .url(`/api/role/${id}`)
    .json({ role: { ...role, id: id } })
    .put()
    .json()
    .catch((err) => {
      return Promise.reject(JSON.parse(err.message));
    });
};

export const saveRole = (role: UnsavedRole) => {
  return client
    .url(`/api/role`)
    .json({ role })
    .post()
    .json()
    .catch((err) => {
      return Promise.reject(JSON.parse(err.message));
    });
};
