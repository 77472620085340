/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import styles from "./fixed-tab-list.module.css";

export interface Tab {
  value: string;
  label: string;
}

interface Props {
  tabs: Array<Tab>;
  value?: string;
  isArchivedLibrary?: boolean;
  onChange: (value: string, isArchivedLibrary: boolean) => void;
  className?: string;
}

const cx = classnames.bind(styles);

const FixedTabList: React.FC<Props> = ({ tabs, value, isArchivedLibrary = false, onChange, className }) => {
  return (
    <ul role="tablist" className={cx(styles["fixed-tab-list"], className)} data-test-id="fixed-tab-list">
      {tabs.map((tab) => {
        const isActive = tab.value === value;
        const classes = cx("fixed-tab-list__item", {
          "is-active": isActive
        });

        return (
          <li
            role="tab"
            data-test-id="fixed-tab-item"
            className={classes}
            key={`fixed-tab-${tab.value}`}
            onClick={() => onChange(tab.value, isArchivedLibrary)}>
            {tab.label}
          </li>
        );
      })}
    </ul>
  );
};

export default FixedTabList;
