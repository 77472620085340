/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import actions, {
  LoginUpdateData,
  GetLoginData,
  SetEmailToResetPassword,
  UpdateResetPasswordFields,
  SetValidationError,
  ClearValidationError,
  CancelFormSignIn,
  InitializeSignInAction,
  SignInSuccessAction,
  OnRouteChanged,
  GetTwoFactorAuthTokenSuccessAction,
  GetTwoFactorAuthTokenFailureAction,
  OnChangeOTP,
  OnChangeTOTP,
  LoadTwoFactorAuth
} from "./actions";
import { RouteDataResponse } from "api/route-data/route-data";
import { QrData } from "api/tfa";

export function updateLoginDataAction(key: string, value: string): LoginUpdateData {
  return { type: actions.UPDATE_LOGIN_DATA, payload: { key, value } };
}

export function getLoginDataAction(data: RouteDataResponse): GetLoginData {
  return { type: actions.GET_LOGIN_DATA, payload: { data } };
}

export function setEmailToResetPasswordAction(email: string): SetEmailToResetPassword {
  return { type: actions.SET_EMAIL_TO_RESET_PASSWORD, payload: { email } };
}

export function updateResetPasswordFieldsAction(key: string, value: string): UpdateResetPasswordFields {
  return { type: actions.UPDATE_RESET_PASSWORD_FIELDS, payload: { key, value } };
}

export function setValidationErrorAction(error: Error): SetValidationError {
  return { type: actions.SET_VALIDATION_ERROR, payload: { error } };
}

export function clearValidationErrorAction(): ClearValidationError {
  return { type: actions.CLEAR_VALIDATION_ERROR };
}

export function cancelFormSignInAction(): CancelFormSignIn {
  return { type: actions.CANCEL_FORM_SIGN_IN };
}

export function initializeSignInAction(): InitializeSignInAction {
  return { type: actions.INITIALIZE_SIGN_IN };
}

export function signInSuccessAction(): SignInSuccessAction {
  return { type: actions.SIGN_IN_SUCCESS };
}

export function onRouteChangedAction(): OnRouteChanged {
  return { type: actions.ON_ROUTE_CHANGED };
}

export function getTwoFactorAuthTokenSuccessAction(token: QrData): GetTwoFactorAuthTokenSuccessAction {
  return { type: actions.AUTH_TOKEN_SUCCESS, payload: { token } };
}

export function getTwoFactorAuthTokenFailureAction(error: Error): GetTwoFactorAuthTokenFailureAction {
  return { type: actions.AUTH_TOKEN_FAILURE, payload: { error } };
}

export function onChangeOTP(otp: string): OnChangeOTP {
  return { type: actions.SET_OTP, payload: { otp } };
}
export function onChangeTOTP(totp: string): OnChangeTOTP {
  return { type: actions.SET_TOTP, payload: { totp } };
}

export function loadTwoFactorAuth(): LoadTwoFactorAuth {
  return { type: actions.LOAD_TWO_FACTOR_AUTH };
}
