/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import actions, {
  ListAttributesAction,
  ListAttributesSuccessAction,
  ListAttributesFailureAction,
  ReplaceCurrentAttributeAction,
  InitNewAttributeAction,
  SaveAttributeAction,
  CancelCreateOrEditAttributeAction,
  EditAttributeAction,
  EditAttributeFailureAction,
  DeleteAttributeAction,
  SaveAttributeSuccessAction,
  SaveAttributeFailureAction,
  DeleteAttributeFailureAction,
  DeleteAttributeSuccessAction,
  ListEntityTypeSuccessAction,
  ListEntityTypeFailureAction
} from "./actions";
import { Attribute } from "api/story-attributes";

export function listAttributesAction(): ListAttributesAction {
  return { type: actions.LIST_ATTRIBUTES };
}

export function listAttributesSuccessAction(attributes: Attribute[]): ListAttributesSuccessAction {
  return { type: actions.LIST_ATTRIBUTES_SUCCESS, payload: { attributes } };
}

export function listAttributesFailureAction(error: Error): ListAttributesFailureAction {
  return { type: actions.LIST_ATTRIBUTES_FAILURE, payload: { error } };
}

export function listEntityTypesSuccessAction(entityTypes: string[]): ListEntityTypeSuccessAction {
  return { type: actions.LIST_ENTITY_TYPE_SUCCESS, payload: { entityTypes } };
}

export function listEntityTypesFailureAction(error: Error): ListEntityTypeFailureAction {
  return { type: actions.LIST_ENTITY_TYPE_FAILURE, payload: { error } };
}

export function replaceCurrentAttributeAction(attribute: Attribute | null): ReplaceCurrentAttributeAction {
  return { type: actions.REPLACE_CURRENT_ATTRIBUTE, payload: { attribute } };
}

export function initNewAttributeAction(): InitNewAttributeAction {
  return { type: actions.INIT_NEW_ATTRIBUTE };
}

export function editAttributeAction(attribute: Attribute): EditAttributeAction {
  return { type: actions.EDIT_ATTRIBUTE, payload: { attribute } };
}

export function editAttributeFailureAction(error: Error): EditAttributeFailureAction {
  return { type: actions.EDIT_ATTRIBUTE_FAILURE, payload: { error } };
}

export function cancelCreateOrEditAttributeAction(attribute: Attribute): CancelCreateOrEditAttributeAction {
  return { type: actions.CANCEL_CREATE_OR_EDIT_ATTRIBUTE, payload: { attribute } };
}

export function saveAttributeAction(attribute: Attribute): SaveAttributeAction {
  return { type: actions.SAVE_ATTRIBUTE, payload: { attribute } };
}

export function saveAttributeSuccessAction(attribute: Attribute): SaveAttributeSuccessAction {
  return { type: actions.SAVE_ATTRIBUTE_SUCCESS, payload: { attribute } };
}

export function saveAttributeFailureAction(attribute: Attribute, error: Error): SaveAttributeFailureAction {
  return { type: actions.SAVE_ATTRIBUTE_FAILURE, payload: { attribute, error } };
}

export function deleteAttributeAction(attribute: Attribute): DeleteAttributeAction {
  return { type: actions.DELETE_ATTRIBUTE, payload: { attribute } };
}

export function deleteAttributeSuccessAction(attribute: Attribute): DeleteAttributeSuccessAction {
  return { type: actions.DELETE_ATTRIBUTE_SUCCESS, payload: { attribute } };
}

export function deleteAttributeFailureAction(attribute: Attribute, error: Error): DeleteAttributeFailureAction {
  return { type: actions.DELETE_ATTRIBUTE_FAILURE, payload: { attribute, error } };
}
