/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";

const Copy = ({ width = "20", height = "20" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g>
      <path
        d="M13.1061 1.66675H4.01515C3.18182 1.66675 2.5 2.34857 2.5 3.1819V13.788H4.01515V3.1819H13.1061V1.66675ZM15.3788 4.69705H7.04545C6.21212 4.69705 5.5303 5.37887 5.5303 6.2122V16.8183C5.5303 17.6516 6.21212 18.3334 7.04545 18.3334H15.3788C16.2121 18.3334 16.8939 17.6516 16.8939 16.8183V6.2122C16.8939 5.37887 16.2121 4.69705 15.3788 4.69705ZM15.3788 16.8183H7.04545V6.2122H15.3788V16.8183Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default Copy;
