/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import { connect } from "react-redux";
import { compose } from "redux";
import _assign from "lodash/assign";

import PhotoEditor from "components/photo-editor/photo-editor";
import { actions } from "../../actions";
import { dataURItoFile } from "pages/media-library/image-utils";
import { getMetadataWithDimensions } from "utils/image-metadata.utils";
import throttle from "helpers/throttle";
import { s3FileUpload } from "helpers/s3-gateway";
import { updateImageElement, uploadCardImageAction } from "../../async-action-creators";

const s3UploadQueue = throttle(s3FileUpload);

function getHighResImageUrl(image) {
  // This is required because thumbor gives back a lower res image if no query param is present
  if (image.url) {
    const url = new URL(image.url);
    url.search = url.search ? url.search + `&dummy=value` : `dummy=value`;
    return url.toString();
  }

  return "";
}

class PhotoEditorWrapper extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.uploadToS3 = this.uploadToS3.bind(this);
    this.resetMetadata = this.resetMetadata.bind(this);
  }
  resetMetadata(dimensions, metadata?) {
    return _assign({}, metadata, {
      width: dimensions.width,
      height: dimensions.height,
      "focus-point": isEqual(pick(metadata, ["width", "height"]), dimensions) ? metadata["focus-point"] : null
    });
  }

  uploadToS3(response) {
    const image = this.props.photoEditor.selectedImage;
    const fileName = image.url.substring(image.url.lastIndexOf("/") + 1);
    if (response) {
      const file = dataURItoFile(response, fileName);
      s3UploadQueue.queueJob(file, (result) => {
        if (result.success && this.props.photoEditor.imageAs) {
          getMetadataWithDimensions(result).then((dimensions) => {
            if (this.props.photoEditor.imageAs.type === "hero-image") {
              this.props.updateHeroImageData({
                ...image,
                "temp-key": result.key,
                url: result.url,
                metadata: this.resetMetadata(dimensions, image.metadata)
              });
            } else if (
              this.props.photoEditor.imageAs.type === "image" ||
              this.props.photoEditor.imageAs.type === "image-gallery" ||
              this.props.photoEditor.imageAs.type === "slideshow"
            ) {
              this.props.updateImageElementData(this.props.photoEditor.imageId, {
                ...image,
                "temp-key": result.key,
                url: result.url,
                metadata: this.resetMetadata(dimensions)
              });
            } else if (this.props.photoEditor.imageAs.type === "card-share-image") {
              this.props.updateCardShareImageData({
                ...image,
                "temp-s3-key": result.key,
                url: result.url,
                metadata: this.resetMetadata(dimensions)
              });
            } else if (this.props.photoEditor.imageAs.type === "alternative-image") {
              this.props.updateAlternative(this.props.photoEditor.imageAs.subtype, "hero-image", {
                ...image,
                "temp-key": result.key,
                url: result.url,
                metadata: this.resetMetadata(dimensions)
              });
            }
          });
        }
      });
    }
  }
  render() {
    const { closePhotoEditor, photoEditorConfig, photoEditor } = this.props;
    if (!photoEditor.visible) {
      return false;
    }
    return (
      <PhotoEditor
        imageUrl={getHighResImageUrl(photoEditor.selectedImage)}
        onExport={this.uploadToS3}
        onClose={closePhotoEditor}
        photoEditorConfig={photoEditorConfig}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    photoEditor: state.storyEditor.app.photoEditor,
    photoEditorConfig: state.config["photo-editor-sdk"]
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const storyId = ownProps.storyId;
  const cardId = ownProps.cardId && ownProps.cardId;
  return {
    closePhotoEditor: () =>
      dispatch({
        type: actions.CLOSE_PHOTO_EDITOR
      }),
    updateHeroImageData: (image) => {
      dispatch({
        type: actions.UPDATE_HERO_IMAGE_DATA,
        payload: {
          image
        }
      });
      dispatch({
        type: actions.CLOSE_PHOTO_EDITOR
      });
    },
    updateImageElementData: (id, changes) => {
      dispatch(updateImageElement(id, changes));
      dispatch({
        type: actions.CLOSE_PHOTO_EDITOR
      });
    },
    updateCardShareImageData: (image) => {
      dispatch(uploadCardImageAction(storyId, cardId, image, true));
      dispatch({
        type: actions.CLOSE_PHOTO_EDITOR
      });
    },
    updateAlternative: (location, key, value) => {
      dispatch({
        type: actions.UPDATE_ALTERNATIVE,
        payload: {
          location,
          key,
          value
        }
      });
      dispatch({
        type: actions.CLOSE_PHOTO_EDITOR
      });
    }
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(PhotoEditorWrapper);
