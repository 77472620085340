/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";

import styles from "./load-more-cards.module.css";

interface LoadMoreCardsProps {
  numberOfCardsShown: number;
  totalNumberOfCards: number;
  onClick: () => void;
}

const LoadMoreCards: React.SFC<LoadMoreCardsProps> = ({ numberOfCardsShown, totalNumberOfCards, onClick }) => (
  <div className={styles["story-editor-load-more-cards"]} onClick={() => onClick()} data-test-id="load-more-cards">
    <p className={styles["load-more-cards-message"]} data-test-id="load-more-cards-message">
      <strong>{`${numberOfCardsShown} / ${totalNumberOfCards}`}</strong> {t("story-editor.load_more_cards")}
    </p>
  </div>
);

export default LoadMoreCards;
