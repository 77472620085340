/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import actions, {
  LoadSettingsDataAction,
  LoadSettingsDataSuccessAction,
  LoadSettingsDataFailureAction,
  UpdateGeneralSettingsAction,
  UpdateSeoMetadataAction,
  UpdateSocialLinksAction,
  UpdateImageUploadStatusAction,
  UpdateImageUploadFailureAction
} from "./actions";
import { Settings } from "api/settings";
import { SEOMetadataResponse } from "api/seo-metadata";

export function loadSettingsData(): LoadSettingsDataAction {
  return { type: actions.LOAD_SETTINGS_DATA };
}

export function loadSettingsDataSuccess(
  settings: Settings | null,
  seoMetadata: SEOMetadataResponse | null
): LoadSettingsDataSuccessAction {
  return { type: actions.LOAD_SETTINGS_DATA_SUCCESS, payload: { settings, seoMetadata } };
}

export function loadSettingsDataFailure(error: Error): LoadSettingsDataFailureAction {
  return { type: actions.LOAD_SETTINGS_DATA_FAILURE, payload: { error } };
}

export function updateGeneralSettings(key: string, changes?: object | string | null): UpdateGeneralSettingsAction {
  return { type: actions.UPDATE_GENERAL_SETTINGS, payload: { key, changes } };
}

export function updateSocialLinks(socialLinks: object): UpdateSocialLinksAction {
  return { type: actions.UPDATE_SOCIAL_LINKS, payload: { socialLinks } };
}

export function updateSeoMetadata(changes: object): UpdateSeoMetadataAction {
  return { type: actions.UPDATE_SEO_METADATA, payload: { changes } };
}

export function imageUploadingStatus(status: { uploading: boolean }): UpdateImageUploadStatusAction {
  return { type: actions.UPDATE_IMAGE_UPLOAD_STATUS, payload: status };
}

export function imageUploadFailure(status: { imageError: string }): UpdateImageUploadFailureAction {
  return { type: actions.UPDATE_IMAGE_UPLOAD_FAILURE, payload: status };
}
