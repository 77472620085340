/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { htmlToPMNode, htmlToInlinePMNode } from "./utils";
import { findElementWithClientIdNP } from "../operations/find";
import {
  StoryElements,
  StoryElement,
  Story,
  Card,
  CompositeStoryElement,
  ChildStoryElement,
  StoryElementTextSubtype
} from "api/story";
import { EditorState } from "prosemirror-state";
import { Schema, DOMParser } from "prosemirror-model";
import { PMElement, ElementType } from "./types";
import { StoryElementId, UUID } from "api/primitive-types";
import { get } from "lodash";
import { t } from "i18n";

const storyElementTextToPMNode = (
  storyElement: StoryElement,
  isCardDisabled: boolean,
  storyVersionId: UUID,
  opts: { editorState: EditorState<Schema> | {}; paragraphParser: DOMParser; schemaParser: DOMParser }
): PMElement => {
  if (opts.editorState.hasOwnProperty("doc")) {
    const element = findElementWithClientIdNP(opts.editorState as EditorState, storyElement);
    return {
      attrs: {
        id: storyElement && storyElement.id,
        "client-id": storyElement && storyElement["client-id"],
        "card-id": storyElement && storyElement["card-id"],
        "story-version-id": storyVersionId,
        "card-version-id": storyElement && storyElement["card-version-id"],
        "family-id": storyElement && storyElement["family-id"],
        subtype: storyElement && storyElement["subtype"],
        isCardDisabled
      },
      type: ElementType.StoryElementText,
      subtype: storyElement && storyElement.subtype,
      content: element && element.node.toJSON().content
    };
  } else {
    const node = htmlToPMNode(opts.paragraphParser, `<div>${(storyElement && storyElement.text) || "<p></p>"}</div>`);
    const json = node && node.toJSON();

    return {
      attrs: {
        id: storyElement && storyElement.id,
        "client-id": storyElement && storyElement["client-id"],
        "card-id": storyElement && storyElement["card-id"],
        "card-version-id": storyElement && storyElement["card-version-id"],
        "family-id": storyElement && storyElement["family-id"],
        "story-version-id": storyVersionId,
        subtype: storyElement && storyElement["subtype"],
        isCardDisabled
      },
      type: ElementType.StoryElementText,
      subtype: storyElement && storyElement.subtype,
      content: json && json.content
    };
  }
};

const storyElementTitleToPMNode = (
  storyElement: StoryElement,
  isCardDisabled: boolean,
  storyVersionId: UUID,
  opts: { editorState: EditorState<Schema> | {}; paragraphParser: DOMParser; schemaParser: DOMParser }
): PMElement => {
  const content =
    storyElement && storyElement.text
      ? [
          {
            type: ElementType.TitleText,
            content: [{ type: ElementType.Text, text: storyElement.text }]
          }
        ]
      : [{ type: ElementType.TitleText, attrs: { placeholder: "Title" } }];

  return {
    type: ElementType.Title,
    attrs: {
      id: storyElement && storyElement.id,
      "client-id": storyElement && storyElement["client-id"],
      type: storyElement && storyElement.type,
      subtype: storyElement && storyElement.subtype,
      "card-id": storyElement && storyElement["card-id"],
      "story-version-id": storyVersionId,
      isCardDisabled
    },
    content
  };
};

const storyElementQuestionToPMNode = (
  storyElement: StoryElement,
  isCardDisabled: boolean,
  storyVersionId: UUID,
  opts: { editorState: EditorState<Schema> | {}; paragraphParser: DOMParser; schemaParser: DOMParser }
): PMElement => {
  const node = htmlToPMNode(opts.paragraphParser, `<div>${(storyElement && storyElement.text) || "<p></p>"}</div>`);
  const json = node && node.toJSON();

  return {
    attrs: {
      id: storyElement && storyElement.id,
      "client-id": storyElement && storyElement["client-id"],
      "card-id": storyElement && storyElement["card-id"],
      "story-version-id": storyVersionId,
      isCardDisabled,
      type: storyElement && storyElement.type,
      subtype: storyElement && storyElement.subtype
    },
    type: ElementType.Question,
    content: [
      { type: ElementType.QuestionHelper },
      { type: ElementType.QuestionText, attrs: { placeholder: "Question here..." }, content: json && json.content },
      { type: ElementType.QuestionAttributionHelper }
    ]
  };
};

const storyElementAnswerToPMNode = (
  storyElement: StoryElement,
  isCardDisabled: boolean,
  storyVersionId: UUID,
  opts: { editorState: EditorState<Schema> | {}; paragraphParser: DOMParser; schemaParser: DOMParser }
): PMElement => {
  const node = htmlToPMNode(opts.paragraphParser, `<div>${(storyElement && storyElement.text) || "<p></p>"}</div>`);
  const json = node && node.toJSON();

  return {
    attrs: {
      id: storyElement && storyElement.id,
      "client-id": storyElement && storyElement["client-id"],
      "card-id": storyElement && storyElement["card-id"],
      "story-version-id": storyVersionId,
      isCardDisabled,
      type: storyElement && storyElement.type,
      subtype: storyElement && storyElement.subtype
    },
    type: ElementType.Answer,
    content: [
      { type: ElementType.AnswerHelper },
      { type: ElementType.AnswerText, attrs: { placeholder: "Answer here..." }, content: json && json.content },
      { type: ElementType.AnswerAttributionHelper }
    ]
  };
};

const storyElementOthersToPMNode = (
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement,
  isCardDisabled: boolean,
  storyVersionId: UUID
): PMElement => ({
  attrs: {
    id: storyElement && storyElement.id,
    "client-id": storyElement && storyElement["client-id"],
    type: storyElement && storyElement.type,
    subtype: storyElement && storyElement.subtype,
    "card-id": storyElement && storyElement["card-id"],
    "story-version-id": storyVersionId,
    isCardDisabled
  },
  type: ElementType.StoryElementOthers
});

const storyElementBlurbToPMNode = (
  storyElement: StoryElement,
  isCardDisabled: boolean,
  storyVersionId: UUID,
  opts: { editorState: EditorState<Schema> | {}; paragraphParser: DOMParser; schemaParser: DOMParser }
): PMElement => {
  const blurbHTML =
    storyElement && storyElement.text ? `<div>${storyElement.text}</div>` : "<div><blockquote></blockquote></div>";
  const node = htmlToInlinePMNode(opts.schemaParser, blurbHTML);
  const json = node && node.toJSON();

  return {
    type: ElementType.Blurb,
    attrs: {
      id: storyElement && storyElement.id,
      "client-id": storyElement && storyElement["client-id"],
      type: storyElement && storyElement.type,
      subtype: storyElement && storyElement.subtype,
      "card-id": storyElement && storyElement["card-id"],
      "story-version-id": storyVersionId,
      isCardDisabled
    },
    content: json && json.content
  };
};

const storyElementQuoteToPMNode = (
  storyElement: StoryElement,
  isCardDisabled: boolean,
  storyVersionId: UUID,
  opts: { editorState: EditorState<Schema> | {}; paragraphParser: DOMParser; schemaParser: DOMParser }
): PMElement => {
  const attribution =
    (storyElement.metadata.attribution && storyElement.metadata.attribution.name
      ? "@" + storyElement.metadata.attribution.name
      : storyElement.metadata.attribution) || "";
  const content = storyElement.metadata.content || "";
  const node = htmlToInlinePMNode(
    opts.schemaParser,
    `<div><blockquote>${content}</blockquote><span class="attribution">${attribution}</span></div>`
  );
  const json = node && node.toJSON();
  const jsonContent = json && json.content;
  return {
    type: ElementType.Quote,
    attrs: {
      id: storyElement && storyElement.id,
      "client-id": storyElement && storyElement["client-id"],
      type: storyElement && storyElement.type,
      subtype: storyElement && storyElement.subtype,
      "card-id": storyElement && storyElement["card-id"],
      "story-version-id": storyVersionId,
      isCardDisabled
    },
    content: [
      jsonContent[0],
      {
        type: ElementType.QuoteHelper
      },
      {
        attrs: {
          id: storyElement.id
        },
        content: jsonContent[1] && jsonContent[1].content && jsonContent[1].content[0].content,
        type: ElementType.QuoteAttribution
      }
    ]
  };
};

export function buildCtaToPMNode(storyElement: StoryElement, storyVersionId?: UUID, isCardDisabled?: boolean) {
  const ctaTitle = get(storyElement, ["metadata", "cta-title"], "");
  const ctaLink = get(storyElement, ["metadata", "cta-url"], "");
  return {
    type: ElementType.Cta,
    attrs: {
      id: storyElement && storyElement.id,
      "client-id": storyElement && storyElement["client-id"],
      type: storyElement && storyElement.type,
      subtype: storyElement && storyElement.subtype,
      "card-id": storyElement && storyElement["card-id"],
      "story-version-id": storyVersionId,
      isCardDisabled
    },
    content: [
      {
        type: ElementType.CtaTitleHelper
      },
      {
        content: ctaTitle && [{ type: ElementType.Text, text: ctaTitle }],
        type: ElementType.CtaTitle
      },
      {
        type: ElementType.CtaUrlHelper
      },
      {
        content: ctaLink && [{ type: ElementType.Text, text: ctaLink }],
        type: ElementType.CtaUrl
      },
      {
        type: ElementType.CtaOptions,
        attrs: {
          id: storyElement && storyElement.id
        },
        content: [
          {
            type: ElementType.CtaTarget,
            attrs: {
              id: storyElement && storyElement.id,
              label: t("story-editor.story-element.open-in-new-tab")
            }
          },
          {
            type: ElementType.CtaNoFollow,
            attrs: {
              id: storyElement.id,
              label: t("story-editor.story-element.no-follow")
            }
          }
        ]
      }
    ]
  };
}

const storyElementCtaElementToPMNode = (
  storyElement: StoryElement,
  isCardDisabled: boolean,
  storyVersionId: UUID
): PMElement => {
  return buildCtaToPMNode(storyElement, storyVersionId, isCardDisabled);
};

const storyElementBigfactToPMNode = (
  storyElement: StoryElement,
  isCardDisabled: boolean,
  storyVersionId: UUID,
  opts: { editorState: EditorState<Schema> | {}; paragraphParser: DOMParser; schemaParser: DOMParser }
): PMElement => {
  const node = htmlToInlinePMNode(
    opts.schemaParser,
    (storyElement && storyElement.text) ||
      '<div><div class="bigfact-title"></div><div class="bigfact-description"></div></div>'
  );
  const json = node && node.toJSON();
  const jsonContent = json && json.content;

  return {
    type: ElementType.Bigfact,
    attrs: {
      id: storyElement && storyElement.id,
      "client-id": storyElement && storyElement["client-id"],
      "card-id": storyElement && storyElement["card-id"],
      type: storyElement && storyElement.type,
      subtype: storyElement && storyElement.subtype,
      "story-version-id": storyVersionId,
      isCardDisabled
    },
    content: [
      jsonContent[0],
      {
        type: ElementType.BigfactHelper
      },
      jsonContent[1]
    ]
  };
};

const storyElementQAndAToPMNode = (
  storyElement: StoryElement,
  isCardDisabled: boolean,
  storyVersionId: UUID,
  opts: { paragraphParser: DOMParser; schemaParser: DOMParser }
): PMElement => {
  const nodeQuestion = htmlToPMNode(
    opts.paragraphParser,
    `<div>${(storyElement && storyElement.metadata.question) || "<p></p>"}</div>`
  );
  const nodeAnswer = htmlToPMNode(
    opts.paragraphParser,
    `<div>${(storyElement && storyElement.metadata.answer) || "<p></p>"}</div>`
  );
  const jsonQuestion = nodeQuestion && nodeQuestion.toJSON();
  const jsonAnswer = nodeAnswer && nodeAnswer.toJSON();

  return {
    type: ElementType.QandA,
    attrs: {
      id: storyElement && storyElement.id,
      "client-id": storyElement && storyElement["client-id"],
      type: storyElement && storyElement.type,
      subtype: storyElement && storyElement.subtype,
      "card-id": storyElement && storyElement["card-id"],
      "story-version-id": storyVersionId,
      isCardDisabled
    },
    content: [
      { type: ElementType.QandAQuestionHelper },
      {
        type: ElementType.QandAQuestion,
        content: jsonQuestion && jsonQuestion.content
      },
      {
        type: ElementType.QandAQuestionAttributionHelper,
        attrs: {
          id: storyElement && storyElement.id,
          helperName: "question"
        }
      },
      { type: ElementType.QandAAnswerHelper },
      {
        type: ElementType.QandAAnswer,
        content: jsonAnswer && jsonAnswer.content
      },
      {
        type: ElementType.QandAAnswerAttributionHelper,
        attrs: {
          id: storyElement && storyElement.id,
          helperName: "answer"
        }
      }
    ]
  };
};

const storyElementToPMNode = (
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement,
  isCardDisabled: boolean,
  storyVersionId: UUID,
  opts: {
    schemaParser: DOMParser;
    paragraphParser: DOMParser;
    editorState: EditorState<Schema> | {};
  }
): PMElement => {
  if (
    storyElement &&
    storyElement.type === "text" &&
    (storyElement.subtype === null || storyElement.subtype === "summary")
  ) {
    return storyElementTextToPMNode(storyElement as StoryElement, isCardDisabled, storyVersionId, opts);
  } else if (storyElement.type === "text" && storyElement.subtype === "question") {
    return storyElementQuestionToPMNode(storyElement as StoryElement, isCardDisabled, storyVersionId, opts);
  } else if (storyElement.type === "text" && storyElement.subtype === "answer") {
    return storyElementAnswerToPMNode(storyElement as StoryElement, isCardDisabled, storyVersionId, opts);
  } else if (
    storyElement &&
    storyElement.type === "text" &&
    (storyElement.subtype === "quote" || storyElement.subtype === "blockquote")
  ) {
    return storyElementQuoteToPMNode(storyElement as StoryElement, isCardDisabled, storyVersionId, opts);
  } else if (storyElement && storyElement.type === "text" && storyElement.subtype === StoryElementTextSubtype.Cta) {
    return storyElementCtaElementToPMNode(storyElement as StoryElement, isCardDisabled, storyVersionId);
  } else if (storyElement && storyElement.type === "text" && storyElement.subtype === "q-and-a") {
    return storyElementQAndAToPMNode(storyElement as StoryElement, isCardDisabled, storyVersionId, opts);
  } else if (storyElement && storyElement.type === "text" && storyElement.subtype === "blurb") {
    return storyElementBlurbToPMNode(storyElement as StoryElement, isCardDisabled, storyVersionId, opts);
  } else if (storyElement && storyElement.type === "text" && storyElement.subtype === "bigfact") {
    return storyElementBigfactToPMNode(storyElement as StoryElement, isCardDisabled, storyVersionId, opts);
  } else if (storyElement && storyElement.type === "title") {
    return storyElementTitleToPMNode(storyElement as StoryElement, isCardDisabled, storyVersionId, opts);
  } else return storyElementOthersToPMNode(storyElement, isCardDisabled, storyVersionId);
};

const storyCardToPMNode = (
  storyElements: StoryElements,
  card: Card,
  storyMetadata: {},
  storyVersionId: UUID,
  opts: {
    schemaParser: DOMParser;
    paragraphParser: DOMParser;
    editorState: EditorState<Schema> | {};
  }
): PMElement => {
  const cardMetadata = card.metadata;
  const metadataImportCard = get(storyMetadata, "imported-card-is-read-only");
  const importCardId = get(storyMetadata, "imported-card-id");
  const cardExternalId = get(cardMetadata, "external-id");
  const isCardDisabled = metadataImportCard && (importCardId === card.id || cardExternalId);
  return {
    attrs: {
      id: get(card, "content-id", card.id),
      "content-version-id": card["content-version-id"],
      "client-id": card["client-id"],
      "story-version-id": storyVersionId,
      isCardDisabled
    },
    type: ElementType.Card,
    content: card.tree.map((storyElementId: StoryElementId) => {
      return storyElementToPMNode(storyElements[storyElementId], isCardDisabled, storyVersionId, opts);
    })
  };
};

export const storyToPMDoc = (
  story: Story,
  opts: {
    schemaParser: DOMParser;
    paragraphParser: DOMParser;
    editorState: EditorState<Schema> | {};
  }
): PMElement => {
  const storyVersionId = story["story-version-id"];
  const storyMetadata = story.metadata;
  const cards = story.tree.map((treeCard) => {
    return storyCardToPMNode(
      story["story-elements"],
      story.cards[treeCard["content-id"]],
      storyMetadata,
      storyVersionId,
      opts
    );
  });

  return {
    type: ElementType.Story,
    content: cards,
    attrs: {
      id: story.id,
      "story-version-id": story["story-version-id"]
    }
  };
};
