/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const DoubleChevron = () => (
  <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path fill="#4860bc" d="M.03.13h24v24h-24z" />
    <path
      fill="#FFF"
      d="M13.85 12a.39.39 0 0 1 .12.28.38.38 0 0 1-.12.28L7.31 19.1a.45.45 0 0 1-.28.09.37.37 0 0 1-.25-.09l-.62-.63a.31.31 0 0 1-.16-.25.41.41 0 0 1 .13-.28l5.65-5.66-5.62-5.65A.43.43 0 0 1 6 6.35a.3.3 0 0 1 .13-.25l.62-.63A.37.37 0 0 1 7 5.38a.45.45 0 0 1 .28.09zm-2.69-5.9a.3.3 0 0 0-.13.25.43.43 0 0 0 .13.28l5.65 5.65-5.65 5.66a.41.41 0 0 0-.13.28.31.31 0 0 0 .13.25l.62.63a.37.37 0 0 0 .25.09.45.45 0 0 0 .28-.09l6.54-6.57a.29.29 0 0 0 .12-.25.39.39 0 0 0-.12-.28l-6.54-6.53a.45.45 0 0 0-.31-.09.37.37 0 0 0-.25.09z"
    />
  </svg>
);

export default DoubleChevron;
