/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

export type CollectionEntityType = string;
export interface CollectionsConfig {
  enabledEntityTypes?: CollectionEntityType[];
  storyLimit?: number | null;
  allEntityTypes?: CollectionEntityType[];
  isCollectionConfigModified?: boolean;
}

export const getCollectionConfig = (): Promise<CollectionsConfig> => {
  return client
    .url("/api/collection-config")
    .get()
    .json();
};

export const saveCollectionConfig = (collectionConfig: CollectionsConfig): Promise<any> => {
  return client
    .url("/api/collection-config")
    .json({ enabledEntityTypes: collectionConfig.enabledEntityTypes, "story-limit": collectionConfig.storyLimit })
    .post()
    .json()
    .catch((err) => {
      return Promise.reject(JSON.parse(err.message));
    });
};

export const saveCollectionPreview = (previewId, body) => {
  return client
    .url(`/api/v1/preview/${previewId}/collection`)
    .json({ collection: body })
    .post()
    .json();
};
