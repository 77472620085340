/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import map from "lodash/map";
import throttle from "helpers/throttle";
import { s3FileUpload, UploadFileResponse } from "helpers/s3-gateway";
import { getImageMetadataWithType } from "utils/image-metadata.utils";
import { extractMetadata, ExtractedData } from "utils/image-metadata.utils";
import { ImageMetadata, AnyImage } from "api/search-media-image";

interface UploadedImage {
  success: boolean;
  "temp-image-key"?: string;
  url?: string;
  metadata?: ImageMetadata;
  "extracted-data"?: ExtractedData;
  attribution?: string;
}

const s3UploadQueue = throttle(s3FileUpload);

const uploadWithPromise = (file: File, enableEXIFLocation: boolean = false): Promise<UploadedImage> => {
  return new Promise((resolve, reject) => {
    s3UploadQueue.queueJob(file, function(result: UploadFileResponse) {
      if (result.success) {
        getImageMetadataWithType(result)
          .then(async function(metadata) {
            const exifData = await extractMetadata(file, enableEXIFLocation);
            const uploadedImg = {
              success: true,
              "temp-image-key": result.key,
              url: result.url,
              metadata: { ...metadata, "file-size": file.size, "file-name": file.name }
            };
            resolve(
              exifData ? { ...uploadedImg, "extracted-data": exifData, attribution: exifData.artist } : uploadedImg
            );
          })
          .catch(() => {
            reject({ success: false });
          });
      } else {
        reject({ success: false });
      }
    });
  });
};

const uploadImage = (
  files: File[],
  imageUploadStatus: (status: { uploading: boolean }) => void,
  setSelectedMedia: (images: AnyImage[]) => void,
  imageUploadError: () => void,
  options: { enableEXIFLocation?: boolean }
) => {
  const alwaysResolvingPromises = map(files, (file) =>
    uploadWithPromise(file, options.enableEXIFLocation).then(
      (res) => res,
      (err) => err
    )
  );

  return Promise.all(alwaysResolvingPromises)
    .then((uploads) => {
      const numberOfFailedUploads = uploads.filter((upload) => upload.success === false).length;
      if (numberOfFailedUploads > 0) {
        imageUploadError();
      }
      setSelectedMedia(uploads);
      imageUploadStatus({ uploading: false });
    })
    .finally();
};

export default uploadImage;
