/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { Route, Switch } from "react-router";

import WebhookLogs from "./logs";
import { WEBHOOKS_LOG_PATH } from "./routes";

export const LogsRouter = () => (
  <>
    <Switch>
      <Route exact path={WEBHOOKS_LOG_PATH} render={(props) => <WebhookLogs {...props} title="webhook_logs" />} />
    </Switch>
  </>
);
