/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { connect } from "react-redux";
import { AnyAction, compose } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { NEW_BREAKING_NEWS, BREAKING_NEWS_ADVANCED_OPTIONS, EDIT_BREAKING_NEWS } from "./routes";
import { PartialAppState } from "./state";
import { fetchSocialAccounts, cleanUpBreakingNewsState } from "./async-action-creators";
import { BreakingNewsId } from "api/primitive-types";
import { SocialAccountParams } from "api/social-accounts";
import { BreakingNewsType } from "api/route-data/breaking-news-route-data";
import NewBreakingNews from "./new-breaking-news";
import ExistingBreakingNews from "./existing-breaking-news";
import wrapPage from "containers/page/page";
import Header from "./components/header/header";

interface Props {
  match: {
    url: string;
    path: string;
    params: any;
  };
}

interface StateProps {
  isBreakingNewsAssociateStoryEnabled: boolean;
  breakingNewsTypes: BreakingNewsType[];
  title: string;
}

interface DispatchToProps {
  fetchSocialAccounts: (params?: SocialAccountParams) => void;
  cleanUpBreakingNewsState: () => void;
}

type BreakingNewsWithRoutesProps = Props & StateProps & DispatchToProps;

class BreakingNewsWithRoutes extends React.Component<BreakingNewsWithRoutesProps> {
  isExistingBreakingNews() {
    return this.props.match.params.id ? true : false;
  }

  componentDidMount() {
    if (this.isExistingBreakingNews()) {
      this.props.fetchSocialAccounts({
        "story-id": this.props.match.params.id,
        needed: ["social-accounts", "social-cards"]
      });
    } else {
      this.props.fetchSocialAccounts();
    }
  }

  componentWillUnmount() {
    this.props.cleanUpBreakingNewsState();
  }

  render() {
    const {
      match: { path, params },
      isBreakingNewsAssociateStoryEnabled,
      breakingNewsTypes
    } = this.props;

    const isBreakingNewsTypesNotEmpty = breakingNewsTypes && breakingNewsTypes.length;

    if (isBreakingNewsTypesNotEmpty && isBreakingNewsAssociateStoryEnabled) {
      switch (path) {
        case NEW_BREAKING_NEWS:
          return <NewBreakingNews showAdvancedOptionsInspector={false} />;
        case BREAKING_NEWS_ADVANCED_OPTIONS:
          return <NewBreakingNews showAdvancedOptionsInspector={true} />;
        case EDIT_BREAKING_NEWS:
          return <ExistingBreakingNews id={params.id as BreakingNewsId} />;

        default:
          return <div />;
      }
    }
    return null;
  }
}
const mapStateToProps = (state: PartialAppState): StateProps => ({
  isBreakingNewsAssociateStoryEnabled: state.features.isBreakingNewsAssociateStoryEnabled,
  breakingNewsTypes: state.config["breaking-news-types"],
  title: "breaking_news"
});

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchToProps => ({
  fetchSocialAccounts: (params?: SocialAccountParams) => dispatch(fetchSocialAccounts(params)),
  cleanUpBreakingNewsState: () => dispatch(cleanUpBreakingNewsState())
});

export { BreakingNewsWithRoutes };

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  wrapPage({ HeaderComponent: Header, isStoryPage: false })
)(BreakingNewsWithRoutes);
