/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

/* eslint-disable array-callback-return */
import * as React from "react";
import TextField from "components/text-field/text-field";
import AsyncSelect from "components/select/async";
import ImageField, { properlyTypeImage } from "components/image-field/image-field";
import { t } from "i18n";
import { get } from "lodash";

export const generateTemplateObj = ({ id, name, slug }) => {
  return { id, name, slug };
};
export const generateTemplateObjForMultiSelect = (values) => values.map((value) => generateTemplateObj(value));

const EntityTemplate = ({
  selectedEntity,
  updateSelectedEntity,
  selectedEntityData,
  fieldErrors,
  uploadImage,
  getEntityId,
  removeEntityImages,
  entityTemplateOptions,
  getEntities,
  setSelectedEntityImages,
  showEditImage,
  switchToUploadRoute,
  updateImageUploadStatus,
  setImageUploadKey
}) => {
  const fields = selectedEntity.template.properties;
  const fetchEntities = (field) => (q = "") =>
    q.length >= 3
      ? getEntities({ q, type: field["entity-type"] }).then((data) => data.entities)
      : Promise.resolve(entityTemplateOptions[field.key] || []);
  return (
    <React.Fragment>
      {fields.map((field) => {
        if (field.type === "entity") {
          return (
            <div className="entity-template-field" key={`entity-template-${field.key}`}>
              <AsyncSelect
                value={selectedEntityData && selectedEntityData[field.key]}
                onChange={(value) => updateSelectedEntity(field.key, generateTemplateObj(value))}
                getOptionLabel={(fieldOption) => fieldOption.name}
                getOptionValue={(fieldOption) => fieldOption.id}
                defaultOptions={entityTemplateOptions ? entityTemplateOptions[field.key] : []}
                loadOptions={fetchEntities(field)}
                label={field.display}
                errorMessage={
                  fieldErrors && fieldErrors[field.key] ? t("errors.validations.missing", { name: field.display }) : ""
                }
              />
            </div>
          );
        }
        if (field.type === "array") {
          if (field["array-element-type"] === "image") {
            return (
              <ImageField
                key={`entity-template-${field.key}`}
                setSelectedMedia={setSelectedEntityImages}
                switchToUploadRoute={(mediaKey) => {
                  setImageUploadKey(field.key);
                  switchToUploadRoute(mediaKey);
                }}
                updateImageUploadStatus={updateImageUploadStatus}
                showEditImage={showEditImage}
                openGallery={() => {
                  setImageUploadKey(field.key);
                  uploadImage(getEntityId());
                }}
                onReplace={() => {
                  setImageUploadKey(field.key);
                  uploadImage(getEntityId());
                }}
                onDelete={() => removeEntityImages(field.key)}
                label={field.display || t("common.photo")}
                value={properlyTypeImage(get(selectedEntityData, [field.key, 0]) || "photo")}
              />
            );
          }
          if (field["array-element-type"] === "entity") {
            return (
              <AsyncSelect
                key={`entity-template-${field.key}`}
                value={selectedEntityData && selectedEntityData[field.key]}
                onChange={(values) => updateSelectedEntity(field.key, generateTemplateObjForMultiSelect(values))}
                getOptionLabel={(fieldOption) => fieldOption.name}
                getOptionValue={(fieldOption) => fieldOption.id}
                loadOptions={fetchEntities(field)}
                defaultOptions={entityTemplateOptions ? entityTemplateOptions[field.key] : []}
                label={field.display}
                isMulti={true}
                errorMessage={
                  fieldErrors && fieldErrors[field.key] ? t("errors.validations.missing", { name: field.display }) : ""
                }
              />
            );
          }
        }
        if (field.type === "string") {
          return (
            <TextField
              key={`entity-template-${field.key}`}
              value={selectedEntityData && selectedEntityData[field.key]}
              label={field.display}
              onChange={(value) => updateSelectedEntity(field.key, value)}
              errorMessage={
                fieldErrors && fieldErrors[field.key] ? t("errors.validations.missing", { name: field.display }) : ""
              }
            />
          );
        }
        return null;
      })}
    </React.Fragment>
  );
};

export default EntityTemplate;
