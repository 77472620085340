/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";

import NoContentCard from "components/no-content-card/no-content-card";
import File from "components/icons/file";
import { ContextMenu, ContextMenuList, ContextMenuItem } from "components/context-menu/context-menu";
import { CustomField } from "api/custom-field";
import styles from "./root-custom-metadata-list.module.css";

interface CustomMetadataListProps {
  customMetadataFields: Array<CustomField>;
  deleteCustomMetadataField: (customMetadataField: CustomField) => void;
  className?: string;
  editCustomMetadataField: (customMetadataField: CustomField) => void;
}

const CustomMetadataList: React.SFC<CustomMetadataListProps> = ({
  customMetadataFields,
  deleteCustomMetadataField,
  className,
  editCustomMetadataField
}) => {
  return (
    <React.Fragment>
      {customMetadataFields.length === 0 && (
        <div className={styles["custom-metadata-no-content"]}>
          <NoContentCard>
            <File />
            <div className={styles["no-content-message"]}>
              {t("custom-metadata.messages.no-custom-metadata-fields")}
            </div>
          </NoContentCard>
        </div>
      )}
      {customMetadataFields.length > 0 && (
        <ul className={className}>
          {customMetadataFields.map((customMetadataField) => (
            <CustomMetadataRow
              key={`custom-metadata-list-${customMetadataField.name}`}
              customMetadataField={customMetadataField}
              deleteCustomMetadataField={deleteCustomMetadataField}
              editCustomMetadataField={editCustomMetadataField}
            />
          ))}
        </ul>
      )}
    </React.Fragment>
  );
};

interface CustomMetadataRowProps {
  customMetadataField: CustomField;
  deleteCustomMetadataField: (customMetadataField: CustomField) => void;
  editCustomMetadataField: (customMetadataField: CustomField) => void;
}

const CustomMetadataRow: React.SFC<CustomMetadataRowProps> = ({
  customMetadataField,
  deleteCustomMetadataField,
  editCustomMetadataField
}) => {
  return (
    <React.Fragment>
      <li
        className={styles["custom-metadata-list-item"]}
        data-test-id="custom-metadata-list-item"
        onClick={() => editCustomMetadataField(customMetadataField)}>
        <div className={styles["custom-metadata-row-info"]}>
          <span>{customMetadataField.name}</span>
        </div>
        <div
          data-test-id="custom-metadata-menu-trigger"
          id={`custom-metadata-${customMetadataField.name}`}
          className={styles["custom-metadata-row-actions"]}>
          <ContextMenu>
            <div id={`custom-metadata-${customMetadataField.name}`}>
              <ContextMenuList>
                <ContextMenuItem
                  label={t("custom-metadata.cta.delete")}
                  isDanger
                  onClick={() => deleteCustomMetadataField(customMetadataField)}
                />
              </ContextMenuList>
            </div>
          </ContextMenu>
        </div>
      </li>
    </React.Fragment>
  );
};

interface RootCustomMetadataListProps {
  customMetadataFields: Array<CustomField>;
  deleteCustomMetadataField: (customField: CustomField) => void;
  editCustomMetadataField: (customField: CustomField) => void;
}

const RootCustomMetadataList: React.SFC<RootCustomMetadataListProps> = ({
  customMetadataFields,
  deleteCustomMetadataField,
  editCustomMetadataField
}) => {
  return (
    <CustomMetadataList
      className={styles["custom-metadata-list"]}
      customMetadataFields={customMetadataFields}
      deleteCustomMetadataField={deleteCustomMetadataField}
      editCustomMetadataField={editCustomMetadataField}
    />
  );
};

export default RootCustomMetadataList;

export { CustomMetadataList, CustomMetadataRow };
