/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client, clientWithoutCreds } from "api/client";
import wretch from "wretch";
import { AiStoryTranslationConfig, WorkspaceRealtimeData } from "../api/workspace";
import { convertObjKeysKebabToCamel } from "utils";

export const getWorkspaceData = (filterParams) => {
  return client
    .url("/api/workspace")
    .json(filterParams)
    .post()
    .json();
};

export const saveFilters = (filterParams, filterName) => {
  return client
    .url("/api/workspace/filters")
    .json({ filter: filterParams, name: filterName })
    .post()
    .json();
};

export const getSavedFilters = () => {
  return client
    .url("/api/workspace/filters")
    .get()
    .json();
};

export const updateSavedFilter = (id, filter) => {
  return client
    .url(`/api/workspace/filters/${id}`)
    .json(filter)
    .put()
    .res();
};

export const deleteSavedFilter = (id) => {
  return client
    .url(`/api/workspace/filters/${id}`)
    .delete()
    .res();
};

export const setDefaultFilter = (id) => {
  return client
    .url(`/api/workspace/filters/${id}/default`)
    .post()
    .res();
};

export const getDefaultFilter = () => {
  return client
    .url(`/api/workspace/filters/default`)
    .get()
    .json();
};

export const shareUnshareFilter = (id, action) => {
  return client
    .url(`/api/workspace/filters/${id}/${action}`)
    .put()
    .res();
};

export const removeDefaultFilter = () => {
  return client
    .url(`/api/workspace/filters/default`)
    .delete()
    .res();
};

export const updateBulkAccessLevel = (query) => {
  return client
    .url(`/api/stories/bulk-access-level-update`)
    .json(query)
    .post()
    .res();
};

export const deleteStory = (storyId) => {
  return client
    .url(`/api/story/${storyId}`)
    .delete()
    .res();
};

export const getMediaLibraryData = (options) => {
  return client
    .url("/api/search/media/image")
    .query(options)
    .get()
    .json();
};

export const signFile = (params) => {
  return client
    .url("/sign")
    .query(params)
    .get()
    .json();
};

export const uploadFileDetails = (url, formData) => {
  return wretch(url)
    .formData(formData)
    .post()
    .res();
};

export const saveMedia = (reqParams) => {
  return client
    .url("/api/media-library-elements")
    .json({ media: reqParams })
    .post()
    .json();
};

export const uploadExternalMedia = (media) => {
  return client
    .url("/api/external-media/save")
    .json(media)
    .post()
    .json();
};

export const getAblyToken = () => {
  return client
    .url("/api/ably-token")
    .get()
    .json();
};

export const getEntities = (params) => {
  return client
    .url("/api/entity")
    .query(params)
    .get()
    .json();
};

export const createEntity = (params) => {
  return client
    .url("/api/entity")
    .json(params)
    .post()
    .json();
};

export const updateEntity = (id, params) => {
  return client
    .url(`/api/entity/${id}`)
    .json(params)
    .put()
    .json();
};

export const deleteEntity = (id) => {
  return client
    .url(`/api/entity/${id}`)
    .delete()
    .res();
};

export const restoreStory = (storyId, storyVersionId) => {
  return client
    .url(`/api/story/${storyId}/restore?format=itsman-x`)
    .json({ "story-version-id": storyVersionId })
    .post()
    .json();
};

export const getOEmbedFromUrl = (params) => {
  return client
    .url("/api/oembed")
    .query(params)
    .get()
    .json();
};

export const getPublishedStories = (params) => {
  return client
    .url("/api/search/published")
    .query(params)
    .get()
    .json((json) => json.stories);
};

export const getEntitiesByType = ({ query, entityTypes }) => {
  const entityTypesString = entityTypes.join(",");
  return client
    .url(`/api/entity`)
    .query({ q: query, type: entityTypesString })
    .get()
    .json((json) => json.entities);
};

export const deleteSocialCards = (cardId, contentId) => {
  return client
    .url("/api/social/delete")
    .json({ "card-id": cardId, "content-id": contentId })
    .post()
    .res();
};

export const getRecommendedTags = (storyId) => {
  return client
    .url(`/api/story/${storyId}/recommended-tags/`)
    .get()
    .json();
};

export const getAdvancedSettings = () => {
  return client
    .url(`/api/advanced-settings`)
    .get()
    .json();
};

export const setAdvancedSetting = (key, value) => {
  return client
    .url(`/api/advanced-settings`)
    .json({ settings: { [key]: value } })
    .post()
    .json();
};

export const fetchTimelineEvents = (storyId, { limit, offset }) => {
  return client
    .url(`/api/story/${storyId}/activity-log`)
    .query({ limit, offset })
    .get()
    .json();
};

export const updateStoryStatus = (storyId, params) => {
  return client
    .url(`/api/story/${storyId}/status?format=itsman-x`)
    .json(params)
    .post()
    .json();
};

export const getIntegrationsData = () => {
  return client
    .url(`/api/other-settings`)
    .get()
    .json();
};

export const getUserRoles = () => {
  return client
    .url(`/api/role`)
    .get()
    .json();
};

export const deleteRoleFromRolesList = (roleId) => {
  return client
    .url(`/api/role/${roleId}`)
    .delete()
    .res();
};

export const getConfigureData = () => {
  return client
    .url(`/api/select-features`)
    .get()
    .json();
};

export const updateConfigurationData = (featureToggles) => {
  return client
    .url(`/api/select-features`)
    .json({ configuration: featureToggles })
    .post()
    .res();
};

export const getCustomTemplates = () => {
  return client
    .url(`/api/content-templates/`)
    .get()
    .json();
};

export const updateTemplate = (template) => {
  return client
    .url(`/api/content-templates/${template["content-type"]}/${template.slug}`)
    .json({ template })
    .post()
    .json();
};

export const addTemplate = (template) => {
  return client
    .url(`/api/content-templates/`)
    .json({ template })
    .post()
    .json();
};

export const spellCheck = (apiHost, strToSpellCheck, service?: string, language?: string) => {
  return clientWithoutCreds
    .url(`${apiHost}/api/spell-check`)
    .json({
      words: strToSpellCheck,
      service: service || null,
      language: language || null
    })
    .post()
    .json();
};

export const translateStory = (storyId: string, storyTranslationConfig: AiStoryTranslationConfig) => {
  return client
    .url(`/api/story/${storyId}/translate`)
    .json(storyTranslationConfig)
    .post()
    .json();
};

export type StoryTranslation = {
  "translated-story-id": string;
  "target-publisher-id": number;
  "source-language": string;
  "target-language": string;
  "editor-url": string;
};

export const getStoryTranslations = (
  storyId: string,
  sourceLanguage: string,
  targetLanguage: string,
  targetPublisherId: string
): Promise<{ translations: StoryTranslation[] }> => {
  const url = `/api/story/${storyId}/translations`;
  const queryParams = {
    ...(targetLanguage ? { "target-language": targetLanguage } : {}),
    ...(sourceLanguage ? { "source-language": sourceLanguage } : {}),
    ...(targetPublisherId ? { "target-publisher-id": targetPublisherId } : {})
  };
  return client
    .url(url)
    .query(queryParams)
    .get()
    .json();
};

export const cloneStory = (storyId: string) => {
  return client
    .url(`/api/story/${storyId}/clone`)
    .json({})
    .post()
    .json();
};

export const cloneStorytoPrint = (storyId: string) => {
  return client
    .url(`/api/story/${storyId}/print`)
    .json({})
    .post()
    .json();
};

export const getStoryWorkspaceRealtimeData = async (requestBody): Promise<WorkspaceRealtimeData> => {
  const result = await client
    .url(`/api/workspace/stories/realtime`)
    .json(requestBody)
    .post()
    .json();
  const { aggregations, ...otherData } = result;
  convertObjKeysKebabToCamel(otherData);
  return { aggregations, ...otherData } as WorkspaceRealtimeData;
};
