/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useCallback, useEffect, useState } from "react";
import { TextFieldWithCount } from "components/text-field/text-field";
import CategoryTitle from "components/category-title/category-title";
import PushNotificationTargets from "pages/content/push-notification/components/push-notification-targets/push-notification-targets";
import { Target } from "api/push-notification";
import { t } from "i18n";
import { AiGenerateButton } from "pages/story-editor/components/ai-generate-button/ai-generate-button";
import { AllowedStoryFields } from "api/ai";
import styles from "./push-notifications.module.css";
import classnames from "classnames/bind";
import { compose } from "redux";
import { connect } from "react-redux";
import { PartialAppState } from "pages/story-editor/state";
import { defaultTo, get, isEmpty } from "lodash";
import AiSuggestions from "pages/story-editor/components/ai-suggestions/ai-suggestions";
import Checkbox from "components/checkbox/checkbox";
import DatePicker from "components/date-picker/date-picker";
import { PushNotificationStoryFieldLinkStatus, PushNotificationStoryFeature } from "api/story";
import LinkUnlinkButton from "pages/story-editor/components/link-unlink-button/link-unlink-button";

const cx = classnames.bind(styles);

interface StateProps {
  isGeneratePNTitleEnabled: boolean;
  isGeneratePNMessageEnabled: boolean;
  pushNotificationStoryFeatures: PushNotificationStoryFeature;
  pushNotificationToStoryMapping: {
    title?: string | null;
    message?: string | null;
  } | null;
}

export const PushNotifications = ({
  story,
  fieldLimits,
  onPushNotificationTextChange,
  onPushNotificationTargetChange,
  onPushNotificationTitleChange,
  onStoryFeatureChange,
  channelsEnabled,
  disabled,
  isGeneratePNTitleEnabled,
  isGeneratePNMessageEnabled,
  pushNotificationStoryFeatures,
  pushNotificationToStoryMapping
}) => {
  const [showPNTitleAiSuggestions, setShowPNTitleAiSuggestions] = useState(false);
  const [showPNMessageAiSuggestions, setShowPNMessageAiSuggestions] = useState(false);
  const [generatingPNTitle, setGeneratingPNTitle] = useState(false);
  const [generatingPNMessage, setGeneratingPNMessage] = useState(false);
  const targets: Target[] = story["push-notification-targets"] || [];
  const maxLength = fieldLimits["push-notification"] && fieldLimits["push-notification"].limit;

  const showGeneratePNTitleBtn = !story["is-published"] && isGeneratePNTitleEnabled;
  const showGeneratePNMessageBtn = !story["is-published"] && isGeneratePNMessageEnabled;
  const togglePNTitleDisableBtn = story["is-published"] || disabled;
  const togglePNMessageDisableBtn = story["is-published"] || disabled;
  const isStoryScheduled = story.status === "scheduled";
  const disablePushNotificationLinking = story["is-published"] || isStoryScheduled || disabled; // Disable the push notification linking if the story is already published or if the 'disabled' flag is true.
  const isScheduled = get(pushNotificationStoryFeatures, ["is-scheduled"], false);
  const publishAt = get(pushNotificationStoryFeatures, ["publish-at"]) || Date.now();
  const handleDateChange = (selectedPublishAt: string | boolean) => {
    onStoryFeatureChange("push-notification", { "publish-at": selectedPublishAt });
  };

  const linkedWithStoryFieldsStatus: PushNotificationStoryFieldLinkStatus = get(
    pushNotificationStoryFeatures,
    ["linked-with-story-fields-status"],
    {
      title: null,
      message: null
    }
  );

  // Update values in `linkedStoryFields` from story based push notification mapping
  const updateLinkedWithStoryFieldsStatus = useCallback(
    (field: "title" | "message", toggle: boolean) => {
      onStoryFeatureChange("push-notification", {
        "linked-with-story-fields-status": {
          ...linkedWithStoryFieldsStatus,
          [field]: toggle
        }
      });
    },
    [onStoryFeatureChange, linkedWithStoryFieldsStatus]
  );

  // Initialize state for linked story fields with default values,
  // using the 'push-notification-title' and 'push-notification' from the 'story' prop if available.
  const [linkedStoryFieldsValue, setLinkedStoryFieldsValue] = useState({
    title: story?.["push-notification-title"] || null, // Default title is story['push-notification-title'] or null if not available
    message: story?.["push-notification"] || null // Default message is story['push-notification'] or null if not available
  });

  useEffect(() => {
    const storyTitleField = pushNotificationToStoryMapping.title;
    if (!disablePushNotificationLinking && storyTitleField && story[storyTitleField]) {
      // Initialize pn title link button
      if (linkedWithStoryFieldsStatus.title === null) {
        updateLinkedWithStoryFieldsStatus("title", true);
      }
      if (linkedStoryFieldsValue.title !== story[storyTitleField]) {
        setLinkedStoryFieldsValue({ ...linkedStoryFieldsValue, title: story[storyTitleField] });
      }
    }
  }, [
    pushNotificationToStoryMapping.title,
    story,
    setLinkedStoryFieldsValue,
    linkedStoryFieldsValue,
    linkedWithStoryFieldsStatus.title,
    updateLinkedWithStoryFieldsStatus,
    disablePushNotificationLinking
  ]);

  useEffect(() => {
    const storyMessageField = pushNotificationToStoryMapping.message;
    if (!disablePushNotificationLinking && storyMessageField && story[storyMessageField]) {
      // Initialize pn message link button
      if (linkedWithStoryFieldsStatus.message === null) {
        updateLinkedWithStoryFieldsStatus("message", true);
      }
      if (linkedStoryFieldsValue.message !== story[storyMessageField]) {
        setLinkedStoryFieldsValue({ ...linkedStoryFieldsValue, message: story[storyMessageField] });
      }
    }
  }, [
    pushNotificationToStoryMapping.message,
    story,
    setLinkedStoryFieldsValue,
    linkedStoryFieldsValue,
    linkedWithStoryFieldsStatus.message,
    updateLinkedWithStoryFieldsStatus,
    disablePushNotificationLinking
  ]);

  // Update PN fields from story fields based on link statuses
  useEffect(() => {
    if (!disablePushNotificationLinking) {
      if (linkedWithStoryFieldsStatus.title && linkedStoryFieldsValue.title !== story["push-notification-title"]) {
        onPushNotificationTitleChange(linkedStoryFieldsValue.title);
      }
      if (linkedWithStoryFieldsStatus.message && linkedStoryFieldsValue.message !== story["push-notification"]) {
        onPushNotificationTextChange(linkedStoryFieldsValue.message);
      }
    }
  }, [
    linkedStoryFieldsValue,
    linkedWithStoryFieldsStatus,
    onPushNotificationTitleChange,
    onPushNotificationTextChange,
    story,
    disablePushNotificationLinking
  ]);

  // If user manually updates PN fields value, make link status as false
  const onPushNotificationTitleUserChange = (title: string) => {
    onPushNotificationTitleChange(title);
    updateLinkedWithStoryFieldsStatus("title", false);
  };
  const onPushNotificationTextUserChange = (text: string) => {
    onPushNotificationTextChange(text);
    updateLinkedWithStoryFieldsStatus("message", false);
  };

  const toggleLinkWithStoryFields = (field: "title" | "message") => {
    if (linkedWithStoryFieldsStatus[field]) {
      updateLinkedWithStoryFieldsStatus(field, false);
    } else {
      updateLinkedWithStoryFieldsStatus(field, true);
      field === "title"
        ? onPushNotificationTitleChange(linkedStoryFieldsValue[field])
        : onPushNotificationTextChange(linkedStoryFieldsValue[field]);
    }
  };

  const showTitleLinkButton =
    linkedWithStoryFieldsStatus.title !== null && pushNotificationToStoryMapping.title && !story["is-published"];
  const showMessageLinkButton =
    linkedWithStoryFieldsStatus.message !== null && pushNotificationToStoryMapping.message && !story["is-published"];
  return (
    <React.Fragment>
      <CategoryTitle title={t("story-editor.inspector.push-notification")} />
      <div
        className={cx("story-inspector-pn-title", {
          "story-inspector-pn-title--show-ai-generate-btn": showGeneratePNTitleBtn,
          "story-inspector-pn-title--show-link": showTitleLinkButton,
          "story-inspector-pn-title--show-ai-generate-btn-and-link": showGeneratePNTitleBtn && showTitleLinkButton
        })}>
        <AiSuggestions
          isOpen={showPNTitleAiSuggestions}
          onClose={() => setShowPNTitleAiSuggestions(false)}
          storyField={AllowedStoryFields.PUSH_NOTIFICATION_TITLE}
          characterLimit={maxLength}
          onSuggestionSelect={(suggestion: string) => onPushNotificationTitleUserChange(suggestion)}
          currentFieldValue={story["push-notification-title"]}
          onGeneratingSuggestion={(generatingSuggestion: boolean) => setGeneratingPNTitle(generatingSuggestion)}
          onSuggestionsChange={(suggestions: string[]) => {
            if (isEmpty(story["push-notification-title"]) && suggestions.length > 0) {
              onPushNotificationTitleUserChange(suggestions[0]);
            }
          }}>
          {showTitleLinkButton && (
            <LinkUnlinkButton
              mappingFrom={"pn-title"}
              data-test-id={"pn-title-link-unlink-btn"}
              mappingTo={pushNotificationToStoryMapping.title}
              className={cx("story-inspector-pn-title-link-btn")}
              onClick={() => toggleLinkWithStoryFields("title")}
              isLinked={!!linkedWithStoryFieldsStatus.title}
              disabled={togglePNTitleDisableBtn}
            />
          )}
          <TextFieldWithCount
            label={t("story-editor.inspector.push-notification-title")}
            value={story["push-notification-title"]}
            disabled={togglePNTitleDisableBtn}
            maxValue={maxLength}
            onChange={onPushNotificationTitleUserChange}
          />
          {showGeneratePNTitleBtn && (
            <AiGenerateButton
              classname={cx("story-inspector-pn-title__ai-generate-btn")}
              disabled={disabled}
              loading={generatingPNTitle}
              onClick={() => setShowPNTitleAiSuggestions(!showPNTitleAiSuggestions)}
            />
          )}
        </AiSuggestions>
      </div>
      <div
        className={cx("story-inspector-pn-message", {
          "story-inspector-pn-message--show-ai-generate-btn": showGeneratePNMessageBtn,
          "story-inspector-pn-message--show-link": showMessageLinkButton,
          "story-inspector-pn-message--show-ai-generate-btn-and-link": showGeneratePNMessageBtn && showMessageLinkButton
        })}>
        <AiSuggestions
          isOpen={showPNMessageAiSuggestions}
          onClose={() => setShowPNMessageAiSuggestions(false)}
          storyField={AllowedStoryFields.PUSH_NOTIFICATION_MESSAGE}
          characterLimit={maxLength}
          onSuggestionSelect={(suggestion: string) => onPushNotificationTextUserChange(suggestion)}
          currentFieldValue={story["push-notification"]}
          onGeneratingSuggestion={(generatingSuggestion: boolean) => setGeneratingPNMessage(generatingSuggestion)}
          onSuggestionsChange={(suggestions: string[]) => {
            if (isEmpty(story["push-notification"]) && suggestions.length > 0) {
              onPushNotificationTextUserChange(suggestions[0]);
            }
          }}>
          {showMessageLinkButton && (
            <LinkUnlinkButton
              mappingFrom="pn-message"
              data-test-id={"pn-message-link-unlink-btn"}
              mappingTo={pushNotificationToStoryMapping.message}
              className={cx("story-inspector-pn-message-link-btn")}
              isLinked={!!linkedWithStoryFieldsStatus.message}
              onClick={() => toggleLinkWithStoryFields("message")}
              disabled={togglePNMessageDisableBtn}
            />
          )}
          <TextFieldWithCount
            label={t("story-editor.inspector.push-notification-message")}
            value={story["push-notification"]}
            disabled={togglePNMessageDisableBtn}
            maxValue={maxLength}
            onChange={(val) => onPushNotificationTextUserChange(val)}
          />
          {showGeneratePNMessageBtn && (
            <AiGenerateButton
              classname={cx("story-inspector-pn-message__ai-generate-btn")}
              disabled={disabled}
              loading={generatingPNMessage}
              onClick={() => setShowPNMessageAiSuggestions(!showPNMessageAiSuggestions)}
            />
          )}
        </AiSuggestions>
      </div>
      {!story["is-published"] && (
        <PushNotificationTargets
          targets={targets}
          channelsEnabled={channelsEnabled}
          onUpdate={(targets) => onPushNotificationTargetChange(targets)}
          disabled={disabled}
        />
      )}
      <div className={cx("push-notification-scheduler-container")}>
        <Checkbox
          id={`social-account-${story["notification-id"]}`}
          disabled={togglePNTitleDisableBtn}
          label={t("story-editor.inspector.schedule-push-notification")}
          checked={isScheduled}
          onChange={(checked) => {
            return onStoryFeatureChange("push-notification", {
              "is-scheduled": checked,
              ...(checked ? { "publish-at": Date.now() } : { "publish-at": null })
            });
          }}
          classname={styles["push-notification-scheduler-checkbox"]}
        />
        {isScheduled && (
          <DatePicker
            dateFormat={"dd/MM/yyyy h:mm a"}
            disabled={togglePNTitleDisableBtn}
            onChange={handleDateChange}
            selectedDate={publishAt}
            showTimeSelect
          />
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    isGeneratePNTitleEnabled: get(
      state.config,
      ["ai-content-generation", "story", AllowedStoryFields.PUSH_NOTIFICATION_TITLE],
      false
    ),
    isGeneratePNMessageEnabled: get(
      state.config,
      ["ai-content-generation", "story", AllowedStoryFields.PUSH_NOTIFICATION_MESSAGE],
      false
    ),
    pushNotificationStoryFeatures: get(state.storyEditor, ["story", "story-features", "push-notification"], {
      "is-scheduled": false,
      "publish-at": Date.now()
    }),
    pushNotificationToStoryMapping: defaultTo(get(state, ["config", "push-notification-to-story-mapping"]), {})
  };
};

export default compose(connect(mapStateToProps))(PushNotifications);
