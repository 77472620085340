/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import GA from "react-ga";
import { mapValues } from "lodash";

interface GAEventsMap {
  [key: string]: Array<any>;
}
export interface GAEventsWithCallbacks {
  [key: string]: Function;
}

export function sendGAEvent(label: string) {
  const { hostname, pathname, search, hash } = window.location;
  const publisherName = hostname.replace(".quintype.com", "");
  const pageURL = pathname + search + hash;
  GA.event({
    category: publisherName,
    action: pageURL,
    label: label
  });
}

export function withGAEvent(f: Function, label: string) {
  sendGAEvent(label);
  f();
}

export function withGAEvents(gaEventsMap: GAEventsMap): GAEventsWithCallbacks {
  return mapValues(gaEventsMap, ([f, label], _) => () => withGAEvent(f, label));
}
