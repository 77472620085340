/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { Domains, Domain, CurrentDomain } from "store/domain/domain";
import { normalize } from "utils";

export const getAllDomains = (): Promise<Domains> => {
  return client
    .url(`/api/v1/domains`)
    .get()
    .json()
    .then((res: Array<Domain>) => normalize(res));
};

export const createDomain = (domainData: CurrentDomain): Promise<void> => {
  return client
    .url(`/api/v1/domains`)
    .post(domainData)
    .res();
};

export const updateDomain = (domainId: number, domainData: CurrentDomain): Promise<void> => {
  return client
    .url(`/api/v1/domains/${domainId}`)
    .patch(domainData)
    .res();
};

export const deleteDomain = (domainId: number): Promise<void> => {
  return client
    .url(`/api/v1/domains/${domainId}`)
    .delete()
    .res();
};
