/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Quote = ({ width = "24", height = "24", color = "#c6ccd5" }) => (
  <svg width={width} height={height} fill={color} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      d="M8.56 21v-4.32l.44-.05c.13 0 3.13-.41 2.91-4.66H6.78V3h10.31v.48s.16 3.94.08 8.21S14 20.63 9.1 21zm1-3.48v2.39c4-.59 6.55-4.64 6.61-8.24.06-3.37 0-6.51-.06-7.68H7.8v7h5.05l.05.44c.45 4.57-2.23 5.78-3.33 6.09z"
    />
  </svg>
);

export default Quote;
