/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Settings } from "api/settings";
import { SEOMetadataResponse } from "api/seo-metadata";

enum actions {
  LOAD_SETTINGS_DATA = "@@settings/LOAD_SETTINGS_DATA",
  LOAD_SETTINGS_DATA_SUCCESS = "@@settings/LOAD_SETTINGS_DATA_SUCCESS",
  LOAD_SETTINGS_DATA_FAILURE = "@@settings/LOAD_SETTINGS_DATA_FAILURE",

  UPDATE_IMAGE_UPLOAD_STATUS = "@@settings/UPDATE_IMAGE_UPLOAD_STATUS",
  UPDATE_IMAGE_UPLOAD_FAILURE = "@@settings/UPDATE_IMAGE_UPLOAD_FAILURE",

  UPDATE_GENERAL_SETTINGS = "@@settings/UPDATE_GENERAL_SETTINGS",
  UPDATE_SOCIAL_LINKS = "@@settings/UPDATE_SOCIAL_LINKS",
  UPDATE_SEO_METADATA = "@@settings/UPDATE_SEO_METADATA"
}

export type LoadSettingsDataAction = { type: actions.LOAD_SETTINGS_DATA };
export type LoadSettingsDataSuccessAction = {
  type: actions.LOAD_SETTINGS_DATA_SUCCESS;
  payload: { settings: Settings | null; seoMetadata: SEOMetadataResponse | null };
};
export type LoadSettingsDataFailureAction = {
  type: actions.LOAD_SETTINGS_DATA_FAILURE;
  payload: { error: Error };
};

export type UpdateGeneralSettingsAction = {
  type: actions.UPDATE_GENERAL_SETTINGS;
  payload: { key: string; changes?: object | string | null };
};
export type UpdateSeoMetadataAction = {
  type: actions.UPDATE_SEO_METADATA;
  payload: { changes: object };
};
export type UpdateSocialLinksAction = {
  type: actions.UPDATE_SOCIAL_LINKS;
  payload: { socialLinks: object };
};

export type UpdateImageUploadStatusAction = {
  type: actions.UPDATE_IMAGE_UPLOAD_STATUS;
  payload: { uploading: boolean };
};
export type UpdateImageUploadFailureAction = {
  type: actions.UPDATE_IMAGE_UPLOAD_FAILURE;
  payload: { imageError: string };
};

export type ActionTypes =
  | LoadSettingsDataAction
  | LoadSettingsDataSuccessAction
  | LoadSettingsDataFailureAction
  | UpdateGeneralSettingsAction
  | UpdateSeoMetadataAction
  | UpdateSocialLinksAction
  | UpdateImageUploadStatusAction
  | UpdateImageUploadFailureAction;

export default actions;
