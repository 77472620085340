/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import TextField from "components/text-field/text-field";
import { get } from "lodash";
import { AmpConfig, ComscoreTrackingVarsTypes, MenuTypes, ChartBeatConfig } from "api/amp-config";
import { convertNumberToString, convertStringToInteger } from "../utils";
import Select from "components/select/select";
import { isClientValidationError } from "utils/validation.utils";
import { Switch } from "components/switch/switch";
import FieldLabel from "components/field-label/field-label";
import styles from "./other-config.module.css";

interface InvalidElementStrategy {
  name: string;
  slug: string;
}

const invalidStoryElementStrategy: Array<InvalidElementStrategy> = [
  { name: "Redirect to web version", slug: "redirect-to-web-version" },
  { name: "Render with notification", slug: "render-with-notification" }
];

interface Props {
  ampConfig: AmpConfig;
  saveErrors: Error | null;
  updateConfig: (
    key: string,
    changes: ComscoreTrackingVarsTypes | string | boolean | MenuTypes | ChartBeatConfig | number | null
  ) => void;
}

const OtherConfig: React.SFC<Props> = ({ ampConfig, saveErrors, updateConfig }) => {
  return (
    <React.Fragment>
      <div className={styles["amp-config-category-details-container"]}>
        <h3 className={styles["amp-config-title"]}>{t("settings.amp.others.title")}</h3>
        <p className={styles["amp-config-description"]}>{t("settings.amp.others.description")}</p>
      </div>
      <div className={styles["amp-config-fields-container"]}>
        <TextField
          label={t("settings.amp.others.logo")}
          value={ampConfig["logo-url"]}
          onChange={(value) => updateConfig("logo-url", value)}
          hint={t("settings.amp.others.logo-size-error")}
          errorMessage={get(saveErrors, ["logo-url", "code"])}
        />
        <TextField
          label={t("settings.amp.others.ga_traking_id")}
          value={ampConfig["google-analytics-tracking-id"]}
          onChange={(value) => updateConfig("google-analytics-tracking-id", value)}
          hint="e.g. G-XXXXXXXXX, UA-XXXXXXXX-X"
          errorMessage={get(saveErrors, ["google-analytics-tracking-id", "code"])}
        />
        <TextField
          label={t("settings.amp.others.gtm-id")}
          value={ampConfig["gtm-id"]}
          onChange={(value) => updateConfig("gtm-id", value)}
          hint="e.g. GTM-XXXXXXX"
          errorMessage={get(saveErrors, ["gtm-id", "code"])}
        />
        <TextField
          label={t("settings.amp.others.comscore-c1")}
          value={convertNumberToString(get(ampConfig, ["comscore-tracking-vars", "c1"]))}
          onChange={(value) =>
            updateConfig("comscore-tracking-vars", {
              ...ampConfig["comscore-tracking-vars"],
              c1: convertStringToInteger(value)
            })
          }
          hint={t("settings.amp.others.comscore-tracking-vars-hint")}
          errorMessage={
            isClientValidationError<AmpConfig>(saveErrors)
              ? get(saveErrors, ["errors", "comscore-tracking-vars", "c1", "0"])
              : get(saveErrors, ["comscore-tracking-vars", "c1", "code"])
          }
        />
        <TextField
          label={t("settings.amp.others.comscore-c2")}
          value={convertNumberToString(get(ampConfig, ["comscore-tracking-vars", "c2"]))}
          onChange={(value) =>
            updateConfig("comscore-tracking-vars", {
              ...ampConfig["comscore-tracking-vars"],
              c2: convertStringToInteger(value)
            })
          }
          hint={t("settings.amp.others.comscore-tracking-vars-hint")}
          errorMessage={
            isClientValidationError<AmpConfig>(saveErrors)
              ? get(saveErrors, ["errors", "comscore-tracking-vars", "c2", "0"])
              : get(saveErrors, ["comscore-tracking-vars", "c2", "code"])
          }
        />

        <TextField
          label={t("settings.amp.others.chartbeat")}
          value={convertNumberToString(get(ampConfig, ["chartbeat", "uid"]))}
          onChange={(value) =>
            updateConfig("chartbeat", {
              ...ampConfig["chartbeat"],
              uid: value
            })
          }
          hint={t("common.hint-eg", { value: "23445" })}
          errorMessage={get(saveErrors, ["chartbeat", "uid", "code"])}
        />

        <TextField
          label={t("settings.amp.others.related_collection")}
          value={convertNumberToString(get(ampConfig, ["related-collection-id"]))}
          onChange={(value) => {
            const intValue = parseInt(value);
            updateConfig("related-collection-id", isNaN(intValue) ? null : intValue);
          }}
          hint={t("common.hint-eg", { value: "23445" })}
          errorMessage={get(saveErrors, ["related-collection-id", "code"])}
        />

        <Select
          label={t("settings.amp.others.invalid-element-strategy")}
          options={invalidStoryElementStrategy}
          getOptionLabel={(item) => item.name}
          getOptionValue={(item) => item.slug}
          value={
            ampConfig["invalid-elements-strategy"]
              ? invalidStoryElementStrategy.find((item) => item.slug === ampConfig["invalid-elements-strategy"])
              : undefined
          }
          onChange={(value: InvalidElementStrategy) => updateConfig("invalid-elements-strategy", value.slug)}
        />
        <div className={styles["amp-config-switch-field-container"]}>
          <FieldLabel label={t("settings.amp.others.google-client-id")} />
          <Switch
            id={"1"}
            checked={get(ampConfig, ["google-client-id-api"], false)}
            onChange={(value) => updateConfig("google-client-id-api", value)}
          />
        </div>
        <div className={styles["amp-config-switch-field-container"]}>
          <FieldLabel label={t("menu.title")} />
          <Switch
            id={"2"}
            checked={get(ampConfig, ["menu", "enabled"], false)}
            onChange={(value) => updateConfig("menu", { enabled: value })}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default OtherConfig;
