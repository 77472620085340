/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { useState, useEffect, FunctionComponent, useCallback } from "react";
import { debounce } from "lodash";

export function useElementScroll(element: HTMLElement | null) {
  const [prevScrollPosition, setScrollPosition] = useState(0);
  const [scrollDifference, setScrollDifference] = useState(0);

  const handleScroll = useCallback(
    debounce(() => {
      if (element) {
        const currentScrollPosition = element.scrollTop;
        setScrollDifference(currentScrollPosition - prevScrollPosition);
        setScrollPosition(currentScrollPosition);
      }
    }, 100),
    [prevScrollPosition]
  );

  useEffect(() => {
    element && element.addEventListener("scroll", handleScroll);
    return () => {
      element && element.removeEventListener("scroll", handleScroll);
    };
  });
  return scrollDifference;
}

export function useVisibleOnScroll(buffer = 0) {
  const [buttonVisible, setButtonVisible] = useState(true);
  const mainLayout = document.getElementById("main-layout");
  const scrollDifference = useElementScroll(mainLayout);
  if (buttonVisible && scrollDifference > buffer) {
    setButtonVisible(false);
  }
  if (!buttonVisible && scrollDifference < -buffer) {
    setButtonVisible(true);
  }
  return buttonVisible;
}

/* Since Story Editor is a class component, using hooks inside it will not work.
Hence the below function `VisibleOnScroll` was created
Reference: https://infinum.com/the-capsized-eight/how-to-use-react-hooks-in-class-components */

type ChildrenProps = (x: boolean) => any;
interface OnScrollProps {
  children: ChildrenProps;
}

export const VisibleOnScroll: FunctionComponent<OnScrollProps> = ({ children }) => {
  const onScroll = useVisibleOnScroll(5);

  return children(onScroll);
};
