/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import produce from "immer";
import actions, { ActionTypes } from "./actions";
import { State, INITIAL_TASK_STATE } from "./state";
import { Task } from "api/tasks";

const updateMatchingTask = (currentTask: Task | null, task: Task): Task | null => {
  if (currentTask == null) {
    return currentTask;
  } else if (currentTask.id === task.id) {
    currentTask.completed = task.completed;
    return currentTask;
  } else {
    const updatedSubtask: Task[] = currentTask.subtasks.map((subTask: Task) => {
      subTask.id === task.id && (subTask.completed = task.completed);
      return subTask;
    });
    currentTask.subtasks = updatedSubtask;
    return currentTask;
  }
};

export default function taskReducer(state: State = INITIAL_TASK_STATE, action: ActionTypes) {
  return produce(state, (draft) => {
    switch (action.type) {
      case actions.CLOSE_TASK:
        draft.ui.taskLoader = { loading: true, error: null };
        break;
      case actions.CLOSE_TASK_SUCCESS:
        draft.ui.taskLoader = { loading: false, error: null };
        draft.app.currentTask = updateMatchingTask(draft.app.currentTask, action.payload);
        break;
      case actions.CLOSE_TASK_FAILURE:
        draft.ui.taskLoader = { loading: false, error: null };
        break;
      case actions.LOAD_TASK:
        draft.ui.taskLoader = { loading: true, error: null };
        break;
      case actions.LOAD_TASK_SUCCESS:
        draft.ui.taskLoader = { loading: false, error: null };
        draft.app.currentTask = action.payload;
        draft.app.isLoggedIn = true;
        break;
      case actions.LOAD_ALL_TASKS:
        draft.ui.myTaskLoader = { loading: true, error: null };
        break;
      case actions.CANCEL_LOAD_ALL_TASKS:
        draft.ui.myTaskLoader = { loading: false, error: null };
        break;
      case actions.LOAD_ALL_TASKS_SUCCESS:
        draft.ui.myTaskLoader = { loading: false, error: null };
        draft.app.allTasks = action.payload;
        draft.app.isLoggedIn = true;
        break;
      case actions.LOAD_ALL_TASKS_FAILURE:
        draft.ui.myTaskLoader = { loading: false, error: action.payload };
        break;
      case actions.SET_TASK_IMAGE:
        draft.app.media.selectedMedia = action.payload;
        break;
      case actions.SHOW_INSPECTOR:
        !action.payload && (draft.app.media.selectedMedia = []);
        draft.ui.showInspector = action.payload;
        break;
      case actions.DELETE_TASK_IMAGE:
        draft.ui.showInspector = !state.ui.showInspector;
        draft.app.media.selectedMedia = [];
        break;
      case actions.UPDATE_IMAGE_DATA:
        const selectedImage = draft.app.media.selectedMedia && draft.app.media.selectedMedia[action.payload.imageIndex];
        draft.app.media.selectedMedia[action.payload.imageIndex] = {
          ...selectedImage,
          [`${action.payload.key}`]: action.payload.value
        };
        break;
      case actions.UPDATE_IMAGE_UPLOAD_STATUS:
        draft.app.media.isUploading = action.payload;
        break;
      case actions.SAVE_IMAGE_VALIDATION_ERRORS:
        draft.app.media.error = action.payload;
        break;
      case actions.TASK_MANAGER_LOGIN:
        draft.ui.taskLoader = { loading: false, error: null };
        draft.ui.myTaskLoader = { loading: false, error: null };
        draft.app.isLoggedIn = false;
        break;
      case actions.CLEAR_SELECTED_MEDIA:
        draft.app.media.selectedMedia = [];
        draft.app.media.error = [];
        break;
      default:
        break;
    }
  });
}
