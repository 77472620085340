/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

export function getAdminAccessToken(_integrationId?: number) {
  return client
    .url(`/api/v1/bk-access-token`)
    .get()
    .json()
    .then((res) => res && res.token);
}
