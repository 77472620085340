/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { wrapInList, liftListItem } from "prosemirror-schema-list";
import { Schema, Node, NodeType, MarkType } from "prosemirror-model";
import { EditorState, Transaction } from "prosemirror-state";
import { findParentNodeClosestToPos } from "./find";
import { schema } from "pages/story-editor/prosemirror/schema";
// export const nop = () => true;

export function toggleList(schema: Schema, listType: NodeType) {
  function isList(node: Node) {
    return node.type === schema.nodes.bullet_list || node.type === schema.nodes.ordered_list;
  }

  const wrap = wrapInList(listType);
  const lift = liftListItem(schema.nodes.list_item);

  return (state: EditorState, dispatch: (tr: Transaction<Schema>) => void) => {
    const bulletListParentDetails = findParentNodeClosestToPos(state, "bullet_list");
    const orderedListParentDetails = findParentNodeClosestToPos(state, "ordered_list");

    const parentNodeDetails = bulletListParentDetails || orderedListParentDetails;
    const parentNode = parentNodeDetails && parentNodeDetails.node;
    const parentNodePosition = parentNodeDetails && parentNodeDetails.pos;

    if (parentNode && parentNodePosition) {
      if (parentNode.type === listType) {
        return lift(state, dispatch);
      }

      if (isList(parentNode)) {
        const tr = state.tr;
        tr.setNodeMarkup(parentNodePosition, listType);
        if (dispatch) dispatch(tr);
        return true;
      }
    }

    return wrap(state, dispatch);
  };
}

export function removeFormatting(schema: Schema, exclusions: Array<MarkType<Schema>> = []) {
  return (editorState: EditorState, dispatch: (tr: Transaction<Schema>) => void) => {
    const { from, to, empty } = editorState.selection,
      tr = editorState.tr;

    if (!empty) {
      for (const key in schema.marks) {
        const markType = schema.marks[key];
        if (!exclusions.includes(markType)) {
          tr.removeMark(from, to, markType);
        }
      }

      tr.setBlockType(from, to, schema.nodes.paragraph);

      if (dispatch) {
        dispatch(tr);
      }
    }

    return true;
  };
}

export type EntityLinkAttributes = {
  href: string;
  "entity-id": number;
  "entity-name": string;
  "entity-slug": string;
  "entity-type-id": number;
  "entity-type-slug": string;
  class: string;
};

export const setEntityLink = (
  editorState: EditorState,
  entityLinkAttributes: EntityLinkAttributes,
  text: string
): Transaction => {
  const tr = editorState.tr;
  const { ranges } = editorState.selection;

  for (let i = 0; i < ranges.length; i++) {
    let { $from, $to } = ranges[i];
    // Create entity link using prose-mirror schema
    const markType = schema.marks.entity_link;
    const mark = entityLinkAttributes ? markType.create(entityLinkAttributes) : markType.create();

    // Ensure positions are valid
    if ($from.pos < $to.pos) {
      let markStart = $from.pos;
      let markEnd = $to.pos;

      // Traverse document to find the full hyperlink range even when partial text is selected
      editorState.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
        if (node.isText) {
          const entityMark = node.marks.find((mark) => mark.type === markType);
          if (entityMark) {
            markStart = Math.min(markStart, pos); // Expand start position
            markEnd = Math.max(markEnd, pos + node.nodeSize); // Expand end position
          }
        }
      });

      // Set hyperlink text
      const newNode = editorState.schema.text(text, [mark]);
      tr.replaceWith(markStart, markEnd, newNode);
    }
  }
  return tr;
};
