/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useCallback, useEffect, useRef } from "react";
import { Menu, MenuAlign, MenuButton, MenuDirection, MenuItem, SubMenu } from "@szhsin/react-menu";
import classnames from "classnames/bind";
import styles from "./advanced-context-menu.module.css";

const cx = classnames.bind(styles);

interface AdvancedContextMenuProps {
  menuButton: React.ReactNode;
  direction?: MenuDirection;
  align?: MenuAlign;
  "data-test-id"?: string;
}

export const AdvancedContextMenu: React.FC<AdvancedContextMenuProps> = ({
  menuButton,
  children,
  direction = "bottom",
  align = "start",
  "data-test-id": dataTestId
}) => {
  const boundingBoxRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    // Assign the element with id 'main-layout' as the boundary ref
    boundingBoxRef.current = document.getElementById("main-layout");
  }, []);
  return (
    <Menu
      data-test-id={dataTestId}
      portal={true}
      direction={direction}
      align={align}
      menuButton={<MenuButton className={cx("context-menu-btn")}>{menuButton}</MenuButton>}
      menuClassName={cx("context-menu")}
      boundingBoxRef={boundingBoxRef}>
      {children}
    </Menu>
  );
};

interface AdvancedContextMenuItemProps {
  onClick?: () => void;
  disabled?: boolean;
  "data-test-id"?: string;
}

const getMenuItemClass = ({ hover, disabled }, existingClasses: string[] = []) => {
  const classNames = [...existingClasses, "context-menu-item"];
  if (hover) {
    classNames.push("context-menu-item--hover");
  }
  if (disabled) {
    classNames.push("context-menu-item--disabled");
  }
  return cx(...classNames);
};

export const AdvancedContextMenuItem: React.FC<AdvancedContextMenuItemProps> = ({
  onClick,
  children,
  disabled,
  "data-test-id": dataTestId
}) => {
  const getMenuItemClassNames = useCallback(getMenuItemClass, []);
  return (
    <MenuItem data-test-id={dataTestId} disabled={disabled} onClick={onClick} className={getMenuItemClassNames}>
      {children}
    </MenuItem>
  );
};

interface AdvancedContextSubmenuProps {
  label: string;
  "data-test-id"?: string;
}

export const AdvancedContextSubmenu: React.FC<AdvancedContextSubmenuProps> = ({
  label,
  children,
  "data-test-id": dataTestId
}) => {
  const getMenuItemClassNames = useCallback((modifiers) => getMenuItemClass(modifiers, ["context-submenu-item"]), []);
  return (
    <SubMenu
      overflow="auto"
      position="auto"
      data-test-id={dataTestId}
      label={label}
      menuClassName={cx("context-menu")}
      itemProps={{ className: getMenuItemClassNames }}>
      {children}
    </SubMenu>
  );
};
