/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ExtendableError } from "utils/error.utils";

export class TimeoutError extends ExtendableError {}
export class NotFoundError extends ExtendableError {}

interface HTTPErrorData {
  status: number;
  error: {
    message: string;
  };
}

export class HTTPError extends ExtendableError {
  status: number;
  error: {
    message: string;
  };

  constructor(errorData: HTTPErrorData) {
    const { status, error } = errorData;
    super(error.message);

    this.status = status;
    this.error = error;
  }
}

export function isHTTPError(error: Error): error is HTTPError {
  return Number.isInteger((error as any).status);
}
