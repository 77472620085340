/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Fields } from "api/route-data/story-route-data";
import { validations } from "pages/story-editor/utils";

interface ChildrenProps {
  fieldName: string;
  bypassFilter?: boolean;
}

interface OwnProps {
  fieldsSpec: Fields;
  children: React.ReactElement<ChildrenProps>[];
}

const MandatoryFilter: React.SFC<OwnProps> = ({ fieldsSpec, children }) => {
  return (
    <React.Fragment>
      {children.filter(
        (child) =>
          child.props.bypassFilter ||
          (!validations.isHidden(child.props.fieldName, fieldsSpec) &&
            validations.isMandatory(child.props.fieldName, fieldsSpec))
      )}
    </React.Fragment>
  );
};

export default MandatoryFilter;
