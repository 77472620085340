/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import Link from "components/icons/link";
import Unlink from "components/icons/unlink";
import classnames from "classnames/bind";
import styles from "./link-unlink-button.module.css";
import Tooltip from "components/tooltip/tooltip";
import t from "i18n";
import Button from "components/button/button";

const cx = classnames.bind(styles);

interface Props {
  isLinked: boolean;
  onClick: () => void;
  mappingFrom: string;
  mappingTo: string;
  disabled?: boolean;
  className?: string;
}

const LinkUnlinkButton: React.FC<Props> = ({ isLinked, onClick, className, mappingTo, mappingFrom, disabled }) => {
  const mappingToField = t(`story-editor.link-unlink-button.fields.${mappingTo}`);
  const tooltipId = `${mappingFrom}-${mappingTo}-link-unlink-btn`;
  return (
    <Button type={"transparent"} onClick={onClick} classname={cx(className, "button")} disabled={disabled}>
      <span data-for={tooltipId} data-tip data-test-id={"link-unlink-btn"}>
        {isLinked ? <Link width={22} height={22} /> : <Unlink width={22} height={22} />}
        <Tooltip
          id={tooltipId}
          place="top"
          effect="solid"
          value={`${
            isLinked
              ? t("story-editor.link-unlink-button.linked-to")
              : t("story-editor.link-unlink-button.unlinked-from")
          } ${mappingToField}`}
        />
      </span>
    </Button>
  );
};

export default LinkUnlinkButton;
