/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

export interface Slug {
  slug: string;
}

export const generateSlug = (name: string): Promise<Slug> => {
  return client
    .url(`/api/slugify?slug=${name}`)
    .get()
    .json();
};
