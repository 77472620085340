/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import TextField from "components/text-field/text-field";
import { Integrations, GoogleAnalytics3RealtimeConfig, IntegrationConfig } from "api/integrations";
import TextArea from "components/text-area/text-area";
import { ButtonWithLoader } from "components/button/button";
import { getErrorMessage } from "../validate";
import { Callout } from "@quintype/em/components/callout";
import { useState } from "react";
import styles from "./ga3-realtime.module.css";

interface Props {
  integration: { key: Integrations.GoogleAnalytics3Realtime; value: GoogleAnalytics3RealtimeConfig; isValid: boolean };
  updateIntegrationField: (key: string, value: Object) => void;
  readOnly?: boolean;
  errors: Error | null;
  validateIntegration?: (integration: IntegrationConfig) => void;
  isValidateInProgress?: boolean;
}

const Ga3Realtime: React.FunctionComponent<Props> = ({
  integration,
  updateIntegrationField,
  validateIntegration,
  readOnly,
  isValidateInProgress,
  errors
}) => {
  const integrationName = integration.key;
  const errorDetail = getErrorMessage(integration.key, "message", errors);
  const [isValidateClicked, setIsValidateClicked] = useState<Boolean>(false);

  return (
    <React.Fragment>
      <TextField
        label={t("integrations.analytics.ga3-realtime.client-email")}
        value={integration.value["client-email"]}
        onChange={(value) => updateIntegrationField(integrationName, { "client-email": value })}
        readOnly={readOnly}
        errorMessage={getErrorMessage(integration.key, "client-email", errors)}
      />
      <TextArea
        label={t("integrations.analytics.ga3-realtime.private-key")}
        value={integration.value["private-key"]}
        onChange={(value) => !readOnly && updateIntegrationField(integrationName, { "private-key": value })}
        maxRows={10}
        errorMessage={getErrorMessage(integration.key, "private-key", errors)}
      />
      <TextField
        label={t("integrations.analytics.ga3-realtime.view-id")}
        value={integration.value["view-id"]}
        onChange={(value) => updateIntegrationField(integrationName, { "view-id": value })}
        readOnly={readOnly}
        errorMessage={getErrorMessage(integration.key, "view-id", errors)}
      />
      {validateIntegration && (
        <ButtonWithLoader
          loading={isValidateInProgress}
          onClick={() => {
            setIsValidateClicked(true);
            validateIntegration(integration);
          }}>
          {t("integrations.validate-credentials")}
        </ButtonWithLoader>
      )}
      <div className={styles["validation-message"]}>
        {isValidateClicked && showCallout(integration.isValid, errorDetail)}
      </div>
    </React.Fragment>
  );
};

function showCallout(isIntegrationValid: boolean, errorMessage?: string) {
  if (isIntegrationValid) {
    return (
      <Callout
        title={t("integrations.validation-success-title")}
        content={t("integrations.analytics.ga3-realtime.usage-info")}
        variant="success"
      />
    );
  }
  if (errorMessage) {
    return <Callout title={t("integrations.validation-error-title")} content={errorMessage} variant="danger" />;
  }
  return;
}

export default Ga3Realtime;
