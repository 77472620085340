/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import Spinner from "components/spinner/spinner";
import { TimeoutError } from "api/errors";
import { LoaderState } from "behaviors/loader/state";
import Plus from "components/icons/plus";
import Button from "components/button/button";

interface LoaderProps {
  loader: LoaderState;
  loadAnalytics: () => void;
}

const Loader: React.SFC<LoaderProps> = ({ loader: { loading, error }, loadAnalytics }) => {
  if (loading) {
    return <Spinner message={t("analytics.messages.loading_analytics")} />;
  }
  if (error) {
    if (error instanceof TimeoutError) {
      return (
        <React.Fragment>
          <Plus width="48" height="48" />
          <p>{t("analytics.messages.could_not_load")}</p>
          <p>
            <Button onClick={() => loadAnalytics()}>{t("analytics.load_retry")}</Button>
          </p>
        </React.Fragment>
      );
    }
  } else {
    return <p>{t("analytics.messages.unknown_error")}</p>;
  }
  return null;
};

export default Loader;
