/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Schema, Node, MarkSpec, DOMOutputSpec, Mark } from "prosemirror-model";
import { addListNodes } from "prosemirror-schema-list";

import "./schema.module.css";
import { t } from "i18n";

// @ts-ignore
import OrderedMap from "orderedmap";

const QuoteNodeSpecs = {
  quote_text: {
    content: "inline*",
    atom: true,
    selectable: false,
    parseDOM: [{ tag: "blockquote" }],
    toDOM() {
      return ["blockquote", 0];
    },
    attrs: {
      type: { default: "text" },
      placeholder: { default: "Enter Text" }
    }
  },
  quote_helper: {
    group: "inline*",
    selectable: false,
    parseDOM: [{ tag: "div.quote-helper-label" }],
    toDOM() {
      return ["div", { class: "quote-helper-label", id: "quote-attribution-label" }, "Attribution"];
    }
  },
  quote_attribution: {
    content: "block*",
    selectable: false,
    parseDOM: [{ tag: "span.attribution" }],
    toDOM() {
      return ["span", { class: "attribution" }, 0];
    },
    attrs: {
      id: { default: "" },
      type: { default: "" },
      placeholder: { default: "Enter Attribution" }
    }
  },
  quote: {
    group: "story_element",
    selectable: false,
    isolating: true,
    content: "quote_text quote_helper quote_attribution",
    toDOM() {
      return ["article", { class: "story-element quote" }, 0];
    },
    parseDOM: [{ tag: "article.story-element.quote" }],
    attrs: {
      id: { default: "" },
      type: { default: "text" },
      subtype: { default: "quote" },
      "card-id": { default: "" },
      "story-version-id": { default: "" },
      isCardDisabled: { default: false },
      "client-id": { default: "" }
    }
  }
};

const BlurbNodeSpecs = {
  blurb: {
    group: "story_element",
    content: "quote_text",
    isolating: true,
    selectable: false,
    toDOM() {
      return ["article", { class: "story-element blurb" }, 0];
    },
    parseDOM: [{ tag: "article.story-element.blurb" }],
    attrs: {
      id: { default: "" },
      type: { default: "text" },
      subtype: { default: "blurb" },
      "card-id": { default: "" },
      "story-version-id": { default: "" },
      isCardDisabled: { default: false },
      "client-id": { default: "" }
    }
  }
};
const TitleNodeSpecs = {
  title_text: {
    content: "inline*",
    marks: "",
    selectable: false,
    parseDOM: [{ tag: "div.title-text" }],
    toDOM() {
      return ["div", { class: "title-text" }, 0];
    },
    attrs: {
      type: { default: "text" },
      placeholder: { default: "Title" }
    }
  },
  title: {
    group: "story_element",
    content: "title_text",
    isolating: true,
    selectable: false,
    toDOM() {
      return ["article", { class: "story-element title" }, 0];
    },
    parseDOM: [{ tag: "article.story-element.title" }],
    attrs: {
      id: { default: "" },
      type: { default: "text" },
      subtype: { default: null },
      "card-id": { default: "" },
      "story-version-id": { default: "" },
      isCardDisabled: { default: false },
      "client-id": { default: "" }
    }
  }
};
const BigfactNodeSpecs = {
  bigfact_title: {
    content: "inline*",
    selectable: false,
    parseDOM: [{ tag: "div.bigfact-title" }],
    toDOM() {
      return ["div", { class: "bigfact-title" }, 0];
    },
    attrs: {
      type: { default: "text" },
      placeholder: { default: "Enter Text" }
    }
  },
  bigfact_description: {
    content: "inline*",
    selectable: false,
    parseDOM: [{ tag: "div.bigfact-description" }],
    toDOM() {
      return ["div", { class: "bigfact-description" }, 0];
    },
    attrs: {
      type: { default: "text" },
      placeholder: { default: "Enter Description" }
    }
  },

  bigfact_helper: {
    group: "inline*",
    selectable: false,
    parseDOM: [{ tag: "div.bigfact-helper-label" }],
    toDOM() {
      return ["div", { class: "bigfact-helper-label" }, "Description"];
    }
  },

  bigfact: {
    group: "story_element",
    isolating: true,
    selectable: false,
    content: "bigfact_title bigfact_helper bigfact_description",
    toDOM() {
      return ["div", { class: "story-element bigfact" }, 0];
    },
    parseDOM: [{ tag: "div.story-element.bigfact" }],
    attrs: {
      id: { default: "" },
      type: { default: "text" },
      subtype: { default: "bigfact" },
      "card-id": { default: "" },
      "story-version-id": { default: "" },
      isCardDisabled: { default: false },
      "client-id": { default: "" }
    }
  }
};
const CtaNodeSpecs = {
  cta_title_helper: {
    group: "inline*",
    selectable: false,
    parseDOM: [{ tag: "div.cta-title-helper" }],
    toDOM() {
      return ["div", { class: "cta-title-helper" }, t("story-editor.story-element.cta-title-label")];
    }
  },
  cta_title: {
    content: "inline*",
    selectable: false,
    parseDOM: [{ tag: "div.cta-title" }],
    toDOM() {
      return ["div", { class: "cta-title" }, 0];
    },
    attrs: {
      type: { default: "text" },
      placeholder: { default: "Enter Title Text" }
    }
  },
  cta_url_helper: {
    group: "inline*",
    selectable: false,
    parseDOM: [{ tag: "div.cta-url-label" }],
    toDOM() {
      return ["div", { class: "cta-url-label" }, t("story-editor.story-element.cta-url-label")];
    }
  },
  cta_url: {
    content: "inline*",
    selectable: false,
    parseDOM: [{ tag: "div.cta-url" }],
    toDOM() {
      return ["div", { class: "cta-url" }, 0];
    },
    attrs: {
      type: { default: "text" },
      placeholder: { default: "https://..." }
    }
  },
  cta_target: {
    group: "inline*",
    selectable: false,
    parseDOM: [{ tag: "span.target" }],
    toDOM() {
      return ["span", { class: "target" }, 0];
    },
    attrs: {
      id: { default: "" },
      type: { default: "" },
      helperName: { default: "open-in-new-tab" },
      label: { default: "" }
    }
  },
  cta_no_follow: {
    content: "block*",
    selectable: false,
    parseDOM: [{ tag: "span.no-follow" }],
    toDOM() {
      return ["span", { class: "no-follow" }, 0];
    },
    attrs: {
      id: { default: "" },
      type: { default: "" },
      helperName: { default: "no-follow" },
      label: { default: "" }
    }
  },
  cta_options: {
    content: "cta_target cta_no_follow",
    selectable: false,
    parseDOM: [{ tag: "span.cta-options" }],
    toDOM() {
      return ["span", { class: "cta-options" }, 0];
    },
    attrs: {
      id: { default: "" },
      type: { default: "" },
      label: { default: "" }
    }
  },
  cta: {
    group: "story_element",
    isolating: true,
    selectable: false,
    content: "cta_title_helper cta_title cta_url_helper cta_url cta_options",
    toDOM() {
      return ["div", { class: "story-element cta" }, 0];
    },
    parseDOM: [{ tag: "div.story-element.cta" }],
    attrs: {
      id: { default: "" },
      type: { default: "text" },
      subtype: { default: "cta" },
      "card-id": { default: "" },
      "story-version-id": { default: "" },
      isCardDisabled: { default: false },
      "client-id": { default: "" }
    }
  }
};

const AnswerNodeSpecs = {
  answer_helper: {
    group: "inline*",
    selectable: false,
    parseDOM: [{ tag: "div.answer-helper-label" }],
    toDOM() {
      return ["div", { class: "answer-helper-label" }, "Answer"];
    }
  },
  answer_text: {
    content: "block*",
    selectable: false,
    parseDOM: [{ tag: "div.answer-text" }],
    toDOM() {
      return ["div", { class: "answer-text" }, 0];
    },
    attrs: {
      type: { default: "text" },
      placeholder: { default: "Answer here..." }
    }
  },
  answer_attribution_helper: {
    group: "inline*",
    selectable: false,
    parseDOM: [{ tag: "div.answer-attribution-helper-label" }],
    toDOM() {
      return ["div", { class: "answer-attribution-helper-label" }, "Attribution"];
    }
  },
  answer: {
    group: "story_element",
    isolating: true,
    selectable: false,
    content: "answer_helper answer_text answer_attribution_helper",
    toDOM() {
      return ["div", { class: "story-element answer" }, 0];
    },
    parseDOM: [{ tag: "div.story-element.answer" }],
    attrs: {
      id: { default: "" },
      type: { default: "text" },
      subtype: { default: "answer" },
      "card-id": { default: "" },
      "story-version-id": { default: "" },
      isCardDisabled: { default: false },
      "client-id": { default: "" }
    }
  }
};

const QuestionNodeSpecs = {
  question_helper: {
    group: "inline*",
    selectable: false,
    parseDOM: [{ tag: "div.question-helper-label" }],
    toDOM() {
      return ["div", { class: "question-helper-label" }, "Question"];
    }
  },
  question_text: {
    content: "block*",
    selectable: false,
    parseDOM: [{ tag: "div.question-text" }],
    toDOM() {
      return ["div", { class: "question-text" }, 0];
    },
    attrs: {
      type: { default: "text" },
      placeholder: { default: "Question here..." }
    }
  },
  question_attribution_helper: {
    group: "inline*",
    selectable: false,
    parseDOM: [{ tag: "div.question-attribution-helper-label" }],
    toDOM() {
      return ["div", { class: "question-attribution-helper-label" }, "Attribution"];
    }
  },
  question: {
    group: "story_element",
    isolating: true,
    selectable: false,
    content: "question_helper question_text question_attribution_helper",
    toDOM() {
      return ["div", { class: "story-element question" }, 0];
    },
    parseDOM: [{ tag: "div.story-element.question" }],
    attrs: {
      id: { default: "" },
      type: { default: "text" },
      subtype: { default: "question" },
      "card-id": { default: "" },
      "story-version-id": { default: "" },
      isCardDisabled: { default: false },
      "client-id": { default: "" }
    }
  }
};

const QAndANodeSpecs = {
  q_and_a_question_helper: {
    group: "inline*",
    selectable: false,
    parseDOM: [{ tag: "div.q-and-a-question-helper-label" }],
    toDOM() {
      return ["div", { class: "q-and-a-question-helper-label" }, "Question"];
    }
  },
  q_and_a_question: {
    content: "block*",
    selectable: false,
    parseDOM: [{ tag: "div.q-and-a-question" }],
    toDOM() {
      return ["div", { class: "q-and-a-question" }, 0];
    },
    attrs: {
      type: { default: "text" }
    }
  },
  q_and_a_question_attribution_helper: {
    group: "inline*",
    selectable: false,
    attrs: {
      id: { default: "" },
      helperName: { default: "answer" }
    },
    parseDOM: [{ tag: "div.q-and-a-question-attribution-helper-label" }],
    toDOM() {
      return ["div", { class: "q-and-a-question-attribution-helper-label" }];
    }
  },
  q_and_a_answer_helper: {
    group: "inline*",
    selectable: false,
    parseDOM: [{ tag: "div.q-and-a-answer-helper-label" }],
    toDOM() {
      return ["div", { class: "q-and-a-answer-helper-label" }, "Answer"];
    }
  },
  q_and_a_answer: {
    content: "block*",
    selectable: false,
    parseDOM: [{ tag: "div.q-and-a-answer" }],
    toDOM() {
      return ["div", { class: "q-and-a-answer" }, 0];
    },
    attrs: {
      type: { default: "text" }
    }
  },
  q_and_a_answer_attribution_helper: {
    group: "inline*",
    selectable: false,
    attrs: {
      id: { default: "" },
      helperName: { default: "answer" }
    },
    parseDOM: [{ tag: "div.q-and-a-answer-attribution-helper-label" }],
    toDOM() {
      return ["div", { class: "q-and-a-answer-attribution-helper-label" }];
    }
  },
  q_and_a_element: {
    group: "story_element",
    isolating: true,
    selectable: false,
    content:
      "q_and_a_question_helper q_and_a_question q_and_a_question_attribution_helper q_and_a_answer_helper q_and_a_answer q_and_a_answer_attribution_helper",
    toDOM() {
      return ["article", { class: "story-element question_and_answer" }, 0];
    },
    parseDOM: [{ tag: "article.story-element.question_and_answer" }],
    attrs: {
      id: { default: "" },
      type: { default: "text" },
      "client-id": { default: "" },
      subtype: { default: "q-and-a" },
      "card-id": { default: "" },
      "story-version-id": { default: "" },
      isCardDisabled: { default: false }
    }
  }
};

export const nodes = {
  story: {
    content: "card*"
  },
  card: {
    marks: "",
    content: "story_element*",
    isolating: true,
    attrs: {
      id: { default: "" },
      "content-version-id": { default: "" },
      "story-version-id": { default: "" },
      "client-id": { default: "" },
      isCardDisabled: { default: false }
    },
    parseDOM: [{ tag: "div.card" }],
    toDOM() {
      return ["div", { class: "card" }, 0];
    }
  },
  story_element_text: {
    group: "story_element",
    content: "block*",
    isolating: false,
    attrs: {
      id: { default: "" },
      "client-id": { default: "" },
      type: { default: "text" },
      subtype: { default: null },
      "story-version-id": { default: "" },
      "card-id": { default: "" },
      "card-version-id": { default: undefined },
      "family-id": { default: undefined },
      isCardDisabled: { default: false }
    },
    parseDOM: [{ tag: "div.story-element.story-element-text" }],
    toDOM() {
      return ["div", { class: "story-element story-element-text" }, 0];
    }
  },
  ...TitleNodeSpecs,
  ...QuoteNodeSpecs,
  ...QuestionNodeSpecs,
  ...AnswerNodeSpecs,
  ...QAndANodeSpecs,
  ...BigfactNodeSpecs,
  ...BlurbNodeSpecs,
  ...CtaNodeSpecs,
  hard_break: {
    inline: true,
    group: "inline",
    selectable: false,
    parseDOM: [{ tag: "br" }],
    toDOM() {
      return ["br"];
    }
  },
  paragraph: {
    content: "inline*",
    group: "block",
    parseDOM: [{ tag: "p" }],
    toDOM() {
      return ["p", 0];
    }
  },
  heading: {
    attrs: { level: { default: 2 } },
    content: "inline*",
    group: "block",
    defining: true,
    parseDOM: [
      { tag: "h2", attrs: { level: 2 } },
      { tag: "h3", attrs: { level: 3 } },
      { tag: "h4", attrs: { level: 4 } },
      { tag: "h5", attrs: { level: 5 } },
      { tag: "h6", attrs: { level: 6 } }
    ],
    toDOM(node: Node) {
      return ["h" + node.attrs.level, 0];
    }
  },
  story_element_others: {
    group: "story_element",
    atom: true,
    marks: "",
    selectable: false,
    attrs: {
      id: { default: "" },
      type: { default: "" },
      subtype: { default: null },
      "client-id": { default: "" },
      "card-id": { default: "" },
      "story-version-id": { default: "" },
      isCardDisabled: { default: false }
    },
    parseDOM: [{ tag: "div.story-element" }],
    toDOM() {
      return ["div", { class: "story-element" }];
    }
  },
  text: {
    group: "inline"
  },
  text_area: {
    content: "paragraphs*",
    parseDOM: [{ tag: "div.text_area" }]
  },
  paragraphs: {
    content: "block*",
    parseDOM: [{ tag: "div" }],
    attrs: {
      type: { default: "text" }
    },
    toDOM() {
      return ["div", { class: "paragraphs" }, 0];
    }
  }
};

const emphasisDOM: DOMOutputSpec = ["em", 0],
  strongDOM: DOMOutputSpec = ["strong", 0],
  underlineDOM: DOMOutputSpec = ["ins", 0],
  strikethroughDOM: DOMOutputSpec = ["del", 0],
  superscriptDOM: DOMOutputSpec = ["sup", 0],
  subscriptDOM: DOMOutputSpec = ["sub", 0];

export const marks: { [name in any]: MarkSpec } = {
  // :: MarkSpec An emphasis mark. Rendered as an `<em>` element.
  // Has parse rules that also match `<i>` and `font-style: italic`.
  em: {
    parseDOM: [{ tag: "i" }, { tag: "em" }, { style: "font-style=italic" }],
    toDOM(): DOMOutputSpec {
      return emphasisDOM;
    }
  },

  // :: MarkSpec A strong mark. Rendered as `<strong>`, parse rules
  // also match `<b>` and `font-weight: bold`.
  strong: {
    parseDOM: [
      { tag: "strong" },
      // This works around a Google Docs misbehavior where
      // pasted content will be inexplicably wrapped in `<b>`
      // tags with a font-weight normal.
      { tag: "b", getAttrs: (node: HTMLElement) => node.style.fontWeight !== "normal" && null },
      { style: "font-weight", getAttrs: (value: string) => /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null }
    ],
    toDOM(): DOMOutputSpec {
      return strongDOM;
    }
  },

  underline: {
    parseDOM: [{ tag: "ins" }, { style: "text-decoration=underline" }],
    toDOM(): DOMOutputSpec {
      return underlineDOM;
    }
  },

  strikethrough: {
    parseDOM: [{ tag: "del" }, { style: "text-decoration=line-through" }],
    toDOM(): DOMOutputSpec {
      return strikethroughDOM;
    }
  },

  superscript: {
    parseDOM: [{ tag: "sup" }],
    toDOM(): DOMOutputSpec {
      return superscriptDOM;
    }
  },

  subscript: {
    parseDOM: [{ tag: "sub" }],
    toDOM(): DOMOutputSpec {
      return subscriptDOM;
    }
  },
  entity_link: {
    attrs: {
      href: { default: "" },
      class: { default: "entity-link" },
      "entity-id": { default: "" },
      "entity-name": { default: "" },
      "entity-slug": { default: "" },
      "entity-type-id": { default: "" },
      "entity-type-slug": { default: "" }
    },
    parseDOM: [
      {
        tag: "a.entity-link",
        getAttrs(dom: HTMLAnchorElement) {
          return {
            href: dom.href,
            class: dom.getAttribute("class"),
            "entity-id": dom.getAttribute("entity-id"),
            "entity-name": dom.getAttribute("entity-name"),
            "entity-slug": dom.getAttribute("entity-slug"),
            "entity-type-id": dom.getAttribute("entity-type-id"),
            "entity-type-slug": dom.getAttribute("entity-type-slug")
          };
        }
      }
    ],
    toDOM(node: Mark): DOMOutputSpec {
      return [
        "a",
        {
          href: node.attrs.href,
          class: node.attrs.class,
          "entity-id": node.attrs["entity-id"],
          "entity-name": node.attrs["entity-name"],
          "entity-slug": node.attrs["entity-slug"],
          "entity-type-id": node.attrs["entity-type-id"],
          "entity-type-slug": node.attrs["entity-type-slug"]
        }
      ];
    },
    inclusive: false,
    atom: true
  },
  link: {
    attrs: { href: { default: "" }, rel: { default: "" } },
    parseDOM: [
      {
        tag: "a",
        getAttrs(dom: HTMLAnchorElement) {
          return { href: dom.href, rel: dom.rel };
        }
      }
    ],
    toDOM(node: Mark): DOMOutputSpec {
      return node.attrs.rel
        ? ["a", { href: node.attrs.href, rel: node.attrs.rel }, 0]
        : ["a", { href: node.attrs.href }, 0];
    },

    inclusive: false
  }
};

export const schema = new Schema({
  nodes: addListNodes(OrderedMap.from(nodes), "(paragraph | heading) block*", "block"),
  marks,
  topNode: "story"
});

export const paragraphSchema = new Schema({
  nodes: addListNodes(
    OrderedMap.from({
      paragraphs: {
        content: "block*",
        parseDOM: [{ tag: "div" }]
      },
      paragraph: nodes.paragraph,
      heading: nodes.heading,
      hard_break: nodes.hard_break,
      text: nodes.text
    }),
    "paragraph block*",
    "block"
  ),
  marks,
  topNode: "paragraphs"
});

export const textAreaSchema = new Schema({
  nodes: addListNodes(
    OrderedMap.from({
      text_area: nodes.text_area,
      paragraphs: nodes.paragraphs,
      paragraph: nodes.paragraph,
      heading: nodes.heading,
      hard_break: nodes.hard_break,
      text: nodes.text
    }),
    "paragraph block*",
    "block"
  ),
  marks,
  topNode: "text_area"
});
