/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose, AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { PartialAppState } from "../../state";
import { ContextMenu, ContextMenuList, ContextMenuItem } from "components/context-menu/context-menu";
import Button from "components/button/button";
import {
  saveCurrentStaticPageAction,
  publishCurrentStaticPageAction,
  retractCurrentStaticPageAction
} from "../../async-action-creators";
import { StaticPage, UnSavedStaticPage, StaticPageStatus } from "api/static-pages";
import Header from "components/header/header";

import styles from "./header.module.css";
import { openDeleteConfirmation } from "../../action-creators";
import { t } from "i18n";

interface DispatchProps {
  saveCurrentPage: Function;
  publishCurrentPage: Function;
  retractCurrentPage: Function;
  deleteCurrentPage: Function;
}

interface StateProps {
  currentStaticPage: StaticPage | UnSavedStaticPage | null;
}

type headerProps = StateProps & DispatchProps;

export class StaticPagesHeader extends React.Component<headerProps> {
  render() {
    const {
      saveCurrentPage,
      publishCurrentPage,
      currentStaticPage,
      retractCurrentPage,
      deleteCurrentPage
    } = this.props;
    return (
      <Header>
        {currentStaticPage && (
          <div className={styles["static-page-header-actions"]}>
            <Button
              type="primary"
              variant="success"
              onClick={() => saveCurrentPage()}
              classname="static-page-header-actions-button">
              {t("staticPages.header.save")}
            </Button>

            {"status" in currentStaticPage && (
              <React.Fragment>
                {(currentStaticPage.status === StaticPageStatus.Draft ||
                  currentStaticPage.status === StaticPageStatus.Retract) && (
                  <Button
                    type="primary"
                    variant="success"
                    onClick={() => publishCurrentPage()}
                    classname="static-page-header-actions-button">
                    {t("staticPages.header.publish")}
                  </Button>
                )}
                {currentStaticPage.hasOwnProperty("id") && (
                  <React.Fragment>
                    <ContextMenu>
                      <ContextMenuList>
                        <ContextMenuItem label={t("staticPages.header.delete")} onClick={() => deleteCurrentPage()} />
                        {currentStaticPage && currentStaticPage.status === StaticPageStatus.Publish && (
                          <ContextMenuItem
                            label={t("staticPages.header.retract")}
                            onClick={() => retractCurrentPage()}
                          />
                        )}
                      </ContextMenuList>
                    </ContextMenu>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        )}
      </Header>
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    currentStaticPage: state.staticPages.app.currentStaticPage
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    saveCurrentPage: () => dispatch(saveCurrentStaticPageAction()),
    publishCurrentPage: () => dispatch(publishCurrentStaticPageAction()),
    retractCurrentPage: () => dispatch(retractCurrentStaticPageAction()),
    deleteCurrentPage: () => dispatch(openDeleteConfirmation())
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(StaticPagesHeader);
