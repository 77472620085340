/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect, useRef } from "react";
import styles from "./slider-window.module.css";
import classnames from "classnames/bind";
import { t } from "i18n";
import Chevron from "../icons/chevron";
import { WindowSetSliderWindowOpen } from "containers/app-layout/app-layout";
import { get } from "lodash";

const cx = classnames.bind(styles);

type Props = {
  url: string;
  onSliderToggleFn: () => void;
  isSliderOpen: boolean;
  showUpdatesIndicator: boolean;
};

const SliderWindow: React.FC<Props> = ({ url, onSliderToggleFn, isSliderOpen, showUpdatesIndicator }: Props) => {
  const sliderIframeRef = useRef<HTMLIFrameElement | null>(null);

  // Update slider open/close status in slider window
  useEffect(() => {
    if (sliderIframeRef.current) {
      const setSliderWindowOpen: WindowSetSliderWindowOpen = get(sliderIframeRef.current, [
        "contentWindow",
        "setSliderWindowOpen"
      ]);
      if (setSliderWindowOpen) {
        setSliderWindowOpen(isSliderOpen);
      }
    }
  }, [isSliderOpen]);

  return (
    <div className={cx("slider-window")}>
      <div className={cx("slider-seperator")}></div>
      <div className={cx("toggle")} onClick={onSliderToggleFn} data-test-id={"slider-toggle"}>
        <div className={cx("toggle__content", { "toggle__content--updates-indicator": showUpdatesIndicator })}>
          <span className={cx("toggle__arrow", { "toggle__arrow--open": isSliderOpen })}>
            <Chevron fill="var(--white)" width={20} height={20} variant="left" />
          </span>
          <p>{isSliderOpen ? t("common.hide") : t("common.show")}</p>
        </div>
      </div>
      <iframe ref={sliderIframeRef} className={cx("iframe-content")} title={"slider-window"} src={url} />
    </div>
  );
};

export default SliderWindow;
