/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Select from "components/select/select";
import Breadcrumbs from "components/breadcrumbs/breadcrumbs";
import { t } from "i18n";

const SectionFilter = ({ sections, value, onChange }: any) => (
  <Select
    label={t("workspace.filters_inspector.section")}
    name="sections"
    value={value}
    options={sections}
    onChange={(sections) => onChange(sections)}
    getOptionLabel={(section) => section.name}
    getOptionValue={(section) => section.id}
    formatOptionLabel={(section) => (
      <React.Fragment>
        {section.name}
        <Breadcrumbs id={section.id} crumbs={sections} getCrumbLabel={(section: any) => section.name} />
      </React.Fragment>
    )}
    isMulti={true}
  />
);

export default SectionFilter;
