/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { t } from "i18n";
import get from "lodash/get";
import { Image, ImageMetadata as ImageMetadataType } from "api/search-media-image";
import { ImageError } from "../../state";
import { getFileName } from "../../image-utils";
import TextFieldWithCopy from "components/text-field/text-field-with-copy";
import Checkbox from "components/checkbox/checkbox";
import { searchAuthors, Author } from "api/author";
import debounce from "p-debounce";
import AsyncSelect from "components/select/async";
import Location from "components/location/location";
import classnames from "classnames/bind";
import styles from "./image-details.module.css";
import ImageMetadata from "../image-metadata/image-metadata";
import { ImageLocation } from "utils/image-metadata.utils";
import RichTextImageField from "components/rich-text-field/rich-text-field";
import TextArea from "components/text-area/text-area";
import { TextField } from "@quintype/em/components/text-field";

const cx = classnames.bind(styles);
const loadAuthorsData = debounce(searchAuthors, 250);

interface ImageDetailsProps {
  image: Image;
  imageIndex: number;
  isEditable?: boolean;
  updateImageData: (
    key: string,
    value: string | number | boolean | { location: ImageLocation } | ImageMetadataType | null,
    index: number
  ) => void;
  imageUploadFailed: boolean;
  isLinkAuthorEnabled: boolean;
  enableEXIFLocation?: boolean;
  error?: ImageError;
  isStoryImageHyperlinkEnabled: boolean;
}

const ImageDetails: React.SFC<ImageDetailsProps> = ({
  image,
  imageIndex,
  updateImageData,
  imageUploadFailed,
  isLinkAuthorEnabled,
  enableEXIFLocation,
  error,
  isStoryImageHyperlinkEnabled
}) => {
  const showAuthorSelect = isLinkAuthorEnabled && (!!image.linkAuthor || !!image["creator-id"]);
  const toggleAuthorLink = (value: boolean, pos: number) => {
    updateImageData("linkAuthor", value, pos);
    updateImageData("creator-id", null, pos);
    value && updateImageData("attribution", null, pos);
  };
  const selectedAuthor: Author = { name: image.attribution || "", id: image["creator-id"] || 0 };

  const getLocationString = (image: Image) => {
    const lat = get(image, ["extracted-data", "location", "lat"]);
    const lon = get(image, ["extracted-data", "location", "lon"]);
    if (lat && lon) {
      return `${lat}, ${lon}`;
    } else {
      return "";
    }
  };

  return (
    <React.Fragment>
      {!imageUploadFailed && (
        <div className={styles["image-details-container"]}>
          {image["temp-image-key"] && (
            <TextField
              onChange={(value) => updateImageData("metadata", { ...image.metadata, "file-name": value }, imageIndex)}
              value={image.metadata["file-name"] || ""}
              label={t("mediaLibrary.file_name")}
              hint={t("mediaLibrary.file_name_hint")}
              errorMessage={error && error.fileName ? error.message : ""}
            />
          )}

          <RichTextImageField
            onChange={(value) => updateImageData("caption", value, imageIndex)}
            value={image.caption || ""}
            label={t("mediaLibrary.caption")}
            errorMessage={error && error.caption ? t("mediaLibrary.missing_caption") : ""}
          />

          {!showAuthorSelect && (
            <RichTextImageField
              value={image.attribution || ""}
              label={t("mediaLibrary.attribution")}
              onChange={(value) => updateImageData("attribution", value, imageIndex)}
              errorMessage={error && error.attribution ? t("mediaLibrary.missing_caption") : ""}
            />
          )}
          {showAuthorSelect && (
            <AsyncSelect
              value={selectedAuthor}
              onChange={(author: Author) => {
                updateImageData("attribution", author.name, imageIndex);
                updateImageData("creator-id", author.id, imageIndex);
              }}
              getOptionLabel={(author: Author) => author.name}
              getOptionValue={(author: Author) => String(author.id)}
              loadOptions={loadAuthorsData}
              isMulti={false}
              label={t("mediaLibrary.attribution")}
              defaultOptions={true}
            />
          )}
          {isLinkAuthorEnabled && (
            <div className={cx("link-author-checkbox", classnames)} data-test-id="link-author-checkbox">
              <Checkbox
                onChange={(value: boolean) => {
                  toggleAuthorLink(value, imageIndex);
                }}
                label={t("mediaLibrary.link_user_to_media")}
                id={`link-author-checkbox-${imageIndex}`}
                checked={showAuthorSelect}
              />
            </div>
          )}
          <TextArea
            onChange={(value) => updateImageData("alt-text", value, imageIndex)}
            value={image["alt-text"] || ""}
            label={t("mediaLibrary.alt-text")}
            errorMessage={error && error["alt-text"] ? t("mediaLibrary.missing_caption") : ""}
          />
          {isStoryImageHyperlinkEnabled && (
            <TextArea
              onChange={(value) => updateImageData("hyperlink", value, imageIndex)}
              value={image.hyperlink || ""}
              label={t("mediaLibrary.hyperlink")}
              errorMessage={error && error.hyperlink ? t("mediaLibrary.invalid_hyperlink") : ""}
            />
          )}
          {enableEXIFLocation && (
            <Location
              className="attribute-location-container"
              latitude={get(image, ["extracted-data", "location", "lat"])}
              longitude={get(image, ["extracted-data", "location", "lon"])}
              onChange={(value) => {
                updateImageData("extracted-data", { ...image["extracted-data"], location: value }, imageIndex);
              }}
              searchBoxLabel={t("common.location")}
              selectedPlace={getLocationString(image)}
            />
          )}
          {image.metadata && (
            <ImageMetadata
              imageMetadata={image.metadata}
              imageFileName={getFileName(image)}
              imageUploadedAt={image["uploaded-at"]}
            />
          )}
          <TextFieldWithCopy value={image.url} label={t("mediaLibrary.file_url")} />
        </div>
      )}
    </React.Fragment>
  );
};

export default ImageDetails;
