/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { pick, startCase } from "lodash";
import { batch, connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction, createSelector } from "@reduxjs/toolkit";
import { Transformers } from "utils";
import {
  clearActiveFilters,
  fetchCollections,
  Filters,
  PartialAppState,
  removeActiveFilter,
  resetFilters
} from "store/collection/collections-dashboard";
import ActiveFilters from "components/active-filters/active-filters";
import styles from "./active-filters.module.css";

interface StateProps {
  filters: Partial<Filters>;
  isDesktopSizeViewport: boolean;
}

interface DispatchProps {
  resetFilter: (filterKey) => void;
  clearAllFilters: () => void;
}

export const transformers: Transformers<Filters> = {
  templates: (key, value) => [startCase(key), value && value.map((template) => startCase(template.label)).join(", ")]
};

const CollectionDashboardActiveFilters: React.FC<StateProps & DispatchProps> = ({
  isDesktopSizeViewport,
  filters,
  resetFilter,
  clearAllFilters
}) => {
  return isDesktopSizeViewport ? (
    <ActiveFilters
      filters={filters}
      resetFilter={resetFilter}
      clearAllFilters={clearAllFilters}
      transformers={transformers}
      classname={styles["active-filters"]}
    />
  ) : null;
};

const selectActiveFilters = createSelector(
  [
    (state: PartialAppState) => state.collectionsDashboard.filters,
    (state: PartialAppState) => state.collectionsDashboard.ui.activeFilters
  ],
  (filters, activeFilters) => pick(filters, activeFilters)
);

function mapStateToProps(state: PartialAppState): StateProps {
  return {
    isDesktopSizeViewport: state.viewport.isDesktopSizeViewport,
    filters: selectActiveFilters(state)
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps {
  return {
    resetFilter: (filterKey) => {
      batch(() => {
        dispatch(resetFilters([filterKey]));
        dispatch(removeActiveFilter(filterKey));
      });
      dispatch(fetchCollections({ shouldClearCollections: true, showLoader: true }));
    },
    clearAllFilters: () => {
      dispatch(clearActiveFilters());
      dispatch(fetchCollections({ shouldClearCollections: true, showLoader: true }));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionDashboardActiveFilters);
