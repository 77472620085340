/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Dialog as EmDialog } from "@quintype/em/components/dialog";

interface DialogProps {
  // Value to show/hide the dialog
  isOpen: boolean;

  // Callback function, called when dialog is closed
  onClose: () => void;

  //Callback function, when the action button is clicked
  onAction?: () => void;

  actionButtonLabel?: string;

  children: JSX.Element | JSX.Element[];
}

const Dialog = (props: DialogProps) => <EmDialog {...props} />;

export default Dialog;
