/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { CollectionEntityType } from "api/collection-config";
import { Features } from "api/route-data/route-data";

export interface PartialAppState {
  collectionsConfig: State;
  config: { publisherWideBannerMessage: string };
  features: Features;
}

export type State = {
  allEntityTypes: CollectionEntityType[];
  enabledEntityTypes?: CollectionEntityType[];
  isCollectionConfigModified: boolean;
  storyLimit?: number | null;
};

export const INITIAL_COLLECTIONS_CONFIG_STATE: State = {
  allEntityTypes: [],
  enabledEntityTypes: [],
  isCollectionConfigModified: false,
  storyLimit: 0
};
