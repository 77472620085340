/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as actions from "./actions";
import { removeKeyFromObject } from "utils";
import { INITIAL_LOADER_STATE } from "behaviors/loader/state";

const initialState = {
  entitiesPage: {
    entities: [],
    pagination: {
      total: 0,
      offset: 0,
      limit: 20
    },
    entitiesPerPage: 20,
    selectedEntity: null,
    selectedEntityImages: []
  },
  selectedEntityData: null,
  entityTemplateOptions: null,
  ui: {
    showInspector: false,
    searchTerm: "",
    currentPage: 1,
    errors: {},
    main: INITIAL_LOADER_STATE,
    imageUploading: false
  }
};

const updateSelectedEntityImages = (selectedImages, payload) => {
  const { key, value, imageIndex } = payload;

  return selectedImages.map((item, index) => {
    if (index !== imageIndex) {
      return item;
    }
    return {
      ...item,
      [key]: value
    };
  });
};

const entitiesReducer = (state: any = initialState, action) => {
  switch (action.type) {
    case actions.ENTITIES_LOADING: {
      return {
        ...state,
        ui: {
          ...state.ui,
          main: { ...state.ui.main, loading: true }
        }
      };
    }
    case actions.ENTITIES_SET_DATA: {
      const { entities, pagination } = action.payload.data;

      return {
        ...state,
        entitiesPage: { ...state.entitiesPage, entities, pagination },
        ui: {
          ...state.ui,
          main: { ...state.ui.main, loading: false }
        }
      };
    }

    case actions.ENTITIES_UPDATE_PAGINATION: {
      return {
        ...state,
        entitiesPage: {
          ...state.entitiesPage,
          pagination: { ...state.entitiesPage.pagination, offset: action.payload.offset }
        }
      };
    }
    case actions.ENTITIES_UPDATE_CURRENT_PAGE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          currentPage: action.payload.pageNumber
        }
      };
    }
    case actions.ENTITIES_UPDATE_SEARCH_TERM: {
      return {
        ...state,
        ui: {
          ...state.ui,
          searchTerm: action.payload.searchTerm
        }
      };
    }
    case actions.ENTITIES_SET_SELECTED_ENTITY: {
      return {
        ...state,
        entitiesPage: {
          ...state.entitiesPage,
          selectedEntity: action.payload.entityType
        },
        ui: {
          ...state.ui,
          errors: {}
        }
      };
    }
    case actions.ENTITIES_UPDATE_SELECTED_ENTITY: {
      const { key, value } = action.payload;

      return {
        ...state,
        selectedEntityData: {
          ...state.selectedEntityData,
          [key]: value
        }
      };
    }
    case actions.ENTITIES_SET_SELECTED_TEMPLATE_OPTIONS: {
      const { entityProperty, data } = action.payload;
      return {
        ...state,
        entityTemplateOptions: {
          ...state.entityTemplateOptions,
          [entityProperty]: data.entities
        }
      };
    }
    case actions.ENTITIES_RESET_SELECTED_ENTITY: {
      return {
        ...state,
        selectedEntityData: null,
        entityTemplateOptions: null,
        entitiesPage: {
          ...state.entitiesPage,
          selectedEntity: null
        }
      };
    }

    case actions.ENTITIES_SET_FIELD_EMPTY_ERROR: {
      return {
        ...state,
        ui: {
          ...state.ui,
          errors: {
            ...state.ui.errors,
            [action.payload.field]: true
          }
        }
      };
    }

    case actions.ENTITIES_REMOVE_FIELD_ERROR: {
      return {
        ...state,
        ui: {
          ...state.ui,
          errors: removeKeyFromObject(action.payload.field, state.ui.errors)
        }
      };
    }

    case actions.ENTITIES_SET_SELECTED_ENTITY_IMAGES: {
      return {
        ...state,
        entitiesPage: {
          ...state.entitiesPage,
          selectedEntityImages: action.payload.images
        }
      };
    }

    case actions.ENTITIES_UPDATE_SELECTED_ENTITY_IMAGES_DATA: {
      return {
        ...state,
        entitiesPage: {
          ...state.entitiesPage,
          selectedEntityImages: updateSelectedEntityImages(state.entitiesPage.selectedEntityImages, action.payload)
        }
      };
    }

    case actions.ENTITIES_UPDATE_SELECTED_ENTITY_IMAGES_UPLOAD_STATUS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          imageUploading: action.payload.status.uploading
        }
      };
    }

    case actions.ENTITIES_IMAGE_UPLOAD_KEY: {
      return {
        ...state,
        ui: {
          ...state.ui,
          currentImageUploadKey: action.payload
        }
      };
    }

    case actions.ENTITIES_UPDATE_FIELD_IMAGE_ERROR: {
      return {
        ...state,
        ui: {
          ...state.ui,
          errors: {
            ...state.ui.errors,
            image: action.payload.errorFields
          }
        }
      };
    }

    default:
      return state;
  }
};

export default entitiesReducer;
