/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { MenuGroupList, UnsavedMenuGroup, MenuGroup } from "api/menu-group";
import { MenuItem, AnyMenuItem, MenuItemMoveDirection, UnsavedMenuItem } from "api/menu-item";

enum actions {
  LOAD_MENU = "@@menu/LOAD_MENU",
  LOAD_MENU_SUCCESS = "@@menu/LOAD_MENU_SUCCESS",
  LOAD_MENU_FAILURE = "@@menu/LOAD_MENU_FAILURE",

  INIT_NEW_MENU = "@@menu/INIT_NEW_MENU",
  UPDATE_MENU = "@@menu/UPDATE_MENU",
  CANCEL_CREATE_MENU = "@@menu/CANCEL_CREATE_MENU",

  SAVE_MENU_SUCCESS = "@@menu/SAVE_MENU_SUCCESS",
  SAVE_MENU = "@@menu/SAVE_MENU",
  SAVE_MENU_FAILURE = "@@menu/SAVE_MENU_FAILURE",

  SELECT_MENU = "@@menu/SELECT_MENU",

  LIST_MENU_ITEMS = "@@menu/LIST_MENU_ITEMS",
  LIST_MENU_ITEMS_SUCCESS = "@@menu/LIST_MENU_ITEMS_SUCCESS",
  LIST_MENU_ITEMS_FAILURE = "@@menu/LIST_MENU_ITEMS_FAILURE",

  REPLACE_CURRENT_MENU_ITEM = "@@menu/REPLACE_CURRENT_MENU_ITEM",

  INIT_NEW_MENU_ITEM = "@@menu/INIT_NEW_MENU_ITEM",
  EDIT_MENU_ITEM = "@@menu/EDIT_MENU_ITEM",
  EDIT_MENU_ITEM_FAILURE = "@@menu/EDIT_MENU_ITEM_FAILURE",
  CANCEL_CREATE_OR_EDIT_MENU_ITEM = "@@menu/CANCEL_CREATE_OR_EDIT_MENU_ITEM",

  SAVE_MENU_ITEM = "@@menu/SAVE_MENU_ITEM",
  SAVE_MENU_ITEM_SUCCESS = "@@menu/SAVE_MENU_ITEM_SUCCESS",
  SAVE_MENU_ITEM_FAILURE = "@@menu/SAVE_MENU_ITEM_FAILURE",

  DELETE_MENU_ITEM = "@@menu/DELETE_MENU_ITEM",
  DELETE_MENU_ITEM_SUCCESS = "@@menu/DELETE_MENU_ITEM_SUCCESS",
  DELETE_MENU_ITEM_FAILURE = "@@menu/DELETE_MENU_ITEM_FAILURE",

  MOVE_MENU_ITEM = "@@menu/MOVE_MENU_ITEM",
  MOVE_MENU_ITEM_SUCCESS = "@@menu/MOVE_MENU_ITEM_SUCCESS",
  MOVE_MENU_ITEM_FAILURE = "@@menu/MOVE_MENU_ITEM_FAILURE",

  TOGGLE_DELETE_CONFIRMATION = "@@menu/TOGGLE_DELETE_CONFIRMATION",
  RESET_MENU = "@@menu/RESET_MENU",
  SET_GENERATED_SLUG = "@@sections/SET_GENERATED_SLUG"
}

export type LoadMenuAction = { type: actions.LOAD_MENU };
export type LoadMenuSuccessAction = { type: actions.LOAD_MENU_SUCCESS; payload: { data: MenuGroupList } };
export type LoadMenuFailureAction = { type: actions.LOAD_MENU_FAILURE; payload: { error: Error } };

export type InitNewMenuAction = { type: actions.INIT_NEW_MENU };
export type UpdateMenuAction = { type: actions.UPDATE_MENU; payload: { data: UnsavedMenuGroup } };
export type CancelCreateMenuAction = { type: actions.CANCEL_CREATE_MENU };

export type SaveMenuAction = { type: actions.SAVE_MENU };
export type SaveMenuSuccessAction = { type: actions.SAVE_MENU_SUCCESS };
export type SaveMenuFailureAction = { type: actions.SAVE_MENU_FAILURE; payload: { error: Error } };

export type SelectMenuAction = { type: actions.SELECT_MENU; payload: { menu: MenuGroup } };

export type ListMenuItemsAction = { type: actions.LIST_MENU_ITEMS };
export type ListMenuItemsSuccessAction = { type: actions.LIST_MENU_ITEMS_SUCCESS; payload: { menuItems: MenuItem[] } };
export type ListMenuItemsFailureAction = { type: actions.LIST_MENU_ITEMS_FAILURE; payload: { error: Error } };

export type ReplaceCurrentMenuItemAction = {
  type: actions.REPLACE_CURRENT_MENU_ITEM;
  payload: { menuItem: AnyMenuItem | null };
};
export type InitNewMenuItemAction = { type: actions.INIT_NEW_MENU_ITEM; payload: { menuItem: UnsavedMenuItem } };
export type EditMenuItemAction = { type: actions.EDIT_MENU_ITEM; payload: { menuItem: MenuItem } };
export type EditMenuItemFailureAction = { type: actions.EDIT_MENU_ITEM_FAILURE; payload: { error: Error } };
export type CancelCreateOrEditMenuItemAction = { type: actions.CANCEL_CREATE_OR_EDIT_MENU_ITEM };

export type SaveMenuItemAction = { type: actions.SAVE_MENU_ITEM; payload: { menuItem: AnyMenuItem } };
export type SaveMenuItemSuccessAction = { type: actions.SAVE_MENU_ITEM_SUCCESS; payload: { menuItem: MenuItem } };
export type SaveMenuItemFailureAction = {
  type: actions.SAVE_MENU_ITEM_FAILURE;
  payload: { menuItem: AnyMenuItem; error: Error };
};

export type DeleteMenuItemAction = { type: actions.DELETE_MENU_ITEM; payload: { menuItem: MenuItem } };
export type DeleteMenuItemSuccessAction = { type: actions.DELETE_MENU_ITEM_SUCCESS; payload: { menuItem: MenuItem } };
export type DeleteMenuItemFailureAction = {
  type: actions.DELETE_MENU_ITEM_FAILURE;
  payload: { menuItem: MenuItem; error: Error };
};

export type MoveMenuItemAction = {
  type: actions.MOVE_MENU_ITEM;
  payload: { menuItem: MenuItem; direction: MenuItemMoveDirection };
};
export type MoveMenuItemSuccessAction = {
  type: actions.MOVE_MENU_ITEM_SUCCESS;
  payload: { menuItem: MenuItem; direction: MenuItemMoveDirection };
};
export type MoveMenuItemFailureAction = {
  type: actions.MOVE_MENU_ITEM_FAILURE;
  payload: { menuItem: MenuItem; direction: MenuItemMoveDirection; error: Error };
};
export type ToggleDeleteConfirmation = {
  type: actions.TOGGLE_DELETE_CONFIRMATION;
  payload: MenuItem | null;
};

export type SetGeneratedSlugAction = { type: actions.SET_GENERATED_SLUG; payload: { menuGroup: UnsavedMenuGroup } };

export type ResetMenuAction = {
  type: actions.RESET_MENU;
};

export type ActionTypes =
  | LoadMenuAction
  | LoadMenuSuccessAction
  | LoadMenuFailureAction
  | InitNewMenuAction
  | UpdateMenuAction
  | CancelCreateMenuAction
  | SaveMenuAction
  | SaveMenuSuccessAction
  | SaveMenuFailureAction
  | SelectMenuAction
  | ListMenuItemsAction
  | ListMenuItemsSuccessAction
  | ListMenuItemsFailureAction
  | ReplaceCurrentMenuItemAction
  | InitNewMenuItemAction
  | EditMenuItemAction
  | EditMenuItemFailureAction
  | CancelCreateOrEditMenuItemAction
  | SaveMenuItemAction
  | SaveMenuItemSuccessAction
  | SaveMenuItemFailureAction
  | DeleteMenuItemAction
  | DeleteMenuItemSuccessAction
  | DeleteMenuItemFailureAction
  | MoveMenuItemAction
  | MoveMenuItemSuccessAction
  | MoveMenuItemFailureAction
  | ToggleDeleteConfirmation
  | ResetMenuAction
  | SetGeneratedSlugAction;

export default actions;
