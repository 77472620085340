/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Node } from "prosemirror-model";
import { addIfNotExists } from "utils/array.utils";
import { findLastCardNP } from "../find";
import { newCardNode } from "../nodes";
import { schema } from "../../prosemirror/schema";
import { AnyStory, Card, StoryElement, Tree, StoryTemplate, CompositeStoryElement, ChildStoryElement } from "api/story";
import { EditorState } from "prosemirror-state";
import { setTextSelectionToDocumentEnd } from "../selection";

function appendCardToStory(
  story: AnyStory,
  card: Card,
  storyElements: Array<StoryElement | CompositeStoryElement | ChildStoryElement>
): AnyStory {
  const { tree, id, metadata, ...cardDetails } = card;

  return {
    ...story,
    cards: { ...story.cards, [card["content-id"]]: card },
    tree: story.tree.concat(cardDetails),
    "updated-cards": addIfNotExists(story["updated-cards"], card["content-id"]),
    "story-elements": storyElements.reduce((acc, storyElement) => {
      return {
        ...acc,
        [storyElement.id]: storyElement
      };
    }, story["story-elements"])
  };
}

function appendCardToEditorState(
  editorState: EditorState,
  card: Card,
  storyElements: Array<StoryElement | CompositeStoryElement | ChildStoryElement>
): EditorState {
  const cleanedStoryElements = storyElements.filter((ele) => !ele.hasOwnProperty("composite-element-id"));

  // Append card and apply transaction
  const tr = editorState.tr,
    np = findLastCardNP(editorState),
    cardNode = Node.fromJSON(schema, newCardNode(card, cleanedStoryElements)),
    insertAtPos = np ? np.pos + np.node.nodeSize : 0;
  tr.insert(insertAtPos, cardNode);
  const updatedEditorState = editorState.apply(tr);

  // Set cursor to document end
  const trWithFocus = setTextSelectionToDocumentEnd(updatedEditorState.tr, updatedEditorState);
  return updatedEditorState.apply(trWithFocus);
}

function appendCardToLiveBlog(
  story: AnyStory,
  card: Card,
  storyElements: Array<StoryElement | CompositeStoryElement | ChildStoryElement>
): AnyStory {
  const { tree, id, metadata, ...cardDetails } = card;

  const newTree: Tree[] = story.tree.slice();
  newTree.splice(0, 0, cardDetails);

  return {
    ...story,
    cards: { ...story.cards, [card["content-id"]]: card },
    tree: newTree,
    "updated-cards": addIfNotExists(story["updated-cards"], card["content-id"]),
    "story-elements": storyElements.reduce((acc, storyElement) => {
      return {
        ...acc,
        [storyElement.id]: storyElement
      };
    }, story["story-elements"])
  };
}

function appendCardToLiveBlogEditorState(
  editorState: EditorState,
  card: Card,
  storyElements: Array<StoryElement | CompositeStoryElement | ChildStoryElement>
): EditorState {
  const tr = editorState.tr,
    cardNode = Node.fromJSON(schema, newCardNode(card, storyElements)),
    insertAtPos = 0;

  // Append card and apply transaction
  tr.insert(insertAtPos, cardNode);
  const updatedEditorState = editorState.apply(tr);

  // Set cursor to document end
  const trWithFocus = setTextSelectionToDocumentEnd(updatedEditorState.tr, updatedEditorState);
  return updatedEditorState.apply(trWithFocus);
}

function appendCard(
  story: AnyStory,
  editorState: EditorState,
  card: Card,
  storyElements: Array<StoryElement | CompositeStoryElement | ChildStoryElement> = []
): {
  story: AnyStory;
  editorState: EditorState;
} {
  const newStory =
      story["story-template"] === StoryTemplate.LiveBlog
        ? appendCardToLiveBlog(story, card, storyElements)
        : appendCardToStory(story, card, storyElements),
    newEditorState =
      story["story-template"] === StoryTemplate.LiveBlog
        ? appendCardToLiveBlogEditorState(editorState, card, storyElements)
        : appendCardToEditorState(editorState, card, storyElements);

  return { story: newStory, editorState: newEditorState };
}

export default appendCard;
