/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import TextArea from "components/text-area/text-area";
import { t } from "i18n";

interface SlugPreviewProps {
  value: string;
}

const SlugPreview = ({ value = "" }: SlugPreviewProps) => (
  <TextArea
    label={t("story-editor.inspector.slug-preview")}
    value={value}
    onChange={() => {}}
    disabled={true}
    hint={t("story-editor.inspector.slug-preview-msg")}
  />
);

export default SlugPreview;
