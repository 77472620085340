/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { findCardNP } from "../find";
import { setTextSelectionToDocumentEnd } from "../selection";
import { AnyStory, Card, Story } from "api/story";
import { EditorState } from "prosemirror-state";
import { getNewChunkedEditorState } from "pages/story-editor/utils";
import { currentCardLoading } from "pages/story-editor/state";
import { CardId } from "api/primitive-types";

export enum Direction {
  UP = "UP",
  DOWN = "DOWN"
}

function move(arr: Array<any>, from: number, to: number): Array<any> {
  let array = arr.slice();
  if (to === from || to >= array.length || to < 0) return array;

  let target = array[from];
  let increment = to < from ? -1 : 1;

  for (let k = from; k !== to; k += increment) {
    array[k] = array[k + increment];
  }
  array[to] = target;
  return array;
}

function moveCardStory(story: AnyStory, card: Card, direction: Direction) {
  const currentCardIndex = story.tree.findIndex((treeCard) => treeCard["content-id"] === card["content-id"]);
  const moveCardToIndex = direction === "DOWN" ? currentCardIndex + 1 : currentCardIndex - 1;
  return {
    ...story,
    tree: move(story.tree, currentCardIndex, moveCardToIndex)
  };
}

function moveCardEditorState(editorState: EditorState, card: Card, direction: Direction) {
  const tr = editorState.tr;
  const cardNP = findCardNP(editorState, card);
  let trWithFocus;
  if (cardNP) {
    const cardNode = cardNP.node;
    const nextCardNP = tr.doc.childAfter(cardNP.pos + cardNode.nodeSize);
    const prevCardNP = tr.doc.childBefore(cardNP.pos);

    if (direction === "DOWN" && nextCardNP.node) {
      tr.insert(nextCardNP.offset + nextCardNP.node.nodeSize, cardNode);
      tr.delete(cardNP.pos, cardNP.pos + cardNode.nodeSize);
      trWithFocus = setTextSelectionToDocumentEnd(tr, editorState);
    }

    if (direction === "UP" && prevCardNP.node) {
      tr.delete(cardNP.pos, cardNP.pos + cardNode.nodeSize);
      tr.insert(prevCardNP.offset, cardNode);
      trWithFocus = setTextSelectionToDocumentEnd(tr, editorState);
    }
  }
  // Apply transaction only when the action is done
  return trWithFocus ? editorState.apply(trWithFocus) : editorState;
}

function moveCard(
  story: AnyStory,
  editorState: EditorState,
  card: Card,
  direction: Direction,
  uiOpts: {
    cardsLoaded: CardId[];
    currentCardLoading: currentCardLoading;
  }
) {
  const { cardsLoaded, currentCardLoading: currentCard } = uiOpts;
  const newStory = moveCardStory(story, card, direction);
  editorState = getNewChunkedEditorState(story as Story, cardsLoaded, currentCard);
  const newEditorState = moveCardEditorState(editorState, card, direction);
  return { story: newStory, editorState: newEditorState };
}

export default moveCard;
