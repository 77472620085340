/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { MenuGroupList, UnsavedMenuGroup, MenuGroup } from "api/menu-group";
import { MenuItem, AnyMenuItem, UnsavedMenuItem, MenuItemType } from "api/menu-item";
import { LoaderState, INITIAL_LOADER_STATE } from "behaviors/loader/state";
import { Section, Features } from "api/route-data/route-data";

export interface PartialAppState {
  menu: State;
  config: {
    sections: Section[];
  };
  features: Features & { isSubmenuEnabled: boolean };
}

export enum MenuPageInspectorType {
  Create = "createMenu"
}

export enum MenuPageItemInspectorType {
  Create = "createMenuItem",
  Edit = "editMenuItem"
}

export interface State {
  menuGroups: MenuGroupList;
  menuItems: MenuItem[];
  app: {
    currentMenu: UnsavedMenuGroup | null;
    itemToBeDeleted: MenuItem | null;
    currentMenuItem: AnyMenuItem | null;
    error: Error | null;
    selectedMenu: MenuGroup | null;
  };
  ui: {
    inspector: null | MenuPageInspectorType | MenuPageItemInspectorType;
    main: LoaderState;
    save: LoaderState;
    sidebar: LoaderState;
    deleteConfirmationVisible: boolean;
    menuItems: { [id: number]: LoaderState | undefined };
  };
}

export const NEW_MENU: UnsavedMenuGroup = {
  name: "",
  slug: ""
};

export const NEW_MENU_ITEM: UnsavedMenuItem = {
  title: "",
  "parent-id": null,
  "item-type": MenuItemType.Section,
  "item-id": null,
  tag: null,
  "menu-group-slug": null,
  "menu-group-id": null,
  data: { color: "#FFFFFF" }
};

export const INITIAL_STATE: State = {
  menuGroups: [],
  menuItems: [],
  app: {
    currentMenu: null,
    itemToBeDeleted: null,
    selectedMenu: null,
    currentMenuItem: null,
    error: null
  },
  ui: {
    inspector: null,
    deleteConfirmationVisible: false,
    main: INITIAL_LOADER_STATE,
    save: { loading: false, error: null },
    sidebar: INITIAL_LOADER_STATE,
    menuItems: {}
  }
};
