/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { t } from "i18n";
import * as React from "react";
import throttle from "helpers/throttle";
import { uploadVideo } from "helpers/upload-video";
import Plus from "../../../../components/icons/plus";
import Media from "components/icons/media";
import VideoStoryElement from "../video-story-element/video-story-element";
import { connect } from "react-redux";
import { fetchVideoClipEmbed, replaceUploadedVideo } from "../../async-action-creators";
import { selectIsReadOnly } from "pages/story-editor/selectors";
import Spinner from "components/spinner/spinner";
import { setIsStoryModifiedState } from "../../action-creators";
import Dropzone from "components/dropzone/dropzone";
import DropzoneMessage, { DropzoneMessageType, DropzoneMessageVariant } from "components/dropzone/dropzone-message";
import { selectIsDesktopSizeViewport } from "store/viewport";
import Button from "components/button/button";
import { get } from "lodash";

import styles from "./video-clip.module.css";
import { validationFn } from "../utils";
import { notificationError } from "action-creators/notification";

import classnames from "classnames/bind";
import { MimeType } from "utils/file.utils";

const cx = classnames.bind(styles);

enum AddNewMediaButtonType {
  Upload = "upload"
}

interface AddNewMediaButtonProps {
  variant: AddNewMediaButtonType;
  uploadText?: string;
  handleClick?: React.MouseEventHandler;
}

const videoUploadQueue = throttle(uploadVideo);

const AddNewMediaButton: React.FC<AddNewMediaButtonProps> = ({ variant, uploadText, handleClick }) => {
  const [buttonIcon, buttonText, dataTestId] =
    variant === AddNewMediaButtonType.Upload
      ? [<Plus width={14} height={14} fill={"var(--mono-5)"} />, uploadText, "add-new-media-upload"]
      : [<Media width={14} height={14} />, t("components.add-new-media.media-gallery"), "add-new-media-open-gallery"];

  return (
    <div className={styles["add-new-media-button-container"]} onClick={handleClick} data-test-id={dataTestId}>
      <span className={styles["add-new-media-button"]}>
        {buttonIcon}
        <span className={styles["add-new-media-button-text"]}>{buttonText}</span>
      </span>
    </div>
  );
};

class VideoClip extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false
    };
  }

  reUploadVideo = (storyElementId) => replaceUploadedVideo(storyElementId);

  validationFailed = (videoAttachmentSize, invalidFiletypeOrSizeError) => {
    invalidFiletypeOrSizeError(t("components.add-new-video-clip.invalid-file-type-error") + videoAttachmentSize + "MB");
  };

  uploadFile = async (
    files: File[],
    elementId,
    videoAttachmentSize,
    invalidFiletypeOrSizeError,
    fetchVideoClipEmbed,
    disableStorySave,
    gumletCollectionId,
    publisher,
    videoHost
  ) => {
    const file = get(files, "[0]");
    if (file) {
      if (validationFn) {
        if (validationFn(file, "video", videoAttachmentSize)) {
          this.setState({
            uploading: true
          });
          disableStorySave(false);
          const payload = {
            file: file,
            gumletCollectionId: gumletCollectionId
          };
          try {
            const upload = await this.uploadToStorage(payload);
            setTimeout(() => {
              this.setState({ uploading: false });
              fetchVideoClipEmbed(elementId, publisher, videoHost, upload);
            }, 10000);
          } catch (err) {
            console.log(err, "Video upload failed");
          }
        } else {
          this.validationFailed(videoAttachmentSize, invalidFiletypeOrSizeError);
        }
      }
    }
  };

  uploadToStorage = async (payload: any) => {
    try {
      const uploadDetails = await new Promise((resolve, reject) => {
        videoUploadQueue.queueJob(payload, function(result) {
          if (result.status === "upload-complete") {
            resolve(result);
          } else {
            reject({ upload: false });
          }
        });
      });
      return uploadDetails;
    } catch (error) {
      console.error("Error during upload:", error);
      throw error;
    }
  };

  generateVideoAttachmentDisclaimer = (videoAttachmentSize, videoAttachmentDuration, videoAttachmentAspectRatio) => {
    return (
      t("components.add-new-video-clip.attachment-info") +
      " " +
      videoAttachmentSize +
      t("components.add-new-video-clip.attachment-size-unit") +
      " , video duration upto" +
      " " +
      videoAttachmentDuration +
      " " +
      t("components.add-new-video-clip.attachment-duration-unit") +
      " and aspect ratio as" +
      " " +
      videoAttachmentAspectRatio
    );
  };

  render() {
    const {
      storyElement,
      videoAttachmentSize,
      videoAttachmentDuration,
      invalidFiletypeOrSizeError,
      gumletCollectionId,
      fetchVideoClipEmbed,
      disableStorySave,
      publisher,
      videoHost,
      isDesktopSizeViewport,
      videoAttachmentAspectRatio
    } = this.props;

    const classes = cx("video-attachment-container", { "is-disabled": this.props.isDisabled });
    const fileUrl = storyElement && storyElement.metadata && storyElement.metadata["video-clip-url"];
    const attachmentMessage = this.generateVideoAttachmentDisclaimer(
      videoAttachmentSize,
      videoAttachmentDuration,
      videoAttachmentAspectRatio
    );

    const { uploading } = this.state;
    return (
      <React.Fragment>
        {!fileUrl && !this.props.isReadOnly && !uploading && (
          <div className={classes} contentEditable={false}>
            <Dropzone
              dropHandler={(file) => {
                this.uploadFile(
                  file,
                  storyElement.id,
                  videoAttachmentSize,
                  invalidFiletypeOrSizeError,
                  fetchVideoClipEmbed,
                  disableStorySave,
                  gumletCollectionId,
                  publisher,
                  videoHost
                );
              }}
              classname={styles["add-new-video-dropzone"]}
              accept={MimeType.Videos}
              onError={invalidFiletypeOrSizeError}
              enableMultipleUploads={false}
              showOnDragAnywhere={true}
              dragEnterMessage={
                <DropzoneMessage
                  message={t("components.dropzone.drop-video-hint")}
                  variant={DropzoneMessageVariant.Small}
                  type={DropzoneMessageType.Video}
                />
              }
              errorMapping={{
                fileType: t("components.dropzone.file-type-error"),
                fileCount: t("components.dropzone.file-count-error"),
                fileSize: t("components.dropzone.file-size-error")
              }}>
              <div>
                <input
                  className={styles["file-field"]}
                  id={`video-attachment-${storyElement.id}`}
                  accept="video/*"
                  type="file"
                  onChange={(e) =>
                    e.target.files &&
                    this.uploadFile(
                      Array.from(e.target.files),
                      storyElement.id,
                      videoAttachmentSize,
                      invalidFiletypeOrSizeError,
                      fetchVideoClipEmbed,
                      disableStorySave,
                      gumletCollectionId,
                      publisher,
                      videoHost
                    )
                  }
                  disabled={this.state.uploading}
                />
                <div className={styles["video-attachment-input-label"]}>
                  <React.Fragment>
                    <label
                      className={styles["video-attachment-label-wrapper"]}
                      htmlFor={`video-attachment-${storyElement.id}`}
                    />
                    <AddNewMediaButton
                      variant={AddNewMediaButtonType.Upload}
                      uploadText={t("components.add-new-video-clip.upload-video-clip")}
                    />
                    <p className={styles["info"]}>{attachmentMessage}</p>
                  </React.Fragment>
                </div>
              </div>
              {isDesktopSizeViewport && (
                <div className={styles["add-new-video-drop-here-text"]}>{t("components.dropzone.drop-video-hint")}</div>
              )}
            </Dropzone>
          </div>
        )}

        {uploading && <Spinner message={t("components.add-new-video-clip.uploading")} />}
        {fileUrl && <VideoStoryElement showPreview={true} storyElement={storyElement} provider="video" />}
        {fileUrl && !this.props.isReadOnly && (
          <div className={styles["video-replace-or-delete-buttons"]} data-test-id="video-replace-or-delete-buttons">
            <Button
              classname={"remove-video-button"}
              type={"default"}
              onClick={() => this.props.reUploadVideo(storyElement.id)}>
              {t("story-editor.story-element.remove")}
            </Button>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isReadOnly: selectIsReadOnly(state),
    videoAttachmentSize: get(state.config, ["video-config", "max-size-in-mb"]),
    videoAttachmentDuration: get(state.config, ["video-config", "max-duration-in-sec"]),
    gumletCollectionId: get(state.config, ["video-config", "gumlet-collection-id"]),
    publisher: get(state.config, ["publisher"]),
    videoHost: get(state.config, ["cdn-video"]),
    isDesktopSizeViewport: selectIsDesktopSizeViewport(state),
    videoAttachmentAspectRatio: get(state.config, ["video-config", "aspect-ratio"])
  };
};

const mapDispatchToProps = (dispatch) => ({
  invalidFiletypeOrSizeError: (error) => dispatch(notificationError(error)),
  fetchVideoClipEmbed: (elementId, publisher, videoHost, res) => {
    dispatch(fetchVideoClipEmbed(elementId, publisher, videoHost, res));
  },
  disableStorySave: (action) => dispatch(setIsStoryModifiedState(action)),
  reUploadVideo: (storyElementId) => dispatch(replaceUploadedVideo(storyElementId))
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoClip);
export { VideoClip };
