/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

export interface S3Signature {
  action: string;
  key: string;
  "Content-Type": string;
  policy: string;
  acl: string;
  success_action_status: string;
  AWSAccessKeyId: string;
  signature: string;
}

export const signFile = (file: File): Promise<S3Signature> => {
  return client
    .url("/sign")
    .query({ "file-name": file.name, "mime-type": file.type })
    .get()
    .json();
};
