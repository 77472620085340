/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

function seq(arr: Array<Function>, callback: Function, index: number) {
  arr[index](function() {
    index++;
    if (index === arr.length) {
      callback();
    } else {
      seq(arr, callback, index);
    }
  });
}

// trigger DOMContentLoaded
function scriptsDone(document: Document) {
  let DOMContentLoadedEvent = document.createEvent("Event");
  DOMContentLoadedEvent.initEvent("DOMContentLoaded", true, true);
  document.dispatchEvent(DOMContentLoadedEvent);
}

/* script runner */

function insertScript(script: HTMLScriptElement, callback: () => void, document: Document) {
  let newScript = document.createElement("script");
  //Create a clone by copying all attributes
  for (let i = script.attributes.length - 1; i >= 0; i--) {
    newScript.setAttribute(script.attributes[i].name, script.attributes[i].value);
  }
  newScript.type = "text/javascript";
  if (script.src) {
    newScript.onload = callback;
    newScript.onerror = callback;
    newScript.src = script.src;
  } else {
    newScript.textContent = script.innerText;
  }

  script.parentNode && script.parentNode.replaceChild(newScript, script);

  // run the callback immediately for inline scripts
  if (!script.src) {
    callback();
  }
}

// https://html.spec.whatwg.org/multipage/scripting.html
var runScriptTypes = [
  "application/javascript",
  "application/ecmascript",
  "application/x-ecmascript",
  "application/x-javascript",
  "text/ecmascript",
  "text/javascript",
  "text/javascript1.0",
  "text/javascript1.1",
  "text/javascript1.2",
  "text/javascript1.3",
  "text/javascript1.4",
  "text/javascript1.5",
  "text/jscript",
  "text/livescript",
  "text/x-ecmascript",
  "text/x-javascript"
];

export function generateIframeContent(iframeBody: ParentNode, document: Document) {
  // get scripts tags from a node
  var scripts = iframeBody.querySelectorAll("script");
  const runList: Array<Function> = [];

  scripts.forEach((script) => {
    const typeAttr = script.getAttribute("type");
    // only run script tags without the type attribute
    // or with a javascript mime attribute value
    if (!typeAttr || runScriptTypes.indexOf(typeAttr) !== -1) {
      runList.push(function(callback: () => void) {
        insertScript(script, callback, document);
      });
    }
  });

  // insert the script tags sequentially
  // to preserve execution order
  // only call when there is at least one script tag
  if (runList.length > 0) {
    const dummyFunction = () => scriptsDone(document);
    seq(runList, dummyFunction, 0);
  }
}
