/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

import {
  StoryElement,
  CompositeStoryElement,
  ChildStoryElement,
  StoryElementTextSubtype,
  StoryElementCompositeSubtype,
  StoryElements,
  StoryElementUI,
  StoryElementFileSubtype,
  StoryElementDataSubtype,
  StoryElementEmbedSubtype,
  StoryElementExternalFileSubtype,
  StoryElementVideoSubtype,
  StoryElementPolltypeSubtype
} from "api/story";
import Text from "components/icons/story-elements/text";
import Title from "components/icons/story-elements/title";
import Summary from "components/icons/story-elements/summary";
import BlockQuote from "components/icons/story-elements/block-quote";
import Question from "components/icons/story-elements/question";
import Answer from "components/icons/story-elements/answer";
import Quote from "components/icons/story-elements/quote";
import BigFact from "components/icons/story-elements/bigfact";
import AlsoRead from "components/icons/story-elements/also-read";
import CompositeStoryElementsDisplay from "./composite-story-element-display";
import Reference from "components/icons/story-elements/reference";
// import ImageGallery from "components/icons/story-elements/image-gallery";
import Attachment from "components/icons/story-elements/attachment";
import Table from "components/icons/story-elements/table";
import NoContentCard from "components/no-content-card/no-content-card";
import Media from "components/icons/media";
import JsEmbed from "components/icons/story-elements/js-embed";
import SocialMediaURL from "components/icons/social-media-url";
import Embed from "pages/story-editor/story-element-types/embed/embed";
import BitgravityVideo from "pages/story-editor/story-element-types/bitgravity-video/bitgravity-video";
import VideoStoryElement from "pages/story-editor/story-element-types/video-story-element/video-story-element";
import VideoClip from "pages/story-editor/story-element-types/video-clip/video-clip";

import Poll from "components/icons/story-elements/poll";
import Blurb from "components/icons/story-elements/blurb";
import QuestionAndAnswer from "components/icons/story-elements/question-and-answer";

import classnames from "classnames/bind";
import styles from "./story-element-display.module.css";
import OpinionPollPreview from "pages/story-editor/story-element-types/opinion-poll/opinion-poll";
import Cta from "components/icons/story-elements/cta";

const cx = classnames.bind(styles);
interface Props {
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement;
  allStoryElements: StoryElements;
  storyElementUI?: StoryElementUI;
}

const StoryElementDisplay: React.SFC<Props> = ({ storyElement, allStoryElements, storyElementUI }) => {
  const storyElementName = storyElement.subtype
    ? `story-element-${storyElement.subtype}`
    : `story-element-${storyElement.type}`;
  return (
    <div className={cx("story-element", `${storyElementName}`)} data-test-id="story-element">
      {storyElement.type === "title" && (
        <React.Fragment>
          <Title />
          <div
            className={styles["story-texts"]}
            data-test-id="story-texts"
            dangerouslySetInnerHTML={{
              __html: storyElement.text || ""
            }}
          />
        </React.Fragment>
      )}
      {storyElement.type === "text" && (
        <React.Fragment>
          {!storyElement.subtype && <Text />}
          {storyElement.subtype === StoryElementTextSubtype.Summary && <Summary />}
          {storyElement.subtype === StoryElementTextSubtype.BlockQuote && <BlockQuote />}
          {storyElement.subtype === StoryElementTextSubtype.Question && <Question />}
          {storyElement.subtype === StoryElementTextSubtype.Answer && <Answer />}
          {storyElement.subtype === StoryElementTextSubtype.Quote && <Quote />}
          {storyElement.subtype === StoryElementTextSubtype.BigFact && <BigFact />}
          {storyElement.subtype === StoryElementTextSubtype.AlsoRead && <AlsoRead />}
          {storyElement.subtype === StoryElementTextSubtype.Blurb && <Blurb />}
          {storyElement.subtype === StoryElementTextSubtype.QandA && <QuestionAndAnswer />}
          {storyElement.subtype === StoryElementTextSubtype.Cta && <Cta />}
          <div
            className={styles["story-texts"]}
            data-test-id="story-texts"
            dangerouslySetInnerHTML={{
              __html: storyElement.text || ""
            }}
          />
        </React.Fragment>
      )}
      {storyElement.type === "image" && (
        <React.Fragment>
          {storyElement.image ? (
            <div className={styles["story-element-image"]} data-test-id="story-element-image">
              <img src={storyElement.image["url"]} alt={storyElement.image["alt-text"] || undefined} />
            </div>
          ) : (
            <NoContentCard>
              <Media />
            </NoContentCard>
          )}
        </React.Fragment>
      )}
      {storyElement.type === "file" && storyElement.subtype === StoryElementFileSubtype.Attachment && (
        <React.Fragment>
          <Attachment />
          <p>{storyElement["file-name"]}</p>
        </React.Fragment>
      )}
      {storyElement.type === "data" && storyElement.subtype === StoryElementDataSubtype.Table && (
        <React.Fragment>
          <Table />
          <p>{storyElement.metadata["file-name"]}</p>
        </React.Fragment>
      )}
      {storyElement.type === "jsembed" && (
        <React.Fragment>
          {(storyElement.subtype === StoryElementEmbedSubtype.FacebookPost ||
            storyElement.subtype === StoryElementEmbedSubtype.FacebookVideo ||
            storyElement.subtype === StoryElementEmbedSubtype.Instagram ||
            storyElement.subtype === StoryElementEmbedSubtype.Tweet ||
            storyElement.subtype === StoryElementEmbedSubtype.Dailymotion ||
            storyElement.subtype === StoryElementEmbedSubtype.SocialMedia) && <SocialMediaURL />}
          {(!storyElement.subtype ||
            storyElement.subtype === StoryElementEmbedSubtype.VidibleVideo ||
            storyElement.subtype === StoryElementEmbedSubtype.Dilmot) && <JsEmbed />}

          {(!storyElement.subtype ||
            storyElement.subtype === StoryElementEmbedSubtype.FacebookPost ||
            storyElement.subtype === StoryElementEmbedSubtype.FacebookVideo ||
            storyElement.subtype === StoryElementEmbedSubtype.Instagram ||
            storyElement.subtype === StoryElementEmbedSubtype.Tweet ||
            storyElement.subtype === StoryElementEmbedSubtype.DailymotionEmbedScript ||
            storyElement.subtype === StoryElementEmbedSubtype.Dilmot ||
            storyElement.subtype === StoryElementEmbedSubtype.VidibleVideo) && (
            <Embed embed={storyElement["embed-js"]} key={storyElement["embed-js"]} showPreview={true} />
          )}
          {(storyElement.subtype === StoryElementEmbedSubtype.Dailymotion ||
            storyElement.subtype === StoryElementEmbedSubtype.SocialMedia) && (
            <VideoStoryElement showPreview={true} storyElement={storyElement} provider={storyElement.subtype} />
          )}
        </React.Fragment>
      )}
      {(storyElement.type === "youtube-video" || storyElement.type === "soundcloud-audio") && (
        <React.Fragment>
          <SocialMediaURL />
          <VideoStoryElement showPreview={true} storyElement={storyElement} provider={storyElement.type} />
        </React.Fragment>
      )}
      {storyElement.type === "video" && storyElement.subtype === StoryElementVideoSubtype.VideoClip && (
        <React.Fragment>
          <VideoClip storyElement={storyElement} showPreview={true}></VideoClip>
        </React.Fragment>
      )}
      {storyElement.type === "external-file" &&
        (storyElement.subtype === StoryElementExternalFileSubtype.Brightcove ||
          storyElement.subtype === StoryElementExternalFileSubtype.JWPlayer ||
          storyElement.subtype === StoryElementExternalFileSubtype.Vod) && (
          <React.Fragment>
            <JsEmbed />
            <VideoStoryElement showPreview={true} storyElement={storyElement} provider={storyElement.subtype} />
          </React.Fragment>
        )}
      {storyElement.type === "external-file" && storyElement.subtype === StoryElementExternalFileSubtype.Bitgravity && (
        <React.Fragment>
          <SocialMediaURL />
          <BitgravityVideo showPreview={true} storyElement={storyElement} />
        </React.Fragment>
      )}
      {storyElement.type === "composite" && (
        <React.Fragment>
          {storyElement.subtype === StoryElementCompositeSubtype.References && <Reference />}
          {/* {storyElement.subtype === StoryElementCompositeSubtype.ImageGallery && <ImageGallery />} */}
          <CompositeStoryElementsDisplay
            storyElement={storyElement as CompositeStoryElement}
            allStoryElements={allStoryElements}
          />
        </React.Fragment>
      )}
      {storyElement.type === "polltype" && (
        <React.Fragment>
          {storyElement.subtype === StoryElementPolltypeSubtype.OpinionPoll && <Poll />}
          <OpinionPollPreview storyElement={storyElement} storyElementUI={storyElementUI} />
        </React.Fragment>
      )}
    </div>
  );
};

export default StoryElementDisplay;
