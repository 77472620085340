/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const NOTIFICATION_SUCCESS = "@@page/NOTIFICATION_SUCCESS";
export const NOTIFICATION_ERROR = "@@page/NOTIFICATION_ERROR";
export const NOTIFICATION_RESET = "@@page/NOTIFICATION_RESET";
export const NOTIFICATION_INFO = "@@page/NOTIFICATION_INFO";

export type SnackBarActions =
  | {
      type: typeof NOTIFICATION_SUCCESS;
      payload: { message: string; onRemove?: () => void };
    }
  | {
      type: typeof NOTIFICATION_ERROR;
      payload: { message: string };
    }
  | {
      type: typeof NOTIFICATION_INFO;
      payload: { message: string };
    };

export const notificationSuccess = (message: string): SnackBarActions => ({
  type: NOTIFICATION_SUCCESS,
  payload: { message }
});

export const notificationError = (message: string): SnackBarActions => ({
  type: NOTIFICATION_ERROR,
  payload: { message }
});

export const notificationInfo = (message: string): SnackBarActions => ({
  type: NOTIFICATION_INFO,
  payload: { message }
});
