/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { validate, ValidationResult } from "utils/validation.utils";
import { UnsavedMenuGroup } from "api/menu-group";
import { AnyMenuItem, MenuItem, UnsavedMenuItem, MenuItemType } from "api/menu-item";
import { assertNever } from "utils";
import { prettify } from "validate.js";
import { t } from "i18n";

export function validateMenu(menu: UnsavedMenuGroup): ValidationResult<UnsavedMenuGroup> {
  return validate(menu, {
    name: {
      presence: { allowEmpty: false }
    },
    slug: {
      presence: { allowEmpty: false }
    }
  });
}

export function validateMenuItem(menuItem: UnsavedMenuItem): ValidationResult<UnsavedMenuItem>;
export function validateMenuItem(menuItem: MenuItem): ValidationResult<MenuItem>;
export function validateMenuItem(menuItem: AnyMenuItem): ValidationResult<AnyMenuItem> {
  return validate(
    menuItem,
    {
      title: {
        presence: { allowEmpty: false }
      },
      "item-type": {
        inclusion: { within: Object.values(MenuItemType) }
      },
      "item-id": () => {
        switch (menuItem["item-type"]) {
          case MenuItemType.Section:
          case MenuItemType.Tag:
            return { presence: { allowEmpty: false }, numericality: { onlyInteger: true } };

          case MenuItemType.Link:
          case MenuItemType.Placeholder:
            return { emptiness: true };

          default:
            return assertNever(menuItem["item-type"]);
        }
      },
      "data.link": () => {
        if (menuItem["item-type"] === MenuItemType.Link) {
          return { presence: { allowEmpty: false }, url: true };
        }

        return false;
      },
      "data.color": {
        presence: { allowEmpty: false },
        format: { pattern: /^#([a-f0-9]{6})\b$/i, message: t("errors.validations.unknown-format") }
      }
    },
    {
      prettify: (value: string) => {
        switch (value) {
          case "item-id":
            return "Target";
          case "data.link":
            return "Link";
          case "data.color":
            return "Color";
          default:
            return prettify(value);
        }
      }
    }
  );
}
