/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import StaticPages from "./static-page";

import { STATIC_PAGES_INDEX_PATH, STATIC_PAGES_EDIT_PATH, STATIC_PAGES_EDIT_MANAGE_PATH } from "./routes";

export const Router = () => (
  <React.Fragment>
    <Switch>
      <Route exact path={STATIC_PAGES_INDEX_PATH} component={StaticPages} />
      <Route exact path={STATIC_PAGES_EDIT_PATH} component={StaticPages} />
      <Route exact path={STATIC_PAGES_EDIT_MANAGE_PATH} component={StaticPages} />
    </Switch>
  </React.Fragment>
);
