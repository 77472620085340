/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect, useState } from "react";
import { useRef } from "react";
import classnames from "classnames/bind";
import styles from "./entity-link.module.css";
import AsyncSelect from "components/select/async";
import TextField from "components/text-field/text-field";
import Button from "components/button/button";
import pDebounce from "p-debounce";
import { listEntities } from "api/entity";
import { isEmpty, startCase, toLower } from "lodash";
import { EditorState } from "prosemirror-state";
import { EntityLinkAttributes } from "pages/story-editor/operations/commands";
import { t } from "i18n";

const cx = classnames.bind(styles);

export type EntityLinkType = {
  id: number;
  name: string;
  slug: string;
  type: {
    id: number;
    slug: string;
  };
};

type Props = {
  value: { entity: EntityLinkType | null; text: string };
  onConfirm: (entity: EntityLinkType | null, textContent: string) => void;
  onCancel: () => void;
  editorState: EditorState;
  includeEntityTypeIds: Number[];
};

const getEntities = async (query: string, includeEntityTypeIds: Number[]): Promise<EntityLinkType[]> => {
  try {
    const includeEntityTypeIdStr = includeEntityTypeIds.toString();
    const response = await listEntities(query, includeEntityTypeIdStr);
    return response.entities.map((entity) => ({
      id: entity.id,
      name: entity.name,
      slug: entity.slug,
      type: {
        id: entity["entity-type-id"],
        slug: entity.type
      }
    })) as EntityLinkType[];
  } catch (err) {
    return [];
  }
};

export const getEntityLinkAttributes = (entity: EntityLinkType): EntityLinkAttributes => {
  const entityUrl = `/${entity.type.slug}/${entity.slug}`;
  return {
    href: entityUrl,
    "entity-id": entity.id,
    "entity-name": entity.name,
    "entity-slug": entity.slug,
    "entity-type-id": entity.type.id,
    "entity-type-slug": entity.type.slug,
    class: "entity-link"
  };
};

const debouncedListEntities = pDebounce(
  async (q: string, includeEntityTypeIds: Number[]) => await getEntities(q, includeEntityTypeIds),
  250
);

export const getEntityDisplayName = (name: string, type: string) => {
  return `${name} (${startCase(toLower(type))})`;
};

const EntityLink: React.FC<Props> = ({ value, onConfirm, onCancel, editorState, includeEntityTypeIds }) => {
  const entityLinkContainer = useRef<HTMLDivElement | null>(null);
  const [selectedText, setSelectedText] = useState<string>("");
  const [defaultOptions, setDefaultOptions] = useState<EntityLinkType[]>([]);
  const [currentText, setCurrentText] = useState<string>("");
  const [currentSelectedEntity, setCurrentSelectedEntity] = useState<EntityLinkType | null>(null);

  useEffect(() => {
    setCurrentSelectedEntity(value.entity);
  }, [value.entity]);

  useEffect(() => {
    setCurrentText(value.text);
  }, [value.text]);

  useEffect(() => {
    const { from, to, empty } = editorState.selection;
    if (!empty) {
      const textSelected = editorState.doc.textBetween(from, to);
      if (textSelected) {
        setSelectedText(textSelected);
      }
    }
  }, [editorState]);

  useEffect(() => {
    (async () => {
      if (isEmpty(value.entity) && selectedText) {
        const entityOptions: EntityLinkType[] = await getEntities(selectedText, includeEntityTypeIds);
        setDefaultOptions(entityOptions);
      }
    })();
  }, [value.entity, selectedText, includeEntityTypeIds]);

  useEffect(() => {
    if (isEmpty(value.text) && selectedText) {
      setCurrentText(selectedText);
    }
  }, [value.text, selectedText]);

  useEffect(() => {
    // When opening the entity link dialog box for the first time, choose first options from the default options
    if (currentSelectedEntity === null && defaultOptions.length > 0) {
      setCurrentSelectedEntity(defaultOptions[0]);
    }
  }, [currentSelectedEntity, defaultOptions]);

  const [fieldErrors, setFieldErrors] = useState<{ entity: boolean; text: boolean }>({ entity: false, text: false });

  const validateAndConfirm = () => {
    if (isEmpty(currentText) || isEmpty(currentSelectedEntity)) {
      return setFieldErrors({
        text: isEmpty(currentText),
        entity: isEmpty(currentSelectedEntity)
      });
    }
    onConfirm(currentSelectedEntity, currentText);
  };

  return (
    <div ref={entityLinkContainer} className={cx("entity-link-container")}>
      <AsyncSelect
        value={currentSelectedEntity}
        onChange={(value: EntityLinkType) => setCurrentSelectedEntity(value)}
        getOptionLabel={(fieldOption) => getEntityDisplayName(fieldOption.name, fieldOption.type.slug)}
        getOptionValue={(fieldOption) => fieldOption.slug}
        defaultOptions={defaultOptions}
        loadOptions={async (q) => debouncedListEntities(q, includeEntityTypeIds)}
        label={t("story-editor.toolbar.entity-link.link-entity")}
        errorMessage={fieldErrors.entity ? t("story-editor.toolbar.entity-link.error.entity-cannot-be-empty") : null}
      />
      <TextField
        value={currentText}
        onChange={(value: string) => {
          setCurrentText(value);
          setFieldErrors({ ...fieldErrors, text: false });
        }}
        errorEnabled={fieldErrors.text}
        label={t("story-editor.toolbar.entity-link.replace-text")}
        errorMessage={fieldErrors.text ? t("story-editor.toolbar.entity-link.error.link-text-cannot-be-empty") : null}
      />
      <div className={cx("entity-link-button-container")}>
        <div className={cx("entity-link-button-container__btn")}>
          <Button type="secondary" onClick={() => onCancel()}>
            {t("common.cancel")}
          </Button>
        </div>
        <div className={cx("entity-link-button-container__btn")}>
          <Button type="primary" onClick={() => validateAndConfirm()}>
            {t("common.ok")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EntityLink;
