/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { PureComponent } from "react";
import { TextArea as EmTextArea } from "@quintype/em/components/text-area";

interface TextAreaProps {
  /** Value to render  */
  value: string;
  /** label for the field */
  label?: string;
  /** onChange callback */
  onChange: (value: string) => void;
  /** onPaste callback */
  onPaste?: (value: React.ClipboardEvent) => void;
  /** onBlur callback */
  onBlur?: () => void;
  /** onKeyPress callback */
  onKeyPress?: React.KeyboardEventHandler<any>;
  /** Placeholder for the field */
  placeholder?: string;
  /** Error message for the field */
  errorMessage?: string;
  /** secondary information */
  hint?: string;
  /** disabled */
  disabled?: boolean;
  /** Optional classname */
  classname?: string;
  /** ref for the field */
  inputRef?: React.RefObject<HTMLTextAreaElement>;
  /** Maximum number of rows */
  maxRows?: number;
  /** variant */
  variant?: string;
  /** size */
  size?: "medium" | "extra-large" | "jumbo";
}

class TextArea extends PureComponent<TextAreaProps> {
  render() {
    return <EmTextArea {...this.props} />;
  }
}
export default TextArea;
