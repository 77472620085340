/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Spinner from "components/spinner/spinner";
import Close from "components/icons/close";
import { LoaderState } from "behaviors/loader/state";
import { PushNotificationNotFoundError } from "./errors";
import { t } from "i18n";

interface LoaderProps {
  loader: LoaderState;
}

const Loader: React.SFC<LoaderProps> = ({ loader: { loading, error } }) => {
  if (loading) {
    return <Spinner message={t("push-notification.messages.loading-list")} />;
  }
  if (error) {
    if (error instanceof PushNotificationNotFoundError) {
      return (
        <React.Fragment>
          <Close width="48" height="48" />
          <p>{t("push-notification.messages.not-found", { id: error.id })}</p>
        </React.Fragment>
      );
    }
  }

  return <p>{t("push-notification.messages.unknown-error")}</p>;
};

export default Loader;
