/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Attribute, AttributeType, DataType } from "api/story-attributes";
import { LoaderState, INITIAL_LOADER_STATE } from "behaviors/loader/state";
import { Features } from "api/route-data/route-data";
import { StoryTemplate } from "api/route-data/story-route-data";

export interface PartialAppState {
  attributes: State;
  features: Features;
  config: {
    storyTemplates: StoryTemplate[];
  };
}

export enum AttributePageInspectorType {
  Create = "create",
  Edit = "edit"
}

export interface State {
  attributes: Attribute[];
  features: Features;
  app: {
    currentAttribute: Attribute | null;
    error: Error | null;
  };
  config: {
    storyTemplates: StoryTemplate[];
  };
  ui: {
    inspector: AttributePageInspectorType | null;
    main: LoaderState;
    save: LoaderState;
    attributes: { [name: string]: LoaderState | undefined };
    entityTypes: string[];
    entityTypesError: Error | null;
  };
}

export const NEW_ATTRIBUTE: Attribute = {
  type: AttributeType.Story,
  name: "",
  values: [],
  "display-name": "",
  "data-type": DataType.MultiValuedStrings,
  "is-mandatory": false,
  "enabled-for-all-templates": true
};

export const INITIAL_ATTRIBUTES_STATE: State = {
  attributes: [],
  features: {},
  config: {
    storyTemplates: []
  },
  app: {
    currentAttribute: null,
    error: null
  },
  ui: {
    inspector: null,
    main: INITIAL_LOADER_STATE,
    save: INITIAL_LOADER_STATE,
    attributes: {},
    entityTypes: [],
    entityTypesError: null
  }
};
