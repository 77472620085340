/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";

import { Top } from "api/analytics";
import { Section } from "api/route-data/route-data";
import { PartialAppState, CurrentFilter } from "pages/analytics/state";
import TopList from "../top-list/top-list";
import { DIMENSION } from "pages/analytics/constants";

interface TopSectionsProps {
  metrics: string;
  data: Top[];
  currentFilter: CurrentFilter;
}

interface StateProps {
  sections: Section[];
}

type Props = TopSectionsProps & StateProps;

export const mapTopSectionsWithName = (data: Top[], sections: Section[]): Top[] => {
  return data.map((topSectionAnalyticsData) => {
    const section = sections.find((section) => topSectionAnalyticsData.id === section.id);

    return section ? { ...topSectionAnalyticsData, name: section.name } : topSectionAnalyticsData;
  });
};

const TopSections: React.SFC<Props> = ({ metrics, data, sections, currentFilter }) => {
  const topSectionsMappedWithName = mapTopSectionsWithName(data, sections);

  return (
    <TopList
      dimension={DIMENSION.SECTION}
      metrics={metrics}
      list={topSectionsMappedWithName}
      showDelta={currentFilter.dateRange["name"] !== "custom"}
    />
  );
};

const mapStateToProps = (state: PartialAppState) => ({
  sections: state.config.sections
});

export default connect(
  mapStateToProps,
  null
)(TopSections);

export { TopSections };
