/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";
import { saveAmpConfigData, getAmpConfigData, AmpConfig } from "api/amp-config";
import { setErrorsAction } from "./action-creators";
import { NOTIFICATION_SUCCESS, NOTIFICATION_ERROR, NOTIFICATION_RESET } from "containers/page/actions";

import { listAmpConfigAction, listAmpConfigSuccessAction, listAmpConfigFailureAction } from "./action-creators";
import { validateAmpConfig } from "./validate";
import { toClientValidationError } from "utils/validation.utils";

export const loadAmpConfig = () => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(listAmpConfigAction());

  try {
    const response = await getAmpConfigData();
    const ampConfig = response["amp-config"];
    dispatch(listAmpConfigSuccessAction(ampConfig));
  } catch (error) {
    dispatch(listAmpConfigFailureAction(error));
  }
};

export const saveAmpConfig = (ampConfig: AmpConfig) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch({
    type: NOTIFICATION_RESET
  });

  let errors = validateAmpConfig(ampConfig);
  if (errors) {
    dispatch(setErrorsAction(toClientValidationError<AmpConfig>(errors)));
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: { message: t("settings.access_levels.messages.amp-save-error") }
    });
    return;
  }

  try {
    const response = await saveAmpConfigData(ampConfig);
    dispatch({
      type: NOTIFICATION_SUCCESS,
      payload: { message: t("settings.access_levels.messages.amp-update-success") }
    });
    dispatch(listAmpConfigSuccessAction(response["amp-config"]));
  } catch (e) {
    dispatch(setErrorsAction(e.errors));
  }
};
