/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import produce from "immer";
import { INITIAL_PROMOTIONAL_MESSAGE_STATE } from "./state";
import actions, { ActionTypes } from "./actions";

export default function promotionalMessagesReducer(state = INITIAL_PROMOTIONAL_MESSAGE_STATE, action: ActionTypes) {
  return produce(state, (draft) => {
    switch (action.type) {
      case actions.LOAD_PROMOTIONAL_MESSAGES_INIT:
        draft.ui.main = { loading: true, error: null };
        break;
      case actions.LOAD_PROMOTIONAL_MESSAGES_SUCCESS:
        draft.promotionalMessages = action.payload.promotionalMessages;
        draft.promotionalMessagesConfig = action.payload.promotionalMessages.config;
        draft.ui.main = { loading: false, error: null };
        draft.ui.isPromotionalMessagesModified = false;
        break;
      case actions.LOAD_PROMOTIONAL_MESSAGES_FAILURE:
        draft.ui.main = { loading: false, error: action.payload.error };
        break;
      case actions.UPDATE_PROMOTIONAL_MESSAGE_CONFIG:
        draft.promotionalMessagesConfig = action.payload.config;
        draft.ui.isPromotionalMessagesModified = true;
        break;
      default:
        break;
    }
  });
}
