/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const CheckCircle = ({ width = "24", height = "24", color = "#fff" }) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={color}
    data-test-id="check-circle-icon"
    viewBox="0 0 24 24">
    <path d="M12 3a8.74 8.74 0 0 1 4.5 1.22 9 9 0 0 1 3.28 3.28A8.64 8.64 0 0 1 21 12a8.64 8.64 0 0 1-1.22 4.5 9.15 9.15 0 0 1-3.28 3.29A8.73 8.73 0 0 1 12 21a8.73 8.73 0 0 1-4.5-1.21 9.15 9.15 0 0 1-3.28-3.29 8.91 8.91 0 0 1 0-9 9 9 0 0 1 3.25-3.31A8.74 8.74 0 0 1 12 3zm0 16.84a7.67 7.67 0 0 0 3.94-1A7.88 7.88 0 0 0 19.81 12a7.65 7.65 0 0 0-1-3.92 7.92 7.92 0 0 0-2.87-2.85A7.88 7.88 0 0 0 8 5.18a8 8 0 0 0-2.82 2.87 7.88 7.88 0 0 0 0 7.86 7.84 7.84 0 0 0 2.87 2.85A7.7 7.7 0 0 0 12 19.81zm5.15-10a.36.36 0 0 0 .11-.29.51.51 0 0 0-.11-.33l-.32-.29a.37.37 0 0 0-.29-.14.51.51 0 0 0-.33.11l-5.95 5.9-2.5-2.54a.55.55 0 0 0-.33-.11.42.42 0 0 0-.29.11l-.32.29a.51.51 0 0 0-.11.33.36.36 0 0 0 .11.29l3.12 3.16a.47.47 0 0 0 .32.14.37.37 0 0 0 .3-.14z" />
  </svg>
);

export default CheckCircle;
