/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { t } from "i18n";

import { AlternativeInspectorErrors, PartialAppState } from "pages/story-editor/state";
import Alternatives from "../alternatives/alternatives";
import styles from "./alternatives-inspector.module.css";

interface AlternativesInspectorProps {
  alternativeLocations: string[];
  errors?: AlternativeInspectorErrors;
}

class AlternativesInspector extends React.Component<AlternativesInspectorProps> {
  render() {
    const titles = {
      home: t("story-editor.inspector.story-alternatives"),
      social: t("story-editor.inspector.social-alternatives")
    };

    return (
      <div className={styles["alternatives-inspector-content-container"]}>
        {this.props.alternativeLocations.map((location) => (
          <Alternatives title={titles[location]} location={location} errors={this.props.errors} />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state: PartialAppState): AlternativesInspectorProps => {
  return {
    alternativeLocations: state.storyEditor.ui.alternativeLocations,
    errors: state.storyEditor.ui.errors.alternativeInspector
  };
};

export default connect(mapStateToProps)(AlternativesInspector);

export { AlternativesInspector };
