/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ThunkDispatch } from "redux-thunk";
import {
  loadTaskAction,
  loadAllTasksAction,
  loadTaskSuccessAction,
  loadAllTasksSuccessAction,
  loadAllTasksFailureAction,
  closeTaskSuccessAction,
  closeTaskAction,
  closeTaskFailureAction,
  setTaskImageAction,
  showInspectorAction,
  deleteTaskImageAction,
  updateImageDataAction,
  updateImageUploadStatusAction,
  saveImageValidationFailureAction,
  taskManagerLoginAction,
  cancelLoadAllTasksAction,
  clearSelectedMediaAction
} from "./action-creators";
import { t } from "i18n";

import * as api from "api/tasks";
import { TaskId } from "api/primitive-types";
import { mapForRequest } from "../../../../src/helpers/image-keys-mapping";
import { saveMedia } from "../../../../src/helpers/api";
import { navigate } from "utils/routes.utils";
import { TASK_PATH, TASKS_INDEX_PATH } from "./routes";
import { getMetadataWithDimensions } from "utils/image-metadata.utils";

import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from "containers/page/actions";
import { ImageError } from "./state";
import { loadMediaDataAction } from "pages/media-library/action-creators";

export const isAuthorizationError = (error: Error) => {
  const errorData = JSON.parse(error.message);
  return errorData.status === 401;
};

export const loadTask = (taskId: TaskId) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(loadTaskAction());
  try {
    const response = await api.fetchTask(taskId);
    dispatch(loadTaskSuccessAction(response));
  } catch (error) {
    if (isAuthorizationError(error)) {
      dispatch(taskManagerLoginAction());
    } else {
      dispatch({
        type: NOTIFICATION_ERROR,
        payload: { message: t("tasks.messages.error_load_task", { taskId }) }
      });
      dispatch(navigate(TASKS_INDEX_PATH));
    }
  }
};

export const loadAllTasks = () => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(loadAllTasksAction());
  try {
    const response = await api.fetchAllTasks();
    dispatch(loadAllTasksSuccessAction(response));
  } catch (error) {
    if (error.status === 403) {
      dispatch(loadAllTasksFailureAction({ message: t("tasks.messages.unauthorised") } as Error));
      return;
    }
    if (isAuthorizationError(error)) {
      dispatch(taskManagerLoginAction());
    } else {
      dispatch(loadAllTasksFailureAction(error));
    }
  }
};

export const closeTask = (taskId: TaskId) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(closeTaskAction());
  try {
    const response = await api.closeTask(taskId);
    dispatch(closeTaskSuccessAction(response));
  } catch (error) {
    if (isAuthorizationError(error)) {
      dispatch(taskManagerLoginAction());
    } else {
      dispatch(closeTaskFailureAction());
      dispatch({
        type: NOTIFICATION_ERROR,
        payload: { message: t("tasks.messages.error_close_task") }
      });
    }
  }
};

export const closeTaskAndRefreshTaskList = (taskId: TaskId) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(loadAllTasksAction());
  try {
    await api.closeTask(taskId);
    dispatch({
      type: NOTIFICATION_SUCCESS,
      payload: { message: t("tasks.messages.close_task_success") }
    });
    const response = await api.fetchAllTasks();
    dispatch(loadAllTasksSuccessAction(response));
  } catch (error) {
    if (isAuthorizationError(error)) {
      dispatch(taskManagerLoginAction());
    } else {
      dispatch(cancelLoadAllTasksAction());
      dispatch({
        type: NOTIFICATION_ERROR,
        payload: { message: t("tasks.messages.error_close_task") }
      });
    }
  }
};

export const setTaskImage = (images: api.Image[]) => async (dispatch: ThunkDispatch<any, any, any>) => {
  const promises = images.map(async (image) => {
    const metadata = await getMetadataWithDimensions(image);
    return { ...image, metadata };
  });
  const imagesWithMetadata = await Promise.all(promises);
  dispatch(setTaskImageAction(imagesWithMetadata));
};

const validateImages = (images: api.Image[]): ImageError[] => {
  let error: ImageError[] = [];
  images.map((image, index) => {
    if (image.caption == null || image.caption.length === 0) error.push({ id: index, caption: true });
    return null;
  });
  return error;
};

export const saveTaskImage = (images: api.Image[], id: String | null) => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  const validationErrors = validateImages(images);
  dispatch(saveImageValidationFailureAction(validationErrors));
  if (validationErrors.length > 0) {
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: { message: t("mediaLibrary.unable_processing_image", { count: validationErrors.length }) }
    });
    return;
  }
  try {
    const updatedImages =
      images &&
      images.map((image) => {
        return { ...mapForRequest(image), "task-id": id };
      });
    await saveMedia(updatedImages);
    dispatch(showInspectorAction(false));
    dispatch(navigate(TASK_PATH, { id }));
    dispatch({
      type: NOTIFICATION_SUCCESS,
      payload: { message: t("tasks.media.save_success") }
    });
    setTimeout(() => {
      dispatch(loadMediaDataAction({ "task-id": id }));
    }, 700);
  } catch (error) {
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: { message: t("tasks.media.save_failure") }
    });
  }
};

export const showInspector = (showInspector: boolean) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(showInspectorAction(showInspector));
};

export const deleteTaskImage = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(deleteTaskImageAction());
};

export const updateImageData = (key: String, value: string | number | null, imageIndex: number) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(updateImageDataAction(key, value, imageIndex));
};

export const updateImageUploadStatus = (status: boolean) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(updateImageUploadStatusAction(status));
};

export const closeImageInspector = (id: TaskId | null) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(clearSelectedMediaAction());
  id && dispatch(navigate(TASK_PATH, { id }));
};
