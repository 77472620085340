/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const TAGS_INDEX_PATH = "/manage/tags";
export const TAGS_NEW_PATH = "/manage/tags/new";
export const TAG_EDIT_PATH = "/manage/tags/:id";
