/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import Inspector from "components/inspector/inspector";
import { t } from "i18n";
import * as React from "react";
import { connect } from "react-redux";
import { AnyAction, compose } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { navigate } from "utils/routes.utils";
import { CONSUMERS_PATH } from "pages/consumers-dashboard/routes";
import { DateRangePicker } from "@quintype/em/components/date-range-picker";
import { fromUnixTime, set, getUnixTime } from "date-fns";
import DateFilter from "../date-filter/date-filter";
import "react-datepicker/dist/react-datepicker.css";
import {
  consumersReplaceFilter,
  consumersResetConsumersList,
  fetchConsumersData,
  FilterSet,
  PartialAppState,
  setConsumersLoadingStatus
} from "store/consumer/consumer";
import { getAdminAccessToken as tokenFn } from "api/bridgekeeper";
import styles from "./filters-inspector.module.css";
import { DateFilterTypes, RelativeDateFilter } from "components/date-filter/date-filter";

interface InspectorDispatchProps {
  cancelAddingFilters: () => void;
  applyFilter: (filterSet: FilterSet, isLoading: boolean, integrationId: number, realmId: number) => void;
}
interface StateProps {
  stagingFilterSet: FilterSet;
}
interface InspectorOwnProps {
  isActive: boolean;
  updateFilter: (key: string, value: any) => void;
  integrationId: number;
  realmId: number;
}

type Props = InspectorDispatchProps & StateProps & InspectorOwnProps;

const onDateRangeSelect = (startDate, endDate) => {
  const formatStartDate = startDate && fromUnixTime(startDate);
  const formatEndDate = endDate && set(fromUnixTime(endDate), { hours: 11, minutes: 59, seconds: 59 });

  const newFilter: FilterSet = {
    "created-at": {
      type: DateFilterTypes.Custom,
      from: formatStartDate.getTime(),
      to: formatEndDate.getTime()
    }
  };
  return newFilter;
};

const FiltersInspector: React.FC<Props> = ({
  isActive,
  cancelAddingFilters,
  stagingFilterSet,
  updateFilter,
  applyFilter,
  integrationId,
  realmId
}) => {
  const filter = stagingFilterSet["created-at"];
  let selectedFromDate;
  let selectedToDate;
  if (filter && filter.type === DateFilterTypes.Custom) {
    selectedFromDate = filter.from;
    selectedToDate = filter.to;
  }

  const getDateRangeProps = (type: DateFilterTypes): { onChange: (startStamp, endStamp) => void } => {
    if (type === DateFilterTypes.Custom) {
      return {
        onChange: (startStamp, endStamp) => {
          const filter = onDateRangeSelect(startStamp, endStamp);
          updateFilter("created-at", filter["created-at"]);
        }
      };
    }
    return { onChange: () => {} };
  };

  const { onChange: onDateRangeChange } = getDateRangeProps((filter && filter.type) || DateFilterTypes.Today);

  const isNotCustomDateFilter = !(filter && filter.type === DateFilterTypes.Custom);

  return (
    <Inspector
      title={t("consumers.inspector.title")}
      actionButtonLabel={t("consumers.inspector.action")}
      onActionButtonClick={() => {
        return applyFilter(stagingFilterSet, true, integrationId, realmId);
      }}
      isActive={isActive}
      onClose={cancelAddingFilters}>
      <DateFilter
        label={t("consumers.inspector.created_date")}
        value={stagingFilterSet["created-at"]}
        onChange={(value: RelativeDateFilter) => updateFilter("created-at", value)}
        classname="filters-form-date-filter-container"
      />
      {filter && filter.type === DateFilterTypes.Custom && selectedFromDate && selectedToDate && (
        <div className={styles["date-range-picker-container"]}>
          <DateRangePicker
            startTimeStamp={getUnixTime(selectedFromDate)}
            endTimeStamp={getUnixTime(selectedToDate)}
            disabled={isNotCustomDateFilter}
            onDateRangeSelect={onDateRangeChange}
          />
        </div>
      )}
    </Inspector>
  );
};

const mapStateToProps = (state: PartialAppState) => {
  return {
    stagingFilterSet: state.consumers.stagingFilterSet
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): InspectorDispatchProps => {
  return {
    cancelAddingFilters: () => {
      dispatch(navigate(CONSUMERS_PATH));
    },
    applyFilter: (filterSet: FilterSet, isLoading: boolean, integrationId: number, realmId: number) => {
      dispatch(consumersReplaceFilter({ filterSet }));
      dispatch(consumersResetConsumersList());
      dispatch(setConsumersLoadingStatus({ isLoading }));
      dispatch(fetchConsumersData(tokenFn, integrationId, realmId));
      dispatch(navigate(CONSUMERS_PATH));
    }
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(FiltersInspector);

export { FiltersInspector };
