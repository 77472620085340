/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Star = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="currentColor">
    <path d="M11.52 4.58a1 1 0 0 1 .55-.5 1.11 1.11 0 0 1 .72 0 .91.91 0 0 1 .54.5l2 4.13 4.57.65a1 1 0 0 1 .64.38 1 1 0 0 1 .22.68 1 1 0 0 1-.3.66l-3.28 3.22.82 4.56a1 1 0 0 1-.14.7 1 1 0 0 1-.58.43 1 1 0 0 1-.72-.1l-4.09-2.12-4.1 2.12a1 1 0 0 1-.72.1.92.92 0 0 1-.65-.43.91.91 0 0 1-.14-.7l.78-4.56-3.28-3.22a1 1 0 0 1-.07-1.34.93.93 0 0 1 .64-.38l4.56-.65z" />
  </svg>
);

export default Star;
