/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface RemoveFormattingIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const RemoveFormatting: React.SFC<RemoveFormattingIconProps> = ({
  width = "24",
  height = "24",
  color = "currentColor"
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 24 24">
    <path d="M9.59,5.59H4.13L4.65,3h14l-.54,2.55H12.62l-2.41,12H7.16Zm10.88,10.1-1.15-1.14-2,1.95-2-1.95L14.2,15.69l2,2-1.74,1.71H3.53V21H15.12l2.22-2.18,2,1.94,1.15-1.14-2-1.94Z" />
  </svg>
);

export default RemoveFormatting;
