/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import Button from "components/button/button";
import Chip from "components/chip/chip";
import { t } from "i18n";
import AllItems from "pages/collections/components/all-items/all-items";
import RealTimeMembers from "pages/collections/components/realtime-members/realtime-members";
import SortedItems from "pages/collections/components/sorted-items/sorted-items";
import * as React from "react";
import { connect } from "react-redux";
import { format as formatDate } from "date-fns";
import { navigateFn } from "utils/routes.utils";
import { publishCollectionAction } from "../../async-action-creators";
import { COLLECTIONS_CONTENT_SETTINGS_PATH, COLLECTIONS_PATH } from "../../routes";
import { actions } from "../../actions";
import { formattedDate } from "utils";
import { selectIsDesktopSizeViewport } from "store/viewport";
import { AnyCollection, DataSource } from "api/story-collection";
import { CollectionTemplateOptions } from "api/template-options";
import { PartialAppState, LHSTab } from "pages/collections/initial-state";
import { CollectionId } from "api/primitive-types";
import { ThunkDispatch } from "redux-thunk";
import { isExistingCollection } from "../../utils";
import { Attribute } from "api/story-attributes";
import { RealtimeMember } from "helpers/ably/types";
import { Link } from "react-router-dom";
import styles from "./collection.module.css";
import GoBack from "components/go-back/go-back";

interface StateProps {
  selectedCollection: AnyCollection;
  stagingSelectedCollection: AnyCollection;
  templateOptions: CollectionTemplateOptions;
  attributes: Attribute[];
  isDesktopSizeViewport: boolean;
}

interface RealtimeProviderProps {
  realtimeCurrentMember: RealtimeMember;
  realtimeMembers: RealtimeMember[];
}

interface DispatchProps {
  openSettingsInspector: (collectionId: CollectionId | "new") => void;
  publishCollection: () => void;
  switchLaneTab: (tab: LHSTab) => void;
}

type Props = StateProps & DispatchProps & RealtimeProviderProps;

const Collection: React.SFC<Props> = ({
  openSettingsInspector,
  publishCollection,
  realtimeCurrentMember,
  realtimeMembers,
  selectedCollection,
  stagingSelectedCollection,
  templateOptions,
  switchLaneTab,
  attributes,
  isDesktopSizeViewport
}) => {
  return (
    <React.Fragment>
      {isDesktopSizeViewport && (
        <Link to={COLLECTIONS_PATH} className={styles["dashboard-link"]}>
          <GoBack message="Dashboard" classname="go-back-to-dashboard" />
        </Link>
      )}
      <header className={styles["collections-header"]} data-test-id="collection-header">
        <div className={styles["collections-meta"]} data-test-id="collection-meta">
          <h2 className={styles["collection-title"]} data-test-id="collection-title">
            {selectedCollection.name || t("collections.new_collection")}
          </h2>
          <div className={styles["collection-labels"]} data-test-id="collection-labels">
            <Chip
              value={
                selectedCollection["data-source"] === DataSource.Automated
                  ? t("collections.automated")
                  : t(`collections.${selectedCollection["data-source"]}`)
              }
              invert
              classname={"collection-chip"}
            />
            <Chip value={`${t("collections.template")}: ${selectedCollection.template}`} />
            {selectedCollection["publish-at"] && selectedCollection.status === "scheduled" && (
              <Chip
                value={
                  `${t("collections.scheduled-chip-label")}: ` +
                  formatDate(selectedCollection["publish-at"], "dd/MM/yyyy HH:mm a")
                }
              />
            )}
          </div>
          {isExistingCollection(selectedCollection) && selectedCollection["updated-at"] && (
            <span className={styles["collections-last-modified"]} data-test-id="collections-last-modified">
              {t("collections.last_modified", {
                date_time: formattedDate(selectedCollection["updated-at"])
              })}
            </span>
          )}
        </div>
        <div className={styles["collections-realtime-members"]}>
          {!isDesktopSizeViewport && (
            <Link to={COLLECTIONS_PATH} className="dashboard-link">
              <GoBack message="Dashboard" classname="go-back-to-dashboard" />
            </Link>
          )}
          <RealTimeMembers realtimeMembers={realtimeMembers} realtimeCurrentMember={realtimeCurrentMember} />
        </div>
        <div className={styles["collections-settings-button"]}>
          <Button
            type="secondary"
            testId="settings-button"
            onClick={() =>
              isExistingCollection(stagingSelectedCollection)
                ? openSettingsInspector(stagingSelectedCollection.id)
                : openSettingsInspector("new")
            }>
            {t("collections.settings")}
          </Button>
        </div>
      </header>

      <div className={styles["collection-container"]} data-test-id="collection-container">
        {isDesktopSizeViewport && <AllItems switchLaneTab={switchLaneTab} />}
        <SortedItems templateOptions={templateOptions} attributes={attributes} />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    stagingSelectedCollection: state.collections.collectionPage.stagingSelectedCollection!,
    templateOptions: state.collections.collectionPage.templateOptions,
    selectedCollection: state.collections.collectionPage.selectedCollection!,
    attributes: state.config.storyAttributes,
    isDesktopSizeViewport: selectIsDesktopSizeViewport(state)
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, any, any>): DispatchProps => {
  const navigate = navigateFn(dispatch);

  return {
    openSettingsInspector: (collectionId) =>
      navigate(COLLECTIONS_CONTENT_SETTINGS_PATH, { collectionId: collectionId || "new" }),
    publishCollection: () => dispatch(publishCollectionAction()),
    switchLaneTab: (tab) => dispatch({ type: actions.SWITCH_LANE_TAB, payload: { selectedTab: tab } })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Collection);
