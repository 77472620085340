/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import MediaCard from "../media-card/media-card";
import { Media } from "pages/media-library/state";

interface MediaListProps {
  mediaItems: Array<Media>;
  editMedia: (media: Media) => void;
  showEditImage?: boolean;
  addThumbnailUrl?: boolean;
  canMultiSelect?: boolean;
  mediaForMultiSelect?: Array<Media>;
  setMediaItemForMultiSelect?: (media: Media) => void;
}

const MediaList: React.FC<MediaListProps> = ({
  mediaItems,
  editMedia,
  addThumbnailUrl,
  canMultiSelect,
  setMediaItemForMultiSelect,
  mediaForMultiSelect
}) => {
  return (
    <React.Fragment>
      {mediaItems.map((media) => (
        <MediaCard
          media={media}
          key={`media-${media.image.key}`}
          editMedia={editMedia}
          addThumbnailUrl={addThumbnailUrl}
          canMultiSelect={canMultiSelect}
          setMediaItemForMultiSelect={setMediaItemForMultiSelect}
          mediaForMultiSelect={mediaForMultiSelect}
        />
      ))}
    </React.Fragment>
  );
};

export default MediaList;
