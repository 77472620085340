/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AmpConfig } from "api/amp-config";
import { INITIAL_LOADER_STATE, LoaderState } from "behaviors/loader/state";
import { Features } from "api/route-data/route-data";

export interface PartialAppState {
  ampConfig: State;
  config: {
    publisherWideBannerMessage: string | null;
  };
  features: Features;
}

export interface State {
  ampConfig: AmpConfig | null;
  ui: {
    main: LoaderState;
    saveErrors: LoaderState;
    isAmpConfigModified: boolean;
  };
}
export const INITIAL_AMP_STATE: State = {
  ampConfig: null,
  ui: {
    main: INITIAL_LOADER_STATE,
    saveErrors: INITIAL_LOADER_STATE,
    isAmpConfigModified: false
  }
};
