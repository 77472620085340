/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { navigateFn } from "../../../../utils/routes.utils";
import * as actions from "../../actions";
import Checkbox from "components/checkbox/checkbox";
import Button from "components/button/button";
import { t } from "i18n";
import { WORKSPACE_BULK_ACTION_PATH } from "../../routes";
import Close from "components/icons/close";

import styles from "./bulk-input-bar.module.css";

class BulkInputBar extends React.Component<any, any> {
  generateCheckboxLabel = () => {
    if (this.props.allStoriesSelected || this.props.tabCount === this.props.bulkSelectedStoryIds.length) {
      return t("workspace.bulk_input_bar.all_stories_selected");
    } else {
      return t("workspace.bulk_input_bar.story_selected", { count: this.props.bulkSelectedStoryIds.length });
    }
  };

  toggleBulkInspector = () => {
    this.props.toggleBulkActionsInspector(this.props.selectedTabSlug);
  };

  render() {
    return (
      <div className={styles["bulk-input-container"]} data-test-id="bulk-input-container">
        <div className={styles["stories-select"]} data-test-id="stories-select">
          <Checkbox
            id="bulk-count"
            label={this.generateCheckboxLabel()}
            checked={this.props.bulkSelected || this.props.allStoriesSelected}
            onChange={this.props.toggleBulkSelect}
          />
          {this.props.bulkSelected &&
            this.props.tabCount > this.props.bulkSelectedStoryIds.length &&
            !this.props.allStoriesSelected && (
              <h5
                className={styles["select-all"]}
                data-test-id="select-all-btn"
                onClick={() => this.props.selectAllStories()}>
                {`(${t("workspace.bulk_input_bar.select_all_stories_with_count", {
                  count: this.props.tabCount
                })})`}
              </h5>
            )}
        </div>
        <div className={styles["action-buttons"]} data-test-id="action-buttons">
          <Button type="secondary" onClick={() => this.props.resetBulkSelect()} testId="bulk-input-bar-reset-btn">
            {t("workspace.bulk_input_bar.reset")}
          </Button>
          <Button type="secondary" onClick={() => this.toggleBulkInspector()} testId="bulk-input-bar-action-btn">
            {t("workspace.bulk_input_bar.action")}
          </Button>
          <span
            className={styles["close-bulk-action"]}
            role="button"
            onClick={() => this.props.disableBulkAction()}
            data-test-id="close-bulk-action-btn">
            <Close />
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    stories: state.workspace.stories,
    bulkSelected: state.workspace.bulkSelected,
    allStoriesSelected: state.workspace.allStoriesSelected,
    bulkSelectedStoryIds: state.workspace.bulkSelectedStoryIds
  };
};

const mapDispatchToProps = (dispatch: any) => {
  const navigate = navigateFn(dispatch);

  return {
    toggleBulkActionsInspector: (tabSlug: any) => navigate(WORKSPACE_BULK_ACTION_PATH, { tabSlug }),
    selectAllStories: () => dispatch({ type: actions.WORKSPACE_SELECT_ALL_STORIES }),
    toggleBulkSelect: (checked: any) =>
      dispatch({ type: actions.WORKSPACE_BULK_SELECT_STORIES, bulkSelected: checked }),
    resetBulkSelect: () => dispatch({ type: actions.WORKSPACE_RESET_BULK_ACCESS_LEVEL_VALUE }),
    disableBulkAction: () => dispatch({ type: actions.WORKSPACE_DISABLE_BULK_ACTION })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkInputBar);

export { BulkInputBar };
