/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { map } from "lodash";
import { ThunkDispatch } from "redux-thunk";
import throttle from "helpers/throttle";
import { s3FileUpload } from "helpers/polltype-s3-gateway";
import { getImageMetadataWithType } from "utils/image-metadata.utils";

const s3UploadQueue = throttle(s3FileUpload);

const uploadWithPromise = (file: any) => {
  return new Promise((resolve, reject) => {
    s3UploadQueue.queueJob(file, function(result: any) {
      if (result.success) {
        getImageMetadataWithType(result).then(function(metadata) {
          resolve({
            success: true,
            "temporary-key": result.key,
            url: result.url,
            metadata: { ...metadata, "file-size": file.size, "file-name": file.name }
          });
        });
      } else {
        reject({ success: false });
      }
    });
  });
};

const startUpload = (
  files: any,
  imageUploadStatus: any,
  errorCallback: any,
  dispatch: ThunkDispatch<any, any, any>
) => {
  const alwaysResolvingPromises = map(files, (file) =>
    uploadWithPromise(file).then(
      (res) => res,
      (err) => {
        errorCallback();
        return err;
      }
    )
  );

  return Promise.all(alwaysResolvingPromises).then((uploads) => {
    dispatch(imageUploadStatus(false));
    return uploads;
  });
};

const uploadImage = (
  files: any,
  imageUploadStatus: any,
  setPollMedia: any,
  errorCallback: any,
  dispatch: ThunkDispatch<any, any, any>,
  { storyId, storyElementId }: any
) => {
  startUpload(files, imageUploadStatus, errorCallback, dispatch)
    .then((images) => {
      dispatch(setPollMedia(storyId, storyElementId, images));
    })
    .finally();
};

export default uploadImage;
