/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import EXIF from "exif-js";
import { Image as MetadataImage, ImageMetadata, ImageDimensions } from "api/search-media-image";
import { CoverImage } from "api/story-collection";
import { SavedImage } from "components/image-field/types";
import assign from "lodash/assign";
import { UploadFileResponse } from "helpers/s3-gateway";
import { get } from "lodash";

export interface ExifData {
  ImageDescription?: string;
  Make?: string;
  Model?: string;
  Artist?: string;
  Copyright?: string;
  GPSLatitudeRef?: string;
  GPSLongitudeRef?: string;
  GPSLatitude?: Array<number>;
  GPSLongitude?: Array<number>;
}

export interface ImageLocation {
  lat: number;
  lon: number;
}

export interface ImageFileName {
  fileName: string;
}

export interface ExtractedData {
  artist: string;
  location?: null | ImageLocation;
}

const convertDMSToDD = (degrees: number, minutes: number, seconds: number, direction: string) => {
  let dd = degrees + minutes / 60 + seconds / (60 * 60);
  if (direction === "S" || direction === "W") {
    dd = dd * -1;
  }
  return dd;
};

export const convertToLatLng = ({ GPSLatitudeRef, GPSLongitudeRef, GPSLatitude, GPSLongitude }: ExifData) => {
  if (
    GPSLatitudeRef &&
    GPSLongitudeRef &&
    GPSLatitude &&
    GPSLongitude &&
    Array.isArray(GPSLongitude) &&
    GPSLatitude.length === 3 &&
    Array.isArray(GPSLongitude) &&
    GPSLongitude.length === 3
  ) {
    return {
      lat: convertDMSToDD(GPSLatitude[0], GPSLatitude[1], GPSLatitude[2], GPSLatitudeRef),
      lon: convertDMSToDD(GPSLongitude[0], GPSLongitude[1], GPSLongitude[2], GPSLongitudeRef)
    };
  } else {
    return null;
  }
};

const getImageMetadata = (data: ExifData, enableEXIFLocation: boolean): ExtractedData => {
  const artist = removeNullCharacter(data.Artist || "");
  if (enableEXIFLocation) {
    return { artist: artist, location: convertToLatLng(data) };
  } else {
    return { artist };
  }
};

const removeNullCharacter = (str: string) => {
  if (str === null || str === "") return "";
  else str = str.toString();
  return str.replace(/\0.*$/g, "");
};

export const extractMetadata = async (file: File, enableEXIFLocation: boolean): Promise<ExtractedData | null> => {
  const temporaryFileReader = new FileReader();
  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      resolve(null);
    };

    temporaryFileReader.onloadend = () => {
      const data: ExifData = EXIF.readFromBinaryFile(temporaryFileReader.result);
      const metadata = getImageMetadata(data, enableEXIFLocation);
      resolve(metadata);
    };
    temporaryFileReader.readAsArrayBuffer(file);
  });
};

export const getImageData = async (image: MetadataImage | SavedImage | CoverImage | null) => {
  if (image) {
    let imageDimensions = { width: get(image, "metadata.width"), height: get(image, "metadata.height") };
    if (!imageDimensions.width || !imageDimensions.height) {
      imageDimensions = await getImageDimensions(image["url"]);
      image.metadata = {
        ...image.metadata,
        ...imageDimensions
      };
    }
    const focusPoint = get(image, "metadata.focus-point")
      ? {
          x: get(image, "metadata.focus-point.0"),
          y: get(image, "metadata.focus-point.1")
        }
      : null;
    return { imageDimensions, focusPoint };
  }
  return { imageDimensions: null, focusPoint: null };
};

export const getImageDimensions = (url?: string): Promise<ImageDimensions> => {
  // Thumbor will limit the image resolution width 2048 and height to 1366 by default
  // To avoid this passing a dummy query param to the url
  url = `${url}?dummy=value`;
  return new Promise(function(resolve, reject) {
    let img = new Image();
    if (url) img.src = url;
    img.onload = function() {
      resolve({
        width: img.width,
        height: img.height
      });
    };
    img.onerror = () => reject();
  });
};

export const getImageMetadataWithType = (
  uploadedImage: UploadFileResponse
): Promise<ImageDimensions & Pick<ImageMetadata, "mime-type">> => {
  return getImageDimensions(uploadedImage.url).then(function(dimensions) {
    return assign(dimensions, { "mime-type": uploadedImage["mime-type"] });
  });
};

export function getMetadataWithDimensions(image: MetadataImage | SavedImage): Promise<ImageMetadata> {
  return new Promise(function(resolve) {
    var metadata = image.metadata || { width: 0, height: 0 };
    if (metadata.width && metadata.height) {
      resolve(metadata);
    } else {
      getImageDimensions(image.url).then(function(dimensions) {
        resolve(Object.assign({}, metadata, dimensions));
      });
    }
  });
}
