/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import INITIAL_STATE, { INITIAL_TIMELINE_EVENT, INITIAL_TIMELINE } from "./initial-state";
import { actions } from "../actions";
import { Timeline, TimelineEvent } from "api/activity-log";

type TimelineActionType =
  | { type: actions.APPEND_TO_TIMELINE; payload: Timeline }
  | { type: actions.SET_TIMELINE_EVENT; payload: { event: TimelineEvent } }
  | { type: actions.RESET_TIMELINE };

function timelineReducer(state = INITIAL_STATE, action: TimelineActionType) {
  switch (action.type) {
    case actions.APPEND_TO_TIMELINE:
      return {
        ...state,
        timeline: {
          ...state.timeline,
          events: state.timeline.events.concat(action.payload.events),
          page: action.payload.page
        }
      };
    case actions.SET_TIMELINE_EVENT:
      return {
        ...state,
        ui: {
          ...state.ui,
          timelineEvent: action.payload.event
        }
      };
    case actions.RESET_TIMELINE:
      return {
        ...state,
        timeline: INITIAL_TIMELINE,
        ui: {
          ...state.ui,
          timelineEvent: INITIAL_TIMELINE_EVENT
        }
      };
    default:
      return state;
  }
}

export default timelineReducer;
