/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const ExclamationTriangle = ({ width = "24", height = "24", color = "var(--white)" }) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h100v100H0z" />
      <path
        d="M56.428 16.79a12.374 12.374 0 014.18 4.158l27.594 45.31c3.55 5.83 1.68 13.421-4.179 16.955A12.45 12.45 0 0177.593 85H22.405C15.554 85 10 79.473 10 72.656c0-2.256.621-4.469 1.796-6.398l27.595-45.31c3.55-5.83 11.179-7.692 17.037-4.159zM50 65a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zm0-30a2.5 2.5 0 00-2.5 2.5V60a2.5 2.5 0 105 0V37.5A2.5 2.5 0 0050 35z"
        fill={color}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default ExclamationTriangle;
