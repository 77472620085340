/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction, compose } from "redux";
import { localesMapping, t } from "i18n";
import styles from "./language-settings.module.css";
import { EditorLanguage, WebsiteLanguage } from "api/publisher";
import {
  PartialAppState,
  PublisherSettings,
  updatePublisherSettings,
  removePublisherSettings,
  loadPublisherSettings
} from "store/secret-mode/secret-mode";
import { enableSaveButton } from "store/secret-mode/secret-mode";
import TextField from "components/text-field/text-field";
import SettingsContainer from "components/settings/settings-container/settings-container";
import SettingsSection from "components/settings/settings-section/settings-section";
import Spinner from "components/spinner/spinner";
import Select from "components/select/select";

interface StateProps {
  isLoading: boolean;
  loadingFailed: boolean;
  websiteLanguage?: Partial<WebsiteLanguage>;
  editorLanguage?: Partial<EditorLanguage>;
}

interface DispatchProps {
  loadPublisherSettings: VoidFunction;
  updatePublisherSettings: (settings: PublisherSettings) => void;
  unloadPublisher: (publisher: string[]) => void;
}

type LanguageSettingsProps = StateProps & DispatchProps;

const directionMapping = {
  ltr: "Left-to-Right",
  rtl: "Right-to-Left"
};

const directions = [
  { value: "ltr", name: directionMapping["ltr"] },
  { value: "rtl", name: directionMapping["rtl"] }
];

const availableEditorLanguages = Object.keys(localesMapping).map((locale) => ({ name: locale, value: locale }));

export const LanguageSettings: React.FC<LanguageSettingsProps> = ({
  isLoading,
  loadingFailed,
  websiteLanguage,
  editorLanguage,
  updatePublisherSettings,
  loadPublisherSettings,
  unloadPublisher
}) => {
  useEffect(() => {
    loadPublisherSettings();
    return () => {
      unloadPublisher(["website-language", "editor-language"]);
    };
  }, [loadPublisherSettings, unloadPublisher]);

  if (isLoading) {
    return <Spinner message={t("secret-mode.language.loading")} classname={styles["language-settings-spinner"]} />;
  }

  const {
    name: websiteLangName = "",
    "iso-code": websiteLangISOCode = "",
    "ietf-code": websiteLangIETFCode = "",
    direction: websiteLangDirection = ""
  } = websiteLanguage || {};

  const {
    "locale-display": editorLangLocaleDisplay = "",
    locale: editorLangLocale = "",
    dir: editorLangDirection = ""
  } = editorLanguage || {};

  return loadingFailed ? null : (
    <SettingsContainer>
      <SettingsSection title={t("secret-mode.language.website.title")}>
        <TextField
          label={t("secret-mode.language.website.name-label")}
          placeholder={t("secret-mode.language.website.name-placeholder")}
          value={websiteLangName}
          onChange={(value) => updatePublisherSettings({ "website-language": { ...websiteLanguage, name: value } })}
        />
        <TextField
          label={t("secret-mode.language.website.iso-code-label")}
          placeholder={t("secret-mode.language.website.iso-code-placeholder")}
          value={websiteLangISOCode}
          onChange={(value) =>
            updatePublisherSettings({ "website-language": { ...websiteLanguage, "iso-code": value } })
          }
        />
        <TextField
          label={t("secret-mode.language.website.ietf-code-label")}
          placeholder={t("secret-mode.language.website.ietf-code-placeholder")}
          value={websiteLangIETFCode}
          onChange={(value) =>
            updatePublisherSettings({ "website-language": { ...websiteLanguage, "ietf-code": value } })
          }
        />
        <Select
          label={t("secret-mode.language.website.direction-label")}
          options={directions}
          getOptionLabel={(direction) => direction.name}
          getOptionValue={(direction) => direction.value}
          value={{ value: websiteLangDirection, name: directionMapping[websiteLangDirection] || "" }}
          onChange={(direction: { name: string; value: string }) =>
            updatePublisherSettings({
              "website-language": { ...websiteLanguage, direction: direction && direction.value }
            })
          }
        />
      </SettingsSection>
      <SettingsSection title={t("secret-mode.language.editor.title")}>
        <TextField
          label={t("secret-mode.language.editor.name-label")}
          placeholder={t("secret-mode.language.editor.name-placeholder")}
          value={editorLangLocaleDisplay}
          onChange={(value) =>
            updatePublisherSettings({ "editor-language": { ...editorLanguage, "locale-display": value } })
          }
        />
        <Select
          label={t("secret-mode.language.editor.locale-label")}
          options={availableEditorLanguages}
          getOptionLabel={(languageCode) => languageCode.name}
          getOptionValue={(languageCode) => languageCode.value}
          value={{ value: editorLangLocale, name: editorLangLocale || "" }}
          onChange={(selectedEditorLanguage: { name: string; value: string }) => {
            return updatePublisherSettings({
              "editor-language": { ...editorLanguage, locale: selectedEditorLanguage.value }
            });
          }}
        />
        <Select
          label={t("secret-mode.language.editor.direction-label")}
          options={directions}
          getOptionLabel={(direction) => direction.name}
          getOptionValue={(direction) => direction.value}
          value={{ value: editorLangDirection, name: directionMapping[editorLangDirection] || "" }}
          onChange={(direction: { name: string; value: string }) =>
            updatePublisherSettings({
              "editor-language": { ...editorLanguage, dir: direction && direction.value }
            })
          }
        />
      </SettingsSection>
    </SettingsContainer>
  );
};

const mapStateToProps = ({ secretMode: { app, ui } }: PartialAppState) => {
  return {
    isLoading: ui.isLoading,
    loadingFailed: ui.loadingFailed,
    websiteLanguage: app.publisher["website-language"],
    editorLanguage: app.publisher["editor-language"]
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => ({
  loadPublisherSettings: () => dispatch(loadPublisherSettings()),
  updatePublisherSettings: (settings) => {
    dispatch(updatePublisherSettings(settings));
    dispatch(enableSaveButton());
  },
  unloadPublisher: (publisher) => dispatch(removePublisherSettings(publisher))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(LanguageSettings);
