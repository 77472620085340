/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { signFile } from "api/sign";
import { uploadFileDetails } from "./api";

export interface UploadedFile {
  key: string;
  url: string;
  "file-name": string;
  "file-size": number;
  "mime-type": string;
  success: boolean;
}

export const uploadFile = async (file: File, callback: (arg0: UploadedFile) => void) => {
  const signature = await signFile(file);
  const key = signature.key;
  const formData = {
    key: signature["key"],
    "Content-Type": signature["Content-Type"],
    success_action_status: signature["success_action_status"],
    policy: signature["policy"],
    AWSAccessKeyId: signature["AWSAccessKeyId"],
    signature: signature["signature"],
    acl: signature["acl"],
    file: file
  };

  await uploadFileDetails(signature.action, formData);

  callback({
    key,
    url: signature.action + key,
    "file-name": file.name,
    "file-size": file.size,
    "mime-type": file.type,
    success: true
  });
};
