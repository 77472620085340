/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { RoleTypes, AnyRole } from "api/roles";
import { difference } from "lodash";
import produce from "immer";

export function getEligibleParents(roles: RoleTypes[], currentRole: AnyRole) {
  if ("id" in currentRole) {
    const currentRoleChildren = roles.filter((role) => role["parent-role-id"] === currentRole.id);
    const eligibleParents =
      currentRoleChildren.length > 0
        ? difference(roles, currentRoleChildren).map((role) => ({ id: role.id, name: role.name }))
        : roles.map((role) => ({ id: role.id, name: role.name }));
    const parents = eligibleParents.filter((role) => role.id !== currentRole.id);
    return parents;
  } else {
    return roles.map((role) => ({ id: role.id, name: role.name }));
  }
}

export function updateRoleWithCategoriesActions(
  role: AnyRole,
  selectedActionCategoryTab: string,
  actionName: string,
  value: boolean
) {
  const selectedCategoryActions = role["action-categories"][selectedActionCategoryTab] || [];
  return produce(role, (draft) => {
    if (value) {
      draft["action-categories"][selectedActionCategoryTab] = [...selectedCategoryActions, actionName];
    } else {
      draft["action-categories"][selectedActionCategoryTab] = selectedCategoryActions.filter(
        (action: string) => action !== actionName
      );
    }
  });
}
