/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ContributorRole } from "api/contributor-roles";
import { AnyContributorRole } from "./state";

enum actions {
  LOAD_CONTRIBUTOR_ROLES = "@@setings/LOAD_CONTRIBUTOR_ROLES",
  MAYBE_ADD_CONTRIBUTOR_ROLE = "@@setings/MAYBE_ADD_CONTRIBUTOR_ROLE",
  UPDATE_CURRENT_CONTRIBUTOR_ROLE = "@@settings/UPDATE_CURRENT_CONTRIBUTOR_ROLE",
  UPDATE_CURRENT_CONTRIBUTOR_ROLE_FAILURE = "@@settings/UPDATE_CURRENT_CONTRIBUTOR_ROLE_FAILURE",
  SAVE_CONTRIBUTOR_ROLE = "@@settings/SAVE_CONTRIBUTOR_ROLE",
  SAVE_CONTRIBUTOR_ROLE_FAILURE = "@@settings/SAVE_CONTRIBUTOR_ROLE_FAILURE",
  MAYBE_EDIT_CONTRIBUTOR_ROLE = "@@settings/MAYBE_EDIT_CONTRIBUTOR_ROLE",
  UPDATE_CONTRIBUTOR_ROLE = "@@settings/UPDATE_CONTRIBUTOR_ROLE",
  MAYBE_DELETE_CONTRIBUTOR_ROLE = "@@settings/MAYBE_DELETE_CONTRIBUTOR_ROLE",
  DELETE_CONTRIBUTOR_ROLE_SUCCESS = "@@settings/DELETE_CONTRIBUTOR_ROLE_SUCCESS",
  CANCEL_DELETE_CONTRIBUTOR_ROLE = "@@settings/CANCEL_DELETE_CONTRIBUTOR_ROLE",
  CANCEL_SAVE_CONTRIBUTOR_ROLE = "@@settings/CANCEL_SAVE_CONTRIBUTOR_ROLE",
  CANCEL_UPDATE_CONTRIBUTOR_ROLE = "@@settings/CANCEL_UPDATE_CONTRIBUTOR_ROLE"
}

export default actions;

export type loadContributorRoles = {
  type: actions.LOAD_CONTRIBUTOR_ROLES;
  payload: ContributorRole[];
};

export type maybeAddContributorRole = {
  type: actions.MAYBE_ADD_CONTRIBUTOR_ROLE;
  payload: {
    allContributorRoles: ContributorRole[] | null;
  };
};

export type updateCurrentContributorRole = {
  type: actions.UPDATE_CURRENT_CONTRIBUTOR_ROLE;
  payload: AnyContributorRole;
};

export type saveContributorRole = {
  type: actions.SAVE_CONTRIBUTOR_ROLE;
};

export type saveContributorRoleFailure = {
  type: actions.SAVE_CONTRIBUTOR_ROLE_FAILURE;
};

export type cancelSaveContributorRole = {
  type: actions.CANCEL_SAVE_CONTRIBUTOR_ROLE;
};

export type maybeEditContributorRole = {
  type: actions.MAYBE_EDIT_CONTRIBUTOR_ROLE;
  payload: {
    currentContributorRole: ContributorRole;
  };
};

export type updateContributorRole = {
  type: actions.UPDATE_CONTRIBUTOR_ROLE;
};

export type updateContributorRoleFailure = {
  type: actions.UPDATE_CURRENT_CONTRIBUTOR_ROLE_FAILURE;
};

export type cancelUpdateContributorRole = {
  type: actions.CANCEL_UPDATE_CONTRIBUTOR_ROLE;
};

export type maybeDeleteContributorRole = {
  type: actions.MAYBE_DELETE_CONTRIBUTOR_ROLE;
  payload: ContributorRole;
};

export type deleteContributorRoleSuccess = {
  type: actions.DELETE_CONTRIBUTOR_ROLE_SUCCESS;
};

export type cancelDeleteContributorRole = {
  type: actions.CANCEL_DELETE_CONTRIBUTOR_ROLE;
};

export type ActionTypes =
  | loadContributorRoles
  | maybeAddContributorRole
  | updateCurrentContributorRole
  | updateContributorRoleFailure
  | saveContributorRole
  | saveContributorRoleFailure
  | cancelSaveContributorRole
  | maybeEditContributorRole
  | updateContributorRole
  | cancelUpdateContributorRole
  | maybeDeleteContributorRole
  | deleteContributorRoleSuccess
  | cancelDeleteContributorRole;
