/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { BreakingNews, ValidationErrors, BreakingNewsValidationFields } from "api/breaking-news";
import { LinkType } from "./constants";
import { StoryTemplateFields, PartialStoryTemplateFields, SocialAccount } from "./state";
import { Story } from "api/story";

enum actions {
  "FETCH_SOCIAL_ACCOUNTS" = "@@breaking-news/FETCH_SOCIAL_ACCOUNTS",
  "INITIALIZE_NEW_BREAKING_NEWS" = "@@breaking-news/INITIALIZE_NEW_BREAKING_NEWS",
  "SET_LINK_TYPE" = "@@breaking-news/SET_LINK_TYPE",
  "UPDATE_SOCIAL_ACCOUNTS" = "@@breaking-news/UPDATE_SOCIAL_ACCOUNTS",
  "LOAD_BREAKING_NEWS_STORY" = "@@breaking-news/LOAD_BREAKING_NEWS_STORY",
  "BREAKING_NEWS_STORY_LOAD_SUCCESS" = "@@breaking-news/BREAKING_NEWS_STORY_LOAD_SUCCESS",
  "BREAKING_NEWS_STORY_LOAD_ERROR" = "@@breaking-news/BREAKING_NEWS_STORY_LOAD_ERROR",
  "UPDATE_BREAKING_NEWS" = "@@breaking-news/UPDATE_BREAKING_NEWS",
  "UPDATE_STORY_TEMPLATE_FIELDS" = "@@breaking-news/UPDATE_STORY_TEMPLATE_FIELDS",
  "SHOW_SOCIAL_ACCOUNTS" = "@@breaking-news/SHOW_SOCIAL_ACCOUNTS",
  "SHOW_ADVANCED_OPTIONS" = "@@breaking-news/SHOW_ADVANCED_OPTIONS",
  "SAVE_STORY_TEMPLATE_FIELDS" = "@@breaking-news/SAVE_STORY_TEMPLATE_FIELDS",
  "ENABLE_BREAKING_NEWS_PUBLISH" = "@@breaking-news/ENABLE_BREAKING_NEWS_PUBLISH",
  "SAVE_BREAKING_NEWS" = "@@breaking-news/SAVE_BREAKING_NEWS",
  "BREAKING_NEWS_SAVE_ERROR" = "@@breaking-news/BREAKING_NEWS_SAVE_ERROR",
  "BREAKING_NEWS_SAVE_SUCCESS" = "@@breaking-news/BREAKING_NEWS_SAVE_SUCCESS",
  "CLEAN_UP_BREAKING_NEWS_STATE" = "@@breaking-news/CLEAN_UP_BREAKING_NEWS_STATE"
}

export type FetchSocialAccounts = {
  type: actions.FETCH_SOCIAL_ACCOUNTS;
  payload: {
    socialAccounts: SocialAccount[];
    isSocialAccountsAvailable: boolean;
  };
};

export type InitializeNewBreakingNews = {
  type: actions.INITIALIZE_NEW_BREAKING_NEWS;
  payload: {
    breakingNews: BreakingNews;
    linkType: LinkType;
  };
};

export type UpdateBreakingNews = {
  type: actions.UPDATE_BREAKING_NEWS;
  payload: {
    breakingNews: BreakingNews;
  };
};

export type SetLinkType = {
  type: actions.SET_LINK_TYPE;
  payload: {
    linkType: LinkType;
    breakingNews: BreakingNews;
  };
};

export type ShowSocialAccounts = {
  type: actions.SHOW_SOCIAL_ACCOUNTS;
  payload: {
    showSocialAccounts: boolean;
  };
};

export type UpdateSocialAccounts = {
  type: actions.UPDATE_SOCIAL_ACCOUNTS;
  payload: {
    socialAccounts: SocialAccount[];
  };
};

export type UpdateStoryTemplateFields = {
  type: actions.UPDATE_STORY_TEMPLATE_FIELDS;
  payload: {
    storyTemplateFields: StoryTemplateFields;
  };
};

export type SaveStoryTemplateFields = {
  type: actions.SAVE_STORY_TEMPLATE_FIELDS;
  payload: {
    storyTemplateFields: PartialStoryTemplateFields;
  };
};

export type EnableBreakingNewsPublish = {
  type: actions.ENABLE_BREAKING_NEWS_PUBLISH;
  payload: {
    enableBreakingNewsPublish: boolean;
  };
};

export type SaveBreakingNews = {
  type: actions.SAVE_BREAKING_NEWS;
};

export type BreakingNewsSaveSuccess = {
  type: actions.BREAKING_NEWS_SAVE_SUCCESS;
};

export type BreakingNewsSaveError = {
  type: actions.BREAKING_NEWS_SAVE_ERROR;
  payload: {
    validationErrors: ValidationErrors<BreakingNewsValidationFields>;
  };
};

export type LoadBreakingNewsStory = {
  type: actions.LOAD_BREAKING_NEWS_STORY;
};

export type BreakingNewsStoryLoadSuccess = {
  type: actions.BREAKING_NEWS_STORY_LOAD_SUCCESS;
  payload: {
    breakingNewsStory: Story;
    breakingNews: BreakingNews;
  };
};

export type BreakingNewsStoryLoadError = {
  type: actions.BREAKING_NEWS_STORY_LOAD_ERROR;
  payload: {
    error: any;
  };
};

export type CleanUpBreakingNewsState = {
  type: actions.CLEAN_UP_BREAKING_NEWS_STATE;
};

export type ActionTypes =
  | FetchSocialAccounts
  | InitializeNewBreakingNews
  | UpdateBreakingNews
  | SetLinkType
  | ShowSocialAccounts
  | UpdateSocialAccounts
  | UpdateStoryTemplateFields
  | SaveStoryTemplateFields
  | EnableBreakingNewsPublish
  | SaveBreakingNews
  | BreakingNewsSaveSuccess
  | BreakingNewsSaveError
  | LoadBreakingNewsStory
  | BreakingNewsStoryLoadSuccess
  | BreakingNewsStoryLoadError
  | CleanUpBreakingNewsState;

export default actions;
