/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { Route, Switch } from "react-router-dom";
import PushNotificationWithRoutes from "./push-notification";
import {
  PUSH_NOTIFICATION_LISTING_PATH,
  PUSH_NOTIFICATION_NEW_PATH,
  PUSH_NOTIFICATION_INDEX_PATH,
  PUSH_NOTIFICATION_EDIT_PATH
} from "./routes";
import { TabValue, getTabValueFromString } from "./tab-list";

export const Router = () => (
  <React.Fragment>
    <Switch>
      <Route
        exact
        path={PUSH_NOTIFICATION_INDEX_PATH}
        render={(props) => <PushNotificationWithRoutes {...props} tabType={TabValue.ALL} />}
      />

      <Route
        exact
        path={PUSH_NOTIFICATION_LISTING_PATH}
        render={(props) => (
          <PushNotificationWithRoutes {...props} tabType={getTabValueFromString(props.match.params.type)} />
        )}
      />

      <Route
        exact
        path={PUSH_NOTIFICATION_NEW_PATH}
        render={(props) => (
          <PushNotificationWithRoutes {...props} tabType={getTabValueFromString(props.match.params.type)} isNew />
        )}
      />
      <Route
        exact
        path={PUSH_NOTIFICATION_EDIT_PATH}
        render={(props) => (
          <PushNotificationWithRoutes
            {...props}
            tabType={getTabValueFromString(props.match.params.type)}
            pushNotificationId={props.match.params["pushNotificationId"]}
            isEdit
          />
        )}
      />
    </Switch>
  </React.Fragment>
);
