/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const MoreVertical = ({ width = 24, height = 24, color = "#5f6978" }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    fill={color}>
    <defs />
    <path fill="none" d="M0 0h24v24H0z" id="Bounding_Boxes" data-name="Bounding Boxes" />
    <path
      d="M12 8a2 2 0 1 0-2-2 2 2 0 0 0 2 2zm0 2a2 2 0 1 0 2 2 2 2 0 0 0-2-2zm0 6a2 2 0 1 0 2 2 2 2 0 0 0-2-2z"
      id="Outline"
    />
  </svg>
);

export default MoreVertical;
