/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const FirstPublishedClock = ({ width = "16", height = "16", color = "var(--mono-5)" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill={color}>
    <path d="M8 14.6666C7.07778 14.6666 6.21111 14.4916 5.4 14.1416C4.58889 13.7916 3.88333 13.3166 3.28333 12.7166C2.68333 12.1166 2.20833 11.4111 1.85833 10.6C1.50833 9.78887 1.33333 8.9222 1.33333 7.99998C1.33333 7.07776 1.50833 6.21109 1.85833 5.39998C2.20833 4.58887 2.68333 3.88331 3.28333 3.28331C3.88333 2.68331 4.58889 2.20831 5.4 1.85831C6.21111 1.50831 7.07778 1.33331 8 1.33331C8.92222 1.33331 9.78889 1.50831 10.6 1.85831C11.4111 2.20831 12.1167 2.68331 12.7167 3.28331C13.3167 3.88331 13.7917 4.58887 14.1417 5.39998C14.4917 6.21109 14.6667 7.07776 14.6667 7.99998C14.6667 8.9222 14.4917 9.78887 14.1417 10.6C13.7917 11.4111 13.3167 12.1166 12.7167 12.7166C12.1167 13.3166 11.4111 13.7916 10.6 14.1416C9.78889 14.4916 8.92222 14.6666 8 14.6666ZM7.33333 13.3V12C6.96667 12 6.65278 11.8694 6.39166 11.6083C6.13055 11.3472 6 11.0333 6 10.6666V9.99998L2.8 6.79998C2.76667 6.99998 2.73611 7.19998 2.70833 7.39998C2.68055 7.59998 2.66667 7.79998 2.66667 7.99998C2.66667 9.34442 3.10833 10.5222 3.99167 11.5333C4.875 12.5444 5.98889 13.1333 7.33333 13.3ZM11.9333 11.6C12.1556 11.3555 12.3556 11.0916 12.5333 10.8083C12.7111 10.525 12.8583 10.2305 12.975 9.92498C13.0917 9.61942 13.1806 9.30553 13.2417 8.98331C13.3028 8.66109 13.3333 8.33331 13.3333 7.99998C13.3333 6.91109 13.0306 5.91665 12.425 5.01665C11.8194 4.11665 11.0111 3.46665 10 3.06665V3.33331C10 3.69998 9.86944 4.01387 9.60833 4.27498C9.34722 4.53609 9.03333 4.66665 8.66667 4.66665H7.33333V5.99998C7.33333 6.18887 7.26944 6.3472 7.14166 6.47498C7.01389 6.60276 6.85555 6.66665 6.66667 6.66665H5.33333V7.99998H9.33333C9.52222 7.99998 9.68055 8.06387 9.80833 8.19165C9.93611 8.31942 10 8.47776 10 8.66665V10.6666H10.6667C10.9556 10.6666 11.2167 10.7528 11.45 10.925C11.6833 11.0972 11.8444 11.3222 11.9333 11.6Z" />
  </svg>
);

export default FirstPublishedClock;
