/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

import { SeoStatItem } from "api/validator";

import Circle from "components/icons/circle";
import styles from "./seo-group.module.css";

interface SeoItemProps {
  items: string[];
  statType: string;
}

const markerColours = {
  warnings: "#f5a623",
  errors: "#ff214b",
  goodies: "#2fd072"
};

const SeoItem: React.SFC<SeoItemProps> = ({ items, statType }) => {
  return (
    <ul className={styles["seo-items-list"]}>
      {items &&
        items.length > 0 &&
        items.map((item) => (
          <li className={styles["seo-item"]} data-test-id="seo-comment-item">
            <span className={styles["seo-status-marker"]}>
              <Circle radius={5} fillColor={markerColours[statType]} strokeColor={markerColours[statType]} />
            </span>
            <span className={styles["seo-item-text"]}>{item}</span>
          </li>
        ))}
    </ul>
  );
};

interface Props {
  group: string;
  seoStatItem: SeoStatItem;
}

const SeoGroup: React.SFC<Props> = ({ group, seoStatItem }) => {
  const itemkeys = Object.keys(seoStatItem);
  return (
    <div className={styles["seo-stat-group"]}>
      <h4 className={styles["stat-group-title"]} data-test-id="stat-group-title">
        {group}
      </h4>
      <div className={styles["stat-group-items"]} data-test-id="stat-group-items">
        {itemkeys.map((key) => {
          return <SeoItem items={seoStatItem[key]} statType={key} />;
        })}
      </div>
    </div>
  );
};

export default SeoGroup;
