/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

/*
Errors cannot be extended in ES6 to create custom error classes easily,
so the convention is to write a custom base class that ensures the prototypal
chaining works correctly

See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#Custom_Error_Types
*/

export class ExtendableError extends Error {
  __proto__: Error;

  constructor(message?: string) {
    const trueProto = new.target.prototype;
    super(message);

    this.__proto__ = trueProto;
    this.name = new.target.name;
  }
}

export function parseError(error: Error) {
  try {
    return JSON.parse(error.message);
  } catch (e) {
    return null;
  }
}

export function getErrorCode(error: Error): string | null {
  const errorObj = parseError(error);
  if (errorObj) {
    return errorObj.error.errors ? errorObj.error.errors.code : errorObj.error.code;
  }
  return null;
}

export function getErrorMessage(error: Error, fallbackMsg: string) {
  let errorObj = parseError(error);
  //TODO: move to I18n
  //TODO: I18n translate server error message
  if (errorObj) {
    return errorObj.error.message;
  }

  return fallbackMsg;
}

export const ErrorCodes = {
  UnexpectedError: "unexpected-error",
  InvalidScheduleCancel: "invalid-schedule-cancel",
  PublishTimeElapsed: "publish-time-elapsed",
  InvalidEmbargoPublish: "invalid-embargo-publish"
};

export function getErrorInfo(error: Error) {
  const errorObj = parseError(error);
  return !errorObj
    ? {
        error: error,
        errorMessage: "Unexpected error",
        errorCode: ErrorCodes.UnexpectedError
      }
    : {
        error: errorObj.error,
        errorMessage: errorObj.error && errorObj.error.message,
        errorCode: errorObj.error && errorObj.error.errors ? errorObj.error.errors.code : errorObj.error.code
      };
}
