/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { t } from "i18n";

import Inspector from "components/inspector/inspector";
import TextField from "components/text-field/text-field";
import { PartialAppState, AttributePageInspectorType } from "../../state";
import {
  Attribute,
  AttributeType,
  DataType,
  EntityAttributeLevel,
  CollectionAttributesDataType
} from "api/story-attributes";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction, compose } from "redux";
import { connect } from "react-redux";
import { cancelSaveAttribute, saveNewAttribute, updateExistingAttribute } from "../../async-action-creators";
import { replaceCurrentAttributeAction } from "../../action-creators";
import Select from "components/select/select";
import CreatableSelect from "components/select/creatable";
import { isClientValidationError } from "utils/validation.utils";
import RadioButton from "components/radio-button/radio-button";
import { EntityTypeName } from "api/primitive-types";
import FieldLabel from "components/field-label/field-label";
import ErrorMessage from "components/error-message/error-message";
import Checkbox from "components/checkbox/checkbox";
import styles from "./inspector.module.css";
import { addNameToAttribute } from "pages/manage/attributes/utils";
import TextArea from "components/text-area/text-area";
import StoryTemplateSelector, {
  templateSlugsToTemplateSelectorOptions,
  storyTemplateToSelectorOptions
} from "pages/manage/menu/components/story-template-selector/story-template-selector";
import { get } from "lodash";
import { StoryTemplate } from "api/route-data/story-route-data";

interface StateProps {
  type: AttributePageInspectorType | null;
  attribute: Attribute | null;
  saveError: Error | null;
  listEntityTypeError: Error | null;
  entityTypes: string[];
  attributes: Attribute[];
  storyTemplates: StoryTemplate[];
}

interface DispatchProps {
  saveNewAttribute: (attribute: Attribute) => void;
  updateExistingAttribute: (attribute: Attribute) => void;
  cancelSaveAttribute: (attribute: Attribute) => void;
  onChange: (attribute: Attribute) => void;
}

type Props = StateProps & DispatchProps;

function isValidAttributeValue(inputValue: string, selectOptions: string[]) {
  if (inputValue.trim().length === 0 || selectOptions.find((option) => option === inputValue)) {
    return false;
  }
  return true;
}

export const showEntityTypes = (
  attribute: Attribute,
  saveError: Error | null,
  listEntityTypeError: Error | null,
  entityTypes: string[],
  onChange: (attribute: Attribute) => void
) => {
  const entityTypeSelect = listEntityTypeError ? (
    <ErrorMessage message={t("attributes.inspector.could-not-load-entity-types")} />
  ) : (
    <Select
      label={t("attributes.inspector.values-entites-label")}
      value={attribute.values}
      options={entityTypes}
      onChange={(entityTypes: EntityTypeName[]) => onChange({ ...attribute, values: entityTypes })}
      getOptionLabel={(entity) => entity}
      getOptionValue={(entity) => entity}
      isMulti={true}
      errorMessage={
        isClientValidationError<Omit<Attribute, "values"> & { values: string }>(saveError)
          ? saveError.errors.values && saveError.errors.values[0]
          : undefined
      }
    />
  );
  return entityTypeSelect;
};

const AttributeInspector: React.SFC<Props> = ({
  attribute,
  onChange,
  saveNewAttribute,
  updateExistingAttribute,
  cancelSaveAttribute,
  type,
  entityTypes,
  listEntityTypeError,
  saveError,
  attributes,
  storyTemplates
}) => {
  const title =
      type === AttributePageInspectorType.Create
        ? t("attributes.inspector.create-title")
        : t("attributes.inspector.update-title"),
    actionButtonLabel =
      type === AttributePageInspectorType.Create ? t("attributes.cta.create") : t("attributes.cta.update");

  const isEnabledForAllTemplates = get(attribute, "enabled-for-all-templates", true);
  const selectedTemplates = get(attribute, "content-templates", []);

  return (
    <Inspector
      title={title}
      onClose={() => cancelSaveAttribute(attribute!)}
      isActive={!!type}
      actionButtonLabel={actionButtonLabel}
      onActionButtonClick={() =>
        type === AttributePageInspectorType.Create ? saveNewAttribute(attribute!) : updateExistingAttribute(attribute!)
      }>
      {attribute && (
        <React.Fragment>
          <TextField
            value={attribute["display-name"]}
            label={t("attributes.inspector.name-label")}
            onChange={(name) => {
              let updatedAttribute = { ...attribute, "display-name": name };
              if (type === AttributePageInspectorType.Create) {
                const existingNames = attributes.map((attrs) => attrs.name);
                updatedAttribute = addNameToAttribute(updatedAttribute, existingNames);
              }
              onChange(updatedAttribute);
            }}
            errorMessage={
              isClientValidationError<Attribute>(saveError)
                ? saveError.errors["display-name"] && saveError.errors["display-name"][0]
                : undefined
            }
          />
          <TextArea
            value={attribute["name"]}
            label={t("common.slug")}
            onChange={(name) => onChange({ ...attribute, name: name })}
            disabled={type === AttributePageInspectorType.Edit}
            errorMessage={
              isClientValidationError<Attribute>(saveError)
                ? saveError.errors.name && saveError.errors.name[0]
                : undefined
            }
          />
          <FieldLabel label={t("attributes.inspector.type-label")} />
          <div className={styles["attribute-type-container"]} data-test-id="attribute-type-container">
            {Object.values(AttributeType).map((value) => (
              <RadioButton
                id={`attribute-inspector-type-${value}`}
                name="attribute-inspector-type"
                key={`attribute-inspector-type-${value}`}
                label={t(`attributes.inspector.type-${value}-label`)}
                checked={attribute.type === value}
                onChange={() => onChange({ ...attribute, type: value })}
                classname={"attribute-inspector-radio-button"}
              />
            ))}
          </div>

          <Select
            label={t("attributes.inspector.data-type-label")}
            value={[attribute["data-type"]]}
            options={
              attribute.type === "collection"
                ? Object.values(CollectionAttributesDataType).sort()
                : Object.values(DataType).sort()
            }
            onChange={(dataType: DataType) =>
              onChange({
                ...attribute,
                ...(dataType === DataType.Entity && { "attribute-level": EntityAttributeLevel.Single }),
                "data-type": dataType,
                values: []
              })
            }
            getOptionLabel={(dataType: DataType) => t(`attributes.inspector.data-type-${dataType}-label`)}
            getOptionValue={(dataType: DataType) => dataType}
          />

          {attribute["data-type"] === DataType.Entity && (
            <React.Fragment>
              <FieldLabel label={t("attributes.inspector.attribute-level-label")} />
              <div className={styles["attribute-type-container"]}>
                {Object.values(EntityAttributeLevel).map((value) => (
                  <RadioButton
                    id={`attribute-inspector-attribute-level-${value}`}
                    name="attribute-inspector-attribute-level"
                    key={`attribute-inspector-attribute-level-${value}`}
                    label={t(`attributes.inspector.attribute-level-${value}-label`)}
                    checked={attribute["attribute-level"] === value}
                    onChange={() => onChange({ ...attribute, "attribute-level": value })}
                    classname={"attribute-inspector-radio-button"}
                  />
                ))}
              </div>
            </React.Fragment>
          )}

          {/* The values here are arrays of one item, because of https://github.com/JedWatson/react-select/issues/2669 */}
          {attribute["data-type"] === DataType.MultiValuedStrings && (
            <CreatableSelect
              label={t("attributes.inspector.data-type-multi-valued-strings-label")}
              value={attribute.values}
              options={[]}
              placeholder={t("attributes.inspector.add-attribute-values")}
              onChange={(values: string[]) => onChange({ ...attribute, values })}
              getOptionLabel={(value: string) => value}
              getOptionValue={(value: string) => value}
              getNewOptionData={(inputValue) => inputValue}
              isValidNewOption={isValidAttributeValue}
              isMulti={true}
              errorMessage={
                isClientValidationError<Omit<Attribute, "values"> & { values: string }>(saveError)
                  ? saveError.errors.values && saveError.errors.values[0]
                  : undefined
              }
            />
          )}

          {attribute["data-type"] === DataType.Entity &&
            showEntityTypes(attribute, saveError, listEntityTypeError, entityTypes, onChange)}

          {attribute["type"] === AttributeType.Story && (
            <>
              <Checkbox
                data-test-id="story-attribute-checkbox"
                id="story-attribute-checkbox"
                checked={isEnabledForAllTemplates}
                label={t("attributes.inspector.story-templates-checkbox-label")}
                onChange={(checked) => onChange({ ...attribute, "enabled-for-all-templates": checked })}
                disabled={false}
              />
              <br />
              {!isEnabledForAllTemplates && (
                <StoryTemplateSelector
                  data-test-id="attributes-story-templates-selector"
                  value={templateSlugsToTemplateSelectorOptions(selectedTemplates, storyTemplates)}
                  storyTemplates={storyTemplateToSelectorOptions(storyTemplates)}
                  onChange={(value) => {
                    const contentTemplates = value.map((item) => item.slug);
                    onChange({ ...attribute, "content-templates": contentTemplates });
                  }}
                />
              )}
            </>
          )}

          <Checkbox
            label={t("attributes.inspector.mandatory_label")}
            id="attribute-isMandatory"
            checked={attribute["is-mandatory"]}
            onChange={(checked) => onChange({ ...attribute, "is-mandatory": !attribute["is-mandatory"] })}
          />
        </React.Fragment>
      )}
    </Inspector>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    attribute: state.attributes.app.currentAttribute,
    type: state.attributes.ui.inspector,
    saveError: state.attributes.ui.save.error,
    entityTypes: state.attributes.ui.entityTypes,
    listEntityTypeError: state.attributes.ui.entityTypesError,
    attributes: state.attributes.attributes,
    storyTemplates: state.config.storyTemplates
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    saveNewAttribute: (attribute: Attribute) => dispatch(saveNewAttribute(attribute)),
    updateExistingAttribute: (attribute: Attribute) => dispatch(updateExistingAttribute(attribute)),
    cancelSaveAttribute: (attribute: Attribute) => dispatch(cancelSaveAttribute(attribute)),
    onChange: (attribute: Attribute) => dispatch(replaceCurrentAttributeAction(attribute))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AttributeInspector);

export { AttributeInspector };
