/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import classnames from "classnames/bind";
import * as React from "react";
import styles from "./error-message.module.css";

interface Props {
  message: string;
  visible?: boolean;
  classname?: string;
  "data-test-id"?: string;
}

const cx = classnames.bind(styles);

const ErrorMessage: React.SFC<Props> = ({ message, classname, "data-test-id": dataTestId }) => (
  <p data-test-id={dataTestId} className={cx("error-message", classname, { visible: message })}>
    {message}
  </p>
);

export default ErrorMessage;
