/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import Inspector from "components/inspector/inspector";
import { t } from "i18n";
import ImageUpload from "pages/media-library/components/image-upload/image-upload";
import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { goBack, navigateFn } from "utils/routes.utils";
import { actions } from "../../actions";
import { COLLECTIONS_CONTENT_SETTINGS_PATH, COLLECTIONS_SELECTED_COLLECTION_PATH } from "../../routes";

const ImageEditInspector = ({
  chooseCoverImage,
  closeSettingsInspector,
  coverImageUploading,
  goToPrevRoute,
  selectedCollectionCoverImages,
  isActive,
  stagingSelectedCollection,
  updateCollectionCoverImagesData,
  isStoryImageHyperlinkEnabled,
  fieldSpecs
}) => {
  return (
    <Inspector
      title={t("collections.edit_image")}
      actionButtonLabel={t("collections.choose")}
      onActionButtonClick={() => chooseCoverImage(stagingSelectedCollection.id)}
      isActive={isActive}
      onClose={() => closeSettingsInspector(stagingSelectedCollection.id)}
      variant="wide"
      level={2}>
      <ImageUpload
        selectedImages={selectedCollectionCoverImages}
        updateImageData={updateCollectionCoverImagesData}
        isUploading={coverImageUploading}
        onDeleteMedia={goToPrevRoute}
        showFields={false}
        isLinkAuthorEnabled={false}
        isStoryImageHyperlinkEnabled={isStoryImageHyperlinkEnabled}
        fieldSpecs={fieldSpecs}
      />
    </Inspector>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    stagingSelectedCollection: state.collections.collectionPage.stagingSelectedCollection,
    selectedCollectionCoverImages: state.collections.collectionPage.selectedCollectionCoverImages,
    coverImageUploading: state.collections.ui.coverImageUploading,
    isStoryImageHyperlinkEnabled: state.features.enableStoryImageHyperlink,
    fieldSpecs: state.storyEditor.storyTemplateFields
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const navigate = navigateFn(dispatch);

  return {
    closeSettingsInspector: (collectionId) => {
      dispatch({ type: actions.RESET_ERRORS });
      dispatch({ type: actions.RESET_STAGING_SELECTED_COLLECTION });
      navigate(COLLECTIONS_SELECTED_COLLECTION_PATH, { collectionId: collectionId || "new" });
    },
    goToPrevRoute: () => dispatch(goBack()),
    updateCollectionCoverImagesData: (key, value, imageIndex) =>
      dispatch({ type: actions.UPDATE_COVER_IMAGE_DATA, payload: { key, value, imageIndex } }),
    chooseCoverImage: (collectionId) => {
      dispatch({ type: actions.UPDATE_COVER_IMAGE });
      navigate(COLLECTIONS_CONTENT_SETTINGS_PATH, { collectionId: collectionId || "new" });
    }
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ImageEditInspector);
