/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Styles } from "react-select/lib/styles";
import { CSSProperties } from "react";

const appStyle = getComputedStyle(document.body);

const editorSpecificStyles: Styles = {
  control: (styles: CSSProperties) => {
    return {
      ...styles,
      borderColor: "transparent",
      "&:hover": { borderColor: "transparent", backgroundColor: appStyle.getPropertyValue("--mono-2") },
      borderWidth: 0,
      backgroundColor: "transparent",
      borderRadius: 0,
      borderStyle: "none",
      boxShadow: "none",
      minHeight: "3rem",
      padding: 0
    };
  },
  valueContainer: (styles: CSSProperties) => {
    return { ...styles, padding: 0 };
  },
  placeholder: (styles: CSSProperties) => {
    return {
      ...styles,
      fontSize: appStyle.getPropertyValue("--fs-xl"),
      color: appStyle.getPropertyValue("--mono-3")
    };
  },
  option: (
    styles: CSSProperties,
    { isFocused, isSelected }: { data: any; isDisabled: any; isFocused: any; isSelected: any }
  ) => {
    return {
      ...styles,
      backgroundColor: isFocused || isSelected ? appStyle.getPropertyValue("--mono-2") : "transparent"
    };
  },
  singleValue: (styles: CSSProperties) => {
    return { ...styles, color: appStyle.getPropertyValue("--mono-4"), margin: 0 };
  }
};

export default editorSpecificStyles;
