/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import {
  IntegrationListByType,
  IntegrationType,
  PartialAppState,
  Publisher
} from "pages/settings/pages/integrations/state";
import { connect } from "react-redux";
import TextField from "components/text-field/text-field";
import Select from "components/select/select";
import { Integrations } from "api/integrations";
import { capitalize, cloneDeep, set, get, toLower } from "lodash";
import CategoryTitle from "components/category-title/category-title";
import { t } from "i18n";

interface OwnProps {
  readOnly?: boolean;
  settings: Publisher;
  updateSettings: (settings) => void;
}

interface StateProps {
  integrationListByType: IntegrationListByType;
}

type Props = OwnProps & StateProps;

const getPushNotificationIntegrations = (integrationsByType: IntegrationListByType): Integrations[] => {
  if (integrationsByType) {
    const pushNotificationProviders = integrationsByType[IntegrationType.PUSH_NOTIFICATION];
    if (pushNotificationProviders) {
      return pushNotificationProviders
        .filter((integration) => !get(integration, ["value", "disabled"], false))
        .map((integration) => integration.key);
    }
  }
  return [];
};

const PushNotificationSettings: React.FC<Props> = ({ readOnly, integrationListByType, settings, updateSettings }) => {
  const { "push-notification": pushNotification } = settings;
  const pushNotificationIntegrations = getPushNotificationIntegrations(integrationListByType);
  const updatePushNotificationSettings = (key: "web" | "mobile" | "icon-url", value: string) => {
    if (key === "web" || key === "mobile") {
      updateSettings(set(cloneDeep(settings), [IntegrationType.PUSH_NOTIFICATION, "provider", key], value));
    } else if (key === "icon-url") {
      updateSettings(set(cloneDeep(settings), [IntegrationType.PUSH_NOTIFICATION, key], value));
    }
  };
  return (
    <>
      {pushNotification && (
        <>
          <CategoryTitle title={t("integrations.push-notification.settings.default-providers-category-title")} />
          <Select
            options={pushNotificationIntegrations}
            getOptionLabel={(integration: Integrations) =>
              t(`integrations.providers.${toLower(integration)}`, capitalize(`${integration}`) || "")
            }
            getOptionValue={(integration) => integration || ""}
            onChange={(value: Integrations) => updatePushNotificationSettings("web", value)}
            value={[get(pushNotification, ["provider", "web"])]}
            isDisabled={readOnly}
            label={t("integrations.push-notification.settings.web-provider-label")}
            isClearable={true}
            data-test-id={"web-provider"}
          />
          <Select
            options={pushNotificationIntegrations}
            getOptionLabel={(integration: Integrations) =>
              t(`integrations.providers.${toLower(integration)}`, capitalize(`${integration}`) || "")
            }
            getOptionValue={(integration) => integration || ""}
            onChange={(value: Integrations) => updatePushNotificationSettings("mobile", value)}
            value={[get(pushNotification, ["provider", "mobile"])]}
            isDisabled={readOnly}
            label={t("integrations.push-notification.settings.mobile-provider-label")}
            isClearable={true}
            data-test-id={"mobile-provider"}
          />
          <CategoryTitle title={t("integrations.push-notification.settings.icon-category-title")} />
          <TextField
            label={t("integrations.push-notification.settings.icon-url-label")}
            value={pushNotification["icon-url"] || ""}
            readOnly={readOnly}
            placeholder={t("integrations.push-notification.settings.icon-url-placeholder")}
            onChange={(value: string) => updatePushNotificationSettings("icon-url", value)}
            data-test-id={"icon-url"}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    integrationListByType: state.integrations.app.integrationListByType
  };
};

export default connect(mapStateToProps)(PushNotificationSettings);

export { PushNotificationSettings };
