/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const SECTIONS_INDEX_PATH = "/manage/sections";
export const SECTIONS_NEW_PATH = "/manage/sections/new";
export const SECTION_EDIT_PATH = "/manage/sections/:id";
export const SECTION_EDIT_WATERMARK_IMAGE = `${SECTION_EDIT_PATH}/watermark-image`;
