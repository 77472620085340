/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { t } from "i18n";
import ConfirmationModal from "components/confirmation-modal/confirmation-modal";

export enum FormModalType {
  Retract = "retract",
  Close = "close",
  Delete = "delete"
}

interface ConfirmationModalProps {
  modalType: FormModalType | null;
  closeModal: () => void;
  retractForm?: () => void;
  deleteForm?: () => void;
}

const FormModal: React.FC<ConfirmationModalProps> = ({ modalType, closeModal, retractForm, deleteForm }) => {
  if (!modalType) return null;

  const { headerText, text, onConfirm } = {
    [FormModalType.Retract]: {
      headerText: t("forms.modal.retract.header-text"),
      text: t("forms.modal.retract.text"),
      onConfirm: retractForm
    },
    [FormModalType.Delete]: {
      headerText: t("forms.modal.delete.header-text"),
      text: t("forms.modal.delete.text"),
      onConfirm: deleteForm
    }
  }[modalType];
  const confirmAndClose = () => {
    onConfirm && onConfirm();
    closeModal();
  };

  return (
    <ConfirmationModal
      showConfirmationModal={!!modalType}
      headerText={headerText}
      text={text}
      confirmButtonLabel={t("common.confirm")}
      cancelButtonLabel={t("common.cancel")}
      onConfirm={confirmAndClose}
      onCancel={closeModal}
      data-test-id={"form-confirmation-modal"}
    />
  );
};

export default FormModal;
