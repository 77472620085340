/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { LoaderState, INITIAL_LOADER_STATE } from "behaviors/loader/state";
import { ListCollectionItemsResponse, CollectionWrapper } from "api/collection-items";
import { CollectionTemplateOptions } from "api/template-options";
import { Attribute } from "api/story-attributes";
import {
  Collection,
  AssociatedMetadata,
  DataSource,
  CollectionType,
  UnsavedManualCollection,
  RulesSortAutomatedResultBy,
  RulesSortBy,
  CollectionItemWrapper,
  CoverImage
} from "api/story-collection";
import { Author as AuthorFromAuthorAPI } from "api/author";
import { Section as SectionFromSectionAPI } from "api/section";
import { Tag as TagFromTagAPI } from "api/tag";
import { Entity as EntityFromEntityAPI } from "api/entity";
import { CollectionId } from "api/primitive-types";
import { CollectionDateFilter } from "components/date-filter/types";
import { StoryTemplate, CollectionRouteData } from "api/route-data/collection-route-data";
import { Config } from "api/route-data/route-data";
import { Features } from "api/route-data/story-route-data";

export interface PartialAppState {
  features: Features;
  config: Config & CollectionRouteData;
  collections: State;
  viewport: {
    isDesktopSizeViewport: boolean;
  };
}

export interface RulesInput {
  authors: AuthorFromAuthorAPI[];
  "collection-id": CollectionId;
  fields: string;
  term?: string;
  sections: SectionFromSectionAPI[];
  sort: RulesSortBy;
  "sort-automated-result": RulesSortAutomatedResultBy;
  "story-template": string[];
  tags: TagFromTagAPI[];
  entities: EntityFromEntityAPI[];
  publishedAt?: CollectionDateFilter | null;
  storyTemplates?: StoryTemplate[];
  storyAttributes?: Attribute[];
  location: {
    "is-enabled": boolean;
    geoPolygon: Array<google.maps.LatLngLiteral>;
  };
  offset?: number;
}

interface AttributesFilterInput {
  [key: string]: string[];
}

export interface FilterInput {
  term: string;
  publishedAt: CollectionDateFilter | null;
  storyTemplates: StoryTemplate[];
  sections: SectionFromSectionAPI[];
  authors: AuthorFromAuthorAPI[];
  tags: TagFromTagAPI[];
  entities: EntityFromEntityAPI[];
  storyAttributes: Attribute[];
  "story-attributes"?: AttributesFilterInput;
  location: {
    "is-enabled": boolean;
    geoPolygon: Array<google.maps.LatLngLiteral>;
  };
  "author-id"?: string;
  offset?: number;
}

export const INITIAL_FILTER_SET: FilterInput = {
  term: "",
  publishedAt: null,
  storyTemplates: [],
  sections: [],
  authors: [],
  tags: [],
  entities: [],
  storyAttributes: [],
  location: {
    "is-enabled": false,
    geoPolygon: []
  }
};

export const NEW_COLLECTION: UnsavedManualCollection = {
  name: "",
  summary: "",
  type: CollectionType.Content,
  "story-content-ids": [],
  metadata: { "cover-image": null },
  access: null,
  "price-amount": null,
  "price-currency": null,
  "collection-date": null,
  items: [],
  "data-source": DataSource.Manual,
  template: "default",
  slug: "",
  "author-id": null,
  "publish-at": null,
  status: null
};

export enum SidebarListCollectionsType {
  All = "all",
  Section = "section",
  BreakingNews = "breaking-news"
}

export enum LHSTab {
  Stories = "story",
  DraftStories = "draft-story",
  Collections = "collection"
}

export interface SearchResults {
  story: ListCollectionItemsResponse;
  collection: ListCollectionItemsResponse;
  "draft-story": ListCollectionItemsResponse;
}

export interface PageViewFields {
  count: Number;
  delta: Number | null;
}

export interface PageViews {
  [key: string]: PageViewFields;
}

export type State = {
  storiesPageViews: PageViews | null;
  collectionPage: {
    collections: Array<CollectionWrapper>;
    selectedCollection: Collection | null;
    templateOptions: CollectionTemplateOptions;
    attributes: Attribute[] | null;
    searchResults: SearchResults;
    filters: FilterInput;
    selectedCollectionCoverImages: Array<CoverImage>;
    stagingSelectedCollection: Collection | null;
  };
  ui: {
    showSettingsInspector: boolean;
    selectedLaneTab: LHSTab;
    isRefreshingLHS: boolean;
    isAllItemsFiltersExpanded: boolean;
    showFileUploading: boolean;
    showPolygonSelection: boolean;
    uploadFileName: string;
    swappedItems: Array<CollectionItemWrapper> | null;
    pageViews: number | null;
    stagingAssociatedMetadata: AssociatedMetadata;
    currentCardExpandedIndex: number | null;
    selectedCollectionType: SidebarListCollectionsType;
    coverImageUploading: { uploading: boolean };
    isCollectionModified: boolean;
    errors: {};
    sidebar: {
      searchTerm: string;
      specifiedPage: number;
      totalCount: number;
    };
  };
  areas: {
    main: LoaderState;
    sidebar: LoaderState;
  };
  app: {
    photoEditor: {
      visible: boolean;
      selectedImage: CoverImage | null;
    };
    geoPolygon: Array<google.maps.LatLngLiteral>;
  };
};

export const INITIAL_STATE: State = {
  storiesPageViews: null,
  collectionPage: {
    collections: [],
    selectedCollection: null,
    templateOptions: { "collection-layouts": [] },
    attributes: null,
    searchResults: {
      story: { items: [], hits: 0 },
      collection: { items: [], hits: 0 },
      "draft-story": { items: [], hits: 0 }
    },
    filters: INITIAL_FILTER_SET,
    selectedCollectionCoverImages: [],
    stagingSelectedCollection: null
  },
  ui: {
    showSettingsInspector: false,
    selectedLaneTab: LHSTab.Stories,
    isRefreshingLHS: false,
    isAllItemsFiltersExpanded: false,
    showFileUploading: false,
    showPolygonSelection: false,
    uploadFileName: "",
    swappedItems: null,
    pageViews: null,
    stagingAssociatedMetadata: {},
    currentCardExpandedIndex: null,
    selectedCollectionType: SidebarListCollectionsType.All,
    coverImageUploading: { uploading: false },
    isCollectionModified: false,
    errors: {},
    sidebar: {
      searchTerm: "",
      specifiedPage: 1,
      totalCount: 0
    }
  },
  areas: {
    main: INITIAL_LOADER_STATE,
    sidebar: INITIAL_LOADER_STATE
  },
  app: {
    photoEditor: {
      visible: false,
      selectedImage: null
    },
    geoPolygon: []
  }
};
