/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { t } from "i18n";

export enum DIMENSION {
  STORY = "story",
  SECTION = "section",
  AUTHOR = "author"
}

export enum METRICS {
  VIEWS = "view",
  UNIQUE_VISIT = "visit",
  SHARES = "share"
}

export const TAB_LIST = () => [
  { label: t("analytics.view"), value: METRICS.VIEWS },
  { label: t("analytics.visit"), value: METRICS.UNIQUE_VISIT },
  { label: t("analytics.share"), value: METRICS.SHARES }
];
