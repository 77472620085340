/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import LoginWrapper from "./login";
import { FORGOT_PASSWORD_PATH, RESET_PASSWORD_PATH, LOGIN_PATH, SETUP_TWO_FACTOR_AUTH, VALIDATE_TWO_FACTOR_AUTH } from "./routes";

export const Router = () => {
  return (
    <Switch>
      <Route
        exact
        path={LOGIN_PATH}
        render={(props) => (
          <LoginWrapper
            {...props}
            enableTwoFactorAuth={false}
            twoFactorAuth={false}
            resetPassword={false}
            forgotPassword={false}
            title="login"
          />
        )}
      />
      <Route
        exact
        path={FORGOT_PASSWORD_PATH}
        render={(props) => (
          <LoginWrapper
            {...props}
            enableTwoFactorAuth={false}
            twoFactorAuth={false}
            resetPassword={false}
            forgotPassword={true}
            title="forgot_password"
          />
        )}
      />
      <Route
        exact
        path={RESET_PASSWORD_PATH}
        render={(props) => (
          <LoginWrapper
            {...props}
            enableTwoFactorAuth={false}
            twoFactorAuth={false}
            forgotPassword={false}
            resetPassword={true}
            title="reset_password"
          />
        )}
      />
      <Route
        exact
        path={SETUP_TWO_FACTOR_AUTH}
        render={(props) => (
          <LoginWrapper
            enableTwoFactorAuth={true}
            twoFactorAuth={false}
            resetPassword={false}
            forgotPassword={false}
            {...props}
            title="two_factor_auth"
          />
        )}
      />
      <Route
        exact
        path={VALIDATE_TWO_FACTOR_AUTH}
        render={(props) => (
          <LoginWrapper
            twoFactorAuth={true}
            enableTwoFactorAuth={false}
            resetPassword={false}
            forgotPassword={false}
            {...props}
            title="two_factor_auth"
          />
        )}
      />
    </Switch>
  );
};
