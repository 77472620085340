/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import styles from "./settings-section.module.css";

type Props = {
  title: string;
  children: Array<React.ReactNode> | React.ReactNode;
  subtitle?: string;
};

export const SettingsSection: React.FC<Props> = ({ title, subtitle, children }) => (
  <section className={styles["settings-section-container"]}>
    <div>
      <h3 className={styles["title"]} data-test-id="settings-card-title">
        {title}
      </h3>
      <p className={styles["subtitle"]}>{subtitle}</p>
    </div>
    <div>{children}</div>
  </section>
);

export default SettingsSection;
