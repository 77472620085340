/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import {
  chainCommands,
  joinBackward,
  joinForward,
  newlineInCode,
  selectNodeBackward,
  setBlockType,
  toggleMark
} from "prosemirror-commands";
import { undo, redo } from "prosemirror-history";
import { liftListItem, sinkListItem, splitListItem } from "prosemirror-schema-list";
import {
  handleBackspace,
  handleDelete,
  handleEnter,
  handleUndo,
  handleRedo,
  nop,
  selectTextNode,
  splitElement,
  toggleList
} from "../prosemirror/commands";
import { Schema } from "prosemirror-model";

export function buildKeymap(schema: Schema, mapKeys: { [key: string]: any } = {}) {
  let keys = {},
    type;
  function bind(key: string, cmd: Function) {
    if (mapKeys) {
      let mapped = mapKeys[key];
      if (mapped === false) return;
      if (mapped) key = mapped;
    }
    keys[key] = cmd;
  }

  if ((type = schema.marks.strong)) bind("Mod-b", toggleMark(type));
  if ((type = schema.marks.em)) bind("Mod-i", toggleMark(type));
  if ((type = schema.marks.underline)) bind("Mod-u", toggleMark(type));
  if ((type = schema.marks.strikethrough)) bind("Mod-s", toggleMark(type));

  if ((type = schema.nodes.ordered_list)) bind("Mod-7", chainCommands(toggleList(schema, type), nop));
  if ((type = schema.nodes.bullet_list)) bind("Mod-8", chainCommands(toggleList(schema, type), nop));

  if ((type = schema.nodes.list_item)) {
    bind("Mod-[", liftListItem(type));
    bind("Mod-]", sinkListItem(type));
  }
  if ((type = schema.nodes.heading)) {
    for (let i = 2; i <= 6; i++) {
      bind("Mod-" + i, chainCommands(setBlockType(type, { level: i }), setBlockType(schema.nodes.paragraph)));
    }
  }

  if ((type = schema.nodes.story_element_text) || (type = schema.nodes.text_area)) {
    bind("Mod-a", chainCommands(selectTextNode(schema, type), nop));
  }

  if ((type = schema.nodes.story_element_text)) {
    bind("Shift-Enter", chainCommands(splitElement()));
  }

  bind("Mod-z", chainCommands(handleUndo(), undo));
  bind("Mod-y", chainCommands(handleRedo(), redo));
  bind("Mod-Shift-z", chainCommands(handleRedo(), redo));

  bind("Backspace", chainCommands(handleBackspace(), joinBackward, selectNodeBackward));
  bind("Mod-Backspace", chainCommands(handleBackspace(), joinBackward, selectNodeBackward));
  bind("Shift-Backspace", chainCommands(handleBackspace(), joinBackward, selectNodeBackward));

  bind("Delete", chainCommands(handleDelete(), joinForward));
  bind("Mod-Delete", chainCommands(handleDelete(), joinForward));
  bind("Shift-Delete", chainCommands(handleDelete(), joinForward));
  bind("Alt-Delete", chainCommands(handleDelete(), joinForward));
  bind("Ctrl-d", chainCommands(handleDelete(), joinForward));

  bind("Mod-Shift-Backspace", nop);
  bind("Alt-Shift-Backspace", nop);
  bind("Alt-Mod-Backspace", nop);
  bind("Alt-Ctrl-Backspace", nop);
  bind("Ctrl-Shift-Backspace", nop);
  bind("Mod-Shift-Delete", nop);
  bind("Alt-Shift-Delete", nop);
  bind("Alt-Mod-Delete", nop);
  bind("Alt-Ctrl-Delete", nop);
  bind("Ctrl-Shift-Delete", nop);

  if ((type = schema.nodes.list_item)) {
    bind("Enter", chainCommands(splitListItem(type), newlineInCode, handleEnter()));
  }
  // Instead of handling the keycommand here, handle the Cut event
  //bind("Mod-x", chainCommands(handleTextCut()));
  return keys;
}
