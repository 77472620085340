/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { base64Encode } from "utils";

const EMBED_SOURCE_URL = {
  "vod-platform.net": "vod-video",
  "cdn.bitgravity.com": "bitgravity-video",
  "players.brightcove.net": "brightcove-video",
  "soundcloud.com": "soundcloud-audio",
  "content.jwplatform.com": "jwplayer",
  "dilmot.com": "dilmot-q-and-a",
  youtube: "youtube-video",
  "youtu.be": "youtube-video",
  dailymotion: "dailymotion-video",
  instagram: "instagram",
  "facebook.com": "facebook",
  "twitter.com": "twitter",
  "vimeo.com": "vimeo-video"
};

const guessEmbedProvider = (embedCode: string) => {
  const providerURL = Object.keys(EMBED_SOURCE_URL).find((url) => embedCode.includes(url));
  return providerURL ? EMBED_SOURCE_URL[providerURL] : "jsembed";
};

const getVimeoUrlAndId = (embedCode: string) => {
  const match = /https:\/\/player\.vimeo\.com\/video\/(\d+)/.exec(embedCode);
  return match
    ? {
        "video-id": match[1]
      }
    : null;
};

export function getJsembedElement(embedCode: string) {
  const guessedProvider = guessEmbedProvider(embedCode);
  const defaultElement = {
    type: "jsembed",
    "embed-js": base64Encode(embedCode)
  };

  switch (guessedProvider) {
    case "vimeo-video": {
      const urlAndId = getVimeoUrlAndId(embedCode);
      return { ...defaultElement, subtype: "vimeo-video", metadata: { provider: "vimeo-video", ...urlAndId } };
    }
    default:
      return defaultElement;
  }
}
