/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Plugin } from "prosemirror-state";
import tippy, { Instance } from "tippy.js";
import { getEntityDisplayName } from "pages/story-editor/plugins/toolbar/entity-link/entity-link";

export function entityLinkTooltipPlugin() {
  let tooltipInstance: Instance | null = null;

  return new Plugin({
    props: {
      handleDOMEvents: {
        mouseover(view, event) {
          const anchorElement = event.target as HTMLAnchorElement;

          if (anchorElement.tagName === "A" && anchorElement.classList.contains("entity-link")) {
            // Destroy existing tooltip
            tooltipInstance?.destroy();

            // Create new tooltip
            tooltipInstance = tippy(anchorElement, {
              content(reference) {
                const entityName = reference.getAttribute("entity-name") || "";
                const entityType = reference.getAttribute("entity-type-slug") || "";
                return `Entity: ${getEntityDisplayName(entityName, entityType)}`;
              },
              theme: "em",
              aria: {
                content: null // Prevents Tippy from adding aria-describedby
              }
            });

            tooltipInstance.show();
          }
          return false;
        },
        mouseout(view, event) {
          tooltipInstance?.hide();
          tooltipInstance?.destroy();
          tooltipInstance = null;
          return false;
        }
      }
    }
  });
}
