/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useMemo } from "react";
import classnames from "classnames/bind";
import styles from "./text-limit-indicator.module.css";
import { formatNumberToMetricSystem } from "utils";

interface Props {
  text: string;
  limit: number;
  okClassName?: string;
  warningClassName?: string;
  warningPercent?: number;
  errorClassName?: string;
}

const cx = classnames.bind(styles);

const TextLimitIndicator: React.FC<Props> = ({
  text,
  limit,
  okClassName = "text-limit-ok",
  warningClassName = "text-limit-warning",
  warningPercent = 70,
  errorClassName = "text-limit-error"
}) => {
  const length = text.length;
  const formattedLimit = useMemo(() => formatNumberToMetricSystem(limit), [limit]);

  const [isError, isWarning, isOk, formattedLength] = useMemo(() => {
    const isError = length > limit,
      isWarning = !isError && length / limit > warningPercent / 100,
      isOk = !(isError || isWarning),
      formattedLength = formatNumberToMetricSystem(length);
    return [isError, isWarning, isOk, formattedLength];
  }, [length, limit, warningPercent]);

  return (
    <span
      className={cx("text-limit", {
        [okClassName]: isOk,
        [warningClassName]: isWarning,
        [errorClassName]: isError,
        "is-greater-limit": `${formattedLimit}`.length > 4
      })}
      data-test-id="text-limit-wrapper">
      <span data-test-id="text-length">{formattedLength}</span>
      <span data-test-id="text-limit">{formattedLimit}</span>
    </span>
  );
};

export default TextLimitIndicator;
