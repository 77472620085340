/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const FORMS_PATH = "/content/forms";
export const FORMS_EDITOR_PATH = "/content/forms/";
export const FORMS_EDITOR_PATH_EDIT = "/content/forms/:formId";
export const FORMS_EDITOR_PATH_MANAGE = "/content/forms/:formId/manage";
