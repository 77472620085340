/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import styles from "./static-page-form.module.css";
import { Metadata, MimeTypes, StaticPage } from "api/static-pages";

import { ThunkDispatch } from "redux-thunk";
import TextArea from "components/text-area/text-area";
import SubTitle from "components/icons/sub-title";
import TextField from "components/text-field/text-field";
import Checkbox from "components/checkbox/checkbox";
import Select from "components/select/select";
import { updateCurrentStaticPage } from "../../action-creators";
import { StaticPageValidationError } from "../../errors";
import { t } from "i18n";
import classnames from "classnames/bind";
import { get } from "lodash";
import StaticPageManageInspector from "pages/content/static-pages/components/inspector/inspector";
import { navigate } from "utils/routes.utils";
import { STATIC_PAGES_NEW_PATH, STATIC_PAGES_EDIT_PATH } from "pages/content/static-pages/routes";
import { NOTIFICATION_SUCCESS } from "containers/page/actions";

interface StateProps {
  currentStaticPage: StaticPage;
  error: {
    name: string;
  };
}

const cx = classnames.bind(styles);

interface DispatchProps {
  updateCurrentPage: Function;
  closeInspector: (id: number) => void;
  updateMetadata: (value: Metadata) => void;
}

interface MimeTypeOption {
  label: string;
  value: MimeTypes;
}

interface OwnProps {
  isInspectorOpen: boolean;
}

const MimeTypeOptions: Array<MimeTypeOption> = [
  { label: "HTML", value: MimeTypes.HTML },
  { label: "JSON", value: MimeTypes.JSON },
  { label: "XML", value: MimeTypes.XML },
  { label: "Text", value: MimeTypes.TEXT }
];

type StaticPageFormProps = StateProps & DispatchProps & OwnProps;

const getErrorMessage = (field: string, error: any) => {
  if (error && error instanceof StaticPageValidationError) {
    return error.error.hasOwnProperty(field);
  }
  return null;
};

const getSlugErrorMessage = (error: any) => {
  if (getErrorMessage("slug", error)) {
    return t("staticPages.form.error.sourcePath");
  }
  if (error && error instanceof StaticPageValidationError && error.message) {
    return t("staticPages.form.error.sourcePathExists");
  }
  return "";
};
const StaticPageForm: React.SFC<StaticPageFormProps> = ({
  currentStaticPage,
  updateCurrentPage,
  error,
  isInspectorOpen,
  closeInspector,
  updateMetadata
}: StaticPageFormProps) => {
  if (!currentStaticPage) {
    return null;
  }
  const { header, footer, "mime-type": mimeType } = get(currentStaticPage, ["metadata"], {} as Metadata);
  const currentMimeType = MimeTypeOptions.find((m) => m.value === mimeType);

  return (
    <section className={styles["static-pages-form-wrapper"]}>
      <div className={styles["static-pages-form-main-container"]}>
        <div className={styles["static-pages-form-field-container"]}>
          <div className={styles["static-pages-form-field-icon"]}>
            <SubTitle />
          </div>
          <div className={styles["static-pages-form-field static-pages-form-field--title"]}>
            <TextArea
              onChange={(value) => updateCurrentPage("title", value)}
              placeholder={t("staticPages.form.placeholder.title")}
              value={currentStaticPage.title}
              errorMessage={getErrorMessage("title", error) ? t("staticPages.form.error.title") : ""}
              variant="plain"
              size="jumbo"
            />
          </div>
        </div>

        <div className={styles["static-pages-form-container"]}>
          <div className={styles["static-pages-form-field static-pages-form-field--source"]}>
            <TextField
              label={t("staticPages.form.label.sourcePath")}
              onChange={(value) => updateCurrentPage("slug", value.replace(/^\/+/, ""))}
              placeholder={t("staticPages.form.placeholder.sourcePath")}
              value={`/${currentStaticPage["slug"]}`}
              errorMessage={getSlugErrorMessage(error)}
            />
          </div>
          <div>
            <Select
              label={t("staticPages.form.label.mimeType")}
              value={currentMimeType}
              getOptionValue={(option) => option.value}
              getOptionLabel={(option) => option.label}
              options={MimeTypeOptions}
              onChange={(option: MimeTypeOption) =>
                updateCurrentPage("metadata", { ...currentStaticPage.metadata, "mime-type": option.value })
              }
            />
          </div>
          <div className={cx("static-pages-form-field", "static-pages-form-field--options")}>
            <div className={styles["static-pages-form-checkbox"]}>
              <Checkbox
                onChange={(value) => updateCurrentPage("metadata", { ...currentStaticPage.metadata, header: value })}
                label={t("staticPages.form.label.showHeader")}
                id="1"
                checked={!!header}
              />
            </div>
            <div className={styles["static-pages-form-checkbox"]}>
              <Checkbox
                onChange={(value) => updateCurrentPage("metadata", { ...currentStaticPage.metadata, footer: value })}
                label={t("staticPages.form.label.showFooter")}
                id="2"
                checked={!!footer}
              />
            </div>
          </div>
          <div className={styles["static-pages-form-field static-pages-form-field--content"]}>
            <TextArea
              onChange={(value) => updateCurrentPage("content", value)}
              label={t("staticPages.form.label.content")}
              value={currentStaticPage.content ? currentStaticPage.content : ""}
              errorMessage=""
              classname={"static-page-content-textarea"}
            />
          </div>
        </div>
        <StaticPageManageInspector
          isOpen={isInspectorOpen}
          onClose={() => closeInspector(currentStaticPage.id)}
          metadata={currentStaticPage.metadata}
          updateMetadata={(newMetadata) => updateMetadata(newMetadata)}
        />
      </div>
    </section>
  );
};

const mapStateToProps = (state: any): StateProps => {
  return {
    currentStaticPage: state.staticPages.app.currentStaticPage,
    error: state.staticPages.ui.save.error
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DispatchProps => {
  return {
    updateCurrentPage: (key: string, value: any) => {
      dispatch(updateCurrentStaticPage(key, value));
    },
    closeInspector: (id) => {
      if (id) {
        dispatch(navigate(STATIC_PAGES_EDIT_PATH, { id }));
      } else {
        dispatch(navigate(STATIC_PAGES_NEW_PATH));
      }
    },
    updateMetadata: (value: Metadata) => {
      dispatch({
        type: NOTIFICATION_SUCCESS,
        payload: { message: t("staticPages.form.inspector.metadata-changed-message") }
      });
      dispatch(updateCurrentStaticPage("metadata", value));
    }
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(StaticPageForm);

export { StaticPageForm };
