/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { t } from "i18n";
import { Config } from "api/content-templates";
import { get, toNumber } from "lodash";

const checkEmpty = (translator) => {
  return {
    presence: { allowEmpty: false, message: translator("errors.validations.character-empty") }
  };
};

const checkNumber = (translator) => {
  return {
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      message: translator("errors.validations.positive-int-expected")
    }
  };
};

export const constraints = (translator: typeof t, config: Config) => {
  const templateFields = [
    { key: "headline", isMaxCharLimitMandatory: true },
    { key: "subheadline", isMaxCharLimitMandatory: true },
    { key: "meta-title", isMaxCharLimitMandatory: true },
    { key: "meta-description", isMaxCharLimitMandatory: true },
    { key: "summary", isMaxCharLimitMandatory: true },
    { key: "hero-image-attribution", isMaxCharLimitMandatory: true },
    { key: "hero-image-caption", isMaxCharLimitMandatory: true },
    { key: "hero-image-alt-text", isMaxCharLimitMandatory: true },
    { key: "image-alt-text", isMaxCharLimitMandatory: true },
    { key: "home-alternative-headline", isMaxCharLimitMandatory: false },
    { key: "social-alternative-headline", isMaxCharLimitMandatory: false }
  ];
  const fieldConstraints = { name: { presence: { allowEmpty: false } } };
  for (const field of templateFields) {
    // Add validations only if field is enabled
    if (!get(config, ["fields", field.key, "validations", "hidden"], false)) {
      if (field.isMaxCharLimitMandatory) {
        // If Maximum character limit is mandatory, then it should satisfy following conditions,
        // 1) Value cannot be empty
        // 2) Should be a valid integer greater than zero
        fieldConstraints[`config.fields.${field.key}.validations.hard-limit`] = {
          ...checkEmpty(translator),
          ...checkNumber(translator)
        };
      } else {
        // If Maximum character limit is not mandatory, then it should satisfy following conditions,
        // 1) Should be a valid integer greater than zero
        fieldConstraints[`config.fields.${field.key}.validations.hard-limit`] = (value: string) =>
          value && checkNumber(translator);
      }
      // Minimum character limit should satisfy following conditions,
      // 1) Value can be empty
      // 2) Should be a valid integer greater than zero
      // 3) Should be less than maximum character limit
      fieldConstraints[`config.fields.${field.key}.validations.min-limit`] = function(value, attributes) {
        if (!value) return false;
        const hardLimit = get(attributes, `config.fields.${field.key}.validations.hard-limit`);
        return {
          numericality: {
            onlyInteger: true,
            greaterThan: 0,
            lessThan: toNumber(hardLimit),
            ...(hardLimit
              ? {
                  lessThan: toNumber(hardLimit),
                  notLessThan: translator("settings.storyTemplates.errors.min_char_limit_greater_than_max_char_limit")
                }
              : {}),
            notGreaterThan: translator("errors.validations.positive-int-expected"),
            notInteger: translator("errors.validations.positive-int-expected"),
            notValid: translator("errors.validations.positive-int-expected")
          }
        };
      };
    }
  }
  return fieldConstraints;
};
