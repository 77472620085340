/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import Content from "./content";
import Metadata from "./metadata";
import Rules from "./rules";
import { actions } from "../../actions";
import {
  COLLECTIONS_CONTENT_MEDIA_GALLERY_PATH,
  COLLECTIONS_DELETE_PATH,
  COLLECTIONS_CONTENT_IMAGE_EDIT_PATH
} from "pages/collections/routes";
import { navigateFn } from "utils/routes.utils";
import { getMetadataFields } from "pages/collections/utils";
import "./settings.css";
import {
  AdditionalMetadataField,
  CollectionMetadataMap,
  StoryTemplate,
  MetadataFields
} from "api/route-data/collection-route-data";
import {
  updateStagingSelectedCollectionEntities,
  fileUploadAction,
  setGeneratedSlug,
  togglePolygonSelection,
  updateLocationFilterToggle
} from "../../async-action-creators";
import { ValidationResult } from "utils/validation.utils";
import { AnyCollection, CoverImage, Metadata as ColMetadata, Collection } from "api/story-collection";
import { CollectionId } from "api/primitive-types";
import { ThunkDispatch } from "redux-thunk";
import { Section } from "api/route-data/route-data";
import { PartialAppState } from "pages/collections/initial-state";
import { EntityInCollection } from "api/entity";
import { Attribute } from "api/story-attributes";
import { ImageOrNewImage } from "api/search-media-image";
import { filterStoryTemplateWithPrint } from "utils";

export enum CollectionTab {
  Content = "content",
  Metadata = "metadata",
  Rules = "rules"
}

interface StateProps {
  additionalMetadataFields: AdditionalMetadataField[];
  collectionMetadata: CollectionMetadataMap;
  errors: ValidationResult<AnyCollection>;
  sections: Section[];
  storyAttributes: Attribute[];
  sketchesHost: string;
  stagingSelectedCollection: AnyCollection;
  storyTemplates: StoryTemplate[];
  enabledEntityTypes: string[];
  isAddPdfAsCollectionMetadataEnabled: boolean;
  maxFileSize: number;
  isRecommendedCollectionEnabled: boolean;
  isAdditionalCollectionMetadataEnabled: boolean;
  isAnalyticsTrendingCollectionEnabled: boolean;
  hasTagCreatePermission: boolean;
  isLocationForStoriesEnabled: boolean;
  isStoryPrintEnabled?: boolean;
  googleMapsApiKey: string;
}

interface DispatchProps {
  updateStagingSelectedCollection: (key: string, value: any) => void;
  updateStagingSelectedCollectionMetadata: (key: string, value: any) => void;
  updateStagingSelectedCollectionRules: (key: string, value: any) => void;
  openMediaGallery: (id?: CollectionId) => void;
  removeSelectedCollectionCoverImages: () => void;
  openDeleteConfirmationModal: (collectionId: CollectionId) => void;
  openPhotoEditor: (image: CoverImage) => void;
  updateStagingSelectedCollectionEntities: (
    selectedEntities: EntityInCollection[],
    entityType: string,
    metadata: ColMetadata
  ) => void;
  fileUploadAction: (err: Error, file: File, fileName: string, metadata: ColMetadata) => void;
  setSlug: (collection: AnyCollection) => void;
  togglePolygonSelection: () => void;
  updateLocationFilterToggleAction: (value: boolean) => void;
  switchToImageEdit: (collectionId: CollectionId, mediaKey: string) => void;
  setCollectionCoverImages: (images: ImageOrNewImage[]) => void;
  setCollectionCoverImagesUploadStatus: (status: { uploading: boolean }) => void;
}

interface OwnProps {
  metadataFields?: MetadataFields;
  selectedTab: CollectionTab;
}

type Props = StateProps & DispatchProps & OwnProps;

const Settings: React.SFC<Props> = ({
  selectedTab,
  stagingSelectedCollection,
  updateStagingSelectedCollection,
  sketchesHost,
  collectionMetadata,
  updateStagingSelectedCollectionMetadata,
  updateStagingSelectedCollectionRules,
  sections,
  storyAttributes,
  storyTemplates,
  additionalMetadataFields,
  openMediaGallery,
  removeSelectedCollectionCoverImages,
  openDeleteConfirmationModal,
  errors,
  openPhotoEditor,
  updateStagingSelectedCollectionEntities,
  enabledEntityTypes,
  fileUploadAction,
  isAddPdfAsCollectionMetadataEnabled,
  maxFileSize,
  isRecommendedCollectionEnabled,
  isAdditionalCollectionMetadataEnabled,
  isAnalyticsTrendingCollectionEnabled,
  hasTagCreatePermission,
  setSlug,
  isLocationForStoriesEnabled,
  googleMapsApiKey,
  togglePolygonSelection,
  updateLocationFilterToggleAction,
  switchToImageEdit,
  setCollectionCoverImages,
  setCollectionCoverImagesUploadStatus,
  isStoryPrintEnabled
}) => {
  if (selectedTab === CollectionTab.Content) {
    return (
      <Content
        updateStagingSelectedCollection={updateStagingSelectedCollection}
        stagingSelectedCollection={stagingSelectedCollection}
        sketchesHost={sketchesHost}
        collectionMetadata={collectionMetadata}
        openMediaGallery={openMediaGallery}
        removeSelectedCollectionCoverImages={removeSelectedCollectionCoverImages}
        errors={errors}
        openDeleteConfirmationModal={openDeleteConfirmationModal}
        openPhotoEditor={openPhotoEditor}
        fileUploadAction={fileUploadAction}
        isAddPdfAsCollectionMetadataEnabled={isAddPdfAsCollectionMetadataEnabled}
        maxFileSize={maxFileSize}
        setSlug={setSlug}
        switchToUploadRoute={(mediaKey) => switchToImageEdit((stagingSelectedCollection as Collection).id, mediaKey)}
        setSelectedMedia={setCollectionCoverImages}
        updateImageUploadStatus={setCollectionCoverImagesUploadStatus}
        showEditImage={true}
      />
    );
  } else if (selectedTab === CollectionTab.Metadata) {
    return (
      <Metadata
        metadataFields={getMetadataFields(stagingSelectedCollection.template, collectionMetadata) || {}}
        stagingSelectedCollection={stagingSelectedCollection}
        updateStagingSelectedCollectionMetadata={updateStagingSelectedCollectionMetadata}
        updateStagingSelectedCollection={updateStagingSelectedCollection}
        sections={sections}
        additionalMetadataFields={additionalMetadataFields}
        updateStagingSelectedCollectionEntities={updateStagingSelectedCollectionEntities}
        enabledEntityTypes={enabledEntityTypes}
        isAdditionalCollectionMetadataEnabled={isAdditionalCollectionMetadataEnabled}
        hasTagCreatePermission={hasTagCreatePermission}
      />
    );
  } else {
    return (
      <Rules
        stagingSelectedCollection={stagingSelectedCollection}
        updateStagingSelectedCollectionRules={updateStagingSelectedCollectionRules}
        updateStagingSelectedCollection={updateStagingSelectedCollection}
        sections={sections}
        storyAttributes={storyAttributes}
        storyTemplates={filterStoryTemplateWithPrint(isStoryPrintEnabled, storyTemplates)}
        isRecommendedCollectionEnabled={isRecommendedCollectionEnabled}
        isAnalyticsTrendingCollectionEnabled={isAnalyticsTrendingCollectionEnabled}
        isLocationForStoriesEnabled={isLocationForStoriesEnabled}
        googleMapsApiKey={googleMapsApiKey}
        togglePolygonSelection={togglePolygonSelection}
        updateLocationFilterToggleAction={updateLocationFilterToggleAction}
      />
    );
  }
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    stagingSelectedCollection: state.collections.collectionPage.stagingSelectedCollection!,
    sketchesHost: state.config.publisher["website-url"],
    collectionMetadata: state.config.collectionMetadata,
    sections: state.config.sections,
    storyAttributes: state.config.storyAttributes,
    storyTemplates: state.config.storyTemplates,
    additionalMetadataFields: state.config.additionalMetadataFields,
    errors: state.collections.ui.errors,
    enabledEntityTypes: state.config.enabledEntityTypes,
    isAddPdfAsCollectionMetadataEnabled: state.features.isAddPdfAsCollectionMetadataEnabled,
    maxFileSize: state.config.maxFileSize,
    isRecommendedCollectionEnabled: state.features.isRecommendedCollectionEnabled,
    isAdditionalCollectionMetadataEnabled: state.features.isAdditionalCollectionMetadataEnabled,
    isAnalyticsTrendingCollectionEnabled: state.features.isAnalyticsTrendingCollectionEnabled,
    hasTagCreatePermission: state.features.hasTagCreatePermission,
    isLocationForStoriesEnabled: state.features.isLocationForStoriesEnabled,
    isStoryPrintEnabled: state.features.isStoryPrintEnabled || false,
    googleMapsApiKey: (state.config.googleMaps && state.config.googleMaps["api-key"]) || ""
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DispatchProps => {
  const navigate = navigateFn(dispatch);

  return {
    updateStagingSelectedCollection: (key, value) =>
      dispatch({ type: actions.UPDATE_STAGING_SELECTED_COLLECTION, payload: { key, value } }),
    updateStagingSelectedCollectionMetadata: (key, value) =>
      dispatch({ type: actions.UPDATE_STAGING_SELECTED_COLLECTION_METADATA, payload: { key, value } }),
    updateStagingSelectedCollectionRules: (key, value) =>
      dispatch({ type: actions.UPDATE_STAGING_SELECTED_COLLECTION_RULES, payload: { key, value } }),
    openMediaGallery: (collectionId) =>
      navigate(COLLECTIONS_CONTENT_MEDIA_GALLERY_PATH, { collectionId: collectionId || "new" }),
    removeSelectedCollectionCoverImages: () => dispatch({ type: actions.REMOVE_COVER_IMAGE }),
    openDeleteConfirmationModal: (collectionId: CollectionId) => navigate(COLLECTIONS_DELETE_PATH, { collectionId }),
    openPhotoEditor: (image) => dispatch({ type: actions.OPEN_PHOTO_EDITOR, payload: { image } }),
    updateStagingSelectedCollectionEntities: (selectedEntities, entityType, metadata) =>
      dispatch(updateStagingSelectedCollectionEntities(selectedEntities, entityType, metadata)),
    fileUploadAction: (err, file, fileName, metadata) => dispatch(fileUploadAction(err, file, fileName, metadata)),
    setSlug: (collection: AnyCollection) => dispatch(setGeneratedSlug(collection)),
    togglePolygonSelection: () => dispatch(togglePolygonSelection()),
    updateLocationFilterToggleAction: (value: boolean) => dispatch(updateLocationFilterToggle(value)),
    switchToImageEdit: (collectionId, mediaKey) =>
      navigate(COLLECTIONS_CONTENT_IMAGE_EDIT_PATH, { collectionId: collectionId || "new", mediaKey }),
    setCollectionCoverImages: (images) => dispatch({ type: actions.SET_COVER_IMAGES, payload: { images } }),
    setCollectionCoverImagesUploadStatus: (status) =>
      dispatch({ type: actions.TOGGLE_COVER_IMAGE_UPLOADING, payload: { status: status.uploading } })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);

export { Settings };
