/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";

import { navigate, route } from "utils/routes.utils";
import { MENU_ITEM_EDIT_PATH } from "../../routes";
import { MenuItem, MenuItemMoveDirection, MenuItemType } from "api/menu-item";
import { MenuGroup } from "api/menu-group";
import { ContextMenu, ContextMenuList, ContextMenuItem } from "components/context-menu/context-menu";
import Circle from "components/icons/circle";
import Chip from "components/chip/chip";
import Button from "components/button/button";
import Chevron from "components/icons/chevron";
import { MenuItemsList } from "./menu-items-list";
import styles from "./menu-items-row.module.css";

interface MenuItemRowProps {
  selectedMenu: MenuGroup | null;
  menuItem: MenuItem;
  allMenuItems: MenuItem[];
  moveMenuItem: (menuItem: MenuItem, direction: MenuItemMoveDirection) => void;
  deleteMenuItem?: (menuItem: MenuItem) => void;
  itemIndex: number;
  itemCount: number;
}

interface MenuItemRowDispatchProps {
  changeRoute: (path: string) => void;
}

function getMenuItemChipLabel(itemType: MenuItemType): string {
  return t(`menu.inspector.item-type-${itemType}-label`);
}

const MenuItemRow: React.SFC<MenuItemRowProps & MenuItemRowDispatchProps> = ({
  selectedMenu,
  menuItem,
  allMenuItems,
  moveMenuItem,
  deleteMenuItem,
  itemIndex,
  itemCount,
  changeRoute
}) => {
  const childrenMenuItems = allMenuItems.filter((_menuItem) => _menuItem["parent-id"] === menuItem.id),
    canMoveUp = itemIndex > 0,
    canMoveDown = itemIndex < itemCount - 1,
    canDelete = childrenMenuItems.length === 0 && !!deleteMenuItem;

  return (
    <React.Fragment>
      <li className={styles["menu-items-list-item"]}>
        <div
          data-test-id="menu-item-link"
          className={styles["menu-item-link"]}
          onClick={() =>
            changeRoute(route(MENU_ITEM_EDIT_PATH, { menuId: selectedMenu && selectedMenu.id, itemId: menuItem.id }))
          }>
          <div className={styles["menu-item-row-info"]}>
            <span className={styles["menu-color-container"]}>
              <Circle
                radius={18}
                fillColor={menuItem.data.color}
                strokeWidth={menuItem.data.color === "#FFFFFF" ? 1 : 0}
              />
            </span>
            <span>{menuItem.title}</span>
            <Chip value={getMenuItemChipLabel(menuItem["item-type"])} classname={"menu-chip"} />
          </div>
          <div className={styles["menu-item-row-actions"]}>
            <Button
              testId={`menu-item-${menuItem.id}-move-up`}
              type="transparent"
              disabled={!canMoveUp}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                canMoveUp && moveMenuItem(menuItem, MenuItemMoveDirection.Up);
              }}
              classname={styles["menu-item-row-actions-button"]}>
              <Chevron fill={canMoveUp ? "var(--black)" : "var(--mono-3)"} />
            </Button>
            <Button
              testId={`menu-item-${menuItem.id}-move-down`}
              type="transparent"
              disabled={!canMoveDown}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                canMoveDown && moveMenuItem(menuItem, MenuItemMoveDirection.Down);
              }}
              classname={styles["menu-item-row-actions-button"]}>
              <Chevron fill={canMoveDown ? "var(--black)" : "var(--mono-3)"} variant="down" />
            </Button>
            <div id={`menu-item-menu-${menuItem.id}`}>
              <ContextMenu>
                <div
                  id={`menu-item-menu-${menuItem.id}`}
                  className={styles["menu-items-action-dropdown"]}
                  data-test-id="menu-items-action-dropdown">
                  <ContextMenuList>
                    <ContextMenuItem
                      label={t("menu.cta.delete")}
                      isDanger
                      isDisabled={!canDelete}
                      onClick={() => deleteMenuItem && deleteMenuItem(menuItem)}
                    />
                  </ContextMenuList>
                </div>
              </ContextMenu>
            </div>
          </div>
        </div>
      </li>

      {childrenMenuItems.length > 0 && (
        <li className={styles["menu-list-nested-items"]} data-test-id="menu-list-nested-items">
          <MenuItemsList
            selectedMenu={selectedMenu}
            allMenuItems={allMenuItems}
            menuItems={childrenMenuItems}
            moveMenuItem={moveMenuItem}
            deleteMenuItem={deleteMenuItem}
            className={"nested-menu-itemslist"}
          />
        </li>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  changeRoute: (path: string) => dispatch(navigate(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemRow);

export { MenuItemRow };
