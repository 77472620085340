/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { connect } from "react-redux";
import { showGlobalAppCrashPage } from "store/error-page";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState } from "store/secret-mode/secret-mode";
import { AnyAction } from "redux";
import { ItsmanWindow } from "containers/page/page";
import { getReduxActionLog } from "utils";

const w = window as Window & Partial<ItsmanWindow>;

interface State {
  hasError: boolean;
}
interface DispatchProps {
  showGlobalAppCrashPage: () => void;
}
class ErrorBoundary extends React.Component<DispatchProps, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.props.showGlobalAppCrashPage();
    w.newrelic &&
      w.newrelic.noticeError(error, {
        errorType: "global_app_crash",
        errorDescription: "Application crashed, found at root level.",
        errorInfo: JSON.stringify({ errorInfo }),
        reduxActionLog: JSON.stringify({ actions: getReduxActionLog() })
      });
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    return null;
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => ({
  showGlobalAppCrashPage: () => dispatch(showGlobalAppCrashPage())
});
export default connect(() => ({}), mapDispatchToProps)(ErrorBoundary);
