/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Poll = ({ width = 24, height = 24, color = "#c6ccd5" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height} fill={color}>
    <defs />
    <path d="M20.08,3H5.93A1.94,1.94,0,0,0,4,4.92V19.06A1.93,1.93,0,0,0,5.93,21H20.08A1.92,1.92,0,0,0,22,19.06V4.92A1.93,1.93,0,0,0,20.08,3Zm.64,16.07a.65.65,0,0,1-.64.65H5.93a.65.65,0,0,1-.64-.65V4.92a.64.64,0,0,1,.64-.64H20.08a.64.64,0,0,1,.64.64ZM9.47,10.71H8.83A.32.32,0,0,0,8.5,11v5.78a.33.33,0,0,0,.33.33h.64a.32.32,0,0,0,.32-.33V11A.32.32,0,0,0,9.47,10.71Zm3.86-3.86h-.65a.32.32,0,0,0-.32.32v9.64a.33.33,0,0,0,.32.33h.65a.33.33,0,0,0,.32-.33V7.17A.32.32,0,0,0,13.33,6.85Zm3.85,6.43h-.64a.32.32,0,0,0-.32.32v3.21a.32.32,0,0,0,.32.33h.64a.32.32,0,0,0,.32-.33V13.6A.32.32,0,0,0,17.18,13.28Z" />
  </svg>
);

export default Poll;
