/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface ItalicIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const Italic: React.SFC<ItalicIconProps> = ({ width = "24", height = "24", color = "currentColor" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 24 24">
    <path fillRule="nonzero" d="M12.36 4h1.752l-3.36 16.8H9z" />
  </svg>
);

export default Italic;
