/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { get } from "lodash";
import TextField from "components/text-field/text-field";
import { CopyscapeConfig, Integrations } from "api/integrations";
import { isClientValidationError } from "utils/validation.utils";

interface Props {
  integration: { key: Integrations.Copyscape; value: CopyscapeConfig };
  updateIntegrationField: (key: string, value: Object) => void;
  readOnly?: boolean;
  errors: Error | null;
}

const Copyscape: React.SFC<Props> = ({ integration, updateIntegrationField, readOnly, errors }) => {
  const integrationName = integration.key;
  return (
    <React.Fragment>
      <TextField
        label={t("integrations.fields.username")}
        value={integration.value["username"]}
        onChange={(value) => updateIntegrationField(integrationName, { username: value })}
        readOnly={readOnly}
        errorMessage={
          isClientValidationError<CopyscapeConfig>(errors)
            ? get(errors, ["errors", "value", "username", "0"])
            : undefined
        }
      />
      <TextField
        label={t("integrations.fields.api-key")}
        value={integration.value["api-key"]}
        onChange={(value) => updateIntegrationField(integrationName, { "api-key": value })}
        readOnly={readOnly}
        errorMessage={
          isClientValidationError<CopyscapeConfig>(errors)
            ? get(errors, ["errors", "value", "api-key", "0"])
            : undefined
        }
      />
    </React.Fragment>
  );
};

export default Copyscape;
