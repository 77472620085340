/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { connect } from "react-redux";
import classnames from "classnames/bind";
import PromotionalMessage from "../manage-fields/promotional-message";
import CardShare from "../manage-fields/card-share";
import AccessLevel from "../manage-fields/access-level";
import RecommendedTags from "pages/story-editor/components/manage-fields/recommended-tags";
import Contributions from "../manage-fields/contributions";
import CategoryTitle from "components/category-title/category-title";
import ClaimReview from "./claim-review";
import Checkbox from "components/checkbox/checkbox";
import { actions } from "../../actions";
import { NOTIFICATION_INFO } from "containers/page/actions";
import { getRecommendedTagsAction } from "pages/story-editor/async-action-creators";
import { get } from "lodash";
import ListicleTitleNumbering from "../manage-fields/listicle-title-numbering";
import styles from "./metadata.module.css";

const cx = classnames.bind(styles);

const Metadata = ({
  fields,
  onChange,
  onMetadataChange,
  onTagChange,
  toggleSponsoredContent,
  showSponsoredContent,
  story,
  fieldSpecs,
  sections,
  isContributorRolesEnabled,
  storyContributions,
  updateStoryContributions,
  accessLevelValues,
  getRecommendedTags,
  recommendedTags,
  addRecommendedTag,
  hasTagCreatePermission,
  errors = {},
  isCardShareEnabled,
  isAccessLevelEnabled,
  isClaimReviewEnabled,
  isTextToSpeechEnabled,
  isTextToSpeechDefault,
  toggleClaimReview,
  onSEOChange,
  isTagRecommendationEnabled,
  hasSetAssignmentPermission,
  enableWatermarkImages,
  defaultStoryAccess,
  defaultStoryAccessLevelValue,
  isDefaultAccessEnabled,
  isStoryLocked,
  isSlugEditableAfterPublish,
  isLinkEntityTagEnabled,
  includeEntityTypeIds
}) => {
  const generalCategoryFields = fields.filter((field) => field.categoryName === "general");
  const storyFeatureCategoryFields = fields.filter((field) => field.categoryName === "story-feature");
  const storyMetadata = story.metadata || {};
  const tagsField = (Component, specPath, storyPath) => {
    return (
      <div
        key={storyPath}
        className={cx("tags-and-recommended-tags-container", {
          "is-recommendations-enabled": isTagRecommendationEnabled
        })}>
        <Component
          value={story[storyPath]}
          onTagChange={onTagChange}
          spec={fieldSpecs[specPath]}
          errors={errors[specPath]}
          hasTagCreatePermission={hasTagCreatePermission}
          disabled={isStoryLocked}
          includeEntityTypeIds={isLinkEntityTagEnabled ? includeEntityTypeIds : []}
        />
        {isTagRecommendationEnabled && (
          <RecommendedTags
            getRecommendedTags={getRecommendedTags}
            storyId={story["story-content-id"]}
            recommendedAndPublisherTags={recommendedTags}
            addRecommendedTag={addRecommendedTag}
            storyTags={story.tags}
            hasTagCreatePermission={hasTagCreatePermission}
          />
        )}
      </div>
    );
  };

  const customSlugField = (Component, specPath, storyPath) => {
    const isSlugEditable = isSlugEditableAfterPublish ? isStoryLocked : story["first-published-at"] || isStoryLocked;
    return (
      <div key={storyPath}>
        <Component
          value={story[storyPath]}
          onChange={onChange(storyPath)}
          errors={errors[specPath] || errors[storyPath]}
          data-test-id="custom-slug"
          disabled={isSlugEditable}
        />
      </div>
    );
  };

  const slugPreview = (Component, storyPath) => {
    return (
      <div key={storyPath}>
        <Component value={story && story.slug ? decodeURIComponent(story.slug) : ""} data-test-id="slug-preview" />
      </div>
    );
  };

  return (
    <div className={styles["metadata-container"]}>
      <CategoryTitle title={t("story-editor.inspector.general")} />
      {generalCategoryFields.map(({ component: Component, specPath, storyPath }, index) => (
        <React.Fragment key={`metadata-${index}`}>
          {(storyPath === "tags" && tagsField(Component, specPath, storyPath)) ||
            (storyPath === "custom-slug" && customSlugField(Component, specPath, storyPath)) ||
            (storyPath === "slug-preview" && slugPreview(Component, storyPath)) || (
              <React.Fragment key={storyPath}>
                {fieldSpecs[specPath] && (
                  <React.Fragment>
                    <Component
                      story={story}
                      storyContributions={storyContributions}
                      updateStoryContributions={updateStoryContributions}
                      value={story[storyPath]}
                      onChange={onChange(storyPath)}
                      onMetadataChange={onMetadataChange}
                      spec={fieldSpecs[specPath]}
                      sections={sections}
                      errors={errors[specPath] || errors[storyPath]}
                      disabled={isStoryLocked}
                    />
                    {storyPath === "authors" && isContributorRolesEnabled && hasSetAssignmentPermission && (
                      <Contributions
                        storyContributions={storyContributions}
                        updateStoryContributions={updateStoryContributions}
                        disabled={isStoryLocked}
                      />
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
        </React.Fragment>
      ))}
      <CategoryTitle title={t("story-editor.inspector.story-features")} />
      <div className={styles["story-features-container"]}>
        {storyFeatureCategoryFields.map(({ component: Component, specPath, storyPath }, index) => (
          <Component
            key={storyPath}
            value={get(story, `${storyPath}`)}
            onChange={onChange(storyPath)}
            spec={fieldSpecs[specPath]}
            errors={errors[specPath]}
            toggleSponsoredContent={toggleSponsoredContent}
            showSponsoredContent={showSponsoredContent}
            onMetadataChange={onMetadataChange}
            disabled={isStoryLocked}
          />
        ))}
        {isCardShareEnabled && (
          <CardShare
            cardShare={storyMetadata["card-share"]}
            onMetadataChange={onMetadataChange}
            disabled={isStoryLocked}
          />
        )}
        {enableWatermarkImages && (
          <Checkbox
            id="watermark-image"
            checked={get(storyMetadata, ["watermark-image", "disabled"], false)}
            label={t("story-editor.inspector.disable-watermark-social-image")}
            onChange={(checked) => onMetadataChange("watermark-image", { disabled: checked })}
            disabled={isStoryLocked}
            classname={styles["metadata-checkbox"]}
          />
        )}
        <PromotionalMessage
          promotionalMessage={storyMetadata["promotional-message"]}
          onMetadataChange={onMetadataChange}
          disabled={isStoryLocked}
        />
        {isTextToSpeechEnabled && (
          <Checkbox
            id="text-to-speech"
            checked={
              storyMetadata["text-to-speech"] ? !storyMetadata["text-to-speech"]["is-disabled"] : isTextToSpeechDefault
            }
            label={t("story-editor.inspector.enable-text-to-speech")}
            onChange={(checked) => onMetadataChange("text-to-speech", { "is-disabled": !checked })}
            disabled={isStoryLocked}
            classname={styles["metadata-checkbox"]}
          />
        )}
        {isClaimReviewEnabled && (
          <ClaimReview
            toggleClaimReview={toggleClaimReview}
            claimReview={story.seo["claim-review"] || {}}
            onSEOChange={onSEOChange("claim-review")}
            errors={errors["story-claim-review"]}
            disabled={isStoryLocked}
          />
        )}
        {story["story-template"] === "listicle" && (
          <ListicleTitleNumbering
            numberingType={story["bullet-type"]}
            onChangeOfListicleTitleNumbering={onChange("bullet-type")}
            disabled={isStoryLocked}
          />
        )}
      </div>
      {isAccessLevelEnabled && (
        <React.Fragment>
          <CategoryTitle title={t("story-editor.inspector.access-level")} />
          <AccessLevel
            access={
              story.access || (isDefaultAccessEnabled && defaultStoryAccess)
            } /*  It is a dummy value and is not passed as part of the metadata on story save */
            accessLevelValue={
              story["access-level-value"] ? [story["access-level-value"]] : [defaultStoryAccessLevelValue]
            }
            onChangeOfAccess={onChange("access")}
            onChangeOfAccessLevel={onChange("access-level-value")}
            accessLevelValues={accessLevelValues}
            disabled={isStoryLocked}
          />
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAccessLevelEnabled: state.features.isAccessLevelValuesEnabled,
    isDefaultAccessEnabled: state.features.isDefaultStoryAccessEnabled,
    isCardShareEnabled: state.features.isCardLevelShareEnabled,
    showSponsoredContent: state.storyEditor.ui.showSponsoredContent,
    accessLevelValues: state.config.accessLevels,
    defaultStoryAccess: state.config.defaultStoryAccess,
    defaultStoryAccessLevelValue: state.config.defaultStoryAccessLevelValue,
    isContributorRolesEnabled: state.features.isContributorRolesEnabled,
    storyContributions: state.storyEditor.ui.storyContributions,
    hasTagCreatePermission: state.features.hasTagCreatePermission,
    isClaimReviewEnabled: state.features.isClaimReviewEnabled,
    isTextToSpeechEnabled: state.features.isTextToSpeechEnabled,
    isTextToSpeechDefault: state.config.textToSpeech,
    isTagRecommendationEnabled: state.features.isTagRecommendationEnabled,
    recommendedTags: state.storyEditor.ui.recommendedTags,
    hasSetAssignmentPermission: state.features.hasSetAssignmentPermission,
    enableWatermarkImages: state.features.enableWatermarkImages,
    isStoryLocked: state.storyEditor.ui.isStoryLocked,
    isSlugEditableAfterPublish: state.features.isSlugEditableAfterPublish,
    isLinkEntityTagEnabled: state.features.isEntityLinkEnabled,
    includeEntityTypeIds: get(state, ["config", "entity-link", "include-entity-type-ids"], [])
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSponsoredContent: (value) => dispatch({ type: actions.TOGGLE_SPONSORED_CONTENT, payload: { value } }),
    toggleClaimReview: (value) => dispatch({ type: actions.TOGGLE_CLAIM_REVIEW, payload: { value } }),
    onSEOChange: (path) => (value) => dispatch({ type: actions.UPDATE_SEO, payload: { path, value } }),
    getRecommendedTags: (storyId) => dispatch(getRecommendedTagsAction(storyId)),
    addRecommendedTag: (tag, hasTagCreatePermission) => {
      if (hasTagCreatePermission) {
        dispatch({ type: actions.ADD_TAGS, payload: { tag } });
      } else {
        dispatch({
          type: NOTIFICATION_INFO,
          payload: { message: t("story-editor.inspector.recommended-tags-create-permission-message"), action: null }
        });
      }
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Metadata);

export { Metadata };
