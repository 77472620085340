/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { t } from "i18n";
import { validate } from "utils/validation.utils";
import { ThunkDispatch } from "redux-thunk";

import Button from "components/button/button";
import Header from "components/header/header";
import { updateSelectedUserData } from "../action-creators";
import action from "../actions";
import { ValidationError, PartialAppState, SelectedUser } from "../state";
import { Location } from "history";

const constraints = {
  name: { presence: { allowEmpty: false } },
  "communication-email": { email: true },
  slug: { presence: { allowEmpty: false } }
};

const canLoginConstraint = {
  name: { presence: { allowEmpty: false } },
  username: { presence: { allowEmpty: false } },
  password: { presence: { allowEmpty: false } },
  "communication-email": { email: true },
  slug: { presence: { allowEmpty: false } }
};

interface StateProps {
  user: SelectedUser;
  isUserProfileModified: boolean;
  canLoginModified: boolean;
}

interface DispatchProps {
  updateSelectedUserData: (user: SelectedUser) => void;
  setErrors: (error: ValidationError) => void;
}

interface OwnProps {
  location: Location;
}

type Props = StateProps & DispatchProps & OwnProps;

class EditUserHeader extends React.Component<Props> {
  userData = (user: SelectedUser, canLoginModified: boolean) => {
    if (canLoginModified) {
      if (user["can-login"]) {
        return user;
      } else {
        return { ...user, username: user.email, password: "Quintype_" + Math.floor(100000 * Math.random()) };
      }
    } else {
      return user;
    }
  };

  validateUserData = (user: SelectedUser, canLoginModified: boolean) => {
    let modifiedUser = this.userData(user, canLoginModified);
    let errors = canLoginModified ? validate(modifiedUser, canLoginConstraint) : validate(modifiedUser, constraints);
    if (errors) {
      this.props.setErrors(errors);
    } else {
      this.props.updateSelectedUserData(modifiedUser);
    }
  };

  render() {
    const { isUserProfileModified, user, canLoginModified } = this.props;
    return (
      <React.Fragment>
        <Header>
          {isUserProfileModified && (
            <Button
              testId="update-btn"
              variant="success"
              type="primary"
              onClick={() => this.validateUserData(user, canLoginModified)}>
              {t("users-and-roles.update")}
            </Button>
          )}
        </Header>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: PartialAppState) => {
  return {
    user: state.usersAndRoles.usersPage.selectedUser,
    isUserProfileModified: state.usersAndRoles.usersPage.ui.isUserProfileModified,
    canLoginModified: state.usersAndRoles.usersPage.ui.canLoginModified
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>, ownProps: OwnProps) => {
  const path = ownProps.location.pathname;

  return {
    updateSelectedUserData: (user: SelectedUser) => dispatch(updateSelectedUserData(user, path)),
    setErrors: (errors: ValidationError) => dispatch({ type: action.SET_ERRORS_FOR_USER, payload: { errors } })
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditUserHeader);

export { EditUserHeader };
