/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Button from "components/button/button";
import copyToClipboard from "helpers/copy-to-clipboard";
import { t } from "i18n";

import styles from "./text-field-with-copy.module.css";
import FieldLabel from "components/field-label/field-label";

interface Props {
  value: string;
  label?: string;
  placeholder?: string;
}

interface State {
  isTextCopied: boolean;
}

class TextFieldWithCopy extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isTextCopied: false };
  }

  onCopyClick = () => {
    copyToClipboard(this.props.value);
    this.setState({ isTextCopied: true });
  };

  render() {
    const { value, label, placeholder } = this.props;
    return (
      <div className={styles["text-field-with-button-wrapper"]}>
        <div className={styles["text-field-with-button"]}>
          <div className={styles["text-field"]}>
            {label && <FieldLabel htmlFor={`text-field-${label}`} label={label} />}
            <input
              className={styles["text-field-input"]}
              id={`text-field-${label}`}
              data-test-id="text-field-input"
              type="text"
              value={value}
              onChange={(e) => null}
              readOnly={true}
              placeholder={placeholder}
            />
          </div>
          <Button
            type="secondary"
            variant={this.state.isTextCopied ? "success" : undefined}
            onClick={this.onCopyClick}
            classname="text-field-with-copy-button">
            {this.state.isTextCopied ? t("copied") : t("copy")}
          </Button>
        </div>
      </div>
    );
  }
}

export default TextFieldWithCopy;
