/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { size } from "lodash";
import { words } from "lodash";
import { includes } from "lodash";

const WORDS_PER_MINUTE = 275;
const SLOW_IMAGE_LIMIT = 10;
const INITIAL_IMAGE_READ_TIME = 12;
const FINAL_IMAGE_READ_TIME = 3;
const ESTIMATED_TWEET_WORDS = 30;

const wordCount = (string) => {
  return size(words(string));
};

const jsEmbedWordCount = (storyElement) => {
  if (storyElement && storyElement["subtype"] === "tweet") {
    return ESTIMATED_TWEET_WORDS;
  }
  return 0;
};

const wordCountForStoryElement = (storyElement) => {
  let count;
  switch (storyElement["type"]) {
    case "text":
      count = wordCount(storyElement["text"]);
      break;
    case "jsembed":
      count = jsEmbedWordCount(storyElement);
      break;
    default:
      count = 0;
  }
  return count;
};

const imageCount = (storyElement) => {
  let count = 0;
  if (storyElement["type"] === "image" || includes(["instagram"], storyElement["subtype"])) count = 1;
  return count;
};

const storyElementsCount = (element) => {
  return { words: wordCountForStoryElement(element), images: imageCount(element) };
};

const elmCount = (card) => {
  return storyElementsCount(card);
};

const totalImageAndWordCount = (cardWordAndImageCounts) => {
  return {
    words: cardWordAndImageCounts.reduce((total, wc) => total + wc["words"], 0),
    images: cardWordAndImageCounts.reduce((total, wc) => total + wc["images"], 0)
  };
};

const imageReadTime = (noOfImages) => {
  let readingTime = 0;
  for (let i = 0; i < parseInt(noOfImages, 10); i++) {
    readingTime += i >= SLOW_IMAGE_LIMIT ? FINAL_IMAGE_READ_TIME : INITIAL_IMAGE_READ_TIME - i;
  }
  return readingTime;
};

const totalWordAndImageReadTime = (wordAndImagesCount) => {
  const imgReadTime = imageReadTime(wordAndImagesCount["images"]);
  return parseInt(wordAndImagesCount["words"]) / (WORDS_PER_MINUTE / 60) + imgReadTime;
};

const timeInSeconds = (story) => {
  const storyElements = Object.values(story["story-elements"]);
  const cardsCount = storyElements.map(elmCount);
  const totalCount = totalImageAndWordCount(cardsCount);
  const totalReadTime = totalWordAndImageReadTime(totalCount);
  return totalReadTime;
};

export const timeInMinutes = (story) => {
  const totalTime = timeInSeconds(story) / 60;
  return Math.ceil(totalTime);
};
