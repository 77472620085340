/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { WretcherResponse } from "wretch";
import { mapKeys, pick, camelCase, kebabCase, mapValues } from "lodash";
import { normalize } from "utils";
import { Form, Forms } from "store/form/form";
import { CurrentForm } from "store/form/editor";
import { EmailListType } from "store/route-data";

export interface FormPayload {
  id: number;
  headline: string;
  subheadline: string;
  slug: string;
  status: string;
  "formio-id": string;
  "formio-data": any;
  "publisher-id": number;
  "created-at": number;
  "updated-at": number;
  "deleted-at": number;
}

type FormResponse = { data: FormPayload };
type FormsResponse = { data: FormPayload[]; hits: number };

function payloadToForm(formPayload: FormPayload): Form {
  const toSnakeCase = mapKeys(formPayload, (_, key) => (key.includes("-") ? camelCase(key) : key));
  return pick(toSnakeCase, [
    "id",
    "headline",
    "subheadline",
    "slug",
    "status",
    "formioId",
    "formioData",
    "updatedAt",
    "createdAt",
    "publishedAt",
    "mailingList"
  ]);
}

function formToPayload(form: CurrentForm) {
  return mapKeys(form, (_, key) => kebabCase(key));
}

export interface GetFormsFilters {
  ids?: number[];
  slugs?: string[];
  q?: string;
  limit?: number;
  offset?: number;
  status?: string;
}

export const getForm = (id: number): Promise<Form> => {
  return client
    .url(`/api/v1/forms/${id}`)
    .get()
    .json()
    .then(({ data }: FormResponse) => payloadToForm(data));
};

export const getForms = (filters: GetFormsFilters): Promise<{ forms: Forms; hits: number }> => {
  const queryParams = mapValues(filters, (value, _) => (Array.isArray(value) ? value.join(",") : value));
  return client
    .url("/api/v1/forms")
    .query(queryParams)
    .get()
    .json()
    .then(({ data, hits }: FormsResponse) => {
      const rawForms = data.map((rawForm: FormPayload) => payloadToForm(rawForm));
      return { forms: normalize(rawForms), hits: hits };
    });
};

export const create = (form: CurrentForm): Promise<Form> => {
  return client
    .url("/api/v1/forms")
    .post(formToPayload(form))
    .json()
    .then(({ data }: FormResponse) => payloadToForm(data));
};

export const update = (id: number, changes: CurrentForm): Promise<Form> => {
  return client
    .url(`/api/v1/forms/${id}`)
    .patch(formToPayload(changes))
    .json()
    .then(({ data }: FormResponse) => payloadToForm(data));
};

export const deleteForm = (id: number): Promise<void> => {
  return client
    .url(`/api/v1/forms/${id}`)
    .delete()
    .res();
};

export const exportSubmissions = async (id: number, format: string): Promise<void> => {
  const response: WretcherResponse = await client
    .url(`/api/v1/forms/${id}/submissions/export`)
    .query({ format })
    .get()
    .res();

  const blob: Blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  let fileName;
  try {
    fileName = `${response.headers.get("content-disposition")}`.split("filename=")[1];
  } catch (e) {
    fileName = `form_${id}_submissions.${format}`;
  }
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const updateDefaultMailingList = async (mailingList: EmailListType): Promise<void> => {
  const update = {
    config: {
      "default-mailing-list": mailingList
    }
  };
  return client.url("/api/v1/features/forms").patch(update);
};
