/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import actions, { ActionTypes } from "./actions";
import { INITIAL_USER_STATE, UserRole, UserTeam, State } from "./state";
import { SelectedUser } from "./state";

function isSelectedUser(user: SelectedUser | {}): user is SelectedUser {
  return (user as SelectedUser).settings !== undefined;
}

export default function usersReducer(state: State = INITIAL_USER_STATE, action: ActionTypes) {
  switch (action.type) {
    case actions.LOAD_USERS_DATA: {
      return {
        ...state,
        users: action.payload.data.members,
        usersPagination: {
          ...state.usersPagination,
          total: action.payload.data["total-count"]
        },
        ui: {
          ...state.ui,
          main: { loading: false, error: null }
        }
      };
    }
    case actions.USERS_UPDATE_CURRENT_PAGE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          currentPage: action.payload.pageNumber,
          main: { loading: true, error: null }
        }
      };
    }
    case actions.USERS_UPDATE_PAGINATION: {
      return {
        ...state,
        usersPagination: {
          ...state.usersPagination,
          offset: action.payload.offset
        }
      };
    }
    case actions.LOAD_ALL_ROLES: {
      return {
        ...state,
        ui: {
          ...state.ui,
          inspector: { loading: true, error: null }
        }
      };
    }
    case actions.LOAD_ALL_ROLES_SUCCESS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          allRoles: action.payload.roles,
          inspector: { loading: false, error: null }
        }
      };
    }
    case actions.LOAD_ALL_ROLES_FAILURE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          inspector: { loading: false, error: action.payload.error }
        }
      };
    }
    case actions.LOAD_ALL_TEAMS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          teamsInspector: { loading: true, error: null }
        }
      };
    }
    case actions.LOAD_ALL_TEAMS_SUCCESS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          allTeams: action.payload.teams,
          teamsInspector: { loading: false, error: null }
        }
      };
    }
    case actions.LOAD_ALL_TEAMS_FAILURE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          teamsInspector: { loading: false, error: action.payload.error }
        }
      };
    }

    case actions.UPDATE_CAN_LOGIN_FOR_SELECTED_USER: {
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          "can-login": action.payload.canLogin
        },
        ui: {
          ...state.ui,
          canLoginModified: true,
          isUserProfileModified: true
        }
      };
    }

    case actions.SET_ROLES_FOR_SELECTED_USER: {
      const selectedRoles = state.ui.allRoles.filter((role: UserRole) => role.checked === true);
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          roles: selectedRoles.map((role: UserRole) => ({ id: role.id, name: role.name }))
        },
        ui: {
          ...state.ui,
          isUserProfileModified: true
        }
      };
    }

    case actions.SET_TEAMS_FOR_SELECTED_USER: {
      const selectedTeams = state.ui.allTeams.filter((team: UserTeam) => team.checked === true);
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          teams: selectedTeams.map((team: UserTeam) => ({ id: team.id, name: team.name }))
        },
        ui: {
          ...state.ui,
          isUserProfileModified: true
        }
      };
    }

    case actions.UPDATE_CAN_LOGIN_FOR_NEW_USER: {
      return {
        ...state,
        newUser: {
          ...state.newUser,
          "can-login": action.payload.canLogin
        }
      };
    }

    case actions.SET_ROLES_FOR_NEW_USER: {
      const selectedRoles = state.ui.allRoles.filter((role: UserRole) => role.checked === true);
      return {
        ...state,
        newUser: {
          ...state.newUser,
          roles: selectedRoles.map((role: UserRole) => ({ id: role.id, name: role.name }))
        },
        ui: {
          ...state.ui,
          isUserProfileModified: true
        }
      };
    }
    case actions.LOAD_USER_DATA: {
      return {
        ...state,
        ui: {
          ...state.ui,
          main: { loading: true, error: null }
        }
      };
    }
    case actions.LOAD_USER_DATA_SUCCESS: {
      return {
        ...state,
        selectedUser: action.payload.data,
        ui: {
          ...state.ui,
          isUserProfileModified: false,
          main: { loading: false, error: null }
        }
      };
    }
    case actions.LOAD_USER_DATA_FAILURE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          main: { loading: false, error: action.payload.error }
        }
      };
    }
    case actions.UPDATE_USER_PROFILE: {
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          [action.payload.key]: action.payload.changes
        },
        ui: {
          ...state.ui,
          isUserProfileModified: true
        }
      };
    }
    case actions.UPDATE_USER_SETTINGS: {
      if (isSelectedUser(state.selectedUser)) {
        return {
          ...state,
          selectedUser: {
            ...state.selectedUser,
            settings: {
              ...state.selectedUser.settings,
              [action.payload.key]: action.payload.changes
            }
          }
        };
      }
      return state;
    }
    case actions.TOGGLE_USER_LANGUAGE_CHANGE_CONFIRMATION_MODAL: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isManageLanguageInspectorModalVisible: !state.ui.isManageLanguageInspectorModalVisible
        }
      };
    }
    case actions.SAVE_NEW_USER_DATA: {
      return {
        ...state,
        newUser: {
          ...state.newUser,
          [action.payload.key]: action.payload.changes
        },
        ui: {
          ...state.ui,
          isUserProfileModified: true
        }
      };
    }
    case actions.RESET_ADD_NEW_MEMBER: {
      return {
        ...state,
        newUser: INITIAL_USER_STATE.newUser,
        ui: {
          ...state.ui,
          isUserProfileModified: false,
          errors: {},
          searchTerm: "",
          saving: false
        }
      };
    }
    case actions.SET_ERRORS_FOR_USER: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isUserProfileModified: true,
          errors: action.payload.errors
        }
      };
    }
    case actions.SET_SEARCH_TERM: {
      return {
        ...state,
        ui: {
          ...state.ui,
          searchTerm: action.payload.searchTerm
        }
      };
    }

    case actions.RESET_SELECTED_USER: {
      return {
        ...state,
        ui: {
          ...state.ui,
          allRoles: [],
          allTeams: [],
          isUserProfileModified: false,
          main: { loading: false, error: null },
          searchTerm: "",
          saving: false,
          canLoginModified: false
        }
      };
    }
    case actions.SAVE_UPDATED_USER_REQUEST: {
      return {
        ...state,
        ui: {
          ...state.ui,
          main: { loading: true, error: null }
        }
      };
    }
    case actions.SAVE_UPDATED_USER_FAILURE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          main: { loading: false, error: action.payload.error }
        }
      };
    }
    case actions.UPDATE_AVATAR_UPLOAD_STATUS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          loadingAvatar: action.payload.uploading
        }
      };
    }

    case actions.SHOW_TOGGLE_LOGIN_CONFIRMATION_MODAL: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isToggleLoginConfirmationModalVisible: true
        }
      };
    }
    case actions.CLOSE_TOGGLE_LOGIN_CONFIRMATION_MODAL: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isToggleLoginConfirmationModalVisible: false
        }
      };
    }

    case actions.SHOW_DARK_MODE_CONFIRMATION_MODAL: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isDarkModeConfirmationModalVisible: true
        }
      };
    }

    case actions.CLOSE_DARK_MODE_CONFIRMATION_MODAL: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isDarkModeConfirmationModalVisible: false
        }
      };
    }

    case actions.SHOW_MANAGE_STORY_INSPECTOR_CONFIRMATION_MODAL: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isManageStoryInspectorModalVisible: true
        }
      };
    }

    case actions.CLOSE_MANAGE_STORY_INSPECTOR_CONFIRMATION_MODAL: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isManageStoryInspectorModalVisible: false
        }
      };
    }

    case actions.SET_BANNER: {
      return {
        ...state,
        ui: {
          ...state.ui,
          banner: { message: action.payload.message }
        }
      };
    }

    case actions.CLOSE_BANNER: {
      return {
        ...state,
        ui: {
          ...state.ui,
          banner: { message: null }
        }
      };
    }

    case actions.SAVING_USER: {
      return {
        ...state,
        ui: {
          ...state.ui,
          saving: action.payload.saving
        }
      };
    }

    default:
      return state;
  }
}
