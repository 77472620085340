/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { CustomURL, AnyCustomURL } from "api/custom-url";
import { CustomURLsPagination } from "./state";

enum actions {
  LIST_CUSTOM_URLS = "@@custom-urls/LIST_CUSTOM_URLS",
  LIST_CUSTOM_URLS_SUCCESS = "@@custom-urls/LIST_CUSTOM_URLS_SUCCESS",
  LIST_CUSTOM_URLS_FAILURE = "@@custom-urls/LIST_CUSTOM_URLS_FAILURE",
  UPDATE_CUSTOM_URLS_PAGINATION = "@@custom-urls/UPDATE_PAGINATION",
  SET_CUSTOM_URLS_SEARCH_TERM = "@@custom-urls/SET_SEARCH_TERM",
  SET_CURRENT_CUSTOM_URL = "@@custom-urls/SET_CURRENT_CUSTOM_URL",
  UPDATE_CURRENT_CUSTOM_URL = "@@custom-urls/UPDATE_CURRENT_CUSTOM_URL",
  CANCEL_SAVE_CURRENT_CUSTOM_URL = "@@custom-urls/CANCEL_SAVE_CURRENT_CUSTOM_URL",
  SAVE_CUSTOM_URL = "@@custom-urls/SAVE_CUSTOM_URL",
  SAVE_CUSTOM_URL_SUCCESS = "@@custom-urls/SAVE_CUSTOM_URL_SUCCESS",
  SAVE_CUSTOM_URL_FAILURE = "@@custom-urls/SAVE_CUSTOM_URL_FAILURE",
  DELETE_CUSTOM_URL = "@@custom-urls/DELETE_CUSTOM_URL",
  DELETE_CUSTOM_URL_SUCCESS = "@@custom-urls/DELETE_CUSTOM_URL_SUCCESS",
  DELETE_CUSTOM_URL_FAILURE = "@@custom-urls/DELETE_CUSTOM_URL_FAILURE",
  TOGGLE_DELETE_CONFIRMATION = "@@custom-urls/TOGGLE_DELETE_CONFIRMATION"
}

export type ListCustomURLsAction = { type: actions.LIST_CUSTOM_URLS };

export type ListCustomURLsSuccessAction = {
  type: actions.LIST_CUSTOM_URLS_SUCCESS;
  payload: {
    customURLs: CustomURL[];
    pagination: number;
  };
};

export type ListCustomURLsFailureAction = {
  type: actions.LIST_CUSTOM_URLS_FAILURE;
  payload: {
    error: Error;
  };
};

export type UpdateCustomUrlsPagination = {
  type: actions.UPDATE_CUSTOM_URLS_PAGINATION;
  payload: {
    pagination: CustomURLsPagination;
  };
};

export type SetCustomUrlsSearchTerm = {
  type: actions.SET_CUSTOM_URLS_SEARCH_TERM;
  payload: {
    searchTerm: string;
  };
};

export type SetCurrentCustomURL = {
  type: actions.SET_CURRENT_CUSTOM_URL;
  payload: {
    customURL: AnyCustomURL;
  };
};

export type UpdateCurrentCustomUrl = {
  type: actions.UPDATE_CURRENT_CUSTOM_URL;
  payload: {
    customURL: AnyCustomURL;
  };
};

export type CancelSaveCurrentCustomURL = {
  type: actions.CANCEL_SAVE_CURRENT_CUSTOM_URL;
};

export type SaveCustomURL = {
  type: actions.SAVE_CUSTOM_URL;
};
export type SaveCustomURLSuccess = {
  type: actions.SAVE_CUSTOM_URL_SUCCESS;
};

export type SaveCustomURLFailure = {
  type: actions.SAVE_CUSTOM_URL_FAILURE;
  payload: { error: Error };
};

export type DeleteCustomURL = {
  type: actions.DELETE_CUSTOM_URL;
};

export type DeleteCustomURLSuccess = {
  type: actions.DELETE_CUSTOM_URL_SUCCESS;
};

export type DeleteCustomURLFailure = {
  type: actions.DELETE_CUSTOM_URL_FAILURE;
  payload: {
    error: Error;
  };
};

export type ToggleDeleteConfirmation = {
  type: actions.TOGGLE_DELETE_CONFIRMATION;
};

export type ActionTypes =
  | ListCustomURLsAction
  | ListCustomURLsSuccessAction
  | ListCustomURLsFailureAction
  | UpdateCustomUrlsPagination
  | SetCustomUrlsSearchTerm
  | SetCurrentCustomURL
  | UpdateCurrentCustomUrl
  | CancelSaveCurrentCustomURL
  | SaveCustomURL
  | SaveCustomURLSuccess
  | SaveCustomURLFailure
  | DeleteCustomURL
  | DeleteCustomURLSuccess
  | DeleteCustomURLFailure
  | ToggleDeleteConfirmation;

export default actions;
