/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

export interface Templates {
  story: { [key: string]: Template };
}

export interface Template {
  name: string;
  "content-type": "story";
  slug: string;
  config: Config;
  toggle: boolean;
  id: number;
  description: string;
}

export interface Config {
  fields: Fields;
}

export interface Fields {
  tags: Author;
  headline: Headline;
  subheadline: HeroImageCaption;
  sections: Sections;
  "custom-url": Author;
  storyline: Author;
  "instant-articles-video-fields": InstantArticlesVideoFields;
  "meta-keywords": Author;
  "meta-description": HeroImageCaption;
  "meta-title": HeroImageCaption;
  author: Author;
  summary: HeroImageCaption;
  "canonical-url": CanonicalURL;
  "hero-image-attribution": HeroImageAttribution;
  "hero-image-s3-key": Author;
  "sponsored-by": Author;
  "hero-image-caption": HeroImageCaption;
  "hero-image-focus-point": Author;
  "hero-image-alt-text": HeroImageCaption;
  "image-alt-text": HeroImageCaption;
  "home-alternative-hero-image-s3-key": Author;
  "home-alternative-headline": Headline;
  "social-alternative-hero-image-s3-key": Author;
  "social-alternative-headline": Headline;
}

export interface Author {
  validations: AuthorValidations;
}

export interface AuthorValidations {
  mandatory: boolean;
  hidden: boolean;
}

export interface CanonicalURL {
  validations: CanonicalURLValidations;
}

export interface CanonicalURLValidations {
  hidden: boolean;
}

export interface Headline {
  "can-be-hidden-in-editor?": boolean;
  validations: HeadlineValidations;
}

export interface HeadlineValidations {
  "hard-limit": number;
  mandatory: boolean;
}

export interface HeroImageAttribution {
  validations: HeadlineValidations;
}

export interface HeroImageCaption {
  validations: HeroImageCaptionValidations;
}

export interface HeroImageCaptionValidations {
  "hard-limit": number;
  mandatory: boolean;
  hidden: boolean;
}

export interface InstantArticlesVideoFields {
  "can-be-hidden-in-editor?": boolean;
  validations: AuthorValidations;
}

export interface Sections {
  "can-be-hidden-in-editor?": boolean;
  validations: SectionsValidations;
}

export interface SectionsValidations {
  mandatory: boolean;
}

export const getCustomTemplates = (): Promise<Templates> => {
  return client
    .url("/api/content-templates")
    .get()
    .json();
};

export const updateTemplate = (template: Template) => {
  return client
    .url(`/api/content-templates/${template["content-type"]}/${template.slug}`)
    .json({ template })
    .post()
    .json();
};

export const addTemplate = (template: Template) => {
  return client
    .url("/api/content-templates")
    .json({ template })
    .post()
    .json();
};
