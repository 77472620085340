/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Select from "components/select/select";
import "react-day-picker/lib/style.css";
import DateRangePicker from "components/date-picker/date-range-picker";
import { startOfDay, endOfDay } from "date-fns";
import "./date-filter.css";
import { t } from "i18n";

export enum DateFilterTypes {
  Today = "today",
  ThisWeek = "this-week",
  ThisMonth = "this-month",
  Last7Days = "last-7-days",
  Last30Days = "last-30-days",
  Custom = "custom"
}

export interface DateOption {
  label: string;
  value: DateFilterTypes;
}

export const DATE_OPTIONS: (translator: typeof t) => Array<DateOption> = (t) => {
  return [
    { label: t("consumers.date_filter_types.today"), value: DateFilterTypes.Today },
    { label: t("consumers.date_filter_types.this_week"), value: DateFilterTypes.ThisWeek },
    { label: t("consumers.date_filter_types.this_month"), value: DateFilterTypes.ThisMonth },
    { label: t("consumers.date_filter_types.custom_range"), value: DateFilterTypes.Custom }
  ];
};
export interface RelativeDateFilter {
  type:
    | DateFilterTypes.Today
    | DateFilterTypes.ThisWeek
    | DateFilterTypes.ThisMonth
    | DateFilterTypes.Last7Days
    | DateFilterTypes.Last30Days;
}

export interface CustomDateFilter {
  type: DateFilterTypes.Custom | null;
  from: Date | number | null;
  to: Date | number | null;
}

export type DateFilter = RelativeDateFilter | CustomDateFilter;

type OnChange = (filter: DateFilter | null) => void;

interface Props {
  label?: string;
  value: DateFilter | null;
  onChange: OnChange;
  classname?: string;
}

interface State {
  filter: DateFilter | null;
}

const toDate = (date: string | number | Date | null) => (date === null ? null : new Date(date));

class DateFilterComponent extends React.Component<Props, State> {
  handleStartAndEndDate(startDate: Date, endDate: Date) {
    const formatStartDate = startDate && startOfDay(startDate);
    const formatEndDate = endDate && endOfDay(endDate);

    const newFilter: CustomDateFilter = {
      type: DateFilterTypes.Custom,
      from: formatStartDate.getTime(),
      to: formatEndDate.getTime()
    };

    this.props.onChange(newFilter as CustomDateFilter);
  }

  onChange(type: DateFilterTypes | null) {
    const defaultStartDate = startOfDay(new Date()).getTime();
    const defaultEndDate = endOfDay(new Date()).getTime();
    const filter = type
      ? type === DateFilterTypes.Custom
        ? ({
            type,
            from: defaultStartDate,
            to: defaultEndDate
          } as CustomDateFilter)
        : ({
            type
          } as RelativeDateFilter)
      : null;
    this.props.onChange(filter);
  }

  render() {
    const filter = this.props.value;
    return (
      <div className={this.props.classname}>
        <Select
          label={this.props.label}
          value={(filter && DATE_OPTIONS(t).find((option) => option.value === filter.type)) || null}
          options={DATE_OPTIONS(t)}
          onChange={(dateOption: DateOption | null) => this.onChange(dateOption ? dateOption.value : null)}
          classname="date-filter-error"
        />
        {filter && filter.type === DateFilterTypes.Custom && (
          <DateRangePicker
            startDate={toDate(filter.from)}
            endDate={toDate(filter.to)}
            onChange={(startDate: Date, endDate: Date) => this.handleStartAndEndDate(startDate, endDate)}
            classname="date-filter-date-picker"
          />
        )}
      </div>
    );
  }
}
export default DateFilterComponent;
