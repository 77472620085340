/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import TextField from "components/text-field/text-field";
import { get } from "lodash";
import { AmpConfig, FontsConfigTypes } from "api/amp-config";
import styles from "./fonts-config.module.css";

interface Props {
  ampConfig: AmpConfig;
  saveErrors: Error | null;
  updateConfig: (key: string, changes: FontsConfigTypes) => void;
}

const FontsConfig: React.SFC<Props> = ({ ampConfig, saveErrors, updateConfig }) => {
  return (
    <React.Fragment>
      <div className={styles["amp-config-category-details-container"]}>
        <h3 className={styles["amp-config-title"]}>{t("settings.amp.fonts.title")}</h3>
        <p className={styles["amp-config-description"]}>{t("settings.amp.fonts.description")}</p>
      </div>
      <div className={styles["amp-config-fields-container"]}>
        <TextField
          label={t("settings.amp.fonts.primary_font_family")}
          value={ampConfig.fonts.primary.family}
          onChange={(value) =>
            updateConfig("fonts", {
              ...ampConfig.fonts,
              primary: { ...ampConfig.fonts.primary, family: value }
            })
          }
          hint={t("common.hint-eg", { value: "'Roboto', sans-serif" })}
          errorMessage={get(saveErrors, ["fonts", "primary", "family", "code"])}
        />
        <TextField
          label={t("settings.amp.fonts.primary_font_url")}
          value={ampConfig.fonts.primary.url}
          onChange={(value) =>
            updateConfig("fonts", { ...ampConfig.fonts, primary: { ...ampConfig.fonts.primary, url: value } })
          }
          hint={t("common.hint-eg", { value: "Roboto:300,300i,400,500,700" })}
          errorMessage={get(saveErrors, ["fonts", "primary", "url", "code"])}
        />
        <TextField
          label={t("settings.amp.fonts.secondary_font_family")}
          value={ampConfig.fonts.secondary.family}
          onChange={(value) =>
            updateConfig("fonts", {
              ...ampConfig.fonts,
              secondary: { ...ampConfig.fonts.secondary, family: value }
            })
          }
          hint={t("common.hint-eg", { value: "'Roboto', sans-serif" })}
          errorMessage={get(saveErrors, ["fonts", "secondary", "family", "code"])}
        />
        <TextField
          label={t("settings.amp.fonts.secondary_font_url")}
          value={ampConfig.fonts.secondary.url}
          onChange={(value) =>
            updateConfig("fonts", {
              ...ampConfig.fonts,
              secondary: { ...ampConfig.fonts.secondary, url: value }
            })
          }
          hint={t("common.hint-eg", { value: "Roboto:300,300i,400,500,700" })}
          errorMessage={get(saveErrors, ["fonts", "secondary", "url", "code"])}
        />
      </div>
    </React.Fragment>
  );
};

export default FontsConfig;
