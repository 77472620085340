/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Linkedin = ({ width = 24, height = 24 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <path
      fill="#4875B4"
      fillRule="evenodd"
      d="M21.5 2c.406 0 .758.148 1.055.445.297.297.445.649.445 1.055v18c0 .406-.148.758-.445 1.055A1.442 1.442 0 0 1 21.5 23h-18c-.406 0-.758-.148-1.055-.445A1.442 1.442 0 0 1 2 21.5v-18c0-.406.148-.758.445-1.055A1.442 1.442 0 0 1 3.5 2h18zM5.234 20h3.141V9.969h-3.14V20zM6.781 8.61c.5 0 .93-.18 1.29-.54.359-.36.538-.78.538-1.265 0-.485-.18-.907-.539-1.266A1.761 1.761 0 0 0 6.781 5c-.5 0-.922.18-1.265.54C5.172 5.897 5 6.32 5 6.804s.172.906.516 1.265c.343.36.765.54 1.265.54zM20 20v-5.484c0-1.563-.219-2.704-.656-3.422-.594-.906-1.61-1.36-3.047-1.36-.719 0-1.36.172-1.922.516-.469.281-.812.64-1.031 1.078h-.047v-1.36h-3V20h3.094v-4.969c0-.781.109-1.375.328-1.781.312-.531.828-.797 1.547-.797.718 0 1.187.297 1.406.89.156.376.234.97.234 1.782V20H20z"
    />
  </svg>
);

export default Linkedin;
