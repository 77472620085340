/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AnySection, SectionTree, Section } from "api/section";
import { TabList } from "./state";

enum actions {
  LIST_SECTIONS = "@@sections/LIST_SECTIONS",
  LIST_SECTIONS_SUCCESS = "@@sections/LIST_SECTIONS_SUCCESS",
  LIST_SECTIONS_FAILURE = "@@sections/LIST_SECTIONS_FAILURE",

  REPLACE_CURRENT_SECTION = "@@sections/REPLACE_CURRENT_SECTION",

  INIT_NEW_SECTION = "@@sections/INIT_NEW_SECTION",
  EDIT_SECTION = "@@sections/EDIT_SECTION",
  EDIT_SECTION_FAILURE = "@@sections/EDIT_SECTION_FAILURE",
  CANCEL_CREATE_OR_EDIT_SECTION = "@@sections/CANCEL_CREATE_OR_EDIT_SECTION",

  SAVE_SECTION = "@@sections/SAVE_SECTION",
  SAVE_SECTION_SUCCESS = "@@sections/SAVE_SECTION_SUCCESS",
  SAVE_SECTION_FAILURE = "@@sections/SAVE_SECTION_FAILURE",

  DELETE_SECTION = "@@sections/DELETE_SECTION",
  DELETE_SECTION_SUCCESS = "@@sections/DELETE_SECTION_SUCCESS",
  DELETE_SECTION_FAILURE = "@@sections/DELETE_SECTION_FAILURE",

  UNDELETE_SECTION = "@@sections/UNDELETE_SECTION",
  UNDELETE_SECTION_SUCCESS = "@@sections/UNDELETE_SECTION_SUCCESS",
  UNDELETE_SECTION_FAILURE = "@@sections/UNDELETE_SECTION_FAILURE",

  SHOW_CONFIRMATION_MODEL = "@@sections/SHOW_CONFIRMATION_MODEL",
  CLOSE_CONFIRMATION_MODEL_AND_RESET_DATA = "@@sections/CLOSE_CONFIRMATION_MODEL_AND_RESET_DATA",
  SET_SECTION_TO_BE_DELETED = "@@sections/SET_SECTION_TO_BE_DELETED",
  SET_GENERATED_SLUG = "@@sections/SET_GENERATED_SLUG",

  SET_CURRENT_TAB = "@@sections/SET_CURRENT_TAB"
}

export type SetGeneratedSlugAction = { type: actions.SET_GENERATED_SLUG; payload: { section: AnySection } };
export type ListSectionsAction = { type: actions.LIST_SECTIONS };
export type ListSectionsSuccessAction = { type: actions.LIST_SECTIONS_SUCCESS; payload: { sections: SectionTree[] } };
export type ListSectionsFailureAction = { type: actions.LIST_SECTIONS_FAILURE; payload: { error: Error } };

export type ReplaceCurrentSectionAction = {
  type: actions.REPLACE_CURRENT_SECTION;
  payload: { section: AnySection | null };
};
export type InitNewSectionAction = { type: actions.INIT_NEW_SECTION };
export type EditSectionAction = { type: actions.EDIT_SECTION; payload: { section: Section } };
export type EditSectionFailureAction = { type: actions.EDIT_SECTION_FAILURE; payload: { error: Error } };
export type CancelCreateOrEditSectionAction = {
  type: actions.CANCEL_CREATE_OR_EDIT_SECTION;
  payload: { section: AnySection };
};

export type SaveSectionAction = { type: actions.SAVE_SECTION; payload: { section: AnySection } };
export type SaveSectionSuccessAction = { type: actions.SAVE_SECTION_SUCCESS; payload: { section: Section } };
export type SaveSectionFailureAction = {
  type: actions.SAVE_SECTION_FAILURE;
  payload: { section: AnySection; error: Error };
};

export type DeleteSectionAction = { type: actions.DELETE_SECTION; payload: { section: Section } };
export type DeleteSectionSuccessAction = { type: actions.DELETE_SECTION_SUCCESS; payload: { section: Section } };
export type DeleteSectionFailureAction = {
  type: actions.DELETE_SECTION_FAILURE;
  payload: { section: Section; error: Error };
};

export type UndeleteSectionAction = {
  type: actions.UNDELETE_SECTION;
  payload: { section: Section };
};
export type UndeleteSectionSuccessAction = { type: actions.UNDELETE_SECTION_SUCCESS; payload: { section: Section } };
export type UndeleteSectionFailureAction = {
  type: actions.UNDELETE_SECTION_FAILURE;
  payload: { section: Section; error: Error };
};

export type ShowConfirmationModel = { type: actions.SHOW_CONFIRMATION_MODEL };
export type CloseConfirmationModel = { type: actions.CLOSE_CONFIRMATION_MODEL_AND_RESET_DATA };
export type SetSectionToBeDeleted = { type: actions.SET_SECTION_TO_BE_DELETED; payload: { section: Section } };

export type SetCurrentTabAction = { type: actions.SET_CURRENT_TAB; payload: { currentTab: TabList } };

export type ActionTypes =
  | ListSectionsAction
  | ListSectionsSuccessAction
  | ListSectionsFailureAction
  | ReplaceCurrentSectionAction
  | InitNewSectionAction
  | EditSectionAction
  | EditSectionFailureAction
  | CancelCreateOrEditSectionAction
  | SaveSectionAction
  | SaveSectionSuccessAction
  | SaveSectionFailureAction
  | DeleteSectionAction
  | DeleteSectionSuccessAction
  | DeleteSectionFailureAction
  | UndeleteSectionAction
  | UndeleteSectionSuccessAction
  | UndeleteSectionFailureAction
  | ShowConfirmationModel
  | CloseConfirmationModel
  | SetSectionToBeDeleted
  | SetGeneratedSlugAction
  | SetCurrentTabAction;

export default actions;
