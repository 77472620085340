/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useCallback, useState } from "react";
import { t } from "i18n";
import classnames from "classnames/bind";

import Button from "components/button/button";
import { connect } from "react-redux";

import { useOverlay, useImageData } from "custom-hooks/image";
import {
  setSelectedFocusPoint,
  setDefaultFocusPoint,
  removeFocusPoint,
  FocusPoint
} from "pages/story-editor/story-element-types/image/focus-point";

import { getErrorMessage } from "pages/story-editor/utils";
import styles from "./hero-image.module.css";
import { Image, AnyImage } from "api/search-media-image";
import AddNewMedia from "components/add-new-media/add-new-media";
import { ClientId } from "api/primitive-types";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState } from "pages/story-editor/state";
import { editInspectorImageAction } from "pages/story-editor/async-action-creators";
import { AnyAction } from "redux";
import { DropzoneMessageVariant } from "components/dropzone/dropzone-message";
import RichTextField from "components/rich-text-field/rich-text-field";
import ImageActionBar, { ImageAction, ImageActionType } from "components/image-action-bar/image-action-bar";
import { get } from "lodash";
import Chip from "components/chip/chip";
import TextArea from "components/text-area/text-area";
import { STORY_EDITOR_IMAGE_INSPECTOR_PATH } from "pages/story-editor/routes";
import { navigateFn } from "utils/routes.utils";
import { Story } from "api/story";

const cx = classnames.bind(styles);

const TRANSLATION_PATH = "story-editor.header-card.hero-image";

interface StateProps {
  isDesktopSizeViewport: boolean;
  isStoryImageHyperlinkEnabled: boolean;
  fieldSpecs?: any;
}

interface OwnProps {
  image: Image | null;
  isDesktopSizeViewport: boolean;
  errors: any;
  openMediaGallery: () => void;
  updateImageData: (image: AnyImage) => void;
  deleteImage?: () => void;
  onSetAlternativesButtonClick?: () => void;
  openPhotoEditor?: (image: Image, imageAs: { type: string }) => void;
  setSelectedImage?: (media: AnyImage[], mediaAs?: { type: string; subtype?: string }, imageId?: ClientId) => void;
  readOnly: boolean;
  isFocusPointMandatory?: boolean;
  story?: Story;
}

interface DispatchProps {
  defaultSetSelectedImage: (storyId: string, imageId: ClientId, image: Image) => void;

  switchToUploadRoute: (mediaKey: string, imageId: ClientId) => void;
}

type HeroImageProps = StateProps & OwnProps & DispatchProps;

function HeroImage({
  image,
  updateImageData,
  deleteImage,
  errors,
  openPhotoEditor,
  onSetAlternativesButtonClick,
  openMediaGallery,
  isDesktopSizeViewport,
  setSelectedImage,
  defaultSetSelectedImage,
  readOnly,
  isFocusPointMandatory,
  isStoryImageHyperlinkEnabled,
  switchToUploadRoute,
  story,
  fieldSpecs
}: HeroImageProps) {
  const isExistingImage = image && !!image.url;
  const imageContainer = React.createRef<HTMLDivElement>();
  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
  const { imageDimensions, focusPoint } = useImageData(image);
  const { overlayProperties, overlayPoint } = useOverlay(imageContainer, imageDimensions, focusPoint);
  const hasError = errors && (errors.key || errors.metadata);
  const showFocusPointMandatoryError = !focusPoint && get(hasError, "focus-point");
  const handleCaptionChange = useCallback((caption) => updateImageData({ ...image!, caption }), [
    image,
    updateImageData
  ]);
  const handleAttributionChange = useCallback((attribution) => updateImageData({ ...image!, attribution }), [
    image,
    updateImageData
  ]);
  const handleHyperlinkChange = useCallback((hyperlink) => updateImageData({ ...image!, hyperlink }), [
    image,
    updateImageData
  ]);
  const handleAltTextChange = useCallback((altText) => updateImageData({ ...image!, "alt-text": altText }), [
    image,
    updateImageData
  ]);
  const setFocusPointFn = (e) => {
    overlayProperties &&
      updateImageData({
        ...image!,
        metadata: setDefaultFocusPoint(e, image!.metadata, overlayProperties.dimensions)
      });
  };
  const imageActions: ImageAction[] = [
    new ImageAction(
      ImageActionType.ClearFocusPoint,
      (e) => updateImageData({ ...image!, metadata: removeFocusPoint(e, image!.metadata) }),
      readOnly || !focusPoint
    ),
    new ImageAction(
      ImageActionType.SetFocusPoint,
      setFocusPointFn,
      readOnly || !!focusPoint,
      undefined,
      showFocusPointMandatoryError
    ),
    new ImageAction(ImageActionType.DownloadImage),
    new ImageAction(ImageActionType.ReplaceImage, (e) => openMediaGallery(), readOnly),
    new ImageAction(
      ImageActionType.EditImage,
      (e) => openPhotoEditor && openPhotoEditor(image!, { type: "hero-image" }),
      readOnly || !openPhotoEditor
    ),
    new ImageAction(ImageActionType.DeleteImage, (e) => deleteImage && deleteImage(), readOnly || !deleteImage)
  ];
  const showSetFocusPointChip = isFocusPointMandatory && !focusPoint && !readOnly;
  const storyId = (story && story["story-content-id"]) || "";
  return (
    <React.Fragment>
      {isExistingImage ? (
        <div className={cx("hero-image-container", { "has-error": hasError })} data-test-id="hero-image-container">
          <div
            className={cx("hero-image-wrapper", { "hero-image-wrapper--error": showFocusPointMandatoryError })}
            data-test-id="hero-image-wrapper"
            ref={imageContainer}>
            <img
              src={image!.url}
              alt={image!.caption || ""}
              className={styles["image-preview"]}
              onLoad={(e) => setIsImageLoading(false)}
              onError={() => setIsImageLoading(false)}
            />
            <div
              style={overlayProperties ? overlayProperties.style : undefined}
              className={styles["image-preview-overlay"]}
              onClick={(e) =>
                overlayProperties &&
                updateImageData({
                  ...image!,
                  metadata: setSelectedFocusPoint(e, image!.metadata, overlayProperties.dimensions)
                })
              }>
              {focusPoint && overlayPoint && (
                <FocusPoint classname="hero-image-focus-point-crosshair" x={overlayPoint.x} y={overlayPoint.y} />
              )}
            </div>
            {showSetFocusPointChip && (
              <span
                className={styles["set-focus-point-container"]}
                onClick={setFocusPointFn}
                data-test-id="set-focus-point-container">
                <Chip
                  classname={cx("set-focus-point-chip", {
                    "set-focus-point-chip--error": showFocusPointMandatoryError
                  })}
                  value={t("story-editor.header-card.hero-image.set-focus-point-chip")}
                />
              </span>
            )}
            <div className={cx("hero-image-action-bar", { "hero-image-action-bar--hide": isImageLoading })}>
              <ImageActionBar
                classname={styles["image-action-buttons"]}
                data-test-id="image-action-buttons"
                actions={imageActions}
                image={image}
              />
            </div>
          </div>
          <div className={styles["image-element-details"]}>
            <RichTextField
              label={t(`${TRANSLATION_PATH}.caption`)}
              placeholder={t(`${TRANSLATION_PATH}.enter-caption`)}
              value={image!.caption ? image!.caption : ""}
              onChange={handleCaptionChange}
              errorMessage={errors && getErrorMessage(errors["caption"])}
              variant={"plain"}
              size={"medium"}
              maxRows={isDesktopSizeViewport ? 10 : undefined}
            />
            <RichTextField
              label={t(`${TRANSLATION_PATH}.attribution`)}
              placeholder={t(`${TRANSLATION_PATH}.enter-attribution`)}
              value={image!.attribution ? image!.attribution : ""}
              onChange={handleAttributionChange}
              errorMessage={errors && getErrorMessage(errors["attribution"])}
              variant={"plain"}
              size={"medium"}
              maxRows={isDesktopSizeViewport ? 10 : undefined}
            />
            {!(fieldSpecs["hero-image-alt-text"] && fieldSpecs["hero-image-alt-text"].validations.hidden) && (
              <TextArea
                label={t(`${TRANSLATION_PATH}.alt-text`)}
                placeholder={t(`${TRANSLATION_PATH}.enter-alt-text`)}
                value={image && image["alt-text"] ? image["alt-text"] : ""}
                onChange={handleAltTextChange}
                errorMessage={errors && getErrorMessage(errors["alt-text"])}
                size={"medium"}
                maxRows={isDesktopSizeViewport ? 10 : undefined}
              />
            )}
            {isStoryImageHyperlinkEnabled && (
              <TextArea
                label={t(`${TRANSLATION_PATH}.hyperlink`)}
                placeholder={t(`${TRANSLATION_PATH}.enter-hyperlink`)}
                value={image!.hyperlink ? image!.hyperlink : ""}
                onChange={handleHyperlinkChange}
                errorMessage={errors && getErrorMessage(errors["hyperlink"])}
                size={"medium"}
                maxRows={isDesktopSizeViewport ? 10 : undefined}
              />
            )}
          </div>
          {onSetAlternativesButtonClick && isDesktopSizeViewport && !readOnly && (
            <div data-test-id="add-alternatives-button">
              <Button type="secondary" testId="set-alternatives-button" onClick={onSetAlternativesButtonClick}>
                {t(`${TRANSLATION_PATH}.add-alternatives`)}
              </Button>
            </div>
          )}
        </div>
      ) : (
        <AddNewMedia
          openGallery={openMediaGallery}
          classname={styles["add-new-hero-image"]}
          setSelectedMedia={(image, imageId) => defaultSetSelectedImage(storyId, imageId, image)}
          uploadText={t(`${TRANSLATION_PATH}.upload-button-text`)}
          dropzoneMessageSize={DropzoneMessageVariant.Medium}
          enableMultipleUploads={false}
          data-test-id={"add-new-hero-image"}
          showEditImage={true}
          switchToUploadRoute={(imageId) => switchToUploadRoute(storyId, imageId)}
        />
      )}
    </React.Fragment>
  );
}

function mapStateToProps(state: PartialAppState): StateProps {
  return {
    isDesktopSizeViewport: state.viewport.isDesktopSizeViewport,
    isStoryImageHyperlinkEnabled: state.features.enableStoryImageHyperlink,
    fieldSpecs: state.storyEditor.storyTemplateFields
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<PartialAppState, any, AnyAction>): DispatchProps {
  const navigate = navigateFn(dispatch);
  return {
    defaultSetSelectedImage: (storyId: string, imageId: string, image: Image) => {
      dispatch(editInspectorImageAction(storyId, image, STORY_EDITOR_IMAGE_INSPECTOR_PATH, imageId));
    },
    switchToUploadRoute: (storyId, imageId) => {
      navigate(STORY_EDITOR_IMAGE_INSPECTOR_PATH, { id: storyId, imageId, elementType: "singleImage" });
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeroImage);

export { HeroImage };
