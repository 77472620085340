/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

export const getAblyToken = () => {
  return client
    .url("/api/ably-token")
    .get()
    .json();
};
