/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Tab } from "components/tabs/fixed-tab-list";

const TAB_LIST: (t: (path: string) => string) => Tab[] = (t) => [
  {
    label: t("workspace.tabs.all"),
    value: "all"
  },
  {
    label: t("workspace.tabs.open"),
    value: "open"
  },
  {
    label: t("workspace.tabs.pending-approval"),
    value: "pending-approval"
  },
  {
    label: t("workspace.tabs.approved"),
    value: "approved"
  },
  {
    label: t("workspace.tabs.published"),
    value: "published"
  },
  {
    label: t("workspace.tabs.rejected"),
    value: "rejected"
  },
  {
    label: t("workspace.tabs.scheduled"),
    value: "scheduled"
  }
];

const FILTER_TAB_LIST: (t: (path: string) => string) => Tab[] = (t) => [
  {
    label: t("workspace.filters_inspector.options"),
    value: "options"
  },
  {
    label: t("workspace.filters_inspector.saved_list"),
    value: "saved"
  }
];

export { TAB_LIST, FILTER_TAB_LIST };
