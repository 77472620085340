/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const SolidStar = ({ width = "24", height = "24", fill = "#5f6978" }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill={fill}
    width={width}
    height={height}>
    <path d="M11.14,4.11a1.11,1.11,0,0,1,.6-.55,1.16,1.16,0,0,1,.78,0,1.06,1.06,0,0,1,.59.55l2.21,4.48,4.95.71a1,1,0,0,1,.7.41,1.12,1.12,0,0,1,.24.74,1.07,1.07,0,0,1-.33.72l-3.59,3.49.84,5a1,1,0,0,1-.15.76,1,1,0,0,1-.63.46,1.06,1.06,0,0,1-.78-.1l-4.44-2.31L7.68,20.74a1.06,1.06,0,0,1-.78.1,1,1,0,0,1-.63-.46,1.13,1.13,0,0,1-.15-.76l.85-5-3.6-3.49a1.06,1.06,0,0,1-.32-.72,1.12,1.12,0,0,1,.24-.74A1,1,0,0,1,4,9.3l5-.71Z" />
  </svg>
);

export default SolidStar;
