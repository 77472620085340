/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { StoryId, CollectionId, Timestamp, SectionId } from "./primitive-types";
import { Author } from "./author";
import { ContentType, RulesSortBy } from "./story-collection";

interface Section {
  id: SectionId;
  name: string;
}

export interface Story {
  authors: Author[];
  headline: string;
  "content-type": string;
  id: StoryId;
  "published-at": Timestamp;
  sections: Section[];
  "disabled-at"?: Timestamp;
}

export interface Collection {
  id: CollectionId;
  name: string;
  "published-at": Timestamp;
  "updated-at": Timestamp;
  template: string;
  status: string | null;
  "publish-at"?: Timestamp;
  "data-source"?: "automated" | "manual";
  "is-pinned"?: boolean;
}

type CollectionIdString = string;

interface StoryWrapper {
  id: StoryId;
  type: ContentType.Story;
  story: Story;
  "disabled-at"?: Timestamp;
  threshold?: number;
}

export interface CollectionWrapper {
  id: CollectionIdString;
  type: ContentType.Collection;
  collection: Collection;
}

type CollectionItemWrapper = StoryWrapper | CollectionWrapper;

export interface ListCollectionItemsParams {
  "collection-id"?: CollectionId;
  sort?: RulesSortBy;
  "exclude-template"?: string;
  template?: string;
  "content-type"?: string;
  limit?: number;
  offset?: number;
  fields?: string;
  q?: string;
  "published-before"?: Timestamp;
  "published-after"?: Timestamp;
  "story-template"?: string;
  "publish-at"?: string;
  status?: string;
}

export interface ListCollectionItemsResponse {
  hits: number;
  items: CollectionItemWrapper[];
}

export const listCollectionItems = (params: ListCollectionItemsParams): Promise<ListCollectionItemsResponse> => {
  return client
    .url("/api/collection-items")
    .query(params)
    .get()
    .json();
};

export const listCollectionItemsPost = (body: ListCollectionItemsParams): Promise<ListCollectionItemsResponse> => {
  return client
    .url("/api/collection-items")
    .json(body)
    .post()
    .json();
};
