/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

import styles from "./file-upload.module.css";

class FileUpload extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      fileName: props.defaultInputLabel
    };
    this.uploadFile = this.uploadFile.bind(this);
  }
  async uploadFile(e) {
    this.setState({
      uploading: true,
      fileName: e.target.files[0].name
    });
    e.target.files[0].size > this.props.size
      ? await this.props.uploadFile(new Error("Exceeds size limit"))
      : await this.props.uploadFile(undefined, e.target.files[0], e.target.files[0].name);
    this.setState({
      uploading: false
    });
  }
  render() {
    return (
      <div className={styles["upload-file"]}>
        <label className={styles["field-label"]}>{this.props.fieldLabel}</label>
        <label htmlFor="upload-file-btn" className={styles["file-input-label"]}>
          <span className={styles["uploaded-file-name"]}>{this.state.fileName}</span>
          <span className={styles["upload-btn"]}>{this.state.uploading ? "Uploading" : this.props.buttonLabel}</span>
        </label>
        {!this.state.uploading && (
          <input
            id="upload-file-btn"
            type="file"
            className={styles["file-input"]}
            accept={Array.isArray(this.props.accepts) ? this.props.accepts.join(",") : this.props.accepts}
            onChange={(e) => this.uploadFile(e)}
            disabled={this.state.uploading}
          />
        )}
      </div>
    );
  }
}

export default FileUpload;
