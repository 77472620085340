/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { TextField as EmTextField } from "@quintype/em/components/text-field";
import { TextFieldWithCount as EmTextFieldWithCount } from "@quintype/em/components/text-field";
import { TextFieldWithButton as EmTextFieldWithButton } from "@quintype/em/components/text-field";

interface TextFieldBaseProps {
  /** Value to render  */
  value: string;
  /** Secondary information  */
  hint?: string;
  /** onChange callback */
  onChange: (value: string) => void;
  /** onBlur callback */
  onBlur?: () => void;
  /** Makes the text field read only */
  readOnly?: boolean;
  /** onKeyPress event callback */
  onKeyPress?: React.KeyboardEventHandler<any>;
  /** Placeholder for the text field */
  placeholder?: string;
  /** Error message to display */
  errorMessage?: string;
  /** Optional classname */
  classname?: string;
  /** React ref object */
  inputRef?: React.RefObject<HTMLInputElement>;
  /** onPaste callback  */
  onPaste?: (value: React.ClipboardEvent) => void;
  /** Parameter to check disable*/
  disabled?: boolean;
  /** To check if error is disabled */
  errorEnabled?: boolean;
}
interface TextFieldWithLabel extends TextFieldBaseProps {
  /** Label for the text field */
  label: string;
}

interface TextFieldWithLabelId extends TextFieldBaseProps {
  /** Label for arialabeled-by*/
  labelId: string;
}

type TextFieldProps = TextFieldWithLabel | TextFieldWithLabelId;

const TextField = (props: TextFieldProps) => <EmTextField {...props} />;
interface TextFieldWithCountProps {
  value: string;
  label: string;
  onChange: (value: string) => void;
  maxValue: number;
  disabled?: boolean;
  errorMessage?: string;
}

const TextFieldWithCount = (props: TextFieldWithCountProps) => <EmTextFieldWithCount {...props} />;
interface TextFieldWithButtonProps {
  value: string;
  label: string;
  buttonLabel: string;
  onButtonClick: () => void;
  onChange: (value: string) => void;
  readOnly?: boolean;
  placeholder?: string;
  errorMessage?: string;
  classname?: string;
}

const TextFieldWithButton = (props: TextFieldWithButtonProps) => <EmTextFieldWithButton {...props} />;

export default TextField;
export { TextFieldWithCount, TextFieldWithButton };
