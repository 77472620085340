/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Headline = ({ width = "24", height = "24", color = "#c6ccd5" }) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={color}
    viewBox="0 0 24 24">
    <path d="M17.05 11.34V4H19v16.54h-1.95V13H6.94v7.57H5V4h1.94v7.35z" />
  </svg>
);

export default Headline;
