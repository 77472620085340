/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const ExclamationCircle = ({ width = "24", height = "24", color = "#fff" }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={color}
    viewBox="0 0 24 24">
    <defs />
    <path fill="none" d="M.03.13h24v24h-24z" />
    <path d="M12 3a8.73 8.73 0 0 1 4.5 1.21 9.15 9.15 0 0 1 3.28 3.29 8.91 8.91 0 0 1 0 9 9.2 9.2 0 0 1-3.28 3.28 8.91 8.91 0 0 1-9 0 9.15 9.15 0 0 1-3.3-3.25A8.73 8.73 0 0 1 3 12a8.68 8.68 0 0 1 1.2-4.47 9.1 9.1 0 0 1 3.29-3.29A8.68 8.68 0 0 1 12 3zm0 1.16a7.67 7.67 0 0 0-3.94 1A7.79 7.79 0 0 0 5.2 8.09a7.67 7.67 0 0 0-1 3.94 7.64 7.64 0 0 0 1 3.97 7.79 7.79 0 0 0 2.85 2.85A7.67 7.67 0 0 0 12 19.86a7.64 7.64 0 0 0 3.93-1.05A7.79 7.79 0 0 0 18.77 16a7.64 7.64 0 0 0 1.05-4 7.67 7.67 0 0 0-1.05-3.94 7.82 7.82 0 0 0-2.86-2.85A7.7 7.7 0 0 0 12 4.19zm0 10.88a1 1 0 0 1 .72.29 1.06 1.06 0 0 1 0 1.46 1 1 0 0 1-.72.29 1 1 0 0 1-.73-.29 1.06 1.06 0 0 1 0-1.46 1 1 0 0 1 .73-.26zm-.4-7.69a.46.46 0 0 0-.35.13.34.34 0 0 0-.09.31l.25 6.21a.37.37 0 0 0 .12.27.42.42 0 0 0 .31.13h.29a.4.4 0 0 0 .31-.13.44.44 0 0 0 .13-.31l.25-6.09a.32.32 0 0 0-.09-.31.44.44 0 0 0-.34-.13z" />
  </svg>
);

export default ExclamationCircle;
