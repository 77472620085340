/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface Props {
  width?: number;
  height?: number;
}

const ArchivedLibrary = ({ width = 24, height = 24 }: Props) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.5 19C3.07817 19 2.72275 18.8554 2.43375 18.5663C2.14458 18.2773 2 17.9218 2 17.5V6.6155C2 6.19367 2.16375 5.81892 2.49125 5.49125C2.81892 5.16375 3.19367 5 3.6155 5H8.59625L10.5963 7H18.3845C18.7295 7 19.0305 7.09325 19.2875 7.27975C19.5445 7.46625 19.7243 7.70633 19.827 8H10.1943L8.19425 6H3.6155C3.436 6 3.2885 6.05767 3.173 6.173C3.05767 6.2885 3 6.436 3 6.6155V17.3845C3 17.5257 3.03525 17.6411 3.10575 17.7308C3.17625 17.8206 3.26917 17.8975 3.3845 17.9615L5.65 10.3845H21.8655L19.598 17.9405C19.5032 18.2583 19.3215 18.5144 19.053 18.7087C18.7843 18.9029 18.4846 19 18.1538 19H3.5ZM4.427 18H18.5385L20.5115 11.3845H6.4L4.427 18Z"
      fill="white"
    />
  </svg>
);

export default ArchivedLibrary;
