/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { createSlice } from "@reduxjs/toolkit";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { t } from "i18n";
import * as domainApi from "api/domain";
import * as routeData from "api/route-data/route-data";
import * as menuGroup from "api/menu-group";
import * as collectionItems from "api/collection-items";
import * as storyCollection from "api/story-collection";
import * as secretMode from "store/secret-mode/secret-mode";
import { loadMenuSuccessAction } from "pages/manage/menu/action-creators";
import { notificationSuccess } from "containers/page/actions";
import { notificationError } from "action-creators/notification";
import { diffObjects, normalize } from "utils";
import { isEqual, uniq, compact, isEmpty } from "lodash";
import { getValidationErrors, getDataErrors } from "pages/secret-mode/domain-manager/errors";

export const MESSAGES_TRANSLATION_PATH = "secret-mode.domain-manager.messages";

interface Payload<T> {
  payload: T;
  type: string;
}

export interface PartialAppState extends secretMode.PartialAppState {
  domain: DomainState;
}

export interface LanguageConfig {
  name: string;
  "iso-code": string;
  "ietf-code": string;
  direction: string;
}

export interface Domain {
  id: number;
  name: string;
  slug: string;
  "host-url": string;
  "beta-host-url": string | null;
  "section-ids": Array<number>;
  "home-collection-id": number | null;
  "menu-groups": Array<number>;
  config: {
    language: LanguageConfig;
  } | null;
}

export type Domains = NormalizedObject<Domain>;

export enum InspectorStateType {
  Create = "CREATE",
  Edit = "EDIT"
}

export enum ConfirmationModalType {
  Create = "CREATE",
  Edit = "EDIT",
  Delete = "DELETE"
}

export type CurrentDomain = Partial<Domain>;

export type MenuGroup = Pick<menuGroup.MenuGroup, "id" | "name">;
export type Section = Pick<routeData.Section, "id" | "name"> & { isDisabled?: boolean };
export type Collection = Pick<collectionItems.Collection, "id" | "name">;

export type MenuGroups = NormalizedObject<MenuGroup>;
export type Collections = NormalizedObject<Collection>;
export type Sections = NormalizedObject<Section>;

export interface DomainState {
  app: {
    domains: Domains;
    currentDomain: CurrentDomain;
    collections: Collections;
    errors: {
      validation: ValidationError;
      data: DataError;
    };
  };
  ui: {
    isLoading: boolean;
    domainManagerFailed: boolean;
    isInspectorLoading: boolean;
    isActionButtonEnabled: boolean;
    inspectorState: InspectorStateType | null;
    showConfirmationModalState: ConfirmationModalType | null;
  };
}

export interface ValidationError {
  name?: string;
  "host-url"?: string;
  "beta-host-url"?: string;
  "section-ids"?: string;
}

export interface DataError {
  name?: string;
  "host-url"?: string;
}

export const INITIAL_STATE: DomainState = {
  app: {
    domains: {},
    currentDomain: {},
    collections: {},
    errors: {
      validation: {},
      data: {}
    }
  },
  ui: {
    isLoading: true,
    domainManagerFailed: true,
    inspectorState: null,
    isInspectorLoading: true,
    isActionButtonEnabled: false,
    showConfirmationModalState: null
  }
};

const { reducer, actions } = createSlice({
  initialState: INITIAL_STATE,
  name: "domain",
  reducers: {
    setDomainManagerLoading: (state: DomainState) => {
      state.ui.isLoading = true;
    },
    loadDomainManagerSuccess: (state: DomainState) => {
      state.ui.isLoading = false;
      state.ui.domainManagerFailed = false;
    },
    loadDomainManagerFailure: (state: DomainState) => {
      state.ui.isLoading = false;
      state.ui.domainManagerFailed = true;
    },
    loadCollectionsSuccess: (state: DomainState, collections: Payload<Collections>) => {
      state.app.collections = collections.payload;
    },
    loadDomainsSuccess: (state: DomainState, domains: Payload<Domains>) => {
      state.app.domains = domains.payload;
    },
    replaceCurrentDomain: (state: DomainState, domain: Payload<CurrentDomain>) => {
      state.app.currentDomain = domain.payload;
    },
    createDomainSuccess: (state: DomainState) => {
      state.ui.inspectorState = null;
      state.app.currentDomain = {};
    },
    updateDomainSuccess: (state: DomainState) => {
      state.ui.inspectorState = null;
      state.app.currentDomain = {};
    },
    deleteDomainSuccess: (state: DomainState) => {
      state.ui.inspectorState = null;
      state.app.currentDomain = {};
    },
    showConfirmationModal: (state: DomainState, modalType: Payload<ConfirmationModalType>) => {
      state.ui.showConfirmationModalState = modalType.payload;
    },
    closeConfirmationModal: (state: DomainState) => {
      state.ui.showConfirmationModalState = null;
    },
    setInspectorLoading: (state: DomainState) => {
      state.ui.isInspectorLoading = true;
    },
    setInspectorLoadingSuccess: (state: DomainState) => {
      state.ui.isInspectorLoading = false;
    },
    setInspectorLoadingFailure: (state: DomainState) => {
      state.ui.isInspectorLoading = false;
    },
    setInspectorType: (state: DomainState, inspectorType: Payload<InspectorStateType>) => {
      state.ui.inspectorState = inspectorType.payload;
    },
    toggleInspectorActionButton: (state: DomainState, isActionButtonDisabled: Payload<boolean>) => {
      state.ui.isActionButtonEnabled = isActionButtonDisabled.payload;
    },
    inspectorCancelSave: (state: DomainState) => {
      state.ui.inspectorState = null;
      state.ui.isActionButtonEnabled = false;
      state.app.currentDomain = {};
    },
    setValidationErrors: (state: DomainState, errors: Payload<ValidationError>) => {
      state.app.errors.validation = errors.payload;
    },
    setDataErrors: (state: DomainState, errors: Payload<DataError>) => {
      state.app.errors.data = errors.payload;
    },
    clearErrors: (state: DomainState) => {
      state.app.errors.validation = {};
      state.app.errors.data = {};
    }
  }
});

export const {
  setDomainManagerLoading,
  loadDomainManagerSuccess,
  loadDomainManagerFailure,
  loadDomainsSuccess,
  loadCollectionsSuccess,
  setInspectorType,
  inspectorCancelSave,
  replaceCurrentDomain,
  createDomainSuccess,
  updateDomainSuccess,
  deleteDomainSuccess,
  closeConfirmationModal,
  setValidationErrors,
  setDataErrors,
  clearErrors,
  showConfirmationModal,
  toggleInspectorActionButton,
  setInspectorLoading,
  setInspectorLoadingSuccess,
  setInspectorLoadingFailure
} = actions;

/* TODO: Have a thunk dispatch to getRouteData to load sections. Currently,
the domain manager and inspector will have stale section data until refresh*/

export function loadMenuGroups() {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
    try {
      dispatch(loadMenuSuccessAction(await menuGroup.getMenuGroupList()));
    } catch (e) {
      dispatch(notificationError(t(`${MESSAGES_TRANSLATION_PATH}.load-menu-groups-fail`)));
      throw e;
    }
  };
}

export function loadCollections(domains: Domains) {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
    try {
      const collectionIds = uniq(compact(Object.values(domains).map((domain: Domain) => domain["home-collection-id"])));
      const rawCollections = await Promise.all(
        collectionIds.map((collectionId: number) => Promise.resolve(storyCollection.fetchCollection(collectionId)))
      );
      const collections = normalize(rawCollections, ["name", "id"]);
      dispatch(loadCollectionsSuccess(collections));
    } catch (e) {
      dispatch(notificationError(t(`${MESSAGES_TRANSLATION_PATH}.load-collections-fail`)));
      throw e;
    }
  };
}

export function loadDomainManager() {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
    dispatch(setDomainManagerLoading());
    try {
      const domains = await domainApi.getAllDomains();
      await dispatch(loadMenuGroups());
      await dispatch(loadCollections(domains));
      dispatch(loadDomainsSuccess(domains));
      dispatch(loadDomainManagerSuccess());
    } catch (e) {
      dispatch(loadDomainManagerFailure());
      dispatch(notificationError(t(`${MESSAGES_TRANSLATION_PATH}.domain-manager-fail`)));
    }
  };
}

export function onDomainFormChange(currentDomain: CurrentDomain) {
  return (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>, getState: () => PartialAppState) => {
    if (currentDomain.id) {
      const initialDomain = getState().domain.app.domains[currentDomain.id];
      isEqual(initialDomain, currentDomain)
        ? dispatch(toggleInspectorActionButton(false))
        : dispatch(toggleInspectorActionButton(true));
    }
    dispatch(replaceCurrentDomain(currentDomain));
  };
}

export function validateAndShowConfirmationModal(domain: CurrentDomain, modalType: ConfirmationModalType) {
  return (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>, getState: () => PartialAppState) => {
    const validationErrors = getValidationErrors(domain);
    const dataErrors =
      modalType === ConfirmationModalType.Create ? getDataErrors(domain, getState().domain.app.domains) : {};
    if (isEmpty(validationErrors) && isEmpty(dataErrors)) {
      dispatch(clearErrors());
      dispatch(showConfirmationModal(modalType));
      return;
    }
    !isEmpty(validationErrors) && dispatch(setValidationErrors(validationErrors));
    !isEmpty(dataErrors) && dispatch(setDataErrors(dataErrors));
  };
}

export function createDomain(domain: CurrentDomain) {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
    try {
      await domainApi.createDomain(domain);
      dispatch(createDomainSuccess());
      dispatch(loadDomainManager());
      dispatch(notificationSuccess(t(`${MESSAGES_TRANSLATION_PATH}.create-success`)));
    } catch (e) {
      dispatch(notificationError(t(`${MESSAGES_TRANSLATION_PATH}.create-fail`)));
    }
  };
}

export function updateDomain(domain: Domain) {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>, getState: () => PartialAppState) => {
    const domainBeforeUpdate = getState().domain.app.domains[domain.id];
    const diffedDomain = diffObjects(domainBeforeUpdate, domain);
    try {
      await domainApi.updateDomain(domain.id, diffedDomain);
      dispatch(updateDomainSuccess());
      dispatch(loadDomainManager());
      dispatch(notificationSuccess(t(`${MESSAGES_TRANSLATION_PATH}.update-success`)));
    } catch (e) {
      dispatch(notificationError(t(`${MESSAGES_TRANSLATION_PATH}.update-fail`)));
    }
  };
}

export function deleteDomain(domain: Domain) {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
    try {
      await domainApi.deleteDomain(domain.id);
      dispatch(deleteDomainSuccess());
      dispatch(loadDomainManager());
      dispatch(notificationSuccess(t(`${MESSAGES_TRANSLATION_PATH}.delete-success`)));
    } catch (e) {
      dispatch(notificationError(t(`${MESSAGES_TRANSLATION_PATH}.delete-fail`)));
    }
  };
}

export function openInspector(inspectorType: InspectorStateType, domain?: Domain) {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
    dispatch(setInspectorType(inspectorType));
    dispatch(clearErrors());
    dispatch(setInspectorLoading());
    dispatch(closeConfirmationModal());
    try {
      domain && dispatch(replaceCurrentDomain(domain));
      await dispatch(loadMenuGroups());
      dispatch(setInspectorLoadingSuccess());
    } catch (e) {
      dispatch(setInspectorLoadingFailure());
      dispatch(notificationError(t(`${MESSAGES_TRANSLATION_PATH}.inspector-fetch-fail`)));
    }
  };
}

export default reducer;
