/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { NewUser } from "../pages/settings/pages/users-and-roles/users/state";

export interface UsersResponse {
  members: Member[];
  "total-count": number;
}

export interface Member {
  email: string;
  "last-name": null | string;
  name: string;
  slug: string;
  "avatar-url": null | string;
  roles: Role[];
  teams?: Team[];
  "first-name": string | null;
  id: number;
  "avatar-s3-key": null;
  "twitter-handle": null;
  bio?: string;
  "can-login": boolean;
}
export interface BridgekeeperUser {
  email: string;
  name: string;
  "bridgekeeper-id": number;
}

export interface Role {
  id: number;
  name: string;
}

export interface Team {
  id: string;
  name: string;
}

export const searchUsers = (term: string, params: { limit: number; offset: number }) => {
  const searchParams =
    term && term !== "" ? { ...params, q: term, mentionable: true } : { ...params, mentionable: true };
  return client
    .url("/api/v1/members")
    .query(searchParams)
    .get()
    .json();
};

export const searchUsersV2 = (term: string, params: { limit: number; offset: number }) => {
  const searchParams = term && term !== "" ? { ...params, q: term } : params;
  return client
    .url(`/api/v2/members`)
    .query(searchParams)
    .get()
    .json();
};

export const getUserProfile = (userId: string) => {
  return client
    .url(`/api/v1/members/${userId}`)
    .get()
    .json();
};

export const updateUserProfile = (updatedUser: Member) => {
  return client
    .url(`/api/v1/members/${updatedUser.id}`)
    .json(updatedUser)
    .put()
    .json()
    .catch((err) => {
      return Promise.reject(JSON.parse(err.message));
    });
};

export const createNewUserProfile = (newUser: NewUser) => {
  return client
    .url(`/api/v1/members`)
    .json(newUser)
    .post()
    .json()
    .catch((err) => {
      return Promise.reject(JSON.parse(err.message));
    });
};

export const createOrUpdateBridgekeeperMember = (bkUser: any) => {
  return client
    .url(`/api/bk-member`)
    .post(bkUser)
    .json()
    .catch((err) => {
      return Promise.reject(JSON.parse(err.message));
    });
};
