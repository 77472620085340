/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import TextField from "components/text-field/text-field";
import TextAreaWithCount from "components/text-area/text-area-with-count";
import TextFieldWithCopy from "components/text-field/text-field-with-copy";
import Select from "components/select/select";
import ImageField, { properlyTypeImage } from "components/image-field/image-field";
import {
  getCollectionTemplateOptions,
  generateRssFeedURL,
  getSelectedTemplate,
  isExistingCollection
} from "pages/collections/utils";

import { t } from "i18n";
import { AnyCollection, Metadata } from "api/story-collection";
import { CollectionMetadataMap, CollectionMetadata } from "api/route-data/collection-route-data";
import { CollectionId } from "api/primitive-types";
import { ValidationResult } from "utils/validation.utils";
import Button from "components/button/button";
import { SavedImage } from "components/image-field/types";
import { CoverImage } from "api/story-collection";
import FieldLabel from "components/field-label/field-label";
import FileUpload from "components/file-upload/file-upload";
import styles from "./content.module.css";
import { ImageOrNewImage } from "api/search-media-image";

interface Props {
  updateStagingSelectedCollection: (key: string, value: any) => void;
  stagingSelectedCollection: AnyCollection;
  sketchesHost: string;
  collectionMetadata?: CollectionMetadataMap;
  openMediaGallery: (id?: CollectionId) => void;
  removeSelectedCollectionCoverImages: () => void;
  errors: ValidationResult<AnyCollection>;
  openDeleteConfirmationModal: (collectionId: CollectionId) => void;
  openPhotoEditor: (value: SavedImage | CoverImage) => void;
  fileUploadAction: (err: Error, file: File, fileName: string, metadata: Metadata) => void;
  isAddPdfAsCollectionMetadataEnabled: boolean;
  maxFileSize: number;
  setSlug: (collection: AnyCollection) => void;
  setSelectedMedia: (images: ImageOrNewImage[]) => void;
  showEditImage?: boolean;
  switchToUploadRoute?: (mediaKey: string) => void;
  updateImageUploadStatus?: (status: { uploading: boolean }) => void;
}

const Content: React.SFC<Props> = ({
  updateStagingSelectedCollection,
  stagingSelectedCollection,
  sketchesHost,
  collectionMetadata,
  openMediaGallery,
  removeSelectedCollectionCoverImages,
  openDeleteConfirmationModal,
  errors,
  openPhotoEditor,
  fileUploadAction,
  isAddPdfAsCollectionMetadataEnabled,
  maxFileSize,
  setSlug,
  setSelectedMedia,
  showEditImage,
  switchToUploadRoute,
  updateImageUploadStatus
}) => {
  const getMetadataFileName = (metadata: Metadata) => {
    return metadata["pdf-src-key"]
      ? metadata["pdf-src-key"]["s3-key"].substr(metadata["pdf-src-key"]["s3-key"].lastIndexOf("/") + 1)
      : t("collections.content.fileUploadDefaultInputLabel");
  };
  return (
    <div className={styles["collections-content-settings"]}>
      <TextField
        value={stagingSelectedCollection.name}
        label={t("collections.content.title")}
        onChange={(value) => {
          updateStagingSelectedCollection("name", value);
        }}
        // onBlur={() => setSlug(stagingSelectedCollection)} TODO remove this comment once the collections api supports vernacular slugs
        errorMessage={errors && errors.name && stagingSelectedCollection.name.length <= 0 ? "Missing Title" : ""}
      />
      <TextAreaWithCount
        value={stagingSelectedCollection.summary || ""}
        label={t("collections.content.summary")}
        onChange={(value) => updateStagingSelectedCollection("summary", value)}
        maxValue={240}
      />

      <ImageField
        key="collections-content-settings-image"
        label={t("collections.content.image")}
        setSelectedMedia={setSelectedMedia}
        showEditImage={showEditImage}
        switchToUploadRoute={switchToUploadRoute}
        updateImageUploadStatus={updateImageUploadStatus}
        openGallery={() => {
          isExistingCollection(stagingSelectedCollection)
            ? openMediaGallery(stagingSelectedCollection.id)
            : openMediaGallery();
        }}
        onReplace={() => {
          isExistingCollection(stagingSelectedCollection)
            ? openMediaGallery(stagingSelectedCollection.id)
            : openMediaGallery();
        }}
        isPhotoEditor={true}
        openPhotoEditor={openPhotoEditor}
        value={properlyTypeImage(
          stagingSelectedCollection &&
            stagingSelectedCollection.metadata &&
            stagingSelectedCollection.metadata["cover-image"]
        )}
        onDelete={removeSelectedCollectionCoverImages}
      />
      {isAddPdfAsCollectionMetadataEnabled && (
        <FileUpload
          fieldLabel={t("collections.content.fileUploadLabel")}
          defaultInputLabel={getMetadataFileName(stagingSelectedCollection.metadata)}
          size={maxFileSize}
          accepts={["application/pdf"]}
          buttonLabel={t("collections.content.upload")}
          uploadFile={(err: Error, file: File, fileName: string) =>
            fileUploadAction(err, file, fileName, stagingSelectedCollection.metadata)
          }
        />
      )}
      <Select
        label={t("collections.content.template")}
        value={collectionMetadata && getSelectedTemplate(stagingSelectedCollection.template, collectionMetadata)}
        onChange={(template) => {
          template && !Array.isArray(template) && updateStagingSelectedCollection("template", template.id);
          template &&
            !Array.isArray(template) &&
            template.id === "series" &&
            updateStagingSelectedCollection("data-source", "automated");
        }}
        options={collectionMetadata && getCollectionTemplateOptions(collectionMetadata)}
        getOptionLabel={(template: CollectionMetadata) => template.label}
        getOptionValue={(template: CollectionMetadata) => template.id}
        defaultValue={[{ label: "Default", id: "default" }]}
        isDisabled={stagingSelectedCollection.template === "breaking-news"}
      />

      {isExistingCollection(stagingSelectedCollection) && (
        <TextFieldWithCopy
          value={generateRssFeedURL(sketchesHost, stagingSelectedCollection.slug)}
          label={t("collections.rss-feed")}
        />
      )}

      {isExistingCollection(stagingSelectedCollection) && (
        <div className={styles["collection-delete"]}>
          <FieldLabel label={t("collections.delete")} />
          <p className={styles["collection-delete-message"]}>{t("collections.delete-message")}</p>
          <Button
            type="primary"
            variant="danger"
            onClick={() => openDeleteConfirmationModal(stagingSelectedCollection.id)}>
            {t("collections.delete-collection-button")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Content;
