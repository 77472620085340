/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { batch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { omit, get } from "lodash";
import { t } from "i18n";
import * as api from "api/forms";
import { notificationError, notificationSuccess, notificationInfo } from "containers/page/actions";
import { FeatureState } from "store/route-data";
import { ExportState, Form, Format, Forms } from "./form";

export interface PartialAppState {
  forms: {
    dashboard: FormDashboardState;
  };
  features: FeatureState;
}

export interface FormActionsState {
  exportState: Partial<ExportState>;
}

export interface FormDashboardState {
  currentForms: Forms;
  hits: number;
  isLoading: boolean;
  formActionsState: NormalizedObject<FormActionsState>;
}

export const initialState: FormDashboardState = {
  currentForms: {},
  hits: 0,
  isLoading: false,
  formActionsState: {}
};

const { reducer, actions } = createSlice({
  name: "form-dashboard",
  initialState,
  reducers: {
    setIsLoading: (state: FormDashboardState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    loadForms: (state: FormDashboardState, action: PayloadAction<Forms>) => {
      state.currentForms = action.payload;
    },
    setHits: (state: FormDashboardState, action: PayloadAction<number>) => {
      state.hits = action.payload;
    },
    updateForm: (state: FormDashboardState, action: PayloadAction<{ id: number; form: Form }>) => {
      const { id, form } = action.payload;
      state.currentForms[id] = form;
    },
    removeForm: (state: FormDashboardState, action: PayloadAction<number>) => {
      state.currentForms = omit(state.currentForms, action.payload);
      state.hits = state.hits - 1;
    },
    setIsExportingJSON: (state: FormDashboardState, action: PayloadAction<{ id: number; isExporting: boolean }>) => {
      const { id, isExporting } = action.payload;
      const exportState = get(state, ["formActionsState", id, "exportState"], {});
      state.formActionsState[id] = {
        ...state.formActionsState[id],
        exportState: { ...exportState, isExportingJSON: isExporting }
      };
    },
    setIsExportingCSV: (state: FormDashboardState, action: PayloadAction<{ id: number; isExporting: boolean }>) => {
      const { id, isExporting } = action.payload;
      const exportState = get(state, ["formActionsState", id, "exportState"], {});
      state.formActionsState[id] = {
        ...state.formActionsState[id],
        exportState: { ...exportState, isExportingCSV: isExporting }
      };
    }
  }
});

export const {
  loadForms,
  setIsLoading,
  updateForm,
  removeForm,
  setHits,
  setIsExportingJSON,
  setIsExportingCSV
} = actions;

export function fetchForms(filters: api.GetFormsFilters) {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
    try {
      const { forms, hits } = await api.getForms(filters);
      batch(() => {
        dispatch(loadForms(forms));
        dispatch(setHits(hits));
      });
    } catch (e) {
      dispatch(notificationError(t("forms.dashboard.messages.load-fail")));
    }
  };
}

export function retractForm(id: number) {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
    try {
      const retractedForm = await api.update(id, { status: "draft" });
      dispatch(updateForm({ id, form: retractedForm }));
      dispatch(notificationSuccess(t("forms.messages.retract-success")));
    } catch (e) {
      dispatch(notificationError(t("forms.messages.retract-fail")));
    }
  };
}

export function deleteForm(id: number) {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
    try {
      await api.deleteForm(id);
      dispatch(removeForm(id));
      dispatch(notificationSuccess(t("forms.messages.delete-success")));
    } catch (e) {
      dispatch(notificationError(t("forms.messages.delete-fail")));
    }
  };
}

export function exportSubmissions(id: number, format: Format) {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
    const setIsExporting =
      format === Format.JSON
        ? (isExporting) => dispatch(setIsExportingJSON({ id, isExporting }))
        : (isExporting) => dispatch(setIsExportingCSV({ id, isExporting }));
    try {
      dispatch(notificationInfo(t("forms.messages.export-triggered")));
      setIsExporting(true);
      await api.exportSubmissions(id, format);
      setIsExporting(false);
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000);
      });
      dispatch(notificationSuccess(t("forms.messages.export-success")));
    } catch (e) {
      setIsExporting(false);
      dispatch(notificationError(t("forms.messages.export-fail")));
    }
  };
}

export default reducer;
