/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import InfiniteScrollList from "components/infinite-scroll-list/infinite-scroll-list";
import { AnyAction, compose } from "redux";
import { ThunkDispatch } from "redux-thunk";
import File from "components/icons/file";

import { PushNotificationListItem } from "api/push-notification";
import { PartialAppState } from "../../state";

import styles from "./push-notification-list.module.css";
import { t } from "i18n";
import Spinner from "components/spinner/spinner";
import NoContentCard from "components/no-content-card/no-content-card";
import { loadNextPushNotifications } from "../../async-action-creators";
import { TabValue } from "../../tab-list";
import PushNotificationRow from "./push-notification-row";

interface StateProps {
  pushNotificationList: PushNotificationListItem[];
  errors: Error | null;
  totalCount: number;
  currentTab: TabValue;
  term: string;
}

interface DispatchProps {
  loadMorePushNotifications: (
    term: string,
    currentTab: TabValue,
    currentPushNotifications: PushNotificationListItem[]
  ) => void;
}

type PushNotificationListProps = StateProps & DispatchProps;

const PushNotificationList: React.SFC<PushNotificationListProps> = ({
  pushNotificationList,
  errors,
  totalCount,
  loadMorePushNotifications,
  currentTab,
  term
}) => (
  <React.Fragment>
    <div className={styles["push-notification-tab-panel"]} data-test-id="infinite-scroll">
      <InfiniteScrollList
        dataLength={pushNotificationList.length}
        next={loadMorePushNotifications.bind(null, term, currentTab, pushNotificationList)}
        hasMore={totalCount - pushNotificationList.length > 0}
        loader={<Spinner message={t("push-notification.messages.loading-list")} />}
        endMessage={
          !errors && (
            <NoContentCard>
              <File />
              <div className={styles["no-content-message"]} data-test-id="no-content-message">
                {totalCount === 0
                  ? t("push-notification.messages.no_push_notifications")
                  : t("push-notification.messages.no_more_push_notifications")}
              </div>
            </NoContentCard>
          )
        }>
        {pushNotificationList.map((pushNotification) => (
          <PushNotificationRow pushNotification={pushNotification || {}} />
        ))}
      </InfiniteScrollList>
    </div>
  </React.Fragment>
);

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    pushNotificationList: state.pushNotification.pushNotifications,
    errors: state.pushNotification.ui.main.error,
    totalCount: state.pushNotification.totalCount,
    currentTab: state.pushNotification.ui.currentTab,
    term: state.pushNotification.ui.searchTerm
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    loadMorePushNotifications: (term, currentTab, currentPushNotifications) =>
      dispatch(loadNextPushNotifications(term, currentTab, currentPushNotifications))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(PushNotificationList);

export { PushNotificationList };
