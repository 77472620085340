/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";
import { isEmpty, isNil, omit, omitBy, pick, reduce } from "lodash";
import * as PublisherAPI from "api/publisher";
import { notificationError, notificationSuccess } from "action-creators/notification";
import { FeatureSettings, loadFeaturesBySlug, saveFeatureSettings, State as Feature } from "store/feature/feature";
import { selectFeaturesBySlug } from "store/feature/selectors";
import { Config } from "api/route-data/route-data";
import { Features as FeatureBooleans } from "api/route-data/secret-mode-route-data";
import { State as Menu } from "pages/manage/menu/state";

export interface PartialAppState {
  config: Pick<Config, "publisher" | "sections">;
  features: FeatureBooleans;
  menu: Pick<Menu, "menuGroups">;
  viewport: {
    isDesktopSizeViewport: boolean;
  };
  feature: Feature;
  secretMode: State;
}

export type PublisherSettings = Partial<PublisherAPI.Publisher>;

export interface State {
  ui: {
    isLoading: boolean;
    loadingFailed: boolean;
    isSaveButtonEnabled: boolean;
    isRegenerateSitemapButtonEnabled: boolean;
  };
  app: {
    publisher: PublisherSettings;
    features: FeatureSettings;
    currentTab: TabType;
    errors: Errors;
  };
}

export interface Errors {
  "website-url"?: string;
}

export enum TabType {
  General = "general-settings",
  DomainManager = "domain-manager",
  Story = "story-settings",
  Collection = "collection-settings",
  Language = "language-settings"
}

export const INITIAL_STATE: State = {
  ui: {
    isLoading: false,
    loadingFailed: false,
    isSaveButtonEnabled: false,
    isRegenerateSitemapButtonEnabled: true
  },
  app: {
    publisher: {},
    features: {},
    currentTab: TabType.General,
    errors: {}
  }
};

export const contributorRolesSlug = "contributor-roles";
export const richTextImageFieldsSlug = "rich-text-image-fields";
export const formsSlug = "forms";
export const attachmentSlug = "attachment";
export const textToSpeechSlug = "text-to-speech";
export const entityAsTagSlug = "entity-as-tag";
export const pdfAsCollectionMetadataSlug = "add-pdf-collection-metadata";

const { reducer, actions } = createSlice({
  initialState: INITIAL_STATE,
  name: "secret-mode",
  reducers: {
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.ui.isLoading = action.payload;
    },
    loadFailed: (state: State, action: PayloadAction<boolean>) => {
      state.ui.loadingFailed = action.payload;
    },
    enableSaveButton: (state: State) => {
      state.ui.isSaveButtonEnabled = true;
    },
    disableSaveButton: (state: State) => {
      state.ui.isSaveButtonEnabled = false;
    },
    setCurrentTab: (state: State, action: PayloadAction<TabType>) => {
      state.app.currentTab = action.payload;
    },
    updatePublisherSettings: (state: State, action: PayloadAction<PublisherSettings>) => {
      state.app.publisher = { ...state.app.publisher, ...action.payload };
    },
    updateFeatureSettings: (state: State, action: PayloadAction<FeatureSettings>) => {
      state.app.features = { ...state.app.features, ...action.payload };
    },
    removePublisherSettings: (state: State, action: PayloadAction<string[]>) => {
      state.app.publisher = omit(state.app.publisher, action.payload);
    },
    removeFeatureSettings: (state: State, action: PayloadAction<string[]>) => {
      state.app.features = omit(state.app.features, action.payload);
    },
    enableRegenerateSitemapButton: (state: State) => {
      state.ui.isRegenerateSitemapButtonEnabled = true;
    },
    disableRegenerateSitemapButton: (state: State) => {
      state.ui.isRegenerateSitemapButtonEnabled = false;
    },
    setErrors: (state: State, action: PayloadAction<Errors>) => {
      state.app.errors = { ...state.app.errors, ...action.payload };
    },
    clearErrors: (state: State) => {
      state.app.errors = {};
    }
  }
});

export const {
  setIsLoading,
  loadFailed,
  enableSaveButton,
  disableSaveButton,
  setCurrentTab,
  enableRegenerateSitemapButton,
  disableRegenerateSitemapButton,
  updatePublisherSettings,
  updateFeatureSettings,
  removePublisherSettings,
  removeFeatureSettings,
  setErrors,
  clearErrors
} = actions;

export function loadFeatures(slugs: string[]) {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>, getState: () => PartialAppState) => {
    await dispatch(loadFeaturesBySlug(slugs));
    const features = selectFeaturesBySlug(getState().feature, slugs);
    dispatch(updateFeatureSettings(features));
  };
}

export function loadPublisherSettings() {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
    try {
      dispatch(setIsLoading(true));
      const publisher = await PublisherAPI.getPublisher();
      dispatch(setIsLoading(false));
      const publisherSettings = pick(publisher, ["website-language", "editor-language"]);
      const sanitizedPublisherSettings = reduce(
        publisherSettings,
        (result, value, key) => (value ? { ...result, [key]: omitBy(value, isNil) } : result),
        {}
      );
      dispatch(updatePublisherSettings(sanitizedPublisherSettings));
    } catch (e) {
      dispatch(setIsLoading(false));
      dispatch(loadFailed(true));
      dispatch(notificationError(t("secret-mode.message.load-failure")));
    }
  };
}

export function loadSettings({ featureSlugs }: { featureSlugs: string[] }) {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
    try {
      dispatch(setIsLoading(true));
      await dispatch(loadFeatures(featureSlugs));
      dispatch(setIsLoading(false));
    } catch (e) {
      dispatch(setIsLoading(false));
      dispatch(loadFailed(true));
      dispatch(notificationError(t("secret-mode.message.load-failure")));
    }
  };
}

export function savePublisherSettings(publisherSettings: PublisherSettings) {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
    try {
      !isEmpty(publisherSettings) && (await PublisherAPI.updatePublisher(publisherSettings));
    } catch (e) {
      switch (e.status) {
        case 409:
          dispatch(setErrors({ "website-url": t("secret-mode.message.duplicate-website-url") }));
          break;
        case 400:
          dispatch(setErrors({ "website-url": t("secret-mode.message.invalid-website-url") }));
          break;
      }
      throw e;
    }
  };
}

export function saveSettings() {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>, getState: () => PartialAppState) => {
    try {
      const publisherSettings = getState().secretMode.app.publisher;
      const featureSettings = getState().secretMode.app.features;
      dispatch(disableSaveButton());
      dispatch(clearErrors());
      await dispatch(savePublisherSettings(publisherSettings));
      await dispatch(saveFeatureSettings(featureSettings));
      dispatch(notificationSuccess(t("secret-mode.message.update-success")));
      await dispatch(loadFeatures(Object.keys(featureSettings)));
      await dispatch(loadPublisherSettings());
    } catch (e) {
      dispatch(notificationError(t("secret-mode.message.update-failure")));
      dispatch(enableSaveButton());
    }
  };
}

export const regenerateSitemap = () => async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
  try {
    dispatch(disableRegenerateSitemapButton());
    await PublisherAPI.regenerateSitemap();
    dispatch(notificationSuccess(t("secret-mode.message.sitemap-regeneration-success")));
    dispatch(enableRegenerateSitemapButton());
  } catch (e) {
    dispatch(enableRegenerateSitemapButton());
    dispatch(notificationError(t("secret-mode.message.server_error")));
  }
};

export const uploadThemeAttributes = (file: File) => (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
  const reader = new FileReader();
  reader.readAsText(file);

  return new Promise((resolve) => {
    reader.onload = async (e: any) => {
      try {
        const themeAttributes = JSON.parse(e.target.result);
        await PublisherAPI.uploadThemeAttributes(themeAttributes);
        dispatch(notificationSuccess(t("secret-mode.message.theme-attributes-upload-success")));
      } catch (e) {
        let message = t("secret-mode.message.server_error");
        if (e.status === 422) {
          message = t("secret-mode.message.theme-attributes-validation-error");
        }
        dispatch(notificationError(message));
      }
      resolve();
    };
  });
};

export default reducer;
