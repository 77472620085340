/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import Image from "../story-element-types/image/image";
// import Embed from "../story-element-types/embed/embed";
// import ImageGallery from "../story-element-types/image-gallery";
// import ExternalFile from "../story-element-types/external-file";
import AlsoRead from "../story-element-types/also-read";
import Attachment from "../story-element-types/attachment/attachment";
import Table from "../story-element-types/table/table";
import { Pending, Unused, Abstract, LocationElementNotAvailable } from "../story-element-types/errors";
import OEmbed from "../story-element-types/oembed/oembed";
import References from "../story-element-types/references/references";
import Text from "../story-element-types/text/text";
import OpinionPoll from "../story-element-types/opinion-poll/opinion-poll";
import ImageGallery from "../story-element-types/image-gallery/image-gallery";
import Conversation from "../story-element-types/conversation/conversation";
import ExternalImage from "../story-element-types/external-image/external-image";
import { StoryElementTextSubtype } from "../../../api/story";

const any = "any",
  none = "none";

// prettier-ignore
const StoryElementsTable = [
  ["composite"       , "image-gallery"            , ImageGallery               ],
  ["composite"       , "ingredients"              , Pending                    ], // TODO: Ingredients (Will not do it, now)
  ["composite"       , "playlist"                 , Pending                    ], // TODO: Playlist (Will not do it, now)
  ["composite"       , "references"               , References                 ],
  ["composite"       , any                        , Abstract                   ], // (Will not do it, now)
  ["data"            , "table"                    , Table                      ],
  ["data"            , any                        , Abstract                   ], // (Will not do it, now)
  ["external-file"   , "bitgravity-video"         , OEmbed                     ],
  ["external-file"   , "brightcove-video"         , OEmbed                     ],
  ["external-file"   , "jwplayer"                 , OEmbed                     ],
  ["external-file"   , "vod-video"                , OEmbed                     ],
  ["external-file"   , any                        , ExternalImage              ],
  ["file"            , "attachment"               , Attachment                 ],
  ["file"            , any                        , Abstract                   ], // (Will not do it, now)
  ["image"           , none                       , Image                      ],
  ["instagram"       , any                        , Unused                     ],
  ["jsembed"         , "avmm-vidible-video"       , Pending                    ], // TODO: OEmbed
  ["jsembed"         , "dailymotion-video"        , OEmbed                     ],
  ["jsembed"         , "dilmot-q-and-a"           , OEmbed                     ],
  ["jsembed"         , "facebook-post"            , OEmbed                     ], //update is pending(embed component)
  ["jsembed"         , "facebook-video"           , OEmbed                     ], //update is pending(embed component)
  ["jsembed"         , "instagram"                , OEmbed                     ],
  ["jsembed"         , "location"                 , LocationElementNotAvailable], // TODO: Location
  ["jsembed"         , "social-media"             , OEmbed                     ], // TODO: OEmbed
  ["jsembed"         , "tweet"                    , OEmbed                     ], //update is pending(embed component)
  ["jsembed"         , "vidible-video"            , OEmbed                     ], //update is pending(embed component)
  ["jsembed"         , any                        , OEmbed                     ], // TODO: JSEmbed
  ["polltype"        , "opinion-poll"             , OpinionPoll                ],
  ["polltype"        , any                        , Abstract                   ], // (Will not do it, now)
  ["soundcloud-audio", none                       , OEmbed                     ],
  ["text"            , "also-read"                , AlsoRead                   ],
  ["text"            , "answer"                   , Conversation               ],
  ["text"            , "bigfact"                  , Text                       ],
  ["text"            , "blockquote"               , Text                       ],
  ["text"            , "blurb"                    , Text                       ],
  ["text"            , "embed-story"              , Pending                    ], // TODO: EmbedStory (Will not do it, now)
  ["text"            , "q-and-a"                  , Text                       ],
  ["text"            , StoryElementTextSubtype.Cta, Text                       ],
  ["text"            , "question"                 , Conversation               ],
  ["text"            , "quote"                    , Text                       ],
  ["text"            , "summary"                  , Text                       ],
  ["text"            , "url"                      , Pending                    ], // TODO:
  ["text"            , any                        , Text                       ],
  ["title"           , none                       , Text                     ],
  ["tweet"           , any                        , Unused                     ],
  ["video"           , "video-clip"               , OEmbed                     ],
  ["youtube-video"   , none                       , OEmbed                     ],
  [any               , any                        , Pending                    ],
];

function match(attrs, table = StoryElementsTable) {
  const { type, subtype } = attrs;

  // @ts-ignore
  // eslint-disable-next-line
  const [_, _1, ...rest] =
    table.find(([expectedType, expectedSubtype, value]) => {
      if (type === expectedType && subtype === expectedSubtype) {
        return true;
      }

      if (type === expectedType && (subtype === null || subtype === undefined) && expectedSubtype === none) {
        return true;
      }

      if (type === expectedType && expectedSubtype === any) {
        return true;
      }

      if (expectedType === any && expectedSubtype === any) {
        return true;
      }

      return false;
    }) || [];

  return rest;
}

export default match;
