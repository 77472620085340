/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import wrapPage from "containers/page/page";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { isEmpty } from "lodash";
import { t } from "i18n";
import Spacer from "components/spacer/spacer";

import ContentHeader from "../../../components/content-header/content-header";
import Toggles from "../components/toggles/toggles";

import { fetchAllStoryTemplates, updateTemplateConfig, addTemplateConfig } from "../action-creator";

import { navigateFn } from "utils/routes.utils";
import {
  CONFIGURE_STORY_TEMPLATES_PATH,
  CONFIGURE_ADD_STORY_TEMPLATE_PATH,
  CONFIGURE_EDIT_STORY_TEMPLATE_PATH
} from "../../../routes";

import {
  SET_SELECTED_STORY_TEMPLATE,
  UPDATE_SELECTED_TEMPLATE,
  SET_NEW_STORY_TEMPLATE,
  UPDATE_NEW_STORY_TEMPLATE,
  SET_TEMPLATE_ERRORS
} from "../../../actions";

import Edit from "components/icons/edit";
import EditTemplateInspector from "./edit-template-inspector";
import AddTemplateInspector from "./add-template-inspector";
import { Template } from "api/content-templates";
import { PartialAppState } from "../../../initial-state";
import { TemplateFieldErrors } from "./shared-types";
import classnames from "classnames/bind";
import styles from "./story-templates.module.css";

const cx = classnames.bind(styles);

interface StateProps {
  customStoryTemplates: {};
  selectedStoryTemplate: Template | null;
  newStoryTemplate: Template | null;
  errors: TemplateFieldErrors;
}

interface DispatchProps {
  loadAllStoryTemplates: () => void;
  closeInspector: () => void;
  storyTemplateEdit: (selectedTemplate: Template) => void;
  onFieldChange: (key: string, changes: Object) => void;
  updateTemplate: (template: Template) => void;
  addTemplate: (template: Template) => void;
  addNewTemplate: () => void;
  setErrors: (errors: TemplateFieldErrors) => void;
}

type Props = StateProps &
  DispatchProps & {
    showSubmenu: string;
    activeMenu: string;
    showAddInspector: boolean;
    showEditInspector: boolean;
    isBannerPresent: boolean;
  };

class StoryTemplates extends React.Component<Props> {
  componentDidMount() {
    this.props.loadAllStoryTemplates();
  }

  render() {
    const {
      closeInspector,
      customStoryTemplates,
      storyTemplateEdit,
      selectedStoryTemplate,
      onFieldChange,
      updateTemplate,
      addTemplate,
      addNewTemplate,
      newStoryTemplate,
      showAddInspector,
      showEditInspector,
      setErrors,
      errors
    } = this.props;

    const allTemplate = customStoryTemplates["all"];
    let customStoryTemplatesWithoutAll = Object.assign({}, customStoryTemplates);
    delete customStoryTemplatesWithoutAll["all"];

    return (
      <React.Fragment>
        <main
          className={cx("story-templates-page-container", "configure-page")}
          data-test-id="story-templates-page-container">
          <ContentHeader
            title={t("settings.storyTemplates.title")}
            description={t("settings.storyTemplates.description")}
            actionButtonLabel={t("settings.storyTemplates.add_template_button")}
            handleActionButtonClick={() => addNewTemplate()}
            addNewDisabled={isEmpty(customStoryTemplatesWithoutAll)}
          />

          <div className={styles["default-configuration"]} data-test-id="default-configuration">
            <h4 className={styles["default-configuration-title"]} data-test-id="default-configuration-title">
              {t("settings.storyTemplates.all_template_defaults")}
            </h4>
            <Spacer token="m" />
            <span
              className={styles["default-configuration-edit-action"]}
              data-test-id="default-configuration-edit-btn"
              onClick={() => storyTemplateEdit(allTemplate)}>
              <Edit />
            </span>
          </div>

          <Toggles<Template>
            toggles={Object.values(customStoryTemplatesWithoutAll)}
            onChange={(selectedFeature) => updateTemplate(selectedFeature)}
            onEdit={(templateSlug) => storyTemplateEdit(customStoryTemplatesWithoutAll[templateSlug])}
          />

          {newStoryTemplate && (
            <AddTemplateInspector
              isActive={showAddInspector}
              onClose={() => closeInspector()}
              newStoryTemplate={newStoryTemplate}
              onFieldChange={onFieldChange}
              addTemplate={(newStoryTemplate) => addTemplate(newStoryTemplate)}
              setErrors={setErrors}
              errors={errors}
            />
          )}

          {selectedStoryTemplate && (
            <EditTemplateInspector
              isActive={showEditInspector}
              onClose={() => closeInspector()}
              selectedStoryTemplate={selectedStoryTemplate}
              onFieldChange={(key, changes) => onFieldChange(key, changes)}
              updateTemplate={(selectedStoryTemplate) => updateTemplate(selectedStoryTemplate)}
              setErrors={setErrors}
              errors={errors}
            />
          )}
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    customStoryTemplates: state.settingsPage.customStoryTemplates,
    selectedStoryTemplate: state.settingsPage.selectedStoryTemplate,
    newStoryTemplate: state.settingsPage.newStoryTemplate,
    errors: state.settingsPage.ui.errors
  };
};

interface OwnProps {
  showAddInspector: boolean;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  ownProps: OwnProps
): DispatchProps => {
  const navigate = navigateFn(dispatch);
  return {
    closeInspector: () => navigate(CONFIGURE_STORY_TEMPLATES_PATH),
    loadAllStoryTemplates: () => dispatch(fetchAllStoryTemplates()),
    storyTemplateEdit: (selectedTemplate) => {
      navigate(CONFIGURE_EDIT_STORY_TEMPLATE_PATH, { id: selectedTemplate.slug });
      dispatch({ type: SET_SELECTED_STORY_TEMPLATE, payload: { selectedTemplate } });
    },
    onFieldChange: (key, changes) => {
      ownProps.showAddInspector
        ? dispatch({ type: UPDATE_NEW_STORY_TEMPLATE, payload: { key, changes } })
        : dispatch({ type: UPDATE_SELECTED_TEMPLATE, payload: { key, changes } });
    },

    updateTemplate: (template) => dispatch(updateTemplateConfig(template)),
    addTemplate: (template) => dispatch(addTemplateConfig(template)),
    addNewTemplate: () => {
      navigate(CONFIGURE_ADD_STORY_TEMPLATE_PATH);
      dispatch({ type: SET_NEW_STORY_TEMPLATE });
    },
    setErrors: (errors) => dispatch({ type: SET_TEMPLATE_ERRORS, payload: { errors } })
  };
};

export default compose<any, any, any>(connect(mapStateToProps, mapDispatchToProps), wrapPage())(StoryTemplates);

export { StoryTemplates };
