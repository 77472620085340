/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

const intl: any = Intl;

export const getTimezoneList = (): string[] => {
  return intl.supportedValuesOf("timeZone");
};

export const getBrowserTimezone = (): string => {
  return intl.DateTimeFormat().resolvedOptions().timeZone;
};
