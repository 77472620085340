/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import NoContentCard from "components/no-content-card/no-content-card";
import Media from "components/icons/media";
import { CompositeStoryElement, StoryElements, StoryElementCompositeSubtype } from "api/story";
import styles from "./composite-story-element-display.module.css";

interface Props {
  storyElement: CompositeStoryElement;
  allStoryElements: StoryElements;
}

const CompositeStoryElementDisplay: React.SFC<Props> = ({ storyElement, allStoryElements }) => (
  <React.Fragment>
    {storyElement.subtype === StoryElementCompositeSubtype.References && (
      <div>
        {storyElement.tree.map((elementId) => (
          <React.Fragment>
            <div
              className={styles["story-texts"]}
              dangerouslySetInnerHTML={{
                __html: allStoryElements[elementId].metadata.name || ""
              }}
            />
            <div
              className={styles["story-texts"]}
              dangerouslySetInnerHTML={{
                __html: allStoryElements[elementId].metadata.url || ""
              }}
            />
            <div
              className={styles["story-texts"]}
              dangerouslySetInnerHTML={{
                __html: allStoryElements[elementId].metadata.description || ""
              }}
            />
          </React.Fragment>
        ))}
      </div>
    )}
    {storyElement.subtype === StoryElementCompositeSubtype.ImageGallery &&
      storyElement.tree.map((elementId) => {
        let childStoryElement = allStoryElements[elementId]["image"];
        return childStoryElement ? (
          <div className={styles["gallery-element-image"]}>
            <img src={childStoryElement["url"]} alt={childStoryElement["alt-text"] || undefined} height={115} />
          </div>
        ) : (
          <NoContentCard>
            <Media />
          </NoContentCard>
        );
      })}
  </React.Fragment>
);

export default CompositeStoryElementDisplay;
