/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const TwitterSquare = ({ width = 24, height = 24 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <path
      fill="#1DA1F2"
      fillRule="evenodd"
      d="M20.75 1.5a2.17 2.17 0 0 1 1.594.656c.437.438.656.969.656 1.594v16.5a2.17 2.17 0 0 1-.656 1.594 2.17 2.17 0 0 1-1.594.656H4.25a2.17 2.17 0 0 1-1.594-.656A2.17 2.17 0 0 1 2 20.25V3.75c0-.625.219-1.156.656-1.594A2.17 2.17 0 0 1 4.25 1.5h16.5zm-2.297 7.453A6.82 6.82 0 0 0 20 7.36a6.268 6.268 0 0 1-1.781.47 3.15 3.15 0 0 0 1.36-1.688 5.83 5.83 0 0 1-1.97.75c-.593-.657-1.328-.985-2.203-.985-.625 0-1.195.172-1.71.516a3.108 3.108 0 0 0-1.15 1.383c-.25.578-.296 1.21-.14 1.898a8.404 8.404 0 0 1-3.562-.96A9.25 9.25 0 0 1 6.03 6.468a2.908 2.908 0 0 0-.422 1.523 3.052 3.052 0 0 0 1.407 2.602 3.201 3.201 0 0 1-1.407-.422v.047c0 .75.235 1.406.703 1.969a3.119 3.119 0 0 0 1.782 1.078 4.234 4.234 0 0 1-1.407.047 2.87 2.87 0 0 0 1.079 1.523c.53.39 1.125.586 1.78.586-1.124.875-2.39 1.312-3.796 1.312H5a8.703 8.703 0 0 0 4.734 1.36c1.782 0 3.375-.453 4.782-1.36 1.25-.812 2.234-1.921 2.953-3.328.656-1.312.984-2.672.984-4.078v-.375z"
    />
  </svg>
);

export default TwitterSquare;
