/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import Inspector from "components/inspector/inspector";
import Select from "components/select/select";
import React, { useRef, useEffect, useState } from "react";
import { t } from "i18n";
import { connect } from "react-redux";
import { get } from "lodash";
import OpenInNewTab from "components/icons/open";
import styles from "./preview.module.css";
import { COLLECTIONS_SELECTED_COLLECTION_PATH } from "pages/collections/routes";
import { Collection } from "api/story-collection";
import NoContentCard from "components/no-content-card/no-content-card";
import File from "components/icons/file";
import { navigate } from "utils/routes.utils";
import { debounce, delay } from "lodash";
import { loadCollectionPreview } from "./utils";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { PartialAppState } from "pages/collections/initial-state";

type OwnProps = {
  isActive: boolean;
};

type DispatchProps = {
  closePreviewInspector: (collectionId: number) => void;
};

type StateProps = {
  previewBase: string;
  previewId: string;
  selectedCollection: Collection;
};

type Props = OwnProps & StateProps & DispatchProps;

export const CollectionPreview: React.FC<Props> = ({
  isActive,
  previewBase,
  previewId,
  closePreviewInspector,
  selectedCollection
}) => {
  const [previewOptions, setOptions] = useState([{ label: "Home", value: "home" }]);
  const [currentPreview, setPreview] = useState({ label: "", value: "" });
  const [previewErrorMsg, setPreviewErrorMsg] = useState(true);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const previewUrl = `${previewBase}/preview/${previewId}/collection/${currentPreview.value}`;

  useEffect(() => {
    if (selectedCollection) {
      const name = selectedCollection.name || "New";
      const slug = selectedCollection.slug || "new";
      const newOptions =
        slug === "home"
          ? [{ label: name, value: slug }]
          : [
              { label: "Home", value: "home" },
              { label: name, value: slug }
            ];
      setOptions(newOptions);
      setPreview({ label: name, value: slug });
    }
  }, [selectedCollection]);

  const debounceTime = 3000;

  const debouncedLoadCollectionPreview = useRef(
    debounce(async (collection) => {
      try {
        if (collection) {
          await loadCollectionPreview(previewId, collection);
        }
      } catch (error) {
        console.error("Error loading collection preview:", error);
        setPreviewErrorMsg(true);
      }
    }, debounceTime)
  ).current;

  useEffect(() => {
    debouncedLoadCollectionPreview(selectedCollection);
    return () => {
      debouncedLoadCollectionPreview.cancel();
    };
  }, [debouncedLoadCollectionPreview, selectedCollection, previewId]);

  useEffect(() => {
    if (isActive && !previewErrorMsg) {
      if (iframeRef && iframeRef.current) {
        iframeRef.current.src = previewUrl;
      }
    }
  }, [isActive, previewErrorMsg, previewUrl]);

  useEffect(() => {
    if (isActive) {
      (async () => await loadCollectionPreview(previewId, selectedCollection))();
      setPreviewErrorMsg(false);
      const checkPreview = async () => {
        try {
          const response = await fetch(previewUrl, { method: "GET" });
          setPreviewErrorMsg(response.status === 404);
        } catch {
          setPreviewErrorMsg(true);
        }
      };
      const delayTime = 500;
      const delayedExecution = delay(checkPreview, delayTime);
      return () => {
        clearTimeout(delayedExecution);
      };
    }
    return () => {};
  }, [isActive, previewUrl, previewId, selectedCollection]);

  return (
    <Inspector
      title={t("collections.preview")}
      actionButtonLabel={t("collections.open-preview")}
      iconComponent={() => <OpenInNewTab fill="#fff" />}
      onActionButtonClick={() => window.open(previewUrl)}
      isActive={isActive}
      onClose={() => closePreviewInspector(selectedCollection.id)}
      level={0}>
      <Select
        name={t("collections.select-preview-page")}
        value={currentPreview}
        options={previewOptions}
        onChange={(selectedOption: any) => {
          setPreview(selectedOption);
        }}
        label={t("collections.select-preview-page")}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        isMulti={false}
      />
      <div data-test-id="collection-preview-container" className={styles["preview-container"]}>
        {previewErrorMsg ? (
          <NoContentCard>
            <File />
            <div data-test-id="no-preview-message">{t("collections.no-preview-message")}</div>
          </NoContentCard>
        ) : (
          <iframe data-test-id="preview-iframe" title="Preview" src={previewUrl} ref={iframeRef} />
        )}
      </div>
    </Inspector>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    previewId: state.config["preview-id"],
    previewBase: get(state, ["config", "publisher", "website-url"]),
    selectedCollection: get(state, ["collections", "collectionPage", "selectedCollection"]) as Collection
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    closePreviewInspector: (collectionId: number) => {
      dispatch(navigate(COLLECTIONS_SELECTED_COLLECTION_PATH, { collectionId: collectionId || "new" }));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionPreview);
