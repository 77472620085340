/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Author as AuthorType } from "api/author";
import styles from "./author.module.css";

type AuthorProps = {
  author: Pick<AuthorType, "name">;
  delimiter?: React.ReactElement<any> | string | null;
};

const Author = ({ author, delimiter }: AuthorProps) => (
  <div className={styles["story-author"]}>
    <span>{author.name}</span>
    {delimiter}
  </div>
);

export default Author;
