/*
 ************************************************************************
 *  © [2015 - 2025] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const MobileIcon = ({ width = "16", height = "16", fill = "var(--mono-5)", color = "#fff" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill={fill}>
    <g clipPath="url(#clip0_3450_4396)">
      <path
        fill={color}
        d="M5.143 14q-.471 0-.807-.32A1.03 1.03 0 0 1 4 12.91V3.09q0-.45.336-.77T5.143 2h5.714q.472 0 .807.32t.336.77v9.82q0 .45-.336.77-.335.32-.807.32zm0-1.636v.545h5.714v-.545zm0-1.091h5.714V4.727H5.143zm0-7.637h5.714v-.545H5.143z"></path>
    </g>
    <defs>
      <clipPath id="clip0_3450_4396">
        <rect width="16" height="16" fill="#fff" rx="2"></rect>
      </clipPath>
    </defs>
  </svg>
);

export default MobileIcon;
